import React from 'react'
import ReactLoading from 'react-loading'

import theme from '@/assets/styles/theme'

import { Container } from './styles'

type LoadingType =
  | 'blank'
  | 'balls'
  | 'bars'
  | 'bubbles'
  | 'cubes'
  | 'cylon'
  | 'spin'
  | 'spinningBubbles'
  | 'spokes'

interface IFullScreenLoaderProps {
  type?: LoadingType
}

export const FullScreenLoader: React.FC<IFullScreenLoaderProps> = ({
  type = 'spin',
}) => (
  <Container>
    <ReactLoading
      type={type}
      width="100px"
      height="100px"
      color={theme.colors.primary}
    />
  </Container>
)
