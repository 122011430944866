/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {
  ChangeEvent,
  FC,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { toast } from 'react-toastify'

import { eq, isEmpty, last } from 'lodash'
import styled from 'styled-components'

import { IObjectLiteral } from '@/@types/common/object-literal'
import { IPermission } from '@/@types/Permission'
import { FullScreenLoader } from '@/components/FullScreenLoader'
import Button from '@/components/Ui/Button'
import { ButtonEdit } from '@/components/Ui/ButtonEdit'
import Input from '@/components/Ui/Form/Input'
import { Select } from '@/components/Ui/Select'
import api from '@/services/api'
import { RootState } from '@/store'
import { BillCreators } from '@/store/ducks/Bill'
import { Creators } from '@/store/ducks/Clients'
import { Creators as CreatorsVisit } from '@/store/ducks/Visit'
import { insertBRNumberMask } from '@/utils/insertNumberMask/insertBRNumberMask'
import { Switch } from '@material-ui/core'
import { FormHandles } from '@unform/core'
import { Form } from '@unform/web'

import { EditClientBoard } from './EditClientBoard'
import { EditClientCommissionInstallationCost } from './EditClientCommissionInstallationCost'
import { EditClientEnergyConsumption } from './EditClientEnergyConsumption'
import { EditClientTariff } from './EditClientTariff'
import { PlanGroup } from './PlanGroup'
import { PlanGroupProposal } from './PlanGroupProposal'
import * as S from './styles'

type UpdateProjectInfoType = {
  [key: string]: string | undefined
  structure?: string
  new_standard_justify?: string
  technical_comment?: string
}

const Styles = {
  FormTechnicalComment: styled(Form)`
    margin-top: 30px;
    input {
      background-color: #f6f6f6;
    }
  `,
  ContainerForm: styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
    button {
      margin-left: 10px;
    }
  `,
}

export type ProposalType = {
  id: string
  created_at: string
  updated_at: string
  power_of_the_project: number
  client_id: string
  distributor_kit_cost: number
  sale_value: number
  franchisee_commission: number
  final_value: number
  final_installed_wp: number
  value_kit_for_customer: number
  nf_value: number
  tax_solar21: number
  contribution_margin: number
  total_amount_receivable_franchisee: number
  inverter_quantity: number
  inverter_power: number
  inverter_brand: string
  inverter_warranty: string
  module_quantity: number
  module_brand: string
  module_power: number
  annual_production: number
  annual_savings: number
  payback: number
  module_warranty: number
  type: string
}

type Item = { value: string; label: string }

const ReportFour: FC = () => {
  const dispatch = useDispatch()

  const commentFormRef = useRef<FormHandles>(null)
  const newConsumptionFormRef = useRef<FormHandles>(null)

  const permission = useSelector((state: RootState) => state.Auth.role)
  const isFranchisee = permission === IPermission.FRANCHISEE
  const isAdmin = permission === IPermission.ADMIN

  let { user_id: userId } = useSelector((state: RootState) => state.Auth)

  if (userId == null) userId = ''

  const {
    client,
    clientProjectInfo,
    isLoading,
    isEditClientTariff,
    isEditClientEnergyConsumption,
    isEditClientBoard,
    clientSimulatedPlanValues,
    freeMonths: free,
  } = useSelector((state: RootState) => state.Client)
  const {
    amount: billAmount,
    new_consumption_media: newAvgConsumption,
    id: billId,
  } = useSelector((state: RootState) => state.Bill.billDetails)

  const technicalVisitStep =
    client.steps?.find(step => step.type === 'TECHNICAL_VISIT')?.status ===
    'APPROVED'

  const [typeKit, setTypeKit] = useState<string>('')
  const [optionsTypes, setOptionsTypes] = useState<Item[]>([])
  const [overload_max, setOverloadMax] = useState<number>(1)
  const [overload_min, setOverloadMin] = useState<number>(1)
  const [kwpprice_min, setKwpPrice] = useState<number>(1)
  const [signatureContract, setSignatureContract] = useState<boolean>(false)
  const [comment, setComment] = useState<string>('')
  const [hasNewAvg, setHasNewAvg] = useState<boolean>(!!newAvgConsumption)
  const [newAvgValue, setNewAvgValue] = useState<number>(newAvgConsumption || 0)
  const [disableAvgForm, setDisableAvgForm] = useState<boolean>(false)
  const [hasFreeMonth, setHasFreeMonth] = useState<boolean>(!eq(free?.max, 0))
  const [justifyStandard, setJustifyStandard] = useState<string>('')
  const [structure, setStructure] = useState<string>('')
  const [technicalComment, setTechnicalComment] = useState<string>('')
  const [specificProduction, setspecificProduction] = useState<boolean>(false)
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [proposalSimulation, setProposalSimulation] = useState<any>()
  const [proposal, setProposal] = useState<ProposalType | undefined>()

  useEffect(() => {
    const loadKitTypes = async () => {
      if (!proposalSimulation) return

      const res = await api.get(
        `admin/get_kit_types/${clientProjectInfo?.structure}/${proposalSimulation.power_of_the_project}`,
      )

      const newOptions = res.data.map((e: any) => {
        const newItem: Item = {
          value: `${e.inverterBrand} - ${e.moduleBrand}`,
          label: `Inversor: ${e.inverterBrand} / Módulo: ${e.moduleBrand}`,
        }
        return newItem
      })

      setOptionsTypes(newOptions)
    }

    loadKitTypes()
  }, [proposalSimulation, clientProjectInfo])

  useEffect(() => {
    if (isEmpty(proposal)) {
      api
        .get(`admin/customers/${client.id}/proposal`)
        .then(({ data }) => {
          const filteredData = data.filter(
            (item: ProposalType) => item.type === 'FINAL',
          )
          setProposal(last(filteredData))
        })
        .catch(err => console.error('proposal retrieving did not work: ', err))
    } else if (proposal) {
      const { inverter_brand, module_brand } = proposal

      setTypeKit(`${inverter_brand} - ${module_brand}`)
    }
  }, [proposal, client])

  useEffect(() => {
    if (!clientProjectInfo) return

    const {
      structure,
      technical_comment,
      new_standard_justify,
    } = clientProjectInfo

    setStructure(structure ?? '')
    setTechnicalComment(technical_comment ?? '')
    setJustifyStandard(new_standard_justify ?? '')
  }, [clientProjectInfo])

  useEffect(() => {
    if (client.technical_comments) setComment(client.technical_comments)
  }, [client])

  useEffect(() => {
    if (
      !isEditClientTariff &&
      !isEditClientEnergyConsumption &&
      !isEditClientBoard
    ) {
      dispatch(Creators.getClientProjectInfoRequest({ id: client.id }))
      dispatch(BillCreators.getBillDetailsRequest())
    }
  }, [
    client.id,
    dispatch,
    isEditClientTariff,
    isEditClientEnergyConsumption,
    isEditClientBoard,
  ])

  useEffect(() => {
    if (billAmount) {
      const clientSimulationData: IObjectLiteral<number> = {}

      if (clientProjectInfo)
        Object.entries(clientProjectInfo).forEach(([key, value]) => {
          if (value) clientSimulationData[key] = value
        })

      dispatch(
        Creators.getClientSimulationByClientIdRequest({
          ...clientSimulationData,
          id: client.id,
          bill_value: billAmount,
        }),
      )
    }
  }, [billAmount, clientProjectInfo, dispatch, client])

  useEffect(() => {
    const getOverloads = () => {
      api.get(`/admin/overload-states/${client.state}`).then(({ data }) => {
        setOverloadMax(data.overload_max)
        setOverloadMin(data.overload_min)
        setKwpPrice(data.kwp_price)
      })
    }

    if (!isEmpty(client)) getOverloads()
  }, [client])

  useEffect(() => {
    api.get(`/admin/check_signers/${client.id}`).then(({ data }) => {
      setSignatureContract(data.is_sign.is_signed)
    })
  }, [client])

  useEffect(() => {
    dispatch(Creators.getClientFreeMonthRequest())
  }, [dispatch])

  useEffect(() => {
    setHasFreeMonth(!eq(free?.max, 0))
  }, [free])

  const editForm = useCallback(() => {
    if (isEditClientTariff) {
      return (
        <S.ContainerLegal>
          <S.LabelContainer>
            <label>Tarifas</label>
            {!isEditClientTariff && (
              <ButtonEdit
                onClick={() => dispatch(Creators.setIsEditClientTariff(true))}
              />
            )}
          </S.LabelContainer>

          <EditClientTariff
            onClickToCancel={() =>
              dispatch(Creators.setIsEditClientTariff(false))
            }
          />
        </S.ContainerLegal>
      )
    }

    if (isEditClientEnergyConsumption) {
      return (
        <S.ContainerLegal>
          <S.LabelContainer>
            <label>Consumo de Energia</label>
            {!isEditClientEnergyConsumption && (
              <ButtonEdit
                onClick={() =>
                  dispatch(Creators.setIsEditClientEnergyConsumption(true))
                }
              />
            )}
          </S.LabelContainer>

          <EditClientEnergyConsumption
            onClickToCancel={() =>
              dispatch(Creators.setIsEditClientEnergyConsumption(false))
            }
          />
        </S.ContainerLegal>
      )
    }
    return (
      <S.ContainerLegal>
        <S.LabelContainer>
          {client.subscription_lending !== 'LENDING' || specificProduction ? (
            <label>Info de Projeto</label>
          ) : (
            <label>Comissão e Custo instalação</label>
          )}
          {!isEditClientBoard && (
            <ButtonEdit
              disabled={!isFranchisee}
              onClick={() => {
                setspecificProduction(false)
                dispatch(Creators.setIsEditClientBoard(true))
              }}
            />
          )}
        </S.LabelContainer>

        {client.subscription_lending !== 'LENDING' && (
          <EditClientBoard
            onClickToCancel={() => {
              dispatch(Creators.setIsEditClientBoard(false))
              setspecificProduction(false)
            }}
          />
        )}

        {client.subscription_lending === 'LENDING' && !specificProduction && (
          <EditClientCommissionInstallationCost
            onClickToCancel={() => {
              dispatch(Creators.setIsEditClientBoard(false))
              setspecificProduction(false)
            }}
          />
        )}

        {client.subscription_lending === 'LENDING' && specificProduction && (
          <EditClientBoard
            onClickToCancel={() => {
              dispatch(Creators.setIsEditClientBoard(false))
              setspecificProduction(false)
            }}
          />
        )}
      </S.ContainerLegal>
    )
  }, [
    dispatch,
    client.subscription_lending,
    isEditClientBoard,
    isEditClientEnergyConsumption,
    isEditClientTariff,
    specificProduction,
    isFranchisee,
  ])

  useEffect(() => {
    // eslint-disable-next-line prefer-const
    let payload: any = {
      bill_value: client.bill.amount,
      solar21_tax: 0,
      not_compensated_tax: 0,
      dealership_tax: clientProjectInfo?.dealership_tax,
      dry_tax: 0,
      kwh_availability: 0,
      concurrency_factor: clientProjectInfo?.concurrency_factor,
      module_power: 1,
      total_losses: 0.2,
      franchisee_commission_percent: clientProjectInfo?.commission,
      installation_material_cost: clientProjectInfo?.installation_material_cost,
      structure_type: clientProjectInfo?.structure,
      specific_production: clientProjectInfo?.specific_production,
      optimizer_quantity: clientProjectInfo?.optimizer_quantity,
    }

    if (typeKit !== '') {
      const split = typeKit.split(' - ')
      // eslint-disable-next-line prefer-destructuring
      payload.inverter_brand = split[0]
      // eslint-disable-next-line prefer-destructuring
      payload.module_brand = split[1]
    }
    api
      .post(`admin/customers/${client.id}/proposal_final/true`, payload)
      .then(({ data }) => {
        setProposalSimulation(data.proposal)
      })
      .catch(error => {
        console.error('Error:', error)
        delete payload.inverter_brand
        delete payload.module_brand

        api
          .post(`admin/customers/${client.id}/proposal_final/true`, {})
          .then(({ data }) => {
            setProposalSimulation(data.proposal)
          })
          .catch(apiError => {
            console.error('Error in secondary API call:', apiError)
          })
      })
  }, [clientProjectInfo, client.bill.amount, client.id, typeKit])

  const createFinalProposal = async () => {
    // eslint-disable-next-line prefer-const
    let payload: any = {
      bill_value: client.bill.amount,
      solar21_tax: 0,
      not_compensated_tax: 0,
      dealership_tax: clientProjectInfo?.dealership_tax,
      dry_tax: 0,
      kwh_availability: 0,
      concurrency_factor: clientProjectInfo?.concurrency_factor,
      module_power: 1,
      total_losses: 0.2,
      franchisee_commission_percent: clientProjectInfo?.commission,
      installation_material_cost: clientProjectInfo?.installation_material_cost,
      structure_type: clientProjectInfo?.structure,
      specific_production: clientProjectInfo?.specific_production,
      optimizer_quantity: clientProjectInfo?.optimizer_quantity,
    }

    if (typeKit !== '') {
      const split = typeKit.split(' - ')
      // eslint-disable-next-line prefer-destructuring
      payload.inverter_brand = split[0]
      // eslint-disable-next-line prefer-destructuring
      payload.module_brand = split[1]
    }

    api
      .post(`admin/customers/${client.id}/proposal_final/false`, payload)
      .then(() => {
        toast.success('Proposta final criada com sucesso!')
      })
  }

  const changeComment = useCallback(() => {
    api
      .put(`/admin/customers/technical_comment/${client.id}`, { comment })
      .then(({ data }) => {
        if (data.affected) toast.success('Atualizado')
      })
  }, [client, comment])

  const clearNewConsumption = useCallback(() => {
    setDisableAvgForm(true)
    api
      .put(`/admin/customers/${billId}/new_consumption_media`, {
        new_consumption_media: null,
      })
      .then(({ data }) => {
        if (data.affected) {
          toast.success('Média de consumo atualizada')
          dispatch(Creators.getClientProjectInfoRequest({ id: client.id }))
        }
      })
      .finally(() => setDisableAvgForm(false))
  }, [billId, dispatch, client])

  const onChangeSwitchNewConsumption = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setHasNewAvg(prev => {
        if (prev) clearNewConsumption()

        return e.target.checked
      })
    },
    [clearNewConsumption],
  )

  const submitNewConsumptionMediaValue = () => {
    /* eslint-disable */
    const new_consumption_media = newConsumptionFormRef.current?.getFieldValue(
      'new_consumption_media_value',
    )
    setDisableAvgForm(true)

    api
      .put(`/admin/customers/${billId}/new_consumption_media`, {
        new_consumption_media,
      })
      .then(({ data }) => {
        if (data.affected) {
          toast.success('Média de consumo atualizada')
          dispatch(Creators.getClientProjectInfoRequest({ id: client.id }))
        }
      })
      .finally(() => setDisableAvgForm(false))

    /* eslint-enable */
  }

  const generatePlans = useCallback(
    async (
      calc_overload: number | undefined,
      calc_kwp_price: number | undefined,
      calc_low_percent_economy: number | undefined,
      calc_med_percent_economy: number | undefined,
      calc_max_percent_economy: number | undefined,
    ) => {
      calc_low_percent_economy = calc_low_percent_economy || 0
      calc_med_percent_economy = calc_med_percent_economy || 0
      calc_max_percent_economy = calc_max_percent_economy || 0
      if (
        calc_overload !== undefined &&
        calc_kwp_price !== undefined &&
        calc_low_percent_economy !== undefined &&
        calc_med_percent_economy !== undefined &&
        calc_max_percent_economy !== undefined
      ) {
        if (calc_overload > overload_max || calc_overload < overload_min) {
          toast.error(
            `Não foi possível gerar o plano. Overload fora dos padrões! Max ${overload_max} e Min ${overload_min}`,
          )
        } else if (calc_kwp_price < kwpprice_min) {
          toast.error(
            `Não foi possível gerar o plano. R$/kWp baixo demais. Min ${kwpprice_min} R$/kWp`,
          )
        } else if (
          calc_low_percent_economy > 35 ||
          calc_med_percent_economy > 35 ||
          calc_max_percent_economy > 35
        ) {
          toast.error(
            `Não foi possível gerar o plano. Economia maior que 30%. Revise o plano.`,
          )
        } else {
          await api
            .post(`/admin/customers/${client.id}/plans`)
            .then(() => {
              toast.success('Plano gerado com sucesso!')
              Creators.setClientGeneratePlansSuccess()
            })
            .then(() => {
              dispatch(Creators.setClientFreeMonthRequest(hasFreeMonth))
            })
            .finally(() => {
              dispatch(CreatorsVisit.setIsReportFour(false))
            })
        }
      }
    },
    [client, dispatch, kwpprice_min, overload_max, overload_min, hasFreeMonth],
  )

  const optionsSelect = [
    { value: 'Cerâmica', label: 'Cerâmica' },
    { value: 'Fibrocimento', label: 'Fibrocimento' },
    { value: 'Metálica', label: 'Metálica' },
    { value: 'Laje', label: 'Laje' },
  ] as const

  const saveProjectInfo = (data: UpdateProjectInfoType) => {
    api
      .patch(`/admin/update/project_info/${client.id}`, data)
      .then(() => {
        const successMessages = [
          { key: 'structure', message: 'Estrutura salva com sucesso!' },
          {
            key: 'new_standard_justify',
            message: 'Justificativa salva com sucesso!',
          },
          {
            key: 'technical_comment',
            message: 'Observação salva com sucesso!',
          },
        ]

        successMessages.forEach(({ key, message }) => {
          if (data[key]) {
            toast.success(message)
          }
        })

        dispatch(Creators.getClientProjectInfoRequest({ id: client.id }))
      })
      // eslint-disable-next-line no-console
      .catch(err => console.error('Error updating clients project info: ', err))
  }

  return (
    <>
      {isLoading && <FullScreenLoader />}

      <S.Container>
        <div style={{ marginTop: '25px' }}>
          <S.Header style={{ marginTop: '20px' }}>
            <h1>Relatório - Etapa 4</h1>
          </S.Header>
        </div>

        {isEditClientTariff ||
        isEditClientEnergyConsumption ||
        isEditClientBoard ? (
          editForm()
        ) : (
          <>
            <S.ContainerLegal>
              <S.LabelContainer>
                <label>Tarifas e Consumo de Energia</label>
                {(isFranchisee && !signatureContract && !technicalVisitStep) ||
                isAdmin ? (
                  <ButtonEdit
                    onClick={() =>
                      dispatch(Creators.setIsEditClientTariff(true))
                    }
                  />
                ) : (
                  ''
                )}
              </S.LabelContainer>

              <S.ContainerTariff>
                <S.ContainerColumnTariffGG>
                  <S.FieldsLabel>
                    <S.Fields>
                      <S.Font>Tarifa não compensada:</S.Font> R${' '}
                      {clientProjectInfo?.not_compensated_tax
                        ?.toFixed(2)
                        .replace('.', ',')}
                    </S.Fields>
                  </S.FieldsLabel>
                  {client.subscription_lending === 'SUBSCRIPTION' ? (
                    <S.FieldsLabel>
                      <S.Fields>
                        <S.Font>Tarifa Solar21:</S.Font> R${' '}
                        {clientProjectInfo?.solar21_tax
                          ?.toFixed(2)
                          .replace('.', ',')}
                      </S.Fields>
                    </S.FieldsLabel>
                  ) : (
                    <div />
                  )}
                  <S.FieldsLabel>
                    <S.Fields>
                      <S.Font>Média (kWh):</S.Font>{' '}
                      {clientSimulatedPlanValues &&
                        insertBRNumberMask(
                          clientSimulatedPlanValues?.energy_consumption.average,
                        )}
                    </S.Fields>
                  </S.FieldsLabel>
                  <S.FieldsLabel>
                    <S.Fields>
                      <S.Font>Total (kWh):</S.Font>{' '}
                      {clientSimulatedPlanValues &&
                        insertBRNumberMask(
                          clientSimulatedPlanValues?.energy_consumption.total,
                        )}
                    </S.Fields>
                  </S.FieldsLabel>
                </S.ContainerColumnTariffGG>
                <S.ContainerColumnTariffGG>
                  <S.FieldsLabel>
                    <S.Fields>
                      <S.Font>Tarifa concessionária: </S.Font> R${' '}
                      {clientProjectInfo?.dealership_tax
                        ?.toFixed(2)
                        .replace('.', ',')}
                    </S.Fields>
                  </S.FieldsLabel>
                  <S.FieldsLabel>
                    <S.Fields>
                      <S.Font>Disponibilidade (kWh):</S.Font>{' '}
                      {clientProjectInfo?.kwh_availability}
                    </S.Fields>
                  </S.FieldsLabel>
                  <S.FieldsLabel>
                    <S.Fields>
                      <S.Font>Fator de Simultaneidade (%):</S.Font>{' '}
                      {clientProjectInfo?.concurrency_factor &&
                        `${clientProjectInfo?.concurrency_factor * 100} %`}
                    </S.Fields>
                  </S.FieldsLabel>
                </S.ContainerColumnTariffGG>
              </S.ContainerTariff>
            </S.ContainerLegal>
            <S.ContainerLegal>
              <S.LabelContainer>
                <label>
                  Cliente optante por nova média de consumo?{' '}
                  <Switch
                    color="primary"
                    checked={hasNewAvg}
                    onChange={onChangeSwitchNewConsumption}
                    disabled={disableAvgForm || technicalVisitStep}
                  />
                </label>
              </S.LabelContainer>

              {hasNewAvg && (
                <Styles.FormTechnicalComment
                  ref={newConsumptionFormRef}
                  onSubmit={submitNewConsumptionMediaValue}
                  style={{
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                  }}
                >
                  <Styles.ContainerForm>
                    <Input
                      // eslint-disable-next-line @typescript-eslint/no-explicit-any
                      onChange={(e: ChangeEvent<any>) =>
                        setNewAvgValue(e.target.value)
                      }
                      disabled={disableAvgForm}
                      name="new_consumption_media_value"
                      value={newAvgValue}
                      autoComplete="new-password"
                      label="Nova média de consumo (kWh)"
                      width="100%"
                      type="text"
                    />

                    <Button
                      onClick={submitNewConsumptionMediaValue}
                      loading={!newAvgValue || disableAvgForm}
                      size="default"
                      type="button"
                    >
                      Salvar
                    </Button>
                  </Styles.ContainerForm>
                  <Styles.ContainerForm>
                    <Input
                      // eslint-disable-next-line
                      onChange={(e: any) => setJustifyStandard(e.target.value)}
                      name="newconsumption_justify"
                      value={justifyStandard}
                      autoComplete="new-password"
                      label="Justificativa para novo padrão de consumo"
                      width="100%"
                      type="text"
                    />

                    <Button
                      onClick={() =>
                        saveProjectInfo({
                          new_standard_justify: justifyStandard,
                        })
                      }
                      loading={!justifyStandard}
                      size="default"
                      type="button"
                    >
                      Salvar
                    </Button>
                  </Styles.ContainerForm>
                </Styles.FormTechnicalComment>
              )}
            </S.ContainerLegal>
            {/* /new consumption */}
            <S.ContainerLegal>
              {[
                'aab26718-5c70-4953-ada9-ae3cf6cafc8a',
                'c1cd2c83-6133-4a11-9e37-5196761ff289',
                '52521cc3-3aba-471e-b75e-0051dcb6637d',
                '446cca4a-84d9-403b-975d-7983883369cc',
                '446cca4a-84d9-403b-975d-7983883369cc',
                'ac84a385-80ff-4efb-8dc2-af39428a61f2',
              ].includes(userId) ? (
                <S.LabelContainer>
                  {client.subscription_lending === 'SUBSCRIPTION' ? (
                    <label>
                      Mensalidade grátis
                      <Switch
                        color="primary"
                        checked={hasFreeMonth}
                        onChange={() =>
                          setHasFreeMonth(currentState => !currentState)
                        }
                      />
                    </label>
                  ) : (
                    ''
                  )}
                </S.LabelContainer>
              ) : (
                ''
              )}
            </S.ContainerLegal>
            {client.subscription_lending === 'SUBSCRIPTION' ? (
              <S.ContainerLegal>
                <S.LabelContainer>
                  <label>Info de Projeto</label>
                  {(isFranchisee &&
                    !signatureContract &&
                    !technicalVisitStep) ||
                  isAdmin ? (
                    <ButtonEdit
                      onClick={() => {
                        setspecificProduction(false)
                        dispatch(Creators.setIsEditClientBoard(true))
                      }}
                    />
                  ) : (
                    ''
                  )}
                </S.LabelContainer>

                <S.ContainerTariff>
                  <S.ContainerColumnTariff>
                    <S.FieldsLabel>
                      <S.Fields>
                        <S.Font>Potência do módulo (Wp):</S.Font>{' '}
                        {clientProjectInfo?.module_power}
                      </S.Fields>
                    </S.FieldsLabel>
                    <S.FieldsLabel>
                      <S.Fields>
                        <S.Font>Número de módulos do projeto:</S.Font>{' '}
                        {clientProjectInfo?.number_of_modules}
                      </S.Fields>
                    </S.FieldsLabel>
                  </S.ContainerColumnTariff>
                  <S.ContainerColumnTariff>
                    <S.FieldsLabel>
                      <S.Fields>
                        <S.Font>Número de módulos sugerido:</S.Font>{' '}
                        {clientSimulatedPlanValues &&
                          clientSimulatedPlanValues.photovoltaic_system
                            .suggested_number_of_project_modules}
                      </S.Fields>
                    </S.FieldsLabel>
                    <S.FieldsLabel>
                      <S.Fields>
                        <S.Font>Perdas totais (%):</S.Font>{' '}
                        {clientProjectInfo?.total_losses &&
                          `${clientProjectInfo.total_losses * 100}`}
                      </S.Fields>
                    </S.FieldsLabel>
                  </S.ContainerColumnTariff>
                  <S.ContainerColumnTariff>
                    <S.FieldsLabel>
                      <S.Fields>
                        <S.Font>Potência do inversor (kW):</S.Font>{' '}
                        {clientProjectInfo?.inverter_power &&
                          `${clientProjectInfo.inverter_power}`}
                      </S.Fields>
                    </S.FieldsLabel>
                  </S.ContainerColumnTariff>
                </S.ContainerTariff>
              </S.ContainerLegal>
            ) : (
              <S.ContainerLegal>
                <S.LabelContainer>
                  <label>Info de Projeto</label>
                  {!isFranchisee ? (
                    <ButtonEdit
                      onClick={() => {
                        setspecificProduction(true)
                        dispatch(Creators.setIsEditClientBoard(true))
                      }}
                    />
                  ) : null}
                </S.LabelContainer>

                <S.ContainerTariff>
                  <S.ContainerColumnTariff>
                    <S.FieldsLabel>
                      <S.Fields>
                        <S.Font>Produção específica:</S.Font>{' '}
                        {(clientProjectInfo?.specific_production || 0)
                          .toString()
                          .replace('.', ',')}{' '}
                        kWh/kWp.ano
                      </S.Fields>
                    </S.FieldsLabel>
                    <S.FieldsLabel>
                      <S.Fields>
                        <S.Font>Número de otimizadores:</S.Font>{' '}
                        {(clientProjectInfo?.optimizer_quantity || 0)
                          .toString()
                          .replace('.', ',')}
                      </S.Fields>
                    </S.FieldsLabel>
                  </S.ContainerColumnTariff>
                </S.ContainerTariff>
              </S.ContainerLegal>
            )}
            {client.subscription_lending === 'LENDING' ? (
              <S.ContainerLegal>
                <S.LabelContainer>
                  <label>Comissão e Custo instalação</label>
                  {(isFranchisee &&
                    !signatureContract &&
                    !technicalVisitStep) ||
                  isAdmin ? (
                    <ButtonEdit
                      disabled={!isFranchisee}
                      onClick={() => {
                        setspecificProduction(false)
                        dispatch(Creators.setIsEditClientBoard(true))
                      }}
                    />
                  ) : (
                    ''
                  )}
                </S.LabelContainer>
                <S.ContainerTariff>
                  <S.ContainerColumnTariff>
                    <S.FieldsLabel>
                      <S.Fields>
                        <S.Font>Sua Comissão:</S.Font>{' '}
                        {clientProjectInfo &&
                          insertBRNumberMask(
                            clientProjectInfo?.commission * 100,
                          )}{' '}
                        {' %'}
                      </S.Fields>
                    </S.FieldsLabel>
                    <S.FieldsLabel>
                      <S.Fields>
                        <S.Font>Instalação + Material CA:</S.Font>{' '}
                        {clientProjectInfo &&
                          insertBRNumberMask(
                            clientProjectInfo?.installation_material_cost *
                              1000,
                          )}
                        {' R$/kWp'}
                      </S.Fields>
                    </S.FieldsLabel>
                  </S.ContainerColumnTariff>
                </S.ContainerTariff>
              </S.ContainerLegal>
            ) : (
              ''
            )}
            {/* new box to more info */}
            <Styles.FormTechnicalComment
              ref={commentFormRef}
              onSubmit={changeComment}
              style={{
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
              }}
            >
              {' '}
              {client.subscription_lending !== 'LENDING' ? null : (
                <Styles.ContainerForm>
                  <Select
                    placeholder="Selecione..."
                    label="Tipo de estrutura"
                    size="custom"
                    isDisabled={technicalVisitStep}
                    defaultValue={optionsSelect[0]}
                    options={optionsSelect}
                    value={optionsSelect?.find(
                      state => state.label === structure,
                    )}
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    onChange={(state: any) => setStructure(state.label)}
                    isSearchable={false}
                  />
                  {(isFranchisee &&
                    !signatureContract &&
                    !technicalVisitStep) ||
                  isAdmin ? (
                    <Button
                      style={{ marginTop: 20 }}
                      onClick={() => saveProjectInfo({ structure })}
                      loading={!structure}
                      size="default"
                      type="button"
                    >
                      Salvar
                    </Button>
                  ) : (
                    ''
                  )}
                </Styles.ContainerForm>
              )}
              {proposalSimulation && client.subscription_lending === 'LENDING' && (
                <Styles.ContainerForm>
                  <Select
                    placeholder="Selecione..."
                    label="Sistemas Equivalentes"
                    size="custom"
                    isDisabled={technicalVisitStep}
                    defaultValue={optionsTypes[0]}
                    options={optionsTypes}
                    value={optionsTypes?.find(state => state.value === typeKit)}
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    onChange={(state: any) => setTypeKit(state.value)}
                    isSearchable={false}
                  />
                </Styles.ContainerForm>
              )}
              <div style={{ height: 40 }} />
              <Styles.ContainerForm>
                <Input
                  // eslint-disable-next-line
                  onChange={(e: any) => setTechnicalComment(e.target.value)}
                  name="technical_comments"
                  value={technicalComment}
                  autoComplete="new-password"
                  label="Observações do Técnico"
                  width="100%"
                  type="text"
                />
                {(isFranchisee && !signatureContract && !technicalVisitStep) ||
                isAdmin ? (
                  <Button
                    onClick={() =>
                      saveProjectInfo({ technical_comment: technicalComment })
                    }
                    loading={!technicalComment}
                    size="default"
                    type="button"
                  >
                    Salvar
                  </Button>
                ) : (
                  ''
                )}
              </Styles.ContainerForm>
            </Styles.FormTechnicalComment>
            {clientSimulatedPlanValues && (
              <S.ContainerLegal>
                <S.LabelContainer>
                  <label>
                    {client.subscription_lending !== 'LENDING'
                      ? 'Simulação do Plano'
                      : 'Simulação da proposta final'}
                  </label>
                </S.LabelContainer>
                {client.subscription_lending !== 'LENDING' && (
                  <PlanGroup simulationValues={clientSimulatedPlanValues} />
                )}
                {client.subscription_lending === 'LENDING' &&
                  (clientProjectInfo?.specific_production || 1) > 500 && (
                    <PlanGroupProposal
                      simulationValues={
                        proposalSimulation != null ? proposalSimulation : null
                      }
                    />
                  )}
              </S.ContainerLegal>
            )}
            <S.GroupButton>
              <Button
                full
                size="default"
                type="button"
                onClick={() => dispatch(CreatorsVisit.setIsReportFour(false))}
              >
                Voltar
              </Button>
              <Button
                full
                color="primary"
                size="default"
                disabled={
                  (clientProjectInfo?.specific_production || 1) < 500 &&
                  client.subscription_lending === 'LENDING'
                }
                onClick={() => {
                  if (client.subscription_lending !== 'LENDING') {
                    generatePlans(
                      clientSimulatedPlanValues?.overload,
                      clientSimulatedPlanValues?.project_indicators.kwp_price,
                      clientSimulatedPlanValues?.plan?.low
                        ?.estimated_annual_savings?.percent,
                      clientSimulatedPlanValues?.plan?.med
                        ?.estimated_annual_savings?.percent,
                      clientSimulatedPlanValues?.plan?.max
                        ?.estimated_annual_savings?.percent,
                    )
                  } else {
                    const optimizerQuantity =
                      clientProjectInfo?.optimizer_quantity || 0
                    const moduleQuantity =
                      proposalSimulation?.module_quantity || 1

                    if (optimizerQuantity > moduleQuantity) {
                      toast.error(
                        'Número de otimizadores precisa ser menor ou igual ao número de módulos do projeto!',
                      )
                    } else {
                      // eslint-disable-next-line no-lonely-if
                      if (
                        (isFranchisee &&
                          !signatureContract &&
                          !technicalVisitStep) ||
                        isAdmin
                      ) {
                        createFinalProposal()
                      } else {
                        toast.error('Contrato definitivo já foi gerado!')
                      }
                    }
                  }
                }}
              >
                {client.subscription_lending !== 'LENDING'
                  ? 'Gerar Plano'
                  : 'Gerar contrato definitivo'}
              </Button>
            </S.GroupButton>
          </>
        )}
      </S.Container>
    </>
  )
}

export { ReportFour }
