import React, { useRef, useCallback, useEffect } from 'react'
import Calendar, { OnChangeDateCallback } from 'react-calendar'

import moment from 'moment'

import CalendarIcon from '@/assets/images/icons/ic-calendar.svg'

import {
  Container,
  ContainerCalendar,
  ButtonCheck,
  CalendarImage,
  ContainerButton,
} from './styles'

interface IButtonCalendarProps {
  onClick: () => void
  onClose: () => void
  onChange: (selectedDate: Date) => void
  isOpen: boolean
  labelButton: string
  width?: string
  disabled?: boolean
  value?: Date
}

const ButtonCalendar: React.FC<IButtonCalendarProps> = ({
  onClick,
  isOpen,
  labelButton,
  onClose,
  width,
  disabled,
  onChange,
  value,
}) => {
  const ref = useRef<HTMLHeadingElement>(null)

  const handleKeypressEsc = useCallback(
    e => {
      if (e.key === 'Escape' && isOpen) onClose()
    },
    [isOpen, onClose],
  )

  const handleClickOut = useCallback(
    e => {
      if (!ref.current?.contains(e.target) && isOpen) {
        onClose()
      }
    },
    [isOpen, onClose],
  )

  useEffect(() => {
    window.addEventListener('click', handleClickOut)
    window.addEventListener('keydown', handleKeypressEsc)

    return () => {
      window.removeEventListener('click', handleClickOut)
      window.removeEventListener('keydown', handleKeypressEsc)
    }
  }, [handleKeypressEsc, handleClickOut])

  const handleChangeDate: OnChangeDateCallback = selectedDate => {
    if (!Array.isArray(selectedDate)) onChange(selectedDate)
  }

  return (
    <>
      <ContainerButton width={width || '140px'}>
        <ButtonCheck disabled={disabled} onClick={onClick}>
          {value ? moment(value).format('DD/MM/YYYY') : labelButton}
          <CalendarImage src={CalendarIcon} alt="Solar 21" />
        </ButtonCheck>
      </ContainerButton>
      <Container ref={ref} isOpen={isOpen}>
        <ContainerCalendar>
          <Calendar
            value={value || moment().toDate()}
            onChange={handleChangeDate}
          />
        </ContainerCalendar>
      </Container>
    </>
  )
}

export { ButtonCalendar }
