import { shade } from 'polished'
import styled from 'styled-components'

import Button from '@material-ui/core/Button'

interface Props {
  width?: number
  bgColor: string
  fontColor: string
}

export const ButtonStyled = styled(Button)<Props>(
  ({ width, bgColor, fontColor }) => ({
    backgroundColor: `${bgColor} !important;`,
    borderColor: `${bgColor} !important;`,
    width,
    height: 'auto',
    fontWeight: 'bold',
    fontSize: '10px',
    color: `${fontColor} !important;`,
    marginBottom: '15px',
    '&:hover': {
      background: `${shade(0.1, bgColor)} !important;`,
    },
  }),
)
