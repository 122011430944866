import styled from 'styled-components'
import { layout } from 'styled-system'

import theme from '@/assets/styles/theme'

export interface IPropsCheckBox {
  className?: string
  checked: boolean
  label?: string | React.ReactNode
  onClick: () => void
}

export const CheckboxContainer = styled.div`
  display: flex;
  align-items: center;
  vertical-align: middle;
  cursor: pointer;
`

export const Icon = styled.svg`
  fill: none;
  stroke: white;
  stroke-width: 2px;
`

export const HiddenCheckbox = styled.input.attrs({ type: 'checkbox' })`
  border: 0;
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`

export const StyledCheckbox = styled.div<IPropsCheckBox>`
  display: inline-block;
  transition: all 150ms;
  ${HiddenCheckbox}:focus + & {
    box-shadow: 0 0 6px ${theme.colors.blue};
  }
  width: 20px;
  height: 20px;
  background: ${(props: { checked: boolean }) =>
    props.checked ? theme.colors.primary : theme.colors.white};
  border: 2px solid
    ${(props: { checked: boolean }) =>
      props.checked ? theme.colors.primary : theme.colors.gray[300]};
  border-radius: 3px;
  ${layout}
  ${Icon} {
    visibility: ${(props: { checked: boolean }) =>
      props.checked ? 'visible' : 'hidden'};
  }
`
export const Label = styled.div<IPropsCheckBox>`
  color: #000000bf;
  margin-left: 10px;
  font-weight: 500;
  font-size: 16px;
  font-family: 'Roboto', sans-serif;
  ${Icon} {
    visibility: ${(IPropsCheckBox: { checked: boolean }) =>
      IPropsCheckBox.checked ? 'visible' : 'hidden'};
  }
`
