import { ClientStepTypeEnum } from '@/store/ducks/Clients/types'

type IStepStyle = {
  [x in ClientStepTypeEnum]: {
    text: string
    color: string
    backgroundColor: string
  }
}

const stepStyles: IStepStyle = {
  [ClientStepTypeEnum.SIMULATION]: {
    text: 'Simulação',
    color: '#FF7600',
    backgroundColor: '#FFE2CF',
  },
  [ClientStepTypeEnum.BILL]: {
    text: 'Conta de luz',
    color: '#FF7600',
    backgroundColor: '#FFE2CF',
  },
  [ClientStepTypeEnum.BILL_PENDING]: {
    text: 'Conta de luz',
    color: '#FF7600',
    backgroundColor: '#FFE2CF',
  },
  [ClientStepTypeEnum.BILL_DENIED]: {
    text: 'Conta de luz',
    color: '#FF7600',
    backgroundColor: '#FFE2CF',
  },
  [ClientStepTypeEnum.BILL_NOT_DONE]: {
    text: 'Conta de luz',
    color: '#FF7600',
    backgroundColor: '#FFE2CF',
  },
  [ClientStepTypeEnum.TECHNICAL_VISIT]: {
    text: 'Visita técnica',
    color: '#FF7600',
    backgroundColor: '#FFE2CF',
  },
  [ClientStepTypeEnum.TECHNICAL_VISIT_FULFILLED]: {
    text: 'Visita técnica',
    color: '#43CC00',
    backgroundColor: '#CFF3BC',
  },
  [ClientStepTypeEnum.TECHNICAL_VISIT_CONFIRMED_SCHEDULE]: {
    text: 'Visita técnica',
    color: '#FFC209',
    backgroundColor: '#FFF0C2 ',
  },
  [ClientStepTypeEnum.TECHNICAL_VISIT_DONE_AND_NOT_APPROVED]: {
    text: 'Visita técnica',
    color: '#ff041a',
    backgroundColor: '#ffbec3',
  },
  [ClientStepTypeEnum.SIGNATURE]: {
    text: 'Assinatura',
    color: '#FF7600',
    backgroundColor: '#FFE2CF',
  },
  [ClientStepTypeEnum.SIGNATURE_APPROVED]: {
    text: 'Assinatura',
    color: '#43CC00',
    backgroundColor: '#CFF3BC',
  },
  [ClientStepTypeEnum.SIGNATURE_DENIED]: {
    text: 'Assinatura',
    color: '#ff041a',
    backgroundColor: '#ffbec3',
  },
  [ClientStepTypeEnum.CHECKOUT]: {
    text: 'Pagamento',
    color: '#FF7600',
    backgroundColor: '#FFE2CF',
  },
  [ClientStepTypeEnum.CHECKOUT_APPROVED]: {
    text: 'Pagamento',
    color: '#43CC00',
    backgroundColor: '#CFF3BC',
  },
  [ClientStepTypeEnum.CHECKOUT_DENIED]: {
    text: 'Pagamento',
    color: '#ff041a',
    backgroundColor: '#ffbec3',
  },
  [ClientStepTypeEnum.INSTALATION]: {
    text: 'Aguardando instalação',
    color: '#FF7600',
    backgroundColor: '#FFE2CF ',
  },
  [ClientStepTypeEnum.CONTRACT]: {
    text: 'Contrato',
    color: '#FF7600',
    backgroundColor: '#FFE2CF ',
  },
  [ClientStepTypeEnum.CONTRACT_APPROVED]: {
    text: 'Contrato',
    color: '#43CC00',
    backgroundColor: '#CFF3BC',
  },
  [ClientStepTypeEnum.CONTRACT_DENIED]: {
    text: 'Contrato',
    color: '#ff041a',
    backgroundColor: '#ffbec3',
  },
  [ClientStepTypeEnum.ACTIVE]: {
    text: 'Ativo',
    color: '#43CC00',
    backgroundColor: '#CFF3BC ',
  },
  [ClientStepTypeEnum.PROPOSAL_INITIAL]: {
    text: 'Proposta Inicial',
    color: '#FF7600',
    backgroundColor: '#FFE2CF ',
  },
  [ClientStepTypeEnum.PROPOSAL_FINAL]: {
    text: 'Proposta Final',
    color: '#FF7600',
    backgroundColor: '#FFE2CF ',
  },
  [ClientStepTypeEnum.SUPPLY]: {
    text: 'Supply',
    color: '#FF7600',
    backgroundColor: '#FFE2CF ',
  },
}

export const getClientStepStyle = (
  stepType: ClientStepTypeEnum,
  status?: string,
  status_tech?: string,
  status_signature?: string,
  status_checkout?: string,
  status_contract?: string,
) => {
  if (stepType === ClientStepTypeEnum.BILL) {
    if (status === 'PENDING') {
      return {
        text: 'Conta de Luz',
        color: '#43CC00',
        backgroundColor: '#CFF3BC',
      }
    }
    if (status === 'NOT_DONE') {
      return {
        text: 'Conta de Luz',
        color: '#FF7600',
        backgroundColor: '#FFE2CF',
      }
    }
    if (status === 'DENIED') {
      return {
        text: 'Conta de Luz',
        color: '#ff041a',
        backgroundColor: '#ffbec3',
      }
    }
  } else if (stepType === ClientStepTypeEnum.TECHNICAL_VISIT) {
    if (status_tech) {
      if (status_tech === 'FULFILLED')
        return {
          text: 'Visita Técnica',
          color: '#43CC00',
          backgroundColor: '#CFF3BC ',
        }
      if (status_tech === 'DONE_AND_NOT_APPROVED')
        return {
          text: 'Visita Técnica',
          color: '#ff041a',
          backgroundColor: '#ffbec3 ',
        }
      if (status_tech === 'WAITING_FOR_TECHNICIAN')
        return {
          text: 'Visita Técnica',
          color: '#FF7600',
          backgroundColor: '#FFE2CF',
        }
      if (status_tech === 'CONFIRMED_SCHEDULE')
        return {
          text: 'Visita Técnica',
          color: '#FFC209',
          backgroundColor: '#FFF0C2 ',
        }

      return {
        text: 'Visita Técnica',
        color: '#FFC209',
        backgroundColor: '#FFF0C2 ',
      }
    }
    return {
      text: 'Visita Técnica',
      color: '#FF7600',
      backgroundColor: '#FFE2CF',
    }
  } else if (stepType === ClientStepTypeEnum.SIGNATURE) {
    if (status_signature) {
      if (status_signature === 'NOT_DONE') {
        return {
          text: 'Assinatura',
          color: '#ff041a',
          backgroundColor: '#ffbec3 ',
        }
      }
      if (status_signature === 'DENIED') {
        return {
          text: 'Assinatura',
          color: '#ff041a',
          backgroundColor: '#ffbec3 ',
        }
      }
      if (status_signature === 'APPROVED') {
        return {
          text: 'Assinatura',
          color: '#43CC00',
          backgroundColor: '#CFF3BC ',
        }
      }

      return {
        text: 'Assinatura',
        color: '#FFC209',
        backgroundColor: '#FFF0C2 ',
      }
    }
    return {
      text: 'Assinatura',
      color: '#FF7600',
      backgroundColor: '#FFE2CF',
    }
  } else if (stepType === ClientStepTypeEnum.CHECKOUT) {
    if (status_checkout) {
      if (status_checkout === 'NOT_DONE') {
        return {
          text: 'Pagamento',
          color: '#ff041a',
          backgroundColor: '#ffbec3 ',
        }
      }
      if (status_checkout === 'DENIED') {
        return {
          text: 'Pagamento',
          color: '#ff041a',
          backgroundColor: '#ffbec3 ',
        }
      }
      if (status_checkout === 'APPROVED') {
        return {
          text: 'Pagamento',
          color: '#43CC00',
          backgroundColor: '#CFF3BC ',
        }
      }

      return {
        text: 'Pagamento',
        color: '#FFC209',
        backgroundColor: '#FFF0C2 ',
      }
    }
    return {
      text: 'Pagamento',
      color: '#FF7600',
      backgroundColor: '#FFE2CF',
    }
  } else if (stepType === ClientStepTypeEnum.CONTRACT) {
    if (status_contract) {
      if (status_contract === 'NOT_DONE') {
        return {
          text: 'Contrato',
          color: '#ff041a',
          backgroundColor: '#ffbec3 ',
        }
      }
      if (status_contract === 'DENIED') {
        return {
          text: 'Contrato',
          color: '#ff041a',
          backgroundColor: '#ffbec3 ',
        }
      }
      if (status_contract === 'APPROVED') {
        return {
          text: 'Contrato',
          color: '#43CC00',
          backgroundColor: '#CFF3BC ',
        }
      }

      return {
        text: 'Contrato',
        color: '#FFC209',
        backgroundColor: '#FFF0C2 ',
      }
    }
    return {
      text: 'Contrato',
      color: '#FF7600',
      backgroundColor: '#FFE2CF',
    }
  }
  return stepStyles[stepType]
}
