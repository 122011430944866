/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/jsx-wrap-multilines */
import React, { useEffect, useRef, useState } from 'react'
import { BsPencil } from 'react-icons/bs'
import { MdVisibility, MdFileDownload } from 'react-icons/md'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'

import { AxiosRequestConfig } from 'axios'
import { isEmpty } from 'lodash'
import moment from 'moment'

import { ClientDocumentStatusEnum } from '@/@types/clientDocument/clientDocumentStatusEnum'
import { IObjectLiteral } from '@/@types/common/object-literal'
import { planType } from '@/@types/planType'
import { FullScreenLoader } from '@/components/FullScreenLoader'
import { Plan } from '@/components/Plan/Plan'
import { Table } from '@/components/Table/Table'
import Button from '@/components/Ui/Button'
import { ButtonEdit } from '@/components/Ui/ButtonEdit'
import { DropdownBootstrap } from '@/components/Ui/Dropdown'
import Input from '@/components/Ui/Form/Input'
import { getDocumentName } from '@/modules/Customer/utils/getClientDocumentName'
import api from '@/services/api'
import history from '@/services/history'
import { RootState } from '@/store'
import { BillCreators as CreatorsBill } from '@/store/ducks/Bill'
import { Creators } from '@/store/ducks/Clients'
import { insertBRNumberMask } from '@/utils/insertNumberMask/insertBRNumberMask'
import { insertCpfMask } from '@/utils/insertNumberMask/insertCpfMask'
import { insertPhoneMask } from '@/utils/insertNumberMask/insertPhoneMask'
import { Chip } from '@material-ui/core'
import { Form } from '@unform/web'

import { EditBillOwner } from './EditBillOwner'
import { EditClientLegal } from './EditClientLegal'
import {
  Container,
  LabelContainer,
  ContainerPlan,
  TitleBillValue,
  ContainerLegal,
  ContainerColumn,
  Fields,
  FieldsLabel,
  Font,
  ContainerClient,
  ContainerBillOwner,
  ContainerButtons,
  ContainerSaveButton,
  DocumentTitle,
  NoSelectedPlanContainer,
} from './styles'

type StatusTypes = 'approved' | 'pending' | 'reproved'

interface IDocumentStatus {
  id: string
  status: StatusTypes
}

const Signature = () => {
  const dispatch = useDispatch()

  const [isEditContract, setEditContract] = React.useState<boolean>(false)
  const [isLending, setIsLending] = useState(false)
  const [contractNumber, setContractNumber] = React.useState<string>('')

  const isEditClientLegal = useSelector(
    (state: RootState) => state.Client.isEditClientLegal,
  )

  const isEditBillOwner = useSelector(
    (state: RootState) => state.Bill.isEditBillOwner,
  )

  const client = useSelector((state: RootState) => state.Client.client)

  React.useEffect(() => {
    if (client?.contracts?.length) {
      const contract_of_adhesion = client.contracts?.find(
        element => element.name === 'Termo de Adesão',
      )
      if (contract_of_adhesion) {
        setContractNumber(contract_of_adhesion.contract_number || '')
      }
    }
  }, [client])

  const clientPlan = useSelector((state: RootState) => state.Client.clientPlan)

  const clientLegal = useSelector(
    (state: RootState) => state.Client.clientLegal,
  )

  const billOwner = useSelector((state: RootState) => state.Bill.billOwner)

  const isLoading = useSelector(
    (state: RootState) => state.Client.isLoading || state.Bill.loading.get,
  )

  const clientDocuments = useSelector(
    (state: RootState) => state.Client.clientDocuments,
  )
  const isClientTypeCpf = client.type.type === 'CPF'

  const statusDocument: { [key in ClientDocumentStatusEnum]: StatusTypes } = {
    [ClientDocumentStatusEnum.APPROVED]: 'approved',
    [ClientDocumentStatusEnum.DENIED]: 'reproved',
    [ClientDocumentStatusEnum.PENDING]: 'pending',
  }

  const [
    clientDocumentSelectOptions,
    setClientDocumentSelectOptions,
  ] = useState<IDocumentStatus[]>([])

  useEffect(() => {
    dispatch(Creators.getClientPlanRequest({ id: client.id }))
    dispatch(Creators.getClientLegalRequest({ id: client.id }))
    dispatch(CreatorsBill.getBillOwnerRequest({ id: client.id }))
    dispatch(Creators.getClientDocumentsRequest({ id: client.id }))
    if (client.subscription_lending === 'LENDING') setIsLending(true)
  }, [
    client.id,
    dispatch,
    isEditClientLegal,
    isEditBillOwner,
    client.subscription_lending,
  ])

  useEffect(() => {
    setClientDocumentSelectOptions(
      clientDocuments.map(clientDocument => ({
        id: clientDocument.id,
        status: statusDocument[clientDocument.status],
      })),
    )
    // eslint-disable-next-line
  }, [clientDocuments])

  const columns = [
    {
      title: 'Tipo do Documento',
      dataIndex: 'documentType',
      key: 'documentType',
      width: 100,
    },
    {
      title: 'Data do Documento',
      dataIndex: 'documentDate',
      key: 'documentDate',
      width: 100,
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      width: 100,
    },
    {
      title: '',
      dataIndex: 'icons',
      key: 'icons',
      width: 50,
    },
  ]

  const getFilename = (url: string): string =>
    url.split('/')[url.split('/').length - 1]

  const download = (url: string) => {
    fetch(url, {
      method: 'GET',
      headers: {},
    }).then(response => {
      response.arrayBuffer().then(buffer => {
        const newUrl = window.URL.createObjectURL(new Blob([buffer]))
        const link = document.createElement('a')
        link.href = newUrl
        link.setAttribute('download', getFilename(url))
        document.body.appendChild(link)
        link.click()
      })
    })
  }

  const dropdownOptions = [
    {
      label: 'Aprovado',
      value: 'approved',
    },
    {
      label: 'Reprovado',
      value: 'reproved',
    },
  ]

  const clientDocumentHandled = clientDocuments?.map(clientDocument => {
    return {
      documentType: (
        <DocumentTitle>{getDocumentName(clientDocument.type)}</DocumentTitle>
      ),
      documentDate: moment(clientDocument.created_at).format('DD/MM/YYYY'),
      status: (
        <DropdownBootstrap
          items={dropdownOptions}
          defaultLabel="Em análise"
          isDisabled={
            clientDocument.status !== ClientDocumentStatusEnum.PENDING
          }
          handleSelect={(...args) => {
            const statusClicked = args[0]

            const documentsHandled = clientDocumentSelectOptions.map(
              clientDocumentSelectOption => {
                if (clientDocumentSelectOption.id === clientDocument.id) {
                  return {
                    id: clientDocument.id,
                    status: statusClicked,
                  }
                }
                return clientDocumentSelectOption
              },
            )

            setClientDocumentSelectOptions(documentsHandled)
          }}
          value={
            clientDocumentSelectOptions.find(
              clientDocumentSelectOption =>
                clientDocumentSelectOption.id === clientDocument.id,
            )?.status
          }
        />
      ),
      icons: (
        <div style={{ display: 'flex', justifyContent: 'space-around' }}>
          <Chip
            variant="outlined"
            size="medium"
            label="Frente do documento"
            avatar={
              <MdVisibility
                size="30px"
                style={{ cursor: 'pointer', color: '#000' }}
                onClick={() => window.open(clientDocument.image_url, '_blank')}
              />
            }
            onDelete={() => download(clientDocument.image_url)}
            deleteIcon={
              <>
                <MdFileDownload size="30px" style={{ cursor: 'pointer' }} />
              </>
            }
          />
          {!!clientDocument.image_back_url && (
            <Chip
              variant="outlined"
              size="medium"
              label="Verso do documento"
              avatar={
                <MdVisibility
                  size="30px"
                  style={{ cursor: 'pointer', color: '#000' }}
                  onClick={() =>
                    window.open(clientDocument.image_back_url, '_blank')
                  }
                />
              }
              onDelete={() => download(clientDocument.image_back_url)}
              deleteIcon={
                <>
                  <MdFileDownload size="30px" style={{ cursor: 'pointer' }} />
                </>
              }
            />
          )}
        </div>
      ),
    }
  })

  const editForm = () => {
    if (isEditClientLegal) {
      return (
        <ContainerLegal>
          <LabelContainer>
            <label>Dados do Representante Legal</label>
            {!isEditClientLegal && (
              <ButtonEdit
                onClick={() => dispatch(Creators.setIsEditClientLegal(true))}
              />
            )}
          </LabelContainer>

          <EditClientLegal
            onClickToCancel={() =>
              dispatch(Creators.setIsEditClientLegal(false))
            }
          />
        </ContainerLegal>
      )
    }
    return (
      <ContainerLegal>
        <LabelContainer>
          <label>Dados do Titular da Unidade Consumidora</label>
          {!isEditBillOwner && (
            <ButtonEdit
              onClick={() => dispatch(CreatorsBill.setIsEditBillOwner(true))}
            />
          )}
        </LabelContainer>
        <EditBillOwner
          onClickToCancel={() =>
            dispatch(CreatorsBill.setIsEditBillOwner(false))
          }
        />
      </ContainerLegal>
    )
  }

  const handleSaveDocumentsStatus = () => {
    const status: IObjectLiteral<ClientDocumentStatusEnum> = {
      approved: ClientDocumentStatusEnum.APPROVED,
      reproved: ClientDocumentStatusEnum.DENIED,
      pending: ClientDocumentStatusEnum.PENDING,
    }

    const clientDocumentsData = clientDocumentSelectOptions
      .filter(clientDocumentSelectOption =>
        clientDocuments.some(
          clientDocument =>
            clientDocument.id === clientDocumentSelectOption.id &&
            clientDocument.status === ClientDocumentStatusEnum.PENDING,
        ),
      )
      .map(clientDocumentSelectOption => ({
        id: clientDocumentSelectOption.id,
        status: status[clientDocumentSelectOption.status],
      }))
    dispatch(Creators.editClientDocumentsStatusRequest(clientDocumentsData))
  }

  const [updatingContract, setUpdatingContract] = React.useState<boolean>(false)

  const submitContractNumber = (form: { contract_number: string }) => {
    setUpdatingContract(true)
    api
      .put(`/admin/customers/contracts/${client.id}/code`, {
        contract_code: form.contract_number,
      })
      // eslint-disable-next-line
      .then(({ data }) => {
        setEditContract(false)
        dispatch(Creators.getClientRequest({ id: client.id }))
      })
      .finally(() => setUpdatingContract(false))
  }

  const downloadContract = (type: 'adesao' | 'procuracao' | 'buy') => {
    const contract_of_adhesion = client.contracts?.find(
      element => element.name === 'Termo de Adesão',
    )
    const power_of_attorney = client.contracts?.find(
      element => element.name === 'Procuração',
    )
    const buy_contract = client.contracts?.find(
      element => element.name === 'Contrato de compra',
    )

    const adhesion_url = contract_of_adhesion?.contract_private_url
    const attorney_url = power_of_attorney?.contract_private_url
    const buy_url = buy_contract?.contract_private_url

    if (!adhesion_url && type === 'adesao') {
      toast.error('Não há Termo de Adesão assinado')
      return
    }
    if (!attorney_url && type === 'procuracao') {
      toast.error('Não há Procuração assinada')
      return
    }
    if (!buy_url && type === 'buy') {
      toast.error('Não há contrato de compra assinada')
      return
    }

    if (type === 'adesao' && adhesion_url) window.open(adhesion_url, '_blank')
    if (type === 'procuracao' && attorney_url) {
      window.open(attorney_url, '_blank')
    }
    if (type === 'buy' && buy_url) {
      window.open(buy_url, '_blank')
    }
  }

  const fileInputRef = useRef<HTMLInputElement>(null)

  const fileSelected = async (event: any) => {
    const file = event.target.files[0]
    const formData = new FormData()
    formData.append('document', file)

    const headerParams = {
      'Access-Control-Allow-Headers': 'X-Requested-With',
      'Content-Type': 'multipart/form-data',
    }
    await api
      .post(
        `/admin/customers/documents/proposal/${client.id}`,
        formData,
        headerParams as AxiosRequestConfig,
      )
      .then(() => toast.success('Documento enviado com sucesso!'))
      .catch(err => console.error(err))
      .finally(() => window.location.reload())
  }

  const openFileSelectionDialog = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click()
    }
  }
  const [signedContract, setSignedContract] = useState<boolean>(false)
  const [signedContractAttorney, setSignedContractAttorney] = useState<boolean>(
    false,
  )

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const checkContract = () => {
    if (isEmpty(client.contracts)) return
    const contract = client.contracts?.find(
      (contract: any) => contract.name === 'Contrato de compra',
    )

    api
      .get(`admin/check_signers_by_document/${contract?.key}`)
      .then(({ data }: any) => {
        if (data.is_signed) setSignedContract(true)
      })
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const checkAttorney = () => {
    if (isEmpty(client.contracts)) return
    const contract = client.contracts?.find(
      (contract: any) => contract.name === 'Procuração',
    )

    api
      .get(`admin/check_signers_by_document/${contract?.key}`)
      .then(({ data }: any) => {
        if (data.is_signed) setSignedContractAttorney(true)
      })
  }

  useEffect(() => {
    checkAttorney()
    checkContract()
  }, [checkAttorney, checkContract])

  return (
    <>
      {isLoading && <FullScreenLoader />}

      {isEditClientLegal || isEditBillOwner ? (
        editForm()
      ) : (
        <Container>
          <>
            {clientPlan ? (
              <div>
                <LabelContainer>
                  <label>Plano Definitivo</label>
                </LabelContainer>

                <ContainerPlan>
                  <Plan
                    planName={planType[clientPlan.plan.type]}
                    planPrice={insertBRNumberMask(parseFloat(clientPlan.price))}
                  />
                  <div>
                    <TitleBillValue>
                      Prazo do plano:{' '}
                      <span>
                        {clientPlan.deadline}
                        anos
                      </span>
                    </TitleBillValue>
                  </div>
                  <div>
                    <TitleBillValue>
                      Economia estimada anual:{' '}
                      <span>
                        {'R$ '}
                        {insertBRNumberMask(
                          parseFloat(clientPlan.annual_savings),
                        )}
                      </span>
                    </TitleBillValue>
                  </div>

                  <div className="signed-documents">
                    <hr />
                    <h3
                      style={{
                        textAlign: 'center',
                        marginTop: 5,
                        marginBottom: 10,
                        fontSize: 22,
                      }}
                    >
                      Download dos documentos assinados
                    </h3>

                    <div
                      id="btn-contract-download"
                      style={{
                        display: 'flex',
                        justifyContent: 'space-evenly',
                      }}
                    >
                      <Button
                        onClick={() =>
                          downloadContract(isLending ? 'buy' : 'adesao')
                        }
                        color="primary"
                        size="default"
                      >
                        {isLending ? 'Contrato de compra' : 'Termo de Adesão'}
                      </Button>
                      <Button
                        onClick={() => downloadContract('procuracao')}
                        color="primary"
                        size="default"
                      >
                        Procuração
                      </Button>
                    </div>
                  </div>
                </ContainerPlan>
              </div>
            ) : (
              <>
                {!isLending ? (
                  <NoSelectedPlanContainer>
                    <h1>Não há um plano selecionado.</h1>
                  </NoSelectedPlanContainer>
                ) : (
                  <></>
                )}
              </>
            )}
            {isLending && signedContract && signedContractAttorney ? (
              <div>
                <ContainerPlan>
                  <div className="signed-documents">
                    <h3
                      style={{
                        textAlign: 'center',
                        marginTop: 5,
                        marginBottom: 10,
                        fontSize: 22,
                      }}
                    >
                      Download dos documentos assinados
                    </h3>

                    <div
                      id="btn-contract-download"
                      style={{
                        display: 'flex',
                        justifyContent: 'space-evenly',
                      }}
                    >
                      <Button
                        onClick={() =>
                          downloadContract(isLending ? 'buy' : 'adesao')
                        }
                        color="primary"
                        size="default"
                      >
                        {isLending ? 'Contrato de compra' : 'Termo de Adesão'}
                      </Button>
                      <Button
                        onClick={() => downloadContract('procuracao')}
                        color="primary"
                        size="default"
                      >
                        Procuração
                      </Button>
                    </div>
                  </div>
                </ContainerPlan>
              </div>
            ) : (
              <>
                {isLending ? (
                  <NoSelectedPlanContainer>
                    <h1>O contrato e a procuração não foram assinados</h1>
                  </NoSelectedPlanContainer>
                ) : (
                  <></>
                )}
              </>
            )}
            {!isClientTypeCpf ? (
              <ContainerLegal>
                <LabelContainer>
                  <label>Dados do Representante Legal</label>
                  <ButtonEdit
                    onClick={() =>
                      dispatch(Creators.setIsEditClientLegal(true))
                    }
                  />
                </LabelContainer>
                <ContainerClient>
                  <ContainerColumn>
                    <FieldsLabel>
                      <Fields>
                        <Font>Nome completo: </Font> {clientLegal?.name}
                      </Fields>
                    </FieldsLabel>
                    <FieldsLabel>
                      <Fields>
                        <Font>CPF:</Font>{' '}
                        {clientLegal?.legal_document &&
                          insertCpfMask(clientLegal.legal_document)}
                      </Fields>
                    </FieldsLabel>
                    <FieldsLabel>
                      <Fields>
                        <Font>Email: </Font> {clientLegal?.email}
                      </Fields>
                    </FieldsLabel>
                  </ContainerColumn>
                  <ContainerColumn>
                    <FieldsLabel>
                      <Fields>
                        <Font>Telefone: </Font>
                        {clientLegal?.phone &&
                          insertPhoneMask(clientLegal.phone)}
                      </Fields>
                    </FieldsLabel>
                    <FieldsLabel>
                      <Fields>
                        <Font>Estado: </Font> {clientLegal?.state}
                      </Fields>
                    </FieldsLabel>
                    <FieldsLabel>
                      <Fields>
                        <Font>Cidade: </Font> {clientLegal?.city}
                      </Fields>
                    </FieldsLabel>
                  </ContainerColumn>
                </ContainerClient>
              </ContainerLegal>
            ) : (
              <ContainerBillOwner>
                <LabelContainer>
                  <label>Dados do Titular da Unidade Consumidora</label>
                  <ButtonEdit
                    onClick={() =>
                      dispatch(CreatorsBill.setIsEditBillOwner(true))
                    }
                  />
                </LabelContainer>
                <ContainerClient>
                  <ContainerColumn>
                    <FieldsLabel>
                      <Fields>
                        <Font>Nome completo: </Font> {billOwner?.name}
                      </Fields>
                    </FieldsLabel>
                    <FieldsLabel>
                      <Fields>
                        <Font>CPF: </Font>{' '}
                        {billOwner?.document_value &&
                          insertCpfMask(billOwner.document_value)}
                      </Fields>
                    </FieldsLabel>
                    <FieldsLabel>
                      <Fields>
                        <Font>Email: </Font> {billOwner?.email}
                      </Fields>
                    </FieldsLabel>
                  </ContainerColumn>
                  <ContainerColumn>
                    <FieldsLabel>
                      <Fields>
                        <Font>Telefone: </Font>{' '}
                        {billOwner?.phone && insertPhoneMask(billOwner.phone)}
                      </Fields>
                    </FieldsLabel>
                    <FieldsLabel>
                      <Fields>
                        <Font>Estado Civil: </Font>
                        {billOwner?.marital_status}
                      </Fields>
                    </FieldsLabel>
                    <FieldsLabel>
                      <Fields>
                        <Font>Profissão: </Font> {billOwner?.occupation}
                      </Fields>
                    </FieldsLabel>
                  </ContainerColumn>

                  <ContainerColumn>
                    <FieldsLabel>
                      <Fields>
                        {isEditContract ? (
                          <Form
                            onSubmit={submitContractNumber}
                            initialData={{ contract_number: contractNumber }}
                          >
                            <Input
                              disabled={updatingContract}
                              name="contract_number"
                              label="Número do contrato"
                              width="100%"
                              placeholder="Número do Contrato"
                            />
                            <ContainerButtons
                              style={{
                                paddingTop: '2px',
                                paddingBottom: '100px',
                              }}
                            >
                              <ContainerSaveButton
                                style={{ paddingRight: '10px' }}
                              >
                                <Button
                                  color="primary"
                                  size="small"
                                  type="submit"
                                  disabled={updatingContract}
                                >
                                  Salvar
                                </Button>
                              </ContainerSaveButton>
                              <Button
                                onClick={() => setEditContract(false)}
                                size="small"
                                type="button"
                                disabled={updatingContract}
                                style={{ backgroundColor: '#fff' }}
                              >
                                Voltar
                              </Button>
                            </ContainerButtons>
                          </Form>
                        ) : (
                          <>
                            <Font>Número do Contrato: </Font>
                            {contractNumber || ''}
                            <BsPencil
                              size={19}
                              // eslint-disable-next-line no-alert
                              onClick={() => setEditContract(true)}
                              style={{ marginLeft: '10px', cursor: 'pointer' }}
                            />
                          </>
                        )}
                      </Fields>
                    </FieldsLabel>
                  </ContainerColumn>
                </ContainerClient>
              </ContainerBillOwner>
            )}
          </>

          <div>
            <Table columns={columns} data={clientDocumentHandled} />
            {clientDocumentHandled.length === 0 ? (
              <>
                <input
                  ref={fileInputRef}
                  style={{ display: 'none' }}
                  type="file"
                  onChange={fileSelected}
                />
                <Button
                  size="default"
                  style={{ width: '100%' }}
                  onClick={openFileSelectionDialog}
                >
                  Enviar documento frente e verso
                </Button>
              </>
            ) : (
              <></>
            )}
          </div>

          <ContainerButtons>
            <Button size="default" onClick={() => history.push('/customer')}>
              Voltar
            </Button>
            <ContainerSaveButton>
              <Button
                onClick={handleSaveDocumentsStatus}
                color="primary"
                size="default"
              >
                Salvar alterações
              </Button>
            </ContainerSaveButton>
          </ContainerButtons>
        </Container>
      )}
    </>
  )
}

export { Signature }
