/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  useState,
  useEffect,
  useCallback,
  useRef,
  ChangeEvent,
} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'

import * as Yup from 'yup'

import {
  TypeDocumentEnum,
  TypeConsumptionClassEnum,
  TypeAlimentationClassEnum,
} from '@/@types/client/client'
import Button from '@/components/Ui/Button'
import Input from '@/components/Ui/Form/Input'
import { InputNumberMask } from '@/components/Ui/InputNumberMask'
import { Select } from '@/components/Ui/Select'
import { getAddressByPostalCode } from '@/providers/addresses/getAddressByPostalCode'
import api from '@/services/api'
import { RootState } from '@/store'
import { BillCreators } from '@/store/ducks/Bill'
import { CitiesCreators } from '@/store/ducks/Cities'
import { CommonCreators } from '@/store/ducks/common'
import { Switch, FormControlLabel } from '@material-ui/core'
import Tooltip from '@material-ui/core/Tooltip'
import Typography from '@material-ui/core/Typography'
import { FormHandles } from '@unform/core'
import { Form } from '@unform/web'

import { ConsumptionHistory } from './ConsumptionHistory'
import stateList from './states'
import {
  Container,
  ContainerForm,
  FormLineInput,
  ContainerInput,
  SelectContainer,
  ContainerButton,
  LabelContainer,
  MediaWrapper,
  ButtonsWrapper,
} from './styles'

interface IFormUpdateData {
  name: string
  document_value: string
  document_cnpj_value: string
  company_name: string
  postal_code: string
  address: string
  address_number: string
  address_neighborhood: string
  address_complement: string
  state: string
  city: string
  dealership: string
  consumption_average: number
  client_code: string
  consumer_unit: string
  type_consumer_class: string
  expiration_month: string
  dealership_tax: string
  amount: number
  month: {
    month: string
    kwh: string | null
  }[]
  kwh_tax: string
  consumption_profile: string
}

const BillEdit: React.FC = () => {
  const formRef = useRef<FormHandles>(null)
  const client = useSelector((state: RootState) => state.Client.client)
  const cityList = useSelector((state: RootState) => state.Cities.cities.items)

  const billDetails = useSelector((state: RootState) => state.Bill.billDetails)
  const consumptionHistory = useSelector(
    (state: RootState) => state.Bill.months,
  )

  const [selectedCity, setSelectedCity] = useState(billDetails.bill_owner?.city)
  const [selectedState, setSelectedState] = useState(
    billDetails.bill_owner?.state,
  )
  const [billValue, setBillValue] = useState<number>(0)
  const [selectedConsumptionClass, setSelectedConsumptionClass] = useState(
    TypeConsumptionClassEnum.RESIDENTIAL,
  )

  const [selectedAlimentationClass, setSelectedAlimentationClass] = useState(
    billDetails?.power_pattern || TypeAlimentationClassEnum.MONOFÁSICO,
  )
  const [averageConsumption, setAverageConsumption] = useState<number>(0)
  const [dealershipTax, setDealershipTax] = useState<number>(0)
  const [averageApplied, setAverageApplied] = useState<boolean>(false)
  const [selectedMonth, setSelectedMonth] = useState(
    billDetails?.expiration_month,
  )
  const [currentCpf, setCurrentCpf] = useState<string>('')
  const [hasCnpj, setHasCnpj] = useState<boolean>(false)

  const dispatch = useDispatch()

  const clientType = client.type.type

  const isClientTypeCPF = clientType === TypeDocumentEnum.CPF

  const loadDealershipTax = async () => {
    const res = await api.get(`/admin/customers/${client.id}/project-infos`)

    setDealershipTax(res.data.dealership_tax)
  }

  useEffect(() => {
    loadDealershipTax()
    dispatch(CommonCreators.ufRequest())
    dispatch(BillCreators.getConsumptionHistoryRequest())
  }, [])

  useEffect(() => {
    const query = {
      states: [selectedState],
      is_residential_available: isClientTypeCPF,
      is_commercial_available: !isClientTypeCPF,
      limit: 1000,
    }
    dispatch(CitiesCreators.getCitiesRequest(query))
  }, [dispatch, isClientTypeCPF, selectedState])

  const handleSubmit = useCallback(
    async (bill: IFormUpdateData) => {
      if (!dealershipTax) {
        toast.error('Preencha a tarifa da concessionária')
        return
      }
      if (hasCnpj && !bill.document_cnpj_value) {
        toast.error('Preencha o campo CNPJ')
        return
      }
      if (hasCnpj && !bill.company_name) {
        toast.error('Preencha o campo Razão social')
        return
      }
      try {
        const clientUploadBill = {
          bill_owner: {
            name: bill.name,
            document_value: bill.document_value.replace(/\D/g, ''),
            document_cnpj_value: hasCnpj
              ? bill.document_cnpj_value?.replace(/\D/g, '')
              : null,
            company_name: hasCnpj ? bill.company_name : null,
            postal_code: bill.postal_code.replace(/\D/g, ''),
            address: bill.address,
            address_neighborhood: bill.address_neighborhood,
            address_number: bill.address_number,
            address_complement: bill.address_complement,
            city: selectedCity,
            state: selectedState,
          },
          consumer_unit: bill.consumer_unit,
          type_consumer_class: selectedConsumptionClass,
          power_pattern: selectedAlimentationClass,
          amount: billValue,
          dealership: bill.dealership,
          client_code: bill.client_code,
          expiration_month: selectedMonth,
          kwh_tax: bill.kwh_tax,
          consumption_profile: bill.consumption_profile,
        }

        const handleConsumptionHistory = bill.month.map(
          consumptionHistoryItem => {
            return {
              month: parseInt(consumptionHistoryItem.month, 10),
              kwh: consumptionHistoryItem.kwh
                ? parseInt(consumptionHistoryItem.kwh, 10)
                : 0,
            }
          },
        )

        if (
          handleConsumptionHistory.some(
            consumptionHistoryItem => consumptionHistoryItem.kwh,
          )
        ) {
          dispatch(
            BillCreators.editConsumptionHistoryRequest(
              handleConsumptionHistory,
            ),
          )
        } else {
          toast.error('Preencha pelo menos um campo do histórico de consumo')
          return
        }

        const userSchema = Yup.object().shape({
          bill_owner: Yup.object().shape({
            name: Yup.string().required('Nome obrigatório'),
            document_value: Yup.string().required(
              'Número do documento é obrigatório',
            ),
            document_cnpj_value: Yup.string().nullable(true),
            company_name: Yup.string().nullable(true),
            postal_code: Yup.string()
              .required('CEP obrigatório')
              .min(8, 'CEP inválido'),
            address: Yup.string().required('Endereço obrigatório'),
            address_number: Yup.string().required('Número obrigatório'),
            address_complement: Yup.string(),
            address_neighborhood: Yup.string().required('Bairro obrigatório'),
            city: Yup.string().required('Cidade obrigatório'),
            state: Yup.string().required('Estado obrigatório'),
          }),
          consumer_unit: Yup.string().required(
            'Unidade de consumo obrigatório',
          ),
          type_consumer_class: Yup.string().required(
            'Classe consumidora obrigatório',
          ),
          amount: Yup.number().required('Valor da conta obrigatório'),
          client_code: Yup.string().required('Código do cliente obrigatório'),
          expiration_month: Yup.string().required(
            'Mês de vencimento obrigatório',
          ),
          power_pattern: Yup.string().required(
            'Tipo de alimentação obrigatório',
          ),
        })

        await userSchema.validate(clientUploadBill, {
          abortEarly: true,
        })
        dispatch(
          BillCreators.editBillDetailsRequest({
            ...clientUploadBill,
            kwh_tax: 1,
          }),
        )
        if (dealershipTax) {
          await api.post(`/admin/customers/${client.id}/project-infos`, {
            dealership_tax: dealershipTax,
          })
        }
      } catch (err) {
        if (Array.isArray(((err as unknown) as any).errors)) {
          // eslint-disable-next-line @typescript-eslint/no-extra-semi
          ;((err as unknown) as any).errors.forEach(() => {
            toast.error(
              `Campo obrigatório: ${((err as unknown) as any).params.path}`,
            )
          })
        } else {
          toast.error('Algo deu errado.')
        }
      }
    },
    [
      hasCnpj,
      billValue,
      dispatch,
      selectedCity,
      selectedConsumptionClass,
      selectedAlimentationClass,
      selectedMonth,
      selectedState,
      dealershipTax,
    ],
  )

  const handleChangeCity = (city: any) => {
    setSelectedCity(city.value)
  }

  const handleChangeState = (state: any) => {
    setSelectedState(state.value)
  }

  const handleChangeConsumptionClass = (consumptionClass: any) => {
    setSelectedConsumptionClass(consumptionClass.value)
  }

  const handleChangeAlimentationClass = (alimentationClass: any) => {
    setSelectedAlimentationClass(alimentationClass.value)
  }

  const handleChangeMonth = (month: any) => {
    setSelectedMonth(month.value)
  }

  const handleChangeAmount = (value: number) => {
    setBillValue(value)
  }

  const handleChangeDealershipTax = (value: number) => {
    setDealershipTax(value)
  }

  const handlePostalCodeChange = async (
    event: ChangeEvent<HTMLInputElement>,
  ) => {
    const postalCode = event.target?.value?.replace(/\D/g, '')
    if (postalCode.length === 8) {
      const addressResponse = await getAddressByPostalCode(postalCode)

      const address = formRef.current?.getFieldValue('address')
      if (!address)
        formRef.current?.setFieldValue('address', addressResponse.address)

      const neighborhood = formRef.current?.getFieldValue(
        'address_neighborhood',
      )
      if (!neighborhood)
        formRef.current?.setFieldValue(
          'address_neighborhood',
          addressResponse.neighborhood,
        )

      const complement = formRef.current?.getFieldValue('address_complement')
      if (!complement)
        formRef.current?.setFieldValue(
          'address_complement',
          addressResponse.complement,
        )

      const city = selectedCity
      if (!city && addressResponse.city) setSelectedCity(addressResponse.city)

      const state = selectedState
      if (!state && addressResponse.state)
        setSelectedState(addressResponse.state)
    }
  }

  const getScoreData = async (force: boolean) => {
    await api
      .get(`/admin/score/${client.id}`, { params: { force: +force } })
      .then(({ data }) => {
        formRef.current?.setData({
          name: data.json['tr_500:identificacao'][0]['tr_500:nome'][0],
        })
        toast.success('Nome buscado com sucesso')
      })
      .catch(() => toast.error('Houve um erro ao consultar o CPF'))
  }

  const updateClientCpf = async (cpf: string) => {
    await api
      .put(`/admin/customers/bills/${client.id}/document`, {
        document_value: cpf,
      })
      .then(() => toast.success('Documento atualizado com sucesso'))
      .catch(() => toast.error('Erro ao atualizar cadastro do cliente'))
  }

  const handleChangeCPF = async (
    changeEvent: ChangeEvent<HTMLInputElement>,
  ) => {
    const cpf = changeEvent.target.value
    const cleasingCpf = cpf.replace(/\D/g, '')

    if (cleasingCpf.length < 11 || cleasingCpf === currentCpf) return
    setCurrentCpf(cleasingCpf)

    await updateClientCpf(cleasingCpf)
    await getScoreData(true)
  }

  // Calcule total consumption history
  const consumptionHistoryTotal = consumptionHistory.reduce((acc, curr) => {
    if (curr.kwh) acc += curr.kwh

    return acc
  }, 0)

  // Get cities
  const cities = cityList.map(city => {
    return {
      value: city.name,
      label: city.name,
    }
  })

  // Get states
  const states = stateList?.map(state => {
    return {
      value: state.sigla,
      label: state.nome,
    }
  })

  // Get consumption class
  const consumptionClass = [
    {
      value: TypeConsumptionClassEnum.RESIDENTIAL,
      label: 'Residencial',
    },
    {
      value: TypeConsumptionClassEnum.COMMERCIAL,
      label: 'Comercial',
    },
  ]

  // Get consumption class
  const alimentationClass = [
    {
      value: TypeAlimentationClassEnum.MONOFÁSICO,
      label: 'Monofásico',
    },
    {
      value: TypeAlimentationClassEnum.BIFÁSICO,
      label: 'Bifásico',
    },
    {
      value: TypeAlimentationClassEnum.TRIFÁSICO,
      label: 'Trifásico',
    },
  ]

  // Get months
  const months = [
    { value: 'Janeiro', label: 'Janeiro' },
    { value: 'Fevereiro', label: 'Fevereiro' },
    { value: 'Março', label: 'Março' },
    { value: 'Abril', label: 'Abril' },
    { value: 'Maio', label: 'Maio' },
    { value: 'Junho', label: 'Junho' },
    { value: 'Julho', label: 'Julho' },
    { value: 'Agosto', label: 'Agosto' },
    { value: 'Setembro', label: 'Setembro' },
    { value: 'Outubro', label: 'Outubro' },
    { value: 'Novembro', label: 'Novembro' },
    { value: 'Dezembro', label: 'Dezembro' },
  ]

  useEffect(() => {
    const { bill_owner } = billDetails

    const fetchData = async () => {
      if (bill_owner && bill_owner.document_value) {
        await getScoreData(false)
      }
    }

    fetchData()

    if (bill_owner?.document_cnpj_value || bill_owner?.company_name)
      setHasCnpj(true)
    formRef.current?.setData({
      name: bill_owner?.name,
      document_value: bill_owner?.document_value || '',
      document_cnpj_value: bill_owner?.document_cnpj_value || '',
      company_name: bill_owner?.company_name || '',
      postal_code: bill_owner?.postal_code || '',
      address: bill_owner?.address,
      address_neighborhood: bill_owner?.address_neighborhood,
      address_complement: bill_owner?.address_complement,
      address_number: bill_owner?.address_number,
      consumption_average: `${consumptionHistoryTotal / 12} kWh`,
      client_code: billDetails.client_code,
      type_consumer_class: billDetails.consumer_unit,
      month: consumptionHistory,
      dealership: billDetails?.dealership,
      dealership_tax: dealershipTax,
      consumer_unit: billDetails?.consumer_unit,
      kwh_tax: billDetails?.kwh_tax,
      consumption_profile: billDetails?.consumption_profile,
    })
    loadDealershipTax()
  }, [billDetails])

  return (
    <Container>
      <Form
        onSubmit={handleSubmit}
        ref={formRef}
        initialData={{
          postal_code: '',
          document_value: '',
        }}
      >
        <FormControlLabel
          control={
            <Switch
              checked={hasCnpj}
              onChange={() => setHasCnpj(prevCnpjState => !prevCnpjState)}
              color="primary"
              name="cnpj-switch"
            />
          }
          label="Pessoa jurídica?"
        />
        <ContainerForm>
          <FormLineInput>
            <Tooltip
              placement="top"
              title={
                // eslint-disable-next-line react/jsx-wrap-multilines
                <Typography color="inherit">
                  Preencha o CPF para buscar o nome
                </Typography>
              }
            >
              <ContainerInput width="775px" marginRight="35px">
                <Input
                  hasMask
                  mask="999.999.999-99"
                  name="document_value"
                  label={`${clientType} *`}
                  width="100%"
                  placeholder="Digite o número do CPF"
                  onChange={handleChangeCPF}
                />
              </ContainerInput>
            </Tooltip>
            <ContainerInput width="775px" marginRight="0px">
              <Input
                name="name"
                label="Nome do Titular *"
                width="100%"
                placeholder="Digite o nome completo"
                disabled
              />
            </ContainerInput>
          </FormLineInput>
          {hasCnpj && (
            <FormLineInput>
              <ContainerInput width="775px" marginRight="35px">
                <Input
                  hasMask
                  mask="99.999.999/9999-99"
                  name="document_cnpj_value"
                  label="CNPJ *"
                  width="100%"
                  placeholder="Digite o número do CNPJ"
                />
              </ContainerInput>
              <ContainerInput width="775px" marginRight="0px">
                <Input
                  name="company_name"
                  label="Razão Social *"
                  width="100%"
                  placeholder="Digite a razão social"
                />
              </ContainerInput>
            </FormLineInput>
          )}
          <FormLineInput>
            <ContainerInput width="775px" marginRight="35px">
              <Input
                hasMask
                mask="99999-999"
                name="postal_code"
                label="CEP *"
                width="100%"
                placeholder="Digite o CEP"
                onChange={handlePostalCodeChange}
              />
            </ContainerInput>
            <ContainerInput width="775px" marginRight="0px">
              <Input
                name="address"
                label="Endereço *"
                width="100%"
                placeholder="Digite o endereço"
              />
            </ContainerInput>
          </FormLineInput>
          <FormLineInput>
            <ContainerInput width="775px" marginRight="35px">
              <Input
                name="address_neighborhood"
                label="Bairro *"
                width="100%"
                placeholder="Digite o bairro"
              />
            </ContainerInput>
            <ContainerInput width="775px" marginRight="0px">
              <Input
                name="address_complement"
                label="Complemento"
                width="100%"
                placeholder="Digite o complemento"
              />
            </ContainerInput>
          </FormLineInput>
          <FormLineInput>
            <ContainerInput width="775px" marginRight="35px">
              <Input
                name="address_number"
                label="Número *"
                width="100%"
                placeholder="Digite número do endereço"
              />
            </ContainerInput>
            <SelectContainer>
              <Select
                label="Estado *"
                size="custom"
                options={states}
                value={states?.find(state => state.value === selectedState)}
                onChange={handleChangeState}
                isSearchable
                placeholder="Selecione um estado..."
              />
            </SelectContainer>
          </FormLineInput>
          <FormLineInput>
            <SelectContainer marginRight="35px">
              <Select
                label="Cidade *"
                size="custom"
                options={cities}
                value={cities.find(city => city.value === selectedCity)}
                onChange={handleChangeCity}
                isSearchable
                placeholder="Selecione uma cidade..."
              />
            </SelectContainer>
            <ContainerInput width="775px" marginRight="0">
              <Input
                name="consumption_average"
                label=" Média Historico de Consumo (kWh) *"
                width="100%"
                placeholder="Média de consumo"
                disabled
              />
            </ContainerInput>
          </FormLineInput>
          <FormLineInput>
            <ContainerInput width="775px" marginRight="35px">
              <Input
                name="client_code"
                label="Código do Cliente *"
                width="100%"
                placeholder="Código do cliente"
              />
            </ContainerInput>
            <ContainerInput width="775px" marginRight="0px">
              <Input
                name="consumer_unit"
                label="Número da UC *"
                width="100%"
                placeholder="Número da UC"
              />
            </ContainerInput>
          </FormLineInput>
          <FormLineInput>
            <SelectContainer marginRight="35px">
              <Select
                label="Classe de Consumo *"
                size="custom"
                options={consumptionClass}
                value={consumptionClass.find(
                  consumption => consumption.value === selectedConsumptionClass,
                )}
                onChange={handleChangeConsumptionClass}
                isSearchable={false}
                placeholder="Selecione uma classe de consumo..."
              />
            </SelectContainer>
            <SelectContainer marginRight="0px">
              <Select
                label="Mês de Vencimento *"
                size="custom"
                options={months}
                value={months.find(
                  listMonths => listMonths.value === selectedMonth,
                )}
                onChange={handleChangeMonth}
                isSearchable={false}
                placeholder="Selecione o mês de vencimento..."
              />
            </SelectContainer>
          </FormLineInput>
          <FormLineInput>
            <ContainerInput width="775px" marginRight="35px">
              <InputNumberMask
                initialValue={billDetails?.amount || 0}
                label="Valor da conta *"
                onChange={handleChangeAmount}
                prefix="R$ "
                decimalSymbol=","
                decimalLimit={2}
              />
            </ContainerInput>
            <ContainerInput width="775px" marginRight="0px">
              <Input
                name="dealership"
                label="Nome da Concessionária *"
                width="100%"
                placeholder="Nome da concessionária"
              />
            </ContainerInput>
          </FormLineInput>

          <FormLineInput>
            <ContainerInput width="775px" marginRight="35px">
              <InputNumberMask
                initialValue={dealershipTax || 0}
                label="Tarifa Concessionária *"
                onChange={handleChangeDealershipTax}
                prefix="R$ "
                decimalSymbol=","
                decimalLimit={2}
              />
            </ContainerInput>
            <SelectContainer marginRight="0px">
              <Select
                label="Padrão de alimentação *"
                size="custom"
                options={alimentationClass}
                value={alimentationClass.find(
                  consumption =>
                    consumption.value === selectedAlimentationClass,
                )}
                onChange={handleChangeAlimentationClass}
                isSearchable={false}
                placeholder="Selecione uma classe de consumo..."
              />
            </SelectContainer>
          </FormLineInput>
        </ContainerForm>
        <LabelContainer>
          <label>Histórico de Consumo (kWh)</label>
        </LabelContainer>
        <ConsumptionHistory
          averageConsumption={averageConsumption}
          averageApplied={averageApplied}
        />
        <ContainerButton>
          <MediaWrapper>
            <Input
              name="media_value"
              type="number"
              label="Média"
              placeholder="Média (kWh)"
              onChange={(event: any) => {
                setAverageConsumption(parseInt(event.target.value, 10))
              }}
            />
            <Button
              full
              type="button"
              size="default"
              color={!averageConsumption ? 'cancel' : 'primary'}
              onClick={() => {
                if (averageConsumption !== 0) {
                  setAverageApplied(true)
                } else {
                  toast.error('Preencha o campo com um valor diferente de 0!')
                }
              }}
            >
              Aplicar
            </Button>
          </MediaWrapper>
          <ButtonsWrapper>
            <Button
              full
              type="button"
              size="default"
              onClick={() => dispatch(BillCreators.setIsEditBillDetails(false))}
            >
              Voltar
            </Button>
            <Button full size="default" color="primary" type="submit">
              Salvar alterações
            </Button>
          </ButtonsWrapper>
        </ContainerButton>
      </Form>
    </Container>
  )
}

export { BillEdit }
