import styled from 'styled-components'

type ButtonProps = {
  color?: string
}

export const Button = styled.button<ButtonProps>`
  display: flex;
  height: 4rem;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 24px;
  border: none;
  background: #ffd500;
  border-radius: 9px;

  &:disabled {
    cursor: default;
    background: #aaaaaa;
  }
`

export const Text = styled.h5`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 130%;
`
