import React from 'react'
import { FiAlertTriangle, FiCheck, FiX } from 'react-icons/fi'

import { Container } from './styles'

interface Props {
  type?: 'success' | 'warning' | 'error'
  text: string
}

const icons = {
  warning: <FiAlertTriangle size={26} />,
  success: <FiCheck size={26} />,
  error: <FiX size={26} />,
}

const Alert: React.FC<Props> = ({ type, text }) => {
  return (
    <Container type={type}>
      {icons[type || 'warning']}
      <strong>{text}</strong>
    </Container>
  )
}

export default Alert
