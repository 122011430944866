import React from 'react'

import Styles from './styles'

interface ISimpleModal {
  onClose: () => void
  title: string
  children: React.ReactNode
}

const SimpleModal: React.FC<ISimpleModal> = ({ onClose, title, children }) => {
  const checkElement = (e: React.MouseEvent<HTMLDivElement>): void => {
    e.preventDefault()
    const dataValue = (e.target as HTMLElement).getAttribute('data-value')
    if (dataValue === 'main') onClose()
  }

  return (
    <Styles.Modal data-value="main" onClick={checkElement}>
      <Styles.ModalContent data-value="parent" onClick={checkElement}>
        <Styles.BtnClose type="button" onClick={onClose}>
          X
        </Styles.BtnClose>
        <Styles.ModalTitle>{title}</Styles.ModalTitle>

        {children}
      </Styles.ModalContent>
    </Styles.Modal>
  )
}

export default SimpleModal
