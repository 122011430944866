import { AxiosResponse } from 'axios'

import api from '@/services/api'

export const uploadFiles = async (file: FormData) => {
  const uploadResponse = await api
    .post(`/admin/uploads`, file)
    .then(({ data }: AxiosResponse) => data)
  return uploadResponse
}
