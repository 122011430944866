/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react'

import moment from 'moment'

import 'moment/locale/pt-br'

import MomentUtils from '@date-io/moment'
import {
  KeyboardDateTimePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers'

moment.locale('pt-br')

interface iDateTimePickerProps {
  label: string
  onChange: (date: any) => void
  value: string | Date
}

const iDateTimePicker: React.FC<iDateTimePickerProps> = ({
  label,
  onChange,
  value,
}) => {
  return (
    <MuiPickersUtilsProvider
      libInstance={moment}
      utils={MomentUtils}
      locale="pt-br"
    >
      <KeyboardDateTimePicker
        style={{ width: '100%' }}
        variant="inline"
        label={label}
        inputVariant="outlined"
        value={value}
        onChange={onChange}
        format="DD/MM/YYYY HH:mm"
        ampm={false}
      />
    </MuiPickersUtilsProvider>
  )
}

export default iDateTimePicker
