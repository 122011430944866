import styled from 'styled-components'

import theme from '@/assets/styles/theme'
import Button from '@/components/Ui/Button'

export const Container = styled.div`
  display: flex;
  padding: 0px 20px 20px 20px;
  flex-direction: column;

  @media only screen and (max-width: ${theme.breakpoints.sm}) {
    width: 333px;
  }
`

export const Title = styled.div`
  & > span {
    font: Bold 20px/35px Roboto;
    letter-spacing: 0px;
    color: #000000;
    opacity: 0.5;
  }
`

export const Buttons = styled.div`
  display: flex;
  width: 220px;
  margin-top: 20px;
  justify-content: space-between;
`
export const ButtonYes = styled(Button)`
  width: 100px;
`
export const ButtonNo = styled(Button)`
  width: 100px;
  background-color: transparent;
  border: 1px solid;
  opacity: 0.3;

  &:hover {
    background-color: ${theme.colors.gray[400]};
  }
`
export const TextArea = styled.textarea`
  width: 600px;
  height: 125px;
  margin-top: 10px;

  ::-webkit-input-placeholder {
    padding: 20px;
  }

  @media only screen and (max-width: ${theme.breakpoints.sm}) {
    width: 300px;
    height: 120px;
  }
`
export const ContainerButton = styled.div`
  display: flex;
  justify-content: flex-end;

  @media only screen and (max-width: ${theme.breakpoints.sm}) {
    justify-content: center;
  }
`
