import React from 'react'

import { planType } from '@/@types/planType'
import { Plan } from '@/components/Plan'

import {
  Container,
  ContainerPlan,
  Fields,
  FieldsLabel,
  Font,
  ContainerColumnPlan,
  PlanComponent,
} from './styles'

interface ISimulatedPlan {
  kwp_price?: string
  kwh_kwp?: string
  overload?: number
  monthly_rental_savings?: string
  monthly_reduction?: string
  rental_value?: string
  old_bill_annual_value?: string
  new_bill_annual_value?: string
  client_annual_rental_value?: string
  client_final_annual_value?: string
  annual_savings?: string
  number_of_project_modules?: number
  power_of_the_project?: string
  annual_production?: string
  co2_emission?: string
  total_planted_trees?: number
  excess_credit?: string
  typePlan: string
  planTerm?: number
  planPrice?: string
  annual_savings_plan?: string
  isNotFit?: boolean
}

const SimulatedPlan = ({
  simulatedPlan,
  isNotFit = true,
}: {
  simulatedPlan: ISimulatedPlan
  isNotFit: boolean
}) => {
  return (
    <Container>
      <ContainerPlan>
        <ContainerColumnPlan>
          <PlanComponent>
            {isNotFit ? (
              <Plan
                width="340px"
                planName={planType[simulatedPlan.typePlan]}
                isNotFit
              />
            ) : (
              <Plan
                width="340px"
                planName={planType[simulatedPlan.typePlan]}
                planPrice={simulatedPlan.planPrice}
              />
            )}
          </PlanComponent>

          {isNotFit ? (
            <>
              <FieldsLabel>
                <Fields>
                  <Font>Prazo do plano:</Font>
                </Fields>
              </FieldsLabel>
              <FieldsLabel>
                <Fields>
                  <Font>Economia estimada anual:</Font>
                </Fields>
              </FieldsLabel>
              <FieldsLabel>
                <Fields>
                  <Font>R$/kWp:</Font>
                </Fields>
              </FieldsLabel>
              <FieldsLabel>
                <Fields>
                  <Font>kWh/kWp:</Font>
                </Fields>
              </FieldsLabel>
              <FieldsLabel>
                <Fields>
                  <Font>Economia mensal média:</Font>
                </Fields>
              </FieldsLabel>
              <FieldsLabel>
                <Fields>
                  <Font>Redução mensal média:</Font>
                </Fields>
              </FieldsLabel>
              <FieldsLabel>
                <Fields>
                  <Font>Valor mensal da assianatura:</Font>
                </Fields>
              </FieldsLabel>
              <FieldsLabel>
                <Fields>
                  <Font>Valor anual conta antiga - Sem Solar21:</Font>
                </Fields>
              </FieldsLabel>
              <FieldsLabel>
                <Fields>
                  <Font>Valor anual conta nova - Com Solar21:</Font>
                </Fields>
              </FieldsLabel>
              <FieldsLabel>
                <Fields>
                  <Font>Valor anual da assinatura:</Font>
                </Fields>
              </FieldsLabel>
              <FieldsLabel>
                <Fields>
                  <Font>Valor final cliente:</Font>
                </Fields>
              </FieldsLabel>
              <FieldsLabel>
                <Fields>
                  <Font>Economia anual - Com Solar21:</Font>
                </Fields>
              </FieldsLabel>
              <FieldsLabel>
                <Fields>
                  <Font>Número de Módulos:</Font>
                </Fields>
              </FieldsLabel>
              <FieldsLabel>
                <Fields>
                  <Font>Potência de projeto (kWp):</Font>
                </Fields>
              </FieldsLabel>
              <FieldsLabel>
                <Fields>
                  <Font>Energia produzida anualmente:</Font>
                </Fields>
              </FieldsLabel>
              <FieldsLabel>
                <Fields>
                  <Font>Emissão de CO2:</Font>
                </Fields>
              </FieldsLabel>
              <FieldsLabel>
                <Fields>
                  <Font>Equivalente em árvores plantadas:</Font>
                </Fields>
              </FieldsLabel>
              <FieldsLabel>
                <Fields>
                  <Font>Crédito excedente (kWh/ano):</Font>
                </Fields>
              </FieldsLabel>
            </>
          ) : (
            <>
              <FieldsLabel>
                <Fields>
                  <Font>Prazo do plano:</Font> {simulatedPlan.planTerm} anos
                </Fields>
              </FieldsLabel>
              <FieldsLabel>
                <Fields>
                  <Font>Economia estimada anual:</Font>{' '}
                  {simulatedPlan.annual_savings_plan} %
                </Fields>
              </FieldsLabel>
              <FieldsLabel>
                <Fields>
                  <Font>R$/kWp:</Font> {simulatedPlan.kwp_price}
                </Fields>
              </FieldsLabel>
              <FieldsLabel>
                <Fields>
                  <Font>kWh/kWp:</Font> {simulatedPlan.kwh_kwp}
                </Fields>
              </FieldsLabel>
              <FieldsLabel>
                <Fields>
                  <Font>Overload:</Font> {simulatedPlan.overload}
                </Fields>
              </FieldsLabel>
              <FieldsLabel>
                <Fields>
                  <Font>Economia mensal média:</Font> R${' '}
                  {simulatedPlan.monthly_rental_savings}
                </Fields>
              </FieldsLabel>
              <FieldsLabel>
                <Fields>
                  <Font>Redução mensal média:</Font>{' '}
                  {simulatedPlan.monthly_reduction} %
                </Fields>
              </FieldsLabel>
              <FieldsLabel>
                <Fields>
                  <Font>Valor mensal da assinatura:</Font> R${' '}
                  {simulatedPlan.rental_value}
                </Fields>
              </FieldsLabel>
              <FieldsLabel>
                <Fields>
                  <Font>Valor anual conta antiga - Sem Solar21:</Font> R${' '}
                  {simulatedPlan.old_bill_annual_value}
                </Fields>
              </FieldsLabel>
              <FieldsLabel>
                <Fields>
                  <Font>Valor anual conta nova - Com Solar21:</Font> R${' '}
                  {simulatedPlan.new_bill_annual_value}
                </Fields>
              </FieldsLabel>
              <FieldsLabel>
                <Fields>
                  <Font>Valor anual da assinatura:</Font> R${' '}
                  {simulatedPlan.client_annual_rental_value}
                </Fields>
              </FieldsLabel>
              <FieldsLabel>
                <Fields>
                  <Font>Valor final cliente:</Font> R${' '}
                  {simulatedPlan.client_final_annual_value}
                </Fields>
              </FieldsLabel>
              <FieldsLabel>
                <Fields>
                  <Font>Economia anual - Com Solar21:</Font> R${' '}
                  {simulatedPlan.annual_savings}
                </Fields>
              </FieldsLabel>
              <FieldsLabel>
                <Fields>
                  <Font>Número de Módulos:</Font>{' '}
                  {simulatedPlan.number_of_project_modules}
                </Fields>
              </FieldsLabel>
              <FieldsLabel>
                <Fields>
                  <Font>Potência de projeto (kWp):</Font>{' '}
                  {simulatedPlan.power_of_the_project} kWp
                </Fields>
              </FieldsLabel>
              <FieldsLabel>
                <Fields>
                  <Font>Energia produzida anualmente:</Font>{' '}
                  {simulatedPlan.annual_production}
                </Fields>
              </FieldsLabel>
              <FieldsLabel>
                <Fields>
                  <Font>Emissão de CO2:</Font> {simulatedPlan.co2_emission}
                </Fields>
              </FieldsLabel>
              <FieldsLabel>
                <Fields>
                  <Font>Equivalente em árvores plantadas:</Font>{' '}
                  {simulatedPlan.total_planted_trees}
                </Fields>
              </FieldsLabel>
              <FieldsLabel>
                <Fields>
                  <Font>Crédito excedente (kWh/ano):</Font>{' '}
                  {simulatedPlan.excess_credit} kWh
                </Fields>
              </FieldsLabel>
            </>
          )}
        </ContainerColumnPlan>
      </ContainerPlan>
    </Container>
  )
}

export { SimulatedPlan }
