import { IoIosDocument } from 'react-icons/io'
import { MdAddCircleOutline } from 'react-icons/md'

import styled from 'styled-components'

import theme from '@/assets/styles/theme'

interface IPropsHover {
  isHover: boolean
}

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  height: 200px;
`
export const UploadList = styled.div`
  width: 150px;
  height: 150px;
  margin-right: 35px;
  position: relative;
`

export const Image = styled.img`
  height: 150px;
  width: 150px;
  margin-right: 35px;
`

export const ContainerDocument = styled.div`
  width: 150px;
  height: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${theme.colors.gray[150]};
  position: relative;
`

export const ContainerUpload = styled(ContainerDocument)`
  border: 2px dashed ${theme.colors.gray[250]};
  background-color: ${theme.colors.gray[150]};
  cursor: pointer;
`

export const IconUpload = styled.input.attrs({ type: 'file' })`
  display: none;
  border-style: none;
  outline: none;
  --webkit-user-select: none;
`

export const Icon = styled(MdAddCircleOutline)`
  font-size: 53px;
  color: ${theme.colors.gray[250]};
`
export const IconDocument = styled(IoIosDocument)`
  font-size: 53px;
  color: ${theme.colors.gray[250]};
`

export const ContainerOpacity = styled.div<IPropsHover>`
  width: 150px;
  height: 150px;
  display: ${props => (props.isHover ? 'flex' : 'none')};
  justify-content: flex-start;
  flex-direction: column;
  align-items: flex-end;
  background-color: rgba(112, 112, 112, 0.6);
  position: absolute;
`

export const IconButton = styled.div`
  width: 26px;
  height: 26px;
  background-color: ${theme.colors.primary};
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 8px 10px 0px 0px;
  border-radius: 5px;
  text-decoration: none;
`
