import React from 'react'

import AuroraHappy from '@/assets/images/aurora/happy/happy-01.svg'
import { NotificationMessage } from '@/components/NotificationMessage'
import history from '@/services/history'

import { Container, Media, Avatar, Info, Time } from './styles'

interface INotificationItemProps {
  message: string
  clientName: string
  time: string
}

const NotificationItem = ({
  message,
  clientName,
  time,
}: INotificationItemProps) => {
  return (
    <Container onClick={() => history.push('/notifications')}>
      <Media>
        <Avatar>
          <img src={AuroraHappy} alt="Aurora" />
        </Avatar>
        <Info>
          <NotificationMessage clientName={clientName} message={message} />
          <Time>{time}</Time>
        </Info>
      </Media>
    </Container>
  )
}

export { NotificationItem }
