/* eslint-disable @typescript-eslint/no-explicit-any */
import styled from 'styled-components'

import theme from '@/assets/styles/theme'
import {
  InputLabel as MuiInputLabel,
  FormControl as MuiFormControl,
  Box as MuiBox,
} from '@material-ui/core'

export const SelectTitle = styled.p``

export const InputLabel = styled(MuiInputLabel)`
  width: 100%;
`

export const FormControl = styled(MuiFormControl)`
  width: 100%;
`

export const BoardHeader = styled.h3`
  font-size: 1.5rem;
  margin-bottom: 1rem;
`

export const MessageBox = styled(MuiBox)`
  width: 100%;
  max-height: 30vh;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
`

export const Message = styled.p`
  width: 100%;
  text-align: center;
  font-weight: bold;
  line-height: ${({ color }: any) => (color ? '1' : '1.2')};
  color: ${({ color }: any) => (color ? '#0005' : 'inherit')};
  font-size: ${({ color }: any) => (color ? 'inherit' : '1.1rem')};
  margin: ${({ color }: any) => (color ? '0 0 15px' : '20px 0')};
`

export const FormBox = styled(MuiBox)`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin: 50px 0 0;
`

export const Label = styled.label`
  margin-bottom: 10px;
  font-weight: bold;
`

export const FileBox = styled(MuiBox)`
  width: 100%;
  display: grid;
  grid-template-columns: 4fr 1fr 1fr;
  padding: 25px 15px;
  background-color: ${({ color }: any) =>
    color ? theme.colors.gray[100] : 'inherit'};
`

export const FileHeader = styled.h4`
  font-weight: bold;
`

export const IconBox = styled(MuiBox)`
  display: flex;
  align-items: center;
  justify-content: space-evenly;
`

export const IconButton = styled.button`
  background-color: transparent;
  border: none;
`

export const SaveBox = styled(MuiBox)`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  padding: 10px 15px 15px;
`

export const SaveButton = styled.button`
  border: none;
  background-color: ${theme.colors.primary};
  font-weight: bold;
  padding: 15px 10px;
  border-radius: 8px;
`

export const NotAllowedMessage = styled.span`
  font-size: 1.3rem;
`
