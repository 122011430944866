import styled from 'styled-components'

export const Container = styled.div`
  height: 71vh;
`

export const ContainerForm = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  padding: 0.25rem 0 0;
  height: calc(100% - 19vh);
`

export const FormLineInput = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 20px;
  gap: 2rem;

  @media only screen and (max-width: 850px) {
    flex-direction: column;
    row-gap: 1rem;
  }
`

export const ContainerInput = styled.div`
  width: 775px;

  @media only screen and (max-width: 850px) {
    width: 100%;
  }
`

export const ContainerButton = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  padding: 25px 0px;
  gap: 1rem;

  @media only screen and (max-width: 850px) {
    flex-direction: column-reverse;
    width: 100%;
  }
`

export const SelectContainer = styled.div`
  width: 775px;
  margin-bottom: 23px;

  @media only screen and (max-width: 850px) {
    width: 100%;
  }
`
