import React from 'react'

import MaterialPagination from '@material-ui/lab/Pagination'

import { Container } from './styles'

interface PaginationProps {
  count: number
  size: 'small' | 'medium' | 'large'
  shape?: 'round' | 'rounded' | undefined
  variant?: 'text' | 'outlined' | undefined
  page?: number | undefined
  onChange: (value: number) => void
}

const Pagination: React.FC<PaginationProps> = ({
  count,
  size,
  shape,
  variant,
  page,
  onChange,
}) => {
  return (
    <Container>
      <MaterialPagination
        onChange={(...args) => {
          const value = args[1]
          onChange(value)
        }}
        count={count}
        shape={shape}
        size={size}
        variant={variant}
        page={page}
      />
    </Container>
  )
}
export { Pagination }
