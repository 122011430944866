import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import moment from 'moment'

import PageContent from '@/components/PageContent'
import { Pagination } from '@/components/Ui/Pagination'
import { RootState } from '@/store'
import { NotificationCreators } from '@/store/ducks/Notification'
import { INotificationItem } from '@/store/ducks/Notification/types'

import { NotificationItem } from '../../components/NotificationItem/NotificationItem'
import { Container, PaginationContainer, NotificationContainer } from './styles'

import 'moment/locale/pt-br'

const Notifications: React.FC = () => {
  const dispatch = useDispatch()

  const notificationItems = useSelector(
    (state: RootState) => state.Notification.notification?.items,
  )
  const pagination = useSelector(
    (state: RootState) => state.Notification.notification?.meta,
  )

  const [page, setPage] = useState(pagination?.current_page || 1)
  const [notifications, setNotifications] = useState<INotificationItem[]>([])

  const handlePageChange = (value: number) => setPage(value)

  const handleClickToMarkAsRead = (id: string) => {
    const currentNotification = notifications.find(
      notification => notification.id === id,
    )

    if (currentNotification && !currentNotification.is_user_read) {
      dispatch(NotificationCreators.setReadNotificationRequest(id))

      setNotifications(previousNotifications =>
        previousNotifications.map(previousNotification => {
          let isUserRead = previousNotification.is_user_read
          if (previousNotification.id === id) isUserRead = true

          return {
            ...previousNotification,
            is_user_read: isUserRead,
          }
        }),
      )
    }
  }

  useEffect(() => {
    dispatch(NotificationCreators.getNotificationsRequest({ page }))
  }, [dispatch, page])

  useEffect(() => {
    if (notificationItems) setNotifications(notificationItems)
  }, [notificationItems])

  return (
    <PageContent title="Notificações">
      <Container>
        <NotificationContainer>
          {notifications.map(notification => (
            <>
              <NotificationItem
                notificationId={notification.id}
                clientId={notification.client.id}
                clientName={notification.client.name}
                message={notification.message}
                time={moment(notification.created_at).calendar()}
                isRead={notification.is_user_read}
                onClick={handleClickToMarkAsRead}
                key={notification.id}
              />
            </>
          ))}
        </NotificationContainer>
        <PaginationContainer>
          <Pagination
            count={pagination?.total_pages || 1}
            size="large"
            shape="rounded"
            variant="outlined"
            page={page}
            onChange={handlePageChange}
          />
        </PaginationContainer>
      </Container>
    </PageContent>
  )
}

export { Notifications }
