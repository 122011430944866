import styled from 'styled-components'

import Button from '@/components/Ui/Button'

export const FaqContainer = styled.div``

export const BtnContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  margin-top: 20px;
`

export const ListContainer = styled.div`
  margin-top: 20px;
`

export const CategoryList = styled.ul`
  display: inline-flex !important;
  justify-content: space-around !important;
  align-items: center !important;
  margin-top: 20px !important;
  width: 100%;
  flex-wrap: wrap;
  li {
    width: 200px;
    /* border: 1px solid grey; */
    background: #d8d7d4;
    padding: 20px;
    margin-top: 10px;
    margin-right: 10px;
    cursor: pointer;
  }
`

export const ListItem = styled.span<{ isActive: boolean }>`
  display: flex;
  justify-content: space-between;
  padding: 20px;
  border: 1px solid #d8d7d4;
  border-radius: 10px;
  cursor: pointer;
  ${({ isActive }) => {
    if (isActive) {
      return `
        margin-bottom: 0;
        border-bottom: 0;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
      `
    }
    return `
      &:hover {
        background-color: #e3e4e8;
      }
      margin-bottom: 10px;
    `
  }}
`

export const ListTitle = styled.span`
  font-weight: bold;
  & .cat {
    font-weight: 300;
    font-style: italic;
  }
`

export const ListIcon = styled.span``

export const EditContainer = styled.div`
  padding: 10px;
  border: 1px solid #d8d7d4;
  border-top: 0;
  border-radius: 10px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  margin-bottom: 10px;
`

export const EditInputTitle = styled.input`
  width: 100%;
  height: 40px;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #dad6d6;
  border-radius: 5px;
`

export const EditSelectCat = styled.select``

export const EditContainerActionGroup = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  margin: 0 10px 20px 10px;
`

export const ActionButton = styled(Button)``
