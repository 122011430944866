import React, { ReactNode } from 'react'

import RCTable from 'rc-table'

import * as S from './styles'

interface IHideKeys {
  key: string
}

export interface ITableProps<Record = unknown> {
  columns: ITableColumns<Record>[]
  data: Record[]
  onClickRow?: () => void
  hovered?: boolean
  // eslint-disable-next-line
  hideKeys?: any
}

interface ITableColumns<Record> {
  title: string | ReactNode
  dataIndex: string
  key: string
  width?: number
  align?: 'left' | 'center' | 'right'
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onClickRow?: (record: any) => any
  // hovered?: boolean
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  render?: (value: any, record: Record, index: number) => any
}

export function Table<Record>({
  columns,
  data,
  hovered,
  hideKeys,
}: ITableProps<Record>): React.ReactElement<ITableProps<Record>> {
  const [finalColumns, setFinalColumns] = React.useState<
    ITableColumns<Record>[]
  >(columns)

  React.useEffect(() => {
    if (columns.length && hideKeys) {
      const newItems: ITableColumns<Record>[] = []
      // eslint-disable-next-line
      columns.map(item => {
        const key = item.key.toString()
        if (!hideKeys.includes(key)) {
          newItems.push(item)
        }
      })
      setFinalColumns(newItems)
    }
  }, [columns, hideKeys])

  return (
    <S.Container hovered={hovered}>
      <RCTable<Record>
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        onRow={(record: any) => ({
          onClick: record.onClickRow,
        })}
        columns={finalColumns}
        data={data}
        emptyText="Nenhum dado encontrado"
      />
    </S.Container>
  )
}
