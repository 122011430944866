import { toast } from 'react-toastify'

import queryString from 'query-string'
import { call, put, select } from 'redux-saga/effects'

import { IObjectLiteral } from '@/@types/common/object-literal'
import { TechnicalVisitStatusEnum } from '@/@types/visit'
import api from '@/services/api'
import history from '@/services/history'
import { RootState } from '@/store'

import { Creators as ClientCreators } from '../../ducks/Clients'
import { Creators } from '../../ducks/Visit'
import {
  IRequestCancelVisit,
  IRequestLinkTechnician,
  IUpdateTecnicalVisitStatus,
  IVisitListQuery,
} from '../../ducks/Visit/types'

const getTechnicalVisitId = (state: RootState) =>
  state.Client.client.technical_visit?.id

const errors: IObjectLiteral = {
  5037: 'Para aprovar a visita técnica é necessário que o plano seja gerado.',
  5022: 'Não é possível atualizar a etapa do cliente se o status for diferente de pendente',
}

export function* technicianRequest() {
  try {
    const response = yield call(api.get, '/admin/users/technicians')

    const technicians = response.data.items

    yield put(Creators.visitTechnicianSuccess(technicians))
  } catch (err) {
    yield put(Creators.visitTechnicianFailure())
  }
}

export function* visitCancelRequest(payload: IRequestCancelVisit) {
  try {
    const { scheduleId, justifyCancel } = payload

    const schedulePayload = {
      status: TechnicalVisitStatusEnum.VISIT_CANCELED,
      justification_cancel: justifyCancel,
    }

    yield call(
      api.patch,
      `admin/customers/scheduling/${scheduleId}/status`,
      schedulePayload,
    )

    yield put(Creators.visitCancelSuccess())
    toast.success('Visita técnica cancelada com sucesso!')
    history.push('/visit')
  } catch (err) {
    yield put(Creators.visitCancelFailure())
    toast.error('Problema ao cancelar a visita!')
  }
}

export function* visitLinkTechnicianRequest(payload: IRequestLinkTechnician) {
  try {
    const { technicianId, scheduleId } = payload

    yield call(
      api.patch,
      `admin/customers/scheduling/${scheduleId}/technician`,
      { technician: technicianId },
    )

    yield put(Creators.visitLinkTechnicianSuccess())
    toast.success('Técnico vínculado com Sucesso!')
    history.push('/visit')
  } catch (err) {
    yield put(Creators.visitLinkTechnicianFailure())
    toast.error('Problema ao víncular um técnico!')
  }
}

export function* visitListRequest(query: IVisitListQuery) {
  try {
    const queryFilters = queryString.stringify(
      {
        page: query.page,
        search: query.search,
        technicians: query.technicians,
        status: query.status,
        date: query.date,
        client_type: query.typeDocumentFilter,
        order: query.order,
        direction: query.direction,
      },
      { arrayFormat: 'bracket' },
    )
    const url = `/admin/customers/schedulings?${queryFilters}`

    const response = yield call(api.get, url)

    const visits = response.data

    yield put(Creators.visitListSuccess(visits))
  } catch (err) {
    yield put(Creators.visitListFailure())
  }
}

export function* visitCancelSchedulingRequest({
  scheduleId,
}: {
  scheduleId: string
}) {
  try {
    const schedulePayload = {
      status: TechnicalVisitStatusEnum.LINK_TECHNICAL,
    }

    yield call(
      api.patch,
      `admin/customers/scheduling/${scheduleId}/status`,
      schedulePayload,
    )

    yield put(Creators.visitCancelSchedulingSuccess())
    toast.success('Agendamento cancelado com sucesso!')
    history.push('/visit')
  } catch (err) {
    yield put(Creators.visitCancelSchedulingFailure())
    toast.error('Problema ao cancelar agendamento!')
  }
}

export function* visitConfirmSchedulingRequest({
  scheduleId,
}: {
  scheduleId: string
}) {
  try {
    const schedulePayload = {
      status: TechnicalVisitStatusEnum.CONFIRMED_SCHEDULE,
    }

    yield call(
      api.patch,
      `admin/customers/scheduling/${scheduleId}/status`,
      schedulePayload,
    )

    yield put(Creators.visitConfirmSchedulingSuccess())
    toast.success('Agendamento confirmado com sucesso!')
    history.push('/visit')
  } catch (err) {
    yield put(Creators.visitConfirmSchedulingFailure())
    toast.error('Problema ao confirmar o agendamento!')
  }
}

/**
 * Finish technical visit
 *
 * @param param0
 */
export function* finishTechnicalVisit({ scheduleId }: { scheduleId: string }) {
  try {
    const payload = {
      status: TechnicalVisitStatusEnum.FULFILLED,
    }

    yield call(
      api.patch,
      `admin/customers/scheduling/${scheduleId}/status`,
      payload,
    )
    yield put(Creators.visitTechnicianFinishSuccess())

    toast.success('Sua visita técnica foi concluída com sucesso!')

    history.push('/visit')
  } catch (err) {
    yield put(Creators.visitTechnicianFinishFailure())

    toast.error(
      'Algo deu errado, não conseguimos concluir sua visita técnica, tente novamente!',
    )
  }
}

export function* updateTechnicalVisitStatus({
  updateStatus,
}: {
  updateStatus: IUpdateTecnicalVisitStatus
}) {
  const scheduleId = yield select(getTechnicalVisitId)
  const getClientId = (state: RootState) => state.Client.client.id
  const clientId = yield select(getClientId)
  try {
    const { data } = yield call(
      api.patch,
      `admin/customers/scheduling/${scheduleId}/status`,
      updateStatus,
    )
    if (data.msg != null) {
      toast.error(data.msg)
      yield put(Creators.updateTechnicianStatusFailure())
    } else {
      yield put(Creators.updateTechnicianStatusSuccess())
      yield put(ClientCreators.getClientRequest({ id: clientId }))
      toast.success('Status da visita salvo com sucesso!')
    }
  } catch (error) {
    toast.error(
      errors[error.response.data.code] ||
        'Não foi possível atualizar o status da visita técnica.',
    )
    yield put(Creators.updateTechnicianStatusFailure())
  }
}
