export enum UserRoleEnum {
  TECHNICIAN = 'TECHNICIAN',
  COMMERCIAL = 'COMMERCIAL',
  ADMIN = 'ADMIN',
  FRANCHISEE = 'FRANCHISEE',
}
export enum UserStatusEnum {
  ACTIVE = 'ACTIVE',
  DISABLED = 'DISABLED',
}
