import React from 'react'

import { IUserPayload } from '@/store/ducks/Users/types'
import {
  LinearProgress,
  TextField,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Select,
  MenuItem,
  FormControl,
} from '@material-ui/core'

import * as CouponTypes from './types'

interface ICouponCreateDialog {
  users: IUserPayload[]
  open: boolean
  disabledActions: boolean
  onClose: () => void
  onConfirm: ({
    coupon_code,
    coupon_ref,
    user_id,
  }: {
    coupon_code: CouponTypes.ICoupon['coupon_code']
    coupon_ref: CouponTypes.ICoupon['coupon_ref']
    user_id: CouponTypes.ICoupon['user_id']
  }) => void
}

const CouponCreateDialog: React.FC<ICouponCreateDialog> = ({
  open,
  onClose,
  onConfirm,
  disabledActions,
  users,
}) => {
  const [code, setCode] = React.useState<string>('')
  const [refCode, setRefCode] = React.useState<string>('')
  const [user, setUser] = React.useState('')

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleChange = (event: any) => {
    setUser(event.target.value)
  }

  return (
    <div>
      <Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
        <DialogTitle>Criar cupom</DialogTitle>
        <DialogContent
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            gap: '1rem',
          }}
        >
          <TextField
            label="Código do Cupom *"
            value={code}
            variant="outlined"
            onChange={e => setCode(e.target.value)}
            disabled={disabledActions}
          />
          <TextField
            label="Referência *"
            value={refCode}
            variant="outlined"
            onChange={e => setRefCode(e.target.value)}
            disabled={disabledActions}
          />
          <FormControl variant="outlined">
            <Select
              value={user}
              onChange={handleChange}
              style={{ minWidth: '230px' }}
            >
              {users.map(_user => (
                <MenuItem key={_user.id} value={_user.id}>
                  {_user.fullname}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </DialogContent>

        {disabledActions && <LinearProgress />}

        <DialogActions>
          <Button disabled={disabledActions} onClick={onClose} color="primary">
            Cancelar
          </Button>

          <Button
            disabled={disabledActions || !code || !refCode || !user}
            onClick={() =>
              onConfirm({
                coupon_code: code,
                coupon_ref: refCode,
                user_id: user,
              })
            }
            color="primary"
            autoFocus
          >
            Criar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default CouponCreateDialog
