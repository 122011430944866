import React from 'react'

import Input from '@/components/Ui/Form/Input'
import Modal from '@/components/Ui/Modal'
import { Form } from '@unform/web'

import { Container, Title } from './styles'
import { IDialogWithInputProps as Props } from './types'

const DialogWithInput: React.FC<Props> = ({
  inputs,
  disabledAll = false,
  submitButton,
  onCloseDialog,
  opened,
  title,
  loading = false,
}): JSX.Element => {
  React.useEffect(() => {
    if (opened) {
      inputs.forEach(input => {
        if (input.defaultValue) {
          const el: HTMLInputElement | null = document.querySelector(
            `#form-inputs input#${input.name}`,
          )
          if (el) el.value = input.defaultValue
        }
      })
    }
  }, [inputs, opened])

  return (
    <Modal onClose={onCloseDialog} openModal={opened}>
      <Container>
        <Title>
          <span>{title}</span>
        </Title>
        <Form id="form-inputs" onSubmit={submitButton.onClick}>
          {inputs.map(input => (
            <Input
              name={input.name}
              key={input.label}
              placeholder={input.placeholder}
              disabled={input.disabled || disabledAll || loading}
            />
          ))}
        </Form>
      </Container>
    </Modal>
  )
}

export default DialogWithInput
