import styled from 'styled-components'

import theme from '@/assets/styles/theme'

interface IContainer {
  width: string
}

export const Container = styled.div<IContainer>`
  display: flex;
  justify-content: space-between;
  width: ${props => props.width};
  position: relative;

  @media only screen and (max-width: ${theme.breakpoints.sm}) {
    width: auto;
  }
`
