import React from 'react'

import * as Styles from './styles'

interface ITabs {
  title: string
  id: number
}

interface ITabsContent {
  id: number
  content: React.ReactNode
}

interface IGenericTabs {
  tabs: ITabs[]
  tabsContent: ITabsContent[]
}

const GenericTabs: React.FC<IGenericTabs> = ({ tabs, tabsContent }) => {
  const [toggleState, setToggleState] = React.useState(0)

  const handleChangeTab = (id: number) => setToggleState(id)

  return (
    <>
      <Styles.Container>
        <Styles.BlocTabs>
          {tabs.map(tab => (
            <Styles.Tabs
              key={tab.id}
              isActive={toggleState === tab.id}
              onClick={() => handleChangeTab(tab.id)}
            >
              <Styles.TabTitle>{tab.title}</Styles.TabTitle>
            </Styles.Tabs>
          ))}
        </Styles.BlocTabs>
        <Styles.ContentTabs>
          {tabsContent.map((tab: ITabsContent) => (
            <Styles.Content key={tab.id} active={toggleState === tab.id}>
              {tab.content}
            </Styles.Content>
          ))}
        </Styles.ContentTabs>
      </Styles.Container>
    </>
  )
}

export default GenericTabs
