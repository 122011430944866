import React, { FC } from 'react'

import * as S from '../../styles'

export enum PositionEnum {
  LEFT = 'LEFT',
  RIGHT = 'RIGHT',
  NONE = 'NONE',
}

type BigNumberCardProps = {
  card: string
  main: string | number
  side?: string
  sidePosition?: PositionEnum
}

const BigNumberCard: FC<BigNumberCardProps> = ({
  card,
  main,
  side = '',
  sidePosition = PositionEnum.NONE,
}) => {
  return (
    <S.BigNumberWrapper>
      <S.UnderlinedText>
        <S.SubTitle>{card}</S.SubTitle>
      </S.UnderlinedText>
      <S.NumberSheet>
        {sidePosition === PositionEnum.LEFT && <S.SubText>{side}</S.SubText>}
        <S.Text>{main}</S.Text>
        {sidePosition === PositionEnum.RIGHT && <S.SubText>{side}</S.SubText>}
      </S.NumberSheet>
    </S.BigNumberWrapper>
  )
}

export default BigNumberCard
