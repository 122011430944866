import React, { useState, useEffect } from 'react'
import { toast } from 'react-toastify'

import moment from 'moment'
import styled from 'styled-components'

import Arrow from '@/assets/images/icons/ic-half-arrow.svg'

import { ButtonCalendar } from './ButtonCalendar'
import { Container } from './styles'

const ArrowIcon = styled.img`
  width: 14px;
`

interface ICalendarFilterProps {
  width?: string
  onChange: (selectedDates: ISelectedDates) => void
}

export interface ISelectedDates {
  dateInitial: string
  dateFinal: string
}

const Calendar: React.FC<ICalendarFilterProps> = ({ width, onChange }) => {
  const [isOpenInitialDateCalendar, setIsOpenInitialDateCalendar] = useState<
    boolean
  >(false)
  const [isOpenFinalDateCalendar, setIsOpenFinalDateCalendar] = useState<
    boolean
  >(false)
  const [isDisabled, setIsDisabled] = useState<boolean>(true)
  const [dateInitial, setDateInitial] = useState<Date>()
  const [verifyDateFinal, setVerifyDateFinal] = useState<Date>()
  const [dateFinal, setDateFinal] = useState<Date>()

  const handleOnChangeInitial = (selectedInitialDate: Date) => {
    setDateInitial(selectedInitialDate)
  }

  const handleOnChangeFinal = (selectedFinalDate: Date) => {
    setVerifyDateFinal(selectedFinalDate)
  }

  useEffect(() => {
    if (dateInitial) {
      setIsDisabled(false)
    }

    if (verifyDateFinal) {
      const momentDateInitial = moment(dateInitial)

      const momentDateFinal = moment(verifyDateFinal)

      const dateDiff = momentDateFinal.diff(momentDateInitial)

      if (dateDiff > 0) {
        setDateFinal(verifyDateFinal)

        onChange({
          dateInitial: moment(dateInitial).format('YYYY-MM-DD'),
          dateFinal: moment(verifyDateFinal).format('YYYY-MM-DD'),
        })
      } else {
        toast.error('Escolha uma data maior que a inicial.')
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateInitial, dateFinal, verifyDateFinal])

  return (
    <>
      <Container width={width || '300px'}>
        <ButtonCalendar
          onChange={handleOnChangeInitial}
          isOpen={isOpenInitialDateCalendar}
          onClose={() => {
            setIsOpenInitialDateCalendar(false)
          }}
          labelButton="De:"
          onClick={() => {
            setIsOpenInitialDateCalendar(!isOpenInitialDateCalendar)
          }}
          value={dateInitial}
        />

        <ArrowIcon src={Arrow} alt="solar21" />

        <ButtonCalendar
          disabled={isDisabled}
          onChange={handleOnChangeFinal}
          isOpen={isOpenFinalDateCalendar}
          onClose={() => {
            setIsOpenFinalDateCalendar(false)
          }}
          labelButton="Até:"
          onClick={() => {
            setIsOpenFinalDateCalendar(!isOpenFinalDateCalendar)
          }}
          value={dateFinal}
        />
      </Container>
    </>
  )
}

export { Calendar }
