import { connectRouter } from 'connected-react-router'
import { History } from 'history'
import { combineReducers } from 'redux'

import { reducer as Auth } from './Auth'
import { reducer as Bill } from './Bill'
import { reducer as Cities } from './Cities'
import { reducer as ClientReport } from './ClientReport'
import { reducer as Client } from './Clients'
import { reducer as Common } from './common'
import { reducer as Databook } from './Databook'
import { reducer as Notification } from './Notification'
import { reducer as PaymentHistory } from './PaymentHistory'
import { reducer as State } from './State'
import { reducer as SystemConfig } from './SystemConfig'
import { reducer as User } from './Users'
import { reducer as Visit } from './Visit'

const reducers = (history: History) =>
  combineReducers({
    router: connectRouter(history),
    Auth,
    Common,
    Visit,
    User,
    Client,
    State,
    SystemConfig,
    Cities,
    Bill,
    PaymentHistory,
    ClientReport,
    Databook,
    Notification,
  })

export default reducers
