import React, { FC, ReactNode } from 'react'

import { Box } from '@material-ui/core'

import { useStyles } from '../styles'

type DCPropsType = {
  title: string
  content: ReactNode
}

const DoubleColumn: FC<DCPropsType> = ({ title, content }) => {
  const classes = useStyles()

  return (
    <Box className={classes.doubleColumn}>
      <span className={classes.doubleTitle}>{title}</span>
      <span className={classes.doubleText}>{content}</span>
    </Box>
  )
}

export default DoubleColumn
