import React, { useState, useEffect, useCallback } from 'react'
import InfiniteScroll from 'react-infinite-scroll-component'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'

import { SystemConfigTypeEnum } from '@/@types/system-config'
import PageContent from '@/components/PageContent'
import { BreadcrumbsItem } from '@/components/PageContent/Breadcrumbs'
import { InputNumber } from '@/components/Ui/InputNumber'
import { Label } from '@/components/Ui/Label'
import { SearchInput } from '@/components/Ui/SearchInput'
import { RootState } from '@/store'
import { ICitiesItem, ICitiesData } from '@/store/ducks/Cities/types'
import { StateCreators } from '@/store/ducks/State'

import { getCities } from '../../providers/cities'
import {
  Container,
  DryTariffContainer,
  InputContainer,
  CitiesContainer,
  CheckboxContainer,
  ButtonContainer,
  SubmitButton,
  CancelButton,
  SearchContainer,
  Checkbox,
} from './styles'

interface IStateEditProps {
  state: IStateEditParamState
  type: SystemConfigTypeEnum
  breadcrumbsItems: BreadcrumbsItem[]
  onClickToCancel: () => void
}

export interface IStateEditParamState {
  id: string
  initials: string
}

interface IStateCityForm {
  id: string
  isCommercialAvailable: boolean
  isResidentialAvailable: boolean
}

interface IStateForm {
  dryTariff: number
  cities: IStateCityForm[]
}

const initialCitiesData = {
  items: [],
  pagination: {
    current_page: 1,
    item_count: 0,
    items_per_page: 10,
    total_items: 0,
    total_pages: 1,
  },
}

export const StateEdit: React.FC<IStateEditProps> = ({
  state,
  type,
  breadcrumbsItems,
  onClickToCancel,
}) => {
  const dispatch = useDispatch()
  const stateToEdit = useSelector((state: RootState) => state.State.stateToEdit)

  const [stateForm, setStateForm] = useState<IStateForm>({
    dryTariff: stateToEdit?.dryTariff || 0,
    cities: [],
  })
  const [citiesQuery, setCitiesQuery] = useState({
    search: '',
    states: [state.initials],
    page: 1,
  })

  const [cities, setCities] = useState<ICitiesData>(initialCitiesData)

  const onChangeDryTariff = (dryTariff: number) =>
    setStateForm(prev => ({ ...prev, dryTariff }))

  const onSearchCities = (search: string) => {
    setCitiesQuery(prevState => ({ ...prevState, search, page: 1 }))
    setCities(initialCitiesData)
  }

  const getIsChecked = (city: ICitiesItem): boolean => {
    const cityForm = stateForm.cities.find(cityForm => cityForm.id === city.id)

    let isChecked = false
    if (cityForm) {
      if (type === SystemConfigTypeEnum.COMMERCIAL)
        isChecked = cityForm.isCommercialAvailable
      else if (type === SystemConfigTypeEnum.RESIDENTIAL)
        isChecked = cityForm.isResidentialAvailable
    } else if (!cityForm) {
      if (type === SystemConfigTypeEnum.COMMERCIAL)
        isChecked = city.isCommercialAvailable
      else if (type === SystemConfigTypeEnum.RESIDENTIAL)
        isChecked = city.isResidentialAvailable
    }

    return isChecked
  }

  const updateCities = (city: ICitiesItem) => {
    const cityInStateForm = stateForm.cities.find(
      cityForm => cityForm.id === city.id,
    )

    const cityHandled = ((cityParam: IStateCityForm) => {
      const cityParsed = JSON.parse(JSON.stringify(cityParam))

      if (cityParam.id === cityParsed.id) {
        if (type === SystemConfigTypeEnum.COMMERCIAL)
          cityParsed.isCommercialAvailable = !cityParsed.isCommercialAvailable
        else if (type === SystemConfigTypeEnum.RESIDENTIAL)
          cityParsed.isResidentialAvailable = !cityParsed.isResidentialAvailable
      }

      return cityParsed
    })(cityInStateForm || city)

    setStateForm(prevStateForm => {
      if (cityInStateForm)
        return {
          ...prevStateForm,
          cities: prevStateForm.cities.map(cityForm =>
            cityForm.id === city.id ? cityHandled : cityForm,
          ),
        }

      return {
        ...prevStateForm,
        cities: [...prevStateForm.cities, cityHandled],
      }
    })
  }

  const onClickToSubmit = () =>
    dispatch(
      StateCreators.updateStateRequest({
        id: state.id,
        dryTariff: stateForm.dryTariff,
        cities: stateForm.cities,
      }),
    )

  const loadMoreCities = useCallback(async () => {
    try {
      const citiesResponse = await getCities(citiesQuery)

      setCities(prevState => ({
        items: [...prevState.items, ...citiesResponse.items],
        pagination: citiesResponse.meta,
      }))
      setCitiesQuery(prevState => ({
        ...prevState,
        page: prevState.page + 1,
      }))
    } catch (err) {
      toast.error('Não foi possível carregar mais cidades.')
    }
  }, [citiesQuery])

  useEffect(() => {
    if (!cities.items.length) loadMoreCities()
  }, [cities.items.length, dispatch, loadMoreCities, state])

  useEffect(() => {
    dispatch(StateCreators.getStateRequest({ stateId: state.id }))
  }, [dispatch, state.id])

  return (
    <>
      {stateToEdit && (
        <PageContent
          title={stateToEdit.name}
          breadcrumbsItems={breadcrumbsItems.concat({ name: stateToEdit.name })}
        >
          <Container>
            <DryTariffContainer>
              <Label>Tarifa Seca</Label>
              <span>
                Configure o valor da Tarifa Seca para cálculo do simulador.
              </span>
              <InputContainer>
                <InputNumber
                  increment={0.01}
                  initialValue={stateToEdit.dryTariff}
                  onChange={onChangeDryTariff}
                />
              </InputContainer>
            </DryTariffContainer>

            <CitiesContainer>
              <Label>Configurar Cidades</Label>
              <span>Selecione as Cidades que o seu serviço atenda.</span>
              <SearchContainer>
                <SearchInput onChange={onSearchCities} />
              </SearchContainer>

              <CheckboxContainer id="scrollable-container">
                <InfiniteScroll
                  dataLength={cities.items.length}
                  hasMore={cities.pagination.total_pages > citiesQuery.page}
                  next={loadMoreCities}
                  loader=""
                  scrollableTarget="scrollable-container"
                >
                  {cities.items.map(city => (
                    <Checkbox
                      key={city.id}
                      checked={getIsChecked(city)}
                      label={city.name}
                      onClick={() => updateCities(city)}
                    />
                  ))}
                </InfiniteScroll>
              </CheckboxContainer>
            </CitiesContainer>

            <ButtonContainer>
              <CancelButton color="cancel" onClick={onClickToCancel}>
                Cancelar
              </CancelButton>
              <SubmitButton onClick={onClickToSubmit}>
                Salvar alterações
              </SubmitButton>
            </ButtonContainer>
          </Container>
        </PageContent>
      )}
    </>
  )
}
