import styled from 'styled-components'

import theme from '@/assets/styles/theme'
import ButtonComponent from '@/components/Ui/Button'
import { CheckBox } from '@/components/Ui/CheckBox'

export const Container = styled.div`
  padding: 25px;
  height: 71vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

export const DryTariffContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 15px 0;
  border-bottom: 2px solid ${theme.colors.gray[400]};
`

export const InputContainer = styled.div`
  padding: 15px 0;
  margin-bottom: 15px;
`

export const CitiesContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 15px 0;
  padding-top: 40px;
  max-width: 675px;
`

export const SearchContainer = styled.div`
  width: 250px;
  margin-top: 25px;
  margin-bottom: 15px;

  @media only screen and (max-width: ${theme.breakpoints.sm}) {
    width: 100%;
  }
`

export const CheckboxContainer = styled.div`
  background: ${theme.colors.gray[200]};
  height: 300px;
  padding: 20px;
  overflow-y: scroll;
`

export const Checkbox = styled(CheckBox)`
  margin-bottom: 25px;

  &:last-child {
    margin: 0;
  }
`

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 15px;

  @media only screen and (max-width: ${theme.breakpoints.sm}) {
    justify-content: flex-start;
  }
`

export const CancelButton = styled(ButtonComponent)`
  width: 120px;
  height: 40px;
`

export const SubmitButton = styled(ButtonComponent)`
  width: 170px;
  height: 40px;
`
