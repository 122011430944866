import { toast } from 'react-toastify'

import { call, put } from 'redux-saga/effects'

import api from '@/services/api'
import { StateCreators } from '@/store/ducks/State'
import {
  IStatePayload,
  IStateData,
  IUpdateStatePayload,
} from '@/store/ducks/State/types'

export function* getState({ stateId }: { stateId: string }) {
  try {
    const { data: state }: { data: IStateData } = yield call(
      api.get,
      `/admin/states/${stateId}`,
    )

    yield put(
      StateCreators.getStateSuccess({
        name: state.name,
        dryTariff: parseFloat(state.dry_tariff),
        initials: state.initials,
      }),
    )
  } catch {
    toast.error('Não foi possível encontrar o Estado.')
  }
}

export function* getStates() {
  try {
    const { data: states } = yield call(api.get, '/admin/states')

    yield put(StateCreators.getStatesSuccess(states))
  } catch (err) {
    yield put(StateCreators.getStatesFailure())
    toast.error('Não foi possível carregar os Estados.')
  }
}

export function* createState(state: IStatePayload) {
  try {
    const { data: state_response } = yield call(api.post, '/admin/states', {
      name: state.name,
      initials: state.initials,
      dry_tariff: state.dryTariff,
    })

    yield put(StateCreators.createStateSuccess(state_response))
  } catch (err) {
    yield put(StateCreators.createStateFailure())

    if (err.response?.data?.code === 6001) toast.error('Esse Estado já existe.')
    else toast.error('Não foi possível criar o Estado.')
  }
}

export function* updateState({
  state: statePayload,
}: {
  state: IUpdateStatePayload
}) {
  try {
    const stateToSend = {
      dry_tariff: statePayload.dryTariff,
      cities: statePayload.cities.map(city => ({
        id: city.id,
        is_commercial_available: city.isCommercialAvailable,
        is_residential_available: city.isResidentialAvailable,
      })),
    }

    yield call(api.put, `/admin/states/${statePayload.id}`, stateToSend)

    yield put(StateCreators.updateStateSuccess())
  } catch (err) {
    yield put(StateCreators.updateStateFailure())

    toast.error('Não foi possível atualizar o Estado.')
  }
}

export function* deleteState({ stateId }: { stateId: IStateData['id'] }) {
  try {
    yield call(api.delete, `/admin/states/${stateId}`)

    yield put(StateCreators.deleteStateSuccess({ stateId }))
  } catch {
    yield put(StateCreators.deleteStateFailure())
    toast.error('Não foi possível deletar o Estado.')
  }
}
