// import { toast } from 'react-toastify'

import queryString from 'query-string'
import { call, put, select } from 'redux-saga/effects'

import api from '@/services/api'
import { RootState } from '@/store'
import { PaymentHistoryCreators } from '@/store/ducks/PaymentHistory'
import { IQueryPaymentHistory } from '@/store/ducks/PaymentHistory/types'

export function* getPaymentHistory(query: IQueryPaymentHistory) {
  const getClientId = (state: RootState) => state.Client.client.id
  const clientId = yield select(getClientId)
  try {
    const queryPaymentHistory = queryString.stringify(
      {
        page: query.page,
        limit: query.limit,
        creation_dates: query.creation_dates,
      },
      { arrayFormat: 'bracket' },
    )
    const { data: paymentHistory } = yield call(
      api.get,
      `/admin/customers/${clientId}/payments?${queryPaymentHistory}`,
    )

    yield put(PaymentHistoryCreators.getPaymentHistorySuccess(paymentHistory))
  } catch (error) {
    // error.response.data.code === 5021
    //   ? toast.error('O cliente não possui histórico de pagamento')
    //  : toast.error('Não foi possível listar o histórico de pagamento')
    yield put(PaymentHistoryCreators.getPaymentHistoryFailure())
  }
}
