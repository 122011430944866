import styled from 'styled-components'

import theme from '@/assets/styles/theme'

export interface InputProps {
  width?: string
  marginRight?: string
}

export const Container = styled.div`
  display: block;
  padding: 20x;
  overflow-y: scroll;

  ::-webkit-scrollbar {
    display: none;
  }
`

export const Header = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  @media only screen and (max-width: ${theme.breakpoints.sm}) {
    justify-content: space-between;
  }

  & > h1 {
    font-size: 22px;
    font-weight: 600;
    color: ${theme.colors.gray[600]};
  }
`

export const FieldsLabel = styled.div`
  @media only screen and (max-width: ${theme.breakpoints.sm}) {
    margin-bottom: 2px;
  }
`
export const Fields = styled.span`
  font-size: 16px;
  font: Regular 16px/22px Roboto;
  opacity: 0.7;
`
export const Font = styled.b`
  font: Bold 16px/22px Roboto;
  opacity: 1;
`

export const LabelContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: baseline;
  padding: 0.5rem 0;

  > label {
    font-size: 1.5rem;
    font-family: bold, Roboto;
    font-weight: 500;
    padding: 0;
  }

  @media only screen and (max-width: 750px) {
    display: grid;
    grid-template-columns: 1fr;
    row-gap: 0.5rem;

    > label {
      font-size: 1.3rem;
    }
  }
`

export const TotalLabelContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: baseline;
  padding: 0 0 0.5rem;
  width: 100%;

  > label {
    font-size: 1.5rem;
    font-family: bold, Roboto;
    font-weight: 500;
    padding: 0;
  }

  @media only screen and (max-width: 750px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
`
export const ContainerLegal = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 20px;
`
export const ContainerTariff = styled.div`
  display: flex;
  margin-top: 20px;
  width: 100%;
  padding: 20px;
  background-color: ${theme.colors.gray[100]};
  justify-content: flex-start;

  @media only screen and (max-width: ${theme.breakpoints.sm}) {
    flex-direction: column;
    justify-content: space-between;
  }
`

export const ContainerConsumeEnergy = styled.div`
  display: flex;
  margin-top: 20px;
  width: 100%;
  padding: 20px;
  background-color: ${theme.colors.gray[100]};
  justify-content: flex-start;

  @media only screen and (max-width: ${theme.breakpoints.sm}) {
    flex-direction: column;
    justify-content: space-between;
  }
`
export const ContainerColumnTariff = styled.div`
  display: flex;
  width: 40%;
  height: 60px;
  flex-direction: column;
  justify-content: space-between;

  @media only screen and (max-width: ${theme.breakpoints.sm}) {
    display: block;
    width: 100%;
    height: auto;
  }
`

export const ContainerColumnTariffGG = styled.div`
  display: flex;
  width: 40%;
  height: 72px;
  flex-direction: column;
  justify-content: space-between;

  @media only screen and (max-width: ${theme.breakpoints.sm}) {
    display: block;
    width: 100%;
    height: auto;
  }
`
export const ContainerColumnConsumeEnergy = styled.div`
  display: flex;
  width: 40%;
  height: 60px;
  flex-direction: column;
  justify-content: space-between;

  @media only screen and (max-width: ${theme.breakpoints.sm}) {
    display: block;
    width: 100%;
    height: auto;
  }
`

export const GroupButton = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: auto auto;
  column-gap: 1rem;
  justify-content: end;
  padding: 0 0 25px;

  @media only screen and (max-width: 750px) {
    display: flex;
    flex-direction: column-reverse;
    row-gap: 1rem;
  }
`
