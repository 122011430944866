import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RouteComponentProps } from 'react-router-dom'

import { IObjectLiteral } from '@/@types/common/object-literal'
import { IPermission } from '@/@types/Permission'
import { HeaderTab, ITab } from '@/components/HeaderTab'
import PageContent from '@/components/PageContent'
import StatusCard from '@/components/Ui/Status/StatusCard'
import ActivityLog from '@/modules/Customer/pages/Main/ClientDetails/ActivityLog'
import { PaymentHistory } from '@/modules/Customer/pages/Main/PaymentHistory'
import history from '@/services/history'
import { RootState } from '@/store'
import { Creators } from '@/store/ducks/Clients'

import { getClientStepStyle } from '../../utils/getClientStepStyle'
import { getCurrentClientStepType } from '../../utils/getCurrentClientStepType'
import { Bill } from './Bill'
import { ClientDetails } from './ClientDetails'
import CustomerSuccess from './CustomerSuccess'
import { Databook } from './Databook'
import { Installation } from './Installation'
import Lending from './Lending'
import NF from './NF'
import { ReportFourPage } from './ReportFourPage'
import { Signature } from './Signature'
import { Simulation } from './Simulation'
import { Container, Header, Name, ClientInfoContainer } from './styles'
import { TechnicalVisit } from './TechnicalVisit'

// import VertConfigTab from './Vert'

// import ClientScore from './ClientDetails/ClientScore'

interface IClient {
  id: string
  section: string
}

const ClientEdit: React.FC<RouteComponentProps<IClient>> = ({ match }) => {
  const permission = useSelector((state: RootState) => state.Auth.role)
  const [isLending, setIsLending] = useState(false)
  const isFranchisee = permission === IPermission.FRANCHISEE

  const { id, section } = match.params

  const getNameTab = () => {
    let name = 'Contratos'

    if (isFranchisee) name = 'Documento'

    return name
  }

  const tabHeaderOptions: IObjectLiteral = {
    details: 'Detalhes',
    simulation: 'Simulação',
    bill: 'Conta de Luz',
    technicalVisit: isFranchisee ? 'Proposta' : 'Visita Técnica',
    supply: 'Supply',
    signature: isLending ? getNameTab() : 'Assinatura do Plano',
    databook: 'Databook',
    paymentHistory: 'Histórico de Pagamento',
    activityLog: 'Log de Atividades',
    customerSuccess: 'Customer Success',
    vert: 'Vert',
    lending: 'Financiamento',
    installation: 'Instalação',
  }

  const tabs: ITab[] = [
    { name: 'Detalhes', value: 'details' },
    { name: 'Simulação', value: 'simulation' },
    { name: 'Conta de Luz', value: 'bill' },
    { name: 'Visita Técnica', value: 'technicalVisit' },
    { name: 'Supply', value: 'supply' },
    {
      name: isLending ? getNameTab() : 'Assinatura do Plano',
      value: 'signature',
    },
    { name: 'Databook', value: 'databook' },
    { name: 'Histórico de Pagamento', value: 'paymentHistory' },
    { name: 'Log de Atividades', value: 'activityLog' },
    { name: 'Customer Success', value: 'customerSuccess' },
    { name: 'Vert', value: 'vert' },
    { name: 'Financiamento', value: 'lending' },
    { name: 'Instalação', value: 'installation' },
  ]

  const filteredTabs = tabs.filter(tab => {
    if (isFranchisee) {
      return (
        tab.value !== 'simulation' &&
        tab.value !== 'signature' &&
        tab.value !== 'databook' &&
        tab.value !== 'paymentHistory' &&
        tab.value !== 'activityLog' &&
        tab.value !== 'customerSuccess' &&
        tab.value !== 'vert'
      )
    }
    // If user is not franchisee, keep all tabs
    return true
  })

  const [breadcrumbs, setBreadcrumbs] = useState<string>(
    tabHeaderOptions[section],
  )
  const dispatch = useDispatch()
  const client = useSelector((state: RootState) => state.Client.client)

  const currentStepType = getCurrentClientStepType(client.steps)

  useEffect(() => {
    dispatch(Creators.getClientRequest({ id }))
    if (client.subscription_lending === 'LENDING') setIsLending(true)
  }, [dispatch, id, client.subscription_lending])

  const pageTabs: IObjectLiteral<React.ReactNode> = {
    details: <ClientDetails />,
    bill: <Bill />,
    databook: <Databook />,
    simulation: <Simulation />,
    technicalVisit: <TechnicalVisit />,
    signature: <Signature />,
    paymentHistory: <PaymentHistory />,
    activityLog: <ActivityLog />,
    customerSuccess: <CustomerSuccess />,
    supply: <ReportFourPage />,
    vert: <NF />,
    lending: <Lending />,
    installation: <Installation />,
  }

  return (
    <PageContent
      title="Clientes"
      breadcrumbsItems={[
        {
          name: 'Clientes',
        },
        {
          name: breadcrumbs,
        },
      ]}
    >
      <Container>
        <Header>
          <ClientInfoContainer>
            <Name>{client.name}</Name>
            {currentStepType && (
              <StatusCard
                style={getClientStepStyle(currentStepType)}
                width="auto"
              />
            )}
          </ClientInfoContainer>
          <HeaderTab
            floaded
            tabNames={filteredTabs}
            tabSelected={breadcrumbs}
            onTabClick={tab => {
              setBreadcrumbs(tab.name)
              history.push(`/customer/${id}/${tab.value}`)
            }}
          />
        </Header>
        <>{pageTabs[section]}</>
      </Container>
    </PageContent>
  )
}

export { ClientEdit }
