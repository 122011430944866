/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react'

import { IPermission } from '@/@types/Permission'
import PageContent from '@/components/PageContent'
import Button from '@material-ui/core/Button'
import ButtonGroup from '@material-ui/core/ButtonGroup'
import ListIcon from '@material-ui/icons/List'
import ViewWeekIcon from '@material-ui/icons/ViewWeek'

import CardView from './CardView'
import TableView from './TableView'

const Customers: React.FC = () => {
  const [userType, setUserType] = useState<string>('')
  const [view, setView] = useState<string>('card')

  useEffect(() => {
    const permission = localStorage.getItem('permission')
    if (permission !== null) setUserType(permission)
  }, [])

  return (
    <PageContent
      title="Clientes"
      add_customer={userType === IPermission.FRANCHISEE}
    >
      <div
        style={{
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-end',
          gap: '0.5rem',
          padding: '0.5rem 1rem',
        }}
      >
        <span>Exibição</span>
        <ButtonGroup size="large" variant="text">
          <Button onClick={() => setView('card')} disabled={view === 'card'}>
            <ViewWeekIcon />
          </Button>
          <Button onClick={() => setView('table')} disabled={view === 'table'}>
            <ListIcon />
          </Button>
        </ButtonGroup>
        {/* <Switch
          checked={view}
          onChange={handleChangeView}
          inputProps={{ 'aria-label': 'primary checkbox' }}
        /> */}
      </div>
      {view === 'table' ? <TableView /> : <CardView />}
    </PageContent>
  )
}

export { Customers }
