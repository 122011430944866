import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: auto;
`

export const ContainerIframe = styled.div`
  width: 100%;
  height: 730px;
  overflow: hidden;
`

export const Title = styled.div`
  padding-bottom: 20px;
`
