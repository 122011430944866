import { toast } from 'react-toastify'

import { isEmpty } from 'lodash'
import queryString from 'query-string'
import { call, put, select } from 'redux-saga/effects'

import { IObjectLiteral } from '@/@types/common/object-literal'
import api from '@/services/api'
import { RootState } from '@/store'
import {
  IClientBoard,
  IClientDocument,
  IMessage,
  IClientDocumentStatus,
  IClientEnergyConsumption,
  IClientItem,
  IClientLegal,
  IClientSimulationByClientId,
  IClientTariff,
  IListClientQuery,
  IFile,
  ProjectStatusType,
  IFreeMonth,
  IPartnership,
  IClientInstallationCost,
} from '@/store/ducks/Clients/types'
import { Creators as CreatorsVisit } from '@/store/ducks/Visit'

import { Creators } from '../../ducks/Clients'

const errors: IObjectLiteral = {
  5027: 'Por favor preencha todos os campos acima para gerar o plano !',
  5029: 'Por favor preencha todos os campos acima para gerar o plano !',
  5036: 'Preencha os dados da conta de luz !',
}

const getClientId = (state: RootState) => state.Client.client.id
const getCostumerSuccessId = (state: RootState) =>
  state.Client.client.customer_success?.id

export function* getClients({
  query = { page: 1 },
}: {
  query?: IListClientQuery
}) {
  let clientQuery = queryString
    .stringify(
      {
        page: query.page,
        search: query.search,
        client_type: query.clientType,
        city_states: query.state,
        cities: query.cityIds,
        current_steps: query.currentSteps,
        bill_status: query.bill_status,
        proposal_status: query.proposal_status,
        visit_status: query.visit_status,
        date: query.date,
      },
      { arrayFormat: 'bracket' },
    )
    .replace('BILL_PENDING', 'BILL')
    .replace('BILL_DENIED', 'BILL')
    .replace('TECHNICAL_VISIT_CONFIRMED_SCHEDULE', 'TECHNICAL_VISIT')
    .replace('TECHNICAL_VISIT_FULFILLED', 'TECHNICAL_VISIT')
    .replace('TECHNICAL_VISIT', 'TECHNICAL_VISIT')

  if (query.bill_status === '') {
    clientQuery = clientQuery.replaceAll('bill_status=', '')
    clientQuery = clientQuery.replaceAll('bill_status=PENDING&', '')
    clientQuery = clientQuery.replaceAll('bill_status=DENIED&', '')
    clientQuery = clientQuery.replaceAll('bill_status=APPROVED&', '')
  }
  if (query.visit_status === '') {
    clientQuery = clientQuery.replace('visit_status=', '')
    clientQuery = clientQuery.replace('visit_status=CONFIRMED_SCHEDULE&', '')
    clientQuery = clientQuery.replace('visit_status=FULFILLED&', '')
    clientQuery = clientQuery.replace('visit_status=NULL&', '')
  }
  if (query.proposal_status === '') {
    clientQuery = clientQuery.replace('proposal_status=', '')
    clientQuery = clientQuery.replace('proposal_status=PROPOSAL_INITIAL&', '')
    clientQuery = clientQuery.replace('proposal_status=PROPOSAL_FINAL&', '')
  }

  if (query.order)
    clientQuery = clientQuery.concat(
      `&direction=${query.direction}&order=${query.order}`,
    )
  try {
    const { data: clients } = yield call(
      api.get,
      `/admin/customers?${clientQuery}`,
    )

    yield put(Creators.getClientsSuccess(clients))
  } catch (error) {
    toast.error('Não foi possível listar os clientes')
    yield put(Creators.getClientsFailure())
  }
}

export function* getClient({ id }: { id: string }) {
  try {
    const { data: client } = yield call(api.get, `/admin/customers/${id}`)

    yield put(Creators.getClientSuccess(client))
  } catch (error) {
    toast.error('Não foi possível listar os clientes')
    yield put(Creators.getClientFailure())
  }
}

export function* editClient({ client }: { client: IClientItem }) {
  const clientId = yield select(getClientId)

  try {
    const newClientData = {
      name: client.name,
      email: client.email,
      state: client.city.state,
      city_id: client.city.id,
      phone: client.phone,
      address: client.address,
      address_complement: client.address_complement,
      address_neighborhood: client.address_neighborhood,
      address_number: client.address_number,
      postal_code: client.postal_code,
      document_value: client.type.value,
    }
    yield call(api.put, `/admin/customers/${clientId}`, newClientData)
    yield put(Creators.editClientSuccess())
    yield put(Creators.setIsEditClientDetails(false))
  } catch (error) {
    toast.error('Não foi possível alterar o usuário')
    yield put(Creators.editClientFailure())
  }
}

export function* getClientSimulation({ id }: { id: string }) {
  try {
    const { data: planSimulated } = yield call(
      api.get,
      `/admin/customers/${id}/selected-plan-simulated`,
    )

    yield put(Creators.getClientSimulationSuccess(planSimulated))
  } catch (error) {
    toast.error('Não foi possível mostrar a simulação do plano')
    yield put(Creators.getClientSimulationFailure())
  }
}

export function* getClientPlanRequest({ id }: { id: string }) {
  try {
    const { data: clientPlan } = yield call(
      api.get,
      `/admin/customers/${id}/plans/selected-plan`,
    )

    yield put(Creators.getClientPlanSuccess(clientPlan))
  } catch (err) {
    yield put(Creators.getClientPlanFailure())
  }
}

export function* getClientLegalRequest({ id }: { id: string }) {
  try {
    const { data: clientLegal } = yield call(
      api.get,
      `/admin/customers/${id}/legitimates`,
    )

    yield put(Creators.getClientLegalSuccess(clientLegal))
  } catch (err) {
    yield put(Creators.getClientLegalFailure())
  }
}

export function* editClientLegalRequest({
  clientLegal,
}: {
  clientLegal: IClientLegal
}) {
  try {
    const client = {
      name: clientLegal.name,
      legal_document: clientLegal.legal_document,
      email: clientLegal.email,
      phone: clientLegal.phone,
      state: clientLegal.state,
      city: clientLegal.city,
    }

    yield call(
      api.post,
      `/admin/customers/${clientLegal.id}/legitimates`,
      client,
    )

    yield put(Creators.editClientLegalSuccess())
    toast.success('Representante legal alterado com sucesso!')
  } catch (error) {
    toast.error('Não foi possível editar o representante legal')
    yield put(Creators.editClientLegalFailure())
  }
}

export function* getClientDocumentsRequest({ id }: { id: string }) {
  try {
    const { data: documents } = yield call(
      api.get,
      `/admin/customers/${id}/documents`,
    )

    yield put(Creators.getClientDocumentsSuccess(documents))
  } catch (err) {
    yield put(Creators.getClientDocumentsFailure())
  }
}

export function* editClientDocumentsStatusRequest({
  clientDocumentsStatus,
}: {
  clientDocumentsStatus: IClientDocumentStatus[]
}) {
  try {
    const clientDocuments: IClientDocument[] = yield select(
      (state: RootState) => state.Client.clientDocuments,
    )
    for (let i = 0; i < clientDocumentsStatus.length; i += 1) {
      yield call(
        api.patch,
        `/admin/customers/documents/${clientDocumentsStatus[i].id}`,
        {
          status: clientDocumentsStatus[i].status,
        },
      )
    }

    const newClientDocuments = clientDocuments.map(clientDocument => {
      const newClientDocumentStatusData = clientDocumentsStatus.find(
        item => item.id === clientDocument.id,
      )
      return {
        ...clientDocument,
        ...newClientDocumentStatusData,
      }
    })

    yield put(Creators.editClientDocumentsStatusSuccess(newClientDocuments))
    toast.success('Status dos documentos alterados com sucesso!')
  } catch (error) {
    toast.error('Problema ao alterar os status dos documentos!')
    yield put(Creators.editClientDocumentsStatusFailure())
  }
}

export function* getClientProjectInfoRequest({ id }: { id: string }) {
  try {
    const { data: clientProjectInfo } = yield call(
      api.get,
      `/admin/customers/${id}/project-infos`,
    )

    yield put(Creators.getClientProjectInfoSuccess(clientProjectInfo))
  } catch (err) {
    yield put(Creators.getClientProjectInfoFailure())
  }
}

export function* editClientTariffRequest({
  clientTariff,
}: {
  clientTariff: IClientTariff
}) {
  try {
    yield call(api.post, `/admin/customers/${clientTariff.id}/project-infos`, {
      dealership_tax: clientTariff.dealership_tax,
      not_compensated_tax: clientTariff.not_compensated_tax,
      solar21_tax: clientTariff.solar21_tax,
    })

    toast.success('Tarifa alterada com sucesso!')
    yield put(Creators.editClientTariffSuccess())
  } catch (err) {
    toast.error('Problema ao alterar a tarifa!')
    yield put(Creators.editClientTariffFailure())
  }
}

export function* editClientCommissionInstallationCostRequest({
  clientInstallationCost,
}: {
  clientInstallationCost: IClientInstallationCost
}) {
  try {
    yield call(
      api.post,
      `/admin/customers/${clientInstallationCost.id}/project-infos`,
      {
        commission: clientInstallationCost.commission,
        installation_material_cost:
          clientInstallationCost.installation_material_cost,
      },
    )

    toast.success('Alterado com sucesso!')
  } catch (err) {
    toast.error('Erro ao realizar alteração!')
  }
}

export function* editClientEnergyConsumptionRequest({
  clientEnergyConsumption,
}: {
  clientEnergyConsumption: IClientEnergyConsumption
}) {
  try {
    yield call(
      api.post,
      `/admin/customers/${clientEnergyConsumption.id}/project-infos`,
      {
        kwh_availability: clientEnergyConsumption.kwh_availability,
        concurrency_factor: clientEnergyConsumption.concurrency_factor,
      },
    )

    toast.success('Consumo de Energia alterado com sucesso!')
    yield put(Creators.editClientEnergyConsumptionSuccess())
  } catch (err) {
    toast.error('Problema ao alterar o consumo de energia!')
    yield put(Creators.editClientEnergyConsumptionFailure())
  }
}

export function* editClientBoardRequest({
  clientBoard,
}: {
  clientBoard: IClientBoard
}) {
  try {
    yield call(api.post, `/admin/customers/${clientBoard.id}/project-infos`, {
      inverter_power: clientBoard.inverter_power,
      module_power: clientBoard.module_power,
      number_of_modules: clientBoard.number_of_modules,
      total_losses: clientBoard.total_losses,
      module_name: clientBoard.module_name,
      inverter_name: clientBoard.inverter_name,
      specific_production: clientBoard.specific_production,
      optimizer_quantity: clientBoard.optimizer_quantity,
    })

    toast.success('Info do Projeto alterado com sucesso!')
    yield put(Creators.editClientBoardSuccess())
  } catch (err) {
    toast.error('Problema ao alterar a Info do Projeto!')
    yield put(Creators.editClientBoardFailure())
  }
}
export function* getClientSimulationByClientIdRequest({
  simulationPayload,
}: {
  simulationPayload: IClientSimulationByClientId
}) {
  try {
    const simulationData = {
      solar21_tax: simulationPayload.solar21_tax,
      not_compensated_tax: simulationPayload.not_compensated_tax,
      dealership_tax: simulationPayload.dealership_tax,
      kwh_availability: simulationPayload.kwh_availability,
      concurrency_factor: simulationPayload.concurrency_factor,
      module_power: simulationPayload.module_power,
      total_losses: simulationPayload.total_losses,
      number_of_modules: simulationPayload.number_of_modules,
      bill_value: simulationPayload.bill_value,
    }

    const { data: clientSimulatedPlanValues } = yield call(
      api.post,
      `/admin/customers/${simulationPayload.id}/plans/simulator`,
      simulationData,
    )

    yield put(
      Creators.getClientSimulationByClientIdSuccess(clientSimulatedPlanValues),
    )
  } catch (err) {
    yield put(Creators.getClientSimulationByClientIdFailure())
  }
}

export function* setClientGeneratePlansRequest({ id }: { id: string }) {
  try {
    yield call(api.post, `/admin/customers/${id}/plans`)

    toast.success('Plano gerado com sucesso!')
    yield put(Creators.setClientGeneratePlansSuccess())
    yield put(CreatorsVisit.setIsReportFour(false))
  } catch (err) {
    toast.error(
      errors[err.response.data.code] ||
        'Não foi possível gerar os planos do cliente.',
    )
    yield put(Creators.setClientGeneratePlansFailure())
  }
}

export function* confirmInstalation() {
  const clientId = yield select(getClientId)
  try {
    yield call(api.post, `/admin/customers/${clientId}/plans/confirm-signature`)
    yield put(Creators.confirmInstalationSuccess())
    yield put(Creators.getClientRequest({ id: clientId }))
    toast.success('Confirmada a instalação!')
  } catch (error) {
    toast.error('Não foi possível confirmar a instalação')
    yield put(Creators.confirmInstalationFailure())
  }
}

export function* getCostumerMessages() {
  const costumerId = yield select(getCostumerSuccessId)

  try {
    const { data } = yield call(
      api.get,
      `/admin/customers_success/${costumerId}/get_messages`,
    )
    const messages: IMessage[] = data.map((_data: IMessage) => ({
      message: _data.message,
      id: _data.id,
      created_at: _data.created_at,
      client_customer_success_id: _data.client_customer_success_id,
    }))
    yield put(Creators.confirmMessagesSuccess(messages))
  } catch (error) {
    toast.error('Não foi possível receber as mensagens do cliente')
    yield put(Creators.confirmMessagesFailure())
  }
}

export function* saveCostumerMessage({ postMessage }: { postMessage: string }) {
  const clientId = yield select(getClientId)

  try {
    const custumerSuccessMessage = {
      client_id: clientId,
      message: postMessage,
    }
    yield call(
      api.post,
      '/admin/customers_success/saveMessage',
      custumerSuccessMessage,
    )
    yield put(Creators.confirmSaveMessageSuccess())
    toast.success('Mensagem postada com sucesso!')
    yield put(Creators.confirmMessagesRequest())
  } catch (error) {
    toast.error('Não foi possível postar a mensagem ao cliente')
    yield put(Creators.confirmSaveMessageFailure())
  }
}

export function* updatedProjectStatus({
  status,
}: {
  status: ProjectStatusType
}) {
  const clientId = yield select(getClientId)
  try {
    const updatedStatus = {
      client_id: clientId,
      status,
    }

    yield call(api.put, '/admin/customers_success/update_status', updatedStatus)
    yield put(Creators.confirmUpdateStatusSuccess())
    toast.success('Status do projeto atualizado com sucesso!')
    yield put(Creators.confirmMessagesRequest())
  } catch (error) {
    toast.error('Não foi possível atualizar o status do projeto')
    yield put(Creators.confirmUpdateStatusFailure())
  }
}

export function* getCostumerFiles() {
  const costumerId = yield select(getCostumerSuccessId)

  try {
    const { data } = yield call(
      api.get,
      `/admin/customers_success/${costumerId}/get_files`,
    )
    const files: IFile[] = data.map((_data: IFile) => ({
      id: _data.id,
      created_at: _data.created_at,
      client_customer_success_id: _data.client_customer_success_id,
      name: _data.name,
      url: _data.url,
    }))
    yield put(Creators.confirmFilesSuccess(files))
  } catch (error) {
    toast.error('Não foi possível receber os arquivos do cliente')
    yield put(Creators.confirmFilesFailure())
  }
}

export function* updatedUploadEnable({
  uploadAvailable,
}: {
  uploadAvailable: boolean
}) {
  const clientId = yield select(getClientId)

  const payload = {
    client_id: clientId,
    upload_available: uploadAvailable,
  }

  try {
    yield call(
      api.put,
      '/admin/customers_success/update_status_upload',
      payload,
    )
    yield put(Creators.getClientRequest({ id: clientId }))
    toast.success('Status de upload de documentos atualizado!')
    yield put(Creators.confirmUploadEnableSuccess())
  } catch (error) {
    toast.error('Não foi possível atualizar o status de upload de documentos')
    yield put(Creators.confirmUploadEnableFailure())
  }
}

export function* sendContactRequest({
  contactAvailable,
}: {
  contactAvailable: boolean
}) {
  const clientId = yield select(getClientId)

  const payload = {
    client_id: clientId,
    contact_available: contactAvailable,
  }

  try {
    yield call(
      api.put,
      '/admin/customers_success/update_status_contact',
      payload,
    )
    yield put(Creators.confirmContactNeededSuccess())
    toast.success('Solicitação de contato atualizada com sucesso!')
  } catch (error) {
    toast.error('Não foi possível atualizar a solicitação de contanto')
    yield put(Creators.confirmContactNeededFailure())
  }
}

export function* setCustomerSuccessTables() {
  const client_id = yield select(getClientId)

  try {
    const clientId = { client_id }
    const { data } = yield call(
      api.post,
      '/admin/customers_success/create',
      clientId,
    )

    yield put(Creators.getClientRequest({ id: client_id }))
    yield put(Creators.confirmClientSuccessTablesSuccess())
    if (!isEmpty(data)) toast.success('Tabelas de CS criadas com sucesso')
  } catch (error) {
    toast.error('Houve erro na criação das tabelas de CS')
    yield put(Creators.confirmClientSuccessTablesFailure())
  }
}

export function* getClientFreeMonth() {
  const client_id = yield select(getClientId)

  try {
    const { data }: { data: IFreeMonth } = yield call(
      api.get,
      `/admin/customers/${client_id}/plans/sales_free_month`,
    )

    yield put(Creators.getClientFreeMonthSuccess(data))
  } catch (err) {
    toast.error('Opção de meses grátis indisponíveis!')
    yield put(Creators.getClientFreeMonthFailure())
  }
}

export function* setClientFreeMonth({ enable }: { enable: boolean }) {
  const client_id = yield select(getClientId)

  try {
    yield call(
      api.patch,
      `/admin/customers/${client_id}/plans/sales_free_month`,
      { sales_free_month: enable ? 1 : 0 },
    )

    yield put(Creators.setClientFreeMonthSuccess())
    toast.success(
      `Foi ${
        enable ? 'habilitada' : 'desabilitada'
      } a opção de mensalidade grátis!`,
    )
    yield put(Creators.getClientFreeMonthRequest())
  } catch (err) {
    toast.error('Não foi possível alterar a opção de mês grátis!')
    yield put(Creators.setClientFreeMonthFailure())
  }
}

export function* getClientPartnership() {
  const client_id = yield select(getClientId)

  try {
    const { data }: { data: IPartnership } = yield call(
      api.get,
      `admin/client-partnership/${client_id}`,
    )

    yield put(Creators.getClientPartnershipSuccess(data))
  } catch (err) {
    toast.error('Houve erro na verificação de parcerias')
    yield put(Creators.getClientPartnershipFailure())
  }
}

export function* confirmPartnershipSuccess() {
  const client_id = yield select(getClientId)

  const client_partnership = {
    client_id,
    is_validated: true,
  }

  try {
    yield call(api.patch, 'admin/client-partnership', client_partnership)

    yield put(Creators.confirmPartnershipSuccess())
    yield put(Creators.getClientPartnershipRequest())
    toast.success('Parceria confirmada com sucesso!')
  } catch (err) {
    toast.error('Houve erro ao confirmar a parceria.')
    yield put(Creators.confirmPartnershipFailure())
  }
}
