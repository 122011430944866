import React from 'react'

import { HeaderTabProps } from '@/@types'
import { HeaderTab } from '@/components/HeaderTab'

const routes = [
  { name: 'Comercial' },
  { name: 'Residencial' },
  { name: 'Outras Configurações' },
  { name: 'Supply' },
  { name: 'Overload' },
]

interface ConfigPageProps extends Omit<HeaderTabProps, 'tabSelected'> {
  selected:
    | 'Comercial'
    | 'Residencial'
    | 'Outras Configurações'
    | 'Supply'
    | 'Overload'
}

export const ConfigPageHeader: React.FC<ConfigPageProps> = ({
  onTabClick,
  tabNames = routes,
  selected,
}) => {
  return (
    <HeaderTab
      tabNames={tabNames}
      onTabClick={onTabClick}
      tabSelected={selected}
    />
  )
}
