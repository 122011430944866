import { ClientStepStatusEnum } from '@/@types/client/client'
import { IClientStep, ClientStepTypeEnum } from '@/store/ducks/Clients/types'

export const getCurrentClientStepType = (steps?: IClientStep[]) => {
  const currentStep = steps?.find(
    step => step.status === ClientStepStatusEnum.PENDING,
  )
  let currentType = currentStep?.type
  const hasCurrentStepInstalation = steps?.some(
    step =>
      step.type === ClientStepTypeEnum.INSTALATION &&
      step.status === ClientStepStatusEnum.APPROVED,
  )
  if (hasCurrentStepInstalation) currentType = ClientStepTypeEnum.ACTIVE

  return currentType
}
