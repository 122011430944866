import styled from 'styled-components'

import theme from '@/assets/styles/theme'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 32%;
`

export const ContainerPlan = styled.div`
  display: flex;
  margin-top: 30px;
  padding: 20px;
  background-color: ${theme.colors.gray[100]};
  justify-content: flex-start;

  @media only screen and (max-width: ${theme.breakpoints.sm}) {
    flex-direction: column;
    justify-content: space-between;
  }
`
export const ContainerColumnPlan = styled.div`
  display: flex;
  height: 680px;
  flex-direction: column;
  justify-content: space-between;
`

export const FieldsLabel = styled.div`
  @media only screen and (max-width: ${theme.breakpoints.sm}) {
    margin-bottom: 2px;
  }
`
export const Fields = styled.span`
  font-size: 16px;
  font: Regular 16px/22px Roboto;
  opacity: 0.7;
`
export const Font = styled.b`
  font: Bold 16px/22px Roboto;
  opacity: 1;
`
export const PlanComponent = styled.div`
  margin-bottom: 25px;
`
