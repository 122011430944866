const messages = {
  signIn: {
    3000: 'Email ou senha incorretos',
  },
}

interface Props {
  x: string
}

export default (path: string) => {
  return (
    path
      .split('.')
      /* eslint-disable @typescript-eslint/no-explicit-any */
      .reduce((acc: any, cur: string | number) => acc[cur], messages)
  )
}
