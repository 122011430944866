import React, { useState, useEffect } from 'react'
import { GrEdit } from 'react-icons/gr'
import { RiDeleteBinLine } from 'react-icons/ri'

import { SystemConfigTypeEnum } from '@/@types/system-config'
import { Table, ITableProps } from '@/components/Table'
import { InputNumber } from '@/components/Ui/InputNumber'
import { Label } from '@/components/Ui/Label'
import { Select } from '@/components/Ui/Select'
import { ISystemConfigData } from '@/store/ducks/SystemConfig/types'

import {
  BillContainer,
  Container,
  SubText,
  InputContainer,
  CitiesContainer,
  StateContainer,
  AddButton,
  TableContainer,
  ButtonContainer,
  SubmitButton,
  SubmitStateButton,
  SelectContainer,
  Icon,
} from './styles'

export interface ISelectOption {
  value: string
  label: string
}

interface ConfigPageBodyProps {
  selectedSystemConfig?: ISystemConfigData
  selectOptions: ISelectOption[]
  statesAndDryTariffs: IStatesAndDryTariff[]
  onChangeDryTariff: (value: number) => void
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onChangeStateSelect: (value: any) => void
  onSubmitStateForm: () => void
  onSubmitSystemConfig: (systemConfigPayload: ISystemConfigData) => void
  onClickToEditState: (state: IStatesAndDryTariff) => void
  onClickToDeleteState: (stateId: IStatesAndDryTariff['id']) => void
}

export interface IStatesAndDryTariff {
  id: string
  state: string
  dryTariff: string
}

export const ConfigPageBody: React.FC<ConfigPageBodyProps> = ({
  selectedSystemConfig = {
    type: SystemConfigTypeEnum.COMMERCIAL,
    minClientBillValue: 500,
  },
  selectOptions,
  statesAndDryTariffs,
  onChangeDryTariff,
  onChangeStateSelect,
  onSubmitStateForm,
  onSubmitSystemConfig,
  onClickToEditState,
  onClickToDeleteState,
}) => {
  const [systemConfigForm, setSystemConfigForm] = useState<ISystemConfigData>({
    type: selectedSystemConfig.type,
    minClientBillValue: selectedSystemConfig.minClientBillValue,
  })
  const handleOnChangeMinClientBillValue = (minClientBillValue: number) =>
    setSystemConfigForm(prev => ({ ...prev, minClientBillValue }))

  const columns: ITableProps<IStatesAndDryTariff>['columns'] = [
    {
      title: 'Estado',
      dataIndex: 'state',
      key: 'state',
    },
    {
      title: 'Tarifa Seca',
      dataIndex: 'dryTariff',
      key: 'dryTariff',
    },
    {
      title: '',
      dataIndex: 'edit',
      key: 'edit',
      align: 'right',
      width: 60,
      render: (...args) => {
        const state = args[1]
        return (
          <Icon onClick={() => onClickToEditState(state)}>
            <GrEdit />
          </Icon>
        )
      },
    },
    {
      title: '',
      dataIndex: 'delete',
      key: 'delete',
      width: 60,
      render: (...args) => {
        const state = args[1]
        return (
          <Icon onClick={() => onClickToDeleteState(state.id)}>
            <RiDeleteBinLine />
          </Icon>
        )
      },
    },
  ]

  useEffect(() => {
    setSystemConfigForm(selectedSystemConfig)
  }, [selectedSystemConfig])

  return (
    <Container>
      <BillContainer>
        <Label>Valor mínimo da conta de luz</Label>
        <SubText>
          Configure o valor mínimo da conta de luz para filtrar seus clientes.
        </SubText>
        <InputContainer>
          <InputNumber
            increment={50}
            initialValue={systemConfigForm.minClientBillValue}
            onChange={handleOnChangeMinClientBillValue}
          />
        </InputContainer>
      </BillContainer>
      <CitiesContainer>
        <Label>Configurar Estados e Tarifa Seca</Label>
        <SubText>
          Configure o valor mínimo da conta de luz para filtrar seus clientes.
        </SubText>
        <StateContainer>
          <SelectContainer>
            <Select
              options={selectOptions}
              onChange={onChangeStateSelect}
              label="Estado"
              size="custom"
              styles={{
                control: styles => ({ ...styles, minHeight: '30px ' }),
              }}
            />
          </SelectContainer>
          <InputNumber
            label="Tarifa Seca"
            increment={0.01}
            initialValue={0.5}
            onChange={onChangeDryTariff}
          />
          <AddButton>
            <SubmitStateButton onClick={onSubmitStateForm}>
              Adicionar
            </SubmitStateButton>
          </AddButton>
        </StateContainer>
        <TableContainer>
          <Table<IStatesAndDryTariff>
            columns={columns}
            data={statesAndDryTariffs}
          />
        </TableContainer>
      </CitiesContainer>
      <ButtonContainer>
        <SubmitButton onClick={() => onSubmitSystemConfig(systemConfigForm)}>
          Salvar alterações
        </SubmitButton>
      </ButtonContainer>
    </Container>
  )
}
