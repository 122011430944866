import styled from 'styled-components'

import theme from '@/assets/styles/theme'
import ReportProblemOutlinedIcon from '@material-ui/icons/ReportProblemOutlined'

export const Container = styled.div`
  display: block;
  padding: 20x;
  overflow-y: scroll;

  ::-webkit-scrollbar {
    display: none;
  }
`
export const ContainerClient = styled.div`
  display: flex;
  margin-top: 30px;
  width: 100%;
  padding: 20px;
  background-color: ${theme.colors.gray[100]};
  justify-content: flex-start;

  @media only screen and (max-width: ${theme.breakpoints.sm}) {
    flex-direction: column;
    justify-content: space-between;
  }
`
export const ContainerColumn = styled.div`
  display: flex;
  width: 40%;
  height: 190px;
  flex-direction: column;
  justify-content: space-between;

  @media only screen and (max-width: ${theme.breakpoints.sm}) {
    display: block;
    width: 100%;
    height: auto;
  }
`
export const Fields = styled.span`
  font-size: 16px;
  font: Regular 16px/22px Roboto;
  opacity: 0.7;
`

export const Font = styled.b`
  font: Bold 16px/22px Roboto;
  opacity: 1;
`
export const FieldsLabel = styled.div`
  @media only screen and (max-width: ${theme.breakpoints.sm}) {
    margin-bottom: 2px;
  }
`
export const Header = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  @media only screen and (max-width: ${theme.breakpoints.sm}) {
    justify-content: space-between;
  }

  & > h1 {
    font-size: 22px;
    font-weight: 600;
    color: ${theme.colors.gray[600]};
  }
`
export const ContainerTable = styled.div`
  margin-top: 30px;
`
export const ContainerReport = styled.div`
  margin-top: 15px;
  @media only screen and (max-width: ${theme.breakpoints.sm}) {
    justify-content: space-between;
  }

  & > h1 {
    font-size: 22px;
    font-weight: 600;
    color: ${theme.colors.gray[600]};
  }
`

export const ReprovalContainer = styled.div`
  margin-top: 15px;
  @media only screen and (max-width: ${theme.breakpoints.sm}) {
    justify-content: space-between;
  }
`

export const ReprovalHeader = styled.h1`
  font-size: 22px;
  font-weight: 600;
  color: ${theme.colors.gray[600]};
`

export const ReprovalBackground = styled.div`
  background-color: ${theme.colors.gray[100]};
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 20px;
  margin: 20px 0 40px;
`

export const ReprovalInfoContainer = styled.div`
  display: flex;
  gap: 20px;
  align-items: center;
  width: 100%;
`

export const InfoIcon = styled(ReportProblemOutlinedIcon)`
  color: ${theme.colors.red[200]};
`

export const ReprovalInfo = styled.span`
  font-size: 18px;
  font-weight: 400;
  color: #3b3b3b;
`

export const ContainerSteps = styled.div`
  margin-top: 30px;
  display: flex;
  justify-content: flex-start;

  & > div {
    margin-right: 30px;
  }
`

export const GroupButton = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  padding: 25px 0px;

  @media only screen and (max-width: 768px) {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
`
export const StatusContainer = styled.div``
