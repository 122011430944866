import { createReducer, createActions } from 'reduxsauce'

import {
  NotificationEnum,
  INotificationActionTypes,
  INotificationStateType,
  INotification,
} from './types'

type NotificationType = typeof NotificationEnum

export const { Types, Creators: NotificationCreators } = createActions<
  NotificationType,
  INotificationActionTypes
>({
  getNotificationsRequest: { page: 0 },
  getNotificationsSuccess: ['notification'],
  getNotificationsFailure: {},

  getUnreadNotificationsRequest: {},
  getUnreadNotificationsSuccess: ['unreadNotification'],
  getUnreadNotificationsFailure: {},

  setReadNotificationRequest: ['id'],
})

export const INITIAL_STATE: INotificationStateType = {
  notification: {
    items: [],
    meta: {
      current_page: 0,
      item_count: 0,
      items_per_page: 0,
      total_items: 0,
      total_pages: 0,
    },
  },
  unreadNotification: {
    items: [],
    meta: {
      current_page: 0,
      item_count: 0,
      items_per_page: 0,
      total_items: 0,
      total_pages: 0,
    },
  },
  isLoading: false,
  isUnreadNotificationLoading: false,
}

export const getNotificationsRequest = (
  state: INotificationStateType = INITIAL_STATE,
) => ({
  ...state,
  isLoading: true,
})

export const getNotificationsSuccess = (
  state: INotificationStateType = INITIAL_STATE,
  { notification }: { notification: INotification },
) => ({
  ...state,
  notification,
  isLoading: false,
})

export const getNotificationsFailure = (
  state: INotificationStateType = INITIAL_STATE,
) => ({
  ...state,
  isLoading: false,
})

export const getUnreadNotificationsRequest = (
  state: INotificationStateType = INITIAL_STATE,
) => ({
  ...state,
  isUnreadNotificationLoading: true,
})

export const getUnreadNotificationsSuccess = (
  state: INotificationStateType = INITIAL_STATE,
  { unreadNotification }: { unreadNotification: INotification },
) => ({
  ...state,
  unreadNotification,
  isLoading: false,
  isUnreadNotificationLoading: false,
})

export const getUnreadNotificationsFailure = (
  state: INotificationStateType = INITIAL_STATE,
) => ({
  ...state,
  isUnreadNotificationLoading: false,
})

export const setReadNotificationRequest = (
  state: INotificationStateType = INITIAL_STATE,
) => ({
  ...state,
})

export const reducer = createReducer<
  INotificationStateType,
  INotificationActionTypes
>(INITIAL_STATE, {
  [Types.GET_NOTIFICATIONS_REQUEST]: getNotificationsRequest,
  [Types.GET_NOTIFICATIONS_SUCCESS]: getNotificationsSuccess,
  [Types.GET_NOTIFICATIONS_FAILURE]: getNotificationsFailure,

  [Types.GET_UNREAD_NOTIFICATIONS_REQUEST]: getUnreadNotificationsRequest,
  [Types.GET_UNREAD_NOTIFICATIONS_SUCCESS]: getUnreadNotificationsSuccess,
  [Types.GET_UNREAD_NOTIFICATIONS_FAILURE]: getUnreadNotificationsFailure,

  [Types.SET_READ_NOTIFICATION_REQUEST]: setReadNotificationRequest,
})
