import React from 'react'
import {
  DragDropContext,
  Droppable,
  Draggable,
  DropResult,
} from 'react-beautiful-dnd'

import { Category as CategoryInterface } from './faq.types'

interface IReorderCategoryItems {
  items: CategoryInterface[]
  onReorder: (item: CategoryInterface[]) => void
}

const grid = 8

const ReorderCategoryItems: React.FC<IReorderCategoryItems> = ({
  items,
  onReorder,
}) => {
  const reorder = (
    list: CategoryInterface[],
    startIndex: number,
    endIndex: number,
  ) => {
    const result = Array.from(list)
    const [removed] = result.splice(startIndex, 1)
    result.splice(endIndex, 0, removed)
    return result
  }

  const onDragEnd = (result: DropResult) => {
    const { source, destination } = result

    if (!destination) return

    const reordered = reorder(items, source.index, destination.index)

    let orderNumber = 1
    reordered.forEach((_, index) => {
      reordered[index].order = orderNumber
      orderNumber += 1
    })

    // eslint-disable-next-line
    console.log('reordered items => ', reordered)
    onReorder(reordered)
  }

  // eslint-disable-next-line
  const getItemStyle = (isDragging: boolean, draggableStyle: any) => ({
    userSelect: 'none',
    padding: grid * 2,
    margin: `0 0 ${grid}px 0`,
    background: isDragging ? 'lightgreen' : 'grey',
    // eslint-disable-next-line
    ...draggableStyle,
  })

  const getListStyle = (isDraggingOver: boolean) => ({
    background: isDraggingOver ? 'lightblue' : 'transparent',
    padding: grid,
    width: 250,
  })

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="droppable">
        {(provided, snapshot) => (
          <div
            {...provided.droppableProps}
            style={getListStyle(snapshot.isDraggingOver)}
            ref={provided.innerRef}
          >
            {items.map((item, index) => (
              <Draggable
                key={item.id}
                draggableId={item.id.toString()}
                index={index}
              >
                {(provided, snapshot) => (
                  <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    style={getItemStyle(
                      snapshot.isDragging,
                      provided.draggableProps.style,
                    )}
                  >
                    {item.title}
                  </div>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  )
}

export default ReorderCategoryItems
