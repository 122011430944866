import produce from 'immer'
import { createReducer, createActions } from 'reduxsauce'

import {
  ActionTypes,
  VisitTypeEnum,
  TypeInitial,
  IRequestSuccessTechnician,
  IRequestCancelVisit,
  IRequestLinkTechnician,
  IRequestSuccessListVisit,
  IUpdateTecnicalVisitStatus,
} from './types'

type VisitType = typeof VisitTypeEnum

export const { Types, Creators } = createActions<VisitType, ActionTypes>({
  visitTechnicianRequest: {},
  visitTechnicianSuccess: ['technicians'],
  visitTechnicianFailure: {},
  visitCancelRequest: { scheduleId: '', justifyCancel: '' },
  visitCancelSuccess: {},
  visitCancelFailure: {},
  visitLinkTechnicianRequest: { technicianId: '', scheduleId: '' },
  visitLinkTechnicianSuccess: {},
  visitLinkTechnicianFailure: {},
  visitListRequest: {
    page: 1,
    search: '',
    technicians: '',
    status: '',
    date: '',
    typeDocumentFilter: '',
    order: '',
    direction: 'DESC',
  },
  visitListSuccess: ['visits'],
  visitListFailure: {},
  visitCancelSchedulingRequest: { scheduleId: '' },
  visitCancelSchedulingSuccess: {},
  visitCancelSchedulingFailure: {},
  visitConfirmSchedulingRequest: { scheduleId: '' },
  visitConfirmSchedulingSuccess: {},
  visitConfirmSchedulingFailure: {},
  visitTechnicianFinishRequest: { scheduleId: '' },
  visitTechnicianFinishSuccess: {},
  visitTechnicianFinishFailure: {},
  setIsReportFour: ['isEditReportFour'],
  updateTechnicianStatusRequest: ['updateStatus'],
  updateTechnicianStatusSuccess: [],
  updateTechnicianStatusFailure: [],
})

export const INITIAL_STATE: TypeInitial = {
  technicians: [],
  visits: {
    items: [],
    meta: {
      current_page: 1,
      item_count: 1,
      items_per_page: 1,
      total_items: 1,
      total_pages: 1,
    },
  },
  isLoading: false,
  isEditReportFour: false,
}

export const visitTechnicianRequest = (
  state: TypeInitial = INITIAL_STATE,
): TypeInitial => ({ ...state })

export const visitTechnicianSuccess = (
  state: TypeInitial = INITIAL_STATE,
  { technicians }: IRequestSuccessTechnician,
): TypeInitial =>
  produce(state, () => ({
    ...state,
    technicians,
  }))

export const visitTechnicianFailure = (
  state: TypeInitial = INITIAL_STATE,
): TypeInitial => ({ ...state })

export const visitCancelRequest = (
  state = INITIAL_STATE,
  cancelVisit: IRequestCancelVisit,
) => produce(state, () => ({ ...state, cancelVisit, isLoading: true }))

export const visitCancelSuccess = (
  state: TypeInitial = INITIAL_STATE,
): TypeInitial => ({ ...state, isLoading: false })

export const visitCancelFailure = (
  state: TypeInitial = INITIAL_STATE,
): TypeInitial => ({ ...state, isLoading: false })

export const visitLinkTechnicianRequest = (
  state = INITIAL_STATE,
  linkTechnician: IRequestLinkTechnician,
) => produce(state, () => ({ ...state, linkTechnician, isLoading: true }))

export const visitLinkTechnicianSuccess = (
  state: TypeInitial = INITIAL_STATE,
): TypeInitial => ({ ...state, isLoading: false })

export const visitLinkTechnicianFailure = (
  state: TypeInitial = INITIAL_STATE,
): TypeInitial => ({ ...state, isLoading: false })

export const visitListRequest = (state = INITIAL_STATE) => ({
  ...state,
  isLoading: true,
})

export const visitListSuccess = (
  state: TypeInitial = INITIAL_STATE,
  { visits }: IRequestSuccessListVisit,
): TypeInitial =>
  produce(state, () => ({
    ...state,
    visits,
    isLoading: false,
  }))

export const visitListFailure = (
  state: TypeInitial = INITIAL_STATE,
): TypeInitial => ({ ...state, isLoading: false })

export const visitCancelSchedulingRequest = (
  state = INITIAL_STATE,
  { scheduleId }: { scheduleId: string },
) => ({
  ...state,
  scheduleId,
  isLoading: true,
})

export const visitCancelSchedulingSuccess = (
  state: TypeInitial = INITIAL_STATE,
): TypeInitial => ({ ...state, isLoading: false })

export const visitCancelSchedulingFailure = (
  state: TypeInitial = INITIAL_STATE,
): TypeInitial => ({ ...state, isLoading: false })

// Visit confirm scheduling

export const visitConfirmSchedulingRequest = (
  state = INITIAL_STATE,
  { scheduleId }: { scheduleId: string },
) => ({
  ...state,
  scheduleId,
  isLoading: true,
})

export const visitConfirmSchedulingSuccess = (
  state: TypeInitial = INITIAL_STATE,
): TypeInitial => ({ ...state, isLoading: false })

export const visitConfirmSchedulingFailure = (
  state: TypeInitial = INITIAL_STATE,
): TypeInitial => ({ ...state, isLoading: false })

// Visit technician finish

export const visitTechnicianFinishRequest = (state = INITIAL_STATE) => ({
  ...state,
  isLoading: true,
})

export const visitTechnicianFinishSuccess = (
  state: TypeInitial = INITIAL_STATE,
): TypeInitial => ({ ...state, isLoading: false })

export const visitTechnicianFinishFailure = (
  state: TypeInitial = INITIAL_STATE,
): TypeInitial => ({ ...state, isLoading: false })

// Set report four

export const setIsReportFour = (
  state = INITIAL_STATE,
  { isEditReportFour }: { isEditReportFour: boolean },
) => ({ ...state, isEditReportFour })

export const updateTechnicianStatusRequest = (
  state = INITIAL_STATE,
  { updateStatus }: { updateStatus: IUpdateTecnicalVisitStatus },
) => ({
  ...state,
  updateStatus,
  isLoading: true,
})

export const updateTechnicianStatusSuccess = (state = INITIAL_STATE) => ({
  ...state,
  isLoading: false,
})

export const updateTechnicianStatusFailure = (state = INITIAL_STATE) => ({
  ...state,
  isLoading: false,
})

export const reducer = createReducer<TypeInitial, ActionTypes>(INITIAL_STATE, {
  [Types.VISIT_TECHNICIAN_REQUEST]: visitTechnicianRequest,
  [Types.VISIT_TECHNICIAN_SUCCESS]: visitTechnicianSuccess,
  [Types.VISIT_TECHNICIAN_FAILURE]: visitTechnicianFailure,
  [Types.VISIT_CANCEL_REQUEST]: visitCancelRequest,
  [Types.VISIT_CANCEL_SUCCESS]: visitCancelSuccess,
  [Types.VISIT_CANCEL_FAILURE]: visitCancelFailure,
  [Types.VISIT_LINK_TECHNICIAN_REQUEST]: visitLinkTechnicianRequest,
  [Types.VISIT_LINK_TECHNICIAN_SUCCESS]: visitLinkTechnicianSuccess,
  [Types.VISIT_LINK_TECHNICIAN_FAILURE]: visitLinkTechnicianFailure,
  [Types.VISIT_LIST_REQUEST]: visitListRequest,
  [Types.VISIT_LIST_SUCCESS]: visitListSuccess,
  [Types.VISIT_LIST_FAILURE]: visitListFailure,
  [Types.VISIT_CANCEL_SCHEDULING_REQUEST]: visitCancelSchedulingRequest,
  [Types.VISIT_CANCEL_SCHEDULING_SUCCESS]: visitCancelSchedulingSuccess,
  [Types.VISIT_CANCEL_SCHEDULING_FAILURE]: visitCancelSchedulingFailure,
  [Types.VISIT_CONFIRM_SCHEDULING_REQUEST]: visitConfirmSchedulingRequest,
  [Types.VISIT_CONFIRM_SCHEDULING_SUCCESS]: visitConfirmSchedulingSuccess,
  [Types.VISIT_CONFIRM_SCHEDULING_FAILURE]: visitConfirmSchedulingFailure,

  [Types.VISIT_TECHNICIAN_FINISH_REQUEST]: visitTechnicianFinishRequest,
  [Types.VISIT_TECHNICIAN_FINISH_SUCCESS]: visitTechnicianFinishSuccess,
  [Types.VISIT_TECHNICIAN_FINISH_FAILURE]: visitTechnicianFinishFailure,

  [Types.SET_IS_REPORT_FOUR]: setIsReportFour,

  [Types.UPDATE_TECHNICIAN_STATUS_REQUEST]: updateTechnicianStatusRequest,
  [Types.UPDATE_TECHNICIAN_STATUS_SUCCESS]: updateTechnicianStatusSuccess,
  [Types.UPDATE_TECHNICIAN_STATUS_FAILURE]: updateTechnicianStatusFailure,
})
