export enum TypeDocumentEnum {
  CPF = 'CPF',
  CNPJ = 'CNPJ',
}

export enum TypeConsumptionClassEnum {
  RESIDENTIAL = 'RESIDENTIAL',
  COMMERCIAL = 'COMMERCIAL',
}

export enum TypeAlimentationClassEnum {
  MONOFÁSICO = 'MONOFÁSICO',
  BIFÁSICO = 'BIFÁSICO',
  TRIFÁSICO = 'TRIFÁSICO',
}

export enum ClientStepStatusEnum {
  NOT_DONE = 'NOT_DONE',
  PENDING = 'PENDING',
  DENIED = 'DENIED',
  APPROVED = 'APPROVED',
}
