import React, { FC } from 'react'

import { useWindowSize } from '@/hooks/useWindowSize'
import history from '@/services/history'

import Button from '../Ui/Button'
import { BreadcrumbsItem, Breadcrumbs } from './Breadcrumbs'
import { Container, Header, Title, BreadcrumbsContainer, Body } from './styles'

export interface IPageContentProps {
  title: string
  add_customer?: boolean
  breadcrumbsItems?: BreadcrumbsItem[]
}

const PageContent: FC<IPageContentProps> = ({
  title,
  breadcrumbsItems,
  children,
  add_customer = false,
}) => {
  const { width } = useWindowSize()

  return (
    <Container>
      <Header>
        <Title>{title}</Title>
        {breadcrumbsItems?.length && (
          <BreadcrumbsContainer>
            <Breadcrumbs items={breadcrumbsItems} />
          </BreadcrumbsContainer>
        )}
        {add_customer && (
          <Button
            full={!!width && width <= 1100}
            size="default"
            loading={false}
            onClick={() => {
              history.push(`/customer/create_customer`)
            }}
          >
            + Adicionar novo cliente
          </Button>
        )}
      </Header>
      <Body>{children}</Body>
    </Container>
  )
}

export default PageContent
