/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react'
import Select from 'react-select'

import theme from '@/assets/styles/theme'

import { Container } from './styles'

interface AtributesSelect {
  width?: string
  payload: PayloadSelect[]
  onChange: (event: React.FormEvent<HTMLInputElement>) => void
  valueSelect?: string
}

interface PayloadSelect {
  value: string
  label: string
}

const SelectVisit: React.FC<AtributesSelect> = ({
  width,
  payload,
  onChange,
  valueSelect,
}) => {
  const handleChange = (e: any) => {
    onChange(e)
  }

  const styleSelect = {
    control: (styles: any) => ({
      ...styles,
      borderRadius: '5px',
      border: '1.5px solid',
      borderColor: theme.colors.gray[600],
      opacity: 1,
      width: width || '50px',
    }),
    placeholder: (styles: any) => ({
      ...styles,
      color: theme.colors.black,
    }),
    dropdownIndicator: (styles: any) => ({
      ...styles,
      color: theme.colors.black,
    }),
    indicatorSeparator: (styles: any) => ({
      ...styles,
      display: 'none',
    }),
  }

  return (
    <Container>
      <Select
        value={payload.find(payload => payload.value === valueSelect)}
        onChange={handleChange}
        styles={styleSelect}
        placeholder="Selecione"
        options={payload}
      />
    </Container>
  )
}

export { SelectVisit }
