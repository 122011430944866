import styled from 'styled-components'

import Button from '@/components/Ui/Button'

export const Container = styled.div`
  font-family: 'Roboto', sans-serif;
  display: flex;
  flex-direction: column;
  width: 100%;
  @media (max-width: 767px) {
    width: 100%;
  }
  margin: auto 0;
  word-break: break-all;
`

export const BlocTabs = styled.div`
  font-family: 'Roboto', sans-serif;
  display: flex;
`

export const TabTitle = styled.span`
  word-break: break-word;
  font-family: 'Roboto', sans-serif;
  font-size: clamp(1em, 1em + 3vw, 1em);
  display: flex;
  align-items: center;
`

export const Tabs = styled.div<{ isActive: boolean }>`
  font-family: 'Roboto', sans-serif;
  font-weight: 600;
  // width: 140px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  padding: 15px;
  text-align: center;
  box-sizing: content-box;
  outline: none;
  @media (max-width: 767px) {
    width: 100%;
  }
  img {
    width: 24px;
    margin-right: 10px;
    @media (max-width: 767px) {
      width: 20px;
      margin-right: 5px;
    }
  }
  ${({ isActive }) => `
    cursor: ${isActive ? `default` : `pointer`};
    border: 1px solid ${isActive ? `none` : `#D8D7D4`};
    background-color: ${isActive ? `#ffd500` : `#f3f4f8`};
    box-shadow: ${isActive ? `#D8D7D4 0px 7px 19px 0px` : `0`};
  `}
`

export const ContentTabs = styled.div`
  font-family: 'Roboto', sans-serif;
  flex-grow: 1;
  transition: all 0.3s;
  background-color: #f2f4f7;
  padding: 10px 50px 10px 50px;
  @media (max-width: 767px) {
    padding: 0px;
  }
`

export const Content = styled.div<{ active: boolean }>`
  font-family: 'Roboto', sans-serif;
  background-color: #f3f4f8;
  padding: 20px;
  width: 100%;
  /* height: 100%; */
  display: none;
  transition: all 0.3s;
  display: ${props => (props.active ? 'block' : 'none')};
`

export const UnfitContainer = styled.div`
  &:after {
    content: 'Não apto';
  }
`

export const EconomyMonthContainer = styled.div`
  font-family: 'Roboto', sans-serif;
  height: 70px;
  border-radius: 10px;
  width: 40%;
  @media (max-width: 878px) {
    width: 100%;
  }
  background-color: #ffd500;
  margin: 0 auto;
  text-align: center;
`

export const EconomyMonthSubTitle = styled.span`
  font-family: 'Roboto', sans-serif;
  font-weight: bold;
  text-align: center;
  display: block;
`
export const EconomyMonthValue = styled.span`
  font-family: 'Roboto', sans-serif;
  font-weight: bold;
  font-size: 40px;
  @media (min-width: 767px) {
    font-size: clamp(2.5em, 1.3em + 3vw, 1.5em);
  }
  text-align: center;
`

export const ValuesContainer = styled.div`
  font-family: 'Roboto', sans-serif;
  display: flex;
  margin-top: 20px;
  text-align: center;
  word-break: break-word;
  flex-flow: row wrap;
  align-content: space-between;
  justify-content: space-between;
`

export const ValuesItem = styled.div`
  margin-bottom: 50px;
  font-family: 'Roboto', sans-serif;
  display: block;
  width: 33.33%;
  word-break: break-word;
  padding: 10px;

  .featuredValue {
    font-size: 18px;
    font-weight: lighter;
  }
  .economyTitle {
    font-weight: 600;
  }

  @media (max-width: 814px) {
    width: 50%;
  }

  @media (max-width: 512px) {
    width: 100%;
  }
`

export const ValuesItemFeatured = styled(ValuesItem)`
  border-radius: 15px;
  font-weigth: 500;
`

export const ValuesItemTitle = styled.span`
  font-family: 'Roboto', sans-serif;
  display: block;
  font-size: clamp(1.75em, 2.6em + 3vw, 2.5em);
  margin-bottom: 7px;
  word-break: break-word;
`
export const ValuesItemTitleBold = styled.span`
  font-family: 'Roboto', sans-serif;
  display: block;
  font-size: clamp(1.75em, 2.6em + 3vw, 2.5em);
  margin-bottom: 7px;
  word-break: break-word;
  font-weight: 800;
`

export const BillValueMonth = styled.span`
  font-family: 'Roboto', sans-serif;
  font-weight: lighter;
  font-size: 25px;
`

export const ValuesItemSubtitle = styled.span`
  font-family: 'Roboto', sans-serif;
  display: block;
  text-align: center;
  word-break: break-word;
`

export const DetailPlanMax = styled.span`
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  display: block;
  word-break: break-word;
`

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;

  @media only screen and (max-width: 768px) {
    flex-direction: column;
  }
`

export const ButtonNextStep = styled(Button)``

export const ContainerIcons = styled.div`
  margin-top: 50px;
  margin-bottom: 50px;
  @media (max-width: 767px) {
    margin-top: 5px;
    margin-bottom: 5px;
  }
  display: flex;
  flex-flow: row wrap;
  align-content: space-between;
  justify-content: center;
`

export const IconItem = styled.div`
  font-family: 'Roboto', sans-serif;
  display: block;
  text-align: center;
  @media (max-width: 814px) {
    width: 50%;
  }
  @media (min-width: 815px) {
    width: 33.33%;
  }
  img {
    width: 100px;
    height: 100px;
    margin: 0 auto;
  }
`

export const IconSubtitle = styled.span`
  font-family: 'Raleway', sans-serif;
  display: block;
  word-break: break-word;
  font-size: 20px;
  font-weight: 500;
  color: #7a7878;
  font-size: clamp(0.75em, 0.3em + 3vw, 1.5em);
  width: 70%;
  margin: 0 auto;
  text-align: center;
`

export const IconTitle = styled.span`
  word-break: break-word;
  font-family: 'Roboto', sans-serif;
  display: block;
  font-size: clamp(1.75em, 1em + 3vw, 2.5em);
`

export const FeaturedMsg = styled.span`
  word-break: break-word;
  font-family: 'Roboto';
  font-weight: 300;
  font-size: 24px;
  text-align: center;
  padding-top: 25px;
  color: #7a7878;
  @media (max-width: 800px) {
    margin-bottom: 50px;
  }
`
export const FeaturedMsgColor = styled.span`
  word-break: break-word;
  font-family: 'Roboto';
  font-weight: 500;
  font-size: 24px;
  text-align: center;
  padding-top: 25px;
  color: #ffd500;
  @media (max-width: 800px) {
    margin-bottom: 50px;
  }
`

export const FooterAlertMsg = styled.span`
  word-break: break-word;
  font-family: 'Roboto';
  &::before {
    content: '* ';
  }
  display: block;
  color: #8e8d8d;
  font-size: 12px;
  text-align: left;
`
