import React from 'react'
import { useSelector } from 'react-redux'
import { toast } from 'react-toastify'

import Button from '@/components/Ui/Button'
import { ButtonEdit } from '@/components/Ui/ButtonEdit'
import { useState } from '@/hooks/useState'
import api from '@/services/api'
import { RootState } from '@/store'
import {
  Container,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TextField,
} from '@material-ui/core'

import { State } from './types'

const StatesOverload: React.FC = () => {
  const initialNewData = {
    newData_kwp_price: undefined,
    newData_min_overload: undefined,
    newData_max_overload: undefined,
  }

  type IStatesOverloadState = {
    states: State[]
    loading: boolean
    updating: boolean
    isEdit: string | null
    newData_kwp_price?: number
    newData_min_overload?: number
    newData_max_overload?: number
  }

  const { state, setState } = useState<IStatesOverloadState>({
    states: [],
    loading: true,
    updating: false,
    isEdit: null,
    newData_kwp_price: undefined,
    newData_min_overload: undefined,
    newData_max_overload: undefined,
  })

  let { user_id: userId } = useSelector((state: RootState) => state.Auth)

  if (userId == null) userId = ''

  const getStates = () => {
    setState({ loading: true })
    api
      .get('/admin/overload-states')
      .then(({ data }) => {
        if (data.length) setState({ states: data })
      })
      .finally(() => setState({ loading: false }))
  }

  const editState = () => {
    setState({ updating: true })
    api
      .put(`/admin/overload-states/${state.isEdit}`, {
        kwp_price: state.newData_kwp_price,
        overload_min: state.newData_min_overload,
        overload_max: state.newData_max_overload,
      })
      .then(({ data }) => {
        if (data.affected > 0) {
          toast.success('Overload com sucesso')
          getStates()
          setState({ isEdit: null, ...initialNewData })
        }
      })
      .finally(() => setState({ updating: false }))
  }

  React.useEffect(() => {
    getStates()
    // eslint-disable-next-line
  }, [])

  return (
    <Container>
      {[
        'aab26718-5c70-4953-ada9-ae3cf6cafc8a',
        'c1cd2c83-6133-4a11-9e37-5196761ff289',
        '52521cc3-3aba-471e-b75e-0051dcb6637d',
      ].includes(userId) ? (
        <Table>
          <TableHead>
            <TableRow>
              <TableCell align="left">Estado/UF</TableCell>
              <TableCell>Overload Min.</TableCell>
              <TableCell>Overload Max.</TableCell>
              <TableCell>R$/kWp</TableCell>
              <TableCell align="right">#</TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {state.states.map(st => (
              <TableRow key={st.id}>
                <TableCell align="left">
                  {st.name} / {st.initials}
                </TableCell>
                <TableCell>
                  {state.isEdit === st.id ? (
                    <TextField
                      disabled={state.updating}
                      variant="outlined"
                      label="Overload min."
                      value={state.newData_min_overload}
                      onChange={e => {
                        setState({ newData_min_overload: e.target.value })
                      }}
                    />
                  ) : (
                    st.overload_min
                  )}
                </TableCell>
                <TableCell>
                  {state.isEdit === st.id ? (
                    <TextField
                      disabled={state.updating}
                      variant="outlined"
                      label="Overload max."
                      value={state.newData_max_overload}
                      onChange={e => {
                        setState({ newData_max_overload: e.target.value })
                      }}
                    />
                  ) : (
                    st.overload_max
                  )}
                </TableCell>
                <TableCell>
                  {state.isEdit === st.id ? (
                    <TextField
                      disabled={state.updating}
                      variant="outlined"
                      label="R$/kwp"
                      value={state.newData_kwp_price}
                      onChange={e => {
                        setState({ newData_kwp_price: e.target.value })
                      }}
                    />
                  ) : (
                    st.kwp_price
                  )}
                </TableCell>
                <TableCell align="right">
                  {state.isEdit === st.id ? (
                    <>
                      <Button
                        onClick={editState}
                        loading={state.updating}
                        size="small"
                      >
                        Salvar
                      </Button>
                      <Button
                        loading={state.updating}
                        size="small"
                        onClick={() =>
                          setState({ isEdit: null, ...initialNewData })
                        }
                      >
                        Cancelar
                      </Button>
                    </>
                  ) : (
                    <ButtonEdit
                      disabled={state.updating}
                      onClick={() =>
                        setState({
                          isEdit: st.id,
                          newData_kwp_price: st.kwp_price,
                          newData_min_overload: st.overload_min,
                          newData_max_overload: st.overload_max,
                        })
                      }
                    />
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      ) : (
        'Você não possui autorização para acessar esta página. Fale com um administrador.'
      )}
    </Container>
  )
}

export default StatesOverload
