import { ProjectStatusType } from '@/store/ducks/Clients/types'

type StatusesType = {
  id: number
  status: ProjectStatusType
  label: string
}

export const statuses: StatusesType[] = [
  { id: 0, status: 'PROJECT_IN_PREPARATION', label: 'Projeto em elaboração' },
  {
    id: 1,
    status: 'PROJECT_SUBMITTED_TO_DEALERSHIP',
    label: 'Projeto submetido à concessionária',
  },
  {
    id: 2,
    status: 'PROJECT_APPROVED_BY_THE_CONCESSIONAIRE',
    label: 'Projeto aprovado pela concessionária',
  },
  { id: 3, status: 'EQUIPMENT_DISPATCHED', label: 'Equipamento Despachado' },
  { id: 4, status: 'EQUIPMENT_DELIVERED', label: 'Equipamento Entregue' },
  { id: 5, status: 'INSPECTION_APPROVED', label: 'Vistoria Aprovada' },
  {
    id: 6,
    status: 'SYSTEM_CONNECTED_AND_WORKING',
    label: 'Sistema conectado e funcionando',
  },
]
