import styled from 'styled-components'

export const Container = styled.div<{
  zeroHeight: boolean
}>`
  padding: 25px;
  padding-top: 10px;
  height: ${props => (props.zeroHeight ? 'auto' : '71vh')};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`
