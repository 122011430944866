import React, { useState, FC } from 'react'

import { Container, HeaderButtons, TabButton, TabText } from './styles'

export interface ITab {
  name: string
  value?: string
}

interface HeaderTabProps {
  tabNames?: ITab[]
  onTabClick?: (name: ITab) => void
  tabSelected?: string
  floaded?: boolean
}

type SelectedState = string | undefined

export const HeaderTab: FC<HeaderTabProps> = ({
  onTabClick,
  tabNames,
  tabSelected,
  floaded = false,
}) => {
  const [selected, setSelected] = useState<SelectedState>(tabSelected)

  const handleClick = (tab: ITab) => {
    setSelected(tab.name)
    if (onTabClick) onTabClick(tab)
  }

  return (
    <Container floaded={floaded}>
      <HeaderButtons>
        {tabNames?.map(tab => (
          <TabButton key={tab.name} isActive={selected === tab.name}>
            <TabText onClick={() => handleClick(tab)}>{tab.name}</TabText>
          </TabButton>
        ))}
      </HeaderButtons>
    </Container>
  )
}
