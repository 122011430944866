/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react'

import { isEmpty } from 'lodash'
import moment from 'moment'

import penIcon from '@/assets/images/icons/ic-pen.svg'
import { FullScreenLoader } from '@/components/FullScreenLoader'
import PageContent from '@/components/PageContent'
import { ITableProps, Table } from '@/components/Table'
import { Calendar } from '@/components/Ui/Calendar'
import { IItem, MultSelect } from '@/components/Ui/MultSelect'
import { Pagination } from '@/components/Ui/Pagination'
import { SearchInput } from '@/components/Ui/SearchInput'
import api from '@/services/api'
import { IActivityLog, IClientStep } from '@/store/ducks/Clients/types'
import { insertPhoneMask } from '@/utils/insertNumberMask/insertPhoneMask'

import * as S from './styles'

interface IClientData {
  id: string
  name: string
  email: string
  type?: string
  city: string
  state: string
  steps?: IClientStep[]
  created_at?: string
  logs?: IActivityLog
  bill_value?: number
  bill_id?: string
  bill_status?: string
  technical_status?: string
  last_login?: string
}

const DATE_FORMAT = 'DD/MM/YYYY HH[h]mm'
interface IDates {
  dateInitial: string
  dateFinal: string
}

const Financing: React.FC = () => {
  const [isOpenState, setIsOpenState] = useState<boolean>(false)
  const [isOpenCity, setIsOpenCity] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [stateList, setStateList] = useState<IItem[]>([])
  const [cityList, setCityList] = useState<IItem[]>([])
  const [statusList, setStatusList] = useState<IItem[]>([])
  const [isOpenSituation, setIsOpenSituation] = useState<boolean>(false)
  const [isOpenHiddenColumn, setIsOpenHiddenColumn] = useState<boolean>(false)
  const [hideKeys, setHideKeys] = useState<string[]>([])
  const [page, setPage] = useState<number>(1)
  const [pageCount, setPageCount] = useState<number>(0)
  const [rawClients, setRawClients] = useState<any[]>([])
  const [clientList, setClientList] = useState<any[]>([])
  const [searchText, setSearchtText] = useState<string>('')
  const [searchState, setSearchState] = useState<string>('')
  const [searchCity, setSearchCity] = useState<string>('')
  const [searchStatus, setSearchStatus] = useState<string>('')
  const [searchDate, setSearchDate] = useState<IDates | undefined>()

  useEffect(() => {
    const up = (str: string) => str.toUpperCase()
    const dateCompare = (user: string, init: string, end: string) => {
      const userDate = new Date(user).getTime()
      const initialDate = new Date(init).getTime()
      const finalDate = new Date(end).getTime()

      return userDate <= finalDate && userDate >= initialDate
    }

    let newClients = rawClients
    if (searchText)
      newClients = newClients.filter(ct => up(ct.name).includes(searchText))
    if (searchState)
      newClients = newClients.filter(ct => ct.state === searchState)
    if (searchStatus) {
      let realStatus = ''
      if (searchStatus === 'Aprovado') realStatus = 'APPROVED'
      if (searchStatus === 'Negado') realStatus = 'DENIED'
      if (searchStatus === 'Avaliação') realStatus = 'IN_EVALUATION'
      if (searchStatus === 'Pendente') realStatus = 'NOT_REQUESTED'
      newClients = newClients.filter(ct => ct.status === realStatus)
    }
    if (searchDate?.dateFinal && searchDate?.dateInitial)
      newClients = newClients.filter(ct =>
        dateCompare(
          ct.created_at.slice(0, 10),
          searchDate.dateInitial,
          searchDate.dateFinal,
        ),
      )
    if (searchCity)
      newClients = newClients.filter(ct =>
        ct.city?.name ? up(ct.city.name) === searchCity : false,
      )

    const ids = newClients.map(nc => nc.id)
    api
      .post('/admin/financing/customers/franchisee', { clients: ids })
      .then(({ data }) => {
        newClients = data?.map(
          (nwCl: { id: any; franchisee: { fullname: any } }) => {
            const currClient = newClients.find(cl => cl.id === nwCl.id)
            return { ...currClient, franchisee: nwCl?.franchisee?.fullname }
          },
        )
        setClientList(newClients)
      })
      .catch(err => console.log(err))

    setIsLoading(false)
  }, [
    searchText,
    searchState,
    searchStatus,
    searchDate,
    rawClients,
    page,
    searchCity,
  ])

  useEffect(() => {
    if (isEmpty(searchState)) return
    api
      .get(
        `/admin/cities?is_residential_available=true&states[]=${searchState}`,
      )
      .then(({ data }) => {
        const { items } = data
        setCityList(() =>
          items.map((city: any, index: number) => ({
            id: index.toString(),
            label: city.name,
          })),
        )
      })
      .catch(err => console.error('Error getting cities: ', err))
  }, [searchState])

  useEffect(() => {
    setIsLoading(true)
    api
      .get('/admin/financing/customers/1/10')
      .then(({ data }) => {
        setPageCount(data?.meta?.total_pages)
        setPage(1)
        setRawClients(
          data?.items?.map((client: any) => ({
            id: client.id,
            name: client.name,
            email: client.email,
            state: client.state,
            city: client.city,
            bill_value: client.bill_value,
            created_at: client.created_at,
            phone: insertPhoneMask(client.phone.replace('55', '')),
            hasPartnership: client.hasPartnership,
            address: client.address,
            last_login: client.last_login,
            status: client.status,
            franchisee: '',
          })),
        )

        let states: any[] = []
        let statuses: any[] = []
        data?.items?.forEach((element: any, index: any) => {
          const { state, status } = element
          if (!states.find(stt => stt.label === state))
            states = states.concat({ id: index, label: state })
          if (!statuses.find(stts => stts.label === status))
            statuses = statuses.concat({ id: index, label: status })
        })
        states = states.map((stt, index) => ({ id: index, label: stt.label }))
        setStateList(states)
        statuses = statuses.map((stts, index) => ({
          id: index,
          label: stts.label,
        }))
        statuses = statuses.map(stts => {
          if (stts.label === 'APPROVED') stts.label = 'Aprovado'
          if (stts.label === 'DENIED') stts.label = 'Negado'
          if (stts.label === 'IN_EVALUATION') stts.label = 'Avaliação'
          if (stts.label === 'NOT_REQUESTED') stts.label = 'Pendente'
          return stts
        })
        setStatusList(statuses)
      })
      .catch(err => console.error('Error getting lending customers: ', err))
  }, [])

  useEffect(() => {
    setIsLoading(true)
    api
      .get(`/admin/financing/customers/${page}/10`)
      .then(({ data }) => {
        setPageCount(data?.meta?.total_pages)
        setRawClients(
          data?.items?.map((client: any) => ({
            id: client.id,
            name: client.name,
            email: client.email,
            state: client.state,
            city: client.city,
            bill_value: client.bill_value,
            created_at: client.created_at,
            phone: insertPhoneMask(client.phone.replace('55', '')),
            hasPartnership: client.hasPartnership,
            address: client.address,
            last_login: client.last_login,
            status: client.status,
            franchisee: '',
          })),
        )

        let states: any[] = []
        let statuses: any[] = []
        data?.items?.forEach((element: any, index: any) => {
          const { state, status } = element
          if (!states.find(stt => stt.label === state))
            states = states.concat({ id: index, label: state })
          if (!statuses.find(stts => stts.label === status))
            statuses = statuses.concat({ id: index, label: status })
        })
        states = states.map((stt, index) => ({ id: index, label: stt.label }))
        setStateList(states)
        statuses = statuses.map((stts, index) => ({
          id: index,
          label: stts.label,
        }))
        statuses = statuses.map(stts => {
          if (stts.label === 'APPROVED') stts.label = 'Aprovado'
          if (stts.label === 'DENIED') stts.label = 'Negado'
          if (stts.label === 'IN_EVALUATION') stts.label = 'Avaliação'
          if (stts.label === 'NOT_REQUESTED') stts.label = 'Pendente'
          return stts
        })
        setStatusList(statuses)
      })
      .catch(err => console.error('Error getting lending customers: ', err))
  }, [page])

  const columns: ITableProps<IClientData>['columns'] = [
    {
      title: 'Nome',
      dataIndex: 'name',
      key: 'name',
      width: 120,
    },
    {
      title: 'Email',
      dataIndex: 'email',
      align: 'center',
      key: 'email',
      width: 10,
    },
    {
      title: 'Franqueado',
      dataIndex: 'franchisee',
      align: 'center',
      key: 'franchisee',
      width: 60,
    },
    {
      title: 'Telefone',
      dataIndex: 'phone',
      align: 'center',
      key: 'phone',
      width: 120,
    },
    {
      title: 'Valor da Conta',
      dataIndex: 'bill_value',
      align: 'center',
      key: 'bill_value',
      width: 10,
      render: (...args) =>
        args[0]
          ? args[0].toLocaleString('pt-BR', {
              style: 'currency',
              currency: 'BRL',
            })
          : '',
    },
    {
      title: 'Cidade/UF',
      dataIndex: 'uf',
      key: 'uf',
      align: 'center',
      width: 50,
      render: (...args) => {
        const { state, city } = args[1] as any
        return `${city.name}/${state}`
      },
    },
    {
      title: 'Último Acesso',
      dataIndex: 'last_login',
      key: 'last_login',
      width: 150,
      align: 'center',
      render: (...args) => {
        const { last_login, created_at } = args[1]
        const dataToUse = last_login || created_at
        const rawDate = new Date(dataToUse || '')

        return rawDate ? moment(rawDate).format(DATE_FORMAT) : '-'
      },
    },
    {
      title: 'Data de Cadastro',
      dataIndex: 'created_at',
      key: 'signup_date',
      width: 150,
      align: 'center',
      render: (...args) => (args[0] ? moment(args[0]).format(DATE_FORMAT) : ''),
    },
    {
      title: 'Whirlpool',
      dataIndex: 'hasPartnership',
      key: 'hasPartnership',
      width: 60,
      align: 'center',
      render: (...args) => `${args[0] ? 'Sim' : 'Não'}`,
    },
    {
      title: 'Tipo',
      dataIndex: 'subscription_lending',
      key: 'subscription_lending',
      width: 60,
      align: 'center',
      render: (...args) =>
        `${args[0] === 'SUBSCRIPTION' ? 'Assinatura' : 'Venda'}`,
    },
    {
      title: 'Situação',
      dataIndex: 'situation',
      align: 'center',
      key: 'situation',
      width: 150,
      render: (value, client: any) => {
        const status = client.status as any
        let statusText = ''
        switch (status) {
          case 'APPROVED':
            statusText = 'Aprovado'
            break
          case 'NOT_REQUESTED':
            statusText = 'Pendente'
            break
          case 'IN_EVALUATION':
            statusText = 'Avaliação'
            break
          default:
            statusText = 'Negado'
            break
        }

        return <S.StatusCard status={status}>{statusText}</S.StatusCard>
      },
    },
    {
      title: '',
      dataIndex: 'edit',
      key: 'edit',
      width: 50,
      render: (value, client) => (
        <S.EditIcon
          src={penIcon}
          onClick={() =>
            window.open(`/customer/${client.id}/lending`, '_blank')
          }
        />
      ),
    },
  ]

  const getOptions = () => {
    const results: { id: string; label: any }[] = []
    columns.forEach((column: any) => {
      const { key, title } = column
      if (title && key)
        results.push({ id: key, label: title?.props?.children[0] ?? title })
    })
    return results
  }

  const handleChangeStateFilter = (selectedStates: IItem[]) => {
    const currentState = selectedStates[0]
    if (!currentState?.label) setCityList([])
    setSearchState(currentState?.label ? currentState.label.toString() : '')
  }

  const handleChangeCityFilter = (selectedCities: IItem[]) => {
    const currentCity = selectedCities[0]
    setSearchCity(
      currentCity?.label ? currentCity.label.toString().toUpperCase() : '',
    )
  }

  const handleChangeSituationFilter = (selectedSituations: IItem[]) => {
    const currentStatus = selectedSituations[0]
    setSearchStatus(currentStatus?.label ? currentStatus.label.toString() : '')
  }

  const handleChangeHiddenFilter = (selectedSituatios: IItem[]) => {
    const results: string[] = []
    selectedSituatios.forEach((column: any) => {
      const { id } = column
      if (id) results.push(id)
    })
    setHideKeys(results)
  }

  return (
    <PageContent title="Clientes">
      <S.Container>
        <S.Header>
          <S.SearchContainer>
            <SearchInput onChange={e => setSearchtText(e.toUpperCase())} />
          </S.SearchContainer>
          <S.FilterContainer>
            {!isEmpty(stateList) && (
              <MultSelect
                isOpen={isOpenState}
                onClose={() => setIsOpenState(false)}
                onClick={() => {
                  setIsOpenState(!isOpenState)
                }}
                labelButton="Estados"
                items={stateList}
                onChange={handleChangeStateFilter}
                minWidth="120px"
              />
            )}
            {!isEmpty(cityList) && (
              <MultSelect
                isOpen={isOpenCity}
                onClose={() => setIsOpenCity(false)}
                onClick={() => {
                  setIsOpenCity(!isOpenCity)
                }}
                labelButton="Cidades"
                items={cityList}
                onChange={handleChangeCityFilter}
                minWidth="120px"
              />
            )}
            {!isEmpty(statusList) && (
              <MultSelect
                isOpen={isOpenSituation}
                onClose={() => setIsOpenSituation(false)}
                onClick={() => {
                  setIsOpenSituation(!isOpenSituation)
                }}
                labelButton="Situação"
                items={statusList}
                onChange={handleChangeSituationFilter}
              />
            )}
          </S.FilterContainer>
          <Calendar onChange={selectedDates => setSearchDate(selectedDates)} />
          <S.HideContainer>
            <MultSelect
              items={getOptions()}
              isOpen={isOpenHiddenColumn}
              onClose={() => setIsOpenHiddenColumn(false)}
              onClick={() => {
                setIsOpenHiddenColumn(!isOpenHiddenColumn)
              }}
              labelButton="Ocultar Colunas"
              onChange={handleChangeHiddenFilter}
              isMultiSelect
            />
          </S.HideContainer>
        </S.Header>
        <S.TableContainer>
          {isLoading ? (
            <FullScreenLoader />
          ) : (
            <Table columns={columns} hideKeys={hideKeys} data={clientList} />
          )}
        </S.TableContainer>
        <S.PaginationContainer>
          <Pagination
            count={pageCount}
            size="large"
            shape="rounded"
            variant="outlined"
            page={page}
            onChange={value => setPage(value)}
          />
        </S.PaginationContainer>
      </S.Container>
    </PageContent>
  )
}

export { Financing }
