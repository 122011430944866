export enum StatusEnum {
  APPROVED = 'APPROVED',
  DISAPPROVED = 'DISAPPROVED',
  PENDING = 'PENDING',
}

export type IStatusType = {
  [x in StatusEnum]: {
    text: string
    color: string
    backgroundColor: string
  }
}
