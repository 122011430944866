import React from 'react'

import {
  CheckboxContainer,
  HiddenCheckbox,
  Icon,
  Label,
  StyledCheckbox,
  IPropsCheckBox,
} from './styles'

const IconsVariants = {
  square: <polyline points="20 6 9 17 4 12" />,
}

const CheckBox: React.FC<IPropsCheckBox> = ({
  className,
  checked,
  label,
  onClick,
  ...props
}) => {
  return (
    <CheckboxContainer className={className}>
      <HiddenCheckbox checked={checked} {...props} />
      <StyledCheckbox checked={checked} onClick={onClick}>
        <Icon viewBox="0 0 24 24">{IconsVariants.square}</Icon>
      </StyledCheckbox>
      <Label checked={checked} onClick={onClick}>
        {' '}
        {label}
      </Label>
    </CheckboxContainer>
  )
}

export { CheckBox }
