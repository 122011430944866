import styled from 'styled-components'

export const Container = styled.div``
export const ContainerButton = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  padding: 25px 0px;
  gap: 1rem;
`
