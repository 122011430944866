/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import moment from 'moment'

import { IObjectLiteral } from '@/@types/common/object-literal'
import { FullScreenLoader } from '@/components/FullScreenLoader'
import { Table, ITableProps } from '@/components/Table/Table'
import { Calendar, ISelectedDates } from '@/components/Ui/Calendar'
import { Pagination } from '@/components/Ui/Pagination/Pagination'
import { RootState } from '@/store'
import { PaymentHistoryCreators } from '@/store/ducks/PaymentHistory'

import { Container, ContainerTable, ContainerPagination } from './styles'

interface IPaymentHistoryData {
  dueDate?: string
  form?: string
  dayPayment?: string
  value?: string
  discount?: string
  ticket?: string
  interest?: string
  situation?: string
}

const PaymentHistory: React.FC = () => {
  const [page, setPage] = useState<number>(1)
  const [totalPages, setTotalPages] = useState<number>(1)
  const [date, setDate] = useState<string[]>([])

  const handleChangeDates = (selectedDates: ISelectedDates) => {
    setDate([
      moment(selectedDates.dateInitial).toISOString(),
      moment(selectedDates.dateFinal).toISOString(),
    ])
  }
  const handleChangePage = (value: number) => {
    setPage(value)
  }
  const isLoading = useSelector(
    (state: RootState) => state.PaymentHistory.isLoading,
  )

  const paymentHistoryData =
    useSelector(
      (state: RootState) => state.PaymentHistory.paymentHistory?.items,
    ) || []
  const pagination = useSelector(
    (state: RootState) => state.PaymentHistory.paymentHistory?.totalItems,
  )
  const dispatch = useDispatch()
  const limitPage = 10
  useEffect(() => {
    const query = {
      page,
      limit: limitPage,
      creation_dates: date,
    }
    dispatch(PaymentHistoryCreators.getPaymentHistoryRequest(query))
  }, [dispatch, date, page])

  useEffect(() => {
    pagination && setTotalPages(pagination / limitPage)
  }, [pagination])
  const paymentHistoryStatus: IObjectLiteral<string> = {
    pending: 'Pendente',
    paid: 'Pago',
    canceled: 'Cancelado',
    partially_paid: 'Parcialmente pago',
    refunded: 'Devolvido',
    expired: 'Expirado',
    authorized: 'Autorizado',
  }

  const paymentForm: IObjectLiteral<string> = {
    credit_cad: 'Cartão de credito',
    bank_slip: 'Boleto',
  }

  const paymentHistoryTable: IPaymentHistoryData[] = paymentHistoryData.map(
    history => {
      return {
        dueDate: moment(history.due_date).calendar(),
        form: paymentForm[history.payable_with],
        dayPayment: history.paid_at && moment(history.paid_at).calendar(),
        value: history.total,
        discount: history.discount ? history.discount : 'R$ 0,00',
        ticket: history.fines_on_occurrence_day,
        interest: history.interest ? history.interest : 'R$ 0,00',
        situation: paymentHistoryStatus[history.status],
      }
    },
  )

  const columns: ITableProps<IPaymentHistoryData>['columns'] = [
    {
      title: 'Vencimento',
      dataIndex: 'dueDate',
      key: 'dueDate',
      width: 50,
    },
    {
      title: 'Forma',
      dataIndex: 'form',
      key: 'form',
      width: 50,
    },
    {
      title: 'Pagamento',
      dataIndex: 'dayPayment',
      key: 'dayPayment',
      width: 50,
    },
    {
      title: 'Valor',
      dataIndex: 'value',
      key: 'value',
      width: 50,
    },
    {
      title: 'Desconto',
      dataIndex: 'discount',
      key: 'discount',
      width: 50,
    },
    {
      title: 'Multa',
      dataIndex: 'ticket',
      key: 'ticket',
      width: 50,
    },
    {
      title: 'Juros',
      dataIndex: 'interest',
      key: 'interest',
      width: 50,
    },
    {
      title: 'Situação',
      dataIndex: 'situation',
      key: 'situation',
      width: 50,
    },
  ]

  return (
    <>
      {isLoading && <FullScreenLoader />}
      <Container>
        {paymentHistoryData === [] ? (
          <>
            <Calendar onChange={handleChangeDates} />
            <ContainerTable>
              <Table<IPaymentHistoryData>
                columns={columns}
                data={paymentHistoryTable}
              />
            </ContainerTable>
            <ContainerPagination>
              <Pagination
                count={totalPages}
                size="large"
                shape="rounded"
                page={page}
                onChange={handleChangePage}
                variant="outlined"
              />
            </ContainerPagination>
          </>
        ) : (
          <p>O Cliente não possui nenhum histórico de pagamento</p>
        )}
      </Container>
    </>
  )
}

export { PaymentHistory }
