import MaskedInput from 'react-text-mask'

import styled from 'styled-components'

import theme from '@/assets/styles/theme'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`

export const Prefix = styled.span`
  border-radius: 5px 0 0 5px;
  border: 1px solid ${theme.colors.gray[400]};
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${theme.colors.gray[500]};
`

export const InputNumberContainer = styled(MaskedInput)`
  border: 1px solid ${theme.colors.gray[400]};
  border-left: none;
  border-right: none;
  padding: 10px;
  display: flex;
  justify-content: center;
  text-align: center;
  -webkit-appearance: none;
  width: 90px;
`

export const Buttons = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0 5px 5px 0;
  border: 1px solid ${theme.colors.gray[400]};
  display: flex;
  flex-direction: column;
  background-color: ${theme.colors.yellow[500]};
`

export const Button = styled.button`
  all: unset;
  border: none;
  background-color: none;
  padding: 0 5px;
  cursor: pointer;

  :active {
    background-color: ${theme.colors.yellow[600]};
  }
`

export const InputContainer = styled.div`
  max-height: 38px;
  display: flex;
`
export const Label = styled.div`
  padding: 5px 0;
`
