import React, { useCallback, useState, useEffect } from 'react'
import InputMask from 'react-input-mask'
import { useDispatch, useSelector } from 'react-redux'
import { toast, ToastContent } from 'react-toastify'

import * as Yup from 'yup'

import Button from '@/components/Ui/Button'
import Input from '@/components/Ui/Form/Input'
import { Select } from '@/components/Ui/Select'
import { RootState } from '@/store'
import { CitiesCreators } from '@/store/ducks/Cities'
import { Creators } from '@/store/ducks/Clients'
import { IClientLegal } from '@/store/ducks/Clients/types'
import { CommonCreators } from '@/store/ducks/common'
import { Form } from '@unform/web'

import {
  Container,
  ContainerForm,
  FormLineInput,
  ContainerInput,
  ContainerButton,
} from './styles'

interface IFormData {
  name: string
  email: string
  phone: string
  clientTypeValue: string
}

interface IClientEditProps {
  onClickToCancel: () => void
}

const EditClientLegal: React.FC<IClientEditProps> = ({ onClickToCancel }) => {
  const dispatch = useDispatch()

  const client = useSelector((state: RootState) => state.Client.client)

  const clientLegal = useSelector(
    (state: RootState) => state.Client.clientLegal,
  )

  const stateList = useSelector((state: RootState) => state.Common.data)
  const cityList = useSelector((state: RootState) => state.Cities.cities.items)

  const [selectedCity, setSelectedCity] = useState(client.city.name)

  const [selectedState, setSelectedState] = useState(client.city.state)

  const handleSubmit = useCallback(
    async (clientLegal: IFormData) => {
      try {
        const clientData: IClientLegal = {
          id: client.id,
          name: clientLegal.name,
          legal_document: clientLegal.clientTypeValue.replace(/\D/g, ''),
          email: clientLegal.email,
          phone: clientLegal.phone.replace(/\D/g, ''),
          state: selectedState,
          city: selectedCity,
        }

        const userSchema = Yup.object().shape({
          name: Yup.string().required('Nome obrigatório'),
          email: Yup.string()
            .email('Email invalido')
            .required('Email obrigatório'),
          phone: Yup.string().required('Telefone obrigatório'),
          city: Yup.string().required('Cidade obrigatória'),
        })

        await userSchema.validate(clientData, {
          abortEarly: false,
        })
        dispatch(Creators.editClientLegalRequest(clientData))
      } catch (err) {
        err.errors.forEach((error: ToastContent) => {
          toast.error(error)
        })
      }
    },
    [client.id, dispatch, selectedCity, selectedState],
  )

  useEffect(() => {
    dispatch(CommonCreators.ufRequest())

    const query = {
      states: [selectedState],
    }
    dispatch(CitiesCreators.getCitiesRequest(query))
  }, [dispatch, selectedState])

  useEffect(() => {
    dispatch(Creators.getClientLegalRequest({ id: client.id }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleChangeCity = (city: any) => {
    setSelectedCity(city.label)
  }
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleChangeState = (state: any) => {
    setSelectedState(state.label)
  }

  const cities = cityList.map(city => {
    return {
      value: city.id,
      label: city.name,
    }
  })

  const states = stateList?.map(state => {
    return {
      value: state.sigla,
      label: state.nome,
    }
  })

  return (
    <Container>
      <Form
        onSubmit={handleSubmit}
        initialData={{
          name: clientLegal?.name,
          email: clientLegal?.email,
          state: clientLegal?.state,
          city: clientLegal?.city,
        }}
      >
        <ContainerForm>
          <FormLineInput>
            <ContainerInput width="775px" marginRight="35px">
              <Input
                name="name"
                label="Nome completo *"
                width="100%"
                placeholder="Digite o nome completo"
              />
            </ContainerInput>
            <ContainerInput width="775px" marginRight="0px">
              <InputMask
                mask="999.999.999-99"
                defaultValue={clientLegal?.legal_document}
              >
                {() => (
                  <Input
                    placeholder="999.999.999-99"
                    name="clientTypeValue"
                    label="CPF"
                    width="100%"
                  />
                )}
              </InputMask>
            </ContainerInput>
          </FormLineInput>
          <FormLineInput>
            <ContainerInput width="775px" marginRight="35px">
              <Input
                placeholder="Digite o email"
                name="email"
                label="Email *"
                width="100%"
              />
            </ContainerInput>
            <ContainerInput width="775px" marginRight="0px">
              <InputMask
                mask="(99)99999-9999"
                defaultValue={clientLegal?.phone}
              >
                {() => (
                  <Input
                    placeholder="(99)99999-9999"
                    name="phone"
                    label="Telefone *"
                    width="100%"
                  />
                )}
              </InputMask>
            </ContainerInput>
          </FormLineInput>

          <FormLineInput>
            <ContainerInput width="775px" marginRight="35px">
              <Select
                placeholder="Selecione..."
                label="Estado *"
                size="custom"
                options={states}
                value={states?.find(state => state.value === selectedState)}
                onChange={handleChangeState}
                isSearchable={false}
              />
            </ContainerInput>
            <ContainerInput width="775px" marginRight="0px">
              <Select
                placeholder="Selecione..."
                label="Cidade *"
                size="custom"
                options={cities}
                value={cities.find(city => city.label === selectedCity)}
                onChange={handleChangeCity}
                isSearchable
              />
            </ContainerInput>
          </FormLineInput>
        </ContainerForm>
        <ContainerButton>
          <Button
            type="button"
            size="default"
            color="cancel"
            onClick={onClickToCancel}
          >
            Cancelar
          </Button>
          <Button size="default" color="primary" type="submit">
            Salvar alterações
          </Button>
        </ContainerButton>
      </Form>
    </Container>
  )
}

export { EditClientLegal }
