import React from 'react'
import { toast } from 'react-toastify'

import theme from '@/assets/styles/theme'
import { useState } from '@/hooks/useState'
import api from '@/services/api'
import {
  Fab,
  Dialog,
  DialogContent,
  DialogTitle,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  LinearProgress,
} from '@material-ui/core'
import { Add as AddIcon } from '@material-ui/icons'
import Alert from '@material-ui/lab/Alert'

import StoreForm from './store'
import { CreateDTO, Supply } from './types'

type ISupplyIndex = Supply & {
  oi?: string
}

type Item = Supply &
  CreateDTO & {
    id: number
  }

const SupplyIndex: React.FC<ISupplyIndex> = ({ type }) => {
  type ISupplyIndexState = {
    showNewDialog: boolean
    creating: boolean
    loading: boolean
    items: Item[]
  }

  const initState: ISupplyIndexState = {
    showNewDialog: false,
    creating: false,
    loading: true,
    items: [],
  }

  const { state, setState } = useState<ISupplyIndexState>(initState)

  /**
   * first letter to uppercase
   * and rest to lowercase
   *
   * @param string type
   * @returns string
   */
  const parseType = (type: Supply['type']): string => {
    const _a = type.charAt(0).toUpperCase()
    const _b = type.substring(1, type.length).toLowerCase()
    return _a + _b
  }

  const getItems = () => {
    setState({
      loading: true,
    })
    api
      .get(`/admin/boards-and-inverters/${type.toLowerCase()}s`)
      .then(({ data }) => {
        if (data.length) setState({ ...initState, items: data })
      })
      .finally(() =>
        setState({
          loading: false,
        }),
      )
  }

  const create = (payload: CreateDTO) => {
    setState({
      creating: true,
    })
    api
      .post('/admin/boards-and-inverters', {
        ...payload,
        type: parseType(type),
      })
      .then(() => {
        toast.success('Criado com sucesso')
        getItems()
      })
      .finally(() =>
        setState({
          creating: false,
        }),
      )
  }

  React.useEffect(() => {
    setState(initState)
    getItems()
    // eslint-disable-next-line
  }, [type])

  return (
    <div>
      {/* Table>TableHead>TableRow>TableCell*3 */}
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>#</TableCell>
            <TableCell>Código</TableCell>
            <TableCell>Potência</TableCell>
            <TableCell>Tipo</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {state.loading && <LinearProgress />}

          {state.items.map(item => (
            <TableRow key={item.id}>
              <TableCell>{item.id}</TableCell>
              <TableCell>{item.code}</TableCell>
              <TableCell>{item.potency}</TableCell>
              <TableCell>
                {type === 'INVERTER' ? 'Inversor' : 'Placa'}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>

      {!state.creating && !state.loading && state.items.length === 0 && (
        <Alert
          variant="filled"
          style={{
            justifyContent: 'center',
            backgroundColor: theme.colors.yellow[700],
          }}
          severity="warning"
        >
          Não há resultados
        </Alert>
      )}

      <Dialog
        open={state.showNewDialog}
        onClose={() => setState({ ...initState, loading: false })}
      >
        <DialogTitle>
          {type === 'BOARD' ? 'Nova Placa' : 'Novo Inversor'}
        </DialogTitle>

        <DialogContent>
          <StoreForm loading={state.creating} type={type} onSubmit={create} />
        </DialogContent>
      </Dialog>

      <Fab
        size="small"
        // color="primary"
        style={{ backgroundColor: theme.colors.yellow[500], color: '#000' }}
        disabled={state.loading || state.creating}
      >
        <AddIcon
          onClick={() =>
            setState({
              showNewDialog: true,
            })
          }
        />
      </Fab>
    </div>
  )
}

export default SupplyIndex
