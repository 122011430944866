import { Action } from 'redux'

import { IPermission } from '@/@types/Permission'

// Enums

export enum AuthEnum {
  SIGN_IN_REQUEST = 'SIGN_IN_REQUEST',
  SIGN_IN_SUCCESS = 'SIGN_IN_SUCCESS',
  SIGN_IN_FAILURE = 'SIGN_IN_FAILURE',

  LOGOUT_REQUEST = 'LOGOUT_REQUEST',
  LOGOUT_SUCCESS = 'LOGOUT_SUCCESS',
}

export interface ISignInRequest {
  email: string
  password: string
}

export interface ISignInSuccess {
  token: string
  role: IPermission
  user_id: string
}

export interface ISignInFailure {
  message: string
}

// Action Types

export interface IAuthActionTypes extends Action<AuthEnum> {
  signInRequest: (data: ISignInRequest) => Action
  signInSuccess: (data: ISignInSuccess) => Action
  signInFailure: (data: ISignInFailure) => Action

  logoutRequest: () => Action
  logoutSuccess: () => Action
}

// State Type

export interface IAuthStateType {
  email: string | null
  password: string | null
  token: string | null
  role: IPermission | null
  user_id: string | null
  isAuthorized: boolean
  isLoading: boolean
  failure?: {
    message: string
  }
}
