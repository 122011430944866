import React from 'react'

import moment from 'moment'
import styled from 'styled-components'

import DebitAccordion from './partials/DebitAccordion'
import { natureOfOperations } from './partials/natureOfOperation'
import ScoreReasons from './partials/ScoreReasons'
import ScoreChart from './ScoreChart'
// import ScoreInfo from './ScoreInfo'
import BoaVistaScoreDetails from './ScoreDetails/BoaVista'
import { QuodRecord } from './ScoreInfo/ScoreInfo.types'
import * as SComponents from './styles'

const DetailsChild = styled.div`
  p {
    padding: 10px;
  }
`

interface IClientCredit {
  scoreData: IScore
}

interface IScore {
  score: number
  json?: QuodRecord
  provider_company: 'BOA_VISTA' | 'QUOD'
}

const ClientCredit: React.FC<IClientCredit> = ({ scoreData }) => {
  const formatAmount = (amount: string): string => {
    return parseFloat(amount).toLocaleString('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    })
  }

  const parseDate = (items: { Day: string; Month: string; Year: string }) => {
    return moment(`${items.Year}-${items.Month}-${items.Day}`).format(
      'DD/MM/YYYY',
    )
  }

  const parseNature = (code: string): string => natureOfOperations[code] || ''

  interface IDefineScoreDetails {
    provider: IScore['provider_company']
  }

  const DefineScoreDetails = ({
    provider,
  }: IDefineScoreDetails): JSX.Element => {
    if (provider === 'QUOD') {
      return (
        <>
          {!!scoreData?.json?.Negative?.Apontamentos?.Apontamento?.length && (
            <SComponents.DebitsList>
              <SComponents.SectionTitle>
                Negativações (
                {formatAmount(scoreData?.json.Negative.PendenciesControlCred)})
              </SComponents.SectionTitle>
              <SComponents.DebitItemsContainer>
                {scoreData?.json?.Negative?.Apontamentos?.Apontamento?.map(
                  (register, key) => (
                    <DebitAccordion
                      key={key}
                      title={register.CompanyName}
                      subtitle={formatAmount(register.Amount)}
                    >
                      <DetailsChild>
                        <p>Vencimento: {parseDate(register.DateOccurred)}</p>
                        <p>Incluído em: {parseDate(register.DateIncluded)}</p>
                        <p>Natureza: {parseNature(register.Nature)}</p>
                      </DetailsChild>
                    </DebitAccordion>
                  ),
                )}
              </SComponents.DebitItemsContainer>
            </SComponents.DebitsList>
          )}
          <ScoreReasons reasons={scoreData.json?.QuodScore} />
        </>
      )
    }

    if (provider === 'BOA_VISTA') {
      return <BoaVistaScoreDetails json={scoreData.json} />
    }

    return <span />
  }

  return (
    <SComponents.ClientCreditContainer>
      <ScoreChart value={scoreData?.score || 0} />
      {/* {!!scoreData.json && <ScoreInfo info={scoreData?.json} />} */}

      <div id="app__details_score">
        <SComponents.SectionTitle>Observações</SComponents.SectionTitle>

        <DefineScoreDetails provider={scoreData.provider_company} />
      </div>
    </SComponents.ClientCreditContainer>
  )
}

export default ClientCredit
