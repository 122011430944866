import styled from 'styled-components'

import theme from '@/assets/styles/theme'

export interface InputProps {
  width?: string
  marginRight?: string
}

export const Container = styled.div``

export const ContainerForm = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  padding: 35px 0px 0 0px;
  height: calc(100% - 19vh);
`
export const FormLineInput = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 20px;
`
export const ContainerInput = styled.div<InputProps>`
  width: ${props => props.width};
  margin-right: ${props => props.marginRight};
`
export const ContainerButton = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  padding: 25px 0px;
  gap: 1rem;
`
export const SelectContainer = styled.div<InputProps>`
  width: 775px;
  margin-bottom: 23px;
  margin-right: ${props => props.marginRight};
  height: 85px;
`
export const ValuesContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  width: 100%;
  padding: 20px;
  background-color: ${theme.colors.gray[100]};
  justify-content: flex-start;

  @media only screen and (max-width: ${theme.breakpoints.sm}) {
    flex-direction: column;
    justify-content: space-between;
  }
`
