/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useCallback } from 'react'
import InputMask from 'react-input-mask'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'

import * as Yup from 'yup'

import { UserRoleEnum } from '@/@types/user'
import { FullScreenLoader } from '@/components/FullScreenLoader'
import PageContent from '@/components/PageContent'
import Button from '@/components/Ui/Button'
import Input from '@/components/Ui/Form/Input'
import { Select as SelectComponent } from '@/components/Ui/Select'
import { useState } from '@/hooks/useState'
import {
  AutoCompleteStyled,
  CssTextField,
} from '@/modules/Customer/pages/Customers/Create/styles'
import api from '@/services/api'
import history from '@/services/history'
import { RootState } from '@/store'
import { Creators } from '@/store/ducks/Users'
import { FormControl, useTheme, Select, withStyles } from '@material-ui/core'
import Checkbox from '@material-ui/core/Checkbox'
import Chip from '@material-ui/core/Chip'
import MuiInput from '@material-ui/core/Input'
import MenuItem from '@material-ui/core/MenuItem'
import Tooltip from '@material-ui/core/Tooltip'

import './css.css'
import {
  Container,
  FormLineInput,
  ContainerButton,
  SelectContainer,
  ContainerForm,
  ContainerInput,
  Form,
  RepresentativeContainer,
  RepresentativeDescription,
  RepresentativeTitle,
} from './styles'

const TOOLTIP_TITLE =
  'Ao menos 8 caracteres, letras e números, sem caracteres especiais'
const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
}

const HtmlTooltip = withStyles(() => ({
  tooltip: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    width: 220,
    fontSize: '11px',
    border: '1px solid #ffd500',
    textAlign: 'center',
  },
}))(Tooltip)

function getStyles(name: string, cities: readonly string[], theme: any) {
  return {
    fontWeight:
      cities.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  }
}

const estadosSP = [
  { value: 'SP', label: 'SP' },
  { value: 'AC', label: 'AC' },
  { value: 'AL', label: 'AL' },
  { value: 'AP', label: 'AP' },
  { value: 'AM', label: 'AM' },
  { value: 'BA', label: 'BA' },
  { value: 'CE', label: 'CE' },
  { value: 'DF', label: 'DF' },
  { value: 'ES', label: 'ES' },
  { value: 'GO', label: 'GO' },
  { value: 'MA', label: 'MA' },
  { value: 'MT', label: 'MT' },
  { value: 'MS', label: 'MS' },
  { value: 'MG', label: 'MG' },
  { value: 'PA', label: 'PA' },
  { value: 'PB', label: 'PB' },
  { value: 'PR', label: 'PR' },
  { value: 'PE', label: 'PE' },
  { value: 'PI', label: 'PI' },
  { value: 'RJ', label: 'RJ' },
  { value: 'RN', label: 'RN' },
  { value: 'RS', label: 'RS' },
  { value: 'RO', label: 'RO' },
  { value: 'RR', label: 'RR' },
  { value: 'SC', label: 'SC' },
  { value: 'SE', label: 'SE' },
  { value: 'TO', label: 'TO' },
]

const options = [
  { value: UserRoleEnum.TECHNICIAN, label: 'Técnico' },
  { value: UserRoleEnum.COMMERCIAL, label: 'Comercial' },
  { value: UserRoleEnum.ADMIN, label: 'Administrador' },
  { value: UserRoleEnum.FRANCHISEE, label: 'Franqueado' },
]

const pixOptions = [
  { value: 'cpf', label: 'CPF' },
  { value: 'cnpj', label: 'CNPJ' },
  { value: 'phone', label: 'Telefone' },
  { value: 'random', label: 'Chave aleatoria' },
  { value: 'email', label: 'E-mail' },
]

interface IUserCreateState {
  pixType: string
  pixMask: string
  stateF: string
  city: number
  selectedRole: string
  is_representative: boolean
}

const UserCreate: React.FC = () => {
  const theme = useTheme()
  const [cities, setCities] = React.useState([])

  const { state, setState } = useState<IUserCreateState>({
    pixType: '',
    pixMask: '',
    selectedRole: '',
    stateF: '',
    city: 0,
    is_representative: false,
  })

  React.useEffect(() => {
    // eslint-disable-next-line
    const pixMaskOptions: any = {
      cpf: '999.999.999-99',
      cnpj: '99.999.999/9999-99',
      phone: '(99) 9 9999-9999',
      random: '*'.repeat(32),
      email: '',
    }
    if (state.pixType) setState({ pixMask: pixMaskOptions[state.pixType] })
    // eslint-disable-next-line
  }, [state.pixType])

  const dispatch = useDispatch()
  const isLoading = useSelector((state: RootState) => state.User.loading.post)

  interface FormData {
    fullname: string
    firstname: string
    lastname: string
    email: string
    role: UserRoleEnum
    password: string
    confirm_password: string
    cpf: string
    pix: string
    cep: string
    phone: string
    pixType: string
  }

  const [citeisSave, setCitiesSave] = React.useState([])

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleChange = (e: any) => setState({ selectedRole: e.value })

  const handleCity = (event: any) => {
    setCitiesSave(event.target.value)
  }

  const findObjectByName = (array: any, name: string) => {
    return array.find((obj: any) => obj.name === name)
  }

  const handleCityNumbers = () => {
    let citeisumbers = ''
    // eslint-disable-next-line array-callback-return
    citeisSave.map((cityName: any) => {
      const city = findObjectByName(cities, cityName)

      citeisumbers = `${citeisumbers}${city.city_id},`
    })
    citeisumbers = citeisumbers.slice(0, -1)

    return citeisumbers
  }

  const handleSubmit = useCallback(
    async (data: FormData) => {
      try {
        let served_cities: string | null = null

        if (citeisSave.length !== 0) served_cities = handleCityNumbers()
        const names = data.fullname.split(' ')

        const { pix, cep, cpf, password, confirm_password, email, phone } = data
        const {
          selectedRole: role,
          pixType,
          stateF,
          city,
          is_representative,
        } = state

        const createUserProfile = {
          firstname: names[0],
          lastname: names[names.length - 1],
          email,
          role,
          password,
          confirm_password,
          cpf: cpf.replace(/\D/g, ''),
          cep,
          phone,
          pix,
          state: stateF,
          city_id: city,
          pix_type: pixType,
          served_cities,
          is_representative,
        }
        const msgPwd =
          'A senha deve conter no mínimo 8 caracteres, com letras e números'
        const regexPwd = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/

        const userSchema = Yup.object().shape({
          email: Yup.string()
            .email('Email inválido')
            .required('Email obrigatório'),
          firstname: Yup.string().required('Nome obrigatório'),
          password: Yup.string()
            .required('Senha obrigatório')
            .min(8, msgPwd)
            .matches(regexPwd, msgPwd),
          role: Yup.string().required('Escolha um perfil'),
          confirm_password: Yup.string()
            .required('Senha obrigatório')
            .min(8, msgPwd)
            .matches(regexPwd, msgPwd),
          pix: Yup.string().required('Chave pix obrigatória'),
          cpf: Yup.string().required('CPF obrigatório'),
          phone: Yup.string().required('Telefone obrigatório'),
          cep: Yup.string().required('CEP obrigatório').min(9, 'Cep inválido'),
        })

        await userSchema.validate(createUserProfile, {
          abortEarly: false,
        })
        dispatch(Creators.createUserRequest(createUserProfile))
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          toast.error(err.errors[0])
        } else {
          toast.error('Erro ao criar usuário!')
        }
      }
    },
    /* eslint-disable-next-line */
    [dispatch, state, citeisSave],
  )

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handlePixChange = (e: any) => setState({ pixType: e.value })

  const handleCancel = () => {
    history.push('/users')
  }

  const getCities = async (state: string) => {
    await api.get(`/portal/cities/${state}`).then(({ data }) => {
      setCities(data.data)
    })
  }

  const handleChangeState = (state: any) => {
    getCities(state.value)
    setState({ stateF: state.value })
  }

  const handleChangeRepresentative = () => {
    const prevValue = state.is_representative
    const currentValue = !prevValue
    setState({ is_representative: currentValue })
  }

  return (
    <PageContent
      title="Adicionar Usuário"
      breadcrumbsItems={[
        {
          name: 'Usuários',
        },
        {
          name: 'Adicionar Usuário',
        },
      ]}
    >
      {isLoading ? (
        <FullScreenLoader />
      ) : (
        <Container>
          <Form onSubmit={handleSubmit}>
            <ContainerForm>
              <FormLineInput>
                <ContainerInput width="700px" marginRight="35px">
                  <Input
                    name="fullname"
                    label="Nome completo *"
                    width="100%"
                    placeholder="Digite o nome completo"
                  />
                </ContainerInput>
                <SelectContainer>
                  <RepresentativeContainer>
                    <SelectComponent
                      label="Perfil *"
                      size="custom"
                      options={options}
                      value={options.find(
                        obj => obj.value === state.selectedRole,
                      )}
                      onChange={handleChange}
                      isSearchable={false}
                    />
                    {state.selectedRole === 'FRANCHISEE' && (
                      <RepresentativeDescription>
                        <RepresentativeTitle>
                          Representante?
                        </RepresentativeTitle>
                        <Checkbox
                          style={{
                            color: '#ffd500',
                          }}
                          onChange={handleChangeRepresentative}
                        />
                      </RepresentativeDescription>
                    )}
                  </RepresentativeContainer>
                </SelectContainer>
              </FormLineInput>
              <FormLineInput>
                <ContainerInput width="760px" marginRight="35px">
                  <HtmlTooltip title={TOOLTIP_TITLE}>
                    <Input
                      name="password"
                      label="Senha *"
                      width="100%"
                      type="password"
                      placeholder="Digite sua senha"
                      note="(Pelo menos 8 caracteres, entre letras e números)"
                    />
                  </HtmlTooltip>
                </ContainerInput>
                <ContainerInput width="760px">
                  <HtmlTooltip title={TOOLTIP_TITLE}>
                    <Input
                      name="confirm_password"
                      label="Confirme sua Senha *"
                      width="100%"
                      type="password"
                      placeholder="Digite sua senha"
                      note="Repita a senha"
                    />
                  </HtmlTooltip>
                </ContainerInput>
              </FormLineInput>
              <FormLineInput>
                <ContainerInput width="700px" marginRight="35px">
                  <InputMask mask="999.999.999-99">
                    {() => (
                      <Input
                        name="cpf"
                        label="CPF *"
                        width="100%"
                        placeholder="Digite seu CPF"
                      />
                    )}
                  </InputMask>
                </ContainerInput>
                <ContainerInput width="700px">
                  <Input
                    name="email"
                    label="Email *"
                    width="100%"
                    placeholder="Digite seu email"
                  />
                </ContainerInput>
              </FormLineInput>
              <FormLineInput>
                <ContainerInput width="700px" marginRight="35px">
                  <InputMask mask="99999-999">
                    {() => (
                      <Input
                        name="cep"
                        label="CEP *"
                        width="100%"
                        placeholder="Digite seu CEP"
                      />
                    )}
                  </InputMask>
                </ContainerInput>
                <SelectContainer>
                  <SelectComponent
                    label="Tipo de chave PIX *"
                    size="custom"
                    options={pixOptions}
                    value={pixOptions.find(obj => obj.value === state.pixType)}
                    onChange={handlePixChange}
                    isSearchable={false}
                  />
                </SelectContainer>

                <ContainerInput width="700px" marginLeft="35px">
                  <InputMask mask={state.pixMask}>
                    {() => (
                      <Input
                        name="pix"
                        label="Chave PIX *"
                        width="100%"
                        placeholder="Digite sua chave Pix"
                      />
                    )}
                  </InputMask>
                </ContainerInput>
              </FormLineInput>
              <FormLineInput>
                <SelectContainer>
                  <SelectComponent
                    label="Estado"
                    size="custom"
                    options={estadosSP}
                    onChange={handleChangeState}
                    isSearchable={false}
                  />
                </SelectContainer>
                <ContainerInput width="700px" marginLeft="35px">
                  <label>Cidade</label>
                  <AutoCompleteStyled
                    disabled={!cities.length}
                    options={cities}
                    getOptionLabel={(option: any) => {
                      return option.name
                    }}
                    onChange={(event, value: any) => {
                      setState({ city: value.city_id })
                    }}
                    getOptionSelected={(option: any) => option.city_id}
                    renderInput={params => {
                      return (
                        <CssTextField
                          placeholder={
                            !cities.length
                              ? 'Selecione um estado'
                              : 'Selecione a cidade'
                          }
                          {...params}
                          variant="outlined"
                        />
                      )
                    }}
                  />
                </ContainerInput>
                <ContainerInput width="700px" marginLeft="35px">
                  <InputMask mask="(99) 99999-9999">
                    {() => (
                      <Input
                        name="phone"
                        label="Telefone *"
                        width="100%"
                        placeholder="Digite seu telefone"
                      />
                    )}
                  </InputMask>
                </ContainerInput>
              </FormLineInput>
              <FormLineInput>
                {state.selectedRole === 'FRANCHISEE' && cities.length !== 0 ? (
                  <ContainerInput>
                    <div style={{ marginBottom: '5px' }}>
                      <label>Cidades atendidas</label>
                    </div>
                    <FormControl variant="outlined" className="selectCities">
                      <Select
                        className="selectCities"
                        style={{
                          width: '250px',
                          border: '0px !important',
                        }}
                        multiple
                        value={citeisSave}
                        onChange={handleCity}
                        input={
                          <MuiInput
                            id="outlined-basic"
                            style={{ width: '250px', border: '0px' }}
                          />
                        }
                        renderValue={(selected: any) => (
                          <div>
                            {selected.map((value: any) => (
                              <Chip key={value} label={value} />
                            ))}
                          </div>
                        )}
                        MenuProps={MenuProps}
                      >
                        {cities.map((name: any) => (
                          <MenuItem
                            key={name.name}
                            value={name.name}
                            style={getStyles(name.name, citeisSave, theme)}
                          >
                            {name.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </ContainerInput>
                ) : (
                  ''
                )}
              </FormLineInput>
            </ContainerForm>
            <ContainerButton>
              <Button
                type="button"
                size="default"
                color="cancel"
                onClick={handleCancel}
              >
                Cancelar
              </Button>
              <Button
                size="default"
                color="primary"
                type="submit"
                style={{ marginLeft: '20px' }}
              >
                Adicionar usuário
              </Button>
            </ContainerButton>
          </Form>
        </Container>
      )}
    </PageContent>
  )
}

export { UserCreate }
