import React from 'react'

import { getTechnicalVisitStatusStyle } from '@/modules/Customer/utils/getTechnicalVisitStyle'
import ButtonGroup from '@material-ui/core/ButtonGroup'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import Grid from '@material-ui/core/Grid'
import Grow from '@material-ui/core/Grow'
import MenuItem from '@material-ui/core/MenuItem'
import MenuList from '@material-ui/core/MenuList'
import Paper from '@material-ui/core/Paper'
import Popper from '@material-ui/core/Popper'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'

import { ButtonStyled } from './styles'

const options = [
  'Visita Realizada',
  'Proposta Aprovada',
  'Proposta Não Aprovada',
]

interface dropdownItemsProps {
  value: string
  label: string
}

interface ModalAvailableProps {
  handleChange: (value: string) => void
  dropdownItems: dropdownItemsProps[]
}

const SplitButton: React.FC<ModalAvailableProps> = ({
  handleChange,
  dropdownItems,
}) => {
  const [dropdownColor, setDropdownColor] = React.useState<string>('#ffe2cf')
  const [dropColorText, setDropColorText] = React.useState<string>('#ff7600')
  const [open, setOpen] = React.useState(false)
  const anchorRef = React.useRef<HTMLDivElement>(null)
  const [selectedIndex, setSelectedIndex] = React.useState(0)

  const handleMenuItemClick = (
    event: React.MouseEvent<HTMLLIElement, MouseEvent>,
    // eslint-disable-next-line
    value: any,
    index: number,
  ) => {
    if (value !== 'FULFILLED') {
      handleChange(value)
      const technicalVisitStatusStyle = getTechnicalVisitStatusStyle(value)
      setDropdownColor(technicalVisitStatusStyle.backgroundColor)
      setDropColorText(technicalVisitStatusStyle.color)
    } else {
      setDropdownColor('#ffe2cf')
      setDropColorText('#ff7600')
    }
    setSelectedIndex(index)
    setOpen(false)
  }

  const handleToggle = () => {
    setOpen(prevOpen => !prevOpen)
  }

  const handleClose = (event: React.MouseEvent<Document, MouseEvent>) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return
    }

    setOpen(false)
  }

  return (
    <Grid container direction="column" alignItems="center">
      <Grid item xs={12}>
        <ButtonGroup
          variant="contained"
          color="primary"
          ref={anchorRef}
          aria-label="split button"
        >
          <ButtonStyled
            width={225}
            bgColor={dropdownColor}
            fontColor={dropColorText}
          >
            {options[selectedIndex]}
          </ButtonStyled>
          <ButtonStyled
            width={20}
            fontColor={dropColorText}
            bgColor={dropdownColor}
            aria-controls={open ? 'split-button-menu' : undefined}
            aria-expanded={open ? 'true' : undefined}
            onClick={handleToggle}
          >
            <ArrowDropDownIcon />
          </ButtonStyled>
        </ButtonGroup>
        <Popper
          open={open}
          anchorEl={anchorRef.current}
          role={undefined}
          transition
          disablePortal
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin:
                  placement === 'bottom' ? 'center top' : 'center bottom',
              }}
            >
              <Paper>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList id="split-button-menu">
                    {dropdownItems.map((option, index) => (
                      <MenuItem
                        key={option.label}
                        selected={index === selectedIndex}
                        onClick={event =>
                          handleMenuItemClick(event, option.value, index)
                        }
                      >
                        {option.label}
                      </MenuItem>
                    ))}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </Grid>
    </Grid>
  )
}

export { SplitButton }
