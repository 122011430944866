import styled from 'styled-components'

export interface InputProps {
  width?: string
  marginRight?: string
}

export const Container = styled.div`
  height: 71vh;
`

export const ContainerForm = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  padding: 35px 0px 0 0px;
  height: calc(100% - 19vh);
`
export const FormLineInput = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 20px;

  @media only screen and (max-width: 750px) {
    margin-top: 0.5rem;
  }
`
export const ContainerInput = styled.div<InputProps>`
  width: ${props => props.width};
  margin-right: ${props => props.marginRight};
`
export const ContainerButton = styled.div`
  display: flex;
  flex-direction: column;
  padding: 25px 0px;
`
export const SelectContainer = styled.div<InputProps>`
  width: 775px;
  margin-bottom: 23px;
  margin-right: ${props => props.marginRight};
  height: 85px;
`

export const LabelContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: baseline;
  padding: 40px 0 0 0;

  > label {
    font-size: 26px;
    font-family: bold, Roboto;
    font-weight: 500;
    // padding: 0px 0 20px 0px;
    margin-right: 25px;
  }
`

export const MediaWrapper = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 10rem 10rem;
  column-gap: 1rem;
  align-items: center;
`

export const ButtonsWrapper = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 180px);
  justify-content: end;
  column-gap: 1rem;
  padding: 0 0 25px;

  @media only screen and (max-width: 750px) {
    display: flex;
    flex-direction: column-reverse;
    width: 100%;
    row-gap: 1rem;
  }
`
