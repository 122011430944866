import styled from 'styled-components'

export interface InputProps {
  width?: string | undefined
  marginRight?: string | undefined
  marginLeft?: string | undefined
}

export const Container = styled.div`
  padding: 0px 33px;
  height: 71vh;

  @media only screen and (max-width: 768px) {
    padding: 0px 25px;
  }
`
export const ContainerForm = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  padding: 35px 0px 0 0px;
  height: auto;

  @media only screen and (max-width: 768px) {
    height: calc(100% - 5vh);
  }
`
export const FormLineInput = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;

  @media only screen and (max-width: 768px) {
    flex-direction: column;
  }
`
export const CheckBoxContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  margin-bottom: 35px;
  margin-left: 25px;
  label {
    display: block;
    margin-bottom: 21px;
  }

  @media only screen and (max-width: 768px) {
    margin-left: 0px;
  }
`
export const ContainerButton = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  padding: 25px 0px;

  @media only screen and (max-width: 768px) {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
`
export const SelectContainer = styled.div`
  width: 100%;
  margin-bottom: 24px;

  @media only screen and (max-width: 768px) {
    width: 100%;
  }
`
export const ContainerInput = styled.div<InputProps>`
  width: ${props => props.width};
  margin-right: ${props => props.marginRight};
  margin-left: ${props => props.marginLeft || '0px'};

  @media only screen and (max-width: 768px) {
    width: 100%;
    margin-right: 0px;
  }
`
export const SwitchContainer = styled.div`
  display: flex;
  justify-content: start;
  min-width: 170px;
  > p {
    font-size: 15px;
    margin-top: 44px;
    margin-left: 12px;
  }

  @media only screen and (max-width: 768px) {
    width: 100%;
    margin-left: 18px;
    min-width: 150px;
  }
`
export const SelectSwitchContanier = styled.div`
  display: flex;
  width: 760px;

  @media only screen and (max-width: 768px) {
    width: 100%;
  }
`
