import React from 'react'
import ReactSelect, { OptionTypeBase, Props as SelectProps } from 'react-select'

import { Container, Label } from './styles'

interface Props extends SelectProps<OptionTypeBase> {
  size?: 'small' | 'medium' | 'big' | 'custom'
}

export const Select: React.FC<Props> = ({
  placeHolder = 'Selecione...',
  label,
  size = 'small',
  ...rest
}) => {
  return (
    <Container size={size}>
      {!!label && <Label>{label}</Label>}
      <ReactSelect
        menuPlacement="auto"
        placeholder={placeHolder}
        styles={{ control: styles => ({ ...styles, minHeight: '58px ' }) }}
        {...rest}
      />
    </Container>
  )
}
