import styled from 'styled-components'

import theme from '@/assets/styles/theme'
import Button from '@/components/Ui/Button'

export const Container = styled.div`
  display: flex;
  padding: 20px;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 600px;
  height: 200px;

  @media only screen and (max-width: ${theme.breakpoints.sm}) {
    width: 320px;
    height: 145px;
  }
`

export const Title = styled.div`
  text-align: center;

  & > span {
    font: Bold 26px/35px Roboto;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
  }

  @media only screen and (max-width: ${theme.breakpoints.sm}) {
    & > span {
      font: Bold 16px/35px Roboto;
    }
  }
`

export const Buttons = styled.div`
  display: flex;
  width: 100%;
  margin-top: 30px;
  justify-content: space-between;
`
export const ButtonYes = styled(Button)`
  width: 45%;
  margin-left: 15px;
`
export const ButtonNo = styled(Button)`
  width: 45%;
  background-color: transparent;
  border: 1px solid;
  opacity: 0.3;

  &:hover {
    background-color: ${theme.colors.gray[400]};
  }
`
