import styled from 'styled-components'

import theme from '@/assets/styles/theme'

export const Container = styled.div`
  display: flex;
  padding: 30px;
  overflow-y: scroll;
  flex-direction: column;
  justify-content: space-between;

  ::-webkit-scrollbar {
    display: none;
  }
`
export const Header = styled.div`
  display: flex;
  justify-content: space-between;

  @media only screen and (max-width: ${theme.breakpoints.sm}) {
    flex-direction: column;
    height: 270px;
  }
`
export const ContainerTable = styled.div`
  margin-top: 30px;
  height: 465px;
  overflow-y: scroll;
`
export const ContainerPagination = styled.div`
  margin-top: 20px;
  display: flex;
  justify-content: flex-end;
`
export const ContainerTypeButton = styled.div`
  width: 302px;
  @media only screen and (max-width: ${theme.breakpoints.sm}) {
    width: auto;
  }
`

export const SortColumn = styled.button`
  cursor: pointer;
  border: none;
  background-color: transparent;
  font-weight: 800;
`
