import { routerMiddleware } from 'connected-react-router'
import { createStore, compose, applyMiddleware } from 'redux'
import { persistStore, persistReducer } from 'redux-persist'
import { createFilter } from 'redux-persist-transform-filter'
import storage from 'redux-persist/lib/storage'
import createSagaMiddleware from 'redux-saga'

import '@/config/reactotron'
import history from '@/services/history'

import reducers from './ducks'
import { AuthEnum } from './ducks/Auth'
import sagas from './sagas'

// Persist Storage
const persitingReducers = createFilter('Auth', [
  'token',
  'isAuthorized',
  'role',
  'user_id',
])

const persistConfig = {
  key: '@PortalAdminSolar21',
  storage,
  transforms: [persitingReducers],
}

const middlewares = []
const routeMiddleware = routerMiddleware(history)

const sagaMonitor =
  /* eslint-disable */
  process.env.NODE_ENV === 'development' && typeof console.tron !== 'undefined'
    ? console.tron.createSagaMonitor()
    : null
/* eslint-enable */

const sagaMiddleware = createSagaMiddleware({ sagaMonitor })

middlewares.push(sagaMiddleware)
middlewares.push(routeMiddleware)

/* eslint-disable */
const composer =
  process.env.NODE_ENV === 'development' && typeof console.tron !== 'undefined'
    ? compose(applyMiddleware(...middlewares), console.tron.createEnhancer())
    : compose(applyMiddleware(...middlewares))
/* eslint-enable */

const fnReducers = reducers(history)

const persistedReducer = persistReducer(persistConfig, fnReducers)

const store = createStore((state, action) => {
  if (action.type === AuthEnum.LOGOUT_SUCCESS) {
    storage.removeItem('persist:@PortalAdminSolar21')
    state = undefined
  }
  return persistedReducer(state, action)
}, composer)

sagaMiddleware.run(sagas)

export type RootState = ReturnType<typeof fnReducers>
export const persistor = persistStore(store)
export default store
