import { useState as reactUseState } from 'react'

export const useState = <S,>(initialState: S | (() => S)) => {
  const [state, changeState] = reactUseState<S>(initialState)

  const setState = (newState: Record<string, unknown>) => {
    changeState((prevState: S) => ({ ...prevState, ...newState }))
  }

  return { state, setState }
}
