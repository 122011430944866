import React from 'react'

import { Divider } from '@material-ui/core'

interface IBoaVistaScoreDetails {
  // eslint-disable-next-line
  json: any
}

const BoaVistaScoreDetails: React.FC<IBoaVistaScoreDetails> = ({ json }) => {
  React.useEffect(() => {
    // eslint-disable-next-line
    // console.log('json received on BoaVistaScoreDetails =>', json)
  }, [json])

  return (
    <div style={{ lineHeight: 1.5 }}>
      <p>
        <span style={{ fontWeight: 'bold' }}>Recomendação:</span>{' '}
        {json['tr_603:decisao'][0]['tr_603:texto']}
      </p>
      <Divider style={{ marginTop: 30, marginBottom: 30 }} />

      <p>
        <span style={{ fontWeight: 'bold' }}>Nome:</span>{' '}
        {json['tr_500:identificacao'][0]['tr_500:nome']}
      </p>
      <p>
        <span style={{ fontWeight: 'bold' }}>Data Nascimento:</span>{' '}
        {json['tr_500:identificacao'][0]['tr_500:dataNascimento']}
      </p>
      <p>
        <span style={{ fontWeight: 'bold' }}>Renda Presumida:</span>{' '}
        {json['tr_601:score_classificacao_varios_modelos'][1]['tr_601:texto']}
      </p>
      <p>
        <span style={{ fontWeight: 'bold' }}>Grau de Instrução:</span>{' '}
        {/* eslint-disable-next-line */}
        {json['tr_500:identificacao'][0]['tr_500:grauInstrucao'][0] == '0'
          ? '-'
          : json['tr_500:identificacao'][0]['tr_500:grauInstrucao'][0]}
      </p>

      <Divider style={{ marginTop: 30, marginBottom: 30 }} />

      <ul style={{ listStyle: 'inside' }}>
        <span style={{ fontWeight: 'bold' }}>Débitos:</span>
        <li>
          Ocorrências:{' '}
          {
            json['tr_108:resumo_ocorrencias_de_debitos'][0][
              'tr_108:totalDevedor'
            ]
          }
        </li>
        <li>
          Valor total: R${' '}
          {
            json['tr_108:resumo_ocorrencias_de_debitos'][0][
              'tr_108:valorAcomulado'
            ]
          }
        </li>
        <li>
          Data última ocorrência: R${' '}
          {json['tr_124:debitos'][0]['tr_124:dataOcorrencia']}
        </li>
      </ul>

      <Divider style={{ marginTop: 30, marginBottom: 30 }} />

      <ul style={{ listStyle: 'inside' }}>
        <span style={{ fontWeight: 'bold' }}>Protestos:</span>
        <li>
          Ocorrências:{' '}
          {json['tr_146:resumo_titulos_protestados'][0]['tr_146:total']}
        </li>
        <li>
          Valor total: R${' '}
          {
            json['tr_146:resumo_titulos_protestados'][0][
              'tr_146:valorAcumulado'
            ]
          }
        </li>
        <li>
          Data última ocorrência: R${' '}
          {json['tr_146:resumo_titulos_protestados'][0]['tr_146:periodoFinal']}
        </li>
      </ul>
    </div>
  )
}

export default BoaVistaScoreDetails
