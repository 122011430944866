import produce from 'immer'
import { createActions, createReducer } from 'reduxsauce'

import {
  DatabookTypeEnum,
  IDatabookData,
  IActionTypes,
  IDatabookInitialState,
} from './types'

type IDatabookTypes = typeof DatabookTypeEnum

export const {
  Types: DatabookTypes,
  Creators: DatabookCreators,
} = createActions<IDatabookTypes, IActionTypes>({
  getDatabookRequest: [],
  getDatabookSuccess: ['databook'],
  getDatabookFailure: {},

  editDatabookRequest: ['databook'],
  editDatabookSuccess: [],
  editDatabookFailure: [],
})

export const INITIAL_STATE: IDatabookInitialState = {
  databook: [],
  isLoading: false,
}

export const getDatabookRequest = (state = INITIAL_STATE) =>
  produce(state, () => ({
    ...state,
    isLoading: true,
  }))

export const getDatabookSuccess = (
  state = INITIAL_STATE,
  { databook }: { databook: IDatabookData[] },
) =>
  produce(state, () => ({
    ...state,
    databook,
    isLoading: false,
  }))

export const getDatabookFailure = (state = INITIAL_STATE) =>
  produce(state, () => ({
    ...state,
    databook: [],
    isLoading: false,
  }))

export const editDatabookRequest = (state = INITIAL_STATE) =>
  produce(state, () => ({
    ...state,
    isLoading: true,
  }))

export const editDatabookSuccess = (state = INITIAL_STATE) =>
  produce(state, () => ({
    ...state,
    isLoading: false,
  }))

export const editDatabookFailure = (state = INITIAL_STATE) =>
  produce(state, () => ({
    ...state,
    isLoading: false,
  }))

export const reducer = createReducer<IDatabookInitialState, IActionTypes>(
  INITIAL_STATE,
  {
    [DatabookTypes.GET_DATABOOK_REQUEST]: getDatabookRequest,
    [DatabookTypes.GET_DATABOOK_SUCCESS]: getDatabookSuccess,
    [DatabookTypes.GET_DATABOOK_FAILURE]: getDatabookFailure,

    [DatabookTypes.EDIT_DATABOOK_REQUEST]: editDatabookRequest,
    [DatabookTypes.EDIT_DATABOOK_SUCCESS]: editDatabookSuccess,
    [DatabookTypes.EDIT_DATABOOK_FAILURE]: editDatabookFailure,
  },
)
