import React, { FC, MouseEventHandler, ReactNode } from 'react'

import * as S from './styles'

type ButtonProps = {
  text: ReactNode
  disabled?: boolean
  color?: string
  onClick: MouseEventHandler<HTMLButtonElement>
}

const Button: FC<ButtonProps> = ({
  text,
  disabled = false,
  color = undefined,
  onClick,
}) => {
  return (
    <S.Button type="button" color={color} disabled={disabled} onClick={onClick}>
      <S.Text>{text}</S.Text>
    </S.Button>
  )
}

export default Button
