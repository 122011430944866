import React from 'react'
import { Editor } from 'react-draft-wysiwyg'

import { EditorState } from 'draft-js'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import styled from 'styled-components'

interface ITextEditor {
  defaultHtmlContent?: string
  editorState: EditorState
  onChangeEditorState: (editorState: EditorState) => void
}

const EditorContainer = styled.div`
  .DraftEditor-editorContainer {
    background-color: #fff;
    padding: 10px;
    margin: 1px 0 3px 0;
    border: 1px solid #dad6d6;
    border-radius: 5px;
  }
`

const TextEditor: React.FC<ITextEditor> = ({
  editorState,
  onChangeEditorState,
}) => {
  return (
    <EditorContainer>
      <Editor
        editorState={editorState}
        wrapperClassName="demo-wrapper"
        editorClassName="demo-editor"
        onEditorStateChange={onChangeEditorState}
        toolbar={{
          fontFamily: {
            options: ['Roboto', 'Montserrat', 'Raleway', 'Arial'],
          },
        }}
      />
    </EditorContainer>
  )
}

export default TextEditor
