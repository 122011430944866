import { toast } from 'react-toastify'

import queryString from 'query-string'
import { call, put } from 'redux-saga/effects'

import api from '@/services/api'
import { NotificationCreators } from '@/store/ducks/Notification'

export function* getNotifications({ page }: { page: number }) {
  try {
    const query = queryString.stringify(
      { page, limit: 8 },
      { arrayFormat: 'bracket' },
    )
    const { data: notifications } = yield call(
      api.get,
      `/admin/notifications?${query}`,
    )
    yield put(NotificationCreators.getNotificationsSuccess(notifications))
  } catch (error) {
    toast.error('Não foi possível carregar as notificações')
    yield put(NotificationCreators.getNotificationsFailure())
  }
}

export function* getUnreadNotifications() {
  try {
    const { data: unreadNotifications } = yield call(
      api.get,
      `/admin/notifications?limit=5&is_user_read=false`,
    )

    yield put(
      NotificationCreators.getUnreadNotificationsSuccess(unreadNotifications),
    )
  } catch {
    yield put(NotificationCreators.getUnreadNotificationsFailure())
  }
}

export function* setReadNotification({ id }: { id: string }) {
  try {
    yield call(api.patch, `/admin/notifications/${id}`)

    yield call(getUnreadNotifications)
    // eslint-disable-next-line no-empty
  } catch (error) {}
}
