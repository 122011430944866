import React from 'react'

import * as S from '../../../styles'

interface SmallRowProps {
  icon: string
  iconAlt: string
  description: string
  // eslint-disable-next-line react/require-default-props
  months?: number | string
  monthDescription: string
}

const SmallRow = (props: SmallRowProps) => {
  const { icon, iconAlt, description, months, monthDescription } = props
  return (
    <>
      <S.SmallDivContent>
        <S.SmallDescriptioContent>
          <S.SmallIconRight src={icon} alt={iconAlt} />
          <S.SmallDescriptionText>{description}</S.SmallDescriptionText>
        </S.SmallDescriptioContent>
      </S.SmallDivContent>
      <S.SmallDivContent>
        <S.CenterDiv>
          {months && <S.MonthsValue>{months}</S.MonthsValue>}
          <S.MonthsDescription>{monthDescription}</S.MonthsDescription>
        </S.CenterDiv>
      </S.SmallDivContent>
    </>
  )
}

export default SmallRow
