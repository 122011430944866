import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { IPermission } from '@/@types/Permission'
import IconNotification from '@/assets/images/icons/ic-notification.svg'
import IconSettings from '@/assets/images/icons/ic-settings.svg'
import history from '@/services/history'
import { RootState } from '@/store'
import { NotificationCreators } from '@/store/ducks/Notification'

import Notification from './Notification'
import { Container, Nav, NavItem, NavLink, Badge, NavLinkBell } from './styles'

const Header = () => {
  const dispatch = useDispatch()

  const permission = useSelector((state: RootState) => state.Auth.role)
  const isTechnician = permission === IPermission.TECHNICIAN
  const isBIVisitor = permission === IPermission.BIVISITOR
  const isFranchisee = permission === IPermission.FRANCHISEE

  const [isOpenDropNotification, setIsOpenDropNotification] = useState(false)

  const unreadUserMessagesTotal = useSelector(
    (state: RootState) =>
      state.Notification.unreadNotification.meta.total_items,
  )

  const handleSetIsOpenDropdownNotification = () => {
    dispatch(NotificationCreators.getUnreadNotificationsRequest())
    setIsOpenDropNotification(!isOpenDropNotification)
  }

  return (
    <>
      <Container>
        <Nav>
          {!(isTechnician || isBIVisitor || isFranchisee) && (
            <NavItem>
              <NavLinkBell onClick={handleSetIsOpenDropdownNotification}>
                <img src={IconNotification} alt="Notificações" />
                {unreadUserMessagesTotal > 0 && (
                  <Badge>{unreadUserMessagesTotal}</Badge>
                )}
              </NavLinkBell>

              <Notification
                isOpen={isOpenDropNotification}
                onClose={() => setIsOpenDropNotification(false)}
              />
            </NavItem>
          )}

          {!(isTechnician || isBIVisitor || isFranchisee) && (
            <NavItem>
              <NavLink onClick={() => history.push('/configs')}>
                <img src={IconSettings} alt="Configurações" />
              </NavLink>
            </NavItem>
          )}
        </Nav>
      </Container>
    </>
  )
}

export default Header
