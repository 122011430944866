import { toast } from 'react-toastify'

import { call, put, select } from 'redux-saga/effects'

import { IObjectLiteral } from '@/@types/common/object-literal'
import api from '@/services/api'
import { RootState } from '@/store'
import {
  IBillFileUpdate,
  IBillOwnerEdit,
  IConsumptionHistory,
  IEditBillDetails,
} from '@/store/ducks/Bill/types'

import { BillCreators } from '../../ducks/Bill'

const getBillId = (state: RootState) => state.Bill.billDetails.id

const errors: IObjectLiteral = {
  1017: 'CPF ou CNPJ inválido.',
  1018: 'CPF ou CNPJ inválido.',
}

export function* getBillDetails() {
  const getClientId = (state: RootState) => state.Client.client.id
  const clientId = yield select(getClientId)
  if (!clientId) return
  try {
    const { data: billDetails } = yield call(
      api.get,
      `/admin/customers/${clientId}/bills`,
    )
    yield put(BillCreators.getBillDetailsSuccess(billDetails))
  } catch (error) {
    toast.error('Não foi possível carregar detalhes da conta de luz')
    yield put(BillCreators.getBillDetailsFailure())
  }
}

export function* editBillDetails({
  bill: billDetailsUpdate,
}: {
  bill: IEditBillDetails
}) {
  const getClientId = (state: RootState) => state.Client.client.id
  const clientId = yield select(getClientId)

  try {
    yield call(api.put, `/admin/customers/${clientId}/bills`, billDetailsUpdate)
    yield put(BillCreators.editBillDetailsSuccess())
    yield put(BillCreators.setIsEditBillDetails(false))
    yield put(BillCreators.getBillDetailsRequest())
  } catch (error) {
    const errorMessage = errors[error.response?.data?.code]
    if (errorMessage) toast.error(errorMessage)
    else toast.error('Não foi possível editar os detalhes da conta de luz')

    yield put(BillCreators.editBillDetailsFailure())
  }
}

export function* getBillOwnerRequest({ id }: { id: string }) {
  try {
    const { data: billOwner } = yield call(
      api.get,
      `/admin/customers/${id}/bills/owners`,
    )

    yield put(BillCreators.getBillOwnerSuccess(billOwner))
  } catch (error) {
    yield put(BillCreators.getBillOwnerFailure())
  }
}

export function* editBillOwnerRequest({
  billOwner,
}: {
  billOwner: IBillOwnerEdit
}) {
  try {
    const billOwnerEdit = {
      name: billOwner.name,
      email: billOwner.email,
      phone: billOwner.phone,
      document_value: billOwner.document_value,
      occupation: billOwner.occupation,
      marital_status: billOwner.marital_status,
    }

    yield call(
      api.patch,
      `/admin/customers/${billOwner.id}/bills/owners`,
      billOwnerEdit,
    )

    yield put(BillCreators.editBillOwnerSuccess())
    toast.success('Titular da unidade consumidora alterado com sucesso!')
  } catch (error) {
    yield put(BillCreators.editBillOwnerFailure())
  }
}

export function* getConsumptionHistory() {
  const getClientId = (state: RootState) => state.Client.client.id
  const clientId = yield select(getClientId)

  try {
    const { data: months } = yield call(
      api.get,
      `/admin/customers/${clientId}/bills/consumption-histories`,
    )
    yield put(BillCreators.getConsumptionHistorySuccess(months))
  } catch (error) {
    toast.error('Não foi possível carregar o histórico de consumo')
    yield put(BillCreators.getConsumptionHistoryFailure())
  }
}

export function* editConsumptionHistory({
  months,
}: {
  months: IConsumptionHistory
}) {
  const clientBillId = yield select(getBillId)
  try {
    yield call(
      api.put,
      `/admin/customers/bills/${clientBillId}/consumption-histories`,
      months,
    )
    toast.success('Histórico de consumo editado com sucesso', {
      onClose: () => window.location.reload(),
      autoClose: 2000,
    })
    yield put(BillCreators.editConsumptionHistorySuccess())
  } catch (error) {
    toast.error('Não foi possível editar o histórico de consumo')
    yield put(BillCreators.editConsumptionHistoryFailure())
  }
}

export function* getBillFiles() {
  const billId = yield select(getBillId)
  try {
    const { data: billFiles } = yield call(
      api.get,
      `/admin/customers/bills/${billId}/files`,
    )
    yield put(BillCreators.getBillFilesSuccess(billFiles))
  } catch (error) {
    toast.error('Não foi possível carregar arquivos da conta de luz')
    yield put(BillCreators.getBillFilesFailure())
  }
}

export function* editBillFile({
  billFilesUpdate,
}: {
  billFilesUpdate: IBillFileUpdate[]
}) {
  try {
    for (let i = 0; i < billFilesUpdate.length; i += 1) {
      yield call(
        api.patch,
        `/admin/customers/bills/files/${billFilesUpdate[i].id}`,
        {
          status: billFilesUpdate[i].status,
          justification_disapproved:
            billFilesUpdate[i].justification_disapproved,
        },
      )
    }

    yield put(BillCreators.editBillFileSuccess())
    yield put(BillCreators.getBillFilesRequest())
    toast.success('Status do arquivo da conta de luz atualizado com sucesso!')
  } catch (error) {
    toast.error('Não foi possível atualizar status do arquivo da conta de luz')
    yield put(BillCreators.editBillFileFailure())
  }
}

export function* updateConsumptionProfile({
  consumptionProfile,
}: {
  consumptionProfile: string
}) {
  const getClientId = (state: RootState) => state.Client.client.id
  const clientId = yield select(getClientId)

  try {
    yield call(api.put, `/admin/customers/${clientId}/consumption_profile`, {
      consumption_profile: consumptionProfile,
    })

    yield put(BillCreators.updateConsumptionProfileSuccess())
    toast.success('Padrão de consumo atualizado com sucesso')
    yield put(BillCreators.getBillDetailsRequest())
  } catch (error) {
    toast.error('Não foi possível editar o padrão de consumo')
    yield put(BillCreators.updateConsumptionProfileFailure())
  }
}
