import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'

import * as Yup from 'yup'

import { Tab } from '@/@types'
import { SystemConfigTypeEnum } from '@/@types/system-config'
import CouponsManager from '@/components/CouponsManager'
import Faq from '@/components/Faq'
import FranchiseeManager from '@/components/FranchiseeManager'
import { FullScreenLoader } from '@/components/FullScreenLoader/FullScreenLoader'
import GenericTabs from '@/components/generic-tabs'
import PageContent from '@/components/PageContent'
import RepresentativeManager from '@/components/RepresentativeManager'
import { GenericModal } from '@/components/Ui/GenericModal/GenericModal'
import { RootState } from '@/store'
import { CommonCreators } from '@/store/ducks/common'
import { StateCreators } from '@/store/ducks/State'
import { IStatePayload } from '@/store/ducks/State/types'
import { SystemConfigCreators } from '@/store/ducks/SystemConfig'
import { ISystemConfigData } from '@/store/ducks/SystemConfig/types'

import {
  ConfigPageBody,
  ISelectOption,
  IStatesAndDryTariff,
} from '../../components/ConfigPageBody'
import OthersSettings from '../../components/ConfigPageBody/OthersSettings'
import { ConfigPageHeader } from '../../components/ConfigPageHeader'
import StatesOverload from '../Overload'
import { StateEdit } from '../StateEdit'
import SupplyComponent from '../Supply'
import { Container } from './styles'

export const Configuration: React.FC = () => {
  const dispatch = useDispatch()

  const commonStates = useSelector((state: RootState) => state.Common.data)
  const states = useSelector((state: RootState) => state.State.states)
  const systemConfigs = useSelector(
    (state: RootState) => state.SystemConfig.systemConfigs,
  )
  const isLoading = useSelector(
    (state: RootState) => state.SystemConfig.isLoading || state.State.isLoading,
  )

  const [stateIdToDelete, setStateIdToDelete] = useState<string | null>(null)
  const [stateToEdit, setStateToEdit] = useState<{
    id: string
    initials: string
  } | null>(null)

  const [selectedSystemConfigType, setSelectedSystemConfigType] = useState<
    SystemConfigTypeEnum
  >(SystemConfigTypeEnum.OTHERS)
  const [selectOptions, setSelectOptions] = useState<ISelectOption[]>([])
  const [stateForm, setStateForm] = useState<IStatePayload>({
    name: '',
    initials: '',
    dryTariff: 0,
  })

  const [selectedSystemConfig, setSelectedSystemConfig] = useState<
    ISystemConfigData
  >({ type: SystemConfigTypeEnum.OTHERS, minClientBillValue: 500 })

  const selectedRoute = {
    [SystemConfigTypeEnum.OTHERS]: 'Outras Configurações',
    [SystemConfigTypeEnum.COMMERCIAL]: 'Comercial',
    [SystemConfigTypeEnum.RESIDENTIAL]: 'Residencial',
    [SystemConfigTypeEnum.SUPPLY]: 'Supply',
    [SystemConfigTypeEnum.OVERLOAD]: 'Overload',
  }[selectedSystemConfigType || SystemConfigTypeEnum.COMMERCIAL] as
    | 'Outras Configurações'
    | 'Comercial'
    | 'Residencial'
    | 'Supply'
    | 'Overload'

  const handledStates = states.map(state => ({
    id: state.id,
    state: state.name,
    initials: state.initials,
    dryTariff: `R$ ${parseFloat(state.dry_tariff)
      .toFixed(2)
      .replace('.', ',')}`,
  }))

  const breadCrumb = [{ name: 'Configurações' }, { name: selectedRoute }]

  useEffect(() => {
    dispatch(CommonCreators.ufRequest())
    dispatch(StateCreators.getStatesRequest())
    dispatch(SystemConfigCreators.getSystemConfigsRequest())
  }, [dispatch])

  useEffect(() => {
    if (commonStates)
      setSelectOptions(
        commonStates.map(commonState => ({
          label: commonState.nome,
          value: commonState.sigla,
        })),
      )
  }, [commonStates])

  useEffect(() => {
    setSelectedSystemConfig(
      systemConfigs.find(
        systemConfig => systemConfig.type === selectedSystemConfigType,
      ) as ISystemConfigData,
    )
  }, [selectedRoute, selectedSystemConfigType, systemConfigs])

  useEffect(() => {
    setStateIdToDelete(null)
  }, [states])

  const handleTab = (tab: Tab) => {
    const getOption = (tabname: string): SystemConfigTypeEnum => {
      // eslint-disable-next-line default-case
      switch (tabname) {
        case 'Comercial':
          return SystemConfigTypeEnum.COMMERCIAL
        case 'Residencial':
          return SystemConfigTypeEnum.RESIDENTIAL
        case 'Supply':
          return SystemConfigTypeEnum.SUPPLY
        case 'Overload':
          return SystemConfigTypeEnum.OVERLOAD
        case 'OTHERS':
          return SystemConfigTypeEnum.OTHERS
        default:
          return SystemConfigTypeEnum.OTHERS
      }
    }
    const systemConfigType = getOption(tab.name)
    // const systemConfigType =
    //   tab.name === 'Comercial'
    //     ? SystemConfigTypeEnum.COMMERCIAL
    //     : SystemConfigTypeEnum.RESIDENTIAL

    setSelectedSystemConfigType(systemConfigType)
  }

  const handleOnChangeStateSelect = ({
    label,
    value,
  }: {
    label: string
    value: string
  }) => {
    setStateForm(prev => ({
      ...prev,
      name: label,
      initials: value,
    }))
  }

  const onSubmitStateForm = async () => {
    try {
      await Yup.object()
        .shape({
          name: Yup.string().required('Selecione um estado.'),
          initials: Yup.string().required('Selecione um estado.'),
          dryTariff: Yup.number().required('Insira um a Tarifa Seca.'),
        })
        .validate(stateForm)

      dispatch(StateCreators.createStateRequest(stateForm))
    } catch (err) {
      // eslint-disable-next-line
      if (err?.errors.length) {
        // eslint-disable-next-line
        err.errors.forEach((message: string) => toast.error(message))
      }
    }
  }

  const onSubmitSystemConfig = (systemConfigPayload: ISystemConfigData) =>
    dispatch(
      SystemConfigCreators.updateSystemConfigRequest({
        systemConfig: systemConfigPayload,
      }),
    )

  const onClickToEditState = (selectedStateToEdit: IStatesAndDryTariff) =>
    setStateToEdit({
      id: selectedStateToEdit.id,
      initials:
        states.find(state => state.id === selectedStateToEdit.id)?.initials ||
        '',
    })

  const onClickToDeleteState = (stateId: IStatesAndDryTariff['id']) =>
    setStateIdToDelete(stateId)

  const onDeleteState = () => {
    if (stateIdToDelete)
      dispatch(StateCreators.deleteStateRequest({ stateId: stateIdToDelete }))
  }

  return (
    <>
      {isLoading && <FullScreenLoader />}

      {stateToEdit ? (
        <StateEdit
          type={selectedSystemConfigType}
          state={stateToEdit}
          breadcrumbsItems={breadCrumb}
          onClickToCancel={() => setStateToEdit(null)}
        />
      ) : (
        <PageContent title="Configurações" breadcrumbsItems={breadCrumb}>
          <Container
            zeroHeight={
              selectedRoute === 'Outras Configurações' ||
              selectedRoute === 'Supply' ||
              selectedRoute === 'Overload'
            }
          >
            <ConfigPageHeader selected={selectedRoute} onTabClick={handleTab} />
            {selectedRoute === 'Comercial' ||
            selectedRoute === 'Residencial' ? (
              <ConfigPageBody
                selectedSystemConfig={selectedSystemConfig}
                selectOptions={selectOptions}
                statesAndDryTariffs={handledStates}
                onChangeDryTariff={value =>
                  setStateForm(prev => ({ ...prev, dryTariff: value }))
                }
                onChangeStateSelect={handleOnChangeStateSelect}
                onSubmitStateForm={onSubmitStateForm}
                onSubmitSystemConfig={onSubmitSystemConfig}
                onClickToEditState={onClickToEditState}
                onClickToDeleteState={onClickToDeleteState}
              />
            ) : (
              <>
                {selectedRoute === 'Supply' || selectedRoute === 'Overload' ? (
                  <>
                    {selectedRoute === 'Overload' ? (
                      <StatesOverload />
                    ) : (
                      <SupplyComponent />
                    )}
                  </>
                ) : (
                  <GenericTabs
                    tabs={[
                      {
                        id: 0,
                        title: 'Calendário de Agendamento',
                      },
                      {
                        id: 1,
                        title: `FAQ's`,
                      },
                      {
                        id: 2,
                        title: `Cupons`,
                      },
                      {
                        id: 3,
                        title: `Franqueados`,
                      },
                      {
                        id: 4,
                        title: `Indicações`,
                      },
                    ]}
                    tabsContent={[
                      {
                        id: 0,
                        content: <OthersSettings />,
                      },
                      {
                        id: 1,
                        content: <Faq />,
                      },
                      {
                        id: 2,
                        content: <CouponsManager />,
                      },
                      {
                        id: 3,
                        content: <FranchiseeManager />,
                      },
                      {
                        id: 4,
                        content: <RepresentativeManager />,
                      },
                    ]}
                  />
                )}
              </>
            )}
          </Container>
        </PageContent>
      )}

      <GenericModal
        label="Deseja realmente deletar esse Estado?"
        openModal={!!stateIdToDelete}
        onClickConfirm={onDeleteState}
        onClose={() => setStateIdToDelete(null)}
      />
    </>
  )
}
