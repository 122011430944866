import { ClientDocumentTypeEnum } from '@/@types/clientDocument/clientDocumentTypeEnum'
import { IObjectLiteral } from '@/@types/common/object-literal'

const documentNames: IObjectLiteral = {
  [ClientDocumentTypeEnum.DOCUMENT]: 'Documento',
  [ClientDocumentTypeEnum.CARD]: 'Cartão CNPJ',
  [ClientDocumentTypeEnum.RECEIPT]: 'Documento do representante legal',
}

export const getDocumentName = (documentType: ClientDocumentTypeEnum) =>
  documentNames[documentType]
