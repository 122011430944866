export enum ClientReportStepTypeEnum {
  IMAGE = 'IMAGE',
  CHOICE = 'CHOICE',
  TEXT = 'TEXT',
}

export enum ReportStepStatusEnum {
  PENDING = 'PENDING',
  APPROVED = 'APPROVED',
}
