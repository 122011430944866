import styled from 'styled-components'

export interface InputProps {
  width?: string
  marginRight?: string
}

export const Container = styled.div`
  height: 71vh;
`

export const ContainerForm = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  padding: 35px 0px 0 0px;
  height: calc(100% - 19vh);
`
export const FormLineInput = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 20px;
`
export const ContainerInput = styled.div<InputProps>`
  width: ${props => props.width};
  margin-right: ${props => props.marginRight};
`
export const ContainerButton = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  padding: 25px 0px;
`
export const SelectContainer = styled.div`
  width: 775px;
  margin-bottom: 23px;
`
