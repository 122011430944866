import styled from 'styled-components'

import theme from '@/assets/styles/theme'

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  > div {
    &:last-child {
      margin-top: 20px;
    }
  }
`
export const LabelContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: baseline;
  > label {
    font-size: 26px;
    font-family: bold, Roboto;
    font-weight: 500;
    padding: 0px 0 20px 0px;
    margin-right: 25px;
  }
`
export const ContainerPlan = styled.div`
  display: flex;
  margin-top: 10px;
  background-color: ${theme.colors.gray[100]};
  justify-content: space-between;
  padding: 30px;
  flex-direction: column;
  // height: 190px;
`

export const NoSelectedPlanContainer = styled(ContainerPlan)`
  height: 90px;
`

export const TitleBillValue = styled.label`
  font-size: 16px;
  font-family: bold, Roboto;
  font-weight: 500;

  > span {
    font-size: 16px;
    font-family: bold, Roboto;
    font-weight: 350;
  }
`
export const ContainerLegal = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  margin-bottom: 30px;
`
export const ContainerClient = styled.div`
  display: flex;
  margin-top: 10px;
  width: 100%;
  padding: 20px;
  background-color: ${theme.colors.gray[100]};
  justify-content: flex-start;
  padding: 30px;
`
export const ContainerColumn = styled.div`
  display: flex;
  width: 40%;
  height: 110px;
  flex-direction: column;
  justify-content: space-between;
`
export const FieldsLabel = styled.div``
export const Fields = styled.span`
  font-size: 16px;
  font: Regular 16px/22px Roboto;
  opacity: 0.7;
`

export const Font = styled.b`
  font: Bold 16px/22px Roboto;
  opacity: 1;
`
export const ContainerBillOwner = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  margin-bottom: 30px;
`
export const ContainerIcons = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 50%;
`
export const ContainerButtons = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: flex-end;
  padding-top: 60px;
  gap: 1rem;
`
export const ContainerSaveButton = styled.div`
  display: flex;
  width: 175px;
  justify-content: flex-end;
`

export const DocumentTitle = styled.h1`
  color: blue;
`

export const OpenNewTabImage = styled.a`
  color: black;
`
