/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/no-array-index-key */
import React, { FC, useCallback } from 'react'

import Box from '@material-ui/core/Box'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'

import InfoCard from '../InfoCard/InfoCard'
import { useStyles } from '../styles'

type LeadsType = {
  id: string
  fullName: string
  phone: string
  power: string
  proposalValue: string
  priority: number
  time: string
  status: string
}

type CardColumnPropsType = {
  title: string
  totalValue: string | null
  leads: LeadsType[]
  noProposal: boolean
  // eslint-disable-next-line react/require-default-props
  hasMore?: boolean
  // eslint-disable-next-line react/require-default-props
  clickMore?: any
  currentStep:
    | 'bill'
    | 'initial'
    | 'visit'
    | 'contract'
    | 'checkout'
    | 'supply'
    | 'instalation'
}

const CardColumn: FC<CardColumnPropsType> = ({
  title,
  totalValue,
  leads,
  noProposal,
  currentStep,
  hasMore = false,
  clickMore = undefined,
}) => {
  const classes = useStyles()

  const renderInfoCards = useCallback(() => {
    return leads.map((lead, index) => (
      <InfoCard
        key={`${lead.id}-${index}`}
        id={lead.id}
        fullName={lead.fullName}
        phone={lead.phone}
        power={lead.power}
        proposalValue={lead.proposalValue}
        priority={lead.priority}
        time={lead.time}
        status={lead.status}
        noProposal={noProposal}
        currentStep={currentStep}
      />
    ))
  }, [leads, noProposal, currentStep])

  return (
    <Card className={classes.cardColumn}>
      <CardContent className={classes.cardContent}>
        <Box className={classes.columnHeader}>{title}</Box>
        <Box className={totalValue ? classes.columnSummary : classes.skeleton}>
          <span>{totalValue ? 'Valor Total' : '-'}</span>
          <span className={classes.summaryValue}>{totalValue}</span>
        </Box>
        <Box className={classes.columnContent}>{renderInfoCards()}</Box>
        {hasMore && clickMore && (
          <Box
            onClick={() =>
              clickMore((prev: any) => ({
                ...prev,
                [currentStep]: {
                  count: prev[currentStep].count + 10,
                  previous: prev[currentStep].previous,
                },
              }))
            }
            className={classes.seeMore}
          >
            Ver mais...
          </Box>
        )}
      </CardContent>
    </Card>
  )
}

export default CardColumn
