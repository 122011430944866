/* eslint-disable @typescript-eslint/no-explicit-any */
import { Action } from 'redux'

import { TechnicalVisitStatusEnum } from '@/@types/visit'

export enum VisitTypeEnum {
  VISIT_TECHNICIAN_REQUEST = 'VISIT_TECHNICIAN_REQUEST',
  VISIT_TECHNICIAN_SUCCESS = 'VISIT_TECHNICIAN_SUCCESS',
  VISIT_TECHNICIAN_FAILURE = 'VISIT_TECHNICIAN_FAILURE',

  VISIT_CANCEL_REQUEST = 'VISIT_CANCEL_REQUEST',
  VISIT_CANCEL_SUCCESS = 'VISIT_CANCEL_SUCCESS',
  VISIT_CANCEL_FAILURE = 'VISIT_CANCEL_FAILURE',

  VISIT_LINK_TECHNICIAN_REQUEST = 'VISIT_LINK_TECHNICIAN_REQUEST',
  VISIT_LINK_TECHNICIAN_SUCCESS = 'VISIT_LINK_TECHNICIAN_SUCCESS',
  VISIT_LINK_TECHNICIAN_FAILURE = 'VISIT_LINK_TECHNICIAN_FAILURE',

  VISIT_LIST_REQUEST = 'VISIT_LIST_REQUEST',
  VISIT_LIST_SUCCESS = 'VISIT_LIST_SUCCESS',
  VISIT_LIST_FAILURE = 'VISIT_LIST_FAILURE',

  VISIT_CANCEL_SCHEDULING_REQUEST = 'VISIT_CANCEL_SCHEDULING_REQUEST',
  VISIT_CANCEL_SCHEDULING_SUCCESS = 'VISIT_CANCEL_SCHEDULING_SUCCESS',
  VISIT_CANCEL_SCHEDULING_FAILURE = 'VISIT_CANCEL_SCHEDULING_FAILURE',

  VISIT_CONFIRM_SCHEDULING_REQUEST = 'VISIT_CONFIRM_SCHEDULING_REQUEST',
  VISIT_CONFIRM_SCHEDULING_SUCCESS = 'VISIT_CONFIRM_SCHEDULING_SUCCESS',
  VISIT_CONFIRM_SCHEDULING_FAILURE = 'VISIT_CONFIRM_SCHEDULING_FAILURE',

  VISIT_TECHNICIAN_FINISH_REQUEST = 'VISIT_TECHNICIAN_FINISH_REQUEST',
  VISIT_TECHNICIAN_FINISH_SUCCESS = 'VISIT_TECHNICIAN_FINISH_SUCCESS',
  VISIT_TECHNICIAN_FINISH_FAILURE = 'VISIT_TECHNICIAN_FINISH_FAILURE',

  SET_IS_REPORT_FOUR = 'SET_IS_REPORT_FOUR',

  UPDATE_TECHNICIAN_STATUS_REQUEST = 'UPDATE_TECHNICIAN_STATUS_REQUEST',
  UPDATE_TECHNICIAN_STATUS_SUCCESS = 'UPDATE_TECHNICIAN_STATUS_SUCCESS',
  UPDATE_TECHNICIAN_STATUS_FAILURE = 'UPDATE_TECHNICIAN_STATUS_FAILURE',
}

export enum StatusType {
  ACTIVE = 'ACTIVE',
  DISABLED = 'DISABLED',
}

export enum SubscriptionLendingTypes {
  LENDING = 'LENDING',
  SUBSCRIPTION = 'SUBSCRIPTION',
}

export interface TechnicianData {
  id: string
  firstname: string
  lastname: string
  username: string
  fullname: string
  role: string
  email: string
  avatar: string
  phone: string
  password: string
  cpf: string
}

export interface TechnicianType {
  avatar: string
  cpf: string
  created_at: string
  email: string
  firstname: string
  fullname: string
  id: string
  lastname: string
  phone: string
  role: string
  status: StatusType
  updated_at: string
  username: string
}

export interface TechnicalVisitData {
  address: string
  neighborhood: string
  cep: string
  city: string
  complement: string
  created_at: string
  gps: string
  id: string
  number: string
  phone: string
  schedule: string
  state: string
  status: TechnicalVisitStatusEnum
  technician: TechnicianType
  updated_at: string
  coordinates?: string
  suggested_new_schedule_date?: string
  justification_cancel?: string
}

export interface IVisit {
  address: string
  address_complement: string
  address_neighborhood: string
  address_number: string
  bill_id: string
  bill_name: string
  bill_value: number
  created_at: string
  email: string
  id: string
  name: string
  ocr_tries: string
  phone: string
  postal_code: string
  selected_client_plan_id: string
  state: string
  technical_visit: TechnicalVisitData
  unit_type: string
  updated_at: string
  hasPartnership?: boolean
  subscription_lending?: SubscriptionLendingTypes
  franchisee?: any
}

export interface IPagination {
  current_page: number
  item_count: number
  items_per_page: number
  total_items: number
  total_pages: number
}

export interface IVisitsData {
  items: IVisit[]
  meta: IPagination
}

export interface TypeInitial {
  technicians: TechnicianData[]
  visits: IVisitsData
  isLoading: boolean
  isEditReportFour: boolean
}

export interface IRequestSuccessTechnician {
  technicians: TechnicianData[]
}

export interface IRequestSuccessListVisit {
  visits: IVisitsData
}

export interface IRequestCancelVisit {
  scheduleId: string | undefined
  justifyCancel: string | undefined
}

export interface IRequestLinkTechnician {
  technicianId: string | undefined
  scheduleId: string | undefined
}

export interface IVisitListQuery {
  page: number
  search?: string
  technicians?: string[]
  status?: string[]
  date?: string[]
  typeDocumentFilter?: 'CPF' | 'CNPJ'
  order?: string | null
  direction?: string | null
}

export interface IUpdateTecnicalVisitStatus {
  status: TechnicalVisitStatusEnum
  justification_cancel?: string
}
export interface ActionTypes extends Action<VisitTypeEnum> {
  // Visit technician
  visitTechnicianRequest: () => Action

  visitTechnicianSuccess: (technicians: TechnicianData[]) => Action

  visitTechnicianFailure: () => Action

  // Visit cancel
  visitCancelRequest: (cancelVisit: IRequestCancelVisit) => Action

  visitCancelSuccess: () => Action

  visitCancelFailure: () => Action

  // Visit link technician
  visitLinkTechnicianRequest: (linkTechnician: IRequestLinkTechnician) => Action

  visitLinkTechnicianSuccess: () => Action

  visitLinkTechnicianFailure: () => Action

  // Visit list
  visitListRequest: (query: IVisitListQuery) => Action

  visitListSuccess: (visits: IVisitsData) => Action

  visitListFailure: () => Action

  // Visiti cancel Scheduling
  visitCancelSchedulingRequest: ({
    scheduleId,
  }: {
    scheduleId: string
  }) => Action

  visitCancelSchedulingSuccess: () => Action

  visitCancelSchedulingFailure: () => Action

  // Visit confirm scheduling
  visitConfirmSchedulingRequest: ({
    scheduleId,
  }: {
    scheduleId: string
  }) => Action

  visitConfirmSchedulingSuccess: () => Action

  visitConfirmSchedulingFailure: () => Action

  // Visit technical finish
  visitTechnicianFinishRequest: ({
    scheduleId,
  }: {
    scheduleId: string
  }) => Action

  visitTechnicianFinishSuccess: () => Action

  visitTechnicianFinishFailure: () => Action

  // Set report four
  setIsReportFour: (isEditReportFour: boolean) => Action

  updateTechnicianStatusRequest: (
    updateStatus: IUpdateTecnicalVisitStatus,
  ) => Action
  updateTechnicianStatusSuccess: () => Action
  updateTechnicianStatusFailure: () => Action
}
