import React, { useCallback, useEffect, useState } from 'react'
import { toast } from 'react-toastify'

import moment from 'moment'

import Button from '@/components/Ui/Button'
import api from '@/services/api'
import * as M from '@material-ui/core'

type IRepresentative = {
  id: string
  client: {
    id: string
    name: string
  }
  user: {
    id: string
    fullname: string
  }
  representative_commission: number
  paid: boolean
  paid_at: Date
}

const RepresentativeManager: React.FC = () => {
  const [representatives, setRepresentatives] = useState<IRepresentative[]>([])

  const getAll = useCallback(async () => {
    const [users, indications] = await Promise.all([
      api
        .get(`/admin/users?limit=100&page=1&search=`)
        .then(({ data }) => data.items),
      api.get(`/admin/users/representative_admin`).then(({ data }) => data),
    ])

    const finalIndications = indications.map((_indic: { user_id: string }) => {
      const currentUser = users.find(
        (_user: { id: string }) => _user.id === _indic.user_id,
      )

      return { ..._indic, user: currentUser }
    })

    setRepresentatives(finalIndications)
  }, [])

  useEffect(() => {
    getAll()
  }, [getAll])

  const updatePaymentStatus = useCallback(
    (representative_id: string) => {
      api
        .put('/admin/users/representative/paid', {
          paid: true,
          representative_id,
        })
        .then(() => toast.success('Status de pagamento atualizado'))
        .then(() => getAll())
        .catch(() => toast.error('Erro ao atualizar status de pagamento'))
    },
    [getAll],
  )

  return (
    <section>
      <M.TableContainer component={M.Paper}>
        <M.Table>
          <M.TableHead>
            <M.TableRow>
              <M.TableCell colSpan={2}>Cliente</M.TableCell>
              <M.TableCell colSpan={2}>Representante</M.TableCell>
              <M.TableCell>Valor</M.TableCell>
              <M.TableCell>Status</M.TableCell>
              <M.TableCell>Pago em</M.TableCell>
              <M.TableCell colSpan={2} />
            </M.TableRow>
          </M.TableHead>
          <M.TableBody>
            {representatives.map(representative => (
              <M.TableRow key={representative.id}>
                <M.TableCell colSpan={2} align="left">
                  {representative.client?.name}
                </M.TableCell>
                <M.TableCell colSpan={2} align="left">
                  {representative.user?.fullname}
                </M.TableCell>
                <M.TableCell align="left">
                  {representative.representative_commission
                    ? `R$${representative.representative_commission
                        .toFixed(2)
                        .replace('.', ',')}`
                    : 'R$0,00'}
                </M.TableCell>
                <M.TableCell align="left">
                  {representative.paid ? 'Pago' : 'Não pago'}
                </M.TableCell>
                <M.TableCell align="left">
                  {representative.paid_at
                    ? moment(representative.paid_at).format('DD/MM/YYYY')
                    : '--'}
                </M.TableCell>
                <M.TableCell colSpan={2} align="right">
                  {representative.paid ? (
                    <Button
                      style={{ width: '200px' }}
                      disabled
                      onClick={() => updatePaymentStatus(representative.id)}
                    >
                      Pago
                    </Button>
                  ) : (
                    <Button
                      style={{ width: '200px' }}
                      onClick={() => updatePaymentStatus(representative.id)}
                    >
                      Registrar pagamento
                    </Button>
                  )}
                </M.TableCell>
              </M.TableRow>
            ))}
          </M.TableBody>
        </M.Table>
      </M.TableContainer>
    </section>
  )
}

export default RepresentativeManager
