/* eslint-disable react/require-default-props */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useCallback, useEffect, useState } from 'react'
import { BsPencil } from 'react-icons/bs'
import NumberFormat from 'react-number-format'
import { useSelector } from 'react-redux'
import { toast } from 'react-toastify'

import { AxiosRequestConfig } from 'axios'
import { useFormik } from 'formik'
import { isEmpty, last } from 'lodash'

import { IPermission } from '@/@types/Permission'
import LogoBancoBv from '@/assets/images/lending/bv_bank.png'
import CloseButton from '@/assets/images/lending/close.png'
import LogoBancoBtg from '@/assets/images/lending/logo_btg.svg'
import LogoBancoItau from '@/assets/images/lending/logo_itau.svg'
import LogoBancoSantander from '@/assets/images/lending/santander_bank.png'
import Button from '@/components/Ui/Button'
import api from '@/services/api'
import { RootState } from '@/store'
import { MenuItem, OutlinedInput, Select, TextField } from '@material-ui/core'
import Dialog from '@material-ui/core/Dialog'

import DocFile from './DocFile'
import * as S from './styles'

export enum ClientLendingStatusEnum {
  NOT_REQUESTED = 'NOT_REQUESTED',
  IN_EVALUATION = 'IN_EVALUATION',
  APPROVED = 'APPROVED',
  DENIED = 'DENIED',
}

export enum OccupationEnum {
  ENTREPRENEUR = 'ENTREPRENEUR',
  SALARIED = 'SALARIED',
  AUTONOMOUS = 'AUTONOMOUS',
  LIBERAL = 'LIBERAL',
  PUBLIC_SERVER = 'PUBLIC_SERVER',
  RETIREE = 'RETIREE',
  INVESTOR = 'INVESTOR',
}

export enum IssuerEnum {
  SSP = 'SSP',
  DETRAN = 'DETRAN',
  PM = 'PM',
  OAB = 'OAB',
  DIC = 'DIC',
}

export enum MaritalEnum {
  SINGLE = 'SINGLE',
  MARIED = 'MARIED',
  SEPARATE = 'SEPARATE',
  DIVORCED = 'DIVORCED',
  WIDOWER = 'WIDOWER',
}

export enum GenderEnum {
  Male = 'M',
  Female = 'F',
}

type ClientLendingType = {
  client_id?: string
  client?: any
  status?: ClientLendingStatusEnum
}

type ProposalType = {
  id: string
  created_at: string
  updated_at: string
  power_of_the_project: number
  client_id: string
  distributor_kit_cost: number
  sale_value: number
  franchisee_commission: number
  final_value: number
  final_installed_wp: number
  value_kit_for_customer: number
  nf_value: number
  tax_solar21: number
  contribution_margin: number
  total_amount_receivable_franchisee: number
  inverter_quantity: number
  inverter_power: number
  inverter_brand: string
  inverter_warranty: string
  module_quantity: number
  module_brand: string
  module_power: number
  annual_production: number
  annual_savings: number
  payback: number
  module_warranty: number
  type: string
}

type MockFile = {
  name: string
}

type BankFormikValues = {
  id: string
  bank: string
  term: number
  installment: string
  status: string
}

const bankValidate = (values: BankFormikValues) => {
  const { bank, term, installment, status } = values
  const errors: any = {}

  if (!bank) errors.bank = 'Mandatório'
  if (!term) errors.term = 'Mandatório'
  if (!status) errors.status = 'Mandatório'
  if (!installment || (installment && Number.isNaN(parseFloat(installment))))
    errors.installment = 'Mandatório'

  return errors
}

type EditFormikValues = {
  rg: string
  rgEmissionDate: string
  rgIssuer: string
  occupation: string
  profession: string
  admissionDate: string
  patrimony: number
  dependents: number
  monthlyIncome: number
  gender: string
  maritalStatus: string
}

const editValidate = (values: EditFormikValues) => {
  const {
    rg,
    rgEmissionDate,
    rgIssuer,
    admissionDate,
    dependents,
    gender,
    maritalStatus,
    monthlyIncome,
    occupation,
    patrimony,
    profession,
  } = values
  const errors: any = {}

  if (!rg) errors.rg = 'Mandatório'
  if (!rgEmissionDate) errors.rgEmissionDate = 'Mandatório'
  if (!rgIssuer) errors.rgIssuer = 'Mandatório'
  if (!admissionDate) errors.admissionDate = 'Mandatório'
  if (!dependents || (dependents && Number.isNaN(dependents)))
    errors.dependents = 'Mandatório'
  if (!gender) errors.gender = 'Mandatório'
  if (!maritalStatus) errors.maritalStatus = 'Mandatório'
  if (!monthlyIncome || (monthlyIncome && Number.isNaN(monthlyIncome)))
    errors.monthlyIncome = 'Mandatório'
  if (!occupation) errors.occupation = 'Mandatório'
  if (!patrimony || (patrimony && Number.isNaN(patrimony)))
    errors.patrimony = 'Mandatório'
  if (!profession) errors.profession = 'Mandatório'

  return errors
}

function NumberFormatCustom(props: {
  [x: string]: any
  name?: any
  inputRef?: any
  onChange?: any
}) {
  const { inputRef, onChange, ...other } = props

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={values => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        })
      }}
      thousandSeparator="."
      decimalSeparator=","
      isNumericString
      decimalScale={0}
      prefix="R$"
    />
  )
}

const Lending: React.FC = () => {
  const client = useSelector((state: RootState) => state.Client.client)
  // const { bill_owner } = useSelector(
  //   (state: RootState) => state.Bill.billDetails,
  // )

  const [bill_owner, setBillOwner] = useState<any>(undefined)
  const [proposal, setProposal] = useState<ProposalType | undefined>()
  const [lending, setLending] = useState<ClientLendingType | undefined>()

  const [userType, setUserType] = useState<string>('')

  const [DOCUMENT, setPersonalDoc] = useState<File | null | MockFile>(null)
  const [DOCUMENT_LINK, setPersonalDocLink] = useState<string>('')
  const [PROOF_OF_ADDRESS_LINK, setAddressDocLink] = useState<string>('')
  const [PROOF_OF_INCOME_LINK, setIncomeDocLink] = useState<string>('')
  const [PROOF_INCOME_TAX_LINK, setIRDocLink] = useState<string>('')
  const [PROOF_OF_MARRIAGE_LINK, setMarriageDocLink] = useState<string>('')
  const [INSTALATION_ADDRESS_LINK, setInstallDocLink] = useState<string>('')
  const [ADDITIONAL_DOCUMENT_LINK, setOtherDocLink] = useState<string>('')
  const [hasDocument, setHasDocument] = useState<boolean>(false)
  const [PROOF_OF_INCOME, setIncomeDoc] = useState<File | null | MockFile>(null)
  const [hasIncome, setHasIncome] = useState<boolean>(false)
  const [PROOF_OF_ADDRESS, setAddressDoc] = useState<File | null | MockFile>(
    null,
  )
  const [hasAddress, setHasAddress] = useState<boolean>(false)
  const [INSTALATION_ADDRESS, setInstallDoc] = useState<File | null | MockFile>(
    null,
  )
  const [hasInstalation, setHasInstalation] = useState<boolean>(false)
  const [PROOF_INCOME_TAX, setIRDoc] = useState<File | null | MockFile>(null)
  const [hasTax, setHasTax] = useState<boolean>(false)
  const [PROOF_OF_MARRIAGE, setMarriageDoc] = useState<File | null | MockFile>(
    null,
  )
  const [hasMarriage, setHasMarriage] = useState<boolean>(false)
  const [ADDITIONAL_DOCUMENT, setOtherDoc] = useState<File | null | MockFile>(
    null,
  )
  const [hasAdditional, setHasAdditional] = useState<boolean>(false)

  const [buttonText, setButtonText] = useState<string>('Solicitar')
  const [status, setStatus] = useState<ClientLendingStatusEnum>(
    ClientLendingStatusEnum.NOT_REQUESTED,
  )

  const [bankDialogOpen, setBankDialogOpen] = useState<boolean>(false)
  const [editDialogOpen, setEditDialogOpen] = useState<boolean>(false)

  const [cards, setCards] = useState<BankFormikValues[]>([])
  const [selectedCard, setSelectedCard] = useState<BankFormikValues | null>(
    null,
  )
  const [loading, setLoading] = React.useState<boolean>(false)
  const permission = useSelector((state: RootState) => state.Auth.role)
  const isFranchisee = permission === IPermission.FRANCHISEE

  useEffect(() => {
    if (isEmpty(bill_owner)) {
      api
        .get(`/admin/customers/${client.id}/bills/owners`)
        .then(({ data }) => setBillOwner(data))
        .catch(err => console.error('proposal retrieving did not work: ', err))
    }
  }, [bill_owner, client])

  useEffect(() => {
    const permission = localStorage.getItem('permission')
    if (permission !== null) setUserType(permission)
  }, [])

  useEffect(() => {
    if (isEmpty(proposal)) {
      api
        .get(`admin/customers/${client.id}/proposal`)
        .then(({ data }) => {
          const filteredData = data.filter(
            (item: ProposalType) => item.type === 'FINAL',
          )
          setProposal(last(filteredData))
        })
        .catch(err => console.error('proposal retrieving did not work: ', err))
    }
  }, [proposal, client])

  useEffect(() => {
    if (isEmpty(lending))
      api
        .get(`/admin/client_lending/${client.id}`)
        .then(({ data }) => {
          setLending(
            isEmpty(data)
              ? { status: ClientLendingStatusEnum.NOT_REQUESTED }
              : data,
          )
          if (data) {
            setCards(JSON.parse(data.lending_options_json))
          }
        })
        .catch(err => console.error('client lending did not work: ', err))
  }, [lending, client])

  useEffect(() => {
    if (isEmpty(lending)) return

    if (lending?.status === ClientLendingStatusEnum.APPROVED) {
      setButtonText('Aprovado')
      setStatus(ClientLendingStatusEnum.APPROVED)
    }
    if (lending?.status === ClientLendingStatusEnum.IN_EVALUATION) {
      setButtonText('Em análise')
      setStatus(ClientLendingStatusEnum.IN_EVALUATION)
    }
    if (lending?.status === ClientLendingStatusEnum.NOT_REQUESTED) {
      setButtonText('Não solicitado')
      setStatus(ClientLendingStatusEnum.NOT_REQUESTED)
    }
    if (lending?.status === ClientLendingStatusEnum.DENIED) {
      setButtonText('Reprovado')
      setStatus(ClientLendingStatusEnum.DENIED)
    }
  }, [lending])

  const getFileName = useCallback(
    file => {
      switch (file) {
        case 'doc_personal':
          return DOCUMENT ? DOCUMENT.name : 'Documento Pessoal'
        case 'doc_income':
          return PROOF_OF_INCOME ? PROOF_OF_INCOME.name : 'Comprovante de renda'
        case 'doc_address':
          return PROOF_OF_ADDRESS
            ? PROOF_OF_ADDRESS.name
            : 'Comprovante de residência'
        case 'doc_install':
          return INSTALATION_ADDRESS
            ? INSTALATION_ADDRESS.name
            : 'Comprovante de endereço de instalação'
        case 'doc_ir':
          return PROOF_INCOME_TAX
            ? PROOF_INCOME_TAX.name
            : 'Declaração do imposto de renda'
        case 'doc_marriage':
          return PROOF_OF_MARRIAGE
            ? PROOF_OF_MARRIAGE.name
            : 'Certificado de casamento'
        case 'doc_other':
          return ADDITIONAL_DOCUMENT
            ? ADDITIONAL_DOCUMENT.name
            : 'Outro documento'
        default:
          return 'Arquivo enviado'
      }
    },
    [
      DOCUMENT,
      PROOF_OF_INCOME,
      PROOF_OF_ADDRESS,
      PROOF_INCOME_TAX,
      INSTALATION_ADDRESS,
      PROOF_OF_MARRIAGE,
      ADDITIONAL_DOCUMENT,
    ],
  )

  const getDocuments = useCallback(() => {
    api
      .get(`/admin/customers/${client.id}/documents`)
      .then(({ data }) => {
        data.forEach((element: any) => {
          if (element.type === 'PROOF_OF_ADDRESS') {
            setHasAddress(true)
            setAddressDocLink(element.image_url)
            setAddressDoc({ name: element.file_name })
          }
          if (element.type === 'PROOF_OF_INCOME') {
            setHasIncome(true)
            setIncomeDocLink(element.image_url)
            setIncomeDoc({ name: element.file_name })
          }
          if (element.type === 'DOCUMENT') {
            setHasDocument(true)
            setPersonalDocLink(element.image_url)
            setPersonalDoc({ name: element.file_name })
          }
          if (element.type === 'PROOF_INCOME_TAX') {
            setHasTax(true)
            setIRDocLink(element.image_url)
            setIRDoc({ name: element.file_name })
          }
          if (element.type === 'PROOF_OF_MARRIAGE') {
            setHasMarriage(true)
            setMarriageDocLink(element.image_url)
            setMarriageDoc({ name: element.file_name })
          }
          if (element.type === 'INSTALATION_ADDRESS') {
            setHasInstalation(true)
            setInstallDocLink(element.image_url)
            setInstallDoc({ name: element.file_name })
          }
          if (element.type === 'ADDITIONAL_DOCUMENT') {
            setHasAdditional(true)
            setOtherDocLink(element.image_url)
            setOtherDoc({ name: element.file_name })
          }
        })
      })
      .catch(err => console.error(err))
  }, [
    client,
    setHasAddress,
    setAddressDoc,
    setHasIncome,
    setIncomeDoc,
    setHasDocument,
    setPersonalDoc,
    setHasTax,
    setIRDoc,
    setHasMarriage,
    setMarriageDoc,
    setHasInstalation,
    setInstallDoc,
    setHasAdditional,
    setOtherDoc,
  ])

  useEffect(() => {
    getDocuments()
  }, [client, getDocuments])

  const handleSend = useCallback(
    async (file: File, type: string) => {
      const formData = new FormData()
      formData.append(type.toLowerCase(), file)

      const headerParams = {
        'Access-Control-Allow-Headers': 'X-Requested-With',
        'Content-Type': 'multipart/form-data',
      }

      await api
        .post(
          `/admin/upload/${client.id}/document_type/${type}`,
          formData,
          headerParams as AxiosRequestConfig,
        )
        .catch(err => console.error(err))
    },
    [client],
  )

  const handlePersonalDoc = (file: File) => {
    if (hasDocument) return

    setPersonalDoc(file)
    handleSend(file, 'DOCUMENT')
  }

  const handleIncomeDoc = (file: File) => {
    if (hasIncome) return

    setIncomeDoc(file)
    handleSend(file, 'PROOF_OF_INCOME')
  }

  const handleAddressDoc = (file: File) => {
    if (hasAddress) return

    setAddressDoc(file)
    handleSend(file, 'PROOF_OF_ADDRESS')
  }

  const handleIRDoc = (file: File) => {
    if (hasTax) return

    setIRDoc(file)
    handleSend(file, 'PROOF_INCOME_TAX')
  }

  const handleMarriageDoc = (file: File) => {
    if (hasMarriage) return

    setMarriageDoc(file)
    handleSend(file, 'PROOF_OF_MARRIAGE')
  }

  const handleInstalationDoc = (file: File) => {
    if (hasInstalation) return

    setInstallDoc(file)
    handleSend(file, 'INSTALATION_ADDRESS')
  }

  const handleAdditionalDoc = (file: File) => {
    if (hasAdditional) return

    setOtherDoc(file)
    handleSend(file, 'ADDITIONAL_DOCUMENT')
  }

  const handleDissmissDocument = useCallback(
    (type: string) => {
      api
        .delete(`admin/customer/${client.id}/dismiss-document/${type}`)
        .catch(err => console.error('Error deleting customer document: ', err))
        .finally(() => getDocuments())
    },
    [client, getDocuments],
  )

  const saveCards = (
    newCard: BankFormikValues | null,
    values: BankFormikValues,
  ) => {
    let newCards
    if (newCard) {
      const currentCard = cards?.find(card => card.id === newCard.id)
      const cleanCard = cards?.filter(card => card.id !== currentCard?.id)
      newCards = [...cleanCard, { ...currentCard, status: values.status }]
    }
    if (!newCard) {
      const newId = cards?.length
      const cardsRefactored = cards?.map((card, index) => ({
        ...card,
        id: index,
      }))
      if (cardsRefactored)
        newCards = [...cardsRefactored, { ...values, id: newId }]
      else newCards = [{ ...values, id: newId }]
    }
    const newCardsJson = JSON.stringify(newCards)
    api
      .patch(`/admin/client_lending/json_update/${client.id}`, {
        lending_options_json: newCardsJson,
      })
      .then(() => {
        setSelectedCard(null)
        setBankDialogOpen(false)
        window.location.reload()
      })
      .catch(err => console.error('Error updating client lending JSON: ', err))
  }

  const bankFormik = useFormik({
    initialValues: {
      id: '',
      bank: 'Bv',
      term: 12,
      installment: '',
      status: 'Pending',
    },
    validate: bankValidate,
    validateOnBlur: false,
    validateOnChange: false,
    validateOnMount: false,
    onSubmit: (values, { resetForm }) => {
      setSelectedCard(null)
      resetForm()
      setBankDialogOpen(false)
    },
  })

  const updateClientBillOwner = (values: EditFormikValues) => {
    api
      .patch(`/admin/customers/${client.id}/bills/owners`, {
        rg: values.rg,
        rg_emission_date: values.rgEmissionDate,
        rg_issuer: values.rgIssuer,
        occupation: values.occupation,
        profession: values.profession,
        admission_date: values.admissionDate,
        patrimony: parseFloat(values.patrimony as any),
        dependents: parseInt(values.dependents as any, 10),
        monthly_income: parseFloat(values.monthlyIncome as any),
        gender: values.gender,
        marital_status: values.maritalStatus,
      })
      .then(() => window.location.reload())
      .catch(err => console.error('Error updating client bill owner: ', err))
  }

  const editFormik = useFormik({
    initialValues: {
      rg: '',
      rgEmissionDate: '',
      rgIssuer: '',
      occupation: '',
      profession: '',
      admissionDate: '',
      patrimony: 0,
      dependents: 0,
      monthlyIncome: 0,
      gender: '',
      maritalStatus: '',
    },
    validate: editValidate,
    validateOnBlur: false,
    validateOnChange: false,
    validateOnMount: false,
    onSubmit: (values, { resetForm }) => {
      api
        .patch(`/admin/customers/${client.id}/bills/owners`, {
          rg: values.rg,
          rg_emission_date: values.rgEmissionDate,
          rg_issuer: values.rgIssuer,
          occupation: values.occupation,
          profession: values.profession,
          admission_date: values.admissionDate,
          patrimony: values.patrimony,
          dependents: values.dependents,
          monthly_income: values.monthlyIncome,
          gender: values.gender,
          marital_status: values.maritalStatus,
        })
        .then(() => resetForm())
        .catch(err => console.error('Error updating client bill owner: ', err))
    },
  })

  const openEditCard = (card: BankFormikValues) => {
    setSelectedCard(card)
    setBankDialogOpen(true)
  }

  const handleChangeStatus = useCallback(
    (receivedStatus?: ClientLendingStatusEnum | undefined) => {
      let newStatus = receivedStatus ?? ClientLendingStatusEnum.IN_EVALUATION
      if (!receivedStatus && status === ClientLendingStatusEnum.NOT_REQUESTED)
        newStatus = ClientLendingStatusEnum.IN_EVALUATION
      if (!receivedStatus && status === ClientLendingStatusEnum.IN_EVALUATION)
        newStatus = ClientLendingStatusEnum.APPROVED

      api
        .patch(`/admin/client_lending/status_update/${client.id}/${newStatus}`)
        .catch(err =>
          console.error('client lending status update did not work: ', err),
        )
        .finally(() => setLending(undefined))
    },
    [status, client],
  )

  const submitSupplyButton = async () => {
    setLoading(true)
    await api.patch(`admin/customers/${client.id}/step_supply/${''}`)
    setLoading(false)
    toast.success('Step atualizado com sucesso', {
      onClose: () => window.location.reload(),
      autoClose: 2000,
    })
  }

  const getOccupation = (occupation: OccupationEnum | undefined) => {
    if (!occupation) return ``

    switch (occupation) {
      case 'ENTREPRENEUR':
        return `Empreendedor`
      case 'SALARIED':
        return `Assalariado`
      case 'AUTONOMOUS':
        return `Autônomo`
      case 'LIBERAL':
        return `Profissional liberal`
      case 'PUBLIC_SERVER':
        return `Servidor público`
      case 'RETIREE':
        return `Aposentado`
      case 'INVESTOR':
        return `Investidor`
      default:
        return ``
    }
  }

  const getMaritalStatus = (maritalStatus: MaritalEnum | undefined) => {
    if (!maritalStatus) return ``

    switch (maritalStatus) {
      case 'SINGLE':
        return `Solteiro(a)`
      case 'MARIED':
        return `Casado(a)`
      case 'SEPARATE':
        return `Separado(a)`
      case 'DIVORCED':
        return `Divorciado(a)`
      case 'WIDOWER':
        return `Viúvo(a)`
      default:
        return ``
    }
  }

  const getDateFormatted = (date: string | undefined) =>
    date ? date.split('-').reverse().join('/') : ``

  return (
    <S.Container>
      <S.HeaderSection>
        <S.SectionTitle>Status do Financiamento</S.SectionTitle>
        <S.StatusTip status={status}>{buttonText}</S.StatusTip>
        <S.Button onClick={() => setEditDialogOpen(true)}>
          Editar <BsPencil size={19} />
        </S.Button>
      </S.HeaderSection>
      <S.DataSection>
        <S.DataTitle>Dados do Cliente</S.DataTitle>
        <S.DataWrapper>
          <S.DataUnit>
            <S.TextStrong>Nome do Titular:</S.TextStrong>
            <span>{bill_owner?.name || ''}</span>
          </S.DataUnit>
          <S.DataUnit>
            <S.TextStrong>Telefone:</S.TextStrong>
            <span>{bill_owner?.phone || ''}</span>
          </S.DataUnit>
          <S.DataUnit>
            <S.TextStrong>E-mail:</S.TextStrong>
            <span>{bill_owner?.email || ''}</span>
          </S.DataUnit>
          <S.DataUnit>
            <S.TextStrong>RG:</S.TextStrong>
            <span>{bill_owner?.rg || ''}</span>
          </S.DataUnit>
          <S.DataUnit>
            <S.TextStrong>Data emissão RG:</S.TextStrong>
            <span>{getDateFormatted(bill_owner?.rg_emission_date)}</span>
          </S.DataUnit>
          <S.DataUnit>
            <S.TextStrong>Orgão Emissor:</S.TextStrong>
            <span>{bill_owner?.rg_issuer || ''}</span>
          </S.DataUnit>
          <S.DataUnit>
            <S.TextStrong>CPF:</S.TextStrong>
            <span>{bill_owner?.document_value || ''}</span>
          </S.DataUnit>
          <S.DataUnit>
            <S.TextStrong>Data de Nasc. do Titular:</S.TextStrong>
            <span>{getDateFormatted(bill_owner?.date_of_birth)}</span>
          </S.DataUnit>
        </S.DataWrapper>
        <S.DataTitle>Dados do Endereço</S.DataTitle>
        <S.DataWrapper>
          <S.DataUnit>
            <S.TextStrong>CEP:</S.TextStrong>
            <span>{bill_owner?.postal_code || ''}</span>
          </S.DataUnit>
          <S.DataUnit>
            <S.TextStrong>Endereço:</S.TextStrong>
            <span>{bill_owner?.address || ''}</span>
          </S.DataUnit>
          <S.DataUnit>
            <S.TextStrong>Número:</S.TextStrong>
            <span>{bill_owner?.address_number || ''}</span>
          </S.DataUnit>
          <S.DataUnit>
            <S.TextStrong>Estado:</S.TextStrong>
            <span>{bill_owner?.state || ''}</span>
          </S.DataUnit>
          <S.DataUnit>
            <S.TextStrong>Cidade:</S.TextStrong>
            <span>{bill_owner?.city || ''}</span>
          </S.DataUnit>
          <S.DataUnit>
            <S.TextStrong>Complemento:</S.TextStrong>
            <span>{bill_owner?.address_complement || ''}</span>
          </S.DataUnit>
        </S.DataWrapper>
        <S.DataTitle>Dados Pessoais</S.DataTitle>
        <S.DataWrapper last>
          <S.DataUnit>
            <S.TextStrong>Natureza da Ocupação:</S.TextStrong>
            <span>{getOccupation(bill_owner?.occupation)}</span>
          </S.DataUnit>
          <S.DataUnit>
            <S.TextStrong>Profissão:</S.TextStrong>
            <span>{bill_owner?.profession || ''}</span>
          </S.DataUnit>
          <S.DataUnit>
            <S.TextStrong>Data de Adimissão:</S.TextStrong>
            <span>{getDateFormatted(bill_owner?.admission_date)}</span>
          </S.DataUnit>
          <S.DataUnit>
            <S.TextStrong>Estado Civil:</S.TextStrong>
            <span>{getMaritalStatus(bill_owner?.marital_status)}</span>
          </S.DataUnit>
          <S.DataUnit>
            <S.TextStrong>Patrimônio:</S.TextStrong>
            <span>R$ {bill_owner?.patrimony || ''}</span>
          </S.DataUnit>
          <S.DataUnit>
            <S.TextStrong>Dependentes:</S.TextStrong>
            <span>{bill_owner?.dependents || ''}</span>
          </S.DataUnit>
          <S.DataUnit>
            <S.TextStrong>Renda Mensal:</S.TextStrong>
            <span>R$ {bill_owner?.monthly_income || ''}</span>
          </S.DataUnit>
        </S.DataWrapper>
      </S.DataSection>
      <S.RequestWrapper>
        {status === ClientLendingStatusEnum.NOT_REQUESTED && (
          <Button full onClick={() => handleChangeStatus()}>
            Solicitar Financiamento
          </Button>
        )}
        {status === ClientLendingStatusEnum.IN_EVALUATION && (
          <S.RequestWrapperButtons>
            <S.DenyButton
              type="button"
              onClick={() => handleChangeStatus(ClientLendingStatusEnum.DENIED)}
            >
              Reprovar
            </S.DenyButton>
            <Button full onClick={() => handleChangeStatus()}>
              Aprovar
            </Button>
          </S.RequestWrapperButtons>
        )}
      </S.RequestWrapper>
      {status !== ClientLendingStatusEnum.NOT_REQUESTED && (
        <>
          <S.HeaderSection>
            <S.SectionTitle>Documentos</S.SectionTitle>
          </S.HeaderSection>
          <S.Sheet column>
            <DocFile
              id="personal-document"
              text="Documento pessoal"
              onChange={handlePersonalDoc}
              file={!!DOCUMENT}
              url_download={DOCUMENT_LINK}
              sent={false}
              getName={getFileName('doc_personal')}
              onClick={() => {
                handleDissmissDocument('DOCUMENT')
                setPersonalDoc(null)
              }}
              dismiss={userType === IPermission.ADMIN && !!DOCUMENT}
            />
            <DocFile
              id="income-document"
              text="Comprovante de renda"
              onChange={handleIncomeDoc}
              file={!!PROOF_OF_INCOME}
              url_download={PROOF_OF_INCOME_LINK}
              sent={false}
              getName={getFileName('doc_income')}
              onClick={() => {
                handleDissmissDocument('PROOF_OF_INCOME')
                setIncomeDoc(null)
              }}
              dismiss={userType === IPermission.ADMIN && !!PROOF_OF_INCOME}
            />
            <DocFile
              id="address-document"
              text="Comprovante de end."
              onChange={handleAddressDoc}
              file={!!PROOF_OF_ADDRESS}
              url_download={PROOF_OF_ADDRESS_LINK}
              sent={false}
              getName={getFileName('doc_address')}
              onClick={() => {
                handleDissmissDocument('PROOF_OF_INCOME')
                setAddressDoc(null)
              }}
              dismiss={userType === IPermission.ADMIN && !!PROOF_OF_ADDRESS}
            />
            <DocFile
              id="install-document"
              text="Comprovante de end. da instalação"
              onChange={handleInstalationDoc}
              file={!!INSTALATION_ADDRESS}
              url_download={INSTALATION_ADDRESS_LINK}
              sent={false}
              getName={getFileName('doc_install')}
              onClick={() => {
                handleDissmissDocument('PROOF_OF_INCOME')
                setInstallDoc(null)
              }}
              dismiss={userType === IPermission.ADMIN && !!INSTALATION_ADDRESS}
            />
            <DocFile
              id="ir-document"
              text="Comprovante do IR"
              onChange={handleIRDoc}
              file={!!PROOF_INCOME_TAX}
              url_download={PROOF_INCOME_TAX_LINK}
              sent={false}
              getName={getFileName('doc_ir')}
              onClick={() => {
                handleDissmissDocument('PROOF_OF_INCOME')
                setIRDoc(null)
              }}
              dismiss={userType === IPermission.ADMIN && !!PROOF_INCOME_TAX}
            />
            <DocFile
              id="marriage-document"
              text="Certidão de casamento"
              onChange={handleMarriageDoc}
              file={!!PROOF_OF_MARRIAGE}
              url_download={PROOF_OF_MARRIAGE_LINK}
              sent={false}
              getName={getFileName('doc_marriage')}
              onClick={() => {
                handleDissmissDocument('PROOF_OF_INCOME')
                setMarriageDoc(null)
              }}
              dismiss={userType === IPermission.ADMIN && !!PROOF_OF_MARRIAGE}
            />
            <DocFile
              id="other-document"
              text="Outro"
              onChange={handleAdditionalDoc}
              file={!!ADDITIONAL_DOCUMENT}
              url_download={ADDITIONAL_DOCUMENT_LINK}
              sent={false}
              getName={getFileName('doc_other')}
              onClick={() => {
                handleDissmissDocument('PROOF_OF_INCOME')
                setOtherDoc(null)
              }}
              dismiss={userType === IPermission.ADMIN && !!ADDITIONAL_DOCUMENT}
            />
          </S.Sheet>
        </>
      )}
      {!isFranchisee && status === ClientLendingStatusEnum.APPROVED && (
        <S.RequestWrapper>
          <Button full onClick={submitSupplyButton} loading={loading}>
            {loading
              ? 'Gerando NF-e, aguarde...'
              : 'Confirmar pagamento e enviar para supply'}
          </Button>
        </S.RequestWrapper>
      )}
      {status !== ClientLendingStatusEnum.NOT_REQUESTED && (
        <>
          <S.HeaderSection>
            <S.SectionTitle>
              Propostas de financiamento aprovadas
            </S.SectionTitle>
          </S.HeaderSection>
          <S.LendingSection>
            {cards?.map((card, index) => {
              return (
                <S.LendingCard
                  key={`card-${index}-${card.installment}`}
                  onClick={() => openEditCard(card)}
                >
                  {card.status === 'Pending' && (
                    <S.LendingCardStatus color="#fcedae">
                      Pendente
                    </S.LendingCardStatus>
                  )}
                  {card.status === 'Approved' && (
                    <S.LendingCardStatus color="#cff3bc">
                      Aprovado
                    </S.LendingCardStatus>
                  )}
                  {card.status === 'Denied' && (
                    <S.LendingCardStatus color="#ffbec3">
                      Reprovado
                    </S.LendingCardStatus>
                  )}
                  {card.bank === 'Bv' && (
                    <img src={LogoBancoBv} alt="logo banco BV" />
                  )}
                  {card.bank === 'Btg' && (
                    <img src={LogoBancoBtg} alt="logo banco BTG" />
                  )}
                  {card.bank === 'Itau' && (
                    <img src={LogoBancoItau} alt="logo banco Itaú" />
                  )}
                  {card.bank === 'Santander' && (
                    <img src={LogoBancoSantander} alt="logo banco Santander" />
                  )}
                  <S.CardRow>
                    <S.CardColumn>
                      <S.CardTitle>Prazo</S.CardTitle>
                      <S.CardValue>{card.term}x</S.CardValue>
                    </S.CardColumn>
                    <S.CardColumn>
                      <S.CardTitle>Valor da parcela</S.CardTitle>
                      <S.CardValue>
                        <S.CardMoneySign>R$</S.CardMoneySign> {card.installment}
                      </S.CardValue>
                    </S.CardColumn>
                  </S.CardRow>
                </S.LendingCard>
              )
            })}
            {!isFranchisee && (
              <S.AddButton
                type="button"
                onClick={() => setBankDialogOpen(true)}
              >
                +
              </S.AddButton>
            )}
          </S.LendingSection>
        </>
      )}
      {/* Edit Dialog */}
      <Dialog open={editDialogOpen} maxWidth="lg">
        <S.EditDialogForm onSubmit={editFormik.handleSubmit}>
          <S.CloseDialog onClick={() => setEditDialogOpen(false)}>
            <img src={CloseButton} alt="ícone fechar" />
          </S.CloseDialog>
          <S.FormControl fullWidth variant="outlined" focused>
            <label htmlFor="rg">RG:</label>
            <OutlinedInput
              id="rg"
              name="rg"
              value={editFormik.values.rg}
              onChange={editFormik.handleChange}
              error={!!editFormik.errors.rg}
            />
          </S.FormControl>
          <S.FormControl fullWidth variant="outlined" focused>
            <label htmlFor="rgEmissionDate">Data de emissão:</label>
            <OutlinedInput
              id="rgEmissionDate"
              name="rgEmissionDate"
              value={editFormik.values.rgEmissionDate}
              onChange={editFormik.handleChange}
              error={!!editFormik.errors.rgEmissionDate}
              type="date"
            />
          </S.FormControl>
          <S.FormControl fullWidth variant="outlined" focused>
            <label htmlFor="rgIssuer">Orgão Emissor:</label>
            <Select
              id="rgIssuer"
              name="rgIssuer"
              value={editFormik.values.rgIssuer}
              onChange={editFormik.handleChange}
              error={!!editFormik.errors.rgIssuer}
            >
              <MenuItem value={IssuerEnum.SSP}>SSP</MenuItem>
              <MenuItem value={IssuerEnum.DETRAN}>DETRAN</MenuItem>
              <MenuItem value={IssuerEnum.PM}>PM</MenuItem>
              <MenuItem value={IssuerEnum.OAB}>OAB</MenuItem>
              <MenuItem value={IssuerEnum.DIC}>DIC</MenuItem>
            </Select>
          </S.FormControl>
          <S.FormControl fullWidth variant="outlined" focused>
            <label htmlFor="occupation">Ocupação:</label>
            <Select
              id="occupation"
              name="occupation"
              value={editFormik.values.occupation}
              onChange={editFormik.handleChange}
              error={!!editFormik.errors.occupation}
            >
              <MenuItem value={OccupationEnum.ENTREPRENEUR}>
                Empreendedor
              </MenuItem>
              <MenuItem value={OccupationEnum.SALARIED}>Assalariado</MenuItem>
              <MenuItem value={OccupationEnum.AUTONOMOUS}>Autônomo</MenuItem>
              <MenuItem value={OccupationEnum.LIBERAL}>Liberal</MenuItem>
              <MenuItem value={OccupationEnum.PUBLIC_SERVER}>
                Servidor Público
              </MenuItem>
              <MenuItem value={OccupationEnum.RETIREE}>Aposentado (a)</MenuItem>
              <MenuItem value={OccupationEnum.INVESTOR}>
                Investidor (a)
              </MenuItem>
            </Select>
          </S.FormControl>
          <S.FormControl fullWidth variant="outlined" focused>
            <label htmlFor="profession">Profissão:</label>
            <OutlinedInput
              id="profession"
              name="profession"
              value={editFormik.values.profession}
              onChange={editFormik.handleChange}
              error={!!editFormik.errors.profession}
            />
          </S.FormControl>
          <S.FormControl fullWidth variant="outlined" focused>
            <label htmlFor="admissionDate">Data da admissão:</label>
            <OutlinedInput
              id="admissionDate"
              name="admissionDate"
              value={editFormik.values.admissionDate}
              onChange={editFormik.handleChange}
              error={!!editFormik.errors.admissionDate}
              type="date"
            />
          </S.FormControl>
          <S.FormControl fullWidth variant="outlined" focused>
            <label htmlFor="patrimony">Patrimônio:</label>
            <TextField
              variant="outlined"
              value={editFormik.values.patrimony}
              onChange={editFormik.handleChange}
              id="patrimony"
              name="patrimony"
              InputProps={{
                inputComponent: NumberFormatCustom,
              }}
              error={!!editFormik.errors.patrimony}
            />
          </S.FormControl>
          <S.FormControl fullWidth variant="outlined" focused>
            <label htmlFor="dependents">Dependentes:</label>
            <OutlinedInput
              id="dependents"
              name="dependents"
              type="number"
              value={editFormik.values.dependents}
              onChange={editFormik.handleChange}
              error={!!editFormik.errors.dependents}
            />
          </S.FormControl>
          <S.FormControl fullWidth variant="outlined" focused>
            <label htmlFor="monthlyIncome">Renda mensal:</label>
            <TextField
              variant="outlined"
              value={editFormik.values.monthlyIncome}
              onChange={editFormik.handleChange}
              id="monthlyIncome"
              name="monthlyIncome"
              InputProps={{
                inputComponent: NumberFormatCustom,
              }}
              error={!!editFormik.errors.monthlyIncome}
            />
          </S.FormControl>
          <S.FormControl fullWidth variant="outlined" focused>
            <label htmlFor="gender">Sexo:</label>
            <Select
              id="gender"
              name="gender"
              value={editFormik.values.gender}
              onChange={editFormik.handleChange}
              error={!!editFormik.errors.gender}
            >
              <MenuItem value={GenderEnum.Male}>Masculino</MenuItem>
              <MenuItem value={GenderEnum.Female}>Feminino</MenuItem>
            </Select>
          </S.FormControl>
          <S.FormControl fullWidth variant="outlined" focused>
            <label htmlFor="maritalStatus">Estado civil:</label>
            <Select
              id="maritalStatus"
              name="maritalStatus"
              value={editFormik.values.maritalStatus}
              onChange={editFormik.handleChange}
              error={!!editFormik.errors.maritalStatus}
            >
              <MenuItem value={MaritalEnum.SINGLE}>Solteiro (a)</MenuItem>
              <MenuItem value={MaritalEnum.MARIED}>Casado (a)</MenuItem>
              <MenuItem value={MaritalEnum.DIVORCED}>Divorciado (a)</MenuItem>
              <MenuItem value={MaritalEnum.SEPARATE}>Separado (a)</MenuItem>
              <MenuItem value={MaritalEnum.WIDOWER}>Viúvo (a)</MenuItem>
            </Select>
          </S.FormControl>
          <S.DialogButtonWrapper>
            <S.CancelButton
              type="button"
              onClick={() => setEditDialogOpen(false)}
            >
              Cancelar
            </S.CancelButton>
            <S.ConfirmButton
              type="button"
              onClick={() => updateClientBillOwner(editFormik.values)}
            >
              Atualizar
            </S.ConfirmButton>
          </S.DialogButtonWrapper>
        </S.EditDialogForm>
      </Dialog>
      {/* Bank Dialog */}
      <Dialog open={bankDialogOpen}>
        <S.BankDialogForm onSubmit={bankFormik.handleSubmit}>
          <S.CloseDialog
            onClick={() => {
              setSelectedCard(null)
              setBankDialogOpen(false)
            }}
          >
            <img src={CloseButton} alt="ícone fechar" />
          </S.CloseDialog>
          {bankFormik.values.bank === 'Bv' && (
            <div style={{ marginBottom: '1rem' }}>
              <img src={LogoBancoBv} alt="logo banco BV" />
            </div>
          )}
          {bankFormik.values.bank === 'Btg' && (
            <div style={{ marginBottom: '1rem' }}>
              <img src={LogoBancoBtg} alt="logo banco BTG" />
            </div>
          )}
          {bankFormik.values.bank === 'Itau' && (
            <div style={{ marginBottom: '1rem' }}>
              <img src={LogoBancoItau} alt="logo banco Itaú" />
            </div>
          )}
          {bankFormik.values.bank === 'Santander' && (
            <div style={{ marginBottom: '1rem' }}>
              <img src={LogoBancoSantander} alt="logo banco Santander" />
            </div>
          )}
          <S.FormControl fullWidth variant="outlined" focused>
            <label htmlFor="bank">Banco:</label>
            <Select
              id="bank"
              name="bank"
              value={selectedCard?.bank || bankFormik.values.bank}
              onChange={bankFormik.handleChange}
              label="Banco"
              error={!!bankFormik.errors.bank}
              disabled={!!selectedCard}
            >
              <MenuItem value="Bv">Banco BV</MenuItem>
              <MenuItem value="Btg">Banco BTG</MenuItem>
              <MenuItem value="Itau">Banco Itaú</MenuItem>
              <MenuItem value="Santander">Santander</MenuItem>
            </Select>
          </S.FormControl>
          <S.FormControl fullWidth variant="outlined" focused>
            <label htmlFor="term">Prazo:</label>
            <Select
              id="term"
              name="term"
              value={selectedCard?.term || bankFormik.values.term}
              onChange={bankFormik.handleChange}
              label="Prazo"
              error={!!bankFormik.errors.term}
              disabled={!!selectedCard}
            >
              <MenuItem value={12}>12 meses</MenuItem>
              <MenuItem value={24}>24 meses</MenuItem>
              <MenuItem value={48}>48 meses</MenuItem>
              <MenuItem value={60}>60 meses</MenuItem>
              <MenuItem value={72}>72 meses</MenuItem>
            </Select>
          </S.FormControl>
          <S.FormControl fullWidth variant="outlined" focused>
            <label htmlFor="installment">Valor da parcela:</label>
            <TextField
              variant="outlined"
              value={selectedCard?.installment || bankFormik.values.installment}
              onChange={bankFormik.handleChange}
              id="installment"
              name="installment"
              InputProps={{
                inputComponent: NumberFormatCustom,
              }}
              error={!!bankFormik.errors.installment}
              disabled={!!selectedCard}
            />
          </S.FormControl>
          <S.FormControl fullWidth variant="outlined" focused>
            <label htmlFor="status">Status:</label>
            <Select
              id="status"
              name="status"
              value={bankFormik.values.status}
              onChange={bankFormik.handleChange}
              label="Status"
              error={!!bankFormik.errors.status}
            >
              <MenuItem value="Pending">Pendente</MenuItem>
              <MenuItem value="Approved">Aprovado</MenuItem>
              <MenuItem value="Denied">Negado</MenuItem>
            </Select>
          </S.FormControl>
          <S.DialogButtonWrapper>
            <S.CancelButton
              type="button"
              onClick={() => {
                setSelectedCard(null)
                setBankDialogOpen(false)
              }}
            >
              Cancelar
            </S.CancelButton>
            <S.ConfirmButton
              type="button"
              onClick={() => saveCards(selectedCard, bankFormik.values)}
            >
              Adicionar
            </S.ConfirmButton>
          </S.DialogButtonWrapper>
        </S.BankDialogForm>
      </Dialog>
    </S.Container>
  )
}

export default Lending
