import produce from 'immer'
import { createActions, createReducer } from 'reduxsauce'

import {
  StateTypeEnum,
  IActionTypes,
  IStateInitialState,
  IStateData,
  IStateToEditPayload,
} from './types'

type StateType = typeof StateTypeEnum

export const { Types: StateTypes, Creators: StateCreators } = createActions<
  StateType,
  IActionTypes
>({
  getStateRequest: { stateId: '' },
  getStateSuccess: ['stateToEdit'],
  getStateFailure: {},

  getStatesRequest: {},
  getStatesSuccess: ['states'],
  getStatesFailure: {},

  createStateRequest: { name: '', initials: '', dryTariff: 0 },
  createStateSuccess: ['state_data'],
  createStateFailure: {},

  updateStateRequest: ['state'],
  updateStateSuccess: {},
  updateStateFailure: {},

  deleteStateRequest: { stateId: '' },
  deleteStateSuccess: { stateId: '' },
  deleteStateFailure: {},
})

export const INITIAL_STATE: IStateInitialState = {
  states: [],
  stateToEdit: null,
  isLoading: false,
}

export const getStateRequest = (state = INITIAL_STATE) =>
  produce(state, () => ({ ...state, isLoading: true }))

export const getStateSuccess = (
  state = INITIAL_STATE,
  { stateToEdit }: { stateToEdit: IStateToEditPayload | null },
) =>
  produce(state, () => ({
    ...state,
    stateToEdit,
    isLoading: false,
  }))

export const getStateFailure = (state = INITIAL_STATE) =>
  produce(state, () => ({ ...state, isLoading: false }))

export const getStatesRequest = (state = INITIAL_STATE) =>
  produce(state, () => ({
    ...state,
    isLoading: true,
  }))

export const getStatesSuccess = (
  state = INITIAL_STATE,
  { states }: { states: IStateData[] },
) =>
  produce(state, () => ({
    ...state,
    states,
    isLoading: false,
  }))

export const getStatesFailure = (state = INITIAL_STATE) =>
  produce(state, () => ({
    ...state,
    isLoading: false,
  }))

export const createStateRequest = (state = INITIAL_STATE) =>
  produce(state, () => ({
    ...state,
    isLoading: true,
  }))

export const createStateSuccess = (
  state = INITIAL_STATE,
  { state_data }: { state_data: IStateData },
) =>
  produce(state, () => ({
    ...state,
    states: [state_data, ...state.states],
    isLoading: false,
  }))

export const createStateFailure = (state = INITIAL_STATE) =>
  produce(state, () => ({
    ...state,
    isLoading: false,
  }))

export const updateStateRequest = (state = INITIAL_STATE) =>
  produce(state, () => ({
    ...state,
    isLoading: true,
  }))

export const updateStateSuccess = (state = INITIAL_STATE) =>
  produce(state, () => ({
    ...state,
    isLoading: false,
  }))

export const updateStateFailure = (state = INITIAL_STATE) =>
  produce(state, () => ({
    ...state,
    isLoading: false,
  }))

export const deleteStateRequest = (state = INITIAL_STATE) =>
  produce(state, () => ({
    ...state,
    isLoading: true,
  }))

export const deleteStateSuccess = (
  state = INITIAL_STATE,
  { stateId }: { stateId: IStateData['id'] },
) =>
  produce(state, () => ({
    ...state,
    states: state.states.filter(_state => _state.id !== stateId),
    isLoading: false,
  }))

export const deleteStateFailure = (state = INITIAL_STATE) =>
  produce(state, () => ({ ...state, isLoading: false }))

export const reducer = createReducer<IStateInitialState, IActionTypes>(
  INITIAL_STATE,
  {
    [StateTypes.GET_STATE_REQUEST]: getStateRequest,
    [StateTypes.GET_STATE_SUCCESS]: getStateSuccess,
    [StateTypes.GET_STATE_FAILURE]: getStateFailure,

    [StateTypes.GET_STATES_REQUEST]: getStatesRequest,
    [StateTypes.GET_STATES_SUCCESS]: getStatesSuccess,
    [StateTypes.GET_STATES_FAILURE]: getStatesFailure,

    [StateTypes.CREATE_STATE_REQUEST]: createStateRequest,
    [StateTypes.CREATE_STATE_SUCCESS]: createStateSuccess,
    [StateTypes.CREATE_STATE_FAILURE]: createStateFailure,

    [StateTypes.UPDATE_STATE_REQUEST]: updateStateRequest,
    [StateTypes.UPDATE_STATE_SUCCESS]: updateStateSuccess,
    [StateTypes.UPDATE_STATE_FAILURE]: updateStateFailure,

    [StateTypes.DELETE_STATE_REQUEST]: deleteStateRequest,
    [StateTypes.DELETE_STATE_SUCCESS]: deleteStateSuccess,
    [StateTypes.DELETE_STATE_FAILURE]: deleteStateFailure,
  },
)
