export const formatPhoneWithDDI = (phone: string): string => {
  return phone.length !== 13
    ? phone
    : phone
        .replace(/\D/g, '')
        .replace(/^(\d{2})(\d)/g, '+$1 $2')
        .replace(/(\d{2})(\d)/, '($1) $2 ')
        .replace(/(\d)(\d{4})$/, '$1-$2')
}

// eslint-disable-next-line
export const file2Base64 = (file: any): Promise<any> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = error => reject(error)
  })
}
