import { toast } from 'react-toastify'

import { select, call, put } from 'redux-saga/effects'

import api from '@/services/api'
import { RootState } from '@/store'
import { DatabookCreators, IUpdateDatabookData } from '@/store/ducks/Databook'

const getClientId = (state: RootState) => state.Client.client.id

export function* getDatabook() {
  const clientId = yield select(getClientId)

  try {
    const { data: databook } = yield call(
      api.get,
      `/admin/customers/${clientId}/databooks`,
    )

    yield put(DatabookCreators.getDatabookSuccess(databook))
  } catch {
    toast.error('Não foi possível carregar o Databook do cliente.')
    yield put(DatabookCreators.getDatabookFailure())
  }
}

export function* editDatabook({
  databook,
}: {
  databook: IUpdateDatabookData[]
}) {
  try {
    yield call(api.patch, `/admin/customers/databooks`, databook)

    yield put(DatabookCreators.editDatabookSuccess())
    toast.success('Databook editado com sucesso!')
  } catch {
    toast.error('Não foi possível atualizar o Databook do cliente.')
    yield put(DatabookCreators.editDatabookFailure())
  }
}
