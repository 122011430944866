import React from 'react'

import { useState } from '@/hooks/useState'
import { IUserPayload } from '@/store/ducks/Users/types'
import {
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  IconButton,
  TextField,
  Switch,
} from '@material-ui/core'
import { yellow } from '@material-ui/core/colors'
import { withStyles } from '@material-ui/core/styles'
import {
  DeleteForeverOutlined as DeleteIcon,
  DoneAllTwoTone as DoneIcon,
  EditOutlined as EditIcon,
  NotInterestedOutlined as RevertIcon,
} from '@material-ui/icons'

import CouponDeleteDialog from './DeleteDialog'
import * as CouponTypes from './types'

interface ICouponsTable {
  users: IUserPayload[]
  coupons: CouponTypes.ICoupon[]
  creating: boolean
  onDelete: ({ id }: { id: CouponTypes.ICoupon['id'] }) => void
  onEditStatus: ({
    id,
    active,
  }: {
    id: CouponTypes.ICoupon['id']
    active: CouponTypes.ICoupon['active']
  }) => void
  onEditSubmit: ({
    id,
    coupon_code,
    coupon_ref,
  }: {
    id: CouponTypes.ICoupon['id']
    coupon_code: CouponTypes.ICoupon['coupon_code']
    coupon_ref: CouponTypes.ICoupon['coupon_ref']
  }) => void
}

interface IState {
  editId: number
  newItem_coupon_code: string
  newItem_coupon_ref: string
  showDeleteDialog: number
}

const CustomSwitch = withStyles({
  switchBase: {
    color: yellow[500],
    '&$checked': {
      color: '#ffd500',
    },
    '&$checked + $track': {
      backgroundColor: '#ffd500',
    },
  },
  checked: {},
  track: {},
})(Switch)

const CouponsTable: React.FC<ICouponsTable> = ({
  coupons,
  creating,
  onEditSubmit,
  onDelete,
  onEditStatus,
  users,
}) => {
  const { state, setState } = useState<IState>({
    editId: 0,
    newItem_coupon_code: '',
    newItem_coupon_ref: '',
    showDeleteDialog: 0,
  })

  React.useEffect(() => {
    setState({
      editId: 0,
      newItem_coupon_code: '',
      newItem_coupon_ref: '',
      showDeleteDialog: 0,
    })
    // eslint-disable-next-line
  }, [coupons])

  return (
    <>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Cupom</TableCell>
              <TableCell>Ref.</TableCell>
              <TableCell>Usuário</TableCell>
              <TableCell>Ativo?</TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {coupons.map(coupon => (
              <TableRow key={coupon.id}>
                {state.editId === coupon.id ? (
                  <>
                    <TableCell align="left">
                      <TextField
                        disabled={creating}
                        variant="outlined"
                        label="Código"
                        value={state.newItem_coupon_code}
                        onChange={e =>
                          setState({ newItem_coupon_code: e.target.value })
                        }
                      />
                    </TableCell>
                    <TableCell align="left">
                      <TextField
                        disabled={creating}
                        variant="outlined"
                        label="Referência"
                        value={state.newItem_coupon_ref}
                        onChange={e =>
                          setState({ newItem_coupon_ref: e.target.value })
                        }
                      />
                    </TableCell>
                    <TableCell align="left">-</TableCell>
                    <TableCell align="right">
                      <IconButton
                        disabled={creating}
                        aria-label="done"
                        onClick={() =>
                          onEditSubmit({
                            id: coupon.id,
                            coupon_code: state.newItem_coupon_code,
                            coupon_ref: state.newItem_coupon_ref,
                          })
                        }
                      >
                        <DoneIcon />
                      </IconButton>
                      <IconButton
                        disabled={creating}
                        aria-label="revert"
                        onClick={() =>
                          setState({
                            editId: 0,
                            newItem_coupon_code: '',
                            newItem_coupon_ref: '',
                          })
                        }
                      >
                        <RevertIcon />
                      </IconButton>
                    </TableCell>
                  </>
                ) : (
                  <>
                    <TableCell align="left">{coupon.coupon_code}</TableCell>
                    <TableCell align="left">{coupon.coupon_ref}</TableCell>
                    <TableCell align="left">
                      {users.find(
                        (_user: IUserPayload) => _user.id === coupon.user_id,
                      )?.fullname || '-'}
                    </TableCell>
                    <TableCell align="left">
                      <CustomSwitch
                        disabled={creating}
                        onChange={e =>
                          onEditStatus({
                            id: coupon.id,
                            active: e.target.checked,
                          })
                        }
                        checked={coupon.active}
                      />
                    </TableCell>
                    <TableCell align="right">
                      <IconButton
                        disabled={creating}
                        onClick={() =>
                          setState({
                            editId: coupon.id,
                            newItem_coupon_code: coupon.coupon_code,
                            newItem_coupon_ref: coupon.coupon_ref,
                          })
                        }
                      >
                        <EditIcon />
                      </IconButton>
                      <IconButton
                        disabled={creating}
                        onClick={() =>
                          setState({ showDeleteDialog: coupon.id })
                        }
                      >
                        <DeleteIcon />
                      </IconButton>
                    </TableCell>
                  </>
                )}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <CouponDeleteDialog
        disabledActions={creating}
        onClose={() => setState({ showDeleteDialog: 0 })}
        open={!!state.showDeleteDialog}
        onConfirm={() => onDelete({ id: state.showDeleteDialog })}
      />
    </>
  )
}

export default CouponsTable
