import React, { useCallback, useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'

import { ReportStepStatusEnum } from '@/@types/clientReportSteps/clientReportSteps'
import { FullScreenLoader } from '@/components/FullScreenLoader'
import Button from '@/components/Ui/Button'
import Input from '@/components/Ui/Form/Input'
import { RadioButtons } from '@/components/Ui/RadioButtons'
import { StepDetailTitle } from '@/components/Ui/StepDetailTitle'
import { RootState } from '@/store'
import { ReportCreators } from '@/store/ducks/ClientReport'
import { Scope, FormHandles } from '@unform/core'
import { Form } from '@unform/web'

import {
  Container,
  ContainerInput,
  ContainerButton,
  InvisibleInput,
  Header,
} from './styles'

interface IFormData {
  steps: []
}
interface ReportThreeProps {
  isDisabled?: boolean
}

const ReportThree: React.FC<ReportThreeProps> = ({ isDisabled = false }) => {
  const dispatch = useDispatch()
  const formRef = useRef<FormHandles>(null)

  useEffect(() => {
    dispatch(ReportCreators.getReportThreeRequest())
  }, [dispatch])

  const reportThree = useSelector(
    (state: RootState) => state.ClientReport.clientReportThree,
  )
  const isLoading = useSelector(
    (state: RootState) => state.ClientReport.isLoading,
  )
  const steps = reportThree?.steps.map((item, itemIndex) => {
    return (
      <div>
        <Scope path={`steps[${itemIndex}]`}>
          <StepDetailTitle
            number={item.step.toString()}
            title={item.description}
          >
            {item.choices.map((choices, choicesIndex) => {
              return (
                <>
                  <Scope path={`choices[${choicesIndex}]`}>
                    <InvisibleInput
                      name="id"
                      value={choices.id}
                      disabled={isDisabled}
                    />
                    <RadioButtons
                      disabled={isDisabled}
                      name="options"
                      options={choices.options.map(options => {
                        return {
                          id: options.id,
                          label: options.description,
                          is_selected: options.is_selected,
                        }
                      })}
                    />
                  </Scope>
                </>
              )
            })}
            <ContainerInput>
              {item.texts.map((text, textIndex) => {
                return (
                  <>
                    <Scope path={`texts[${textIndex}]`}>
                      <InvisibleInput
                        name="id"
                        value={text.id}
                        disabled={isDisabled}
                      />
                      <Input
                        name="value"
                        multiline
                        placeholder={text.description}
                        disabled={isDisabled}
                        style={{ minHeight: '0px', height: '54px' }}
                      />
                    </Scope>
                  </>
                )
              })}
            </ContainerInput>
          </StepDetailTitle>
        </Scope>
      </div>
    )
  })

  const handleSubmit = useCallback(async (formReportThree: IFormData) => {
    try {
      const clientReportThree = {
        status: ReportStepStatusEnum.APPROVED,
        steps: formReportThree.steps,
      }
      dispatch(ReportCreators.editReportThreeRequest(clientReportThree))
    } catch (err) {
      toast.error('Não foi possível editar o relatório.')
    }
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    formRef.current?.setData({ steps: reportThree?.steps })
  }, [reportThree])

  return (
    <>
      {isLoading && <FullScreenLoader />}
      <Container>
        <Header>
          <h1>Relatório - Etapa 3</h1>
        </Header>
        <Form onSubmit={handleSubmit} ref={formRef}>
          {steps}
          {isDisabled ? (
            <ContainerButton>
              <Button
                full
                size="default"
                color="primary"
                type="submit"
                onClick={() => dispatch(ReportCreators.setIsReportThree(false))}
              >
                Voltar
              </Button>
            </ContainerButton>
          ) : (
            <ContainerButton>
              <Button
                full
                size="default"
                color="cancel"
                type="button"
                onClick={() => dispatch(ReportCreators.setIsReportThree(false))}
              >
                Cancelar
              </Button>
              <Button full size="default" color="primary" type="submit">
                Concluir Etapa 3
              </Button>
            </ContainerButton>
          )}
        </Form>
      </Container>
    </>
  )
}

export { ReportThree }
