import styled from 'styled-components'

import theme from '@/assets/styles/theme'

export interface InputProps {
  width?: string
  marginRight?: string
}

export const Container = styled.div``
export const ContainerClient = styled.div`
  width: 100%;
  padding: 30px;
  margin-bottom: 20px;
  background-color: ${theme.colors.gray[100]};
  display: grid;
  grid-template-columns: 1fr auto;
  border-radius: 0.5rem;
  overflow: hidden;

  @media only screen and (max-width: 850px) {
    grid-template-columns: 1fr;
    row-gap: 1rem;
  }

  @media only screen and (max-width: 375px) {
    padding: 1rem 0.5rem;
  }
`

export const ContainerGeneral = styled.div`
  width: 100%;
  padding: 30px;
  margin-bottom: 20px;
  background-color: ${theme.colors.gray[100]};
  display: grid;
  grid-template-columns: 1fr 1fr;
  border-radius: 0.5rem;
  overflow: hidden;

  @media only screen and (max-width: 850px) {
    grid-template-columns: 1fr;
    row-gap: 1rem;
  }

  @media only screen and (max-width: 375px) {
    padding: 1rem 0.5rem;
  }
`

export const ClientDataBox = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;

  @media only screen and (max-width: 850px) {
    grid-template-columns: 1fr;
    row-gap: 1rem;
  }
`

export const ClientCodeBox = styled.div`
  display: flex;
  flex-direction: column;
  justifycontent: center;
  alignitems: center;
  gap: 1rem;
  max-width: 100%;
`

export const ContainerColumn = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: flex-start;
  gap: 1rem;

  @media only screen and (max-width: 850px) {
    height: auto;
    width: auto;
  }
`
export const FieldsLabel = styled.div``
export const Fields = styled.span`
  font-size: 16px;
  font-weight: 400;
  font-family: Roboto;
`

export const Font = styled.b`
  font-weight: 600;
  font-size: 1rem;
  font-family: Roboto;
  color: ${theme.colors.gray[700]};
  display: block;
`
export const LabelContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: baseline;

  > label {
    font-size: 26px;
    font-family: bold, Roboto;
    font-weight: 500;
    padding: 0 0 1rem;
    margin-right: 25px;
  }

  @media only screen and (max-width: 850px) {
    > label {
      font-size: 20px;
    }
  }

  @media only screen and (max-width: 375px) {
    flex-direction: column;
  }
`
export const ContainerButton = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  padding: 25px 0px;
`
