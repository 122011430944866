/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import moment from 'moment'

import { IPermission } from '@/@types/Permission'
import penIcon from '@/assets/images/icons/ic-pen.svg'
import { FullScreenLoader } from '@/components/FullScreenLoader'
import PageContent from '@/components/PageContent'
import { ITableProps, Table } from '@/components/Table'
import { Calendar } from '@/components/Ui/Calendar'
import { IItem, MultSelect } from '@/components/Ui/MultSelect'
import { Pagination } from '@/components/Ui/Pagination'
import { SearchInput } from '@/components/Ui/SearchInput'
import StatusCard from '@/components/Ui/Status/StatusCard'
import { RootState } from '@/store'
import { CitiesCreators } from '@/store/ducks/Cities'
import { Creators } from '@/store/ducks/Clients'
import {
  ClientStepTypeEnum,
  IActivityLog,
  IClientStep,
} from '@/store/ducks/Clients/types'
import { StateCreators } from '@/store/ducks/State'
import { insertPhoneMask } from '@/utils/insertNumberMask/insertPhoneMask'
import { UnfoldMore } from '@material-ui/icons'

import { getClientStepStyle } from '../../utils/getClientStepStyle'
import { getCurrentClientStepType } from '../../utils/getCurrentClientStepType'
import * as S from './styles'

interface IClientData {
  id: string
  name: string
  email: string
  type?: string
  city: string
  state: string
  steps?: IClientStep[]
  created_at?: string
  logs?: IActivityLog
  bill_value?: number
  bill_id?: string
  bill_status?: string
  technical_status?: string
  last_login?: string
}
interface ISituation {
  text: string
  backgroundColor: string
  color: string
}

interface ISelectedDatesCalendar {
  dateInitial: string
  dateFinal: string
}

const Installation: React.FC = () => {
  const [isOpenState, setIsOpenState] = useState<boolean>(false)
  const [isOpenCity, setIsOpenCity] = useState<boolean>(false)
  const [isDisable, setIsDisable] = useState<boolean>(true)

  const [page, setPage] = useState<number>(1)
  const [clientType] = useState<string>('CPF')
  const [search, setSearch] = useState<string>('')
  const [userType, setUserType] = useState<string>('')
  const [stateInitials, setStateInitials] = useState<string>('')
  const [cityIds, setCityIds] = useState<string[]>([])
  const [isOpenHiddenColumn, setIsOpenHiddenColumn] = useState<boolean>(false)
  const [currentSteps] = useState<ClientStepTypeEnum[]>([])
  const [hideKeys, setHideKeys] = useState<string[]>([])
  const [sortData, setSortData] = useState<string>('')
  const [sortDirection, setSortDirection] = useState<'ASC' | 'DESC'>('ASC')

  const availableType =
    clientType === 'CPF'
      ? { is_residential_available: true }
      : { is_commercial_available: true }

  const dispatch = useDispatch()
  const clientList = useSelector(
    (state: RootState) => state.Client.clients.items,
  )
  const pagination = useSelector(
    (state: RootState) => state.Client.clients.meta,
  )
  const stateList = useSelector((state: RootState) => state.State.states)
  const citiesList = useSelector(
    (state: RootState) => state.Cities.cities.items,
  )
  const isLoading = useSelector(
    (state: RootState) => state.Client.isClientLoading,
  )

  const dtFormat = 'DD/MM/YYYY HH[h]mm'

  const getTitle = useCallback(
    (text: string, sort: string) => {
      const handleSort = () => {
        if (sortData === sort)
          setSortDirection(direction => (direction === 'ASC' ? 'DESC' : 'ASC'))
        else {
          setSortDirection('ASC')
          setSortData(sort)
        }
      }

      return (
        <S.SortColumn onClick={handleSort}>
          {text} <UnfoldMore />
        </S.SortColumn>
      )
    },
    [sortData],
  )

  const columns: ITableProps<IClientData>['columns'] = [
    {
      title: getTitle('Nome', 'name'),
      dataIndex: 'name',
      key: 'name',
      width: 120,
    },
    {
      title: getTitle('Email', 'email'),
      dataIndex: 'email',
      align: 'center',
      key: 'email',
      width: 10,
    },
    {
      title: getTitle('Telefone', 'phone'),
      dataIndex: 'phone',
      align: 'center',
      key: 'phone',
      width: 120,
    },
    {
      title: getTitle('Valor da Conta', 'bill_value'),
      dataIndex: 'bill_value',
      align: 'center',
      key: 'bill_value',
      width: 10,
      render: (...args) =>
        args[0]
          ? args[0].toLocaleString('pt-BR', {
              style: 'currency',
              currency: 'BRL',
            })
          : '',
    },
    {
      title: getTitle('Cidade/UF', 'city'),
      dataIndex: 'city',
      key: 'city',
      align: 'center',
      width: 50,
      render: (...args) => `${args[1].city}-${args[1].state}`,
    },
    {
      title: getTitle('Último Acesso', 'last_login'),
      dataIndex: 'last_login',
      key: 'last_login',
      width: 150,
      align: 'center',
      render: (...args) => {
        const { last_login, created_at } = args[1]
        const dataToUse = last_login || created_at
        const rawDate = new Date(dataToUse || '')

        return rawDate ? moment(rawDate).format(dtFormat) : '-'
      },
    },
    {
      title: getTitle('Data de Cadastro', 'created_at'),
      dataIndex: 'created_at',
      key: 'signup_date',
      width: 150,
      align: 'center',
      render: (...args) => (args[0] ? moment(args[0]).format(dtFormat) : ''),
    },
    {
      title: getTitle('Whirlpool', 'hasPartnership'),
      dataIndex: 'hasPartnership',
      key: 'hasPartnership',
      width: 60,
      align: 'center',
      render: (...args) => `${args[0] ? 'Sim' : 'Não'}`,
    },
    {
      title: getTitle('Tipo', 'subscription_lending'),
      dataIndex: 'subscription_lending',
      key: 'subscription_lending',
      width: 60,
      align: 'center',
      render: (...args) =>
        `${args[0] === 'SUBSCRIPTION' ? 'Assinatura' : 'Venda'}`,
    },
    {
      title: 'Situação',
      dataIndex: 'situation',
      align: 'center',
      key: 'situation',
      width: 150,
      render: (value, client) => {
        const currentStep = getCurrentClientStepType(client.steps)
        let a: string | undefined
        let b: string | undefined
        if (currentStep?.toString() === 'BILL') {
          a = client.bill_id ? 'PENDING' : 'NOT_DONE'
          if (client.bill_status === 'DENIED') a = 'DENIED'
        } else if (currentStep?.toString() === 'TECHNICAL_VISIT')
          b = client.technical_status
        return (
          <div>
            {currentStep && (
              <StatusCard
                centered
                style={getClientStepStyle(currentStep, a, b)}
              />
            )}
          </div>
        )
      },
    },
    {
      title: '',
      dataIndex: 'edit',
      key: 'edit',
      width: 50,
      render: (value, client) => {
        return (
          <S.EditIcon
            src={penIcon}
            onClick={() =>
              window.open(`/customer/${client.id}/technicalVisit`, '_blank')
            }
          />
        )
      },
    },
  ]

  const getOptions = () => {
    const results: { id: string; label: any }[] = []
    columns.forEach((column: any) => {
      const { key, title } = column
      if (title && key)
        results.push({ id: key, label: title?.props?.children[0] ?? title })
    })
    return results
  }

  useEffect(() => setPage(1), [
    stateInitials,
    clientType,
    cityIds,
    currentSteps,
  ])

  const [date, setDate] = useState<string[]>([])

  useEffect(() => {
    const query = {
      page,
      search,
      state: [stateInitials],
      clientType,
      cityIds,
      date,
      order: sortData,
      direction: sortDirection,
      currentSteps: [ClientStepTypeEnum.INSTALATION],
    }
    dispatch(StateCreators.getStatesRequest())
    dispatch(Creators.getClientsRequest(query))
  }, [
    dispatch,
    page,
    search,
    stateInitials,
    clientType,
    cityIds,
    date,
    sortData,
    sortDirection,
  ])

  useEffect(() => {
    const permission = localStorage.getItem('permission')
    if (permission !== null) setUserType(permission)
  }, [])

  useEffect(() => {
    const query = {
      states: [stateInitials],
      ...availableType,
    }
    dispatch(CitiesCreators.getCitiesRequest(query))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stateInitials])

  const clients = clientList?.map(client => {
    return {
      id: client.id,
      name: client.name,
      email: client.email,
      type: client.type.value,
      phone: insertPhoneMask(client.phone.replace('55', '')),
      city: client.city.name,
      state: client.city.state,
      steps: client.steps,
      key: client.id,
      created_at: client.created_at,
      logs: client.logs,
      last_login: client.last_login,
      bill_value: client.bill_value,
      bill_id: client.bill_id,
      bill_status: client.bill?.status || null,
      technical_status: client.technical_visit?.status,
      hasPartnership: client.hasPartnership || null,
      subscription_lending: client.subscription_lending || null,
    }
  })

  const states = stateList?.map(state => {
    return {
      id: state.id,
      label: state.initials,
    }
  })

  const cities = citiesList?.map(city => {
    return {
      id: city.id,
      label: city.name,
    }
  })

  const handlePageChange = (value: number) => {
    setPage(value)
  }

  const handleChange = (value: string) => {
    setPage(1)
    setSearch(value)
  }

  const handleChangeStateFilter = (selectedStates: IItem[]) => {
    const selectedState = selectedStates[0]?.label as string
    setStateInitials(selectedState)
    if (selectedState !== undefined) setIsDisable(false)
  }

  const handleChangeCityFilter = (selectedCities: IItem[]) => {
    const selectedCityIds = selectedCities.map(city => city.id)
    setCityIds(selectedCityIds)
  }

  const handleChangeDates = (selectedDates: ISelectedDatesCalendar) => {
    setDate([selectedDates.dateInitial, selectedDates.dateFinal])
  }

  const handleChangeHiddenFilter = (selectedSituatios: IItem[]) => {
    const results: string[] = []
    selectedSituatios.forEach((column: any) => {
      const { id } = column
      if (id) results.push(id)
    })
    setHideKeys(results)
  }

  return (
    <PageContent
      title="Clientes"
      add_customer={userType === IPermission.FRANCHISEE}
    >
      <S.Container>
        <S.Header>
          <S.SearchContainer>
            <SearchInput onChange={handleChange} />
          </S.SearchContainer>
          <S.FilterContainer>
            <MultSelect
              isOpen={isOpenState}
              onClose={() => setIsOpenState(false)}
              onClick={() => {
                setIsOpenState(!isOpenState)
              }}
              labelButton="Estados"
              items={states}
              onChange={handleChangeStateFilter}
              isMultiSelect={false}
            />
            <MultSelect
              disabled={isDisable}
              isOpen={isOpenCity}
              onClose={() => setIsOpenCity(false)}
              onClick={() => {
                setIsOpenCity(!isOpenCity)
              }}
              labelButton="Cidade"
              items={cities}
              onChange={handleChangeCityFilter}
              isMultiSelect
            />
          </S.FilterContainer>
          <Calendar onChange={handleChangeDates} />
          <S.HideContainer>
            <MultSelect
              items={getOptions()}
              isOpen={isOpenHiddenColumn}
              onClose={() => setIsOpenHiddenColumn(false)}
              onClick={() => {
                setIsOpenHiddenColumn(!isOpenHiddenColumn)
              }}
              labelButton="Ocultar Colunas"
              onChange={handleChangeHiddenFilter}
              isMultiSelect
            />
          </S.HideContainer>
        </S.Header>
        <S.TableContainer>
          {isLoading ? (
            <FullScreenLoader />
          ) : (
            <Table<IClientData>
              columns={columns}
              hideKeys={hideKeys}
              data={clients}
            />
          )}
        </S.TableContainer>

        <S.PaginationContainer>
          <Pagination
            count={pagination.total_pages}
            size="large"
            shape="rounded"
            variant="outlined"
            page={page}
            onChange={handlePageChange}
          />
        </S.PaginationContainer>
      </S.Container>
    </PageContent>
  )
}

export { Installation }
