import styled from 'styled-components'

import { Form as FormComponent } from '@unform/web'

export interface InputProps {
  width?: string | undefined
  marginRight?: string | undefined
  marginLeft?: string | undefined
}

export const Container = styled.div`
  padding: 0px 33px;
  height: 81vh;
  @media only screen and (max-width: 768px) {
    padding: 0px 20px;
    overflow-y: auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    height: 565px;
  }
`
export const ContainerForm = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  padding: 35px 0px 0 0px;
  height: auto;
  @media only screen and (max-width: 768px) {
    height: calc(100% - 5vh);
  }
`
export const FormLineInputCities = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media only screen and (max-width: 768px) {
    flex-direction: column;
  }
`
export const FormLineInput = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;

  @media only screen and (max-width: 768px) {
    flex-direction: column;
  }
`
export const ContainerButton = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  padding: 25px 0px;
  @media only screen and (max-width: 768px) {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
`
export const SelectContainer = styled.div`
  width: 760px;
  margin-bottom: 23px;
  @media only screen and (max-width: 768px) {
    width: 100%;
  }
`
export const ContainerInput = styled.div<InputProps>`
  width: ${props => props.width};
  margin-right: ${props => props.marginRight};
  margin-left: ${props => props.marginLeft || '0px'};

  @media only screen and (max-width: 768px) {
    width: 100%;
    margin-right: 0px;
  }
`
export const Form = styled(FormComponent)`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

export const RepresentativeContainer = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: center;
  gap: 1rem;
`

export const RepresentativeDescription = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

export const RepresentativeTitle = styled.span`
  @media only screen and (max-width: 768px) {
    font-size: 0.8rem;
  }
`
