import React from 'react'
import { BsPencil } from 'react-icons/bs'

import { ContainerButton, Button } from './styles'

interface IPropsButtonEdit {
  onClick: () => void
  disabled?: boolean
}

const ButtonEdit: React.FC<IPropsButtonEdit> = ({
  onClick,
  disabled = false,
}) => {
  return (
    <ContainerButton onClick={disabled ? () => null : onClick}>
      <Button disabled={disabled}>
        Editar <BsPencil size={19} />
      </Button>
    </ContainerButton>
  )
}

export { ButtonEdit }
