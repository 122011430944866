import { Action } from 'redux'

export enum DatabookTypeEnum {
  GET_DATABOOK_REQUEST = 'GET_DATABOOK_REQUEST',
  GET_DATABOOK_SUCCESS = 'GET_DATABOOK_SUCCESS',
  GET_DATABOOK_FAILURE = 'GET_DATABOOK_FAILURE',

  EDIT_DATABOOK_REQUEST = 'EDIT_DATABOOK_REQUEST',
  EDIT_DATABOOK_SUCCESS = 'EDIT_DATABOOK_SUCCESS',
  EDIT_DATABOOK_FAILURE = 'EDIT_DATABOOK_FAILURE',
}

export interface IDatabookData {
  id: string
  description: string
  step: number
  files: IDatabookFileData[]
}

export interface IDatabookFileData {
  id: string
  name?: string
  url: string
}

export interface IDatabookInitialState {
  databook: IDatabookData[]
  isLoading: boolean
}

export interface IUpdateDatabookData {
  id: string
  files?: IUpdateDatabookFileData[]
}

export interface IUpdateDatabookFileData {
  id?: string
  name?: string
  url: string
}

export interface IActionTypes extends Action<DatabookTypeEnum> {
  getDatabookRequest: () => Action
  getDatabookSuccess: (databook: IDatabookData[]) => Action
  getDatabookFailure: () => Action

  editDatabookRequest: (databook: IUpdateDatabookData[]) => Action
  editDatabookSuccess: () => Action
  editDatabookFailure: () => Action
}
