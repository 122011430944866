import React from 'react'

import theme from '@/assets/styles/theme'

import {
  Container,
  Up,
  ContainerIcon,
  Icon,
  TextsStepsContainer,
} from './styles'

interface IPropsSteps {
  type: 'concluded' | 'pending'
  titleSteps: string
  descriptionSteps: string
  onClick?: () => void
}

const Steps: React.FC<IPropsSteps> = ({
  titleSteps,
  descriptionSteps,
  type,
  onClick,
}) => {
  return (
    <Container onClick={onClick}>
      <Up type={type}>
        <span>{type !== 'pending' ? 'CONCLUÍDO' : 'PENDENTE'}</span>
      </Up>
      <ContainerIcon type={type}>
        <Icon
          color={
            type !== 'pending'
              ? theme.colors.green[300]
              : theme.colors.gray[400]
          }
        />
      </ContainerIcon>
      <TextsStepsContainer>
        <span>{titleSteps}</span>
        <span>{descriptionSteps}</span>
      </TextsStepsContainer>
    </Container>
  )
}

export default Steps
