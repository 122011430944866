import React from 'react'

import { Container, Label } from './styles'

interface StatusCardStyle {
  style: Colors
  width?: string
  centered?: boolean
}

interface Colors {
  backgroundColor: string
  color: string
  text: string
}

const StatusCard: React.FC<StatusCardStyle> = ({
  style = { backgroundColor: '', color: '', text: '' },
  width,
  centered = false,
}) => {
  return (
    <Container
      style={{ margin: centered ? '0 auto' : 0 }}
      backgroundColor={style.backgroundColor}
      width={width || '150px'}
    >
      <Label color={style.color}>{style.text}</Label>
    </Container>
  )
}

export default StatusCard
