import React from 'react'

import styled from 'styled-components'

import signInBackground from '@/assets/images/sign-in-bg.png'
import theme from '@/assets/styles/theme'

// Styles
export const Container = styled.div`
  height: 100vh;
  display: flex;
  align-items: stretch;
  background: ${theme.colors.gray[200]};
`

export const Content = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  max-width: 50%;

  @media screen and (max-width: ${theme.breakpoints.md}) {
    max-width: 100%;
    padding-left: 20px;
    padding-right: 20px;
  }
`

export const Background = styled.div`
  flex: 1;
  background: linear-gradient(rgba(0, 0, 0, 0.47), rgba(0, 0, 0, 0.47)),
    url(${signInBackground}) no-repeat center;
  background-size: cover;

  @media screen and (max-width: ${theme.breakpoints.md}) {
    display: none;
  }
`

const AuthLayout: React.FC = ({ children }) => {
  return (
    <Container>
      <Content>{children}</Content>

      <Background />
    </Container>
  )
}

export default AuthLayout
