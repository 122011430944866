import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { toast, ToastContent } from 'react-toastify'

import * as Yup from 'yup'

import Button from '@/components/Ui/Button'
import { InputNumberMask } from '@/components/Ui/InputNumberMask'
import { RootState } from '@/store'
import { Creators } from '@/store/ducks/Clients'
import { IClientEnergyConsumption } from '@/store/ducks/Clients/types'
import { Form } from '@unform/web'

import {
  Container,
  ContainerForm,
  FormLineInput,
  ContainerInput,
  ContainerButton,
} from './styles'

interface IClientEditProps {
  onClickToCancel: () => void
}

const EditClientEnergyConsumption: React.FC<IClientEditProps> = ({
  onClickToCancel,
}) => {
  const dispatch = useDispatch()

  const client = useSelector((state: RootState) => state.Client.client)

  const clientProjectInfo = useSelector(
    (state: RootState) => state.Client.clientProjectInfo,
  )

  const [kwhAvailability, setKwhAvailability] = useState<number>(0)

  const [concurrencyFactor, setConcurrencyFactor] = useState<number>(0)

  const handleSubmit = useCallback(async () => {
    try {
      const clientEnergyConsumption: IClientEnergyConsumption = {
        kwh_availability: kwhAvailability,
        concurrency_factor: concurrencyFactor / 100,
        id: client.id,
      }

      const userSchema = Yup.object().shape({
        kwh_availability: Yup.number().required('Disponibilidade obrigatória'),
        concurrency_factor: Yup.number().required(
          'Fator de Simultaneidade obrigatório',
        ),
      })

      await userSchema.validate(clientEnergyConsumption, {
        abortEarly: false,
      })

      dispatch(
        Creators.editClientEnergyConsumptionRequest(clientEnergyConsumption),
      )
    } catch (err) {
      err.errors.forEach((error: ToastContent) => {
        toast.error(error)
      })
    }
  }, [client.id, concurrencyFactor, dispatch, kwhAvailability])

  useEffect(() => {
    dispatch(Creators.getClientProjectInfoRequest({ id: client.id }))
  }, [client.id, dispatch])

  return (
    <Container>
      <Form onSubmit={handleSubmit}>
        <ContainerForm>
          <FormLineInput>
            <ContainerInput width="775px" marginRight="35px">
              <InputNumberMask
                decimalLimit={2}
                decimalSymbol=","
                suffix=" kWh"
                initialValue={clientProjectInfo?.kwh_availability || 0}
                label="Disponibilidade (kWh)"
                onChange={(value: number) => {
                  setKwhAvailability(value)
                }}
              />
            </ContainerInput>
            <ContainerInput width="775px" marginRight="0px">
              <InputNumberMask
                decimalLimit={2}
                suffix=" %"
                decimalSymbol=","
                initialValue={
                  (clientProjectInfo?.concurrency_factor || 0) * 100
                }
                label="Fator de Simultaneidade (%)"
                onChange={(value: number) => setConcurrencyFactor(value)}
              />
            </ContainerInput>
          </FormLineInput>
        </ContainerForm>
        <ContainerButton>
          <Button type="button" size="default" onClick={onClickToCancel}>
            Voltar
          </Button>
          <Button size="default" color="primary" type="submit">
            Salvar alterações
          </Button>
        </ContainerButton>
      </Form>
    </Container>
  )
}

export { EditClientEnergyConsumption }
