import React, { useCallback } from 'react'

import Modal from '@/components/Ui/Modal'

import { Title, Container, Buttons, ButtonYes, ButtonNo } from './styles'

interface PropsModal {
  openModal: boolean
  onClose: () => void
  onClickConfirm: () => void
  label: string
  labelActions?: {
    confirm: string
    close: string
  }
  children?: React.ReactNode
}

const GenericModal: React.FC<PropsModal> = ({
  onClose,
  openModal,
  onClickConfirm,
  label,
  labelActions,
  children,
}) => {
  const handleCloseModal = useCallback(() => {
    onClose()
  }, [onClose])

  const handleClickConfirm = useCallback(() => {
    onClickConfirm()
  }, [onClickConfirm])

  return (
    <Modal onClose={handleCloseModal} openModal={openModal}>
      <Container>
        <Title>
          <span>{label}</span>
        </Title>
        {!!children && children}
        <Buttons>
          <ButtonNo onClick={handleCloseModal}>
            {labelActions?.close || 'Não'}
          </ButtonNo>
          <ButtonYes onClick={handleClickConfirm}>
            {labelActions?.confirm || 'Sim'}
          </ButtonYes>
        </Buttons>
      </Container>
    </Modal>
  )
}

export { GenericModal }
