import React from 'react'

import logoPlan from '@/assets/images/icons/logo-plans.svg'

import {
  ContainerIcon,
  PlanStructure,
  PlanName,
  PlanIcon,
  ContainerPlan,
} from './styles'

interface IPlan {
  planName: 'LOW' | 'MED' | 'MAX' | 'NOT_FIT'
  planPrice?: string
  width?: string
  isNotFit?: boolean
}

const Plan: React.FC<IPlan> = ({ planName, planPrice, width, isNotFit }) => {
  const planNames: { [key: string]: string } = {
    LOW: 'Plano Low',
    MED: 'Plano Med',
    MAX: 'Plano Max',
  }

  return (
    <div>
      <PlanStructure>
        <ContainerIcon width={width || '530px'}>
          <ContainerPlan>
            <PlanIcon src={logoPlan} />
            <PlanName>{planNames[planName]}</PlanName>
          </ContainerPlan>
          <div>
            {isNotFit ? (
              <span>
                <b>Não Apto !!!</b>
              </span>
            ) : (
              <span>
                R$ <b>{planPrice} </b> / mês
              </span>
            )}
          </div>
        </ContainerIcon>
      </PlanStructure>
    </div>
  )
}

export { Plan }
