import React, { useEffect, useState } from 'react'

import BigModal from '@/components/Ui/BigModal'
import {
  FormControl,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from '@material-ui/core'

import {
  Container,
  Title,
  ButtonNo,
  ButtonYes,
  Buttons,
  ContainerButton,
  ContainerTitle,
  TitleModal,
} from './styles'

interface PropsModal {
  openModal: boolean
  onClose: () => void
  onClickConfirm: () => void
  onChange: (event: string) => void
  label: string
  // eslint-disable-next-line
  value?: string
}

const disapproval_json = [
  {
    category_label: 'Telhado',
    category_enum: 'ROOF',
    options: [
      { option_label: 'Cobertura de laje sem telhas;', selected: false },
      {
        option_label:
          'Terça do telhado imprópria para receber as estruturas dos módulos (necessidade de reforço estrutural);',
        selected: false,
      },
      {
        option_label: 'Sem espaço para recebimento do kit mínimo;',
        selected: false,
      },
      {
        option_label: 'Tipo de telhado não atendido;',
        selected: false,
      },
      {
        option_label:
          'Sombreamento excessivo - irradiação inferior a 950 kWh/kWp;',
        selected: false,
      },
    ],
  },
  {
    category_label: 'Elétrica',
    category_enum: 'ELECTRICAL',
    options: [
      {
        option_label:
          'Instalações elétricas fora dos padrões mínimos de segurança;',
        selected: false,
      },
      {
        option_label:
          'Padrão de entrada fora das normas antigas e fora dos padrões aceitos pela concessionária local;',
        selected: false,
      },
    ],
  },
  {
    category_label: 'Plano Inapto',
    category_enum: 'UNFIT_PLAN',
    options: [
      {
        option_label: 'Telhado irregular com baixa performance;',
        selected: false,
      },
      {
        option_label: 'Baixa tarifa da concessionária, planos inaptos;',
        selected: false,
      },
    ],
  },
  {
    category_label: 'Outros',
    category_enum: 'OTHERS',
    options: [
      {
        option_label:
          'Localização que apresente risco para a segurança dos equipamentos;',
        selected: false,
      },
      {
        option_label: 'Titular da conta não é o proprietário da residência;',
        selected: false,
      },
      {
        option_label: 'Cliente não reside no local;',
        selected: false,
      },
      {
        option_label: 'Endereço da conta divergente da visita;',
        selected: false,
      },
      {
        option_label: 'Não passou na análise de crédito;',
        selected: false,
      },
    ],
  },
]

const ModalJustifyTechnical: React.FC<PropsModal> = ({
  onClose,
  openModal,
  onClickConfirm,
  onChange,
  label,
}) => {
  const [string, setString] = useState('')
  const [json, setJson] = useState([])

  useEffect(() => {
    const myJSON = JSON.stringify(disapproval_json)
    setString(myJSON)
  }, [])

  const handleChange = (text: string) => {
    onChange(text)
  }

  useEffect(() => {
    if (string !== '') {
      const obj = JSON.parse(string)
      setJson(obj)
    }
  }, [string])

  return (
    <BigModal onClose={onClose} openModal={openModal}>
      <Container>
        <ContainerTitle>
          <TitleModal>
            <span>{label}</span>
          </TitleModal>
        </ContainerTitle>
        {/* eslint-disable */}
        {json.map((i: any, __i: number) => (
          <Container key={i.category_label}>
            <Title>
              <span>{i.category_label}</span>
            </Title>
            <div>
              <FormControl component="fieldset">
                <FormGroup>
                  {i.options.map((o: any, _i: number) => (
                    <FormControlLabel
                      key={o.option_label}
                      control={
                        <Checkbox
                          checked={o.selected}
                          onChange={() => {
                            const obj = JSON.parse(string)

                            obj[__i].options[_i].selected = !obj[__i].options[
                              _i
                            ].selected

                            const myString = JSON.stringify(obj)

                            handleChange(myString)
                            setString(myString)
                          }}
                          style={{ color: '#ffd500' }}
                          value={o.option_label}
                        />
                      }
                      label={o.option_label}
                    />
                  ))}
                </FormGroup>
              </FormControl>
            </div>
          </Container>
        ))}
        {/* eslint-enable */}
        <ContainerButton>
          <Buttons>
            <ButtonNo onClick={onClose}>Cancelar</ButtonNo>
            <ButtonYes onClick={onClickConfirm}>Concluir</ButtonYes>
          </Buttons>
        </ContainerButton>
      </Container>
    </BigModal>
  )
}

export { ModalJustifyTechnical }
