import React, { ChangeEvent } from 'react'

import Modal from '@/components/Ui/Modal'
import { useState } from '@/hooks/useState'
import {
  FormControl,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from '@material-ui/core'

import {
  Container,
  Title,
  ButtonNo,
  ButtonYes,
  Buttons,
  ContainerButton,
} from './styles'

interface IOption {
  label: string
  value: number
}

interface PropsModal {
  openModal: boolean
  options: IOption[]
  onClose: () => void
  onClickConfirm: () => void
  onChange: (event: ChangeEvent<HTMLInputElement>) => void
  label: string
  // eslint-disable-next-line
  value?: string
}

const ModalJustify: React.FC<PropsModal> = ({
  onClose,
  openModal,
  onClickConfirm,
  onChange,
  options,
  label,
  value: defaultValue,
}) => {
  interface IState {
    selectedReason?: string
  }

  const { state, setState } = useState<IState>({
    selectedReason: undefined,
  })

  const handleChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = ev.target
    setState({
      selectedReason: value === state.selectedReason ? undefined : value,
    })
    onChange(ev)
  }

  React.useEffect(() => {
    if (defaultValue) {
      const a = options.find(i => i.label === defaultValue)
      if (a?.label) setState({ selectedReason: a?.label })
    }
    // eslint-disable-next-line
  }, [defaultValue])

  return (
    <Modal onClose={onClose} openModal={openModal}>
      <Container>
        <Title>
          <span>{label}</span>
        </Title>
        <div>
          <FormControl component="fieldset">
            <FormGroup>
              {/* eslint-disable */}
              {options.map((i: IOption) => (
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={state.selectedReason === i.label}
                      onChange={handleChange}
                      style={{ color: '#ffd500' }}
                      disabled={
                        !!state.selectedReason &&
                        state.selectedReason !== i.label
                      }
                      value={i.label}
                    />
                  }
                  label={i.label}
                />
              ))}
              {/* eslint-enable */}
            </FormGroup>
          </FormControl>
        </div>
        {/* <span style={{marginTop: '10px'}}>Se não encontrou a opção desejada, insira uma justificativa personalizada</span>
        <div>
          <TextArea
            onChange={handleOnChange}
            placeholder="Digite sua justificativa"
            value={value}
          />
        </div> */}
        <ContainerButton>
          <Buttons>
            <ButtonNo onClick={onClose}>Cancelar</ButtonNo>
            <ButtonYes onClick={onClickConfirm}>Concluir</ButtonYes>
          </Buttons>
        </ContainerButton>
      </Container>
    </Modal>
  )
}

export { ModalJustify }
