import produce from 'immer'
import { createActions, createReducer } from 'reduxsauce'

import {
  BillEnum,
  IBillActionTypes,
  IBillDetails,
  IBillFile,
  IBillOwner,
  IBillStateType,
  IConsumptionHistory,
  IEditBillDetails,
} from './types'

export const INITIAL_STATE: IBillStateType = {
  statusOcr: 'Pending',
  loading: {
    get: false,
    put: false,
  },

  billOwner: undefined,
  isEditBillOwner: false,
  billDetails: {
    id: '',
    name: '',
    status: '',
    bill_owner: {
      name: '',
      email: '',
      document_value: '',
      state: '',
      city: '',
      address: '',
      address_number: '',
      address_complement: '',
      address_neighborhood: '',
      postal_code: '',
    },
    dealership: '',
    consumption_average: 0,
    amount: 0,
    client_code: '',
    consumer_unit: '',
    type_consumer_class: '',
    expiration_month: '',
    bill_value: '',
    consumption_profile: '',
  },
  billFiles: [
    {
      id: '',
      client_bill_id: '',
      created_at: '',
      name: '',
      url: '',
      justification_disapproved: '',
      status: '',
    },
  ],
  isEditBill: false,
  isEditBillDetails: false,
  billEditDetails: {
    bill_owner: {
      name: '',
      document_value: '',
      postal_code: '',
      address: '',
      address_neighborhood: '',
      address_number: '',
      address_complement: '',
      city: '',
      state: '',
    },
    consumer_unit: '',
    type_consumer_class: '',
    consumption_average: 0,
    amount: 0,
    dealership: '',
    client_code: '',
    expiration_month: '',
    consumption_profile: '',
  },
  months: [],
  consumptionProfile: '',
}

type BillType = typeof BillEnum

export const { Types, Creators: BillCreators } = createActions<
  BillType,
  IBillActionTypes
>({
  getBillDetailsRequest: [],
  getBillDetailsFailure: [],
  getBillDetailsSuccess: ['billDetails'],
  getBillOwnerRequest: { id: '' },
  getBillOwnerSuccess: ['billOwner'],
  getBillOwnerFailure: {},
  editBillOwnerRequest: ['billOwner'],
  editBillOwnerSuccess: {},
  editBillOwnerFailure: {},
  setIsEditBillOwner: ['isEditBillOwner'],
  editBillDetailsRequest: ['bill'],
  editBillDetailsSuccess: {},
  editBillDetailsFailure: {},
  setIsEditBillDetails: ['isEditBillDetails'],
  getConsumptionHistoryRequest: [],
  getConsumptionHistorySuccess: ['months'],
  getConsumptionHistoryFailure: [],
  editConsumptionHistoryRequest: ['months'],
  editConsumptionHistorySuccess: [],
  editConsumptionHistoryFailure: [],
  getBillFilesRequest: [],
  getBillFilesSuccess: ['billFiles'],
  getBillFilesFailure: [],
  editBillFileRequest: ['billFilesUpdate'],
  editBillFileSuccess: [],
  editBillFileFailure: [],
  updateConsumptionProfileRequest: ['consumptionProfile'],
  updateConsumptionProfileSuccess: [],
  updateConsumptionProfileFailure: [],
})

export const getBillDetailsRequest = (state = INITIAL_STATE) => ({
  ...state,
  loading: {
    ...state.loading,
    get: true,
  },
})

export const getBillDetailsFailure = (state = INITIAL_STATE) => ({
  ...state,
  loading: {
    ...state.loading,
    get: false,
  },
})

export const getBillDetailsSuccess = (
  state: IBillStateType = INITIAL_STATE,
  { billDetails }: { billDetails: IBillDetails },
): IBillStateType =>
  produce(state, () => ({
    ...state,
    loading: { ...state.loading, get: false },
    billDetails,
  }))

export const getBillOwnerRequest = (state = INITIAL_STATE) => ({
  ...state,
  loading: {
    ...state.loading,
    get: true,
  },
})

export const getBillOwnerSuccess = (
  state: IBillStateType = INITIAL_STATE,
  { billOwner }: { billOwner: IBillOwner },
): IBillStateType =>
  produce(state, () => ({
    ...state,
    loading: { ...state.loading, get: false },
    billOwner,
  }))

export const getBillOwnerFailure = (state = INITIAL_STATE) => ({
  ...state,
  loading: {
    ...state.loading,
    get: false,
  },
})

export const editBillOwnerRequest = (state = INITIAL_STATE) => ({
  ...state,
  loading: {
    ...state.loading,
    put: true,
  },
})

export const editBillOwnerSuccess = (state = INITIAL_STATE) => ({
  ...state,
  loading: {
    ...state.loading,
    put: false,
  },
  isEditBillOwner: false,
})

export const editBillOwnerFailure = (state = INITIAL_STATE) => ({
  ...state,
  loading: {
    ...state.loading,
    put: false,
  },
})

export const setIsEditBillOwner = (
  state: IBillStateType = INITIAL_STATE,
  { isEditBillOwner }: { isEditBillOwner: boolean },
): IBillStateType =>
  produce(state, () => ({
    ...state,
    loading: { ...state.loading, get: false },
    isEditBillOwner,
  }))

export const editBillDetailsRequest = (
  state: IBillStateType = INITIAL_STATE,
  { bill }: { bill: IEditBillDetails },
): IBillStateType =>
  produce(state, () => ({
    ...state,
    bill,
    loading: { ...state.loading, put: true },
    isEditBill: true,
  }))

export const editBillDetailsSuccess = (state = INITIAL_STATE) => ({
  ...state,
  loading: { ...state.loading, put: false },
  isEditBill: false,
})

export const editBillDetailsFailure = (state = INITIAL_STATE) => ({
  ...state,
  loading: { ...state.loading, put: false },
  isEditBill: false,
})

export const setIsEditBillDetails = (
  state = INITIAL_STATE,
  { isEditBillDetails }: { isEditBillDetails: boolean },
) => ({
  ...state,
  isEditBillDetails,
})
export const getBillFilesRequest = (state = INITIAL_STATE) => ({
  ...state,
  loading: {
    get: true,
  },
})
export const getBillFilesSuccess = (
  state = INITIAL_STATE,
  { billFiles }: { billFiles: IBillFile[] },
) => ({
  ...state,
  loading: {
    get: false,
  },
  billFiles,
})
export const getBillFilesFailure = (state = INITIAL_STATE) => ({
  ...state,
  loading: {
    get: false,
  },
})

export const getConsumptionHistoryRequest = (state = INITIAL_STATE) => ({
  ...state,
  loading: {
    get: true,
  },
})

export const getConsumptionHistorySuccess = (
  state = INITIAL_STATE,
  { months }: { months: IConsumptionHistory[] },
) =>
  produce(state, () => ({
    ...state,
    months,
    loading: {
      get: false,
    },
  }))

export const getConsumptionHistoryFailure = (state = INITIAL_STATE) => ({
  ...state,
  loading: {
    get: false,
  },
})

export const editConsumptionHistoryRequest = (
  state = INITIAL_STATE,
  { months }: { months: IConsumptionHistory[] },
) =>
  produce(state, () => ({
    ...state,
    months,
    loading: {
      put: true,
    },
  }))

export const editConsumptionHistorySuccess = (state = INITIAL_STATE) => ({
  ...state,
  loading: {
    put: false,
  },
})

export const editConsumptionHistoryFailure = (state = INITIAL_STATE) => ({
  ...state,
  loading: {
    put: false,
  },
})

export const editBillFileRequest = (state = INITIAL_STATE) => ({
  ...state,
  loading: {
    put: true,
  },
})

export const editBillFileSuccess = (state = INITIAL_STATE) => ({
  ...state,
  loading: {
    put: false,
  },
})

export const editBillFileFailure = (state = INITIAL_STATE) => ({
  ...state,
  loading: {
    put: false,
  },
})

export const updateConsumptionProfileRequest = (
  state = INITIAL_STATE,
  { consumptionProfile }: { consumptionProfile: string },
) =>
  produce(state, () => ({
    ...state,
    consumptionProfile,
    loading: {
      put: true,
    },
  }))

export const updateConsumptionProfileSuccess = (state = INITIAL_STATE) => ({
  ...state,
  loading: {
    put: false,
  },
})

export const updateConsumptionProfileFailure = (state = INITIAL_STATE) => ({
  ...state,
  loading: {
    put: false,
  },
})

export const reducer = createReducer<IBillStateType, IBillActionTypes>(
  INITIAL_STATE,
  {
    [Types.GET_BILL_DETAILS_REQUEST]: getBillDetailsRequest,
    [Types.GET_BILL_DETAILS_FAILURE]: getBillDetailsFailure,
    [Types.GET_BILL_DETAILS_SUCCESS]: getBillDetailsSuccess,

    [Types.GET_BILL_OWNER_REQUEST]: getBillOwnerRequest,
    [Types.GET_BILL_OWNER_SUCCESS]: getBillOwnerSuccess,
    [Types.GET_BILL_OWNER_FAILURE]: getBillOwnerFailure,

    [Types.EDIT_BILL_OWNER_REQUEST]: editBillOwnerRequest,
    [Types.EDIT_BILL_OWNER_SUCCESS]: editBillOwnerSuccess,
    [Types.EDIT_BILL_OWNER_FAILURE]: editBillOwnerFailure,

    [Types.SET_IS_EDIT_BILL_OWNER]: setIsEditBillOwner,

    [Types.EDIT_BILL_DETAILS_REQUEST]: editBillDetailsRequest,
    [Types.EDIT_BILL_DETAILS_SUCCESS]: editBillDetailsSuccess,
    [Types.EDIT_BILL_DETAILS_FAILURE]: editBillDetailsFailure,

    [Types.SET_IS_EDIT_BILL_DETAILS]: setIsEditBillDetails,

    [Types.GET_CONSUMPTION_HISTORY_REQUEST]: getConsumptionHistoryRequest,
    [Types.GET_CONSUMPTION_HISTORY_SUCCESS]: getConsumptionHistorySuccess,
    [Types.GET_CONSUMPTION_HISTORY_FAILURE]: getConsumptionHistoryFailure,

    [Types.EDIT_CONSUMPTION_HISTORY_REQUEST]: editConsumptionHistoryRequest,
    [Types.EDIT_CONSUMPTION_HISTORY_SUCCESS]: editConsumptionHistorySuccess,
    [Types.EDIT_CONSUMPTION_HISTORY_FAILURE]: editConsumptionHistoryFailure,

    [Types.GET_BILL_FILES_REQUEST]: getBillFilesRequest,
    [Types.GET_BILL_FILES_SUCCESS]: getBillFilesSuccess,
    [Types.GET_BILL_FILES_FAILURE]: getBillFilesFailure,

    [Types.EDIT_BILL_FILE_REQUEST]: editBillFileRequest,
    [Types.EDIT_BILL_FILE_SUCCESS]: editBillFileSuccess,
    [Types.EDIT_BILL_FILE_FAILURE]: editBillFileFailure,

    [Types.UPDATE_CONSUMPTION_PROFILE_REQUEST]: updateConsumptionProfileRequest,
    [Types.UPDATE_CONSUMPTION_PROFILE_SUCCESS]: updateConsumptionProfileSuccess,
    [Types.UPDATE_CONSUMPTION_PROFILE_FAILURE]: updateConsumptionProfileFailure,
  },
)
