import { TechnicalVisitStatusEnum } from '@/@types/visit'

type ITechnicalVisitStatusType = {
  [x in TechnicalVisitStatusEnum]: {
    text: string
    color: string
    backgroundColor: string
  }
}

const statusVisit: ITechnicalVisitStatusType = {
  [TechnicalVisitStatusEnum.UNCONFIRMED_APPOINTMENT]: {
    text: 'Agendamento não confirmado',
    color: '#FF7600',
    backgroundColor: '#FFE2CF',
  },
  [TechnicalVisitStatusEnum.VISIT_CANCELED]: {
    text: 'Visita cancelada',
    color: '#FF041A',
    backgroundColor: '#FFCED3',
  },
  [TechnicalVisitStatusEnum.LINK_TECHNICAL]: {
    text: 'Vincular técnico',
    color: '#FF7600',
    backgroundColor: '#FFE2CF',
  },
  [TechnicalVisitStatusEnum.WAITING_FOR_TECHNICIAN]: {
    text: 'Esperando o técnico',
    color: '#FF7600',
    backgroundColor: '#FFCED3',
  },
  [TechnicalVisitStatusEnum.SCHEDULE_REFUSED]: {
    text: 'Visita cancelada',
    color: '#FF041A',
    backgroundColor: '#FFCED3',
  },
  [TechnicalVisitStatusEnum.FULFILLED]: {
    text: 'Realizada e aprovada',
    color: '#43CC00',
    backgroundColor: '#CFF3BC',
  },
  [TechnicalVisitStatusEnum.CONFIRMED_SCHEDULE]: {
    text: 'Agendamento confirmado',
    color: '#FF7600',
    backgroundColor: '#FFE2CF',
  },
  [TechnicalVisitStatusEnum.DONE_AND_NOT_APPROVED]: {
    text: 'Realizada e não aprovada',
    color: '#FF041A',
    backgroundColor: '#FFCED3',
  },
  [TechnicalVisitStatusEnum.ACCOMPLISHED_AND_APPROVED]: {
    text: 'Realizada e aprovada',
    color: '#43CC00',
    backgroundColor: '#CFF3BC',
  },
}

const statusVisitLending: ITechnicalVisitStatusType = {
  [TechnicalVisitStatusEnum.UNCONFIRMED_APPOINTMENT]: {
    text: 'Agendamento não confirmado',
    color: '#FF7600',
    backgroundColor: '#FFE2CF',
  },
  [TechnicalVisitStatusEnum.VISIT_CANCELED]: {
    text: 'Visita cancelada',
    color: '#FF041A',
    backgroundColor: '#FFCED3',
  },
  [TechnicalVisitStatusEnum.LINK_TECHNICAL]: {
    text: 'Vincular técnico',
    color: '#FF7600',
    backgroundColor: '#FFE2CF',
  },
  [TechnicalVisitStatusEnum.WAITING_FOR_TECHNICIAN]: {
    text: 'Esperando o técnico',
    color: '#FF7600',
    backgroundColor: '#FFCED3',
  },
  [TechnicalVisitStatusEnum.SCHEDULE_REFUSED]: {
    text: 'Visita cancelada',
    color: '#FF041A',
    backgroundColor: '#FFCED3',
  },
  [TechnicalVisitStatusEnum.FULFILLED]: {
    text: 'Contrato gerado',
    color: '#43CC00',
    backgroundColor: '#CFF3BC',
  },
  [TechnicalVisitStatusEnum.CONFIRMED_SCHEDULE]: {
    text: 'Agendamento confirmado',
    color: '#FF7600',
    backgroundColor: '#FFE2CF',
  },
  [TechnicalVisitStatusEnum.DONE_AND_NOT_APPROVED]: {
    text: 'Realizada e não aprovada',
    color: '#FF041A',
    backgroundColor: '#FFCED3',
  },
  [TechnicalVisitStatusEnum.ACCOMPLISHED_AND_APPROVED]: {
    text: 'Contrato gerado',
    color: '#43CC00',
    backgroundColor: '#CFF3BC',
  },
}

export const getTechnicalVisitStatusStyle = (
  technicalVisitStatus: TechnicalVisitStatusEnum,
) => statusVisit[technicalVisitStatus]

export const getTechnicalVisitStatusLendingStyle = (
  technicalVisitStatus: TechnicalVisitStatusEnum,
) => statusVisitLending[technicalVisitStatus]
