/* eslint-disable */
import React from 'react'

import { Tab, Tabs, AppBar, Container } from '@material-ui/core'
import { makeStyles, Theme } from '@material-ui/core/styles'

import SupplyIndex from '../../components/Supply'
import { Supply } from '../../components/Supply/types'
import theme from '@/assets/styles/theme'

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
}))

const SupplyComponent: React.FC = () => {
  const classes = useStyles()

  const [tabValue, setTabValue] = React.useState<Number>(0)
  const [supplyType, setSupplyType] = React.useState<Supply['type']>('INVERTER')

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setSupplyType(newValue === 0 ? 'INVERTER' : 'BOARD')
    setTabValue(newValue)
  }

  return (
    <div className={classes.root}>
      <AppBar position="static">
        <Tabs
          style={{ backgroundColor: theme.colors.yellow[500], color: '#000' }}
          centered
          variant="fullWidth"
          value={tabValue}
          onChange={handleChange}
        >
          <Tab label="Inversores" />
          <Tab label="Placas" />
        </Tabs>
      </AppBar>

      <Container style={{ paddingTop: 15 }}>
        <SupplyIndex type={supplyType} />
      </Container>
    </div>
  )
}

export default SupplyComponent
