import styled from 'styled-components'

import theme from '@/assets/styles/theme'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;

  > div {
    &:last-child {
      margin-top: 50px;
    }
  }
`
export const LabelContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: baseline;

  > label {
    font-size: 26px;
    font-family: bold, Roboto;
    font-weight: 500;
    padding: 0px 0 20px 0px;
    margin-right: 25px;
  }
`
export const ContainerBill = styled.div`
  display: flex;
  margin-top: 10px;
  width: 100%;
  padding: 20px;
  background-color: ${theme.colors.gray[100]};
  justify-content: flex-start;
  padding: 30px;
`
export const TitleBillValue = styled.label`
  font-size: 16px;
  font-family: Bold, Roboto;
  font-weight: 500;
  opacity: 0.7;

  > span {
    font-size: 16px;
    font-family: bold, Roboto;
    font-weight: 400;
  }
`
export const ContainerPlan = styled.div`
  display: flex;
  margin-top: 10px;
  background-color: ${theme.colors.gray[100]};
  justify-content: space-between;
  padding: 30px;
  flex-direction: column;
  height: 190px;
`
