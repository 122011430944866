import produce from 'immer'
import { createReducer, createActions } from 'reduxsauce'

import { UserRoleEnum, UserStatusEnum } from '@/@types/user'

import {
  UserTypeEnum,
  IActionTypes,
  IUserStateType,
  IUsersData,
  IEditUserRequestPayload,
  ICreateUserRequestPayload,
  IUserPayload,
} from './types'

type UserType = typeof UserTypeEnum

// CreateActions
export const { Types, Creators } = createActions<UserType, IActionTypes>({
  editUserRequest: ['editUser'],
  editUserSuccess: [],
  editUserFailure: [],
  getUsersRequest: { page: 1, search: '' },
  getUsersSuccess: ['users'],
  getUsersFailure: [],
  createUserRequest: ['newUser'],
  createUserSuccess: [],
  createUserFailure: [],
  getUserRequest: { id: '' },
  getUserSuccess: ['user'],
  getUserFailure: [],
})

/* ------------- Initial State ------------- */

export const INITIAL_STATE: IUserStateType = {
  users: {
    items: [],
    meta: {
      current_page: 1,
      item_count: 1,
      items_per_page: 1,
      total_items: 1,
      total_pages: 1,
    },
  },
  loading: {
    post: false,
    patch: false,
    get: false,
    put: false,
  },
  user: {
    id: '',
    firstname: '',
    lastname: '',
    username: '',
    fullname: '',
    role: UserRoleEnum.TECHNICIAN,
    email: '',
    avatar: '',
    phone: '',
    password: '',
    status: UserStatusEnum.ACTIVE,
    cpf: '',
  },
  editUser: {
    email: '',
    role: UserRoleEnum.TECHNICIAN,
    status: UserStatusEnum.ACTIVE,
    firstname: '',
    lastname: '',
    password: '',
    confirm_password: '',
    cpf: '',
  },
}

/* ------------- Reducers ------------- */

export const EditUserRequest = (
  state = INITIAL_STATE,
  { editUser }: { editUser: IEditUserRequestPayload },
) =>
  produce(state, () => ({
    ...state,
    editUser,
    loading: { ...state.loading, put: true },
  }))

export const EditUserSuccess = (
  state: IUserStateType = INITIAL_STATE,
): IUserStateType =>
  produce(state, () => ({
    ...state,
    loading: { ...state.loading, put: false },
  }))

export const EditUserFailure = (state = INITIAL_STATE) => ({
  ...state,
  loading: { ...state.loading, put: false },
})

export const GetUsersRequest = (state = INITIAL_STATE): IUserStateType =>
  produce(state, () => ({
    ...state,
    loading: { ...state.loading, get: true },
  }))

export const GetUsersSuccess = (
  state: IUserStateType = INITIAL_STATE,
  { users }: { users: IUsersData },
): IUserStateType =>
  produce(state, () => ({
    ...state,
    users,
    loading: { ...state.loading, get: false },
  }))

export const GetUsersFailure = (
  state: IUserStateType = INITIAL_STATE,
): IUserStateType =>
  produce(state, () => ({
    ...state,
    loading: { ...state.loading, get: false },
  }))

export const CreateUserRequest = (
  state: IUserStateType = INITIAL_STATE,
  { newUser }: { newUser: ICreateUserRequestPayload },
): IUserStateType =>
  produce(state, () => ({
    ...state,
    newUser,
    loading: { ...state.loading, post: true },
  }))

export const CreateUserSuccess = (state = INITIAL_STATE) =>
  produce(state, () => ({
    ...state,
    loading: { ...state.loading, post: false },
  }))

export const CreateUserFailure = (
  state: IUserStateType = INITIAL_STATE,
): IUserStateType =>
  produce(state, () => ({
    ...state,
    loading: { ...state.loading, post: false },
  }))

export const GetUserRequest = (state = INITIAL_STATE) => ({
  ...state,
  loading: { ...state.loading, get: true },
})

export const GetUserSuccess = (
  state = INITIAL_STATE,
  { user }: { user: IUserPayload },
) =>
  produce(state, () => ({
    ...state,
    user,
    loading: { ...state.loading, get: false },
  }))

export const GetUserFailure = (state = INITIAL_STATE) => ({
  ...state,
  loading: { ...state.loading, get: false },
})

/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer<IUserStateType, IActionTypes>(
  INITIAL_STATE,
  {
    [Types.EDIT_USER_REQUEST]: EditUserRequest,
    [Types.EDIT_USER_SUCCESS]: EditUserSuccess,
    [Types.EDIT_USER_FAILURE]: EditUserFailure,

    [Types.GET_USERS_REQUEST]: GetUsersRequest,
    [Types.GET_USERS_SUCCESS]: GetUsersSuccess,
    [Types.GET_USERS_FAILURE]: GetUsersFailure,

    [Types.CREATE_USER_REQUEST]: CreateUserRequest,
    [Types.CREATE_USER_SUCCESS]: CreateUserSuccess,
    [Types.CREATE_USER_FAILURE]: CreateUserFailure,

    [Types.GET_USER_REQUEST]: GetUserRequest,
    [Types.GET_USER_SUCCESS]: GetUserSuccess,
    [Types.GET_USER_FAILURE]: GetUserFailure,
  },
)
