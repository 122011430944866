/* eslint-disable @typescript-eslint/no-extra-semi */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { toast, ToastContent } from 'react-toastify'

import * as Yup from 'yup'

import { IPermission } from '@/@types/Permission'
import Button from '@/components/Ui/Button'
import { InputNumberMask } from '@/components/Ui/InputNumberMask'
import { RootState } from '@/store'
import { Creators } from '@/store/ducks/Clients'
import {
  IClientTariff,
  IClientEnergyConsumption,
} from '@/store/ducks/Clients/types'
import { Form } from '@unform/web'

import {
  Container,
  ContainerForm,
  FormLineInput,
  ContainerInput,
  ContainerButton,
} from './styles'

interface IClientEditProps {
  onClickToCancel: () => void
}

const EditClientTariff: React.FC<IClientEditProps> = ({ onClickToCancel }) => {
  const dispatch = useDispatch()

  const client = useSelector((state: RootState) => state.Client.client)

  const clientProjectInfo = useSelector(
    (state: RootState) => state.Client.clientProjectInfo,
  )

  const permission = useSelector((state: RootState) => state.Auth.role)
  const isFranchisee = permission === IPermission.FRANCHISEE

  const [solarTax, setSolarTax] = useState<number>(0)

  const [dealershipTax, setDealershipTax] = useState<number>(0)

  const [notCompensatedTax, setNotCompensatedTax] = useState<number>(0)

  const [kwhAvailability, setKwhAvailability] = useState<number>(0)

  const [concurrencyFactor, setConcurrencyFactor] = useState<number>(0)

  const handleSubmit = useCallback(async () => {
    try {
      setKwhAvailability(0)
      const clientTariff: IClientTariff = {
        solar21_tax: solarTax,
        dealership_tax: dealershipTax,
        not_compensated_tax: notCompensatedTax,
        id: client.id,
      }

      const userSchema = Yup.object().shape({
        solar21_tax: Yup.number().required('Tarifa solar obrigatória'),
        dealership_tax: Yup.number().required(
          'Tarifa concessionária obrigatória',
        ),
        not_compensated_tax: Yup.number().required(
          'Tarifa não compensada obrigatória',
        ),
      })

      await userSchema.validate(clientTariff, {
        abortEarly: false,
      })

      dispatch(Creators.editClientTariffRequest(clientTariff))

      const clientEnergyConsumption: IClientEnergyConsumption = {
        kwh_availability: kwhAvailability,
        concurrency_factor: concurrencyFactor / 100,
        id: client.id,
      }

      const userSchema2 = Yup.object().shape({
        kwh_availability: Yup.number().required('Disponibilidade obrigatória'),
        concurrency_factor: Yup.number().required(
          'Fator de Simultaneidade obrigatório',
        ),
      })

      await userSchema2.validate(clientEnergyConsumption, {
        abortEarly: false,
      })

      dispatch(
        Creators.editClientEnergyConsumptionRequest(clientEnergyConsumption),
      )
    } catch (err) {
      ;((err as unknown) as any).errors.forEach((error: ToastContent) => {
        toast.error(error)
      })
    }
  }, [
    client.id,
    dealershipTax,
    dispatch,
    notCompensatedTax,
    solarTax,
    concurrencyFactor,
    kwhAvailability,
  ])

  return (
    <Container>
      <Form onSubmit={handleSubmit}>
        <ContainerForm>
          <FormLineInput>
            {client.subscription_lending === 'SUBSCRIPTION' && (
              <ContainerInput width="775px" marginRight="35px">
                <InputNumberMask
                  decimalLimit={2}
                  prefix="R$ "
                  decimalSymbol=","
                  initialValue={clientProjectInfo?.solar21_tax || 0}
                  label="Tarifa Solar21 (R$/kWh)"
                  onChange={(value: number) => {
                    setSolarTax(value)
                  }}
                />
              </ContainerInput>
            )}
            <ContainerInput width="775px" marginRight="35px">
              <InputNumberMask
                decimalLimit={2}
                prefix="R$ "
                decimalSymbol=","
                initialValue={clientProjectInfo?.dealership_tax || 0}
                label="Tarifa concessionária"
                onChange={(value: number) => {
                  setDealershipTax(value)
                }}
              />
            </ContainerInput>
            <ContainerInput width="775px" marginRight="0px">
              <InputNumberMask
                decimalLimit={2}
                disabled={isFranchisee}
                prefix="R$ "
                decimalSymbol=","
                initialValue={clientProjectInfo?.not_compensated_tax || 0.02}
                label="Tarifa não compensada (R$/kWh)"
                onChange={(value: number) => {
                  setNotCompensatedTax(value)
                }}
              />
            </ContainerInput>
          </FormLineInput>
          <FormLineInput>
            <ContainerInput width="775px" marginRight="35px">
              <InputNumberMask
                decimalLimit={2}
                disabled={isFranchisee}
                suffix=" %"
                decimalSymbol=","
                initialValue={
                  (clientProjectInfo?.concurrency_factor || 0.4) * 100
                }
                label="Fator de Simultaneidade (%)"
                onChange={(value: number) => setConcurrencyFactor(value)}
              />
            </ContainerInput>
            <ContainerInput width="775px" marginRight="0px">
              <InputNumberMask
                decimalLimit={2}
                decimalSymbol=","
                suffix=" kWh"
                initialValue={clientProjectInfo?.kwh_availability || 0}
                label="Disponibilidade (kWh)"
                onChange={(value: number) => {
                  setKwhAvailability(value)
                }}
              />
            </ContainerInput>
          </FormLineInput>
        </ContainerForm>
        <ContainerButton>
          <Button type="button" size="default" onClick={onClickToCancel}>
            Voltar
          </Button>
          <Button size="default" color="primary" type="submit">
            Salvar alterações
          </Button>
        </ContainerButton>
      </Form>
    </Container>
  )
}

export { EditClientTariff }
