import produce from 'immer'
import { createReducer, createActions } from 'reduxsauce'

import {
  IPaymentHistoryStateType,
  PaymentHistoryEnum,
  IPaymentHistoryActionTypes,
  IPaymentHistoryData,
} from './types'

export const INITIAL_STATE: IPaymentHistoryStateType = {
  isLoading: false,
  paymentHistory: undefined,
}

type PaymentHistoryType = typeof PaymentHistoryEnum

export const { Types, Creators: PaymentHistoryCreators } = createActions<
  PaymentHistoryType,
  IPaymentHistoryActionTypes
>({
  getPaymentHistoryRequest: { page: '', limit: '', creation_dates: '' },
  getPaymentHistorySuccess: ['paymentHistory'],
  getPaymentHistoryFailure: [],
})

export const getPaymentHistoryRequest = (state = INITIAL_STATE) => ({
  ...state,
  isLoading: true,
})

export const getPaymentHistorySuccess = (
  state = INITIAL_STATE,
  { paymentHistory }: { paymentHistory: IPaymentHistoryData },
) =>
  produce(state, () => ({
    ...state,
    isLoading: false,
    paymentHistory,
  }))

export const getPaymentHistoryFailure = (state = INITIAL_STATE) => ({
  ...state,
  paymentHistory: undefined,
  isLoading: false,
})

export const reducer = createReducer<
  IPaymentHistoryStateType,
  IPaymentHistoryActionTypes
>(INITIAL_STATE, {
  [Types.GET_PAYMENT_HISTORY_REQUEST]: getPaymentHistoryRequest,
  [Types.GET_PAYMENT_HISTORY_SUCCESS]: getPaymentHistorySuccess,
  [Types.GET_PAYMENT_HISTORY_FAILURE]: getPaymentHistoryFailure,
})
