import styled from 'styled-components'

const Styles = {
  Modal: styled.div`
    z-index: 300;
    background: rgba(0, 0, 0, 0.65);
    position: absolute;
    top: 0;
    width: 100%;
    left: 0;
    display: flex;
    height: 100vh;
    justify-content: center;
    align-items: center;
  `,

  ModalContent: styled.div`
    background: #fff;
    transform: scale(1);
    opacity: 1;
    visibility: visible;
    border-radius: 18px;
    box-shadow: 0px 3px 6px grey;
    padding: 30px;
    @media (max-width: 764px) {
      width: 80%;
    }
    @media (min-width: 765px) {
      width: 70%;
    }
  `,

  BtnClose: styled.button`
    position: absolute;
    top: 10px;
    right: 10px;
    background: transparent;
    border: 0;
    color: grey;
    cursor: pointer;
  `,

  ModalTitle: styled.h2``,
}

export default Styles
