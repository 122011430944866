/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react'

import * as S from '../styles'

const FeaturesCard = ({
  header,
  children,
  style,
  icon,
  sheetStyle,
  wrapper,
}: any) => {
  return (
    <S.BigNumberWrapper style={wrapper}>
      {header && (
        <S.UnderlinedText>
          <S.SubTitle>{header}</S.SubTitle>
          {icon && <S.IconHeader src={icon} />}
        </S.UnderlinedText>
      )}
      <S.Sheet style={sheetStyle}>
        <S.ContentColumns style={style}>{children}</S.ContentColumns>
      </S.Sheet>
    </S.BigNumberWrapper>
  )
}

export default FeaturesCard
