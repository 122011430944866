import styled, { css } from 'styled-components'

import theme from '@/assets/styles/theme'

interface Props {
  width?: string
  height?: string
  typeVariant?: 'approved' | 'pending' | 'reproved'
}

const DropdownTypeVariations = {
  approved: css`
    background-color: ${theme.colors.green[100]};
    color: ${theme.colors.green[200]};
  `,

  pending: css`
    background-color: ${theme.colors.orange[100]};
    color: ${theme.colors.orange[200]};
  `,

  reproved: css`
    background-color: ${theme.colors.red[100]};
    color: ${theme.colors.red[200]};
  `,
}

export const Button = styled.button<Props>`
  width: ${props => (props.width ? props.width : '150px')};
  height: ${props => (props.height ? props.height : '37px')};
  border: none;
  border-radius: 10px;
  text-align: right;
  ${props => DropdownTypeVariations[props.typeVariant || 'pending']}
`
export const SelectedOptionContainer = styled.div`
  display: flex;
  width: 100%;
`

export const TextContainer = styled.div`
  display: flex;
  width: 85%;
  height: 100%;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`
export const IconContainer = styled.div`
  display: flex;
  width: 15%;
  height: 100%;
  align-items: center;
  justify-content: center;
`
