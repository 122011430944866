import React, { StrictMode } from 'react'
import { Provider } from 'react-redux'
import { ToastContainer } from 'react-toastify'

import { PersistGate } from 'redux-persist/integration/react'

import GlobalStyle from '@/assets/styles/global'
import 'react-toastify/dist/ReactToastify.css'
import 'bootstrap/dist/css/bootstrap.min.css'

import { ThemeProvider, createMuiTheme } from '@material-ui/core'

import Routes from './routes'
import history from './services/history'
import store, { persistor } from './store'

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#FED600',
    },
  },
})

const App: React.FC = () => {
  return (
    <ThemeProvider theme={theme}>
      <StrictMode>
        <PersistGate persistor={persistor}>
          <ToastContainer />
          <Provider store={store}>
            <Routes history={history} />
            <GlobalStyle />
          </Provider>
        </PersistGate>
      </StrictMode>
    </ThemeProvider>
  )
}

export default App
