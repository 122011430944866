import styled from 'styled-components'

import theme from '@/assets/styles/theme'

export const Container = styled.div`
  width: 100%;
  height: 71vh;
  padding: 0px 15px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: ${theme.colors.white};
  border-radius: 5px;
  box-shadow: 0px 0px 17px -8px rgba(0, 0, 0, 0.05);
`

export const Title = styled.h1`
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 10px;
  color: ${theme.colors.gray[600]};

  @media screen and (max-width: ${theme.breakpoints.md}) {
    font-size: 16px;
  }
`

export const ContainerRegister = styled.div`
  width: 100%;
  height: 10vh;
  padding: 0px 15px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin-bottom: 20px;
  background: ${theme.colors.white};
  border-radius: 5px;
  box-shadow: 0px 0px 17px -8px rgba(0, 0, 0, 0.05);
`

export const Input = styled.input`
  border: none;
  background: transparent;
  width: 100%;
  height: 100%;
`

export const InputContainer = styled.div`
  background: ${theme.colors.gray[100]};
  width: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 10px 10px 10px 0px;
  height: 40px;
  padding: 10px 10px 10px 0px;
  border-radius: 5px;
  width: 100%;
`

export const PaginationContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
  padding: 30px 0px;
`
export const Header = styled.div`
  height: 100px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 30px 0px;
  @media only screen and (max-width: 768px) {
    height: 200px;
    flex-direction: column-reverse;
    padding: 30px 48px;
  }
`
export const SearchContainer = styled.div`
  width: 400px;
  @media only screen and (max-width: 768px) {
    width: 305px;
    margin-top: 20px;
  }
`
export const ClientContainer = styled.div`
  width: 400px;
  @media only screen and (max-width: 768px) {
    width: 305px;
    margin-top: 20px;
  }
`
export const Button = styled.button`
  width: 255px;
  height: 40px;
  background-color: ${theme.colors.primary};
  border: 0;
  border-radius: 5px;
  font-size: 16px;
  font-weight: 700;
  cursor: pointer;
  background: #ffd500;
`
export const TableContainer = styled.div`
  height: 465px;
  overflow-y: scroll;
`
export const EditIcon = styled.img`
  width: 20px;
  height: 20px;
  cursor: pointer;
`
export const FilterContainer = styled.div`
  width: 440px;
  display: flex;
  justify-content: center;
  gap: 1rem;
  padding: 0px 16px;
`
export const SelectContainer = styled.div`
  width: 120px;
`
export const HideContainer = styled.div`
  width: 200px;
  @media only screen and (max-width: 768px) {
    width: 305px;
    margin-top: 20px;
  }
`
export const SortColumn = styled.button`
  cursor: pointer;
  border: none;
  background-color: transparent;
  font-weight: 800;
`
