import styled from 'styled-components'

export const Container = styled.div`
  width: 100%;
  height: 71vh;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  ::-webkit-scrollbar {
    display: none;
  }
`
export const NotificationContainer = styled.div`
  margin-top: 20px;
`
export const PaginationContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
  padding: 30px 30px;
`
