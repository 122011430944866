import styled from 'styled-components'

import theme from '@/assets/styles/theme'

export const FileArea = styled.div`
  width: 100%;
  height: 3rem;
  border: 1px solid ${theme.colors.green[300]};
  border-radius: 0.5rem;
  padding: 0.5rem 0;
  display: flex;
  gap: 1rem;
  align-items: center;
  justify-content: flex-end;
  padding-right: 1rem;
`

export const FileName = styled.h5`
  font-family: Roboto;
  font-weight: 500;

  @media only screen and (max-width: 750px) {
    font-size: 0.8rem;
  }
`

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
`

export const HeaderText = styled.h4`
  font-family: Roboto;
  font-weight: 500;
  font-size: 2rem;
  margin-bottom: 10px;
`

export const DropArea = styled.div`
  width: 100%;
  height: 3rem;
  border-radius: 0.5rem;
  border: 1px dashed ${theme.colors.green[200]};
  padding: 0.5rem 0;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-right: 1rem;

  & > input {
    display: none;
  }

  @media only screen and (max-width: 750px) {
    justify-content: center;
    padding: 0.5rem 0;
  }
`

export const TextArea = styled.div`
  height: 100%;
  display: flex;
  justify-content: flex-end;
  padding-right: 1rem;
`

export const InfoText = styled.h5`
  font-family: Roboto;
  font-weight: 500;
  font-size: 1rem;

  @media only screen and (max-width: 750px) {
    font-size: 0.8rem;
  }
`

export const MimeText = styled.h5`
  font-family: Roboto;
  font-weight: 300;
  font-size: 2rem;
`

export const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`

export const RemoveButton = styled.button`
  width: 60px;
  height: 60px;
  border-radius: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: inherit;
  border: none;

  &:hover {
    background-color: #ccc;
  }
`
