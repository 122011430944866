import React, { useCallback, useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { FullScreenLoader } from '@/components/FullScreenLoader'
import Button from '@/components/Ui/Button/index'
import Input from '@/components/Ui/Form/Input'
import { RadioButtons } from '@/components/Ui/RadioButtons'
import { StepDetailTitle } from '@/components/Ui/StepDetailTitle'
import { RootState } from '@/store'
import { ReportCreators as ClientReportCreators } from '@/store/ducks/ClientReport'
import { FormHandles, Scope } from '@unform/core'
import { Form } from '@unform/web'

import {
  Container,
  Header,
  ContainerButton,
  StepContainer,
  InvisibleInput,
} from './styles'

interface ReportTwo {
  clientId: string
  isDisabled?: boolean
}

interface IFormReportTwo {
  steps: []
}

const ReportTwo: React.FC<ReportTwo> = ({ clientId, isDisabled = false }) => {
  const dispatch = useDispatch()

  const formRef = useRef<FormHandles>(null)

  const clientReportTwo = useSelector(
    (state: RootState) => state.ClientReport.clientReportTwo,
  )
  const isLoading = useSelector(
    (state: RootState) => state.ClientReport.isLoading,
  )

  useEffect(() => {
    dispatch(ClientReportCreators.getReportTwoRequest({ clientId }))
  }, [dispatch, clientId])

  const handleSubmit = useCallback(async (formReportTwo: IFormReportTwo) => {
    const editReportStepTwo = {
      status: 'APPROVED',
      ...formReportTwo,
    }
    dispatch(
      ClientReportCreators.editReportStepTwoRequest({
        clientId,
        editReportStepTwo,
      }),
    )
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    formRef.current?.setData({ steps: clientReportTwo?.steps })
  }, [clientReportTwo])

  return (
    <>
      {isLoading && <FullScreenLoader />}
      <Container>
        <Header>
          <h1>Relatório - Etapa 2</h1>
        </Header>
        <Form onSubmit={handleSubmit} ref={formRef}>
          {clientReportTwo?.steps.map((item, stepIndex) => {
            return (
              <StepContainer>
                <Scope path={`steps[${stepIndex}]`}>
                  <StepDetailTitle
                    number={item.step.toString()}
                    title={item.description}
                  >
                    {item.texts?.length
                      ? item.texts.map((text, textIndex) => {
                          return (
                            <Scope path={`texts[${textIndex}]`}>
                              <div>
                                <InvisibleInput
                                  name="id"
                                  value={text.id}
                                  disabled={isDisabled}
                                />
                                <Input
                                  style={{ minHeight: '0px', height: '54px' }}
                                  disabled={isDisabled}
                                  multiline
                                  name="value"
                                  placeholder={text.description}
                                />
                              </div>
                            </Scope>
                          )
                        })
                      : item.choices.map((choice, choiceIndex) => {
                          return (
                            <Scope path={`choices[${choiceIndex}]`}>
                              <InvisibleInput
                                name="id"
                                value={choice.id}
                                disabled={isDisabled}
                              />
                              <div>
                                <RadioButtons
                                  disabled={isDisabled}
                                  name="options"
                                  options={choice.options.map(option => {
                                    return {
                                      id: option.id,
                                      label: option.description,
                                      is_selected: option.is_selected,
                                    }
                                  })}
                                />
                              </div>
                            </Scope>
                          )
                        })}
                  </StepDetailTitle>
                </Scope>
              </StepContainer>
            )
          })}
          {isDisabled ? (
            <ContainerButton>
              <Button
                full
                size="default"
                color="primary"
                type="button"
                onClick={() =>
                  dispatch(
                    ClientReportCreators.setIsEditReportTwoTechnician(false),
                  )
                }
              >
                Voltar
              </Button>
            </ContainerButton>
          ) : (
            <ContainerButton>
              <Button
                full
                size="default"
                color="cancel"
                type="button"
                onClick={() =>
                  dispatch(
                    ClientReportCreators.setIsEditReportTwoTechnician(false),
                  )
                }
              >
                Cancelar
              </Button>

              <Button full type="submit" color="primary" size="default">
                Concluir Etapa 2
              </Button>
            </ContainerButton>
          )}
        </Form>
      </Container>
    </>
  )
}

export { ReportTwo }
