import { toast } from 'react-toastify'

import { put, call } from 'redux-saga/effects'

import { SystemConfigTypeEnum } from '@/@types/system-config'
import api from '@/services/api'
import { SystemConfigCreators } from '@/store/ducks/SystemConfig'
import { ISystemConfigData } from '@/store/ducks/SystemConfig/types'

interface IGetSystemConfigResponse {
  type: SystemConfigTypeEnum
  updated_at: string
  created_at: string
  min_client_bill_value: string
}

export function* getSystemConfigs() {
  try {
    const {
      data: systemConfigs,
    }: { data: IGetSystemConfigResponse[] } = yield call(
      api.get,
      '/admin/system-configs',
    )

    const handledSystemConfigs = systemConfigs.map(systemConfig => ({
      type: systemConfig.type,
      minClientBillValue: parseFloat(systemConfig.min_client_bill_value),
    }))

    yield put(
      SystemConfigCreators.getSystemConfigsSuccess(handledSystemConfigs),
    )
  } catch {
    yield put(SystemConfigCreators.getSystemConfigsFailure())
    toast.error('Não foi possível obter as configurações do sistema.')
  }
}

export function* updateSystemConfig({
  systemConfig,
}: {
  systemConfig: ISystemConfigData
}) {
  try {
    yield call(api.patch, `/admin/system-configs/${systemConfig.type}`, {
      type: systemConfig.type,
      min_client_bill_value: systemConfig.minClientBillValue,
    })

    yield put(SystemConfigCreators.updateSystemConfigSuccess(systemConfig))
    toast.success('Configurações do sistema atualizadas com sucesso.')
  } catch {
    yield put(SystemConfigCreators.updateSystemConfigFailure())
    toast.error('Não foi possível atualizar as configurações do sistema.')
  }
}
