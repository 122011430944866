import React from 'react'
import { toast } from 'react-toastify'

import { EditorState } from 'draft-js'

import Button from '@/components/Ui/Button'
import api from '@/services/api'
import { FormControl, InputLabel, Select, MenuItem } from '@material-ui/core'

import TextEditor from '../TextEditor'
import { editorToHTML, htmlToEditor } from '../TextEditor/utils'
import { Faq as FaqInterface, Category as CatInterface } from './faq.types'
import ManageCategoriesDialog from './manage-categories.dialog'
import NewFaqDialog from './new-faq.dialog'
import ReorderCategoryItems from './reorder-cats.dialog'
import ReorderDialog from './reorder.dialog'
import * as Styles from './styles'

const Faq: React.FC = () => {
  const [editorState, setEditorState] = React.useState(
    EditorState.createEmpty(),
  )
  const [faqs, setFaqs] = React.useState<FaqInterface[]>([])
  const [itemEdit, setItemEdit] = React.useState<number | null>(null)
  const [loading, setLoading] = React.useState<boolean>(false)
  const [showDialogNewFaq, setShowDialogNewFaq] = React.useState<boolean>(false)
  const [showDialogOrder, setShowDialogOrder] = React.useState<boolean>(false)
  const [showCatsOrder, setShowCatsOrder] = React.useState<boolean>(false)
  const [categories, setCategories] = React.useState<CatInterface[]>([])
  const [showManageCategories, setShowManageCategories] = React.useState<
    boolean
  >(false)

  const getFaqs = async () => {
    const { data }: { data: FaqInterface[] } = await api.get('/faq')
    if (data.length) setFaqs(data)
  }

  /* eslint-disable @typescript-eslint/no-unused-vars */
  const deleteFaq = async (id: number): Promise<void> => {
    // eslint-disable-next-line no-alert
    if (window.confirm('Tem certeza?')) {
      setLoading(true)
      await api.delete(`/admin/faq/${id}`)
      toast.success('Deletado')
      await getFaqs()
      setLoading(false)
    }
  }

  const getCategories = async (): Promise<void> => {
    const { data } = await api.get('/faq/categories')
    if (data.length) setCategories(data)
  }

  // eslint-disable-next-line
  const editFaq = async (id: number, content: Partial<FaqInterface>) => {
    const cat = faqs.find(faq => faq.id === id)?.category?.id
    if (!cat) return toast.error('Selecionar categoria')

    setLoading(true)

    const elementID = `#title-item-${id}`
    const title = document.querySelector<HTMLInputElement>(elementID)?.value

    const { data } = await api.put(`/admin/faq/${id}`, {
      data: { ...content, title, category: cat },
    })
    if (data.affected) {
      toast.success('Faq atualizada')
      setItemEdit(null)
      await getFaqs()
      setLoading(false)
    }
  }

  const createFaq = async (
    content: Partial<FaqInterface>,
  ): Promise<FaqInterface> => {
    setLoading(true)
    const {
      data,
    }: {
      data: FaqInterface
    } = await api.post('/admin/faq', { data: content })
    toast.success('Item criado')
    setShowDialogNewFaq(false)
    await getFaqs()
    setLoading(false)
    return data
  }

  React.useEffect(() => {
    getFaqs()
    getCategories()
  }, [])

  React.useEffect(() => {
    if (itemEdit) {
      const faqSelected = faqs.find(item => item.id === itemEdit)
      if (faqSelected) setEditorState(htmlToEditor(faqSelected?.description))
    } else {
      setEditorState(EditorState.createEmpty())
    }
    // eslint-disable-next-line
  }, [itemEdit])

  const handleClickListItem = (id: number) => {
    setItemEdit(itemEdit === id ? 0 : id)
  }

  const saveOrder = async () => {
    const filtered: Partial<FaqInterface>[] = []

    faqs.forEach(item => {
      const { id, order } = item
      if (order) filtered.push({ id, order })
    })

    const req = await api.put('/admin/faq-orders', {
      data: filtered,
    })
    if (req.status === 200) {
      toast.success('Sucesso')
    }
    setShowDialogOrder(false)
    // eslint-disable-next-line
    console.log('edit order =>', req.data)
  }

  const saveCatsOrder = async () => {
    const filtered: Partial<CatInterface>[] = []

    categories.forEach(item => {
      const { id, order } = item
      if (order) filtered.push({ id, order })
    })

    const req = await api.put('/admin/faq-orders-categories', {
      data: filtered,
    })
    if (req.status === 200) {
      toast.success('Sucesso')
    }
    setShowCatsOrder(false)
    // eslint-disable-next-line
    console.log('edit order =>', req.data)
  }

  const handleChangeCat = (
    event: React.ChangeEvent<{ value: unknown }>,
    faqId: FaqInterface['id'],
  ) => {
    const item = faqs.find(faq => faq.id === faqId) || faqs[0]
    const cat = categories.find(cat => cat.id === event.target.value)
    item.category = cat
    setFaqs(faqs => [...faqs, item])
  }

  return (
    <Styles.FaqContainer>
      {/* <Styles.BtnMainContainer> */}
      <Styles.BtnContainer>
        <Button
          loading={showDialogOrder}
          onClick={() => setShowDialogNewFaq(true)}
        >
          + Adicionar novo item
        </Button>

        <Button
          onClick={() => {
            setShowDialogOrder(true)
            setShowCatsOrder(false)
          }}
        >
          Organizar itens
        </Button>

        <Button onClick={() => setShowManageCategories(true)}>
          Criar ou renomear categorias
        </Button>

        <Button
          onClick={() => {
            setShowCatsOrder(true)
            setShowDialogOrder(false)
          }}
        >
          Organizar categorias
        </Button>
      </Styles.BtnContainer>
      {/* </Styles.BtnMainContainer> */}

      <Styles.ListContainer>
        {!showDialogOrder &&
          !!faqs.length &&
          faqs.map((faq: FaqInterface) => (
            <React.Fragment key={faq.id}>
              <Styles.ListItem
                isActive={faq.id === itemEdit}
                onClick={() => handleClickListItem(faq.id)}
              >
                <Styles.ListTitle>
                  {faq.title}{' '}
                  {faq.category && (
                    <span className="cat">({faq.category?.title})</span>
                  )}
                </Styles.ListTitle>
                {itemEdit === faq.id && <Styles.ListIcon>▼</Styles.ListIcon>}
                {/* ▼ ▲ */}
              </Styles.ListItem>

              {itemEdit === faq.id && (
                <Styles.EditContainer>
                  <Styles.EditInputTitle
                    placeholder="Título"
                    defaultValue={faq.title}
                    id={`title-item-${faq.id}`}
                  />

                  {!!categories.length && (
                    <FormControl
                      variant="outlined"
                      style={{ width: '100%', marginTop: 10, marginBottom: 10 }}
                    >
                      <InputLabel id="demo-simple-select-outlined-label">
                        Categoria
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        value={faq.category?.id || 0}
                        onChange={ev => handleChangeCat(ev, faq.id)}
                        label="Categoria"
                      >
                        <MenuItem value={0}>
                          <em>-- Selecione a Categoria --</em>
                        </MenuItem>
                        {categories.map((cat: CatInterface) => (
                          <MenuItem key={`cat-${cat.id}`} value={cat.id}>
                            {cat.title}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  )}

                  <TextEditor
                    editorState={editorState}
                    onChangeEditorState={setEditorState}
                  />

                  <Styles.EditContainerActionGroup>
                    <Styles.ActionButton
                      onClick={() => deleteFaq(faq.id)}
                      loading={loading}
                      color="error"
                    >
                      Excluir item
                    </Styles.ActionButton>

                    <Styles.ActionButton
                      onClick={() =>
                        editFaq(faq.id, {
                          description: editorToHTML(
                            editorState.getCurrentContent(),
                          ),
                        })
                      }
                      loading={loading}
                      color="success"
                    >
                      Salvar alterações
                    </Styles.ActionButton>
                  </Styles.EditContainerActionGroup>
                </Styles.EditContainer>
              )}
            </React.Fragment>
          ))}
      </Styles.ListContainer>

      {showManageCategories && (
        <ManageCategoriesDialog
          onCloseDialog={() => setShowManageCategories(false)}
          categories={categories}
          updateCategories={getCategories}
          onUpdateOrder={cats => setCategories(cats)}
        />
      )}

      {showDialogNewFaq && (
        <NewFaqDialog
          loading={loading}
          onCloseDialog={() => setShowDialogNewFaq(false)}
          onSubmit={createFaq}
        />
      )}

      {showCatsOrder && (
        <>
          <ReorderCategoryItems
            items={categories}
            onReorder={cats => setCategories(cats)}
          />
          <Button style={{ marginTop: 20 }} onClick={saveCatsOrder}>
            Salvar ordem
          </Button>
          <Button
            style={{ marginTop: 20 }}
            onClick={() => setShowCatsOrder(false)}
          >
            Cancelar Edição
          </Button>
        </>
      )}

      {showDialogOrder && (
        <>
          <ReorderDialog items={faqs} onReorder={faqs => setFaqs(faqs)} />
          <Button style={{ marginTop: 20 }} onClick={saveOrder}>
            Salvar ordem
          </Button>
          <Button
            style={{ marginTop: 20 }}
            onClick={() => setShowDialogOrder(false)}
          >
            Cancelar Edição
          </Button>
        </>
      )}
    </Styles.FaqContainer>
  )
}

export default Faq
