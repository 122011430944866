import styled from 'styled-components'

export const Span = styled.span`
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: #eee;
  border-radius: 50%;
  background-color: white;
  border: 1px solid #000;
  opacity: 0.6;

  &:after {
    content: '';
    position: absolute;
    display: none;
  }
`

export const ContainerLabel = styled.label`
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 18px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
  }
  &:checked input ~ ${Span} {
    background-color: white;
    border: 1px solid black;
  }
  input:checked ~ ${Span} {
    background-color: white;
    border: 1px solid yellow;
  }
  input:checked ~ ${Span}:after {
    display: block;
  }
  ${Span}:after {
    width: 14px;
    height: 14px;
    border-radius: 50%;
    background: yellow;
    margin: 2px 0px 0px 2px;
  }
`
