import React, { FC } from 'react'

import history from '@/services/history'

import { Container, Item, ItemName, Separator } from './styles'

interface BreadcrumbsProps {
  items: BreadcrumbsItem[]
  separator?: string
}

export interface BreadcrumbsItem {
  name: string
  to?: string
}

export const Breadcrumbs: FC<BreadcrumbsProps> = ({
  items,
  separator = '/',
}) => {
  return (
    <Container>
      {items.map((item: BreadcrumbsItem, index: number) => {
        const isNotLastItem = items.length - 1 !== index

        return (
          <Item key={item.name}>
            <ItemName
              onClick={() => item.to && history.push(item.to)}
              cursor={item.to ? 'pointer' : 'auto'}
            >
              {item.name}
            </ItemName>
            {isNotLastItem && <Separator>{separator}</Separator>}
          </Item>
        )
      })}
    </Container>
  )
}
