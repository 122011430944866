/* eslint-disable @typescript-eslint/no-extra-semi */
/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable no-plusplus */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState, useEffect } from 'react'
import DayPicker from 'react-day-picker'
import { FiCalendar, FiClock, FiMapPin } from 'react-icons/fi'
import InputMask from 'react-input-mask'
import { useSelector } from 'react-redux'
import { toast } from 'react-toastify'

import { FormikProvider, useFormik } from 'formik'
import 'react-day-picker/lib/style.css'
import * as Yup from 'yup'

import theme from '@/assets/styles/theme'
import Button from '@/components/Ui/Button/index'

import { getNextDay } from './date'
import * as S from './styles'

const MONTHS = [
  'Janeiro',
  'Fevereiro',
  'Março',
  'Abril',
  'Maio',
  'Junho',
  'Julho',
  'Agosto',
  'Setembro',
  'Outubro',
  'Novembro',
  'Dezembro',
]

const WEEKDAYS_LONG = [
  'Domingo',
  'Segunda',
  'Terça',
  'Quarta',
  'Quinta',
  'Sexta',
  'Sabado',
]

const WEEKDAYS_SHORT = ['Dom', 'Seg', 'Ter', 'Quar', 'Qui', 'Sex', 'Sa']

const HOURS = [
  '09:00',
  '10:00',
  '11:00',
  '12:00',
  '14:00',
  '15:00',
  '16:00',
  '17:00',
]

// Form validation Schema
const validationSchema = Yup.object().shape({
  phone: Yup.string().required('Campo obrigatório').nullable(),
  cep: Yup.string().required('Campo obrigatório').nullable(),
  address: Yup.string().required('Campo obrigatório').nullable(),
  neighborhood: Yup.string().required('Campo obrigatório').nullable(),
  number: Yup.string().required('Campo obrigatório').nullable(),
  complement: Yup.string().nullable(),
  city: Yup.string().required('Campo obrigatório').nullable(),
  state: Yup.string().required('Campo obrigatório').nullable(),
})

function ScheduleCalendar({ onSubmit }: { onSubmit: any }) {
  const [value, setValue] = useState(null)
  const [hour, setHour] = useState(null)
  const [disabledAddress, setDisabledAddress] = useState(false)
  const [disabledNeighborhood, setDisabledNeighborhood] = useState(false)
  const [disabledComplement, setDisabledComplement] = useState(false)
  const [disabledCity, setDisabledCity] = useState(false)
  const [disabledState, setDisabledState] = useState(false)
  const [disabledDays, setDisabledDays] = useState([])

  const billOwner = useSelector((state: any) => state.Bill.billOwner)
  const { phone: userPhone } = useSelector((state: any) => state.Client.client)
  const user = useSelector((state: any) => state.Client.client)

  const handleDate = (date: any, { disabled }: any) => {
    if (disabled) return 0
    setValue(date)
    return 1
  }

  const handleSubmit = (form: any) => {
    if (!value || !hour)
      return toast.error('Por favor selecione um dia e horário.')

    const formatedValue = new Date(value as any)
    formatedValue.setHours((hour as any).substring(0, 2))
    formatedValue.setMinutes(0)

    form.schedule = formatedValue
    onSubmit(form)
    return 1
  }

  const formikbag = useFormik({
    initialValues: {
      phone: userPhone?.substr(2),
      cep: billOwner?.postal_code,
      address: billOwner?.address,
      neighborhood: '',
      number: billOwner?.address_number,
      complement: billOwner?.address_complement,
      city: user?.city?.name,
      state: user?.state,
      schedule: '',
    },
    validationSchema,
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: handleSubmit as any,
  })

  useEffect(() => {
    if (billOwner?.postal_code)
      formikbag.setFieldValue('cep', billOwner?.postal_code)
    if (billOwner?.address)
      formikbag.setFieldValue('address', billOwner?.address)
    if (billOwner?.address_neighborhood)
      formikbag.setFieldValue('neighborhood', billOwner?.address_neighborhood)
    if (billOwner?.address_complement)
      formikbag.setFieldValue('complement', billOwner?.address_complement)
    if (billOwner?.city) formikbag.setFieldValue('city', billOwner?.city)
    if (billOwner?.address_number)
      formikbag.setFieldValue('number', billOwner?.address_number)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [billOwner])

  const setFieldValue = (fieldName: any, value: any) =>
    formikbag.setFieldValue(fieldName, value)

  const handleCepChange = async (event: any) => {
    const cep = event.target.value.replace(/\D/g, '')

    formikbag.setFieldValue('cep', cep)

    if (cep.length >= 8) {
      fetch(`https://viacep.com.br/ws/${cep}/json/`)
        .then(res => res.json())
        .then(data => {
          setFieldValue('address', data.logradouro || '')
          setFieldValue('neighborhood', data.bairro || '')
          setFieldValue('complement', data.complemento || '')
          setFieldValue('city', data.localidade || '')
          setFieldValue('state', data.uf || '')

          if (data.logradouro) setDisabledAddress(true)
          else setDisabledAddress(false)

          if (data.bairro) setDisabledNeighborhood(true)
          else setDisabledNeighborhood(false)

          if (data.complemento) setDisabledComplement(true)
          else setDisabledComplement(false)

          if (data.localidade) setDisabledCity(true)
          else setDisabledCity(false)

          if (data.uf) setDisabledState(true)
          else setDisabledState(false)

          if (data.erro) {
            toast.error(
              'Não encontramos o CEP desejado. Por favor verifique os dados informados e tente novamente.',
              {
                autoClose: 10000,
              },
            )
          }
        })
        .catch(() => {
          toast.error(
            'Algo de errado, não conseguimos consultar o CEP desejado. Por favor tente novamente.',
            {
              autoClose: 10000,
            },
          )
        })
    }
  }

  const birthdayStyle = `.DayPicker-Day--selected {
    background-color: ${theme.colors.primary} !important;
    color: black !important;
    font-weight: bold;
  }

  .DayPicker-Month {
    margin: 0 !important
  }
  `

  const disabledStyle = `.DayPicker-Day--disabled {
    color: #ffaeb3 !important;
    font-weight: bold;
  }`

  const padding = `.DayPicker-Day {
    padding: 1.3rem !important;
    color: ${theme.colors.gray[600]};
    font-weight: bold;
  }`

  const query = `@media (max-width: 768px) {
    .DayPicker-Day {
    padding: 0.875rem !important;
    }
  }`

  const days = `.DayPicker-Weekday abbr[title] {
    font-weight: bold;
    color: ${theme.colors.primary};
  }`

  const clearArrDuplicatedItems = (arr: any[]) => {
    const seen = {}
    const ret_arr = []
    for (let i = 0; i < arr.length; i++) {
      if (!(arr[i] in seen)) {
        ret_arr.push(arr[i])
        // @ts-ignore
        seen[arr[i]] = true
      }
    }
    return ret_arr
  }

  const getDisabledDays = async () => {
    const disableds = [...disabledDays]

    const defaultDisable = [
      new Date(),
      { before: new Date() },
      { daysOfWeek: [0] },
    ]

    const apiRequest: any = []
    if (apiRequest.length)
      apiRequest.forEach((item: any) => disableds.push(new Date(item) as never))

    if (new Date().getHours() >= 12) {
      if (new Date().getDay() === 5) {
        ;[6, 0, 1].forEach(item => disableds.push(getNextDay(item) as never))
      } else if (new Date().getDay() === 6) {
        ;[0, 1].forEach(item => disableds.push(getNextDay(item) as never))
      } else {
        disableds.push(getNextDay(new Date().getDay() + 1) as never)
      }
    } else if (new Date().getDay() === 6 || new Date().getDay() === 0) {
      ;[1].forEach(item => disableds.push(getNextDay(item) as never))
    }

    const _date_ = new Date()

    function addDays(date: any, days: any) {
      const result = new Date(date)
      result.setDate(result.getDate() + days)
      return result
    }

    for (let idx = 31; idx < 365 * 20; idx++) {
      disableds.push(addDays(_date_, idx) as never)
    }

    setDisabledDays([
      ...clearArrDuplicatedItems(disableds),
      ...defaultDisable,
    ] as never[])
  }

  React.useEffect(() => {
    getDisabledDays()
    // eslint-disable-next-line
  }, [])

  return (
    <div>
      <S.StyledDateTimeContainer>
        <S.CalendarContainer>
          <S.CalendarIconContainer>
            <FiCalendar
              size="30px"
              color={theme.colors.primary}
              style={{ marginRight: '5px' }}
            />
            Data
          </S.CalendarIconContainer>
          <style>
            {birthdayStyle}
            {padding}
            {days}
            {query}
            {disabledStyle}
          </style>
          <DayPicker
            months={MONTHS}
            weekdaysLong={WEEKDAYS_LONG}
            weekdaysShort={WEEKDAYS_SHORT}
            selectedDays={value as any}
            onDayClick={handleDate}
            disabledDays={disabledDays}
          />
        </S.CalendarContainer>
        <S.CalendarContainer>
          <S.CalendarIconContainer>
            <FiClock
              size="30px"
              color={theme.colors.primary}
              style={{ marginRight: '5px' }}
            />
            Horario
          </S.CalendarIconContainer>
          <S.StyledTimerContainer>
            {HOURS.map(h => (
              <S.StyledTime
                key={h}
                onClick={() => setHour(h as any)}
                // @ts-ignore
                selected={h === hour}
              >
                {h}
              </S.StyledTime>
            ))}
          </S.StyledTimerContainer>
        </S.CalendarContainer>

        <S.CalendarContainer style={{ width: '100%' }}>
          <S.CalendarIconContainer>
            <FiMapPin
              size="30px"
              color={theme.colors.primary}
              style={{ marginRight: '5px' }}
            />
            Endereço
          </S.CalendarIconContainer>
          <FormikProvider value={formikbag}>
            <S.StyledTextFieldWrapper>
              <S.StyledTextFieldContainer>
                <S.CustomInputLabel htmlFor="phone">
                  Telefone:
                </S.CustomInputLabel>
                <InputMask
                  mask="(99) 9 9999-9999"
                  maskChar=" "
                  onChange={(e: any) => setFieldValue('phone', e.target.value)}
                  value={formikbag.values.phone}
                >
                  {() => (
                    <S.MuiInput id="phone" name="phone" variant="outlined" />
                  )}
                </InputMask>
              </S.StyledTextFieldContainer>
              <S.StyledTextFieldContainer>
                <S.CustomInputLabel htmlFor="cep">CEP:</S.CustomInputLabel>

                <InputMask
                  mask="99999-999"
                  maskChar=" "
                  onChange={(e: any) => handleCepChange(e)}
                  value={formikbag.values.cep}
                >
                  {() => <S.MuiInput id="cep" name="cep" variant="outlined" />}
                </InputMask>
              </S.StyledTextFieldContainer>
              <S.StyledTextFieldContainer>
                <S.CustomInputLabel htmlFor="address">
                  Endereço:
                </S.CustomInputLabel>
                <S.MuiInput
                  id="address"
                  name="address"
                  type="text"
                  value={formikbag.values.address}
                  onChange={event =>
                    setFieldValue('address', event.target.value)
                  }
                  disabled={!!disabledAddress}
                  variant="outlined"
                />
              </S.StyledTextFieldContainer>
              <S.StyledTextFieldContainer>
                <S.CustomInputLabel htmlFor="neighborhood">
                  Bairro:
                </S.CustomInputLabel>
                <S.MuiInput
                  id="neighborhood"
                  name="neighborhood"
                  type="text"
                  value={formikbag.values.neighborhood}
                  onChange={event =>
                    setFieldValue('neighborhood', event.target.value)
                  }
                  disabled={!!disabledNeighborhood}
                  variant="outlined"
                />
              </S.StyledTextFieldContainer>
              <S.StyledTextFieldContainer>
                <S.CustomInputLabel htmlFor="number">
                  Número:
                </S.CustomInputLabel>
                <S.MuiInput
                  id="number"
                  name="number"
                  type="text"
                  value={formikbag.values.number}
                  onChange={event =>
                    setFieldValue('number', event.target.value)
                  }
                  variant="outlined"
                />
              </S.StyledTextFieldContainer>
              <S.StyledTextFieldContainer>
                <S.CustomInputLabel htmlFor="complement">
                  Complemento:
                </S.CustomInputLabel>
                <S.MuiInput
                  id="complement"
                  name="complement"
                  type="text"
                  value={formikbag.values.complement}
                  onChange={event =>
                    setFieldValue('complement', event.target.value)
                  }
                  disabled={!!disabledComplement}
                  variant="outlined"
                />
              </S.StyledTextFieldContainer>
              <S.StyledTextFieldContainer>
                <S.CustomInputLabel htmlFor="city">Cidade:</S.CustomInputLabel>
                <S.MuiInput
                  id="city"
                  name="city"
                  type="text"
                  value={formikbag.values.city}
                  onChange={event => setFieldValue('city', event.target.value)}
                  disabled={!!disabledCity}
                  variant="outlined"
                />
              </S.StyledTextFieldContainer>

              <S.StyledTextFieldContainer>
                <S.CustomInputLabel htmlFor="state">Estado:</S.CustomInputLabel>
                <S.MuiInput
                  id="state"
                  name="state"
                  type="text"
                  value={formikbag.values.state}
                  onChange={event => setFieldValue('state', event.target.value)}
                  disabled={!!disabledState}
                  variant="outlined"
                />
              </S.StyledTextFieldContainer>
            </S.StyledTextFieldWrapper>
          </FormikProvider>
        </S.CalendarContainer>
        <div />
      </S.StyledDateTimeContainer>
      <S.ButtonContainer>
        <Button type="submit" onClick={formikbag.handleSubmit as any}>
          Agende sem custo
        </Button>
      </S.ButtonContainer>
    </div>
  )
}

export default ScheduleCalendar
