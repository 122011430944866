import React from 'react'
import DayPicker, { DateUtils, DayModifiers } from 'react-day-picker'
import { toast } from 'react-toastify'

import styled from 'styled-components'

import Button from '@/components/Ui/Button'
import api from '@/services/api'

import { months, weekdaysShort } from './utils'

import 'react-day-picker/lib/style.css'

const Styles = {
  Container: styled.div``,
  Title: styled.span`
    margin-top: 20px;
    margin-bottom: 20px;
    display: block;
    font-size: 1rem;
    font-weight: bold;
    display: block;
    u {
      color: red;
    }
  `,
  Button: styled(Button)`
    margin: 0 auto;
  `,
  PickerContainer: styled.div<{ isLoading: boolean }>`
    text-align: center;
    opacity: ${props => (props.isLoading ? 0.5 : 1)};
  `,
  BtnContainer: styled.div`
    display: block;
    text-align: center;
  `,
}

const CalendarBlockScheduling = () => {
  const [selectedDays, setSelectedDays] = React.useState<Date[]>([])
  const [loading, setLoading] = React.useState<boolean>(true)

  const getBlockedDays = () => {
    setLoading(true)
    api
      .get('/admin/config-calendar')
      .then(({ data }) => {
        if (data.success) {
          const receivedDays: Date[] = []
          data.data.forEach((item: string) => receivedDays.push(new Date(item)))
          setSelectedDays(receivedDays)
        } else {
          toast.error('Houve um erro ao recuperar as datas bloqueadas')
        }
      })
      .finally(() => setLoading(false))
  }

  const updateBlockedDays = () => {
    setLoading(true)
    api
      .put('/admin/config-calendar', selectedDays)
      .then(res => {
        if (res.data.success) {
          toast.success('Bloqueios atualizados com sucesso')
          getBlockedDays()
        } else {
          toast.error('Houve um erro ao atualizar os dias bloqueados')
        }
      })
      .finally(() => setLoading(false))
  }

  const handleDayClick = (day: Date, props: DayModifiers) => {
    const _selectedDays = selectedDays.concat()
    if (props.selected) {
      const selectedIndex = _selectedDays.findIndex((selectedDay: Date) =>
        DateUtils.isSameDay(selectedDay, day),
      )
      _selectedDays.splice(selectedIndex, 1)
    } else {
      _selectedDays.push(day)
    }
    setSelectedDays(_selectedDays)
  }

  React.useEffect(() => {
    getBlockedDays()
  }, [])

  return (
    <Styles.Container>
      <Styles.Title>
        Configure datas <u>adicionais</u> bloqueadas para agendamentos de
        visitas técnicas
      </Styles.Title>
      <Styles.PickerContainer isLoading={loading}>
        <DayPicker
          numberOfMonths={3}
          months={months}
          weekdaysShort={weekdaysShort}
          selectedDays={selectedDays}
          onDayClick={handleDayClick}
        />
      </Styles.PickerContainer>
      <Styles.BtnContainer>
        <Styles.Button onClick={updateBlockedDays} loading={loading}>
          Atualizar datas bloqueadas
        </Styles.Button>
      </Styles.BtnContainer>
    </Styles.Container>
  )
}

export default CalendarBlockScheduling
