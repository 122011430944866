import styled from 'styled-components'

import theme from '@/assets/styles/theme'

export const Container = styled.div`
  margin: 15px 0px 15px 0px;
`

export const TitleContainer = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  justify-content: start;
  align-items: center;

  & > h1 {
    color: ${theme.colors.black};
    font-size: 1rem;
    margin-left: 1rem;
  }
`

export const NumberContainer = styled.div`
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  background: ${theme.colors.primary};
  display: flex;
  align-items: center;
  justify-content: center;

  & > span {
    color: ${theme.colors.gray[600]};
    font-weight: 700;
    font-size: 1rem;
  }
`

export const ContentContainer = styled.div`
  width: 100%;
  overflow: auto;
`
