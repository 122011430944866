import styled from 'styled-components'

import theme from '@/assets/styles/theme'

export const Container = styled.div`
  display: block;
  padding: 30px;
  overflow-y: scroll;

  ::-webkit-scrollbar {
    display: none;
  }
`
export const Header = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  @media only screen and (max-width: ${theme.breakpoints.sm}) {
    justify-content: space-between;
  }

  & > h1 {
    font-size: 22px;
    font-weight: 600;
    color: ${theme.colors.gray[600]};
  }
`

export const ContainerClient = styled.div`
  display: flex;
  margin-top: 30px;
  width: 100%;
  padding: 20px;
  background-color: ${theme.colors.gray[100]};
  justify-content: flex-start;

  @media only screen and (max-width: ${theme.breakpoints.sm}) {
    flex-direction: column;
    justify-content: space-between;
  }
`
export const ContainerColumn = styled.div`
  display: flex;
  width: 40%;
  height: 190px;
  flex-direction: column;
  justify-content: space-between;

  @media only screen and (max-width: ${theme.breakpoints.sm}) {
    display: block;
    width: 100%;
    height: auto;
  }
`
export const Fields = styled.span`
  font-size: 16px;
  font: Regular 16px/22px Roboto;
  opacity: 0.7;
`

export const Font = styled.b`
  font: Bold 16px/22px Roboto;
  opacity: 1;
`
export const ContainerSelect = styled.div`
  display: flex;
`

export const LabelSelect = styled.div`
  margin-top: 5px;
  font-size: 16px;
  font: Regular 16px/22px Roboto;
  opacity: 0.7;
`

export const Select = styled.div`
  margin-left: 5px;
`
export const FieldsLabel = styled.div`
  @media only screen and (max-width: ${theme.breakpoints.sm}) {
    margin-bottom: 2px;
  }
`
export const ContainerTable = styled.div`
  margin-top: 30px;
`
export const Status = styled.div`
  margin-left: 10px;
  @media only screen and (max-width: ${theme.breakpoints.sm}) {
    margin-left: 0;
  }
`

export const GroupButton = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 180px 220px;
  justify-content: end;
  column-gap: 1rem;
  padding: 25px 0px;

  @media only screen and (max-width: 750px) {
    display: flex;
    flex-direction: column-reverse;
    width: 100%;
    row-gap: 1rem;
  }
`

export const ContainerReport = styled.div`
  margin-top: 15px;
  @media only screen and (max-width: ${theme.breakpoints.sm}) {
    justify-content: space-between;
  }

  & > h1 {
    font-size: 22px;
    font-weight: 600;
    color: ${theme.colors.gray[600]};
  }
`
export const ContainerSteps = styled.div`
  margin-top: 30px;
  padding-bottom: 2rem;
  display: flex;
  justify-content: flex-start;
  width: 100%;
  overflow: auto;

  & > div {
    margin-right: 30px;
  }

  @media only screen and (max-width: 750px) {
    width: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-right: 0;
    row-gap: 1rem;
  }
`
