/* eslint-disable react/no-array-index-key */
/* eslint-disable react/require-default-props */
import React from 'react'

import history from '@/services/history'
import { capitalizeFirstLetter } from '@/utils/capitalizeFirstLetter'

import { Name, Message } from './styles'

interface INotificationMessageProps {
  message: string
  clientName: string
  /**
   * Pass clientId if you want to redirect to client details when
   * click in client name
   */
  clientId?: string
}

/**
 * Handle notification message component,
 * inserting the client name in message variable.
 *
 * Pass **clientId** to redirect to client details
 *
 * @param param
 */
const NotificationMessage = ({
  message,
  clientName,
  clientId,
}: INotificationMessageProps) => {
  const messageParts = message.split(' ')

  const handleRedirectToClient = () =>
    clientId && history.push(`/customer/${clientId}/details`)

  return (
    <div>
      {messageParts.map((messagePart, index) => {
        const isFirstWord = index === 0

        return messagePart === '{{client_name}}' ? (
          <Name
            key={index}
            onClick={handleRedirectToClient}
            isClicked={!!clientId}
          >
            {clientName}{' '}
          </Name>
        ) : (
          <Message key={index}>
            {' '}
            {isFirstWord
              ? capitalizeFirstLetter(messagePart)
              : messagePart}{' '}
          </Message>
        )
      })}
    </div>
  )
}

export { NotificationMessage }
