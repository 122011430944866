import styled, { css } from 'styled-components'

import theme from '@/assets/styles/theme'

interface ContainerProps {
  type?: 'success' | 'warning' | 'error'
}

const alertTypeVariations = {
  success: css`
    background-color: ${theme.colors.green[100]};
    color: ${theme.colors.green[200]};
  `,

  warning: css`
    background: ${theme.colors.orange[100]};
    color: ${theme.colors.orange[200]};
  `,

  error: css`
    background-color: ${theme.colors.red[100]};
    color: ${theme.colors.red[200]};
  `,
}

export const Container = styled.div<ContainerProps>`
  align-items: center;
  border: 1px solid transparent;
  border-radius: 5px;
  display: flex;
  margin-bottom: 16px;
  padding: 10px 20px;
  position: relative;

  ${props => alertTypeVariations[props.type || 'warning']}

  > svg {
    margin-right: 10px;
  }

  @media only screen and (max-width: 750px) {
    > svg {
      display: none;
    }
  }
`
