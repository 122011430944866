import styled, { css } from 'styled-components'

import theme from '@/assets/styles/theme'

interface ContainerProps {
  size: 'small' | 'medium' | 'big' | 'custom'
}
const sizes = {
  small: css`
    width: 100px;
  `,
  medium: css`
    width: 200px;
  `,
  big: css`
    width: 350px;
  `,
  custom: css`
    width: 100%;
  `,
}

export const Container = styled.div<ContainerProps>`
  ${props => sizes[props.size]}
`

export const SelectContainer = styled.select`
  border: 1px solid ${theme.colors.gray[400]};
  border-radius: 5px;
  background-color: transparent;
  padding: 10px 10px;
`
export const Label = styled.div`
  padding: 5px 0;

  @media only screen and (max-width: 750px) {
    font-size: 0.8rem;
  }
`
