import styled from 'styled-components'

import theme from '@/assets/styles/theme'

export const ClientCreditContainer = styled.div`
  display: flex;
  flex-direction: row;
  background-color: ${theme.colors.gray[100]};
  margin-bottom: 20px;
  padding: 20px;
  flex-wrap: wrap;
`

export const DebitsList = styled.div`
  width: 70%;
  display: block;
`

export const SectionTitle = styled.h2`
  color: ${theme.colors.gray[600]};
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 10px;
`

export const DebitItemsContainer = styled.div`
  /* display: flex;
  flex-direction: row; */
`
