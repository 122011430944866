/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react'

import GroupPresentation from './GroupPresentation'
import { ContainerPlan } from './styles'

const PlanGroupProposal = ({ simulationValues }: { simulationValues: any }) => {
  return (
    <ContainerPlan>
      <GroupPresentation simulationValues={simulationValues} />
    </ContainerPlan>
  )
}

export { PlanGroupProposal }
