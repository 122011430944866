/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { FC, useEffect, useState } from 'react'
import MapPicker from 'react-google-map-picker'

import Button from '@/components/Ui/Button'
import { useWindowSize } from '@/hooks/useWindowSize'
import { Dialog } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'

import * as S from './styles'

interface CoordinatesState {
  lat: number
  lng: number
}

interface ICoordinatesMapProps {
  defaultCoords?: CoordinatesState
  opened: boolean
  onCloseDialog: any
  onSubmit: (location: any) => void
}

const CoordinatesMap: FC<ICoordinatesMapProps> = ({
  defaultCoords = undefined,
  onCloseDialog,
  opened,
  onSubmit,
}) => {
  const [defaultLocation] = useState({
    lat: -23.5688619,
    lng: -46.6486371,
  })

  const { width } = useWindowSize()

  const [location, setLocation] = useState(defaultLocation)
  const [zoom, setZoom] = useState(10)

  const handleChangeLocation = (lat: any, lng: any) => {
    setLocation({ lat, lng })
  }

  const handleChangeZoom = (newZoom: any) => setZoom(newZoom)

  const key: string = process.env.REACT_APP_GMAPS_API_KEY as string

  useEffect(() => {
    if (defaultCoords?.lat) {
      setLocation({ lat: defaultCoords.lat, lng: defaultCoords.lng })
    }
  }, [defaultCoords])

  return (
    <Dialog
      open={opened}
      onClose={onCloseDialog}
      fullScreen={!!width && width <= 750}
    >
      <S.CloseButton type="button" onClick={onCloseDialog}>
        <CloseIcon />
      </S.CloseButton>
      <S.Container>
        <S.Title>
          <span>Coordenadas</span>
        </S.Title>
        <div style={{ width: '100%' }}>
          <S.InputsContainer>
            <S.InputsFeatures>
              <label>Lat:</label>
              <input
                type="text"
                value={location.lat}
                onChange={e =>
                  setLocation({
                    lat: parseFloat(e.target.value),
                    lng: location.lng,
                  })
                }
              />
            </S.InputsFeatures>
            <S.InputsFeatures>
              <label>Lng:</label>
              <input
                type="text"
                value={location.lng}
                onChange={e =>
                  setLocation({
                    lng: parseFloat(e.target.value),
                    lat: location.lat,
                  })
                }
              />
            </S.InputsFeatures>
          </S.InputsContainer>

          <div style={{ height: '300px' }} id="okok">
            <MapPicker
              defaultLocation={location}
              zoom={zoom}
              style={{ height: '300px', width: '100%' }}
              onChangeLocation={handleChangeLocation}
              onChangeZoom={handleChangeZoom}
              apiKey={key}
            />
          </div>
        </div>
        <S.ButtonContainer>
          <Button
            full={!!width && width <= 550}
            size="default"
            color="primary"
            onClick={() => onSubmit(location)}
          >
            Atualizar
          </Button>
        </S.ButtonContainer>
      </S.Container>
    </Dialog>
  )
}

export default CoordinatesMap
