import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;

  @media only screen and (max-width: 750) {
    width: 100%;
  }
`

export const InputsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  padding: 0.5rem 0;
  margin-bottom: 0.5rem;

  @media only screen and (max-width: 550px) {
    flex-direction: column;
  }
`

export const Title = styled.div`
  text-align: center;
  margin-bottom: 1rem;

  & > span {
    font: Bold 26px/35px Roboto;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
  }
`

export const ContainerCoords = styled.div`
  position: absolute;
  z-index: 2;
  background-color: #ffd500;
  letter-spacing: 1px;
  padding: 3px;
  opacity: 0.66;
  border-radius: 10px;
  width: 50%;
  height: 50vh;
`

export const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 1rem 0;
  text-align: center;
`

export const InputsFeatures = styled.div`
  display: flex;
  align-items: center;
  gap: 0.25rem;

  & > input {
    width: 100%;
    border-radius: 0.25rem;
  }

  @media only screen and (max-width: 550px) {
    width: 100%;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }
`

export const CloseButton = styled.button`
  border: none;
  background-color: transparent;
  position: absolute;
  right: 0.5rem;
  top: 0.5rem;
  padding: 0.5rem;
  border-radius: 50%;

  &:hover {
    background-color: #ccc3;
  }
`
