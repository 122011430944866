export enum PlanTypeEnum {
  MAX = 'MAX',
  MED = 'MED',
  LOW = 'LOW',
}

export const planType: { [key: string]: PlanTypeEnum } = {
  MAX: PlanTypeEnum.MAX,
  MED: PlanTypeEnum.MED,
  LOW: PlanTypeEnum.LOW,
}
