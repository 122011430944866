import React, { useRef, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'

import * as Yup from 'yup'

import SignInLogo from '@/assets/images/sign-in-logo.png'
import Alert from '@/components/Ui/Alert'
import Button from '@/components/Ui/Button'
import Input from '@/components/Ui/Form/Input'
import { RootState } from '@/store'
import { Creators } from '@/store/ducks/Auth'
import { FormHandles } from '@unform/core'
import { Form } from '@unform/web'

import { Container, Logo, Title, Subtitle, Warp, Forgot } from './styles'

interface SignInFormData {
  email: string
  password: string
}

const SignIn: React.FC = () => {
  const dispatch = useDispatch()

  const formRef = useRef<FormHandles>(null)

  const failure = useSelector((state: RootState) => state.Auth.failure)
  const loading = useSelector((state: RootState) => state.Auth.isLoading)

  const handleSubmit = useCallback(
    async (data: SignInFormData) => {
      try {
        formRef.current?.setErrors({})

        const schema = Yup.object().shape({
          email: Yup.string()
            .email('Digite um e-mail válido')
            .required('O email é obrigatório'),
          password: Yup.string().required('A senha é obrigatório'),
        })

        await schema.validate(data, {
          abortEarly: false,
        })

        dispatch(Creators.signInRequest(data))
      } catch (err) {
        const validationErrors: { [key: string]: string } = {}

        if (err instanceof Yup.ValidationError) {
          err.inner.forEach((error: Yup.ValidationError) => {
            validationErrors[error.path] = error.message
          })

          formRef.current?.setErrors(validationErrors)
        }
      }
    },
    [dispatch],
  )

  return (
    <Container>
      <Logo>
        <img src={SignInLogo} alt="Solar21" />
        <h1>Painel franqueado</h1>
      </Logo>
      <Title>Seja Bem-Vindo</Title>
      <Subtitle>Para acessar o sistema, efetue o login</Subtitle>

      <Warp>
        {failure?.message && <Alert type="error" text={failure.message} />}

        <Form ref={formRef} onSubmit={handleSubmit}>
          <Input
            name="email"
            type="text"
            placeholder="Digite seu email"
            label="Email"
          />

          <Input
            name="password"
            type="password"
            placeholder="Digite sua senha"
            label="Senha"
          />

          <Button
            type="submit"
            color="primary"
            size="big"
            full
            disabled={!!loading}
          >
            {loading ? 'Acessando...' : 'Acessar'}
          </Button>
        </Form>
      </Warp>

      <Forgot>
        <Link to="/forgot-password">Esqueceu a senha?</Link>
      </Forgot>
    </Container>
  )
}

export default SignIn
