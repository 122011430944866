import styled from 'styled-components'

import theme from '@/assets/styles/theme'
import ButtonComponent from '@/components/Ui/Button'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: auto;
`

export const BillContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 15px 0;
  border-bottom: 2px solid ${theme.colors.gray[400]};
`

export const SubText = styled.span``

export const InputContainer = styled.div`
  padding: 15px 0;
  margin-bottom: 15px;
`

export const CitiesContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 15px 0;
  padding-top: 40px;
  max-width: 675px;
`

export const StateContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  padding: 15px 0;
  width: 100%;
  & > div {
    margin-right: 15px;
  }

  @media only screen and (max-width: 850px) {
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    height: 250px;
    margin-bottom: 20px;
  }
`

export const Icon = styled.div`
  cursor: pointer;
`

export const SelectContainer = styled.div`
  width: 100%;
`

export const SubmitStateButton = styled(ButtonComponent)`
  width: 120px;
  height: 40px;

  @media only screen and (max-width: ${theme.breakpoints.sm}) {
    margin: 10px 0;
  }
`

export const TableContainer = styled.div``

export const AddButton = styled.div`
  max-height: 41px;
`

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 15px;
  @media only screen and (max-width: ${theme.breakpoints.sm}) {
    justify-content: flex-start;
  }
`

export const SubmitButton = styled(ButtonComponent)`
  width: 170px;
  height: 40px;
`
