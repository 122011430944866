import React, { FC, ReactNode, useCallback, useEffect, useState } from 'react'
import { BsPencil } from 'react-icons/bs'
import { useDispatch, useSelector } from 'react-redux'

import moment from 'moment'

import { IPermission } from '@/@types/Permission'
import { TechnicalVisitStatusEnum } from '@/@types/visit'
import { FullScreenLoader } from '@/components/FullScreenLoader'
import PageContent from '@/components/PageContent'
import { Table } from '@/components/Table/Table'
import { Calendar } from '@/components/Ui/Calendar'
import { MultSelect } from '@/components/Ui/MultSelect'
import { Pagination } from '@/components/Ui/Pagination/Pagination'
import { SearchInput } from '@/components/Ui/SearchInput/SearchInput'
import StatusCard from '@/components/Ui/Status/StatusCard'
import history from '@/services/history'
import { RootState } from '@/store'
import { Creators } from '@/store/ducks/Visit'
import { IVisitListQuery } from '@/store/ducks/Visit/types'
import { getStatusVisitStyle } from '@/utils/statusVisitStyle'
import { UnfoldMore } from '@material-ui/icons'

import * as S from './styles'

interface ISelectedDatesCalendar {
  dateInitial: string
  dateFinal: string
}

enum TypeDocumentEnum {
  CPF = 'CPF',
  CNPJ = 'CNPJ',
}

export interface IListDropDown {
  id: string
  label: string | ReactNode
}

const statusFilterData = [
  {
    id: TechnicalVisitStatusEnum.ACCOMPLISHED_AND_APPROVED,
    label: (
      <StatusCard
        style={getStatusVisitStyle(
          TechnicalVisitStatusEnum.ACCOMPLISHED_AND_APPROVED,
        )}
      />
    ),
  },
  {
    id: TechnicalVisitStatusEnum.DONE_AND_NOT_APPROVED,
    label: (
      <StatusCard
        style={getStatusVisitStyle(
          TechnicalVisitStatusEnum.DONE_AND_NOT_APPROVED,
        )}
      />
    ),
  },
  {
    id: TechnicalVisitStatusEnum.CONFIRMED_SCHEDULE,
    label: (
      <StatusCard
        style={getStatusVisitStyle(TechnicalVisitStatusEnum.CONFIRMED_SCHEDULE)}
      />
    ),
  },
  {
    id: TechnicalVisitStatusEnum.UNCONFIRMED_APPOINTMENT,
    label: (
      <StatusCard
        style={getStatusVisitStyle(
          TechnicalVisitStatusEnum.UNCONFIRMED_APPOINTMENT,
        )}
      />
    ),
  },
  {
    id: TechnicalVisitStatusEnum.VISIT_CANCELED,
    label: (
      <StatusCard
        style={getStatusVisitStyle(TechnicalVisitStatusEnum.VISIT_CANCELED)}
      />
    ),
  },
  {
    id: TechnicalVisitStatusEnum.LINK_TECHNICAL,
    label: (
      <StatusCard
        style={getStatusVisitStyle(TechnicalVisitStatusEnum.LINK_TECHNICAL)}
      />
    ),
  },
  {
    id: TechnicalVisitStatusEnum.WAITING_FOR_TECHNICIAN,
    label: (
      <StatusCard
        style={getStatusVisitStyle(
          TechnicalVisitStatusEnum.WAITING_FOR_TECHNICIAN,
        )}
      />
    ),
  },
  {
    id: TechnicalVisitStatusEnum.SCHEDULE_REFUSED,
    label: (
      <StatusCard
        style={getStatusVisitStyle(TechnicalVisitStatusEnum.SCHEDULE_REFUSED)}
      />
    ),
  },
  {
    id: TechnicalVisitStatusEnum.FULFILLED,
    label: (
      <StatusCard
        style={getStatusVisitStyle(TechnicalVisitStatusEnum.FULFILLED)}
      />
    ),
  },
]

const Visits: FC = () => {
  const dispatch = useDispatch()

  const permission = useSelector((state: RootState) => state.Auth.role)
  const visits = useSelector((state: RootState) => state.Visit.visits?.items)
  const pagination = useSelector((state: RootState) => state.Visit.visits?.meta)
  const userTechnicians = useSelector(
    (state: RootState) => state.Visit.technicians,
  )
  const isLoading = useSelector((state: RootState) => state.Visit.isLoading)

  const [page, setPage] = useState<number>(1)
  const [totalPages, setTotalPages] = useState<number>(1)
  const [search, setSearch] = useState<string>('')

  const [isOpenFilterTechnician, setIsOpenFilterTechnician] = useState<boolean>(
    false,
  )

  const [isOpenFilterStatus, setIsOpenFilterStatus] = useState<boolean>(false)
  const [technicians, setTechnicians] = useState<string[]>([])
  const [status, setStatus] = useState<string[]>([])
  const [date, setDate] = useState<string[]>([])

  const typeDocumentFilter = TypeDocumentEnum.CPF

  const hasPermission = permission === IPermission.TECHNICIAN

  const handleClickVisit = (id: string, status: TechnicalVisitStatusEnum) => {
    if (permission === IPermission.ADMIN)
      status === TechnicalVisitStatusEnum.LINK_TECHNICAL
        ? window.open(`/visit/${id}/edit`, '_blank')
        : window.open(`/customer/${id}/technicalVisit`, '_blank')

    if (
      permission === IPermission.TECHNICIAN ||
      permission === IPermission.FRANCHISEE
    )
      history.push(`/visit/${id}/edit`)
  }

  useEffect(() => {
    localStorage.setItem('sortData', '')
    setPage(1)
  }, [search, technicians, status, date, typeDocumentFilter])

  useEffect(() => {
    const query = {
      page,
      search,
      technicians,
      status,
      date,
      typeDocumentFilter,
      order: localStorage.getItem('sortData'),
      direction: localStorage.getItem('sortDirection'),
    }

    dispatch(Creators.visitListRequest(query))
  }, [dispatch, page, search, technicians, status, date, typeDocumentFilter])

  const formatDate = (date: string): string => {
    return moment(date).format('DD/MM/YYYY - HH[h]mm')
  }

  const visitsHandled = visits.map((visit, index) => {
    const ddd = visit.technical_visit.phone.substr(0, 2)
    const phone = visit.technical_visit.phone.substr(
      2,
      visit.technical_visit.phone.length,
    )

    return {
      client: visit.name,
      date: formatDate(visit.technical_visit.schedule),
      // hour: moment(visit.technical_visit.schedule).format('HH:mm'),
      phone: `(${ddd}) ${phone}`,
      scheduled_maked_in: formatDate(visit.technical_visit.created_at),
      technician: visit.technical_visit.technician?.fullname || '',
      city: `${visit.technical_visit.city}/${visit.technical_visit.state}`,
      status: (
        <StatusCard style={getStatusVisitStyle(visit.technical_visit.status)} />
      ),
      hasPartnership: visit.hasPartnership ? 'Sim' : 'Não',
      franchisee: visit?.franchisee?.fullname,
      subscription_lending:
        visit.subscription_lending === 'SUBSCRIPTION' ? 'Assinatura' : 'Venda',
      key: index,
      onClickRow: () =>
        handleClickVisit(visit.id, visit.technical_visit.status),
      edit: (
        <BsPencil
          size={20}
          style={{ cursor: 'pointer' }}
          onClick={() =>
            handleClickVisit(visit.id, visit.technical_visit.status)
          }
        />
      ),
    }
  })

  const handleChangePage = (value: number) => {
    setPage(value)
  }

  useEffect(() => {
    if (pagination) setTotalPages(pagination.total_pages)
  }, [pagination])

  const handleOnChangeInput = (value: string) => {
    setPage(1)
    setSearch(value)
  }

  const techniciansFilterData = [
    {
      id: 'all',
      label: 'Todos',
    },
    ...userTechnicians.map(technician => {
      return { id: technician.id, label: technician.fullname }
    }),
  ]

  const handleChangeFilterTechnician = (selectedItems: IListDropDown[]) => {
    let selectedTechnicianIds = selectedItems.map(
      selectedItem => selectedItem.id,
    )

    const isExists = selectedTechnicianIds.some(
      selectedId => selectedId === 'all',
    )

    if (isExists) selectedTechnicianIds = []
    setTechnicians(selectedTechnicianIds)
  }

  const handleChangeFilterStatus = (selectedItems: IListDropDown[]) => {
    const selectedStatusIds = selectedItems.map(selectedItem => selectedItem.id)

    setStatus(selectedStatusIds)
  }

  useEffect(() => {
    if (permission === IPermission.ADMIN)
      dispatch(Creators.visitTechnicianRequest())
  }, [dispatch, permission])

  const handleChangeDates = (selectedDates: ISelectedDatesCalendar) => {
    setDate([selectedDates.dateInitial, selectedDates.dateFinal])
  }

  useEffect(() => {
    localStorage.setItem('sortData', '')
    localStorage.setItem('sortDirection', 'DESC')
  }, [])

  const getTitle = useCallback(
    (text: string, sort: string) => {
      const handleSort = () => {
        const sortData = localStorage.getItem('sortData')
        const sortDirection = localStorage.getItem('sortDirection')
        if (sortData === sort)
          localStorage.setItem(
            'sortDirection',
            sortDirection === 'ASC' ? 'DESC' : 'ASC',
          )
        else {
          localStorage.setItem('sortDirection', 'ASC')
          localStorage.setItem('sortData', sort)
        }

        const query: IVisitListQuery = {
          page,
          search,
          technicians,
          status,
          date,
          typeDocumentFilter,
          order: localStorage.getItem('sortData'),
          direction: localStorage.getItem('sortDirection'),
        }

        dispatch(Creators.visitListRequest(query))
      }

      return (
        <S.SortColumn onClick={handleSort}>
          {text} <UnfoldMore />
        </S.SortColumn>
      )
    },
    [dispatch, date, page, search, status, technicians, typeDocumentFilter],
  )

  const getColumns = () => {
    const newColumns = [
      {
        title: getTitle('Cliente', 'client'),
        dataIndex: 'client',
        key: `client`,
        width: 150,
      },
      {
        title: getTitle('Franqueado', 'franchisee'),
        dataIndex: 'franchisee',
        key: 'franchisee',
        width: 60,
      },
      {
        title: getTitle('Agendamento feito em', 'scheduled_maked_date'),
        dataIndex: 'scheduled_maked_in',
        key: `scheduled_maked_in`,
        width: 100,
      },
      {
        title: getTitle('Agendado para', 'date'),
        dataIndex: 'date',
        key: `date`,
        width: 100,
      },
      {
        title: getTitle('Cidade/UF', 'city'),
        dataIndex: 'city',
        key: `city`,
        width: 100,
      },
      {
        title: 'Telefone',
        dataIndex: 'phone',
        key: `phone`,
        width: 100,
      },
      {
        title: getTitle('Técnico', 'technician'),
        dataIndex: 'technician',
        key: `technician`,
        width: 100,
      },
      {
        title: getTitle('Whirlpool', 'hasPartnership'),
        dataIndex: 'hasPartnership',
        key: 'hasPartnership',
        width: 33,
      },
      {
        title: getTitle('Tipo', 'subscription_lending'),
        dataIndex: 'subscription_lending',
        key: 'subscription_lending',
        width: 33,
      },
      {
        title: 'Status',
        dataIndex: 'status',
        key: `status`,
        width: 100,
      },
      {
        title: '',
        dataIndex: 'edit',
        key: `edit`,
        width: 20,
      },
    ]

    return newColumns
  }

  return (
    <PageContent title="Visita Técnica">
      {isLoading && <FullScreenLoader />}
      <S.Container>
        <S.Header>
          <div>
            <SearchInput onChange={handleOnChangeInput} />
          </div>
          {!hasPermission && (
            <MultSelect
              isOpen={isOpenFilterTechnician}
              labelButton="Técnico"
              onClose={() => setIsOpenFilterTechnician(false)}
              onClick={() => setIsOpenFilterTechnician(!isOpenFilterTechnician)}
              items={techniciansFilterData}
              onChange={handleChangeFilterTechnician}
              isMultiSelect
              hasSearch
            />
          )}

          <MultSelect
            isOpen={isOpenFilterStatus}
            labelButton="Status"
            onClose={() => setIsOpenFilterStatus(false)}
            onClick={() => setIsOpenFilterStatus(!isOpenFilterStatus)}
            items={statusFilterData}
            onChange={handleChangeFilterStatus}
            isMultiSelect
          />
          <Calendar onChange={handleChangeDates} />
          {/* </S.ContainerTypeButton>
            <ButtonSelect
              onClick={handleClick}
              isCheckedPF={typeDocumentFilter === 'CPF'}
              isCheckedPJ={typeDocumentFilter === 'CNPJ'}
            />
          </S.ContainerTypeButton> */}
        </S.Header>
        <S.ContainerTable>
          <Table columns={getColumns()} data={visitsHandled} hovered />
        </S.ContainerTable>
        <S.ContainerPagination>
          <Pagination
            count={totalPages}
            size="large"
            shape="rounded"
            page={page}
            onChange={handleChangePage}
            variant="outlined"
          />
        </S.ContainerPagination>
      </S.Container>
    </PageContent>
  )
}

export { Visits }
