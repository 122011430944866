import React, { ReactNode } from 'react'

import {
  Container,
  NumberContainer,
  TitleContainer,
  ContentContainer,
} from './styles'

interface IPropsStepDetailTitle {
  number: string
  title: string
  children: ReactNode
}
const StepDetailTitle: React.FC<IPropsStepDetailTitle> = ({
  number,
  title,
  children,
}) => {
  return (
    <Container>
      <TitleContainer>
        <NumberContainer>
          <span>{number}</span>
        </NumberContainer>
        <h1>{title}</h1>
      </TitleContainer>
      <ContentContainer>{children}</ContentContainer>
    </Container>
  )
}

export { StepDetailTitle }
