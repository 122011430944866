import produce from 'immer'
import { createActions, createReducer } from 'reduxsauce'

import {
  SystemConfigActionEnum,
  ISystemConfigInitialState,
  IActionTypes,
  ISystemConfigData,
} from './types'

type SystemConfigType = typeof SystemConfigActionEnum

// Initial state
export const INITIAL_STATE: ISystemConfigInitialState = {
  systemConfigs: [],
  isLoading: false,
}

// Create actions
export const {
  Types: SystemConfigTypes,
  Creators: SystemConfigCreators,
} = createActions<SystemConfigType, IActionTypes>({
  getSystemConfigsRequest: {},
  getSystemConfigsSuccess: ['systemConfigs'],
  getSystemConfigsFailure: {},
  updateSystemConfigRequest: { systemConfig: {} },
  updateSystemConfigSuccess: ['systemConfigPayload'],
  updateSystemConfigFailure: {},
})

// Reducers
export const getSystemConfigsRequest = (state = INITIAL_STATE) =>
  produce(state, () => ({
    ...state,
    isLoading: true,
  }))

export const getSystemConfigsSuccess = (
  state: ISystemConfigInitialState = INITIAL_STATE,
  { systemConfigs }: { systemConfigs: ISystemConfigData[] },
) =>
  produce(state, () => ({
    ...state,
    systemConfigs,
    isLoading: false,
  }))

export const getSystemConfigsFailure = (state = INITIAL_STATE) =>
  produce(state, () => ({
    ...state,
    isLoading: false,
  }))

export const updateSystemConfigRequest = (state = INITIAL_STATE) =>
  produce(state, () => ({
    ...state,
    isLoading: true,
  }))

export const updateSystemConfigSuccess = (
  state: ISystemConfigInitialState = INITIAL_STATE,
  { systemConfigPayload }: { systemConfigPayload: ISystemConfigData },
) =>
  produce(state, () => ({
    ...state,
    systemConfigs: state.systemConfigs.map(systemConfig =>
      systemConfig.type === systemConfigPayload.type
        ? systemConfigPayload
        : systemConfig,
    ),
    isLoading: false,
  }))

export const updateSystemConfigFailure = (state = INITIAL_STATE) =>
  produce(state, () => ({
    ...state,
    isLoading: false,
  }))

// Hookup Reducers To Types
export const reducer = createReducer<ISystemConfigInitialState, IActionTypes>(
  INITIAL_STATE,
  {
    [SystemConfigTypes.GET_SYSTEM_CONFIGS_REQUEST]: getSystemConfigsRequest,
    [SystemConfigTypes.GET_SYSTEM_CONFIGS_SUCCESS]: getSystemConfigsSuccess,
    [SystemConfigTypes.GET_SYSTEM_CONFIGS_REQUEST]: getSystemConfigsFailure,
    [SystemConfigTypes.UPDATE_SYSTEM_CONFIG_REQUEST]: updateSystemConfigRequest,
    [SystemConfigTypes.UPDATE_SYSTEM_CONFIG_SUCCESS]: updateSystemConfigSuccess,
    [SystemConfigTypes.UPDATE_SYSTEM_CONFIG_FAILURE]: updateSystemConfigFailure,
  },
)
