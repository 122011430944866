import styled from 'styled-components'

import theme from '@/assets/styles/theme'

export const Container = styled.div``

export const ContainerButton = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: auto auto;
  column-gap: 1rem;
  justify-content: end;
  padding: 1rem 0 25px;

  @media only screen and (max-width: 750px) {
    display: flex;
    flex-direction: column-reverse;
    row-gap: 1rem;
  }
`
export const Header = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 25px;

  @media only screen and (max-width: ${theme.breakpoints.sm}) {
    justify-content: space-between;
  }

  & > h1 {
    font-size: 22px;
    font-weight: 600;
    color: ${theme.colors.gray[600]};
  }
`

export const EmptyImage = styled.div`
  padding: 20px;
`
