import React, {
  ChangeEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'

import Arrow from '@/assets/images/icons/sort-arrows-couple-pointing-up-and-down.svg'
import { TextField } from '@material-ui/core'

import * as S from './styles'

interface IProps {
  onClick: () => void
  onClose: () => void
  isOpen: boolean
  labelButton: string
  items: IItem[]
  onChange: (items: IItem[]) => void
  hasItemAll?: boolean
  disabled?: boolean
  width?: string
  isMultiSelect?: boolean
  minWidth?: string
  hasSearch?: boolean
}

export interface IItem {
  id: string
  label: string | React.ReactNode
}

const MultSelect: React.FC<IProps> = ({
  onClick,
  isOpen,
  labelButton,
  items,
  onClose,
  onChange,
  hasItemAll,
  disabled,
  width,
  isMultiSelect = false,
  minWidth,
  hasSearch = false,
}) => {
  const [selectedItems, setSelectedItems] = useState<IItem[]>([])
  const [filteredItems, setFilteredItems] = useState<IItem[]>(items)

  const ref = useRef<HTMLHeadingElement>(null)

  const handleKeypressEsc = useCallback(
    e => {
      if (e.key === 'Escape' && isOpen) onClose()
    },
    [isOpen, onClose],
  )

  const handleClickOut = useCallback(
    e => {
      if (!ref.current?.contains(e.target) && isOpen) {
        onClose()
      }
    },
    [isOpen, onClose],
  )

  useEffect(() => {
    window.addEventListener('click', handleClickOut)
    window.addEventListener('keydown', handleKeypressEsc)

    return () => {
      window.removeEventListener('click', handleClickOut)
      window.removeEventListener('keydown', handleKeypressEsc)
    }
  }, [handleKeypressEsc, handleClickOut])

  const handleOnClick = (item: IItem) => {
    const isExists =
      selectedItems.findIndex(selectedItem => {
        return selectedItem.id === item.id
      }) !== -1
    if (isMultiSelect) {
      if (item.id === 'all') {
        !isExists
          ? setSelectedItems([item])
          : setSelectedItems(
              selectedItems.filter(selectedItem => {
                return selectedItem.id !== item.id
              }),
            )
      } else {
        const isSelectedAll =
          selectedItems.findIndex(selectedItem => {
            return selectedItem.id === 'all'
          }) !== -1

        if (isSelectedAll) {
          setSelectedItems([item])
        } else {
          !isExists
            ? setSelectedItems([...selectedItems, item])
            : setSelectedItems(
                selectedItems.filter(selectedItem => {
                  return selectedItem.id !== item.id
                }),
              )
        }
      }
    } else {
      !isExists ? setSelectedItems([item]) : setSelectedItems([])
    }
  }

  useEffect(() => {
    onChange(selectedItems)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedItems])

  const filterItems = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setFilteredItems(
        e.target?.value.length === 0
          ? items
          : items.filter(item =>
              (item.label as string)
                .toLowerCase()
                .includes(e.target?.value.toLowerCase()),
            ),
      )
    },
    [items],
  )

  return (
    <>
      <S.ContainerMultSelect>
        <S.ContainerButton width={width || '120px'}>
          <S.ButtonCheck disabled={disabled} onClick={onClick}>
            {labelButton}
            <S.ArrowImage src={Arrow} alt="" />
          </S.ButtonCheck>
        </S.ContainerButton>

        <S.Container minWidth={minWidth || '200px'} ref={ref} isOpen={isOpen}>
          {hasSearch && (
            <S.SearchBoxWrapper>
              <TextField
                id="search-input"
                label="Pesquisar"
                variant="outlined"
                size="small"
                onChange={filterItems}
              />
            </S.SearchBoxWrapper>
          )}
          <S.ContainerCheckBox>
            {hasItemAll && (
              <div key="all">
                <S.CheckBox
                  checked={
                    selectedItems.findIndex(
                      selectedItem => selectedItem.id === 'all',
                    ) !== -1
                  }
                  onClick={() => handleOnClick({ id: 'all', label: 'todos' })}
                  label="Todos"
                />
              </div>
            )}
            {filteredItems.map(item => (
              <div key={item.id}>
                <S.CheckBox
                  checked={
                    selectedItems.findIndex(
                      selectedItem => selectedItem.id === item.id,
                    ) !== -1
                  }
                  onClick={() =>
                    handleOnClick({ id: item.id, label: item.label })
                  }
                  label={item.label}
                />
              </div>
            ))}
          </S.ContainerCheckBox>
        </S.Container>
      </S.ContainerMultSelect>
    </>
  )
}

export { MultSelect }
