import React from 'react'
import ReactSpeedometer, { Transition } from 'react-d3-speedometer'

import { SectionTitle } from '../styles'

interface IScoreChart {
  value?: number
}

const ScoreChart: React.FC<IScoreChart> = ({ value = 300 }) => {
  return (
    <div style={{ width: '30%' }}>
      <SectionTitle>Score</SectionTitle>
      <ReactSpeedometer
        minValue={0}
        segments={3}
        customSegmentStops={[0, 300, 500, 1000]}
        segmentColors={['#FD4332', '#F5BC42', '#00B04F']}
        valueTextFontSize="22px"
        needleTransitionDuration={5000}
        needleTransition={Transition.easeElastic}
        ringWidth={60}
        needleHeightRatio={0.7}
        value={value}
      />
    </div>
  )
}

export default ScoreChart
