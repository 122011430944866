import React, { useState, useRef, useCallback } from 'react'
import { Link } from 'react-router-dom'
import { toast } from 'react-toastify'

import * as Yup from 'yup'

import SignInLogo from '@/assets/images/sign-in-logo.png'
import Button from '@/components/Ui/Button'
import Input from '@/components/Ui/Form/Input'
import api from '@/services/api'
import history from '@/services/history'
import { FormHandles } from '@unform/core'
import { Form } from '@unform/web'

import { Container, Logo, Title, Subtitle, Warp, Forgot } from './styles'

interface SignInFormData {
  email: string
  password: string
}

interface IClient {
  id: string
  section: string
}

const ForgetPassword: React.FC = () => {
  const formRef = useRef<FormHandles>(null)

  const getQueryVariable = (search: string) => {
    const query = window.location.search.substring(1)
    const vars = query.split('&')
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < vars.length; i++) {
      const pair = vars[i].split('=')
      if (decodeURIComponent(pair[0]) === search) {
        return decodeURIComponent(pair[1])
      }
    }
    return null
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [hasToken, setHasToken] = useState<boolean>(false)

  React.useEffect(() => {
    const token = getQueryVariable('token')
    if (token) {
      setHasToken(true)
      // call function to validate code
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.location.search])

  const [loading, setLoading] = useState(false)

  const recoveryPwd = async () => {
    setLoading(true)

    api
      .post(`/admin/recovery-password?token=${getQueryVariable('token')}`, {
        password: formRef.current?.getFieldValue('password'),
        confirm_password: formRef.current?.getFieldValue('confirm_password'),
      })
      .then(res => {
        // eslint-disable-next-line no-console
        // console.log(res.data)

        if (res.data?.code === 101) {
          toast.success('Senha recriada com sucesso')
          history.push('/')
        } else {
          toast.error('Erro ao enviar email de recuperação')
        }
      })
      .catch(() => toast.error('Senha fraca ou token expirado'))
      .finally(() => setLoading(false))
  }
  const handleSubmit = useCallback(async (data: SignInFormData) => {
    try {
      setLoading(true)

      const schema = Yup.object().shape({
        email: Yup.string()
          .email('Digite um e-mail válido')
          .required('O email é obrigatório'),
      })

      await schema.validate(data, {
        abortEarly: false,
      })

      const request = await api.post('/admin/forgot-password', {
        email: formRef.current?.getFieldValue('email'),
      })

      // eslint-disable-next-line no-console
      // console.log(request.data)

      if (request.data?.code === 100) {
        toast.success('Email de recuperação enviado')
      } else {
        toast.error('Erro ao enviar email de recuperação')
      }
      setLoading(false)

      // TODO: implement integration with api
    } catch (err) {
      setLoading(false)
      // eslint-disable-next-line no-console
      // console.log('err =>', err)

      const validationErrors: { [key: string]: string } = {}

      if (err instanceof Yup.ValidationError) {
        err.inner.forEach((error: Yup.ValidationError) => {
          validationErrors[error.path] = error.message
        })

        formRef.current?.setErrors(validationErrors)
      }
    }
  }, [])
  return (
    <Container>
      <Logo>
        <img src={SignInLogo} alt="Solar21" />
      </Logo>
      <Title>Recuperar senha</Title>
      {hasToken ? (
        <>
          <Subtitle>Para continuar, insira sua nova senha:</Subtitle>
          <Warp>
            <Form ref={formRef} onSubmit={recoveryPwd}>
              <Input
                name="password"
                type="password"
                autoComplete="new-password"
                placeholder="Digite sua nova senha"
                label="Senha"
              />
              <Input
                name="confirm_password"
                type="password"
                autoComplete="new-password"
                placeholder="Confirme sua nova senha"
                label="Confirmação de Senha"
              />
              <Button
                color="primary"
                size="big"
                full
                type="submit"
                loading={loading}
              >
                Redefinir senha
              </Button>
            </Form>
          </Warp>
        </>
      ) : (
        <>
          <Subtitle>
            Para recuperar sua senha, digite seu email de cadastro:
          </Subtitle>
          <Warp>
            <Form ref={formRef} onSubmit={handleSubmit}>
              <Input
                name="email"
                type="text"
                placeholder="Digite seu email"
                label="Email"
              />
              <Button
                color="primary"
                size="big"
                full
                type="submit"
                loading={loading}
              >
                Recuperar senha
              </Button>
            </Form>
          </Warp>
        </>
      )}
      <Forgot>
        <Link to="/">Voltar</Link>
      </Forgot>
    </Container>
  )
}

export default ForgetPassword
