/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-extra-semi */
import React, { useCallback, useState, useEffect } from 'react'
import InputMask from 'react-input-mask'
import { useDispatch, useSelector } from 'react-redux'
import { toast, ToastContent } from 'react-toastify'

import * as Yup from 'yup'

import { FullScreenLoader } from '@/components/FullScreenLoader'
import Button from '@/components/Ui/Button'
import Input from '@/components/Ui/Form/Input'
import { Select } from '@/components/Ui/Select'
import { useWindowSize } from '@/hooks/useWindowSize'
import { RootState } from '@/store'
import { CitiesCreators } from '@/store/ducks/Cities'
import { Creators } from '@/store/ducks/Clients'
import { IUploadClientPayload } from '@/store/ducks/Clients/types'
import { CommonCreators } from '@/store/ducks/common'
import { Form } from '@unform/web'

import * as S from './styles'

interface IFormData {
  id: string
  name: string
  email: string
  phone: string
  state: string
  city: string
  address: string
  addressComplement: string
  addressNeighborhood: string
  addressNumber: string
  postalCode: string
  clientTypeValue: string
}

const ClientDetailsEdit: React.FC = () => {
  const client = useSelector((state: RootState) => state.Client.client)
  const stateList = useSelector((state: RootState) => state.Common.data)
  const cityList = useSelector((state: RootState) => state.Cities.cities.items)
  const isLoading = useSelector((state: RootState) => state.Client.isLoading)
  const dispatch = useDispatch()

  const [selectedCity, setSelectedCity] = useState(client.city.id)
  const [selectedState, setSelectedState] = useState(client.city.state)

  const { width } = useWindowSize()

  const clientType = client.type.type

  const handleSubmit = useCallback(
    async (client: IFormData) => {
      try {
        const clientData: IUploadClientPayload = {
          id: client.id,
          name: client.name,
          email: client.email,
          city: {
            state: selectedState,
            name: client.city,
            id: selectedCity,
          },
          phone: client.phone.replace(/\D/g, ''),
          address: client.address,
          address_complement: client.addressComplement,
          address_neighborhood: client.addressNeighborhood,
          address_number: client.addressNumber,
          postal_code: client.postalCode.replace(/\D/g, ''),
          type: {
            type: clientType,
            value: client.clientTypeValue.replace(/\D/g, ''),
          },
        }

        const userSchema = Yup.object().shape({
          name: Yup.string().required('Nome obrigatório'),
          email: Yup.string()
            .email('Email invalido')
            .required('Email obrigatório'),
          phone: Yup.string().required('Telefone obrigatório'),
          postal_code: Yup.string()
            .required('CEP obrigatório')
            .min(8, 'CEP inválido'),
          address: Yup.string().required('Endereço obrigatório'),
          address_number: Yup.string().required('Número obrigatório'),
          address_neighborhood: Yup.string().required('Bairro obrigatório'),
          city: Yup.string().required('Cidade obrigatório'),
        })

        await userSchema.validate(clientData, {
          abortEarly: false,
        })

        clientData.phone = `55${clientData.phone}`

        dispatch(Creators.editClientRequest(clientData))
      } catch (err) {
        ;(err as any).errors.forEach((error: ToastContent) => {
          toast.error(error)
        })
      }
    },
    [clientType, dispatch, selectedCity, selectedState],
  )

  useEffect(() => {
    dispatch(CommonCreators.ufRequest())

    const query = {
      states: [selectedState],
      is_residential_available: true,
      is_commercial_available: false,
      limit: 1000,
    }
    dispatch(CitiesCreators.getCitiesRequest(query))
  }, [dispatch, selectedState])

  const handleChangeCity = (city: any) => {
    setSelectedCity(city.value)
  }

  const handleChangeState = (state: any) => {
    setSelectedState(state.value)
  }

  const cities = cityList.map(city => {
    return {
      value: city.id,
      label: city.name,
    }
  })

  const states = stateList?.map(state => {
    return {
      value: state.sigla,
      label: state.nome,
    }
  })

  return (
    <S.Container>
      <Form
        onSubmit={handleSubmit}
        initialData={{
          name: client.name,
          clientTypeValue: client.type.value,
          email: client.email,
          phone: client.phone.substring(2),
          postalCode: client.postal_code,
          address: client.address,
          addressNumber: client.address_number,
          addressComplement: client.address_complement,
          addressNeighborhood: client.address_neighborhood,
          state: client.city.state,
          city: client.city.name,
        }}
      >
        {isLoading && <FullScreenLoader />}
        <S.ContainerForm>
          <S.FormLineInput>
            <S.ContainerInput>
              <Input name="name" label="Nome completo *" width="100%" />
            </S.ContainerInput>
            <S.ContainerInput>
              <InputMask mask="999.999.999-99" defaultValue={client.type.value}>
                {() => (
                  <Input
                    name="clientTypeValue"
                    label={`${clientType} *`}
                    width="100%"
                    placeholder="Digite o número do documento"
                  />
                )}
              </InputMask>
            </S.ContainerInput>
          </S.FormLineInput>
          <S.FormLineInput>
            <S.ContainerInput>
              <Input name="email" label="Email *" width="100%" />
            </S.ContainerInput>
            <S.ContainerInput>
              <InputMask
                mask="(99) 99999-9999"
                defaultValue={client.phone.substring(2)}
              >
                {() => (
                  <Input
                    name="phone"
                    label="Telefone *"
                    width="100%"
                    placeholder="Digite o telefone"
                  />
                )}
              </InputMask>
            </S.ContainerInput>
          </S.FormLineInput>
          <S.FormLineInput>
            <S.ContainerInput>
              <InputMask mask="99999-999" defaultValue={client.postal_code}>
                {() => (
                  <Input
                    name="postalCode"
                    label="CEP *"
                    width="100%"
                    placeholder="Digite o CEP"
                  />
                )}
              </InputMask>
            </S.ContainerInput>
            <S.ContainerInput>
              <Input name="address" label="Endereço *" width="100%" />
            </S.ContainerInput>
          </S.FormLineInput>
          <S.FormLineInput>
            <S.ContainerInput>
              <Input name="addressNumber" label="Número *" width="100%" />
            </S.ContainerInput>
            <S.ContainerInput>
              <Input
                name="addressComplement"
                label="Complemento"
                width="100%"
              />
            </S.ContainerInput>
          </S.FormLineInput>
          <S.FormLineInput>
            <S.ContainerInput>
              <Input name="addressNeighborhood" label="Bairro *" width="100%" />
            </S.ContainerInput>
            <S.SelectContainer>
              <Select
                label="Estado *"
                size="custom"
                options={states}
                value={states?.find(state => state.value === selectedState)}
                onChange={handleChangeState}
                isSearchable
              />
            </S.SelectContainer>
          </S.FormLineInput>
          <S.FormLineInput>
            <S.SelectContainer>
              <Select
                label="Cidade *"
                size="custom"
                options={cities}
                value={cities.find(city => city.value === selectedCity)}
                onChange={handleChangeCity}
                isSearchable
              />
            </S.SelectContainer>
          </S.FormLineInput>
        </S.ContainerForm>
        <S.ContainerButton>
          <Button
            full={!!width && width <= 1100}
            type="button"
            size="default"
            onClick={() => dispatch(Creators.setIsEditClientDetails(false))}
          >
            Voltar
          </Button>
          <Button
            full={!!width && width <= 1100}
            size="default"
            color="primary"
            type="submit"
          >
            Salvar alterações
          </Button>
        </S.ContainerButton>
      </Form>
    </S.Container>
  )
}

export { ClientDetailsEdit }
