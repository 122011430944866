import styled from 'styled-components'

interface ContainerProps {
  fontSize: string
  fontWeight: string
  padding: string
}

export const Container = styled.span<ContainerProps>`
  font-size: ${props => props.fontSize};
  font-weight: ${props => props.fontWeight};
  padding: ${props => props.padding};
`
