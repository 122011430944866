import React, { useState, useRef } from 'react'
import { GoTrashcan } from 'react-icons/go'
import { MdVisibility, MdFileDownload } from 'react-icons/md'

import theme from '@/assets/styles/theme'

import {
  Container,
  ContainerDocument,
  ContainerUpload,
  Icon,
  IconDocument,
  UploadList,
  ContainerOpacity,
  IconButton,
  IconUpload,
} from './styles'

export interface IFile {
  id: string
  type?: string
  url: string
}

interface IPropsDelete {
  onDeleteFile: (file: IFile) => void
  fileList: IFile[]
  onUploadFile: (file: File | null) => void
  // eslint-disable-next-line react/require-default-props
  isDisabled?: boolean
}

const ManagePdfs: React.FC<IPropsDelete> = ({
  onDeleteFile,
  fileList,
  onUploadFile,
  isDisabled,
}) => {
  const ref = useRef<HTMLInputElement>(null)
  const uploadImageClick = (): void => {
    ref.current?.click()
  }

  const newFileList = fileList.map(file => {
    return {
      id: file.id,
      url: file.url,
      type: 'image',
    }
  })

  const obj: { [x: string]: boolean } = {}

  newFileList.forEach((file: IFile) => {
    obj[file.id] = false
  })

  const [hover, setHover] = useState(obj)

  const setItemHover = (id: string, isHover: boolean): void => {
    setHover(prevState => {
      prevState[id] = isHover
      return { ...prevState }
    })
  }

  const getFilename = (url: string): string => {
    return url.split('/')[url.split('/').length - 1]
  }

  const download = (url: string) => {
    fetch(url, {
      method: 'GET',
      headers: {},
    }).then(response => {
      response.arrayBuffer().then(buffer => {
        const newUrl = window.URL.createObjectURL(new Blob([buffer]))
        const link = document.createElement('a')
        link.href = newUrl
        link.setAttribute('download', getFilename(url))
        document.body.appendChild(link)
        link.click()
      })
    })
  }

  const imageContentWithOpacity = (file: IFile) => {
    return (
      <>
        {isDisabled ? (
          <ContainerOpacity isHover={hover[file.id]}>
            <a href={file.url} rel="noopener noreferrer" target="_blank">
              <IconButton>
                <MdVisibility color={theme.colors.black} />
              </IconButton>
            </a>
            <IconButton onClick={() => download(file.url)}>
              <MdFileDownload color={theme.colors.black} />
            </IconButton>
          </ContainerOpacity>
        ) : (
          <ContainerOpacity isHover={hover[file.id]}>
            <a href={file.url} rel="noopener noreferrer" target="_blank">
              <IconButton>
                <MdVisibility color={theme.colors.black} />
              </IconButton>
            </a>
            <IconButton onClick={() => download(file.url)}>
              <MdFileDownload color={theme.colors.black} />
            </IconButton>
            <IconButton onClick={() => onDeleteFile(file)}>
              <GoTrashcan color={theme.colors.black} />
            </IconButton>
          </ContainerOpacity>
        )}
      </>
    )
  }

  return (
    <Container>
      {newFileList.map((file: IFile) => (
        <UploadList
          key={file.id}
          onMouseEnter={() => setItemHover(file.id, true)}
          onMouseLeave={() => setItemHover(file.id, false)}
        >
          <>
            {imageContentWithOpacity(file)}
            <ContainerDocument>
              {imageContentWithOpacity(file)}
              <IconDocument />
            </ContainerDocument>
          </>
        </UploadList>
      ))}
      {!isDisabled && (
        <>
          {newFileList.length < 3 && (
            <ContainerUpload onClick={() => uploadImageClick()}>
              <IconUpload
                accept="images/*"
                ref={ref}
                type="file"
                onChange={e =>
                  onUploadFile(e.target.files ? e.target.files[0] : null)
                }
                disabled={isDisabled}
              />
              <Icon />
            </ContainerUpload>
          )}
        </>
      )}
    </Container>
  )
}

export default ManagePdfs
