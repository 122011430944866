import React from 'react'
import { useParams } from 'react-router-dom'
import { toast } from 'react-toastify'

import moment from 'moment'

import { useState } from '@/hooks/useState'
import api from '@/services/api'
import { IActivityLog } from '@/store/ducks/Clients/types'

interface IClientDetailsState {
  loading: boolean
  logs?: IActivityLog[]
}

const ClientDetails: React.FC = () => {
  const { id: userId } = useParams<{ id: string }>()

  const { state, setState } = useState<IClientDetailsState>({
    loading: true,
    logs: [],
  })

  const dtFormat = 'DD/MM/YYYY HH[h]mm'

  const getLogs = () => {
    setState({ loading: true })
    api
      .get(`/admin/customers/${userId}/logs`)
      .then(({ data }) => {
        // eslint-disable-next-line no-console
        // console.log('log request response =>', data)
        if (data?.items?.length) setState({ logs: data.items })
      })
      // eslint-disable-next-line no-console
      .catch(() => toast.error('Erro ao recuperar a atividade do usuário'))
      .finally(() => setState({ loading: false }))
  }

  React.useEffect(() => {
    // eslint-disable-next-line no-console
    // console.log('AcitivityLog mounted!')
    getLogs()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (state.loading) return <p>Carregando</p>
  return (
    <div>
      {state.logs?.map(i => (
        <React.Fragment key={i.id}>
          <p>ID: {i.id}</p>
          <p>Data: {moment(i.created_at).format(dtFormat)}</p>
          <p style={{ marginBottom: 20 }}>Descrição: {i.description}</p>
        </React.Fragment>
      ))}

      {!state.logs?.length && <p>Sem resultados</p>}
    </div>
  )
}

export default ClientDetails
