import { IAddress } from './interfaces/addressInterface'
import { IViacepResponse } from './interfaces/viacepResponseInterface'

export const getAddressByPostalCode = async (
  postalCode: string,
): Promise<IAddress> => {
  const response: IViacepResponse = await fetch(
    `https://viacep.com.br/ws/${postalCode}/json`,
  ).then(data => data.json())

  return {
    address: response.logradouro,
    neighborhood: response.bairro,
    complement: response.complemento,
    city: response.localidade,
    state: response.uf,
  }
}
