import styled from 'styled-components'

import theme from '@/assets/styles/theme'

export const Container = styled.footer`
  background-color: ${theme.colors.gray[100]};
  align-items: center;
  display: flex;
  grid-area: footer;
  justify-content: flex-start;
  padding: 0 30px 30px;

  @media only screen and (max-width: ${theme.breakpoints.sm}) {
    font-size: 14px;
    justify-content: center;
  }
`
