/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useCallback, useEffect, useState } from 'react'
import { BsPencil } from 'react-icons/bs'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'

import { last } from 'lodash'
import moment from 'moment'

import { ReportStepStatusEnum } from '@/@types/clientReportSteps/clientReportSteps'
import { IPermission } from '@/@types/Permission'
import { TechnicalVisitStatusEnum } from '@/@types/visit'
import CoordinatesMap from '@/components/CoordinatesMap'
import { FullScreenLoader } from '@/components/FullScreenLoader'
import { ReportOne } from '@/components/ReportForm/ReportOne'
import { ReportThree } from '@/components/ReportForm/ReportThree'
import { ReportTwo } from '@/components/ReportForm/ReportTwo'
import { Table } from '@/components/Table/Table'
import Button from '@/components/Ui/Button/index'
import DateTimePicker from '@/components/Ui/DateTimePicker'
import { SplitButton } from '@/components/Ui/DropdownMui'
import Modal from '@/components/Ui/Modal'
import { ModalJustifyTechnical } from '@/components/Ui/ModalJustifyTechnical'
import StatusCard from '@/components/Ui/Status/StatusCard'
import Steps from '@/components/Ui/Steps'
import ToggleSwitch from '@/components/Ui/ToggleSwitch'
import { useState as useCustomState } from '@/hooks/useState'
import { getCurrentClientStepType } from '@/modules/Customer/utils/getCurrentClientStepType'
import {
  getTechnicalVisitStatusLendingStyle,
  getTechnicalVisitStatusStyle,
} from '@/modules/Customer/utils/getTechnicalVisitStyle'
import api from '@/services/api'
import history from '@/services/history'
import { RootState } from '@/store'
import { ReportCreators } from '@/store/ducks/ClientReport'
import { Creators as ClientCreators } from '@/store/ducks/Clients'
import { ClientStepTypeEnum } from '@/store/ducks/Clients/types'
import { Creators } from '@/store/ducks/Visit'

import { ProposalType } from '../Bill'
import { ReportFour } from './ReportFour'
import ScheduleCalendar from './ScheduleCalendar'
import * as S from './styles'

interface ITechnicalVisitState {
  showModalReschedule: boolean
  newScheduleDate: string | Date
  pending: boolean
}

const TechnicalVisit: React.FC = () => {
  const dispatch = useDispatch()

  const { state, setState } = useCustomState<ITechnicalVisitState>({
    showModalReschedule: false,
    newScheduleDate: new Date(),
    pending: false,
  })
  const [currentStatus, setCurrentStatus] = useState<TechnicalVisitStatusEnum>(
    TechnicalVisitStatusEnum.WAITING_FOR_TECHNICIAN,
  )
  const [isOpenModal, setIsOpenModal] = useState(false)
  const [justifyText, setJustifyText] = useState<string>('')
  const [openCoordinatesDialog, setOpenCoordinatesDialog] = useState(false)
  const [, setUpdatingCoords] = useState(false)
  const [enableSchedule, setEnableSchedule] = useState<boolean>(false)

  const client = useSelector((state: RootState) => state.Client.client)
  const technicalVisit = useSelector(
    (state: RootState) => state.Client.client.technical_visit,
  )
  const isEditReportThree = useSelector(
    (state: RootState) => state.ClientReport.isEditReportThree,
  )
  const permission = useSelector((state: RootState) => state.Auth.role)
  const isEditReportFour = useSelector(
    (state: RootState) => state.Visit.isEditReportFour,
  )
  const isEditReportTwo = useSelector(
    (state: RootState) => state.ClientReport.isEditReportTwo,
  )
  const isEditReportOne = useSelector(
    (state: RootState) => state.ClientReport.isEditReportOne,
  )
  const isLoading = useSelector(
    (state: RootState) => state.Visit.isLoading || state.ClientReport.isLoading,
  )
  const reportsStatus = useSelector(
    (state: RootState) => state.ClientReport.reports,
  )

  const [proposal, setProposal] = useState<ProposalType | undefined>()

  const dropdownItems = [
    {
      value: TechnicalVisitStatusEnum.FULFILLED,
      label: 'VISITA REALIZADA',
    },
    {
      value: TechnicalVisitStatusEnum.ACCOMPLISHED_AND_APPROVED,
      label:
        client.subscription_lending === 'SUBSCRIPTION'
          ? 'PROPOSTA APROVADA'
          : 'LIBERAR PROPOSTA',
    },
  ]

  useEffect(() => {
    if (client) {
      const { steps } = client
      const technicalVisitStatus = steps?.find(
        stp => stp.type === 'TECHNICAL_VISIT',
      )?.status

      setEnableSchedule(technicalVisitStatus === 'PENDING' && !technicalVisit)
    }
  }, [client, technicalVisit])

  useEffect(() => {
    if (!isEditReportFour) {
      dispatch(ReportCreators.getReportsRequest())
      dispatch(ClientCreators.getClientRequest({ id: client.id }))
    }
    // eslint-disable-next-line
  }, [client.id, isEditReportFour])

  useEffect(() => {
    if (technicalVisit) setCurrentStatus(technicalVisit.status)
  }, [technicalVisit])

  const ddd = technicalVisit?.phone.substr(0, 2)
  const phone = technicalVisit?.phone.substr(2, technicalVisit.phone.length)
  const phoneClient = client?.phone.substr(2, client.phone.length)
  const isFulfilled =
    technicalVisit?.status === TechnicalVisitStatusEnum.FULFILLED
  const hasPermission = permission === IPermission.TECHNICIAN

  const columns = [
    {
      title: 'Data',
      dataIndex: 'date',
      key: 'date',
      width: 100,
    },
    {
      title: 'Hora',
      dataIndex: 'hour',
      key: 'hour',
      width: 100,
    },
    {
      title: 'Telefone',
      dataIndex: 'phone',
      key: 'phone',
      width: 200,
    },
    {
      title: 'Endereço',
      dataIndex: 'address',
      key: 'address',
      width: 100,
    },
    {
      title: 'Bairro',
      dataIndex: 'neighborhood',
      key: 'neighborhood',
      width: 100,
    },
    {
      title: 'Número',
      dataIndex: 'number',
      key: 'number',
      width: 100,
    },
    {
      title: 'Cidade',
      dataIndex: 'city',
      key: 'city',
      width: 100,
    },
    {
      title: 'Estado',
      dataIndex: 'state',
      key: 'state',
      width: 100,
    },
    {
      title: 'Técnico',
      dataIndex: 'technician',
      key: 'technician',
      width: 100,
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      width: 200,
      render: () => {
        const handleOnChange = (status: any) => {
          setCurrentStatus(status)
          if (status === TechnicalVisitStatusEnum.DONE_AND_NOT_APPROVED)
            setIsOpenModal(true)
        }
        return (
          <S.StatusContainer>
            {!isFulfilled ? (
              <>
                {technicalVisit && (
                  <StatusCard
                    width="100%"
                    style={
                      client.subscription_lending === 'SUBSCRIPTION'
                        ? getTechnicalVisitStatusStyle(technicalVisit.status)
                        : getTechnicalVisitStatusLendingStyle(
                            technicalVisit.status,
                          )
                    }
                  />
                )}
              </>
            ) : (
              <SplitButton
                handleChange={handleOnChange}
                dropdownItems={dropdownItems}
              />
            )}
          </S.StatusContainer>
        )
      },
    },
  ]

  const clientData = [
    {
      date: moment(technicalVisit?.schedule).format('DD/MM/YYYY'),
      hour: moment(technicalVisit?.schedule).format('HH:mm'),
      phone: technicalVisit?.phone && `(${ddd}) ${phone}`,
      address: technicalVisit?.address,
      neighborhood: technicalVisit?.neighborhood,
      number: technicalVisit?.number,
      city: technicalVisit?.city,
      state: technicalVisit?.state,
      technician: technicalVisit?.technician?.fullname || 'Víncular Técnico',
      key: '1',
    },
  ]

  const reportStepStatus: {
    [key: string]: 'concluded' | 'pending'
  } = {
    APPROVED: 'concluded',
    PENDING: 'pending',
  }

  const reportOne = reportsStatus?.find(item => item.step === 1)
  const reportTwo = reportsStatus?.find(item => item.step === 2)
  const reportThree = reportsStatus?.find(item => item.step === 3)
  const reportFour = reportsStatus?.find(item => item.step === 4)

  const reports = () => {
    if (isEditReportOne) return <ReportOne isDisabled />
    if (isEditReportTwo) return <ReportTwo isDisabled clientId={client.id} />
    if (isEditReportThree) return <ReportThree isDisabled />
    return <ReportFour />
  }

  const clientStep = getCurrentClientStepType(client.steps)
  const isClientStepInstalation = clientStep === ClientStepTypeEnum.INSTALATION

  const handleChange = (text: any) => {
    setJustifyText(text)
  }

  const handleOnClose = () => {
    setIsOpenModal(false)
  }

  const handleClickToConfim = () => {
    setCurrentStatus(TechnicalVisitStatusEnum.DONE_AND_NOT_APPROVED)
    setIsOpenModal(false)
  }

  const handleSubmit = () => {
    try {
      const updateStatus = {
        status: currentStatus,
        justification_cancel: justifyText || undefined,
      }
      dispatch(Creators.updateTechnicianStatusRequest(updateStatus))
    } catch (error) {
      toast.error('Não foi possível editar a visita técnica')
    }
  }
  useEffect(() => {
    api
      .get(`admin/customers/${client.id}/proposal`)
      .then(({ data }) => {
        const filteredData = data.filter(
          (item: ProposalType) => item.type === 'FINAL',
        )
        setProposal(last(filteredData))
      })
      .catch(err => console.error('proposal retrieving did not work: ', err))
  }, [client])

  const updateCoordinates = (values: {
    lat: string | number
    lng: string | number
  }) => {
    // eslint-disable-next-line
    const coordinates = `${values.lat}, ${values.lng}`
    setUpdatingCoords(true)
    api
      .patch(
        `/admin/customers/scheduling/${client.technical_visit?.id}/coordinates`,
        {
          coordinates,
        },
      )
      .then(res => {
        if (res.data.id) {
          toast.success('Atualizado com sucesso')
          dispatch(ClientCreators.getClientRequest({ id: client.id }))
          setOpenCoordinatesDialog(false)
        }
      })
      .catch(() => toast.error('Erro ao salvar informações'))
      .finally(() => setUpdatingCoords(false))
  }

  const reschedule = () => {
    api
      .patch(`admin/customers/scheduling/${technicalVisit?.id}/reschedule`, {
        newDate: state.newScheduleDate,
        pending: state.pending,
      })
      .then(({ data }) => {
        // eslint-disable-next-line
        console.log('reschedule =>', data)
      })
  }

  const reprovalMessage = useCallback(
    (message: string, key: string) => (
      <S.ReprovalInfoContainer key={key}>
        <S.InfoIcon />
        <S.ReprovalInfo>{message}</S.ReprovalInfo>
      </S.ReprovalInfoContainer>
    ),
    [],
  )

  const getReprovalInfo = useCallback(
    technicalVisit => {
      const { justification_cancel } = technicalVisit
      if (!justification_cancel)
        return reprovalMessage(
          'Motivo da reprovação não especificado!',
          'single-message',
        )

      try {
        const reprovalOptions = JSON.parse(justification_cancel)

        let messages: string[] = []
        reprovalOptions.forEach((category: any) =>
          category.options.forEach((option: any) =>
            messages.push(option.selected ? option.option_label : null),
          ),
        )
        messages = messages.filter(message => message)

        return messages.map(message => reprovalMessage(message, message))
      } catch (error) {
        return reprovalMessage(justification_cancel, 'single-message')
      }
    },
    [reprovalMessage],
  )

  const handleSubmitCalendar = (form: any) => {
    form.phone = form.phone.replace(/[^\d]/g, '')
    form.cep = form.cep.replace(/[^\d]/g, '')

    api
      .post(`admin/customers/${client.id}/scheduling`, form)
      .then(() => toast.success('Visita técnica marcada com sucesso!'))
      .catch(err => console.error('Error scheduling technical visit: ', err))
      .finally(() => window.location.reload())
  }

  return (
    <S.Container>
      {isLoading && <FullScreenLoader />}
      {technicalVisit ? (
        <>
          <S.Header>
            <h1>Dados da Visita Técnica</h1>
          </S.Header>
          <S.ContainerClient>
            <S.ContainerColumn>
              <S.FieldsLabel>
                <S.Fields>
                  <S.Font>Cliente: </S.Font>
                  {client.name}
                </S.Fields>
              </S.FieldsLabel>
              <S.FieldsLabel>
                <S.Fields>
                  <S.Font>Telefone: </S.Font>
                  {technicalVisit.phone && `(${ddd}) ${phone}`}
                </S.Fields>
              </S.FieldsLabel>
              <S.FieldsLabel>
                <S.Fields>
                  <S.Font>Telefone 2: </S.Font>
                  {client.phone && `(${ddd}) ${phoneClient}`}
                </S.Fields>
              </S.FieldsLabel>
              <S.FieldsLabel>
                <S.Fields>
                  <S.Font>Data: </S.Font>
                  {moment(technicalVisit.schedule).format('DD/MM/YYYY')}
                </S.Fields>
              </S.FieldsLabel>
              <S.FieldsLabel>
                <S.Fields>
                  <S.Font>Hora: </S.Font>
                  {moment(technicalVisit.schedule).format('HH:mm')}
                </S.Fields>
              </S.FieldsLabel>
              <S.FieldsLabel>
                <S.Fields>
                  <S.Font>CEP: </S.Font>
                  {technicalVisit.cep}
                </S.Fields>
              </S.FieldsLabel>
            </S.ContainerColumn>
            <S.ContainerColumn>
              <S.FieldsLabel>
                <S.Fields>
                  <S.Font>Endereço: </S.Font>
                  {technicalVisit.address}
                </S.Fields>
              </S.FieldsLabel>
              <S.FieldsLabel>
                <S.Fields>
                  <S.Font>Bairro: </S.Font>
                  {technicalVisit.neighborhood}
                </S.Fields>
              </S.FieldsLabel>
              <S.FieldsLabel>
                <S.Fields>
                  <S.Font>Número: </S.Font>
                  {technicalVisit.number}
                </S.Fields>
              </S.FieldsLabel>
              <S.FieldsLabel>
                <S.Fields>
                  <S.Font>Complemento: </S.Font> {technicalVisit.complement}
                </S.Fields>
              </S.FieldsLabel>
              <S.FieldsLabel>
                <S.Fields>
                  <S.Font>Cidade: </S.Font> {technicalVisit.city}
                </S.Fields>
              </S.FieldsLabel>
              <S.FieldsLabel>
                <S.Fields>
                  <S.Font>Estado: </S.Font> {technicalVisit.state}
                </S.Fields>
              </S.FieldsLabel>
            </S.ContainerColumn>
            <S.ContainerColumn>
              <S.FieldsLabel>
                <S.Fields>
                  <S.Font>Técnico: </S.Font>
                  {technicalVisit.technician?.fullname || 'Víncular Técnico'}
                </S.Fields>
              </S.FieldsLabel>
              {!!technicalVisit.suggested_new_schedule_date && (
                <S.FieldsLabel>
                  <S.Fields>
                    <S.Font>Nova data sugerida: </S.Font>
                    {moment(technicalVisit.suggested_new_schedule_date).format(
                      'DD/MM/YYYY HH:mm',
                    )}
                  </S.Fields>
                </S.FieldsLabel>
              )}

              <S.FieldsLabel>
                <S.Fields>
                  <S.Font>Coordenadas:</S.Font>
                  {technicalVisit?.coordinates ? (
                    <a
                      href={`https://maps.google.com/?q=${technicalVisit?.coordinates}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {technicalVisit?.coordinates}
                    </a>
                  ) : (
                    '-'
                  )}
                  <BsPencil
                    size={19}
                    onClick={() => setOpenCoordinatesDialog(true)}
                    style={{ marginLeft: '10px', cursor: 'pointer' }}
                  />
                </S.Fields>
              </S.FieldsLabel>
            </S.ContainerColumn>
            <CoordinatesMap
              onSubmit={updateCoordinates}
              defaultCoords={
                technicalVisit?.coordinates
                  ? {
                      lat: parseFloat(
                        technicalVisit?.coordinates.split(', ')[0],
                      ),
                      lng: parseFloat(
                        technicalVisit?.coordinates.split(', ')[1],
                      ),
                    }
                  : undefined
              }
              onCloseDialog={() => setOpenCoordinatesDialog(false)}
              opened={openCoordinatesDialog}
            />
          </S.ContainerClient>

          {isEditReportFour ||
          isEditReportOne ||
          isEditReportTwo ||
          isEditReportThree ? (
            reports()
          ) : (
            <>
              <S.ContainerTable>
                <Table columns={columns} data={clientData} />
              </S.ContainerTable>

              {technicalVisit.status === 'DONE_AND_NOT_APPROVED' && (
                <S.ReprovalContainer>
                  <S.ReprovalHeader>Motivo reprovação</S.ReprovalHeader>
                  <S.ReprovalBackground>
                    {getReprovalInfo(technicalVisit)}
                  </S.ReprovalBackground>
                </S.ReprovalContainer>
              )}

              <S.ContainerReport>
                <h1>Relatórios</h1>
                <S.ContainerSteps>
                  <div>
                    <Steps
                      descriptionSteps="Relatório Fotográfico"
                      titleSteps="Etapa 1"
                      type={
                        reportStepStatus[
                          reportOne?.status || ReportStepStatusEnum.PENDING
                        ]
                      }
                      onClick={() =>
                        dispatch(ReportCreators.setIsReportOne(true))
                      }
                    />
                  </div>
                  <div>
                    <Steps
                      descriptionSteps="Relatório Descritivo"
                      titleSteps="Etapa 2"
                      type={
                        reportStepStatus[
                          reportTwo?.status || ReportStepStatusEnum.PENDING
                        ]
                      }
                      onClick={() =>
                        dispatch(
                          ReportCreators.setIsEditReportTwoTechnician(true),
                        )
                      }
                    />
                  </div>
                  <div>
                    <Steps
                      descriptionSteps="Aprovação"
                      titleSteps="Etapa 3"
                      type={
                        reportStepStatus[
                          reportThree?.status || ReportStepStatusEnum.PENDING
                        ]
                      }
                      onClick={() =>
                        dispatch(ReportCreators.setIsReportThree(true))
                      }
                    />
                  </div>
                  {!hasPermission && (
                    <div>
                      <Steps
                        descriptionSteps={
                          client.subscription_lending === 'SUBSCRIPTION'
                            ? 'Condições do plano'
                            : 'Gerar contrato definitivo'
                        }
                        titleSteps="Etapa 4"
                        type={
                          reportStepStatus[
                            // eslint-disable-next-line no-nested-ternary
                            client.subscription_lending === 'SUBSCRIPTION'
                              ? reportFour?.status ||
                                ReportStepStatusEnum.PENDING
                              : proposal
                              ? ReportStepStatusEnum.APPROVED
                              : ReportStepStatusEnum.PENDING
                          ]
                        }
                        onClick={() => dispatch(Creators.setIsReportFour(true))}
                      />
                    </div>
                  )}
                </S.ContainerSteps>
              </S.ContainerReport>
              <S.GroupButton>
                {isClientStepInstalation && (
                  <Button
                    full
                    type="button"
                    loading={false}
                    onClick={() =>
                      dispatch(ClientCreators.confirmInstalationRequest())
                    }
                  >
                    Confirmar instalação
                  </Button>
                )}
                <Button
                  full
                  type="button"
                  loading={false}
                  onClick={() => setState({ showModalReschedule: true })}
                  disabled={
                    technicalVisit.status === 'ACCOMPLISHED_AND_APPROVED'
                  }
                >
                  Reagendar visita
                </Button>

                <Button
                  full
                  size="default"
                  type="button"
                  onClick={() => history.push('/customer')}
                >
                  Voltar
                </Button>
                <Button
                  full
                  color="primary"
                  size="default"
                  onClick={handleSubmit}
                >
                  Salvar Alterações
                </Button>
              </S.GroupButton>
            </>
          )}
          <>
            <ModalJustifyTechnical
              openModal={isOpenModal}
              onClickConfirm={handleClickToConfim}
              onClose={handleOnClose}
              onChange={handleChange}
              label="Informe a justificativa do cancelamento"
            />
            {state.showModalReschedule && (
              <Modal
                openModal={state.showModalReschedule}
                onClose={() => setState({ showModalReschedule: false })}
              >
                <div
                  style={{
                    marginTop: 10,
                    marginBottom: 0,
                    padding: 20,
                  }}
                >
                  <h2
                    style={{
                      marginBottom: 10,
                      fontWeight: 'bold',
                      fontSize: 26,
                    }}
                    className="text-center"
                  >
                    Reagendar visita
                  </h2>

                  <DateTimePicker
                    value={state.newScheduleDate}
                    onChange={newScheduleDate => setState({ newScheduleDate })}
                    label="Escolha a nova data"
                  />

                  <ToggleSwitch
                    leftLabel="Reagendar"
                    rightLabel="Enviar sugestão para cliente"
                    onChange={pending => setState({ pending })}
                    checked={state.pending}
                  />

                  <div
                    style={{
                      display: 'inline-flex',
                      justifyContent: 'space-between',
                      width: '100%',
                      marginTop: 10,
                    }}
                  >
                    <Button
                      onClick={() => setState({ showModalReschedule: false })}
                    >
                      Cancelar
                    </Button>
                    <Button onClick={reschedule}>Confirmar</Button>
                  </div>
                </div>
              </Modal>
            )}
          </>
        </>
      ) : (
        <div>
          <h1>Ainda não houve uma visita técnica.</h1>
          {enableSchedule && (
            <ScheduleCalendar onSubmit={handleSubmitCalendar} />
          )}
        </div>
      )}
    </S.Container>
  )
}

export { TechnicalVisit }
