import React, { useEffect, useCallback, useState } from 'react'
import { FiArrowRightCircle } from 'react-icons/fi'
import ReactLoading from 'react-loading'
import { useDispatch, useSelector } from 'react-redux'
import { NavLink } from 'react-router-dom'

import moment from 'moment'

import theme from '@/assets/styles/theme'
import { RootState } from '@/store'
import { NotificationCreators } from '@/store/ducks/Notification'
import { INotificationItem } from '@/store/ducks/Notification/types'

import { NotificationItem } from './NotificationItem'
import { Container, Header, Content, List, Footer } from './styles'

interface INotificationProps {
  isOpen: boolean
  onClose: () => void
}

const Notification = ({ isOpen, onClose }: INotificationProps) => {
  const dispatch = useDispatch()

  const [unreadNotifications, setUnreadNotifications] = useState<
    INotificationItem[]
  >([])

  const unreadNotificationItems = useSelector(
    (state: RootState) => state.Notification.unreadNotification.items,
  )
  const isLoading = useSelector(
    (state: RootState) => state.Notification.isUnreadNotificationLoading,
  )

  const handleKeyAscPress = useCallback(
    e => {
      if (e.key === 'Escape' && isOpen) onClose()
    },
    [isOpen, onClose],
  )

  useEffect(() => {
    window.addEventListener('keydown', handleKeyAscPress)

    return () => {
      window.removeEventListener('keydown', handleKeyAscPress)
    }
  }, [handleKeyAscPress])

  useEffect(() => {
    dispatch(NotificationCreators.getUnreadNotificationsRequest())
  }, [dispatch])

  useEffect(() => {
    setUnreadNotifications(unreadNotificationItems)
  }, [unreadNotificationItems])

  return (
    <>
      {isOpen && (
        <Container>
          <Header>
            <h2>Notificações</h2>
          </Header>

          <Content>
            {isLoading ? (
              <ReactLoading
                type="spin"
                width="50px"
                height="50px"
                color={theme.colors.primary}
              />
            ) : (
              <List>
                {unreadNotifications.length
                  ? unreadNotifications.map(unreadNotification => (
                      <NotificationItem
                        key={unreadNotification.created_at.toString()}
                        clientName={unreadNotification.client.name}
                        message={unreadNotification.message}
                        time={moment(unreadNotification.created_at).calendar()}
                      />
                    ))
                  : 'Nenhuma notificação não lida.'}
              </List>
            )}
          </Content>

          <Footer>
            <NavLink to="/notifications">
              <FiArrowRightCircle size={25} />
              Ver todos...
            </NavLink>
          </Footer>
        </Container>
      )}
    </>
  )
}

export default Notification
