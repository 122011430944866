import produce from 'immer'
import { createReducer, createActions } from 'reduxsauce'

import {
  CommonEnumType,
  CommonTypes,
  CommonState,
  UfRequestResponse,
} from '@/@types/reducers'

// CreateActions
export const { Types, Creators: CommonCreators } = createActions<
  CommonEnumType,
  CommonTypes
>({
  ufRequest: null,
  ufFailure: null,
  ufSuccess: ['data'],
})

/* ------------- Initial State ------------- */

export const INITIAL_STATE: CommonState = {
  data: undefined,
  loading: {
    get: false,
    post: false,
    put: false,
    delete: false,
  },
}

/* ------------- Reducers ------------- */
export const UfRequest = (state: CommonState = INITIAL_STATE): CommonState =>
  produce(state, () => ({
    ...state,
    loading: { ...state.loading, get: true },
  }))

export const UfRequestSuccess = (
  state: CommonState = INITIAL_STATE,
  { data }: UfRequestResponse,
): CommonState =>
  produce(state, () => ({
    ...state,
    data,
    loading: { ...state.loading, get: false },
  }))

export const UfRequestFailure = (
  state: CommonState = INITIAL_STATE,
): CommonState =>
  produce(state, () => ({
    ...state,
    loading: { ...state.loading, get: false },
  }))

/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer<CommonState, CommonTypes>(INITIAL_STATE, {
  [Types.UF_REQUEST]: UfRequest,
  [Types.UF_SUCCESS]: UfRequestSuccess,
  [Types.UF_FAILURE]: UfRequestFailure,
})

// /* ------------- Selectors ------------- */
