import React from 'react'

import { LinearProgress } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'

interface ICouponDeleteDialog {
  open: boolean
  disabledActions: boolean
  onClose: () => void
  onConfirm: () => void
}

const CouponDeleteDialog: React.FC<ICouponDeleteDialog> = ({
  open,
  onClose,
  onConfirm,
  disabledActions,
}) => {
  return (
    <div>
      <Dialog open={open} onClose={onClose}>
        <DialogTitle>Deseja deletar este cupom?</DialogTitle>
        <DialogContent>
          <DialogContentText>Essa ação é irreversível</DialogContentText>
        </DialogContent>

        {disabledActions && <LinearProgress />}

        <DialogActions>
          <Button disabled={disabledActions} onClick={onClose} color="primary">
            Cancelar
          </Button>
          <Button
            disabled={disabledActions}
            onClick={onConfirm}
            color="primary"
            autoFocus
          >
            Confirmar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default CouponDeleteDialog
