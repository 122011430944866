import { AxiosResponse } from 'axios'
import queryString from 'query-string'

import api from '@/services/api'

import { ICityResponse } from './interfaces/city-response.interface'
import { IGetCitiesQuery } from './interfaces/get-cities-query.interface'

export const getCities = async (query: IGetCitiesQuery = {}) => {
  const queryCities = queryString.stringify(query, {
    arrayFormat: 'bracket',
  })
  return api
    .get(`/admin/cities?${queryCities}`)
    .then(({ data: cities }: AxiosResponse<ICityResponse>) => ({
      ...cities,
      items: cities.items.map(city => ({
        id: city.id,
        name: city.name,
        state: city.state,
        isCommercialAvailable: city.is_commercial_available,
        isResidentialAvailable: city.is_residential_available,
      })),
    }))
}
