import { toast } from 'react-toastify'

import axios from 'axios'

import store, { RootState } from '@/store'

const { REACT_APP_API_URL: baseURL } = process.env

const api = axios.create({ baseURL })

api.interceptors.request.use(async config => {
  const state = store.getState() as RootState
  const { token } = state.Auth

  if (token) config.headers.Authorization = `Bearer ${token}`
  return config
})

api.interceptors.response.use(
  response => response,
  error => {
    if (!error.response.status) return Promise.reject(error)
    if (error.response?.status === 401) {
      toast.error('Sessão expirada')
      localStorage.clear()
      window.location.href = '/'
    }
    return Promise.reject(error)
  },
)

export default api
