import React from 'react'
import { toast } from 'react-toastify'

import { useState } from '@/hooks/useState'
import api from '@/services/api'
import {
  Grid,
  List,
  Card,
  CardHeader,
  ListItem,
  ListItemText,
  ListItemIcon,
  Checkbox,
  Button,
  Divider,
  TextField,
  LinearProgress,
  ButtonGroup,
} from '@material-ui/core'
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles'
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert'

const Alert = (props: AlertProps) => {
  return <MuiAlert elevation={6} variant="filled" {...props} />
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      margin: 'auto',
    },
    cardHeader: {
      padding: theme.spacing(1, 2),
    },
    list: {
      width: '100%',
      height: '80vh',
      backgroundColor: theme.palette.background.paper,
      overflow: 'auto',
    },
    button: {
      margin: theme.spacing(0.5, 0),
    },
  }),
)

interface Client {
  client_id: string
  client_name: string
  client_email: string
  steps_type: string
  steps_status: string
}

interface IVertMainState {
  clients: Client[]
  selecteds: Client['client_id'][]
  checked: Client[]
  left: Client[]
  right: Client[]
  loadingClients: boolean
  previewData?: string
  previewDataErrors: string[]
  depreciation_rate: number
  loadingPreview: boolean
  xlsx?: string
}

const VertMain: React.FC = () => {
  const classes = useStyles()

  const { state, setState } = useState<IVertMainState>({
    clients: [],
    selecteds: [],
    checked: [],
    left: [],
    right: [],
    loadingClients: true,
    previewData: undefined,
    depreciation_rate: 5,
    loadingPreview: false,
    previewDataErrors: [],
    xlsx: '',
  })

  const handleToggle = (client: Client['client_id']) => () => {
    let selecteds
    if (state.selecteds.includes(client)) {
      selecteds = [...state.selecteds]
      selecteds.splice(selecteds.indexOf(client), 1)
    } else {
      selecteds = [...state.selecteds, client]
    }

    setState({ selecteds })
  }

  const handleToggleAll = () => {
    // eslint-disable-next-line
    console.log('entrou no @handleToggleAll')
    if (state.selecteds.length < state.clients.length) {
      const selecteds: Client['client_id'][] = []
      state.clients.forEach(c => selecteds.push(c.client_id))
      setState({ selecteds })
    } else {
      setState({ selecteds: [] })
    }
  }

  React.useEffect(() => {
    // eslint-disable-next-line
    console.log('selecteds =>', state.selecteds)
  }, [state.selecteds])

  const getClients = () => {
    api
      .get('/admin/vert/clients')
      .then(({ data: clients }) => {
        // eslint-disable-next-line
        console.log('getVertClients =>', clients)
        setState({ clients })
      })
      .finally(() => setState({ loadingClients: false }))
  }

  const generateXls = () => window.open(state.xlsx, '_blank')

  const generate = ({ is_preview }: { is_preview: boolean }) => {
    if (is_preview) setState({ loadingPreview: true })
    api
      .post(
        '/admin/vert/make',
        {
          clients: state.selecteds,
          depreciation_rate: state.depreciation_rate,
        },
        { params: { is_preview } },
      )
      .then(({ data }) => {
        // eslint-disable-next-line
        console.log('generate', data)
        if (data.file) {
          setState({ previewData: data.file, xlsx: data.xlsx })
          toast.success(
            is_preview ? 'Preview gerado com sucesso' : 'Enviado para a Vert',
          )
        }
        setState({ previewDataErrors: data.errors || [] })
      })
      .finally(() => {
        if (is_preview) {
          //
          setState({ loadingPreview: false })
        }
      })
  }

  React.useEffect(() => {
    getClients()
    // eslint-disable-next-line
  }, [])

  return (
    <Grid container spacing={2} alignItems="center">
      <Grid item xs={5}>
        <Card>
          <CardHeader
            className={classes.cardHeader}
            avatar={
              // eslint-disable-next-line react/jsx-wrap-multilines
              <Checkbox
                onClick={handleToggleAll}
                checked={state.clients.length === state.selecteds.length}
                indeterminate={
                  state.selecteds.length > 0 &&
                  state.selecteds.length !== state.clients.length
                }
                disabled={state.clients.length === 0}
                inputProps={{ 'aria-label': 'all items selected' }}
                style={{
                  color: '#ffd500',
                }}
              />
            }
            title="Clientes"
            subheader={`${state.selecteds.length}/${state.clients.length} selecionado(s)`}
          />
          <Divider />
          <List className={classes.list} dense component="div" role="list">
            {state.clients.map((client: Client) => {
              const labelId = `transfer-list-all-item-${client}-label`

              return (
                <ListItem
                  key={client.client_id}
                  role="listitem"
                  button
                  onClick={handleToggle(client.client_id)}
                >
                  <ListItemIcon>
                    <Checkbox
                      checked={state.selecteds.includes(client.client_id)}
                      tabIndex={-1}
                      disableRipple
                      inputProps={{ 'aria-labelledby': labelId }}
                      style={{
                        color: '#ffd500',
                      }}
                    />
                  </ListItemIcon>
                  <ListItemText id={labelId} primary={client.client_name} />
                </ListItem>
              )
            })}
            <ListItem />
          </List>
        </Card>
      </Grid>

      <Grid item xs={7}>
        <Grid container spacing={3} alignItems="center">
          <Grid item xs={12}>
            <TextField
              type="number"
              disabled={state.loadingPreview}
              onChange={e => setState({ depreciation_rate: e.target.value })}
              value={state.depreciation_rate}
              id="standard-basic"
              label="Taxa de depreciação (em %)"
              variant="outlined"
            />
          </Grid>

          <Grid item xs={12} alignItems="center">
            <ButtonGroup color="primary">
              <Button
                variant="contained"
                disabled={
                  !state.selecteds.length ||
                  !state.depreciation_rate ||
                  state.loadingPreview
                }
                color="primary"
                onClick={() => generate({ is_preview: true })}
                // style={{
                //   borderRadius: 8,
                //   backgroundColor: '#ffd500',
                //   fontSize: '14px',
                //   fontWeight: 'bold',
                //   padding: '10px',
                //   color: '#000000'
                // }}
              >
                Pré-visualizar
              </Button>

              <Button
                variant="contained"
                disabled={
                  !state.selecteds.length ||
                  !state.depreciation_rate ||
                  !state.previewData ||
                  state.loadingPreview
                }
                color="primary"
                onClick={() => generate({ is_preview: false })}
              >
                Enviar para VERT
              </Button>

              <Button
                variant="contained"
                disabled={
                  !state.selecteds.length ||
                  !state.previewData ||
                  state.loadingPreview ||
                  !state.xlsx
                }
                color="primary"
                onClick={generateXls}
              >
                Download Excel
              </Button>
            </ButtonGroup>
          </Grid>
          <Grid item xs={12}>
            {state.loadingPreview && <LinearProgress />}
          </Grid>

          {!!state.previewData && (
            <>
              {!!state.previewDataErrors.length && (
                <Grid item xs={12}>
                  {state.previewDataErrors.map(err => (
                    <Alert key={err} severity="warning">
                      {err}
                    </Alert>
                  ))}
                </Grid>
              )}

              <Grid item xs={12}>
                <p style={{ textAlign: 'center' }}>Preview</p>
                <div style={{ border: '3px dotted blue', padding: 5 }}>
                  {/* eslint-disable react/no-danger */}
                  <span
                    style={{ whiteSpace: 'pre-wrap' }}
                    dangerouslySetInnerHTML={{ __html: state.previewData }}
                  />
                </div>
              </Grid>
            </>
          )}
        </Grid>
      </Grid>
    </Grid>
  )
}

export default VertMain
