import styled from 'styled-components'

import theme from '@/assets/styles/theme'

export const Container = styled.div`
  padding-top: 30px;
  width: 100%;
  overflow-y: scroll;

  ::-webkit-scrollbar {
    display: none;
  }
`

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;

  @media screen and (max-width: 1100px) {
    display: grid;
    grid-template-columns: 1fr;
  }
`

export const Title = styled.h1`
  font-size: 22px;
  font-weight: 600;
  color: ${theme.colors.gray[600]};

  @media screen and (max-width: 1100px) {
    margin-bottom: 0.25rem;
  }
`

export const BreadcrumbsContainer = styled.div``

export const Body = styled.div`
  background: ${theme.colors.white};
  width: 100%;
  max-height: 71vh;
  overflow-y: scroll;
  border-radius: 5px;
  box-shadow: 0px 0px 17px -8px rgba(0, 0, 0, 0.05);

  ::-webkit-scrollbar {
    display: none;
  }
  overflow: auto;

  @media screen and (max-width: 1366px) {
    height: 65vh;
  }
  @media screen and (max-width: ${theme.breakpoints.md}) {
    height: 72vh;
  }
`
