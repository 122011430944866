/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useCallback } from 'react'

import CostInfos from './CostInfos/CostInfos'
import DetailsCard from './DetailsCard'
import BigNumberCard, {
  PositionEnum,
} from './GeneralDataCards/BigNumberCard/BigNumberCard'
import * as S from './styles'

const GroupPresentation = ({ simulationValues }: any) => {
  const getProjectPower = useCallback(() => {
    if (!simulationValues?.power_of_the_project) return 0

    return simulationValues.power_of_the_project.toLocaleString('pt-br', {
      minimumFractionDigits: 1,
      maximumFractionDigits: 3,
    })
  }, [simulationValues])

  const getMonthlyProduction = useCallback(() => {
    if (!simulationValues?.annual_production) return 0

    const { annual_production } = simulationValues
    const yearProduction =
      annual_production > 0 ? annual_production : annual_production * -1

    return `${(yearProduction / 12).toFixed(0)}`.replace('.', ',')
  }, [simulationValues])

  const getAnnualSavings = useCallback(() => {
    const yearSavings = Math.abs(simulationValues?.annual_savings ?? 0)
    const formattedSavings = `${(yearSavings / 1000).toFixed(2)}`.replace(
      '.',
      ',',
    )

    return `${formattedSavings}mil`
  }, [simulationValues])

  const getPowerPrice = useCallback(() => {
    if (
      !simulationValues?.final_value ||
      !simulationValues?.power_of_the_project
    ) {
      return 0
    }

    return `${(
      simulationValues?.final_value /
        (1000 * simulationValues?.power_of_the_project) || 0
    )
      .toFixed(2)
      .replace('.', ',')}`
  }, [simulationValues])

  const getPayback = useCallback(() => {
    if (!simulationValues?.payback) return 0
    return simulationValues.payback
  }, [simulationValues])

  return (
    <S.DetailsContainer>
      <S.Subcontainer>
        <BigNumberCard
          card="Potência"
          main={getProjectPower()}
          side="kWp"
          sidePosition={PositionEnum.RIGHT}
        />
        <BigNumberCard
          card="Preço por potência"
          main={getPowerPrice()}
          side="R$/Wp"
          sidePosition={PositionEnum.RIGHT}
        />
        <BigNumberCard
          card="Produção mensal"
          main={getMonthlyProduction()}
          side="kWh"
          sidePosition={PositionEnum.RIGHT}
        />
        <BigNumberCard
          card="Economia anual"
          main={getAnnualSavings()}
          side="R$"
          sidePosition={PositionEnum.LEFT}
        />
        <BigNumberCard
          card="Payback"
          main={getPayback()}
          side="meses"
          sidePosition={PositionEnum.RIGHT}
        />
      </S.Subcontainer>
      <S.Subcontainer>
        <DetailsCard simulationValues={simulationValues} />
      </S.Subcontainer>
      <CostInfos totalCost={simulationValues?.final_value || 0} />
    </S.DetailsContainer>
  )
}

export default GroupPresentation
