import styled from 'styled-components'

import theme from '@/assets/styles/theme'
import { withStyles, FormControl as MuiFormControl } from '@material-ui/core'
import Tooltip from '@material-ui/core/Tooltip'
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined'

type StyleProps = {
  status?: 'NOT_REQUESTED' | 'IN_EVALUATION' | 'APPROVED' | 'DENIED'
  column?: boolean
}

const getBackground = (status?: string) => {
  if (status === 'NOT_REQUESTED' || status === 'IN_EVALUATION')
    return theme.colors.orange[100]
  if (status === 'APPROVED') return theme.colors.green[100]
  return theme.colors.red[100]
}

const getColor = (status?: string) => {
  if (status === 'NOT_REQUESTED' || status === 'IN_EVALUATION')
    return theme.colors.orange[200]
  if (status === 'APPROVED') return theme.colors.green[200]
  return theme.colors.red[200]
}

export const ContainerIframe = styled.div`
  width: 100%;
  height: 730px;
  overflow: hidden;
`

export const Title = styled.div`
  padding-bottom: 20px;
`

export const Subcontainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;

  @media only screen and (max-width: 750px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    place-items: center;
    column-gap: 1rem;
  }
`

export const BigNumberWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

  @media only screen and (max-width: 750px) {
    min-width: 100%;
    flex: 1;
  }
`

export const UnderlinedText = styled.div`
  border-bottom: 3px solid #ffd500;
  width: 7rem;
  margin-bottom: 0.5rem;
  overflow: visible;
  white-space: nowrap;
`
export const SubTitle = styled.span`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 1.3rem;
  line-height: 130%;

  @media only screen and (max-width: 750px) {
    font-size: 1rem;
  }
`

export const SubText = styled.div`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  font-size: 1rem;
  line-height: 130%;
  padding-bottom: 0.2rem;

  @media only screen and (max-width: 750px) {
    font-size: 0.8rem;
    padding-bottom: 0.1rem;
  }
`

export const Sheet = styled.div<StyleProps>`
  display: flex;
  flex-direction: ${({ column }) => (column ? 'column' : 'row')};
  justify-content: flex-start;
  align-items: flex-end;
  flex: 1;
  padding: 2.5rem;
  background-color: #edf2f7;
  border-radius: 0.5rem;

  @media only screen and (max-width: 750px) {
    width: 100%;
    margin-bottom: 1rem;
    padding: 2.5rem 1rem;
    margin-right: 0;
    justify-content: center;
  }
`

export const Text = styled.span`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 1.5rem;
  line-height: 130%;

  @media only screen and (max-width: 750px) {
    font-size: 1rem;
  }
`

export const CostWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin: 1rem 2rem 0 0;

  @media only screen and (max-width: 750px) {
    justify-content: center;
    margin: 0;
  }
`

export const ValueWrapper = styled.div`
  width: 50%;
  display: grid;
  place-items: center;
  grid-template-columns: 200px 1fr;

  @media only screen and (max-width: 750px) {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
`

export const TotalCost = styled.span`
  font-family: 'Roboto';
  font-weight: 500;
  font-size: 1.5rem;
  line-height: 130%;
  padding: 0 1.5rem;

  @media only screen and (max-width: 750px) {
    font-size: 1.2rem;
    padding: 0;
  }
`

export const MoneySign = styled.span`
  font-family: 'Roboto';
  font-weight: 300;
  font-size: 1.5rem;
  margin: 0 0.4rem;

  @media only screen and (max-width: 750px) {
    font-size: 1rem;
  }
`

export const MoneyValue = styled.span`
  font-family: 'Roboto';
  font-weight: 500;
  font-size: 1.5rem;

  @media only screen and (max-width: 750px) {
    font-size: 1.2rem;
  }
`

export const MoneyWrapper = styled.div`
  display: flex;
  justify-self: end;
  align-items: flex-end;
  margin-right: 2rem;

  @media only screen and (max-width: 750px) {
    margin: 0;
  }
`

export const TitleWrapper = styled.div`
  display: flex;
  gap: 1rem;
  align-items: center;

  @media only screen and (max-width: 750px) {
    display: grid;
    grid-template-columns: 1fr auto;

    & > div:nth-child(3) { {
      grid-column: 1 / 3;
      margin-top: 0 !important;
    }
  }
`

export const StatusIndicator = styled.div<StyleProps>`
  background-color: ${({ status }) => getBackground(status)};
  color: ${({ status }) => getColor(status)};
  padding: 1rem 1.5rem;
  border-radius: 0.5rem;
  margin-left: 2rem;
`

export const DocFileWrapper = styled.div<{ dismiss: boolean }>`
  width: 100%;
  display: grid;
  gap: 1rem;
  grid-template-columns: 200px 1fr auto;
  align-items: center;
  margin-bottom: 1rem;

  @media only screen and (max-width: 750px) {
    grid-template-columns: repeat(5, 1fr);

    & > span {
      width: 100%;
      grid-column: ${({ dismiss }) => (dismiss ? '1 / 5' : '1 / 6')};
    }

    & > div:nth-child(2) {
      width: 100%;
      grid-column: ${({ dismiss }) => (dismiss ? '1 / 4' : '1 / 5')};
    }
  }
`

export const ContainerIcons = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 70%;
  gap: 1rem;

  @media only screen and (max-width: 750px) {
    width: 100%;
    justify-content: space-around;

    & > svg {
      width: 1.5rem;
      height: 1.5rem;
    }
  }
`

export const MUITooltip = withStyles(() => ({
  tooltip: {
    backgroundColor: theme.colors.gray[100],
    color: theme.colors.black,
    maxWidth: 250,
    fontSize: '16px',
    border: `1px solid ${theme.colors.gray[300]}`,
    textAlign: 'center',
  },
}))(Tooltip)

type IconProps = {
  hasTooltip?: boolean
}

export const InfoIcon = styled(InfoOutlinedIcon)<IconProps>`
  color: ${theme.colors.red[200]};
  opacity: ${({ hasTooltip }) => (hasTooltip ? 1 : 0)};
  cursor: ${({ hasTooltip }) => (hasTooltip ? 'help' : 'default')};
`

export const DiscardDoc = styled.div`
  color: red;
  cursor: pointer;
  font-size: 1.2rem;
  font-weight: 600;
`

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 2rem;
  margin-bottom: 2rem;
  margin-right: 2rem;

  @media only screen and (max-width: 750px) {
    width: 100%;
    margin-right: 0;
  }
`

/*
 * Refactor
 */
type StyleType = {
  last?: boolean
  color?: string
}

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: auto;
  gap: 1rem;
`

export const HeaderSection = styled.div`
  display: grid;
  grid-template-columns: auto 1fr auto;
  gap: 1rem;
  align-items: center;

  @media only screen and (max-width: 750px) {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 100%;
    align-items: flex-start;
  }
`

export const LendingSection = styled.div`
  display: flex;
  width: 100%;
  height: auto;
  gap: 1rem;
  padding-bottom: 1rem;
  align-items: center;

  @media only screen and (max-width: 750px) {
    flex-direction: column;
    width: 100%;
  }
`

export const DataSection = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  background-color: #efefef;
  border-radius: 10px;
  padding: 1rem;
`

export const SectionTitle = styled.span`
  font-family: 'Roboto';
  font-size: 24px;
  font-weight: 700;
  line-height: 31px;
  letter-spacing: 0em;
  text-align: left;
`

const getStatusColor = (status: string | undefined) => {
  switch (status) {
    case 'NOT_REQUESTED':
      return theme.colors.red[100]
    case 'IN_EVALUATION':
      return theme.colors.blue
    case 'APPROVED':
      return theme.colors.green[100]
    case 'DENIED':
      return theme.colors.red[100]
    default:
      return theme.colors.red[100]
  }
}

export const StatusTip = styled.div<StyleProps>`
  width: 137px;
  height: 43px;
  border-radius: 10px;
  background-color: ${({ status }) => getStatusColor(status)};
  display: grid;
  place-items: center;
  font-family: Roboto;
  font-size: 16px;
  font-weight: 700;
  line-height: 21px;
  letter-spacing: 0em;
`

export const DataTitle = styled.span`
  font-family: 'Montserrat';
  font-size: 24px;
  font-weight: 500;
  letter-spacing: 0em;
  text-align: left;
  text-decoration: underline;
`

export const DataWrapper = styled.div<StyleType>`
  display: grid;
  grid-template-rows: repeat(3, auto);
  grid-template-columns: repeat(3, 1fr);
  align-items: center;
  justify-content: start;
  column-gap: 1rem;
  margin-bottom: ${({ last }) => (last ? '0' : '2rem')};

  @media only screen and (max-width: 750px) {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
`

export const DataUnit = styled.div`
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
  // height: 2rem;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  padding: 0.5rem 0;
`

export const TextStrong = styled.b`
  font-weight: 700;
  margin-right: 0.25rem;
  width: fit-content;
`

export const RequestWrapper = styled.div`
  width: fit-content;
  margin-left: auto;
  margin-right: 0;
  padding-bottom: 1rem;
`

export const RequestWrapperButtons = styled.div`
  width: fit-content;
  display: flex;
  gap: 1rem;
`

export const Button = styled.button`
  font-family: 'Montserrat';
  font-size: 1rem;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0em;
  width: 120px;
  height: 39px;
  color: ${theme.colors.gray[500]};
  border: 0;
  border-radius: 6px;
  cursor: pointer;

  &:disabled {
    cursor: not-allowed;
  }
`

export const LendingCard = styled.div`
  border: 1px solid #cac9c9;
  width: 249px;
  height: 160px;
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: space-between;
  border-radius: 10px;
  position: relative;
  padding: 1rem;
  cursor: pointer;

  &:hover {
    box-shadow: 2px 2px 2px #cac9c9;
  }
`

export const LendingCardStatus = styled.div<StyleType>`
  position: absolute;
  top: 0.5rem;
  right: 1rem;
  font-family: Roboto;
  font-size: 11px;
  font-weight: 700;
  line-height: 14px;
  letter-spacing: 0em;
  text-align: left;
  padding: 2px 0.75rem;
  border-radius: 0.5rem;
  background-color: ${({ color }) => color ?? theme.colors.gray[500]};
`

export const CardRow = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const CardColumn = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: space-between;
`

export const CardTitle = styled.span`
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 600;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
`

export const CardValue = styled.span`
  font-family: Montserrat;
  font-size: 24px;
  font-weight: 400;
  line-height: 31px;
  letter-spacing: 0em;
  text-align: left;
`

export const CardMoneySign = styled.b`
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
`

export const AddButton = styled.button`
  border: none;
  background-color: ${theme.colors.primary};
  width: 3.5rem;
  height: 3.5rem;
  border-radius: 50%;
  color: ${theme.colors.black};
  font-size: 1.5rem;

  &:hover {
    background-color: #e5bf00;
  }
`

export const BankDialogForm = styled.form`
  display: flex;
  flex-direction: column;
  width: 550px;
  padding: 3rem 5rem 3rem;
  gap: 0.5rem 1rem;
  position: relative;

  label {
    font-family: Montserrat;
    font-size: 1rem;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: left;
  }

  @media only screen and (max-width: 750px) {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 3rem 1rem;
  }
`

export const EditDialogForm = styled.form`
  display: grid;
  grid-template-columns: 1fr 1fr;
  place-items: center;
  width: 850px;
  padding: 3rem 5rem;
  gap: 0.5rem 1rem;
  position: relative;

  label {
    font-family: Montserrat;
    font-size: 1rem;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: left;
  }

  @media only screen and (max-width: 750px) {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 3rem 1rem;
  }
`

export const CloseDialog = styled.div`
  position: absolute;
  top: 1rem;
  right: 1rem;
  cursor: pointer;
`

export const DialogFieldWrapper = styled.form`
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
`

export const DialogButtonWrapper = styled.div`
  display: flex;
  gap: 1rem;
  align-items: center;
  justify-content: space-around;
  padding: 1rem;
  width: 100%;

  @media only screen and (max-width: 750px) {
    gap: 0.25rem;
  }
`

export const CancelButton = styled.button`
  background-color: transparent;
  border: none;
  padding: 0.5rem 2rem;
  border-radius: 10px;
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 600;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: center;

  &:hover {
    background-color: ${theme.colors.gray[300]};
  }
`

export const DenyButton = styled.button`
  padding: 12px 24px;
  border-radius: 8px;
  border: 1px solid #cac9c9;
  gap: 10px;
  font-family: Roboto;
  font-size: 1rem;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: center;
  font-weight: bold;

  &:hover {
    background-color: ${theme.colors.gray[300]};
  }
`

export const ConfirmButton = styled.button`
  background-color: ${theme.colors.primary};
  border: none;
  padding: 0.5rem 2rem;
  border-radius: 10px;
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 600;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: center;

  &:hover {
    background-color: rgba(255, 213, 0, 0.8);
  }
`

export const EditDialogRow = styled.div`
  display: grid;
  place-items: center;
  grid-template-columns: repeat(3, 1fr);
  gap: 0.5rem 1rem;
`

export const FormControl = styled(MuiFormControl)`
  label {
    color: ${theme.colors.gray[600]} !important;
    font-size: 0.8rem !important;
  }

  fieldset {
    border-color: ${theme.colors.gray[300]} !important;
    border-width: 2px !important;
  }
`
