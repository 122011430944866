import produce from 'immer'
import { createActions, createReducer } from 'reduxsauce'

import {
  CitiesTypeEnum,
  IActionTypes,
  ICitiesInitialState,
  ICitiesData,
} from './types'

type CitiesType = typeof CitiesTypeEnum

export const { Types: CitiesTypes, Creators: CitiesCreators } = createActions<
  CitiesType,
  IActionTypes
>({
  getCitiesRequest: ['query'],
  getCitiesSuccess: ['cities'],
  getCitiesFailure: {},
})

export const INITIAL_STATE: ICitiesInitialState = {
  cities: {
    items: [],
    pagination: {
      current_page: 1,
      item_count: 0,
      items_per_page: 10,
      total_items: 0,
      total_pages: 1,
    },
  },
  isLoading: false,
}

export const getCitiesRequest = (state = INITIAL_STATE) =>
  produce(state, () => ({
    ...state,
    isLoading: true,
  }))

export const getCitiesSuccess = (
  state = INITIAL_STATE,
  { cities }: { cities: ICitiesData },
) =>
  produce(state, () => ({
    ...state,
    cities,
    isLoading: false,
  }))

export const getCitiesFailure = (state = INITIAL_STATE) =>
  produce(state, () => ({
    ...state,
    isLoading: false,
  }))

export const reducer = createReducer<ICitiesInitialState, IActionTypes>(
  INITIAL_STATE,
  {
    [CitiesTypes.GET_CITIES_REQUEST]: getCitiesRequest,
    [CitiesTypes.GET_CITIES_SUCCESS]: getCitiesSuccess,
    [CitiesTypes.GET_CITIES_FAILURE]: getCitiesFailure,
  },
)
