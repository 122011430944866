import { toast } from 'react-toastify'

import { call, put, select } from 'redux-saga/effects'

import api from '@/services/api'
import { RootState } from '@/store'
import {
  IEditReportStepThree,
  IEditReportStep,
  IEditReportStepOne,
  IEditReportStepFive,
  IEditReportStepSix,
} from '@/store/ducks/ClientReport/types'

import { ReportCreators } from '../../ducks/ClientReport'

const getClientId = (state: RootState) => state.Client.client.id

interface IReportTwo {
  clientId: string
  editReportStepTwo: IEditReportStep
}

export function* getReportTwoRequest({ clientId }: { clientId: string }) {
  try {
    const { data: clientReportTwo } = yield call(
      api.get,
      `/admin/customers/${clientId}/reports/2`,
    )

    yield put(ReportCreators.getReportTwoSuccess(clientReportTwo))
  } catch (err) {
    yield put(ReportCreators.getReportTwoFailure())
  }
}

export function* getReportThree() {
  const clientId = yield select(getClientId)
  try {
    const { data: report } = yield call(
      api.get,
      `/admin/customers/${clientId}/reports/3`,
    )
    yield put(ReportCreators.getReportThreeSuccess(report))
  } catch (error) {
    toast.error('Não foi possível carregar o relatório três')
    yield put(ReportCreators.getReportThreeFailure())
  }
}

export function* editReportThree({
  editReportStepThree,
}: {
  editReportStepThree: IEditReportStepThree
}) {
  const clientId = yield select(getClientId)
  try {
    yield call(
      api.patch,
      `/admin/customers/${clientId}/reports/3`,
      editReportStepThree,
    )
    yield put(ReportCreators.editReportThreeSuccess())
    yield put(ReportCreators.setIsReportThree(false))
    toast.success('Relatório três editado com sucesso.')
  } catch (error) {
    toast.error('Não foi possível editar o relatório!')
    yield put(ReportCreators.editReportThreeFailure())
  }
}

export function* editReportStepTwoRequest({
  reportTwo,
}: {
  reportTwo: IReportTwo
}) {
  try {
    yield call(
      api.patch,
      `/admin/customers/${reportTwo.clientId}/reports/2`,
      reportTwo.editReportStepTwo,
    )

    yield put(ReportCreators.editReportStepTwoSuccess())
    toast.success('Relatório dois editado com sucesso!')
    yield put(ReportCreators.setIsEditReportTwoTechnician(false))
  } catch (err) {
    yield put(ReportCreators.editReportStepTwoFailure())
    toast.error('Não foi possível editar o relatório!')
  }
}

export function* getReportOne() {
  const clientId = yield select(getClientId)
  try {
    const { data: clientReportOne } = yield call(
      api.get,
      `/admin/customers/${clientId}/reports/1`,
    )
    yield put(ReportCreators.getReportOneSuccess(clientReportOne))
  } catch (error) {
    yield put(ReportCreators.getReportOneFailure())
    toast.error('Não foi possível carregar o relatório um.')
  }
}

export function* getReportFive() {
  const clientId = yield select(getClientId)
  try {
    const { data: clientReportOne } = yield call(
      api.get,
      `/admin/customers/${clientId}/reports/5`,
    )
    yield put(ReportCreators.getReportFiveSuccess(clientReportOne))
  } catch (error) {
    yield put(ReportCreators.getReportFiveFailure())
    toast.error('Não foi possível carregar o relatório um.')
  }
}

export function* getReportSix() {
  const clientId = yield select(getClientId)
  try {
    const { data: clientReportOne } = yield call(
      api.get,
      `/admin/customers/${clientId}/reports/6`,
    )
    yield put(ReportCreators.getReportSixSuccess(clientReportOne))
  } catch (error) {
    yield put(ReportCreators.getReportSixFailure())
    toast.error('Não foi possível carregar o relatório um.')
  }
}

export function* editReportStepFive({
  editReportStepFive,
}: {
  editReportStepFive: IEditReportStepFive
}) {
  const clientId = yield select(getClientId)
  try {
    yield call(
      api.patch,
      `/admin/customers/${clientId}/reports/5`,
      editReportStepFive,
    )
    yield put(ReportCreators.editReportFiveSuccess())
    toast.success('Relatório um editado com sucesso!')
    yield put(ReportCreators.setIsReportFive(false))
  } catch (error) {
    yield put(ReportCreators.editReportFiveFailure())
    toast.error('Não foi possível editar o relatório!')
  }
}

export function* editReportStepOne({
  editReportStepOne,
}: {
  editReportStepOne: IEditReportStepOne
}) {
  const clientId = yield select(getClientId)
  try {
    yield call(
      api.patch,
      `/admin/customers/${clientId}/reports/1`,
      editReportStepOne,
    )
    yield put(ReportCreators.editReportOneSuccess())
    toast.success('Relatório um editado com sucesso!')
    yield put(ReportCreators.setIsReportOne(false))
  } catch (error) {
    yield put(ReportCreators.editReportOneFailure())
    toast.error('Não foi possível editar o relatório!')
  }
}

export function* editReportSaveImage({
  editReportSaveImage,
}: {
  editReportSaveImage: IEditReportStepOne
}) {
  const clientId = yield select(getClientId)
  try {
    yield call(
      api.patch,
      `/admin/customers/${clientId}/reports_image/1`,
      editReportSaveImage,
    )
    yield put(ReportCreators.editReportOneSuccess())
    toast.success('Imagem salva com sucesso!')
  } catch (error) {
    yield put(ReportCreators.editReportOneFailure())
    toast.error('Não foi possível editar o relatório!')
  }
}

export function* editReportSavePdf({
  editReportSavePdf,
}: {
  editReportSavePdf: IEditReportStepSix
}) {
  const clientId = yield select(getClientId)
  try {
    yield call(
      api.patch,
      `/admin/customers/${clientId}/reports_image/6`,
      editReportSavePdf,
    )
    yield put(ReportCreators.editReportSixSuccess())
    toast.success('PDF salvo com sucesso!')
  } catch (error) {
    yield put(ReportCreators.editReportSixFailure())
    toast.error('Não foi possível editar o relatório!')
  }
}

export function* getReports() {
  const clientId = yield select(getClientId)
  try {
    const { data: stepsStatus } = yield call(
      api.get,
      `/admin/customers/${clientId}/reports`,
    )
    yield put(ReportCreators.getReportsSuccess(stepsStatus))
  } catch (error) {
    yield put(ReportCreators.getReportsFailure())
    toast.error('Não foi carregar os status dos relatórios')
  }
}
