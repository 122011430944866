/* eslint-disable @typescript-eslint/no-explicit-any */
import { all, takeLatest } from 'redux-saga/effects'

import { Types as AuthTypes } from '../ducks/Auth'
import { Types as BillTypes } from '../ducks/Bill'
import { CitiesTypes } from '../ducks/Cities'
import { Types as ClientReportTypes } from '../ducks/ClientReport'
import { Types as ClientType } from '../ducks/Clients'
import { Types as CommonTypes } from '../ducks/common'
import { DatabookTypes } from '../ducks/Databook'
import { Types as NotificationTypes } from '../ducks/Notification'
import { Types as PaymentHistoryTypes } from '../ducks/PaymentHistory'
import { StateTypes } from '../ducks/State'
import { SystemConfigTypes } from '../ducks/SystemConfig'
import { Types as UserTypes } from '../ducks/Users'
import { Types as VisitTypes } from '../ducks/Visit'
import { logout, signIn } from './Auth'
import {
  editBillDetails,
  editBillFile,
  editBillOwnerRequest,
  editConsumptionHistory,
  getBillDetails,
  getBillFiles,
  getBillOwnerRequest,
  getConsumptionHistory,
  updateConsumptionProfile,
} from './Bill'
import { getCities } from './Cities'
import {
  editReportSaveImage,
  editReportSavePdf,
  editReportStepOne,
  editReportStepTwoRequest,
  editReportThree,
  getReportFive,
  getReportOne,
  getReports,
  getReportSix,
  getReportThree,
  getReportTwoRequest,
} from './ClientReport'
import {
  confirmInstalation,
  confirmPartnershipSuccess,
  editClient,
  editClientBoardRequest,
  editClientCommissionInstallationCostRequest,
  editClientDocumentsStatusRequest,
  editClientEnergyConsumptionRequest,
  editClientLegalRequest,
  editClientTariffRequest,
  getClient,
  getClientDocumentsRequest,
  getClientFreeMonth,
  getClientLegalRequest,
  getClientPartnership,
  getClientPlanRequest,
  getClientProjectInfoRequest,
  getClients,
  getClientSimulation,
  getClientSimulationByClientIdRequest,
  getCostumerFiles,
  getCostumerMessages,
  saveCostumerMessage,
  sendContactRequest,
  setClientFreeMonth,
  setClientGeneratePlansRequest,
  setCustomerSuccessTables,
  updatedProjectStatus,
  updatedUploadEnable,
} from './Clients'
import { requestUfs } from './common'
import { editDatabook, getDatabook } from './Databook'
import {
  getNotifications,
  getUnreadNotifications,
  setReadNotification,
} from './Notification'
import { getPaymentHistory } from './PaymentHistory'
import {
  createState,
  deleteState,
  getState,
  getStates,
  updateState,
} from './State'
import { getSystemConfigs, updateSystemConfig } from './SystemConfig'
import { createUser, editUser, getUser, getUsers } from './Users'
import {
  finishTechnicalVisit,
  technicianRequest,
  updateTechnicalVisitStatus,
  visitCancelRequest,
  visitCancelSchedulingRequest,
  visitConfirmSchedulingRequest,
  visitLinkTechnicianRequest,
  visitListRequest,
} from './Visit'

export default function* rootSaga() {
  return yield all([
    // Auth
    takeLatest<string, any>(AuthTypes.SIGN_IN_REQUEST, signIn),
    takeLatest<string, any>(AuthTypes.LOGOUT_REQUEST, logout),

    // Ufs
    takeLatest(CommonTypes.UF_REQUEST, requestUfs),

    // Users
    takeLatest<string, any>(UserTypes.EDIT_USER_REQUEST, editUser),
    takeLatest<string, any>(UserTypes.GET_USERS_REQUEST, getUsers),
    takeLatest<string, any>(UserTypes.CREATE_USER_REQUEST, createUser),
    takeLatest<string, any>(UserTypes.GET_USER_REQUEST, getUser),

    // Visit
    takeLatest(VisitTypes.VISIT_TECHNICIAN_REQUEST, technicianRequest),
    takeLatest<string, any>(
      VisitTypes.VISIT_CANCEL_REQUEST,
      visitCancelRequest,
    ),
    takeLatest<string, any>(
      VisitTypes.VISIT_LINK_TECHNICIAN_REQUEST,
      visitLinkTechnicianRequest,
    ),
    takeLatest<string, any>(VisitTypes.VISIT_LIST_REQUEST, visitListRequest),
    takeLatest<string, any>(
      VisitTypes.VISIT_CANCEL_SCHEDULING_REQUEST,
      visitCancelSchedulingRequest,
    ),
    takeLatest<string, any>(
      VisitTypes.VISIT_CONFIRM_SCHEDULING_REQUEST,
      visitConfirmSchedulingRequest,
    ),
    takeLatest<string, any>(
      VisitTypes.VISIT_TECHNICIAN_FINISH_REQUEST,
      finishTechnicalVisit,
    ),

    // State
    takeLatest<string, any>(StateTypes.GET_STATE_REQUEST, getState),
    takeLatest<string, any>(StateTypes.GET_STATES_REQUEST, getStates),
    takeLatest<string, any>(StateTypes.CREATE_STATE_REQUEST, createState),
    takeLatest<string, any>(StateTypes.UPDATE_STATE_REQUEST, updateState),
    takeLatest<string, any>(StateTypes.DELETE_STATE_REQUEST, deleteState),

    // System config
    takeLatest<string, any>(
      SystemConfigTypes.GET_SYSTEM_CONFIGS_REQUEST,
      getSystemConfigs,
    ),
    takeLatest<string, any>(
      SystemConfigTypes.UPDATE_SYSTEM_CONFIG_REQUEST,
      updateSystemConfig,
    ),

    // Clients
    takeLatest<string, any>(ClientType.GET_CLIENTS_REQUEST, getClients),
    takeLatest<string, any>(ClientType.GET_CLIENT_REQUEST, getClient),
    takeLatest<string, any>(ClientType.EDIT_CLIENT_REQUEST, editClient),
    takeLatest<string, any>(
      ClientType.GET_CLIENT_DOCUMENTS_REQUEST,
      getClientDocumentsRequest,
    ),
    takeLatest<string, any>(
      ClientType.EDIT_CLIENT_DOCUMENTS_STATUS_REQUEST,
      editClientDocumentsStatusRequest,
    ),
    takeLatest<string, any>(
      ClientType.GET_CLIENT_PROJECT_INFO_REQUEST,
      getClientProjectInfoRequest,
    ),
    takeLatest<string, any>(
      ClientType.EDIT_CLIENT_TARIFF_REQUEST,
      editClientTariffRequest,
    ),
    takeLatest<string, any>(
      ClientType.EDIT_CLIENT_COMMISSION_INSTALLATION_COST_REQUEST,
      editClientCommissionInstallationCostRequest,
    ),
    takeLatest<string, any>(
      ClientType.EDIT_CLIENT_ENERGY_CONSUMPTION_REQUEST,
      editClientEnergyConsumptionRequest,
    ),

    takeLatest<string, any>(
      ClientType.EDIT_CLIENT_BOARD_REQUEST,
      editClientBoardRequest,
    ),
    takeLatest<string, any>(
      ClientType.GET_CLIENT_SIMULATION_BY_CLIENT_ID_REQUEST,
      getClientSimulationByClientIdRequest,
    ),
    takeLatest<string, any>(
      ClientType.CONFIRM_INSTALATION_REQUEST,
      confirmInstalation,
    ),

    // Cities
    takeLatest<string, any>(CitiesTypes.GET_CITIES_REQUEST, getCities),

    // Simulation
    takeLatest<string, any>(
      ClientType.GET_CLIENT_SIMULATION_REQUEST,
      getClientSimulation,
    ),

    // Plans
    takeLatest<string, any>(
      ClientType.GET_CLIENT_PLAN_REQUEST,
      getClientPlanRequest,
    ),
    takeLatest<string, any>(
      ClientType.SET_CLIENT_GENERATE_PLANS_REQUEST,
      setClientGeneratePlansRequest,
    ),

    // Client Legal
    takeLatest<string, any>(
      ClientType.GET_CLIENT_LEGAL_REQUEST,
      getClientLegalRequest,
    ),
    takeLatest<string, any>(
      ClientType.EDIT_CLIENT_LEGAL_REQUEST,
      editClientLegalRequest,
    ),
    // Bill
    takeLatest<string, any>(BillTypes.GET_BILL_DETAILS_REQUEST, getBillDetails),
    takeLatest<string, any>(
      BillTypes.GET_BILL_OWNER_REQUEST,
      getBillOwnerRequest,
    ),
    takeLatest<string, any>(
      BillTypes.EDIT_BILL_OWNER_REQUEST,
      editBillOwnerRequest,
    ),
    takeLatest<string, any>(
      BillTypes.EDIT_BILL_DETAILS_REQUEST,
      editBillDetails,
    ),
    takeLatest<string, any>(
      BillTypes.GET_CONSUMPTION_HISTORY_REQUEST,
      getConsumptionHistory,
    ),
    takeLatest<string, any>(
      BillTypes.EDIT_CONSUMPTION_HISTORY_REQUEST,
      editConsumptionHistory,
    ),
    takeLatest<string, any>(BillTypes.GET_BILL_FILES_REQUEST, getBillFiles),
    takeLatest<string, any>(BillTypes.EDIT_BILL_FILE_REQUEST, editBillFile),
    takeLatest<string, any>(
      BillTypes.UPDATE_CONSUMPTION_PROFILE_REQUEST,
      updateConsumptionProfile,
    ),

    // Client Report
    takeLatest<string, any>(
      ClientReportTypes.GET_REPORT_TWO_REQUEST,
      getReportTwoRequest,
    ),
    takeLatest<string, any>(
      ClientReportTypes.GET_REPORT_THREE_REQUEST,
      getReportThree,
    ),
    takeLatest<string, any>(
      ClientReportTypes.EDIT_REPORT_THREE_REQUEST,
      editReportThree,
    ),
    takeLatest<string, any>(
      ClientReportTypes.EDIT_REPORT_STEP_TWO_REQUEST,
      editReportStepTwoRequest,
    ),
    takeLatest<string, any>(
      ClientReportTypes.GET_REPORT_ONE_REQUEST,
      getReportOne,
    ),
    takeLatest<string, any>(
      ClientReportTypes.GET_REPORT_FIVE_REQUEST,
      getReportFive,
    ),
    takeLatest<string, any>(
      ClientReportTypes.GET_REPORT_SIX_REQUEST,
      getReportSix,
    ),
    takeLatest<string, any>(
      ClientReportTypes.EDIT_REPORT_ONE_REQUEST,
      editReportStepOne,
    ),
    takeLatest<string, any>(
      ClientReportTypes.EDIT_REPORT_SAVE_IMAGE_REQUEST,
      editReportSaveImage,
    ),
    takeLatest<string, any>(
      ClientReportTypes.EDIT_REPORT_SAVE_PDF_REQUEST,
      editReportSavePdf,
    ),
    takeLatest<string, any>(ClientReportTypes.GET_REPORTS_REQUEST, getReports),

    // Databook
    takeLatest<string, any>(DatabookTypes.GET_DATABOOK_REQUEST, getDatabook),
    takeLatest<string, any>(DatabookTypes.EDIT_DATABOOK_REQUEST, editDatabook),

    // Payment History
    takeLatest<string, any>(
      PaymentHistoryTypes.GET_PAYMENT_HISTORY_REQUEST,
      getPaymentHistory,
    ),
    takeLatest<string, any>(
      VisitTypes.UPDATE_TECHNICIAN_STATUS_REQUEST,
      updateTechnicalVisitStatus,
    ),

    // Notifications
    takeLatest<string, any>(
      NotificationTypes.GET_NOTIFICATIONS_REQUEST,
      getNotifications,
    ),
    takeLatest<string, any>(
      NotificationTypes.GET_UNREAD_NOTIFICATIONS_REQUEST,
      getUnreadNotifications,
    ),
    takeLatest<string, any>(
      NotificationTypes.SET_READ_NOTIFICATION_REQUEST,
      setReadNotification,
    ),

    // Customer Success Messages
    takeLatest<string, any>(
      ClientType.CONFIRM_MESSAGES_REQUEST,
      getCostumerMessages,
    ),
    takeLatest<string, any>(
      ClientType.CONFIRM_SAVE_MESSAGE_REQUEST,
      saveCostumerMessage,
    ),

    // Customer Success Files
    takeLatest<string, any>(ClientType.CONFIRM_FILES_REQUEST, getCostumerFiles),

    // Customer Success Project Status Update
    takeLatest<string, any>(
      ClientType.CONFIRM_UPDATE_STATUS_REQUEST,
      updatedProjectStatus,
    ),

    takeLatest<string, any>(
      ClientType.CONFIRM_UPLOAD_ENABLE_REQUEST,
      updatedUploadEnable,
    ),

    takeLatest<string, any>(
      ClientType.CONFIRM_CONTACT_NEEDED_REQUEST,
      sendContactRequest,
    ),

    // Customer Success Creation
    takeLatest<string, any>(
      ClientType.CONFIRM_CLIENT_SUCCESS_TABLES_REQUEST,
      setCustomerSuccessTables,
    ),

    takeLatest<string, any>(
      ClientType.GET_CLIENT_FREE_MONTH_REQUEST,
      getClientFreeMonth,
    ),

    takeLatest<string, any>(
      ClientType.SET_CLIENT_FREE_MONTH_REQUEST,
      setClientFreeMonth,
    ),

    takeLatest<string, any>(
      ClientType.GET_CLIENT_PARTNERSHIP_REQUEST,
      getClientPartnership,
    ),

    takeLatest<string, any>(
      ClientType.CONFIRM_PARTNERSHIP_REQUEST,
      confirmPartnershipSuccess,
    ),
  ])
}
