import styled from 'styled-components'

import theme from '@/assets/styles/theme'
import Button from '@/components/Ui/Button'
import { CheckBox as Check } from '@/components/Ui/CheckBox/index'

interface IContainerProps {
  isOpen: boolean
}

interface IButtonProps {
  width: string
}

export const Container = styled.div<IContainerProps>`
  background-color: ${theme.colors.white};
  border-radius: 5px;
  box-shadow: 0 0 50px 5px #ededed;
  position: absolute;
  z-index: 998;
  padding: 20px;
  display: ${props => (props.isOpen ? 'column' : 'none')};
  margin-top: 40px;

  .react-calendar {
    width: 235px;
    max-width: 100%;
    background: white;
    font-family: Arial, Helvetica, sans-serif;
  }
  .react-calendar--doubleView {
    width: 700px;
  }
  .react-calendar--doubleView .react-calendar__viewContainer {
    display: flex;
    margin: -0.5em;
  }
  .react-calendar--doubleView .react-calendar__viewContainer > * {
    width: 50%;
    margin: 0.5em;
  }
  .react-calendar,
  .react-calendar *,
  .react-calendar *:before,
  .react-calendar *:after {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    font-size: 12px;
  }
  .react-calendar button {
    margin: 0;
    border: 0;
    outline: none;
  }
  .react-calendar button:enabled:hover {
    cursor: pointer;
  }
  .react-calendar__navigation {
    margin: 0;
  }
  .react-calendar__navigation button {
    min-width: 44px;
    background: none;
  }
  .react-calendar__navigation button:enabled:hover,
  .react-calendar__navigation button:enabled:focus {
    background-color: #e6e6e6;
  }
  .react-calendar__navigation button[disabled] {
    background-color: #f0f0f0;
  }
  .react-calendar__month-view__weekdays {
    text-align: center;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 12px;
  }
  .react-calendar__month-view__weekdays__weekday {
    padding: 0.5em;
  }
  .react-calendar__month-view__weekNumbers {
    font-weight: bold;
  }
  .react-calendar__month-view__weekNumbers .react-calendar__tile {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    padding: calc(0.75em / 0.75) calc(0.5em / 0.75);
  }
  .react-calendar__month-view__days__day--weekend {
    color: #d10000;
  }
  .react-calendar__month-view__days__day--neighboringMonth {
    color: #757575;
  }
  .react-calendar__year-view .react-calendar__tile,
  .react-calendar__decade-view .react-calendar__tile,
  .react-calendar__century-view .react-calendar__tile {
    padding: 2em 0.5em;
  }
  .react-calendar__tile {
    max-width: 100%;
    text-align: center;
    padding: 0.75em 0.5em;
    background: none;
  }
  .react-calendar__tile:disabled {
    background-color: #f0f0f0;
  }
  .react-calendar__tile:enabled:hover,
  .react-calendar__tile:enabled:focus {
    background-color: #e6e6e6;
  }
  .react-calendar__tile--now {
    background: ${theme.colors.white};
  }
  .react-calendar__tile--now:enabled:hover,
  .react-calendar__tile--now:enabled:focus {
    background: #ffffa9;
  }
  .react-calendar__tile--hasActive {
    background: #76baff;
  }
  .react-calendar__tile--hasActive:enabled:hover,
  .react-calendar__tile--hasActive:enabled:focus {
    background: #a9d4ff;
  }
  .react-calendar__tile--active {
    background: ${theme.colors.primary};
    color: white;
  }
  .react-calendar__tile--active:enabled:hover,
  .react-calendar__tile--active:enabled:focus {
    background: ${theme.colors.primary};
  }
  .react-calendar--selectRange .react-calendar__tile--hover {
    background-color: #e6e6e6;
  }
`
export const ContainerCalendar = styled.div`
  display: flex;
  flex-direction: column;

  & > div {
    margin-bottom: 10px;
    &:last-child {
      margin: 0;
    }
  }
`
export const ButtonCheck = styled(Button)`
  background-color: ${theme.colors.gray[200]};
  border: none;
  opacity: 0.7;
  width: 100%;
  height: 40px;
  font-weight: 400;
  justify-content: space-between;

  @media only screen and (max-width: ${theme.breakpoints.sm}) {
    font-size: 13.5px;
  }
  &:hover {
    background-color: ${theme.colors.gray[100]};
  }
`
export const CalendarImage = styled.img``
export const CheckBox = styled(Check)`
  & > div {
    &:last-child {
      font-weight: 400;
    }
  }
`

export const ContainerButton = styled.div<IButtonProps>`
  width: ${props => props.width};
`
