/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { FC, useCallback, useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { toast } from 'react-toastify'

import { IObjectLiteral } from '@/@types/common/object-literal'
import { IPermission } from '@/@types/Permission'
import { FullScreenLoader } from '@/components/FullScreenLoader'
import Button from '@/components/Ui/Button'
import { ButtonEdit } from '@/components/Ui/ButtonEdit'
import { getCurrentClientStepType } from '@/modules/Customer/utils/getCurrentClientStepType'
import api from '@/services/api'
import { RootState } from '@/store'
import { BillCreators } from '@/store/ducks/Bill'
import { Creators } from '@/store/ducks/Clients'
import { ClientStepTypeEnum } from '@/store/ducks/Clients/types'

import { EditClientBoard } from './EditClientBoard'
import { EditClientCommissionInstallationCost } from './EditClientCommissionInstallationCost'
import { EditClientEnergyConsumption } from './EditClientEnergyConsumption'
import { EditClientTariff } from './EditClientTariff'
import { PlanGroup } from './PlanGroup'
import { PlanGroupProposal } from './PlanGroupProposal'
import * as S from './styles'

type UpdateProjectInfoType = {
  [key: string]: string | undefined
  structure?: string
  new_standard_justify?: string
  technical_comment?: string
}

const ReportFourPage: FC = () => {
  const dispatch = useDispatch()
  const [signatureContract, setSignatureContract] = useState<boolean>(false)

  let { user_id: userId } = useSelector((state: RootState) => state.Auth)

  if (userId == null) userId = ''

  const permission = useSelector((state: RootState) => state.Auth.role)
  const isFranchisee = permission === IPermission.FRANCHISEE
  const isAdmin = permission === IPermission.ADMIN

  const {
    client,
    clientProjectInfo,
    isLoading,
    isEditClientTariff,
    isEditClientEnergyConsumption,
    isEditClientBoard,
    clientSimulatedPlanValues,
  } = useSelector((state: RootState) => state.Client)
  const { amount: billAmount } = useSelector(
    (state: RootState) => state.Bill.billDetails,
  )

  const [specificProduction, setspecificProduction] = useState<boolean>(false)
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [proposalSimulation, setProposalSimulation] = useState<any>()

  useEffect(() => {
    if (
      !isEditClientTariff &&
      !isEditClientEnergyConsumption &&
      !isEditClientBoard
    ) {
      dispatch(Creators.getClientProjectInfoRequest({ id: client.id }))
      dispatch(BillCreators.getBillDetailsRequest())
    }
  }, [
    client.id,
    dispatch,
    isEditClientTariff,
    isEditClientEnergyConsumption,
    isEditClientBoard,
  ])

  useEffect(() => {
    if (billAmount) {
      const clientSimulationData: IObjectLiteral<number> = {}

      if (clientProjectInfo)
        Object.entries(clientProjectInfo).forEach(([key, value]) => {
          if (value) clientSimulationData[key] = value
        })

      dispatch(
        Creators.getClientSimulationByClientIdRequest({
          ...clientSimulationData,
          id: client.id,
          bill_value: billAmount,
        }),
      )
    }
  }, [billAmount, clientProjectInfo, dispatch, client])

  const currentStep = getCurrentClientStepType(client.steps)

  useEffect(() => {
    dispatch(Creators.getClientFreeMonthRequest())
  }, [dispatch])

  const editForm = useCallback(() => {
    if (isEditClientTariff) {
      return (
        <S.ContainerLegal>
          <S.LabelContainer>
            <label>Tarifas</label>
            {!isEditClientTariff && (
              <ButtonEdit
                onClick={() => dispatch(Creators.setIsEditClientTariff(true))}
              />
            )}
          </S.LabelContainer>

          <EditClientTariff
            onClickToCancel={() =>
              dispatch(Creators.setIsEditClientTariff(false))
            }
          />
        </S.ContainerLegal>
      )
    }

    if (isEditClientEnergyConsumption) {
      return (
        <S.ContainerLegal>
          <S.LabelContainer>
            <label>Consumo de Energia</label>
            {!isEditClientEnergyConsumption && (
              <ButtonEdit
                onClick={() =>
                  dispatch(Creators.setIsEditClientEnergyConsumption(true))
                }
              />
            )}
          </S.LabelContainer>

          <EditClientEnergyConsumption
            onClickToCancel={() =>
              dispatch(Creators.setIsEditClientEnergyConsumption(false))
            }
          />
        </S.ContainerLegal>
      )
    }
    return (
      <S.ContainerLegal>
        <S.LabelContainer>
          {client.subscription_lending !== 'LENDING' || specificProduction ? (
            <label>Info de Projeto</label>
          ) : (
            <label>Comissão e Custo instalação</label>
          )}
          {!isEditClientBoard && (
            <ButtonEdit
              disabled={!isFranchisee}
              onClick={() => {
                setspecificProduction(false)
                dispatch(Creators.setIsEditClientBoard(true))
              }}
            />
          )}
        </S.LabelContainer>

        {client.subscription_lending !== 'LENDING' && (
          <EditClientBoard
            onClickToCancel={() => {
              dispatch(Creators.setIsEditClientBoard(false))
              setspecificProduction(false)
            }}
          />
        )}

        {client.subscription_lending === 'LENDING' && !specificProduction && (
          <EditClientCommissionInstallationCost
            onClickToCancel={() => {
              dispatch(Creators.setIsEditClientBoard(false))
              setspecificProduction(false)
            }}
          />
        )}

        {client.subscription_lending === 'LENDING' && specificProduction && (
          <EditClientBoard
            onClickToCancel={() => {
              dispatch(Creators.setIsEditClientBoard(false))
              setspecificProduction(false)
            }}
          />
        )}
      </S.ContainerLegal>
    )
  }, [
    dispatch,
    client.subscription_lending,
    isEditClientBoard,
    isEditClientEnergyConsumption,
    isEditClientTariff,
    specificProduction,
    isFranchisee,
  ])

  const submitSupplyButton = async () => {
    await api.patch(`admin/customers/${client.id}/step_supply/${''}`)
    toast.success('Step atualizado com sucesso', {
      onClose: () => window.location.reload(),
      autoClose: 2000,
    })
  }

  useEffect(() => {
    api.get(`/admin/check_signers/${client.id}`).then(({ data }) => {
      setSignatureContract(data.is_sign.is_signed)
    })
  }, [client])

  useEffect(() => {
    api.get(`admin/customers/${client.id}/proposal`).then(({ data }) => {
      const finalProposal = data.find(
        (currentProposal: any) => currentProposal.type === 'FINAL',
      )
      setProposalSimulation(finalProposal || undefined)
    })
  }, [client.id])

  const approveSupply = async () => {
    api.put(`admin/approve_supply/${client.id}`).then(() => {
      toast.success('Enviado para instalação com sucesso!', {
        onClose: () => window.location.reload(),
        autoClose: 2000,
      })
    })
  }

  return (
    <>
      {isLoading && <FullScreenLoader />}

      <S.Container>
        <div style={{ marginTop: '25px' }}>
          <S.Header style={{ marginTop: '20px' }}>
            <h1>Supply</h1>
          </S.Header>
        </div>

        {isEditClientTariff ||
        isEditClientEnergyConsumption ||
        isEditClientBoard ? (
          editForm()
        ) : (
          <>
            {clientSimulatedPlanValues && (
              <S.ContainerLegal>
                {client.subscription_lending !== 'LENDING' &&
                (clientProjectInfo?.specific_production || 1) > 500 ? (
                  <PlanGroup simulationValues={clientSimulatedPlanValues} />
                ) : (
                  ''
                )}
                {client.subscription_lending === 'LENDING' &&
                (clientProjectInfo?.specific_production || 1) > 500 ? (
                  <PlanGroupProposal
                    simulationValues={
                      proposalSimulation != null ? proposalSimulation : null
                    }
                  />
                ) : (
                  ''
                )}
              </S.ContainerLegal>
            )}
            {currentStep === ClientStepTypeEnum.SUPPLY ? (
              <S.GroupButton>
                <Button
                  full
                  color="primary"
                  size="default"
                  disabled={(clientProjectInfo?.specific_production || 1) < 500}
                  onClick={() => {
                    approveSupply()
                  }}
                >
                  Enviar para instalação
                </Button>
                {(isFranchisee || isAdmin) && signatureContract ? (
                  <Button
                    full
                    onClick={submitSupplyButton}
                    loading={false}
                    style={{ maxWidth: 450 }}
                  >
                    Enviar para supply
                  </Button>
                ) : (
                  ''
                )}
              </S.GroupButton>
            ) : (
              ''
            )}
          </>
        )}
      </S.Container>
    </>
  )
}

export { ReportFourPage }
