import styled from 'styled-components'

import theme from '@/assets/styles/theme'

export const Container = styled.div`
  .Mui-selected {
    color: ${theme.colors.primary};
    border-color: ${theme.colors.primary};
    background-color: white;
  }
  > nav {
    > ul {
      > li {
        > button {
          background-color: white !important;
          &:hover {
            color: ${theme.colors.primary};
            border-color: ${theme.colors.primary};
            background-color: white;
          }
        }
      }
    }
  }
`
