import React, { useState, useEffect } from 'react'
import { FiPlus, FiMinus } from 'react-icons/fi'
import { MaskedInputProps } from 'react-text-mask'

import { createNumberMask } from 'text-mask-addons'

import {
  convertMoneyFormatToNumber,
  convertNumberToMoneyFormat,
} from '@/utils/convertNumber'

import {
  Container,
  InputNumberContainer,
  Button,
  Buttons,
  Prefix,
  InputContainer,
  Label,
} from './styles'

interface InputNumberProps {
  onChange?: (value: number) => void
  initialValue?: number
  label?: string
  prefix?: string
  increment?: number
  decrement?: number
  maskOptions?: MaskedInputProps
}

const defaultMaskOptions = {
  prefix: '',
  suffix: '',
  includeThousandsSeparator: true,
  thousandsSeparatorSymbol: '.',
  allowDecimal: true,
  decimalSymbol: ',',
  decimalLimit: 3,
  integerLimit: 7,
  allowNegative: false,
  allowLeadingZeroes: false,
}

export const InputNumber: React.FC<InputNumberProps> = ({
  label,
  initialValue = 500,
  increment = 1,
  decrement,
  onChange,
  prefix = 'R$',
  ...maskOptions
}) => {
  const mask = createNumberMask({
    ...defaultMaskOptions,
    ...maskOptions,
  })

  const [value, setValue] = useState<string>(
    convertNumberToMoneyFormat(initialValue),
  )

  useEffect(() => {
    if (onChange) onChange(convertMoneyFormatToNumber(value))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value])

  useEffect(() => {
    setValue(convertNumberToMoneyFormat(initialValue))
  }, [initialValue])

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    try {
      const _value = event.currentTarget.value
      if (_value) setValue(_value)

      if (_value && onChange) onChange(convertMoneyFormatToNumber(_value))
      else setValue('0')
    } catch {
      setValue('0')
    }
  }

  const incrementValue = () => {
    setValue(
      convertNumberToMoneyFormat(convertMoneyFormatToNumber(value) + increment),
    )
  }

  const decrementValue = () => {
    const numValue = convertMoneyFormatToNumber(value)
    if (numValue > 0)
      setValue(convertNumberToMoneyFormat(numValue - (decrement || increment)))
  }

  return (
    <Container>
      <Label>{!!label && label}</Label>
      <InputContainer>
        {prefix && <Prefix>{prefix}</Prefix>}
        <InputNumberContainer
          value={value}
          onChange={handleChange}
          mask={mask}
        />
        <Buttons>
          <Button onClick={incrementValue}>
            <FiPlus size="15px" />
          </Button>
          <Button onClick={decrementValue}>
            <FiMinus size="15px" />
          </Button>
        </Buttons>
      </InputContainer>
    </Container>
  )
}
