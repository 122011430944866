/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react'
import { MdFileDownload, MdVisibility, MdDeleteForever } from 'react-icons/md'

import FileInput from '@/components/FileInput'
import Tooltip from '@material-ui/core/Tooltip'

import * as S from '../styles'

type DocFileProps = {
  text: string
  onChange: any
  file: boolean
  sent: boolean
  getName: string
  // eslint-disable-next-line react/require-default-props
  onClick?: (value: any) => void
  id: string
  // eslint-disable-next-line react/require-default-props
  url_download?: string
  dismiss: boolean
}

const DocFile = ({
  text,
  onChange,
  file,
  sent,
  getName,
  onClick,
  id,
  url_download,
  dismiss,
}: DocFileProps) => {
  const getFilename = (url: string): string => {
    return url.split('/')[url.split('/').length - 1]
  }

  const download = (url: string) => {
    fetch(url, {
      method: 'GET',
      headers: {},
    }).then(response => {
      response.arrayBuffer().then(buffer => {
        const newUrl = window.URL.createObjectURL(new Blob([buffer]))
        const link = document.createElement('a')
        link.href = newUrl
        link.setAttribute('download', getFilename(url))
        document.body.appendChild(link)
        link.click()
      })
    })
  }

  const open = (url: string) => window.open(url, '_blank')

  return (
    <S.DocFileWrapper id={id} dismiss={dismiss}>
      <span>{text}</span>
      <FileInput
        onChange={onChange}
        hasFile={!!file}
        isFileSent={sent}
        fileName={getName}
      />
      {url_download && (
        <S.ContainerIcons>
          <Tooltip title={`Baixar ${text}`} arrow placement="top">
            <MdFileDownload
              size={40}
              style={{ cursor: 'pointer' }}
              onClick={() => download(url_download)}
            />
          </Tooltip>
          <Tooltip title={`Visualizar ${text}`} arrow placement="top">
            <MdVisibility
              size={40}
              style={{ cursor: 'pointer' }}
              onClick={() => open(url_download)}
            />
          </Tooltip>
          {dismiss && (
            <Tooltip title={`Invalidar ${text}`} arrow placement="top">
              <MdDeleteForever
                size={40}
                style={{ cursor: 'pointer' }}
                onClick={onClick}
              />
            </Tooltip>
          )}
        </S.ContainerIcons>
      )}
    </S.DocFileWrapper>
  )
}

export default DocFile
