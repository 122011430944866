import React, { useEffect, useState } from 'react'

import api from '@/services/api'
import * as M from '@material-ui/core'

type ICode = {
  name_id: number
  fullname: string
  email: string
}

const FranchiseeTable: React.FC = () => {
  const [codes, setCodes] = useState<ICode[]>([])

  useEffect(() => {
    const getAll = () =>
      api.get('/admin/users/franchisees').then(({ data }) => setCodes(data))

    getAll()
  }, [])

  return (
    <section>
      <M.TableContainer component={M.Paper}>
        <M.Table>
          <M.TableHead>
            <M.TableRow>
              <M.TableCell>Nome</M.TableCell>
              <M.TableCell>E-mail</M.TableCell>
              <M.TableCell>Código</M.TableCell>
            </M.TableRow>
          </M.TableHead>
          <M.TableBody>
            {codes.map(code => (
              <M.TableRow key={code.name_id}>
                <M.TableCell align="left">{code.fullname}</M.TableCell>
                <M.TableCell align="left">{code.email}</M.TableCell>
                <M.TableCell align="left">{code.name_id}</M.TableCell>
              </M.TableRow>
            ))}
          </M.TableBody>
        </M.Table>
      </M.TableContainer>
    </section>
  )
}

export default FranchiseeTable
