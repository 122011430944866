import React, { FC, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import Input from '@/components/Ui/Form/Input'
import { RootState } from '@/store'
import { Scope } from '@unform/core'

import {
  Container,
  FormLineInput,
  ContainerInput,
  ContainerHistory,
} from './styles'

interface Props {
  averageConsumption: number
  averageApplied: boolean
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const ConsumptionHistory: FC<Props> = ({
  averageConsumption,
  averageApplied,
}) => {
  const consumptionHistory = useSelector(
    (state: RootState) => state.Bill.months,
  )

  const handleMonth: { [key: string]: string } = {
    0: 'Janeiro',
    1: 'Fevereiro',
    2: 'Março',
    3: 'Abril',
    4: 'Maio',
    5: 'Junho',
    6: 'Julho',
    7: 'Agosto',
    8: 'Setembro',
    9: 'Outubro',
    10: 'Novembro',
    11: 'Dezembro',
  }

  const [average, setAverage] = useState<string>()

  useEffect(() => {
    setAverage(averageConsumption.toFixed(0))
  }, [averageConsumption])

  const history = consumptionHistory.map(consumptionHistoryItem => {
    return (
      <>
        <ContainerHistory>
          <Scope path={`month[${consumptionHistoryItem.month}]`}>
            <Input
              name="month"
              value={consumptionHistoryItem.month}
              style={{ display: 'none' }}
            />
            {averageApplied ? (
              <Input
                name="kwh"
                label={handleMonth[consumptionHistoryItem.month]}
                value={average}
                width="100%"
                placeholder="Consumo (kWh)"
              />
            ) : (
              <Input
                name="kwh"
                label={handleMonth[consumptionHistoryItem.month]}
                width="100%"
                placeholder="Consumo (kWh)"
              />
            )}
          </Scope>
        </ContainerHistory>
      </>
    )
  })

  return (
    <Container>
      <FormLineInput>
        <ContainerInput>{history}</ContainerInput>
      </FormLineInput>
    </Container>
  )
}
export { ConsumptionHistory }
