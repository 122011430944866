/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState, useCallback, useEffect, useRef } from 'react'
// import InputMask from 'react-input-mask'
import { useDispatch, useSelector } from 'react-redux'
import { RouteComponentProps } from 'react-router-dom'
import Switch from 'react-switch'
import { toast } from 'react-toastify'

import * as Yup from 'yup'

import { UserRoleEnum, UserStatusEnum } from '@/@types/user'
import { FullScreenLoader } from '@/components/FullScreenLoader'
import PageContent from '@/components/PageContent'
import Button from '@/components/Ui/Button'
import Input from '@/components/Ui/Form/Input'
import { Select } from '@/components/Ui/Select'
import { useState as useCustomState } from '@/hooks/useState'
import api from '@/services/api'
import history from '@/services/history'
import { RootState } from '@/store'
import { Creators } from '@/store/ducks/Users/user'
import { useTheme } from '@material-ui/core'
import Chip from '@material-ui/core/Chip'
import MuiInput from '@material-ui/core/Input'
import MenuItem from '@material-ui/core/MenuItem'
import MuiSelect from '@material-ui/core/Select'
import { FormHandles } from '@unform/core'
import { Form } from '@unform/web'

import {
  Container,
  FormLineInput,
  CheckBoxContainer,
  ContainerButton,
  SelectContainer,
  ContainerForm,
  ContainerInput,
  SwitchContainer,
  SelectSwitchContanier,
} from './styles'

const options = [
  { value: UserRoleEnum.TECHNICIAN, label: 'Técnico' },
  { value: UserRoleEnum.COMMERCIAL, label: 'Comercial' },
  { value: UserRoleEnum.FRANCHISEE, label: 'Franqueado' },
  { value: UserRoleEnum.ADMIN, label: 'Administrador' },
]

const pixOptions = [
  { value: 'cpf', label: 'CPF' },
  { value: 'cnpj', label: 'CNPJ' },
  { value: 'phone', label: 'Telefone' },
  { value: 'random', label: 'Chave aleatoria' },
  { value: 'email', label: 'E-mail' },
]

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
}

interface FormData {
  fullname: string
  email: string
  role: UserRoleEnum
  status: UserStatusEnum
  password: string
  confirm_password: string
  cpf: string
  phone: string
  cep: string
  pix?: string
  pix_type?: string
}
interface IUser {
  id: string
}

type InitialFormData = {
  cpf?: string
  phone?: string
  fullname?: string
  email?: string
  cep?: string
  pix?: string
  pix_type?: string
}

const UserEdit: React.FC<RouteComponentProps<IUser>> = ({ match }) => {
  const theme = useTheme()
  const [citeisSave, setCitiesSave] = useState<string[]>([])

  const [cities, setCities] = React.useState([])

  const { id } = match.params
  const dispatch = useDispatch()

  const { state, setState } = useCustomState<{
    pixType: string
    pixMask: string
  }>({
    pixType: '',
    pixMask: '',
  })

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handlePixChange = (e: any) =>
    setState({
      pixType: e.value,
    })

  const userFormRef = useRef<FormHandles>(null)
  const userData = useSelector((state: RootState) => state.User.user)
  const [selectedRole, setSelectedRole] = useState<UserRoleEnum | undefined>(
    userData?.role,
  )
  const [selectedStatus, setSelectedStatus] = useState<UserStatusEnum>(
    userData?.status,
  )

  const [initialData, setInitialData] = useState<InitialFormData>()
  const isLoading = useSelector(
    (state: RootState) => state.User.loading.put || state.User.loading.get,
  )

  const isCurrentStatus = selectedStatus === 'ACTIVE'
  const [isChecked, setIsChecked] = useState(isCurrentStatus)

  const findObjectByCityId = (array: any, city_id: number) => {
    return array.find((obj: any) => obj.city_id === city_id)
  }

  const findObjectByName = (array: any, name: string) => {
    return array.find((obj: any) => obj.name === name)
  }

  const handleCityNumbers = () => {
    let citeisumbers = ''
    // eslint-disable-next-line array-callback-return
    citeisSave.map((cityName: any) => {
      const city = findObjectByName(cities, cityName)

      citeisumbers = `${citeisumbers}${city.city_id},`
    })
    citeisumbers = citeisumbers.slice(0, -1)

    return citeisumbers
  }

  const handleSubmit = useCallback(
    async (data: FormData) => {
      try {
        const names = data.fullname.split(' ')

        let served_cities: string | null = null

        if (citeisSave.length !== 0) served_cities = handleCityNumbers()

        const { email, password, confirm_password, phone, cpf } = data

        // eslint-disable-next-line
        const userProfile: any = {
          firstname: names[0],
          lastname: names[names.length - 1],
          email,
          role: selectedRole,
          status: selectedStatus,
          cpf: cpf.replace(/\D/g, ''),
          phone: phone ? `55${phone.replace(/[^0-9]/g, '')}` : null,
          pix_type: state.pixType,
          pix: data.pix,
          cep: data.cep,
          served_cities,
        }

        if (password) userProfile.password = password
        if (confirm_password) userProfile.confirm_password = confirm_password

        const userSchema = Yup.object().shape({
          email: Yup.string().email().required('Email obrigatório'),
          firstname: Yup.string().required('Nome obrigatório'),
          cpf: Yup.string().required('CPF obrigatório'),
        })

        await userSchema.validate(userProfile, {
          abortEarly: false,
        })
        dispatch(Creators.editUserRequest(userProfile))
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          toast.error(err.errors[0])
        }
      }
    },
    /* eslint-disable-next-line */
    [selectedRole, selectedStatus, dispatch, state, citeisSave],
  )

  React.useEffect(() => {
    // eslint-disable-next-line
    const pixMaskOptions: any = {
      cpf: '999.999.999-99',
      cnpj: '99.999.999/9999-99',
      phone: '(99) 9 9999-9999',
      random: '*'.repeat(32),
      email: '',
    }
    if (state.pixType) setState({ pixMask: pixMaskOptions[state.pixType] })
    // eslint-disable-next-line
  }, [state.pixType])

  const handleCityArray = (cities_data: any) => {
    if (userData.served_cities) {
      const citiesSplit = userData.served_cities.split(',')
      const newCities: string[] = []

      // eslint-disable-next-line array-callback-return
      citiesSplit.map((cityName: any) => {
        // eslint-disable-next-line radix
        const city = findObjectByCityId(cities_data, parseInt(cityName))
        newCities.push(city.name)
      })

      setCitiesSave(newCities)
    }
  }

  const getCities = async (state: string) => {
    await api.get(`/portal/cities/${state}`).then(({ data }) => {
      setCities(data.data)
      handleCityArray(data.data)
    })
  }

  useEffect(() => {
    dispatch(
      Creators.getUserRequest({
        id,
      }),
    )
  }, [dispatch, id])

  useEffect(() => {
    setSelectedRole(userData?.role)
    if (userData.state) getCities(userData.state)
    // eslint-disable-next-line
  }, [userData?.role, userData?.state])

  useEffect(() => {
    setIsChecked(isCurrentStatus)
    // eslint-disable-next-line
  }, [userData?.status, isCurrentStatus])

  useEffect(() => {
    const {
      cpf,
      phone,
      email,
      firstname,
      lastname,
      pix,
      pix_type,
      cep,
    } = userData

    const initial: InitialFormData = {
      cpf: cpf || '',
      phone: phone ? phone?.replace('55', '') : '',
      email,
      fullname: `${firstname} ${lastname}`,
      pix,
      pix_type,
      cep,
    }

    if (pix_type) setState({ pixType: pix_type })

    setInitialData(initial)
    userFormRef.current?.setData(initial)
    // eslint-disable-next-line
  }, [userData])

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleChange = (e: any) => setSelectedRole(e.value)

  const handleClick = () => {
    setIsChecked(!isChecked)
    setSelectedStatus(UserStatusEnum[isChecked ? 'DISABLED' : 'ACTIVE'])
  }

  const handleCancel = () => history.push('/users')

  function getStyles(name: string, personName: readonly string[], theme: any) {
    return {
      fontWeight:
        personName.indexOf(name) === -1
          ? theme.typography.fontWeightRegular
          : theme.typography.fontWeightMedium,
    }
  }

  const handleCity = (event: any) => {
    setCitiesSave(event.target.value)
  }

  return (
    <PageContent
      title="Editar Usuário"
      breadcrumbsItems={[
        {
          name: 'Usuários',
        },
        {
          name: 'Editar Usuário',
        },
      ]}
    >
      {isLoading ? (
        <FullScreenLoader />
      ) : (
        <Container>
          <Form
            ref={userFormRef}
            initialData={initialData}
            onSubmit={handleSubmit}
            style={{
              height: '100%',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
            }}
          >
            <ContainerForm>
              <FormLineInput>
                <ContainerInput width="700px" marginRight="35px">
                  <Input
                    autoComplete="new-password"
                    name="fullname"
                    label="Nome completo *"
                    width="100%"
                  />
                </ContainerInput>
                <SelectSwitchContanier>
                  <SelectContainer>
                    <Select
                      label="Perfil *"
                      size="custom"
                      options={options}
                      value={options.find(
                        option => option.value === selectedRole,
                      )}
                      onChange={handleChange}
                      isSearchable={false}
                    />
                  </SelectContainer>
                  <SwitchContainer>
                    <CheckBoxContainer>
                      <label>Status *</label>
                      <Switch
                        onChange={handleClick}
                        checked={isChecked}
                        checkedIcon={false}
                        uncheckedIcon={false}
                        offColor="#FF0000"
                        onColor="#FFD500"
                      />
                    </CheckBoxContainer>
                    {!isChecked ? <p>Bloqueado</p> : <p>Ativo</p>}
                  </SwitchContainer>
                </SelectSwitchContanier>
              </FormLineInput>
              <FormLineInput>
                <ContainerInput width="700px" marginRight="35px">
                  <Input name="email" label="Email *" width="100%" />
                </ContainerInput>
                <ContainerInput width="700px" marginRight="35px">
                  <Input
                    hasMask
                    mask="999.999.999-99"
                    name="cpf"
                    label="CPF *"
                    width="100%"
                    placeholder="Digite o número do documento"
                    defaultValue={userData?.cpf}
                  />
                </ContainerInput>
                <ContainerInput width="700px" marginRight="35px">
                  <Input
                    hasMask
                    mask="(99) 9 9999-9999"
                    name="phone"
                    label="Celular"
                    width="100%"
                    type="text"
                    placeholder="Digite o celular com DDD"
                  />
                </ContainerInput>
              </FormLineInput>
              <FormLineInput>
                <ContainerInput width="700px" marginRight="35px">
                  <Input
                    name="password"
                    autoComplete="new-password"
                    label="Senha"
                    width="100%"
                    type="password"
                    placeholder="Digite sua senha"
                    note="Minimo de 8 caracteres com letras e números"
                  />
                </ContainerInput>
                <ContainerInput width="700px" marginRight="35px">
                  <Input
                    name="confirm_password"
                    label="Confirme sua Senha"
                    width="100%"
                    type="password"
                    placeholder="Digite sua senha"
                    note="Confirme a senha"
                  />
                </ContainerInput>
              </FormLineInput>

              <FormLineInput>
                <ContainerInput width="700px" marginRight="35px">
                  <Input
                    name="cep"
                    autoComplete="new-password"
                    label="CEP *"
                    width="100%"
                    type="text"
                    placeholder="Insira seu CEP"
                  />
                </ContainerInput>

                <SelectContainer>
                  <Select
                    label="Tipo de chave PIX *"
                    size="custom"
                    options={pixOptions}
                    value={pixOptions.find(obj => obj.value === state.pixType)}
                    onChange={handlePixChange}
                    isSearchable={false}
                  />
                </SelectContainer>

                <ContainerInput width="700px" marginLeft="35px">
                  <Input
                    name="pix"
                    label="Chave PIX *"
                    width="100%"
                    placeholder="Digite sua chave Pix"
                  />
                </ContainerInput>

                {userData?.role === 'FRANCHISEE' ? (
                  <ContainerInput width="700px" marginLeft="35px">
                    <label style={{ marginBottom: '10px' }}>
                      Cidades atendidas
                    </label>
                    <MuiSelect
                      style={{
                        width: '250px',
                        border: '0px !important',
                      }}
                      multiple
                      value={citeisSave}
                      onChange={handleCity}
                      input={
                        <MuiInput
                          id="outlined-basic"
                          style={{ width: '250px' }}
                        />
                      }
                      renderValue={(selected: any) => (
                        <div>
                          {selected.map((value: any) => (
                            <Chip key={value} label={value} />
                          ))}
                        </div>
                      )}
                      MenuProps={MenuProps}
                    >
                      {cities.map((name: any) => (
                        <MenuItem
                          key={name.name}
                          value={name.name}
                          style={getStyles(name.name, citeisSave, theme)}
                        >
                          {name.name}
                        </MenuItem>
                      ))}
                    </MuiSelect>
                  </ContainerInput>
                ) : (
                  ''
                )}
              </FormLineInput>
            </ContainerForm>
            <ContainerButton>
              <Button
                size="default"
                color="cancel"
                type="button"
                onClick={handleCancel}
              >
                Cancelar
              </Button>
              <Button
                size="default"
                color="primary"
                type="submit"
                style={{ marginLeft: '20px' }}
              >
                Salvar alterações
              </Button>
            </ContainerButton>
          </Form>
        </Container>
      )}
    </PageContent>
  )
}

export { UserEdit }
