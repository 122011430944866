import React, { useEffect, useState, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'

import shortid from 'shortid'
import validator from 'validator'

import { FullScreenLoader } from '@/components/FullScreenLoader'
import Button from '@/components/Ui/Button'
import { GenericModal } from '@/components/Ui/GenericModal'
import ManageFiles, { IFile } from '@/components/Ui/ManageFiles'
import { StepDetailTitle } from '@/components/Ui/StepDetailTitle'
import { deleteDatabookFiles } from '@/modules/Customer/providers/databook/deleteDatabookFile'
import { uploadFiles } from '@/providers/uploadFiles/uploadFiles'
import { RootState } from '@/store'
import {
  DatabookCreators,
  IDatabookData,
  IUpdateDatabookFileData,
} from '@/store/ducks/Databook'

import { Container, ContainerButton } from './styles'

interface IResponseUploadFile {
  key: string
  url: string
}
interface IAttibutesDeleteStepFile {
  step: number
  deleteFile: IFile
}

const Databook: React.FC = () => {
  const dispatch = useDispatch()
  const [isLoading, setIsLoading] = useState(false)
  const [newDatabook, setNewDatabook] = useState<IDatabookData[] | undefined>()
  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false)
  const [attributesDeleteStepFile, setAttributesDeleteStepFile] = useState<
    IAttibutesDeleteStepFile
  >()

  useEffect(() => {
    dispatch(DatabookCreators.getDatabookRequest())
  }, [dispatch])

  const databookData = useSelector(
    (state: RootState) => state.Databook.databook,
  )
  const isPatchLoading = useSelector(
    (state: RootState) => state.Databook.isLoading,
  )

  useEffect(() => {
    databookData && setNewDatabook(databookData)
  }, [databookData])

  const handleUploadFile = async (step: number, file: File | null) => {
    try {
      if (file !== null) {
        setIsLoading(true)
        const formData = new FormData()
        formData.append('file', file)
        const uploadResponse: IResponseUploadFile = await uploadFiles(formData)
        const uploadFile = {
          id: shortid.generate(),
          url: uploadResponse.url,
        }
        setNewDatabook(
          prevDatabook =>
            prevDatabook &&
            prevDatabook.map(newDataBookItem => {
              const files =
                newDataBookItem.step === step
                  ? [...newDataBookItem.files, uploadFile]
                  : newDataBookItem.files
              return {
                ...newDataBookItem,
                files,
              }
            }),
        )
      }
    } catch (error) {
      toast.error('Não foi possível fazer o upload do arquivo.')
    } finally {
      setIsLoading(false)
    }
  }

  const handleDeleteFile = async (step: number, deleteFile: IFile) => {
    try {
      setIsLoading(true)
      if (validator.isUUID(deleteFile.id)) {
        await deleteDatabookFiles(deleteFile.id)
      }
      setNewDatabook(
        prevDatabook =>
          prevDatabook &&
          prevDatabook.map(newDataBookItem => {
            const files =
              newDataBookItem.step === step
                ? newDataBookItem.files.filter(
                    file => file.id !== deleteFile.id,
                  )
                : newDataBookItem.files
            return {
              ...newDataBookItem,
              files,
            }
          }),
      )
    } catch (error) {
      toast.error('Não foi possível apagar o arquivo!')
    } finally {
      setIsLoading(false)
    }
  }

  const steps = newDatabook?.map(item => {
    return (
      <>
        <StepDetailTitle number={item.step.toString()} title={item.description}>
          <ManageFiles
            onDeleteFile={file => {
              setAttributesDeleteStepFile({ step: item.step, deleteFile: file })
              setIsOpenDeleteModal(true)
            }}
            onUploadFile={file => handleUploadFile(item.step, file)}
            fileList={item.files.map(file => {
              return {
                id: file.id,
                type: 'image',
                url: file.url,
              }
            })}
          />
        </StepDetailTitle>
      </>
    )
  })

  const handleSubmit = useCallback(() => {
    try {
      const updateDatabookFiles =
        newDatabook?.map(item => {
          const files: IUpdateDatabookFileData[] = item.files.map(file => {
            return {
              id: validator.isUUID(file.id) ? file.id : undefined,
              name: file.name,
              url: file.url,
            }
          })
          return {
            id: item.id,
            files,
          }
        }) || []

      dispatch(DatabookCreators.editDatabookRequest(updateDatabookFiles))
    } catch (error) {
      toast.error('Não foi possível editar o databook')
    }
  }, [dispatch, newDatabook])

  const handleOnClose = () => {
    setIsOpenDeleteModal(false)
  }

  const handleOnClickConfirm = () => {
    setIsOpenDeleteModal(false)
    if (attributesDeleteStepFile) {
      handleDeleteFile(
        attributesDeleteStepFile.step,
        attributesDeleteStepFile.deleteFile,
      )
    }
  }

  return (
    <>
      {(isLoading || isPatchLoading) && <FullScreenLoader />}
      <Container>
        {isOpenDeleteModal && (
          <GenericModal
            onClose={handleOnClose}
            openModal={isOpenDeleteModal}
            onClickConfirm={handleOnClickConfirm}
            label="Deseja deletar a imagem?"
          />
        )}
        {steps}
        <ContainerButton>
          <Button
            size="default"
            type="button"
            onClick={() => setNewDatabook(databookData)}
          >
            Voltar
          </Button>
          <Button size="default" color="primary" onClick={handleSubmit}>
            Salvar alterações
          </Button>
        </ContainerButton>
      </Container>
    </>
  )
}

export { Databook }
