import styled from 'styled-components'

import theme from '@/assets/styles/theme'

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  > div {
    &:last-child {
      margin-top: 20px;
    }
  }
`
export const LabelContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: baseline;
  > label {
    font-size: 26px;
    font-family: bold, Roboto;
    font-weight: 500;
    padding: 0px 0 20px 0px;
    margin-right: 25px;
  }
`
export const ContainerNF = styled.div`
  display: flex;
  margin-top: 10px;
  background-color: ${theme.colors.gray[100]};
  justify-content: space-between;
  padding: 30px;
  flex-direction: column;
  height: 340px;
`

export const ContainerColumn = styled.div`
  display: flex;
  width: 100%;
  height: 110px;
  flex-direction: column;
  justify-content: space-between;
`

export const FieldsLabel = styled.div`
  padding-top: 10px;
`
export const Fields = styled.span`
  font-size: 16px;
  font: Regular 16px/22px Roboto;
  opacity: 0.7;
`

export const Font = styled.b`
  font: Bold 16px/22px Roboto;
  opacity: 1;
`
