import React from 'react'

import AuroraHappy from '@/assets/images/aurora/happy/happy-01.svg'
import { NotificationMessage } from '@/components/NotificationMessage'

import { Container, Avatar, TextContainer, Time, Circle } from './styles'

interface NotificationProps {
  notificationId: string
  clientId: string
  clientName: string
  message: string
  time: string
  isRead: boolean
  onClick: (id: string) => void
}

const NotificationItem: React.FC<NotificationProps> = ({
  notificationId,
  clientId,
  clientName,
  message,
  time,
  isRead,
  onClick,
}) => {
  return (
    <Container
      hasBackground={!isRead}
      hasBorderBottom
      onClick={() => onClick(notificationId)}
    >
      <Avatar>
        <img src={AuroraHappy} alt="aurora feliz" />
      </Avatar>
      <TextContainer>
        <NotificationMessage
          clientName={clientName}
          message={message}
          clientId={clientId}
          key={notificationId}
        />
        <Time>{time}</Time>
      </TextContainer>
      <Circle hasBackground={!isRead} />
    </Container>
  )
}

export { NotificationItem }
