import { FaCheck } from 'react-icons/fa'

import styled, { css } from 'styled-components'

import theme from '@/assets/styles/theme'

interface IPropsSteps {
  type?: 'concluded' | 'pending'
}

const StepsVariations = {
  concluded: css`
    background-color: ${theme.colors.green[300]};
    color: ${theme.colors.green[200]};
  `,

  pending: css`
    background-color: ${theme.colors.red[300]};
    color: ${theme.colors.red[100]};
  `,
}

export const Container = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 244px;
  height: 282px;
  border: 0.5px solid #70707069;
  border-radius: 5px;
  padding: 0px 50px 10px 50px;
`

export const Up = styled.div<IPropsSteps>`
  font-family: 'Roboto', sans-serif;
  background: #43cc00;
  border-radius: 0px 0px 5px 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 173px;
  height: 29px;

  ${props => StepsVariations[props.type || 'pending']}

  & > span {
    color: #ffffff;
    font-weight: 700;
  }
`

export const ContainerIcon = styled.div<IPropsSteps>`
  width: 75px;
  height: 75px;
  background: ${props =>
    props.type !== 'pending'
      ? theme.colors.green[100]
      : theme.colors.gray[100]};
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
`

export const Icon = styled(FaCheck)`
  font-size: 40px;
`

export const TextsStepsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin-bottom: 15px;

  & > span:first-child {
    font-family: 'Roboto', sans-serif;
    color: #3b3b3b;
    font-weight: 700;
    font-size: 20px;
    padding-bottom: 20px;
  }

  & > span:last-child {
    font-family: 'Roboto', sans-serif;
    color: #3b3b3b;
    font-size: 16px;
    line-height: 20px;
  }
`
