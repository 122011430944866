/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { ChangeEvent, useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { isEmpty, last } from 'lodash'
import moment from 'moment'

import theme from '@/assets/styles/theme'
import { FullScreenLoader } from '@/components/FullScreenLoader'
import { useState as hookState } from '@/hooks/useState'
import { RootState } from '@/store'
import { Creators } from '@/store/ducks/Clients'
import { IFile, IMessage, ProjectStatusType } from '@/store/ducks/Clients/types'
import { StateCreators } from '@/store/ducks/State'
import { Creators as UsersCreators } from '@/store/ducks/Users'
import {
  Grid,
  Select,
  MenuItem,
  FormControlLabel,
  TextField,
} from '@material-ui/core'
import Checkbox, { CheckboxProps } from '@material-ui/core/Checkbox'
import { withStyles } from '@material-ui/core/styles'
import { CloudDownload, Visibility } from '@material-ui/icons'

import { statuses } from './data/statuses'
import * as S from './styles'

const DATE_FORMAT = 'DD/MM/YYYY [às] HH[h]mm'

interface ICustomerSuccessState {
  isLoading: boolean
  requireClientAction: number
  postMessage: string
}

type CustomerSuccessType = {
  projectCurrentStatus: ProjectStatusType | string
  checkContactNeeded: boolean
  checkEnableUpload: boolean
}

const SolarCheckbox: any = withStyles({
  root: {
    color: '#fda500',
    '&$checked': {
      color: theme.colors.primary,
    },
  },
  checked: {},
})((props: CheckboxProps) => <Checkbox color="default" {...props} />)

const CustomerSuccess = () => {
  const { state, setState } = hookState<ICustomerSuccessState>({
    isLoading: true,
    requireClientAction: 0,
    postMessage: '',
  })

  const [customerSuccess, setCustomerSuccess] = useState<CustomerSuccessType>({
    projectCurrentStatus: statuses[0].status,
    checkContactNeeded: false,
    checkEnableUpload: false,
  })

  const dispatch = useDispatch()

  const client = useSelector((state: RootState) => state.Client.client)
  const userData = useSelector((state: RootState) => state.User.user)
  const { user_id: userId } = useSelector((state: RootState) => state.Auth)

  useEffect(() => {
    if (!userData.fullname)
      dispatch(UsersCreators.getUserRequest({ id: userId as string }))
  }, [userData, dispatch, userId])

  useEffect(() => {
    setCustomerSuccess({
      projectCurrentStatus: client.customer_success?.status
        ? client.customer_success.status
        : statuses[0].status,
      checkEnableUpload:
        client.customer_success?.upload_available === undefined
          ? false
          : client.customer_success?.upload_available,
      checkContactNeeded:
        client.customer_success?.contact_available === undefined
          ? false
          : client.customer_success?.contact_available,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [client])

  const messages = useSelector(
    (state: RootState) => state.Client.costumerSuccessMessages,
  )
  const files = useSelector((state: RootState) => state.Client.files)

  useEffect(() => {
    if (isEmpty(client?.id)) return
    dispatch(StateCreators.getStatesRequest())
    if (client && client?.customer_success?.id) {
      dispatch(Creators.confirmMessagesRequest())
      dispatch(Creators.confirmFilesRequest())
    } else {
      dispatch(Creators.confirmClientSuccessTablesRequest())
    }
    setState({ ...state, isLoading: false })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, client])

  const getMimeType = useCallback((name: string) => {
    const nameArray = name.split('.')
    const extensionType = nameArray.slice(-1)
    switch (extensionType[0]) {
      case 'png':
        return 'image/png'
      case 'jpeg' || 'jpg':
        return 'image/jpeg'
      case 'pdf':
        return 'application/pdf'
      case 'webp':
        return 'image/webp'
      default:
        return 'text/plain'
    }
  }, [])

  const handleFileDownload = useCallback(
    (url: string, name: string) => {
      const mimeType = getMimeType(name)
      // eslint-disable-next-line no-console
      console.log(mimeType)
      const linkElement = document.createElement('a')
      linkElement.href = url
      linkElement.download = name
      linkElement.click()
      linkElement.remove()
    },
    [getMimeType],
  )

  const handleFileView = useCallback((url: string) => {
    const anchorElement = document.createElement('a')
    anchorElement.href = url
    anchorElement.target = '_blanck'
    anchorElement.click()
    anchorElement.remove()
  }, [])

  const renderMessages = useCallback(() => {
    const { messages: msg } = messages as any

    if (isEmpty(msg))
      return <S.Message color="bold">Sem mensagens até o momento</S.Message>

    const messagesArray = msg.map((_msg: IMessage) => {
      const bold = _msg === last(msg) ? undefined : 'bold'
      const { message: _message } = _msg
      return (
        <S.Message color={bold} key={_msg.id}>
          {_message}
        </S.Message>
      )
    })

    return messagesArray.reverse()
  }, [messages])

  const renderFiles = useCallback(() => {
    const { files: fileData } = files as any

    if (isEmpty(fileData))
      return <S.Message color="bold">Sem arquivos até o momento</S.Message>

    return (
      <>
        <S.FileBox color="header">
          <S.FileHeader>Nome do arquivo</S.FileHeader>
          <S.FileHeader>Data do documento</S.FileHeader>
        </S.FileBox>
        {fileData.map((_data: IFile) => (
          <S.FileBox key={_data.id}>
            <span>{_data.name}</span>
            <span>{moment(_data.created_at).format('L')}</span>
            <S.IconBox>
              <S.IconButton
                onClick={() => handleFileDownload(_data.url, _data.name)}
              >
                <CloudDownload />
              </S.IconButton>
              <S.IconButton onClick={() => handleFileView(_data.url)}>
                <Visibility />
              </S.IconButton>
            </S.IconBox>
          </S.FileBox>
        ))}
      </>
    )
  }, [files, handleFileDownload, handleFileView])

  const handleCurrentStatusChange = (
    event: React.ChangeEvent<{ value: any }>,
  ) =>
    setCustomerSuccess(prev => ({
      ...prev,
      projectCurrentStatus: event.target.value,
    }))

  const handleChangeClientAction = (ev: ChangeEvent<HTMLInputElement>) => {
    const { value } = ev.target

    value === 'client-action-contact'
      ? setCustomerSuccess((prev: CustomerSuccessType) => ({
          ...prev,
          checkContactNeeded: !prev.checkContactNeeded,
        }))
      : setCustomerSuccess((prev: CustomerSuccessType) => ({
          ...prev,
          checkEnableUpload: !prev.checkEnableUpload,
        }))
  }

  const saveClientFeedback = () => {
    if (
      customerSuccess.projectCurrentStatus !== client.customer_success?.status
    )
      dispatch(
        Creators.confirmUpdateStatusRequest(
          customerSuccess.projectCurrentStatus as ProjectStatusType,
        ),
      )
    if (state.postMessage.length) {
      const _message = `${userData.fullname} (${moment().format(
        DATE_FORMAT,
      )}): ${state.postMessage}`
      dispatch(Creators.confirmSaveMessageRequest(_message))
      setState({ ...state, postMessage: '' })
    }
    if (
      customerSuccess.checkEnableUpload !==
      client.customer_success?.upload_available
    )
      dispatch(
        Creators.confirmUploadEnableRequest(customerSuccess.checkEnableUpload),
      )
    if (
      customerSuccess.checkContactNeeded !==
      client.customer_success?.contact_available
    )
      dispatch(
        Creators.confirmContactNeededRequest(
          customerSuccess.checkContactNeeded,
        ),
      )
  }

  if (state.isLoading) return <FullScreenLoader />

  return (
    <Grid container spacing={3}>
      {client?.customer_success?.id ? (
        <>
          <Grid item xs={4}>
            <S.FormControl variant="outlined">
              <S.InputLabel id="select-project-current-status">
                Status atual do projeto
              </S.InputLabel>
              <Select
                labelId="select-project-current-status"
                id="demo-simple-select-outlined"
                value={customerSuccess.projectCurrentStatus}
                onChange={handleCurrentStatusChange}
                label="Status atual do projeto"
              >
                {statuses.map(sts => (
                  <MenuItem value={sts.status} key={sts.id}>
                    {sts.label}
                  </MenuItem>
                ))}
              </Select>
            </S.FormControl>
            <p style={{ marginTop: '20px' }}>Necessita de ação do cliente?</p>
            <FormControlLabel
              control={
                // eslint-disable-next-line
                <SolarCheckbox
                  checked={customerSuccess.checkContactNeeded}
                  onChange={handleChangeClientAction}
                  name="client-action-contact"
                  value="client-action-contact"
                />
              }
              label="Entrar em contato"
            />

            <FormControlLabel
              control={
                // eslint-disable-next-line
                <SolarCheckbox
                  checked={customerSuccess.checkEnableUpload}
                  onChange={handleChangeClientAction}
                  name="client-action-upload"
                  value="client-action-upload"
                />
              }
              label="Liberar envio de documento"
            />
          </Grid>

          <Grid item xs={8}>
            <S.BoardHeader>Mural de Status</S.BoardHeader>
            <S.MessageBox>{renderMessages()}</S.MessageBox>
            <S.FormBox>
              <S.Label htmlFor="custumer-message">
                Digite uma mensagem para postar no mural do cliente
              </S.Label>
              <TextField
                id="custumer-message"
                name="custumer-message"
                multiline
                rows={2}
                variant="outlined"
                value={state.postMessage}
                onChange={e =>
                  setState({ ...state, postMessage: e.target.value })
                }
              />
            </S.FormBox>
          </Grid>
          <S.SaveBox>
            <S.SaveButton onClick={saveClientFeedback}>
              Salvar Alterações
            </S.SaveButton>
          </S.SaveBox>
          <Grid item xs={12}>
            {renderFiles()}
          </Grid>
        </>
      ) : (
        <Grid item xs={12}>
          <S.NotAllowedMessage>
            O cliente ainda não assinou a todos os contratos.
          </S.NotAllowedMessage>
        </Grid>
      )}
    </Grid>
  )
}

export default CustomerSuccess
