import styled from 'styled-components'

import theme from '@/assets/styles/theme'

interface ItemNameProps {
  cursor?: 'pointer' | 'auto'
}

export const Container = styled.div`
  display: flex;
  justify-content: space-between;

  @media screen and (max-width: 1100px) {
    justify-content: flex-start;
  }
`

export const Item = styled.div`
  display: flex;
  justify-content: space-between;
  color: ${theme.colors.gray[500]};

  @media screen and (max-width: 1100px) {
    font-size: 1rem;
  }
`

export const ItemName = styled.div<ItemNameProps>`
  cursor: ${props => props.cursor || 'auto'};
`

export const Separator = styled.div`
  margin-left: 5px;
  margin-right: 5px;
`
