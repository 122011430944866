export enum TechnicalVisitStatusEnum {
  UNCONFIRMED_APPOINTMENT = 'UNCONFIRMED_APPOINTMENT',
  VISIT_CANCELED = 'VISIT_CANCELED',
  LINK_TECHNICAL = 'LINK_TECHNICAL',
  WAITING_FOR_TECHNICIAN = 'WAITING_FOR_TECHNICIAN',
  SCHEDULE_REFUSED = 'SCHEDULE_REFUSED',
  FULFILLED = 'FULFILLED',
  CONFIRMED_SCHEDULE = 'CONFIRMED_SCHEDULE',
  DONE_AND_NOT_APPROVED = 'DONE_AND_NOT_APPROVED',
  ACCOMPLISHED_AND_APPROVED = 'ACCOMPLISHED_AND_APPROVED',
}
