import { EditorState, ContentState, convertToRaw } from 'draft-js'
import draftToHtml from 'draftjs-to-html'
import htmlToDraft from 'html-to-draftjs'

export const htmlToEditor = (html: string): EditorState => {
  const draft = htmlToDraft(html)
  const state = ContentState.createFromBlockArray(draft.contentBlocks)
  return EditorState.createWithContent(state)
}

export const editorToHTML = (editorContent: ContentState): string => {
  return draftToHtml(convertToRaw(editorContent))
}
