import produce from 'immer'
import { createReducer, createActions } from 'reduxsauce'

import { TypeDocumentEnum } from '@/@types/client/client'
import { TechnicalVisitStatusEnum } from '@/@types/visit'

import { StatusType } from '../Visit/types'
import {
  ClientTypeEnum,
  IActionTypes,
  IClientStateType,
  IClientsData,
  IClientItem,
  IPlanSimulated,
  IClientPlan,
  IClientLegal,
  IClientDocument,
  IClientDocumentStatus,
  IClientProjectInfo,
  IClientPlanSimulated,
  IMessage,
  IFile,
  ProjectStatusType,
  IFreeMonth,
  IPartnership,
} from './types'

type ClientType = typeof ClientTypeEnum

// CreateActions
export const { Types, Creators } = createActions<ClientType, IActionTypes>({
  getClientsRequest: ['query'],
  getClientsSuccess: ['clients'],
  getClientsFailure: {},
  getClientRequest: { id: '' },
  getClientSuccess: ['client'],
  getClientFailure: {},
  editClientRequest: ['client'],
  editClientSuccess: {},
  editClientFailure: {},
  getClientSimulationRequest: { id: '' },
  getClientSimulationSuccess: ['planSimulated'],
  getClientSimulationFailure: {},
  getClientPlanRequest: { id: '' },
  getClientPlanSuccess: ['clientPlan'],
  getClientPlanFailure: {},
  getClientLegalRequest: { id: '' },
  getClientLegalSuccess: ['clientLegal'],
  getClientLegalFailure: {},
  editClientLegalRequest: ['clientLegal'],
  editClientLegalSuccess: {},
  editClientLegalFailure: {},
  setIsEditClientLegal: ['isEditClientLegal'],
  getClientDocumentsRequest: { id: '' },
  getClientDocumentsSuccess: ['clientDocuments'],
  getClientDocumentsFailure: {},
  editClientDocumentsStatusRequest: ['clientDocumentsStatus'],
  editClientDocumentsStatusSuccess: ['clientDocuments'],
  editClientDocumentsStatusFailure: {},
  setIsEditClientDetails: ['isEditClientDetails'],
  getClientProjectInfoRequest: { id: '' },
  getClientProjectInfoSuccess: ['clientProjectInfo'],
  getClientProjectInfoFailure: {},
  setIsEditClientTariff: ['isEditClientTariff'],
  editClientTariffRequest: ['clientTariff'],
  editClientCommissionInstallationCostRequest: ['clientInstallationCost'],
  editClientTariffSuccess: {},
  editClientTariffFailure: {},
  setIsEditClientEnergyConsumption: ['isEditClientEnergyConsumption'],
  editClientEnergyConsumptionRequest: ['clientEnergyConsumption'],
  editClientEnergyConsumptionSuccess: {},
  editClientEnergyConsumptionFailure: {},
  setIsEditClientBoard: ['isEditClientBoard'],
  editClientBoardRequest: ['clientBoard'],
  editClientBoardSuccess: {},
  editClientBoardFailure: {},
  getClientSimulationByClientIdRequest: ['simulationPayload'],
  getClientSimulationByClientIdSuccess: ['clientSimulatedPlanValues'],
  getClientSimulationByClientIdFailure: {},
  setClientGeneratePlansRequest: { id: '' },
  setClientGeneratePlansSuccess: {},
  setClientGeneratePlansFailure: {},
  confirmInstalationRequest: {},
  confirmInstalationSuccess: {},
  confirmInstalationFailure: {},
  confirmMessagesRequest: {},
  confirmMessagesSuccess: ['messages'],
  confirmMessagesFailure: {},
  confirmSaveMessageRequest: ['postMessage'],
  confirmSaveMessageSuccess: {},
  confirmSaveMessageFailure: {},
  confirmUpdateStatusRequest: ['status'],
  confirmUpdateStatusSuccess: {},
  confirmUpdateStatusFailure: {},
  confirmFilesRequest: {},
  confirmFilesSuccess: ['files'],
  confirmFilesFailure: {},
  confirmUploadEnableRequest: ['uploadAvailable'],
  confirmUploadEnableSuccess: {},
  confirmUploadEnableFailure: {},
  confirmContactNeededRequest: ['contactAvailable'],
  confirmContactNeededSuccess: {},
  confirmContactNeededFailure: {},
  confirmClientSuccessTablesRequest: {},
  confirmClientSuccessTablesSuccess: {},
  confirmClientSuccessTablesFailure: {},
  getClientFreeMonthRequest: {},
  getClientFreeMonthSuccess: ['freeMonths'],
  getClientFreeMonthFailure: {},
  setClientFreeMonthRequest: ['enable'],
  setClientFreeMonthSuccess: {},
  setClientFreeMonthFailure: {},
  getClientPartnershipRequest: {},
  getClientPartnershipSuccess: ['partnership'],
  getClientPartnershipFailure: {},
  confirmPartnershipRequest: {},
  confirmPartnershipSuccess: {},
  confirmPartnershipFailure: {},
})

/* ------------- Initial State ------------- */

export const INITIAL_STATE: IClientStateType = {
  clients: {
    items: [],
    meta: {
      current_page: 1,
      item_count: 1,
      items_per_page: 1,
      total_items: 1,
      total_pages: 1,
    },
  },
  isClientLoading: false,
  client: {
    id: '',
    name: '',
    email: '',
    state: {
      name: '',
      state: '',
    },
    steps: [],
    type: {
      type: TypeDocumentEnum.CPF,
      value: '',
    },
    city: {
      name: '',
      state: '',
    },
    technical_visit: {
      address: '',
      neighborhood: '',
      cep: '',
      city: '',
      complement: '',
      created_at: '',
      gps: '',
      id: '',
      number: '',
      phone: '',
      schedule: '',
      state: '',
      status: TechnicalVisitStatusEnum.WAITING_FOR_TECHNICIAN,
      technician: {
        avatar: '',
        cpf: '',
        updated_at: '',
        status: StatusType.ACTIVE,
        created_at: '',
        email: '',
        firstname: '',
        fullname: '',
        id: '',
        lastname: '',
        phone: '',
        role: '',
        username: '',
      },
      updated_at: '',
    },
    phone: '',
    unit_type: '',
    address: '',
    address_complement: '',
    address_neighborhood: '',
    address_number: '',
    postal_code: '',
    customer_success: undefined,
  },
  isEditClientDetails: false,
  planSimulated: undefined,
  clientPlan: undefined,
  clientLegal: undefined,
  isEditClientLegal: false,
  clientDocuments: [],
  clientProjectInfo: undefined,
  isEditClientTariff: false,
  isEditClientEnergyConsumption: false,
  isEditClientBoard: false,
  clientSimulatedPlanValues: undefined,
  isLoading: false,
  costumerSuccessMessages: [],
  postMessage: '',
  status: '',
  files: [],
  uploadAvailable: false,
  contactAvailable: false,
  freeMonths: {},
  partnership: {},
}

/* ------------- Reducers ------------- */

export const getClientsRequest = (state = INITIAL_STATE): IClientStateType =>
  produce(state, () => ({
    ...state,
    isClientLoading: true,
  }))

export const getClientsSuccess = (
  state: IClientStateType = INITIAL_STATE,
  { clients }: { clients: IClientsData },
): IClientStateType =>
  produce(state, () => ({
    ...state,
    clients,
    isClientLoading: false,
  }))

export const getClientsFailure = (
  state: IClientStateType = INITIAL_STATE,
): IClientStateType =>
  produce(state, () => ({
    ...state,
    isClientLoading: false,
  }))

export const getClientRequest = (state = INITIAL_STATE): IClientStateType =>
  produce(state, () => ({
    ...state,
    isLoading: true,
  }))

export const getClientSuccess = (
  state: IClientStateType = INITIAL_STATE,
  { client }: { client: IClientItem },
): IClientStateType =>
  produce(state, () => ({
    ...state,
    client,
    isLoading: false,
  }))

export const getClientFailure = (
  state: IClientStateType = INITIAL_STATE,
): IClientStateType =>
  produce(state, () => ({
    ...state,
    isLoading: false,
  }))

export const getClientSimulationRequest = (
  state = INITIAL_STATE,
): IClientStateType =>
  produce(state, () => ({
    ...state,
    isLoading: true,
  }))

export const getClientSimulationSuccess = (
  state: IClientStateType = INITIAL_STATE,
  { planSimulated }: { planSimulated: IPlanSimulated },
): IClientStateType =>
  produce(state, () => ({
    ...state,
    planSimulated,
    isLoading: false,
  }))

export const getClientSimulationFailure = (
  state: IClientStateType = INITIAL_STATE,
): IClientStateType =>
  produce(state, () => ({
    ...state,
    isLoading: false,
  }))

export const editClientRequest = (
  state: IClientStateType = INITIAL_STATE,
): IClientStateType =>
  produce(state, () => ({
    ...state,
    isClientEdit: false,
    isLoading: true,
  }))

export const editClientSuccess = (
  state: IClientStateType = INITIAL_STATE,
): IClientStateType =>
  produce(state, () => ({
    ...state,
    isClientEdit: true,
    isLoading: false,
  }))

export const editClientFailure = (
  state: IClientStateType = INITIAL_STATE,
): IClientStateType =>
  produce(state, () => ({
    ...state,
    isClientEdit: false,
    isLoading: false,
  }))

export const getClientPlanRequest = (state = INITIAL_STATE): IClientStateType =>
  produce(state, () => ({
    ...state,
    isLoading: true,
  }))

export const getClientPlanSuccess = (
  state: IClientStateType = INITIAL_STATE,
  { clientPlan }: { clientPlan: IClientPlan },
): IClientStateType =>
  produce(state, () => ({
    ...state,
    clientPlan,
    isLoading: false,
  }))

export const getClientPlanFailure = (
  state: IClientStateType = INITIAL_STATE,
): IClientStateType =>
  produce(state, () => ({
    ...state,
    isLoading: false,
    clientPlan: undefined,
  }))

export const getClientLegalRequest = (
  state = INITIAL_STATE,
): IClientStateType =>
  produce(state, () => ({
    ...state,
    isLoading: true,
  }))

export const getClientLegalSuccess = (
  state: IClientStateType = INITIAL_STATE,
  { clientLegal }: { clientLegal: IClientLegal },
): IClientStateType =>
  produce(state, () => ({
    ...state,
    clientLegal,
    isLoading: false,
  }))

export const getClientLegalFailure = (
  state: IClientStateType = INITIAL_STATE,
): IClientStateType =>
  produce(state, () => ({
    ...state,
    isLoading: false,
  }))

export const editClientLegalRequest = (
  state: IClientStateType = INITIAL_STATE,
): IClientStateType =>
  produce(state, () => ({
    ...state,
    isLoading: true,
  }))

export const editClientLegalSuccess = (
  state: IClientStateType = INITIAL_STATE,
): IClientStateType =>
  produce(state, () => ({
    ...state,
    isLoading: false,
    isEditClientLegal: false,
  }))

export const editClientLegalFailure = (
  state: IClientStateType = INITIAL_STATE,
): IClientStateType =>
  produce(state, () => ({
    ...state,
    isLoading: false,
    isEditClientLegal: true,
  }))

export const setIsEditClientLegal = (
  state: IClientStateType = INITIAL_STATE,
  { isEditClientLegal }: { isEditClientLegal: boolean },
): IClientStateType =>
  produce(state, () => ({
    ...state,
    isLoading: false,
    isEditClientLegal,
  }))

export const getClientDocumentsRequest = (
  state = INITIAL_STATE,
): IClientStateType =>
  produce(state, () => ({
    ...state,
    isLoading: true,
  }))

export const getClientDocumentsSuccess = (
  state: IClientStateType = INITIAL_STATE,
  { clientDocuments }: { clientDocuments: IClientDocument[] },
): IClientStateType =>
  produce(state, () => ({
    ...state,
    clientDocuments,
    isLoading: false,
  }))

export const getClientDocumentsFailure = (
  state: IClientStateType = INITIAL_STATE,
): IClientStateType =>
  produce(state, () => ({
    ...state,
    isLoading: false,
  }))

export const editClientDocumentsStatusRequest = (
  state: IClientStateType = INITIAL_STATE,
  clientDocumentsStatus: IClientDocumentStatus[],
): IClientStateType =>
  produce(state, () => ({
    ...state,
    clientDocumentsStatus,
    isLoading: true,
  }))

export const editClientDocumentsStatusSuccess = (
  state: IClientStateType = INITIAL_STATE,
  { clientDocuments }: { clientDocuments: IClientDocument[] },
): IClientStateType =>
  produce(state, () => ({
    ...state,
    clientDocuments,
    isLoading: false,
  }))

export const editClientDocumentsStatusFailure = (
  state: IClientStateType = INITIAL_STATE,
): IClientStateType =>
  produce(state, () => ({
    ...state,
    isLoading: false,
  }))

export const setIsEditClientDetails = (
  state = INITIAL_STATE,
  { isEditClientDetails }: { isEditClientDetails: boolean },
) => ({
  ...state,
  isEditClientDetails,
})

export const getClientProjectInfoRequest = (
  state = INITIAL_STATE,
): IClientStateType =>
  produce(state, () => ({
    ...state,
    isLoading: true,
  }))

export const getClientProjectInfoSuccess = (
  state: IClientStateType = INITIAL_STATE,
  { clientProjectInfo }: { clientProjectInfo: IClientProjectInfo },
): IClientStateType =>
  produce(state, () => ({
    ...state,
    clientProjectInfo,
    isLoading: false,
  }))

export const getClientProjectInfoFailure = (
  state: IClientStateType = INITIAL_STATE,
): IClientStateType =>
  produce(state, () => ({
    ...state,
    isLoading: false,
    clientProjectInfo: undefined,
  }))

export const setIsEditClientTariff = (
  state: IClientStateType = INITIAL_STATE,
  { isEditClientTariff }: { isEditClientTariff: boolean },
): IClientStateType =>
  produce(state, () => ({
    ...state,
    isLoading: false,
    isEditClientTariff,
  }))

export const editClientTariffRequest = (
  state: IClientStateType = INITIAL_STATE,
): IClientStateType =>
  produce(state, () => ({
    ...state,
    isLoading: true,
  }))

export const editClientCommissionInstallationCostRequest = (
  state: IClientStateType = INITIAL_STATE,
): IClientStateType =>
  produce(state, () => ({
    ...state,
    isLoading: true,
  }))

export const editClientTariffSuccess = (
  state: IClientStateType = INITIAL_STATE,
): IClientStateType =>
  produce(state, () => ({
    ...state,
    isLoading: false,
    isEditClientTariff: false,
  }))

export const editClientTariffFailure = (
  state: IClientStateType = INITIAL_STATE,
): IClientStateType =>
  produce(state, () => ({
    ...state,
    isLoading: false,
    isEditClientTariff: true,
  }))

export const setIsEditClientEnergyConsumption = (
  state: IClientStateType = INITIAL_STATE,
  { isEditClientEnergyConsumption }: { isEditClientEnergyConsumption: boolean },
): IClientStateType =>
  produce(state, () => ({
    ...state,
    isLoading: false,
    isEditClientEnergyConsumption,
  }))

export const editClientEnergyConsumptionRequest = (
  state: IClientStateType = INITIAL_STATE,
): IClientStateType =>
  produce(state, () => ({
    ...state,
    isLoading: true,
  }))

export const editClientEnergyConsumptionSuccess = (
  state: IClientStateType = INITIAL_STATE,
): IClientStateType =>
  produce(state, () => ({
    ...state,
    isLoading: false,
    isEditClientEnergyConsumption: false,
  }))

export const editClientEnergyConsumptionFailure = (
  state: IClientStateType = INITIAL_STATE,
): IClientStateType =>
  produce(state, () => ({
    ...state,
    isLoading: false,
    isEditClientEnergyConsumption: true,
  }))

export const setIsEditClientBoard = (
  state: IClientStateType = INITIAL_STATE,
  { isEditClientBoard }: { isEditClientBoard: boolean },
): IClientStateType =>
  produce(state, () => ({
    ...state,
    isLoading: false,
    isEditClientBoard,
  }))

export const editClientBoardRequest = (
  state: IClientStateType = INITIAL_STATE,
): IClientStateType =>
  produce(state, () => ({
    ...state,
    isLoading: true,
  }))

export const editClientBoardSuccess = (
  state: IClientStateType = INITIAL_STATE,
): IClientStateType =>
  produce(state, () => ({
    ...state,
    isLoading: false,
    isEditClientBoard: false,
  }))

export const editClientBoardFailure = (
  state: IClientStateType = INITIAL_STATE,
): IClientStateType =>
  produce(state, () => ({
    ...state,
    isLoading: false,
    isEditClientBoard: true,
  }))

export const getClientSimulationByClientIdRequest = (
  state = INITIAL_STATE,
): IClientStateType =>
  produce(state, () => ({
    ...state,
  }))

export const getClientSimulationByClientIdSuccess = (
  state: IClientStateType = INITIAL_STATE,
  {
    clientSimulatedPlanValues,
  }: { clientSimulatedPlanValues: IClientPlanSimulated },
): IClientStateType =>
  produce(state, () => ({
    ...state,
    clientSimulatedPlanValues,
  }))

export const getClientSimulationByClientIdFailure = (
  state: IClientStateType = INITIAL_STATE,
): IClientStateType =>
  produce(state, () => ({
    ...state,
  }))

export const setClientGeneratePlansRequest = (
  state = INITIAL_STATE,
): IClientStateType =>
  produce(state, () => ({
    ...state,
    isLoading: true,
  }))

export const setClientGeneratePlansSuccess = (
  state = INITIAL_STATE,
): IClientStateType =>
  produce(state, () => ({
    ...state,
    isLoading: false,
  }))

export const setClientGeneratePlansFailure = (
  state = INITIAL_STATE,
): IClientStateType =>
  produce(state, () => ({
    ...state,
    isLoading: false,
  }))

export const confirmInstalationRequest = (
  state: IClientStateType = INITIAL_STATE,
) => ({
  ...state,
  isLoading: true,
})

export const confirmInstalationSuccess = (
  state: IClientStateType = INITIAL_STATE,
) => ({
  ...state,
  isLoading: false,
})

export const confirmInstalationFailure = (
  state: IClientStateType = INITIAL_STATE,
) => ({
  ...state,
  isLoading: false,
})

export const confirmMessagesRequest = (
  state: IClientStateType = INITIAL_STATE,
) => ({
  ...state,
  isLoading: true,
})

export const confirmMessagesSuccess = (
  state: IClientStateType = INITIAL_STATE,
  messages: IMessage[],
): IClientStateType =>
  produce(state, () => ({
    ...state,
    costumerSuccessMessages: messages,
    isLoading: false,
  }))

export const confirmMessagesFailure = (
  state: IClientStateType = INITIAL_STATE,
) => ({
  ...state,
  costumerSuccessMessages: [],
  isLoading: false,
})

export const confirmUpdateStatusRequest = (
  state: IClientStateType = INITIAL_STATE,
  status: ProjectStatusType,
) => ({
  ...state,
  isLoading: true,
  status,
})

export const confirmUpdateStatusSuccess = (
  state: IClientStateType = INITIAL_STATE,
) => ({
  ...state,
  isLoading: false,
})

export const confirmUpdateStatusFailure = (
  state: IClientStateType = INITIAL_STATE,
) => ({
  ...state,
  isLoading: false,
})

export const confirmSaveMessageRequest = (
  state: IClientStateType = INITIAL_STATE,
  postMessage: string,
) => ({
  ...state,
  isLoading: true,
  postMessage,
})

export const confirmSaveMessageSuccess = (
  state: IClientStateType = INITIAL_STATE,
) => ({
  ...state,
  isLoading: false,
})

export const confirmSaveMessageFailure = (
  state: IClientStateType = INITIAL_STATE,
) => ({
  ...state,
  isLoading: false,
})

export const confirmUploadEnableRequest = (
  state: IClientStateType = INITIAL_STATE,
  uploadAvailable: boolean,
) => ({
  ...state,
  isLoading: true,
  uploadAvailable,
})

export const confirmUploadEnableSuccess = (
  state: IClientStateType = INITIAL_STATE,
): IClientStateType =>
  produce(state, () => ({
    ...state,
    isLoading: false,
  }))

export const confirmUploadEnableFailure = (
  state: IClientStateType = INITIAL_STATE,
) => ({
  ...state,
  isLoading: false,
})

export const confirmFilesRequest = (
  state: IClientStateType = INITIAL_STATE,
) => ({
  ...state,
  isLoading: true,
})

export const confirmFilesSuccess = (
  state: IClientStateType = INITIAL_STATE,
  files: IFile[],
): IClientStateType =>
  produce(state, () => ({
    ...state,
    files,
    isLoading: false,
  }))

export const confirmFilesFailure = (
  state: IClientStateType = INITIAL_STATE,
) => ({
  ...state,
  files: [],
  isLoading: false,
})

export const confirmContactNeededRequest = (
  state: IClientStateType = INITIAL_STATE,
  contactAvailable: boolean,
) => ({
  ...state,
  isLoading: true,
  contactAvailable,
})

export const confirmContactNeededSuccess = (
  state: IClientStateType = INITIAL_STATE,
): IClientStateType =>
  produce(state, () => ({
    ...state,
    isLoading: false,
  }))

export const confirmContactNeededFailure = (
  state: IClientStateType = INITIAL_STATE,
) => ({
  ...state,
  isLoading: false,
})

export const confirmClientSuccessTablesRequest = (
  state: IClientStateType = INITIAL_STATE,
) => ({
  ...state,
  isClientLoading: true,
})

export const confirmClientSuccessTablesSuccess = (
  state: IClientStateType = INITIAL_STATE,
): IClientStateType =>
  produce(state, () => ({
    ...state,
    isClientLoading: false,
  }))

export const confirmClientSuccessTablesFailure = (
  state: IClientStateType = INITIAL_STATE,
) => ({
  ...state,
  isClientLoading: false,
})

export const getClientFreeMonthRequest = (
  state: IClientStateType = INITIAL_STATE,
) => ({
  ...state,
  isClientLoading: true,
})

export const getClientFreeMonthSuccess = (
  state: IClientStateType = INITIAL_STATE,
  { freeMonths }: { freeMonths: IFreeMonth },
): IClientStateType =>
  produce(state, () => ({
    ...state,
    isClientLoading: false,
    freeMonths,
  }))

export const getClientFreeMonthFailure = (
  state: IClientStateType = INITIAL_STATE,
) => ({
  ...state,
  isClientLoading: false,
  freeMonths: {},
})

export const setClientFreeMonthRequest = (
  state: IClientStateType = INITIAL_STATE,
) => ({
  ...state,
  isClientLoading: true,
})

export const setClientFreeMonthSuccess = (
  state: IClientStateType = INITIAL_STATE,
): IClientStateType =>
  produce(state, () => ({
    ...state,
    isClientLoading: false,
  }))

export const setClientFreeMonthFailure = (
  state: IClientStateType = INITIAL_STATE,
) => ({
  ...state,
  isClientLoading: false,
})

export const getClientPartnershipRequest = (
  state: IClientStateType = INITIAL_STATE,
) => ({
  ...state,
  isClientLoading: true,
})

export const getClientPartnershipSuccess = (
  state: IClientStateType = INITIAL_STATE,
  { partnership }: { partnership: IPartnership },
): IClientStateType =>
  produce(state, () => ({
    ...state,
    isClientLoading: false,
    partnership,
  }))

export const getClientPartnershipFailure = (
  state: IClientStateType = INITIAL_STATE,
) => ({
  ...state,
  isClientLoading: false,
})

export const confirmPartnershipRequest = (
  state: IClientStateType = INITIAL_STATE,
) => ({
  ...state,
  isClientLoading: true,
})

export const confirmPartnershipSuccess = (
  state: IClientStateType = INITIAL_STATE,
): IClientStateType =>
  produce(state, () => ({
    ...state,
    isClientLoading: false,
  }))

export const confirmPartnershipFailure = (
  state: IClientStateType = INITIAL_STATE,
) => ({
  ...state,
  isClientLoading: false,
})

/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer<IClientStateType, IActionTypes>(
  INITIAL_STATE,
  {
    [Types.GET_CLIENTS_REQUEST]: getClientsRequest,
    [Types.GET_CLIENTS_SUCCESS]: getClientsSuccess,
    [Types.GET_CLIENTS_FAILURE]: getClientsFailure,

    [Types.GET_CLIENT_REQUEST]: getClientRequest,
    [Types.GET_CLIENT_SUCCESS]: getClientSuccess,
    [Types.GET_CLIENT_FAILURE]: getClientFailure,

    [Types.EDIT_CLIENT_REQUEST]: editClientRequest,
    [Types.EDIT_CLIENT_SUCCESS]: editClientSuccess,
    [Types.EDIT_CLIENT_FAILURE]: editClientFailure,

    [Types.GET_CLIENT_SIMULATION_REQUEST]: getClientSimulationRequest,
    [Types.GET_CLIENT_SIMULATION_SUCCESS]: getClientSimulationSuccess,
    [Types.GET_CLIENT_SIMULATION_FAILURE]: getClientSimulationFailure,

    [Types.GET_CLIENT_PLAN_REQUEST]: getClientPlanRequest,
    [Types.GET_CLIENT_PLAN_SUCCESS]: getClientPlanSuccess,
    [Types.GET_CLIENT_PLAN_FAILURE]: getClientPlanFailure,

    [Types.GET_CLIENT_LEGAL_REQUEST]: getClientLegalRequest,
    [Types.GET_CLIENT_LEGAL_SUCCESS]: getClientLegalSuccess,
    [Types.GET_CLIENT_LEGAL_FAILURE]: getClientLegalFailure,

    [Types.EDIT_CLIENT_LEGAL_REQUEST]: editClientLegalRequest,
    [Types.EDIT_CLIENT_LEGAL_SUCCESS]: editClientLegalSuccess,
    [Types.EDIT_CLIENT_LEGAL_FAILURE]: editClientLegalFailure,

    [Types.SET_IS_EDIT_CLIENT_LEGAL]: setIsEditClientLegal,

    [Types.GET_CLIENT_DOCUMENTS_REQUEST]: getClientDocumentsRequest,
    [Types.GET_CLIENT_DOCUMENTS_SUCCESS]: getClientDocumentsSuccess,
    [Types.GET_CLIENT_DOCUMENTS_FAILURE]: getClientDocumentsFailure,

    [Types.EDIT_CLIENT_DOCUMENTS_STATUS_REQUEST]: editClientDocumentsStatusRequest,
    [Types.EDIT_CLIENT_DOCUMENTS_STATUS_SUCCESS]: editClientDocumentsStatusSuccess,
    [Types.EDIT_CLIENT_DOCUMENTS_STATUS_FAILURE]: editClientDocumentsStatusFailure,
    [Types.GET_CLIENT_SIMULATION_REQUEST]: getClientSimulationRequest,
    [Types.GET_CLIENT_SIMULATION_SUCCESS]: getClientSimulationSuccess,
    [Types.GET_CLIENT_SIMULATION_FAILURE]: getClientSimulationFailure,

    [Types.SET_IS_EDIT_CLIENT_DETAILS]: setIsEditClientDetails,

    [Types.GET_CLIENT_PROJECT_INFO_REQUEST]: getClientProjectInfoRequest,
    [Types.GET_CLIENT_PROJECT_INFO_SUCCESS]: getClientProjectInfoSuccess,
    [Types.GET_CLIENT_PROJECT_INFO_FAILURE]: getClientProjectInfoFailure,

    [Types.SET_IS_EDIT_CLIENT_TARIFF]: setIsEditClientTariff,

    [Types.EDIT_CLIENT_TARIFF_REQUEST]: editClientTariffRequest,
    [Types.EDIT_CLIENT_COMMISSION_INSTALLATION_COST_REQUEST]: editClientCommissionInstallationCostRequest,
    [Types.EDIT_CLIENT_TARIFF_SUCCESS]: editClientTariffSuccess,
    [Types.EDIT_CLIENT_TARIFF_FAILURE]: editClientTariffFailure,

    [Types.SET_IS_EDIT_CLIENT_ENERGY_CONSUMPTION]: setIsEditClientEnergyConsumption,

    [Types.EDIT_CLIENT_ENERGY_CONSUMPTION_REQUEST]: editClientEnergyConsumptionRequest,
    [Types.EDIT_CLIENT_ENERGY_CONSUMPTION_SUCCESS]: editClientEnergyConsumptionSuccess,
    [Types.EDIT_CLIENT_ENERGY_CONSUMPTION_FAILURE]: editClientEnergyConsumptionFailure,

    [Types.SET_IS_EDIT_CLIENT_BOARD]: setIsEditClientBoard,

    [Types.EDIT_CLIENT_BOARD_REQUEST]: editClientBoardRequest,
    [Types.EDIT_CLIENT_BOARD_SUCCESS]: editClientBoardSuccess,
    [Types.EDIT_CLIENT_BOARD_FAILURE]: editClientBoardFailure,

    [Types.GET_CLIENT_SIMULATION_BY_CLIENT_ID_REQUEST]: getClientSimulationByClientIdRequest,
    [Types.GET_CLIENT_SIMULATION_BY_CLIENT_ID_SUCCESS]: getClientSimulationByClientIdSuccess,
    [Types.GET_CLIENT_SIMULATION_BY_CLIENT_ID_FAILURE]: getClientSimulationByClientIdFailure,

    [Types.SET_CLIENT_GENERATE_PLANS_REQUEST]: setClientGeneratePlansRequest,
    [Types.SET_CLIENT_GENERATE_PLANS_SUCCESS]: setClientGeneratePlansSuccess,
    [Types.SET_CLIENT_GENERATE_PLANS_FAILURE]: setClientGeneratePlansFailure,

    [Types.CONFIRM_INSTALATION_REQUEST]: confirmInstalationRequest,
    [Types.CONFIRM_INSTALATION_SUCCESS]: confirmInstalationSuccess,
    [Types.CONFIRM_INSTALATION_FAILURE]: confirmInstalationFailure,

    [Types.CONFIRM_MESSAGES_REQUEST]: confirmMessagesRequest,
    [Types.CONFIRM_MESSAGES_SUCCESS]: confirmMessagesSuccess,
    [Types.CONFIRM_MESSAGES_FAILURE]: confirmMessagesFailure,

    [Types.CONFIRM_SAVE_MESSAGE_REQUEST]: confirmSaveMessageRequest,
    [Types.CONFIRM_SAVE_MESSAGE_SUCCESS]: confirmSaveMessageSuccess,
    [Types.CONFIRM_SAVE_MESSAGE_FAILURE]: confirmSaveMessageFailure,

    [Types.CONFIRM_UPDATE_STATUS_REQUEST]: confirmUpdateStatusRequest,
    [Types.CONFIRM_UPDATE_STATUS_SUCCESS]: confirmUpdateStatusSuccess,
    [Types.CONFIRM_UPDATE_STATUS_FAILURE]: confirmUpdateStatusFailure,

    [Types.CONFIRM_FILES_REQUEST]: confirmFilesRequest,
    [Types.CONFIRM_FILES_SUCCESS]: confirmFilesSuccess,
    [Types.CONFIRM_FILES_FAILURE]: confirmFilesFailure,

    [Types.CONFIRM_UPLOAD_ENABLE_REQUEST]: confirmUploadEnableRequest,
    [Types.CONFIRM_UPLOAD_ENABLE_SUCCESS]: confirmUploadEnableSuccess,
    [Types.CONFIRM_UPLOAD_ENABLE_FAILURE]: confirmUploadEnableFailure,

    [Types.CONFIRM_CONTACT_NEEDED_REQUEST]: confirmContactNeededRequest,
    [Types.CONFIRM_CONTACT_NEEDED_SUCCESS]: confirmContactNeededSuccess,
    [Types.CONFIRM_CONTACT_NEEDED_FAILURE]: confirmContactNeededFailure,

    [Types.CONFIRM_CLIENT_SUCCESS_TABLES_REQUEST]: confirmClientSuccessTablesRequest,
    [Types.CONFIRM_CLIENT_SUCCESS_TABLES_SUCCESS]: confirmClientSuccessTablesSuccess,
    [Types.CONFIRM_CLIENT_SUCCESS_TABLES_FAILURE]: confirmClientSuccessTablesFailure,

    [Types.GET_CLIENT_FREE_MONTH_REQUEST]: getClientFreeMonthRequest,
    [Types.GET_CLIENT_FREE_MONTH_SUCCESS]: getClientFreeMonthSuccess,
    [Types.GET_CLIENT_FREE_MONTH_FAILURE]: getClientFreeMonthFailure,

    [Types.SET_CLIENT_FREE_MONTH_REQUEST]: setClientFreeMonthRequest,
    [Types.SET_CLIENT_FREE_MONTH_SUCCESS]: setClientFreeMonthSuccess,
    [Types.SET_CLIENT_FREE_MONTH_FAILURE]: setClientFreeMonthFailure,

    [Types.GET_CLIENT_PARTNERSHIP_REQUEST]: getClientPartnershipRequest,
    [Types.GET_CLIENT_PARTNERSHIP_SUCCESS]: getClientPartnershipSuccess,
    [Types.GET_CLIENT_PARTNERSHIP_FAILURE]: getClientPartnershipFailure,

    [Types.CONFIRM_PARTNERSHIP_REQUEST]: confirmPartnershipRequest,
    [Types.CONFIRM_PARTNERSHIP_SUCCESS]: confirmPartnershipSuccess,
    [Types.CONFIRM_PARTNERSHIP_FAILURE]: confirmPartnershipFailure,
  },
)
