import React, { KeyboardEvent } from 'react'
import { toast } from 'react-toastify'

import { useState } from '@/hooks/useState'
import api from '@/services/api'
import { TextField } from '@material-ui/core'

import SimpleModal from '../SimpleModal'
import { Category as CategoryInterface } from './faq.types'
import * as Styles from './styles'

interface IManageCategoriesDialog {
  categories: CategoryInterface[]
  onCloseDialog: () => void
  updateCategories: () => void
  onUpdateOrder: (cats: CategoryInterface[]) => void
}

const ManageCategoriesDialog: React.FC<IManageCategoriesDialog> = ({
  categories,
  onCloseDialog,
  updateCategories,
}): JSX.Element => {
  const editRef = React.createRef<HTMLInputElement>()
  const newRef = React.createRef<HTMLInputElement>()

  interface IState {
    loading: boolean
    showAddItem: boolean
    editItem: number
    editingInProgress: boolean
    creatingInProgress: boolean
  }

  const { state, setState } = useState<IState>({
    loading: false,
    showAddItem: false,
    editItem: 0,
    editingInProgress: false,
    creatingInProgress: false,
  })

  const editCatName = async (
    catId: CategoryInterface['id'],
    newName: string,
  ): Promise<void> => {
    setState({ editingInProgress: true })
    api
      .put(`/faq/categories/${catId}`, {
        title: newName,
      })
      .then(({ data }) => {
        if (data.affected > 0) {
          toast.success('Categoria atualizada')
        }
      })
      .finally(async () => {
        await updateCategories()
        setState({ editingInProgress: false, editItem: 0 })
      })
  }

  const onChangeCatName = (event: KeyboardEvent<HTMLInputElement>) => {
    const value = editRef?.current?.value || null

    switch (event.key) {
      case 'Escape':
        setState({ editItem: 0 })
        break
      case 'Enter':
        if (value) editCatName(state.editItem, value || '')
        break

      default:
        break
    }
  }

  const createCat = async (title: string): Promise<void> => {
    setState({ creatingInProgress: true })
    api
      .post('/faq/categories', { title })
      .then(({ data }) => {
        if (data.id) toast.success('Categoria criada com sucesso')
      })
      .finally(async () => {
        await updateCategories()
        setState({ showAddItem: false, creatingInProgress: false })
      })
  }

  const onCreateCatName = (event: KeyboardEvent<HTMLInputElement>) => {
    const value = newRef?.current?.value || null

    switch (event.key) {
      case 'Escape':
        setState({ showAddItem: false })
        break
      case 'Enter':
        if (value) createCat(value)
        break

      default:
        break
    }
  }

  return (
    <SimpleModal title="Gerenciar Categorias" onClose={onCloseDialog}>
      {!!categories.length && (
        <Styles.CategoryList>
          {categories.map((cat: CategoryInterface) => (
            <React.Fragment key={cat.id}>
              {state.editItem === cat.id ? (
                <div>
                  <TextField
                    variant="outlined"
                    label="Nome da categoria"
                    onBlur={() => setState({ editItem: 0 })}
                    inputRef={editRef}
                    disabled={state.editingInProgress}
                    type="text"
                    defaultValue={cat.title}
                    onKeyDown={onChangeCatName}
                  />
                </div>
              ) : (
                /* eslint-disable */
                <li
                  onClick={
                    state.editingInProgress
                      ? () => {}
                      : () => setState({ editItem: cat.id })
                  }
                  key={`cat-item-${cat.id}`}
                >
                  {cat.title}
                </li>
                /* eslint-enable */
              )}
            </React.Fragment>
          ))}
        </Styles.CategoryList>
      )}

      <Styles.BtnContainer>
        <Styles.ActionButton
          onClick={() =>
            setState({
              showAddItem: true,
            })
          }
          loading={state.loading}
        >
          + Adicionar nova
        </Styles.ActionButton>
      </Styles.BtnContainer>

      {state.showAddItem && (
        <div style={{ marginTop: 20, textAlign: 'center', marginBottom: 20 }}>
          <p style={{ paddingBottom: 10 }}>Criar nova categoria</p>
          <TextField
            variant="outlined"
            label="Nome da categoria"
            inputRef={newRef}
            disabled={state.creatingInProgress}
            type="text"
            onKeyDown={onCreateCatName}
          />
        </div>
      )}

      <Styles.EditContainerActionGroup>
        <Styles.ActionButton
          onClick={onCloseDialog}
          loading={state.loading}
          color="error"
        >
          Cancelar
        </Styles.ActionButton>
        {/* <Styles.ActionButton
          onClick={handleSubmit}
          loading={loading}
          color="success"
        >
          Salvar alterações
        </Styles.ActionButton> */}
      </Styles.EditContainerActionGroup>
    </SimpleModal>
  )
}

export default ManageCategoriesDialog
