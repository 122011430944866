import React from 'react'
import Iframe from 'react-iframe'

import { Title } from '@/components/PageContent/styles'

import { Container, ContainerIframe } from './styles'

const BIMain: React.FC = () => {
  return (
    <Container>
      <br />
      <Title>Operação Usinas</Title>
      <br />
      <ContainerIframe>
        <Iframe
          url="https://app.powerbi.com/view?r=eyJrIjoiZDIzYTEyOTMtMDNjNy00YWJhLWIxMzAtZTQ0M2YwNmZhZDFmIiwidCI6IjFhMjBkM2Q0LTNkY2QtNGY2Zi04YTVjLTFmMmU4MjQwYjdlYSJ9"
          width="100%"
          height="800px"
          id="myId"
          className="myClassname"
          display="inline"
          position="relative"
        />
      </ContainerIframe>
      <br />
      <Title>Implantação Usinas</Title>
      <br />
      <ContainerIframe>
        <Iframe
          url="https://app.powerbi.com/view?r=eyJrIjoiNGFkNzhhMDItNzIxNS00MTMzLTlmMWMtYTgzNDBhYThhNDlhIiwidCI6Ijg4MmU3YjZiLWM5ZTMtNDM3Yi05YzcyLTg2OWE3ZGFkYzUzMCJ9&pageName=ReportSection76461ec89029e67f4745"
          width="100%"
          height="800px"
          id="myId"
          className="myClassname"
          display="inline"
          styles={{ top: '-800px' }}
          position="relative"
        />
      </ContainerIframe>
    </Container>
  )
}

export default BIMain
