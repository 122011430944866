import MaskedInput from 'react-text-mask'

import styled from 'styled-components'

import theme from '@/assets/styles/theme'

export const Container = styled.div``
export const InputContainer = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  margin-bottom: 30px;
`
export const Label = styled.div`
  padding: 5px 0;
  font-size: 1rem;

  @media only screen and (max-width: 750px) {
    height: 2.2rem;
    font-size: 0.8rem;
  }
`

export const InputNumberContainer = styled(MaskedInput)`
  border: 1px solid ${theme.colors.gray[400]};
  width: 100%;
  height: 100%;
  color: #1a1818;
  display: block;
  margin-top: 3px;
  padding: 18px;
  resize: vertical;
  -webkit-transition: border-color 0.2s;
  transition: border-color 0.2s;
  width: 100%;
`
