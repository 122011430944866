import axios from 'axios'
import { call, put } from 'redux-saga/effects'

import { CommonCreators } from '../ducks/common'

export function* requestUfs() {
  try {
    const { data } = yield call(
      axios.get,
      `${process.env.REACT_APP_IBGE_API}localidades/estados`,
    )
    yield put(CommonCreators.ufSuccess(data))
  } catch (err) {
    yield put(CommonCreators.ufFailure())
  }
}
