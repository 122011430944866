import React from 'react'
import { useSelector } from 'react-redux'

import moment from 'moment'

import { useState } from '@/hooks/useState'
import api from '@/services/api'
import { RootState } from '@/store'
import { file2Base64 } from '@/utils'
import { insertCnpjMask } from '@/utils/insertNumberMask/insertCnpjMask'
import { Button, LinearProgress } from '@material-ui/core'

import {
  Container,
  LabelContainer,
  ContainerNF,
  ContainerColumn,
  Fields,
  FieldsLabel,
  Font,
} from './styles'

interface NFState {
  upload?: File | null
  nf?: NFData
  submiting: boolean
}

interface NFData {
  client_id: string
  description: string
  id: number
  key: string
  nfe_date: Date
  nfe_value: string
  number: string
  peak_power: string
  provider_cnpj: string
  provider_name: string
  upload_url: string
}

const NF = () => {
  const client = useSelector((state: RootState) => state.Client.client)
  const { state, setState } = useState<NFState>({
    upload: undefined,
    nf: undefined,
    submiting: false,
  })

  const getNfs = () => {
    api.get(`/admin/nfs/${client.id}`).then(({ data }) => {
      // eslint-disable-next-line
      console.log('@getNfs =>', data)
      if (data?.id) setState({ nf: data })
    })
  }

  const uploadAndParse = (b64: string) => {
    setState({ submiting: true })
    api
      .post(`/admin/nfs/${client.id}`, { xml: b64 })
      .then(({ data }) => {
        // eslint-disable-next-line
        console.log('@uploadAndParse', data)
        getNfs()
      })
      .finally(() => {
        setState({ submiting: false })
      })
  }

  const onChangeUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
    event.persist()
    const { files } = event.currentTarget
    if (files?.length) {
      const upload: File | null = files[0] || undefined
      const result = await file2Base64(upload)
      uploadAndParse(result.replace('data:text/xml;base64,', ''))
      setState({ upload })
    } else {
      setState({ upload: undefined })
    }
    // eslint-disable-next-line
  }

  React.useEffect(() => {
    getNfs()
    // eslint-disable-next-line
  }, [])

  React.useEffect(() => {
    // eslint-disable-next-line
    console.log('nf change', state.nf)
    // eslint-disable-next-line
  }, [state.nf])

  return (
    <div>
      <Button
        style={{
          borderRadius: 8,
          backgroundColor: '#ffd500',
          fontSize: '14px',
          fontWeight: 'bold',
          padding: '10px',
        }}
        disabled={state.submiting}
        variant="contained"
        component="label"
      >
        Enviar Nota Fiscal (.xml)
        <input
          disabled={state.submiting}
          accept="text/xml"
          onChange={onChangeUpload}
          type="file"
          hidden
        />
      </Button>

      {state.submiting && (
        <LinearProgress style={{ marginTop: 20, marginBottom: 20 }} />
      )}

      {!!state.nf?.id && (
        <Container>
          <div>
            <LabelContainer>
              <label>Dados Nota Fiscal</label>
            </LabelContainer>

            <ContainerNF>
              <ContainerColumn>
                <FieldsLabel>
                  <Fields>
                    <Font>Nome do fornecedor: </Font> {state.nf.provider_name}
                  </Fields>
                </FieldsLabel>
                <FieldsLabel>
                  <Fields>
                    <Font>CNPJ do fornecedor: </Font>{' '}
                    {insertCnpjMask(state.nf.provider_cnpj)}
                  </Fields>
                </FieldsLabel>
                <FieldsLabel>
                  <Fields>
                    <Font>Descrição: </Font> {state.nf.description}
                  </Fields>
                </FieldsLabel>
                <FieldsLabel>
                  <Fields>
                    <Font>Número: </Font> NF{state.nf.number}
                  </Fields>
                </FieldsLabel>
                <FieldsLabel>
                  <Fields>
                    <Font>Chave: </Font> {state.nf.key}
                  </Fields>
                </FieldsLabel>
                <FieldsLabel>
                  <Fields>
                    <Font>Potência: </Font> {state.nf.peak_power} kWp
                  </Fields>
                </FieldsLabel>
                <FieldsLabel>
                  <Fields>
                    <Font>Data da Nota: </Font>{' '}
                    {moment(state.nf.nfe_date).format('DD/MM/YYYY')}
                  </Fields>
                </FieldsLabel>
                <FieldsLabel>
                  <Fields>
                    <Font>Valor da Nota: </Font>{' '}
                    {parseFloat(state.nf.nfe_value).toLocaleString('pt-BR', {
                      style: 'currency',
                      currency: 'BRL',
                    })}
                  </Fields>
                </FieldsLabel>
                <FieldsLabel>
                  <Fields>
                    <Font>
                      <a
                        href={state.nf.upload_url}
                        rel="noopener noreferrer"
                        target="_blank"
                      >
                        Download da nota fiscal
                      </a>{' '}
                    </Font>
                  </Fields>
                </FieldsLabel>
              </ContainerColumn>
            </ContainerNF>
          </div>
        </Container>
      )}
    </div>
  )
}

export default NF
