import React from 'react'
import { useSelector } from 'react-redux'

import { RootState } from '@/store'
import { IClientPlanSimulated } from '@/store/ducks/Clients/types'
import { insertBRNumberMask } from '@/utils/insertNumberMask/insertBRNumberMask'

import { SimulatedPlan } from './SimulatedPlan'
import { ContainerPlan } from './styles'

const PlanGroup = ({
  simulationValues,
}: {
  simulationValues: IClientPlanSimulated
}) => {
  const clientType = useSelector(
    (state: RootState) => state.Client.client.type.type,
  )

  const simulation = {
    kwh_kwp: insertBRNumberMask(simulationValues.project_indicators.kwh_kwp),
    old_bill_annual_value: insertBRNumberMask(
      simulationValues.annual_values.old_bill_annual_value,
    ), // Valor anual conta antiga sem solar 21
    new_bill_annual_value: insertBRNumberMask(
      simulationValues.annual_values.new_bill_annual_value,
    ), // Valor Anual conta nova com solar 21
    number_of_project_modules:
      simulationValues.photovoltaic_system.number_of_project_modules, // Número de Placas
    power_of_the_project: insertBRNumberMask(
      simulationValues.photovoltaic_system.power_of_the_project,
    ), // Potência do Projeto em kWh
    annual_production: insertBRNumberMask(
      simulationValues.photovoltaic_system.annual_production,
    ), // Energia Produzida Anualmente
    co2_emission: insertBRNumberMask(
      simulationValues.photovoltaic_system.co2_emission,
    ), // Emissão de co2
    total_planted_trees:
      simulationValues.photovoltaic_system.total_planted_trees, // Equivalente em arvores plantadas
    overload: simulationValues.overload, // Equivalente em arvores plantadas
    excess_credit: insertBRNumberMask(
      simulationValues.photovoltaic_system.excess_credit,
    ), // Crédito Excedente
  }

  const planLow = simulationValues.plan.low && {
    ...simulation,
    typePlan: 'LOW', // Tipo do Plano
    planTerm: clientType === 'CPF' ? 3 : 5, // Prazo do plano
    planPrice: insertBRNumberMask(simulationValues.plan.low.monthly_value), // Preço do Preço
    annual_savings_plan: insertBRNumberMask(
      simulationValues.plan.low.estimated_annual_savings.percent,
    ), // Economia Anual Estimada
    monthly_rental_savings: insertBRNumberMask(
      simulationValues.plan.low.estimated_monthly_savings.value,
    ), // Economia mensal média
    client_annual_rental_value: insertBRNumberMask(
      simulationValues.plan.low.monthly_value * 12,
    ), // Valor Anual do Aluguel
    rental_value: insertBRNumberMask(simulationValues.plan.low.monthly_value), // Valor Mensal do Aluguel
    monthly_reduction: insertBRNumberMask(
      simulationValues.plan.low.estimated_annual_savings.percent,
    ), // Redução mensal média
    client_final_annual_value: insertBRNumberMask(
      simulationValues.plan.low.monthly_value * 12 +
        simulationValues.annual_values.new_bill_annual_value,
    ), // Valor Final Cliente
    annual_savings: insertBRNumberMask(
      simulationValues.plan.low.estimated_annual_savings.value,
    ), // Economia Anual - com Solar 21
    kwp_price: insertBRNumberMask(
      simulationValues.plan.low.monthly_value /
        simulationValues.photovoltaic_system.power_of_the_project,
    ), // Preço do kWp
  }

  const planMed = simulationValues.plan.med && {
    ...simulation,
    typePlan: 'MED', // Tipo do Plano
    planTerm: clientType === 'CPF' ? 5 : 10, // Prazo do plano
    planPrice: insertBRNumberMask(simulationValues.plan.med.monthly_value), // Preço do Preço
    annual_savings_plan: insertBRNumberMask(
      simulationValues.plan.med.estimated_annual_savings.percent,
    ), // Economia Anual Estimada
    monthly_rental_savings: insertBRNumberMask(
      simulationValues.plan.med.estimated_monthly_savings.value,
    ), // Economia mensal média
    client_annual_rental_value: insertBRNumberMask(
      simulationValues.plan.med.monthly_value * 12,
    ), // Valor Anual do Aluguel
    rental_value: insertBRNumberMask(simulationValues.plan.med.monthly_value), // Valor Mensal do Aluguel
    monthly_reduction: insertBRNumberMask(
      simulationValues.plan.med.estimated_annual_savings.percent,
    ), // Redução mensal média
    client_final_annual_value: insertBRNumberMask(
      simulationValues.plan.med.monthly_value * 12 +
        simulationValues.annual_values.new_bill_annual_value,
    ), // Valor Final Cliente
    annual_savings: insertBRNumberMask(
      simulationValues.plan.med.estimated_annual_savings.value,
    ), // Economia Anual - com Solar 21
    kwp_price: insertBRNumberMask(
      simulationValues.plan.med.monthly_value /
        simulationValues.photovoltaic_system.power_of_the_project,
    ), // Preço do kWp
  }

  const planMax = simulationValues.plan.max && {
    ...simulation,
    typePlan: 'MAX', // Tipo do Plano
    planTerm: 20, // Prazo do plano
    planPrice: insertBRNumberMask(simulationValues.plan.max.monthly_value), // Preço do Preço
    annual_savings_plan: insertBRNumberMask(
      simulationValues.plan.max.estimated_annual_savings.percent,
    ), // Economia Anual Estimada
    monthly_rental_savings: insertBRNumberMask(
      simulationValues.plan.max.estimated_monthly_savings.value,
    ), // Economia mensal média
    client_annual_rental_value: insertBRNumberMask(
      simulationValues.plan.max.monthly_value * 12,
    ), // Valor Anual do Aluguel
    rental_value: insertBRNumberMask(simulationValues.plan.max.monthly_value), // Valor Mensal do Aluguel
    monthly_reduction: insertBRNumberMask(
      simulationValues.plan.max.estimated_annual_savings.percent,
    ), // Redução mensal média
    client_final_annual_value: insertBRNumberMask(
      simulationValues.plan.max.monthly_value * 12 +
        simulationValues.annual_values.new_bill_annual_value,
    ), // Valor Final Cliente
    annual_savings: insertBRNumberMask(
      simulationValues.plan.max.estimated_annual_savings.value,
    ), // Economia Anual - com Solar 21
    kwp_price: insertBRNumberMask(
      simulationValues.plan.max.monthly_value /
        simulationValues.photovoltaic_system.power_of_the_project,
    ), // Preço do kWp
  }

  return (
    <ContainerPlan>
      {planLow ? (
        <SimulatedPlan isNotFit={false} simulatedPlan={planLow} />
      ) : (
        <SimulatedPlan isNotFit simulatedPlan={{ typePlan: 'LOW' }} />
      )}
      {planMed ? (
        <SimulatedPlan isNotFit={false} simulatedPlan={planMed} />
      ) : (
        <SimulatedPlan isNotFit simulatedPlan={{ typePlan: 'MED' }} />
      )}
      {planMax ? (
        <SimulatedPlan isNotFit={false} simulatedPlan={planMax} />
      ) : (
        <SimulatedPlan isNotFit simulatedPlan={{ typePlan: 'MAX' }} />
      )}
    </ContainerPlan>
  )
}

export { PlanGroup }
