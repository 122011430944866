export const natureOfOperations: { [key: string]: string } = {
  ABI: 'Adiantamento a Depositante-Banco de Investimento',
  ACV: 'Adiantamento a Depositante-Corretora de Valores',
  ACI: 'Adiantamento a Depositante-Crédito Imobiliário',
  AF: 'Adiantamento a Depositante-Financeira',
  ACC: 'Adiantamento a Depositantes - Conta Corrente Devedora',
  AS: 'Adiantamento a Depositante-Seguradora',
  ASP: 'Adiantamento a Depositante-Sociedade de Poupança',
  AT: 'Adiantamento a Depositante-Turismo',
  AD: 'Adiantamento de Conta',
  AL: 'Aluguel',
  APS: 'Arrecadadores-Prestadores de Serviços',
  AR: 'Arrendamento, Inclusive Leasing',
  ALI: 'Arrendamentos Inclusive Leasing-Banco de Investimento',
  ALV: 'Arrendamentos Inclusive Leasing-Corretora de Valores',
  ALC: 'Arrendamentos Inclusive Leasing-Crédito Imobiliário',
  ALF: 'Arrendamentos Inclusive Leasing-Financeira',
  ALS: 'Arrendamentos Inclusive Leasing-Seguradora',
  ALP: 'Arrendamentos Inclusive Leasing-Sociedade de Poupança',
  ALT: 'Arrendamentos Inclusive Leasing-Turismo',
  CDB: 'CDC Bens',
  CDM: 'CDC Motos',
  CDL: 'CDC Veículos Leves',
  CDP: 'CDC Veículos Pesados',
  CHQ: 'Cheque',
  CO: 'Consórcio Contemplado',
  CM: 'Consórcio Imóvel',
  CMO: 'Consórcio Motocicletas e Motonetas',
  CBE: 'Consórcio Outros Bens Móveis',
  CPA: 'Consórcio Passagens Aéreas',
  CVE: 'Consórcio Veículos',
  CVP:
    'Consórcio Veículos Pesados: Tratores, Ônibus, Caminhões, Barcos e Aviões',
  CC: 'Cota Condominial',
  CRC: 'Crédito Cartão',
  CD: 'Crédito Direto – Crediário',
  CFB: 'Crédito e Financiamento-Banco de Investimento',
  CFV: 'Crédito e Financiamento-Corretora de Valores',
  CFM: 'Crédito e Financiamento-Crédito Imobiliário',
  CFF: 'Crédito e Financiamento-Financeira',
  CFS: 'Crédito e Financiamento-Seguradora',
  CFP: 'Crédito e Financiamento-Sociedade de Poupança',
  CFT: 'Crédito e Financiamento-Turismo',
  CP: 'Crédito Pessoal',
  CFI: 'Créditos e Financiamentos',
  HO: 'Despesas com Hospitais',
  DE: 'Dívidas Originárias de Cheque Eletrônico',
  DC: 'Dívidas Originárias de Cheques',
  DP: 'Duplicata',
  EP: 'Empréstimo',
  AG:
    'Empréstimo / Financiamento Agrícola / Rural-Comercial, Industrial E Custeio',
  EG: 'Empréstimo Consignado',
  EC: 'Empréstimo em Conta',
  ECI: 'Empréstimo em Conta-Banco de Investimento',
  ECV: 'Empréstimo em Conta-Corretora de Valores',
  ECM: 'Empréstimo em Conta-Crédito Imobiliário',
  ECF: 'Empréstimo em Conta-Financeira',
  ECS: 'Empréstimo em Conta-Seguradora',
  ECP: 'Empréstimo em Conta-Sociedade de Poupança',
  ECT: 'Empréstimo em Conta-Turismo',
  EAB: 'Empréstimos Agrícolas e Industriais-Banco de Investimento',
  EAC: 'Empréstimos Agrícolas e Industriais-Corretora de Valores',
  EAI: 'Empréstimos Agrícolas e Industriais-Crédito Imobiliário',
  EAF: 'Empréstimos Agrícolas e Industriais-Financeira',
  EAS: 'Empréstimos Agrícolas e Industriais-Seguradora',
  EAP: 'Empréstimos Agrícolas e Industriais-Sociedade de Poupança',
  EAT: 'Empréstimos Agrícolas e Industriais-Turismo',
  ECC:
    'Empréstimos Em Conta, C/C Garantida, Capital De Giro, Programa Especial',
  EE: 'Energia Elétrica-Faturas de Fornecimento De Luz',
  FI: 'Financiamentos',
  FG: 'Gás-Faturas de Fornecimento de Gás',
  CR: 'Impedidos de Crédito Rural Pelo Bacen',
  IRI: 'Impedimento pelo BC Obtenção de Crédito Rural-Banco de Investimento',
  IRV: 'Impedimento pelo BC Obtenção de Crédito Rural-Corretora de Valores',
  IRC: 'Impedimento pelo BC Obtenção de Crédito Rural-Crédito Imobiliário',
  IRF: 'Impedimento pelo BC Obtenção de Crédito Rural-Financeira',
  IRS: 'Impedimento pelo BC Obtenção de Crédito Rural-Seguradora',
  IRP: 'Impedimento pelo BC Obtenção de Crédito Rural-Sociedade de Poupança',
  IRT: 'Impedimento pelo BC Obtenção de Crédito Rural-Turismo',
  IBI: 'Indisponibilidade de Bens - Banco de Investimento',
  IBG: 'Indisponibilidade de Bens - C/C Devedores em Geral',
  IBC: 'Indisponibilidade de Bens - Corretora de Valores',
  IBM: 'Indisponibilidade de Bens - Crédito Imobiliário',
  IBF: 'Indisponibilidade de Bens - Financeira',
  IBS: 'Indisponibilidade de Bens - Seguradora',
  IBP: 'Indisponibilidade de Bens - Sociedade de Poupança',
  IBT: 'Indisponibilidade de Bens - Turismo',
  IE: 'Instituição de Ensino: Bolsa Restituível',
  IPV: 'IPVA',
  LEA: 'Leasing',
  LM: 'Leasing Motos',
  LV: 'Leasing Veículo',
  LVP: 'Leasing Veículos Pesados',
  SS: 'Mensalidade de Plano/Seguro de Saúde',
  ME: 'Mensalidades Escolares',
  NF: 'Notas Fiscais',
  OC: 'Operação Cambio',
  POR: 'Operação Prejuízo',
  OA: 'Operações Agrícolas – Negociações Produtos Rurais-Insumos,Sementes etc.',
  OJ: 'Operações Ajuizadas',
  OAI: 'Operações Ajuizadas-Banco de Investimento',
  OAV: 'Operações Ajuizadas-Corretora de Valores',
  OAM: 'Operações Ajuizadas-Crédito Imobiliário',
  OAF: 'Operações Ajuizadas-Financeira',
  OAS: 'Operações Ajuizadas-Seguradora',
  OAP: 'Operações Ajuizadas-Sociedade de Poupança',
  OAT: 'Operações Ajuizadas-Turismo',
  CT: 'Operações com Cartão de Crédito',
  OCI: 'Operações de Câmbio-Banco de Investimento',
  OCV: 'Operações de Câmbio-Corretora de Valores',
  OCM: 'Operações de Câmbio-Crédito Imobiliário',
  OCF: 'Operações de Câmbio-Financeira',
  OCS: 'Operações de Câmbio-Seguradora',
  OCP: 'Operações de Câmbio-Sociedade de Poupança',
  OCT: 'Operações de Câmbio-Turismo',
  CA: 'Operações de Financiamento de Câmbio Em Geral',
  ORE: 'Operações De Repasse, Finame, 63, Recon Etc.',
  ORI: 'Operações de Repasse-Banco de Investimento',
  ORV: 'Operações de Repasse-Corretora de Valores',
  ORM: 'Operações de Repasse-Crédito Imobiliário',
  ORF: 'Operações de Repasse-Financeira',
  ORS: 'Operações de Repasse-Seguradora',
  ORP: 'Operações de Repasse-Sociedade de Poupança',
  ORT: 'Operações de Repasse-Turismo',
  IM: 'Operações Imobiliárias',
  OQI: 'Operações Quitadas com Prejuízo-Banco de Investimento',
  OQV: 'Operações Quitadas com Prejuízo-Corretora de Valores',
  OQM: 'Operações Quitadas com Prejuízo-Crédito Imobiliário',
  OQF: 'Operações Quitadas com Prejuízo-Financeira',
  OQS: 'Operações Quitadas com Prejuízo-Seguradora',
  OQP: 'Operações Quitadas com Prejuízo-Sociedade de Poupança',
  OQT: 'Operações Quitadas com Prejuízo-Turismo',
  OOI: 'Outras Operações-Banco de Investimento',
  OOV: 'Outras Operações-Corretora de Valores',
  OOM: 'Outras Operações-Crédito Imobiliário',
  OOF: 'Outras Operações-Financeira',
  OOS: 'Outras Operações-Seguradora',
  OOP: 'Outras Operações-Sociedade de Poupança',
  OOT: 'Outras Operações-Turismo',
  OO: 'Outras Operações',
  PGI: 'Prejuízos em Geral-Banco de Investimento',
  PGV: 'Prejuízos em Geral-Corretora de Valores',
  PGM: 'Prejuízos em Geral-Crédito Imobiliário',
  PGF: 'Prejuízos em Geral-Financeira',
  PGS: 'Prejuízos em Geral-Seguradora',
  PGP: 'Prejuízos em Geral-Sociedade de Poupança',
  PGT: 'Prejuízos em Geral-Turismo',
  VW: 'Prestação de Serviços-Vendas de Mercadorias',
  QP: 'Quitação de Prejuízos',
  RE: 'Repasses',
  SB: 'Saneamento Básico-Faturas de Fornecimento de Água',
  SR: 'Seguro de Risco Decorrido',
  SF: 'Seguro Fiança Locatícia',
  SG: 'Seguro Garantia',
  SQ: 'Seguro Quebra de Garantia',
  SRD: 'Seguro Risco Decorrido',
  TI:
    'Serviços de Dados e Internet (Speedy, Aluguel de Canais de Dados e Fibra Ótica )',
  TR: 'Serviços de Telefonia – Renegociação da Dívida',
  TP:
    'Serv. de Tel. / Vendas de Prod. – Vendas de Aparelhos, Inst., Aluguel de Aparelhos',
  TF: 'Serviços de Telefonia Fixa – Outras Contas Após Rescisão do Contrato',
  TT: 'Serviços de Telefonia Fixa – Res 085/98',
  TM: 'Serviços de Telefonia Móvel Pessoal / Celular – Res221/00',
  TE:
    'Serv. de Telef. Móvelpessoal/Celular – Outras Contas Após Rescisão do Contrato.',
  TC: 'Termo De Confissão de Dívida Descumprido',
  TD: 'Títulos Descontados',
  TDU: 'Títulos Descontados, Duplicatas, Promissórias etc.',
  TDI: 'Títulos Descontados-Banco de Investimento',
  TDV: 'Títulos Descontados-Corretora de Valores',
  TDM: 'Títulos Descontados-Crédito Imobiliário',
  TDF: 'Títulos Descontados-Financeira',
  TDS: 'Títulos Descontados-Seguradora',
  TDP: 'Títulos Descontados-Sociedade de Poupança',
  TDT: 'Títulos Descontados-Turismo',
}
