import styled from 'styled-components'

interface INameStyleProps {
  isClicked: boolean
}

export const Name = styled.span`
  text-align: left;
  font-family: Roboto, bold;
  letter-spacing: 0px;
  font-weight: 700;
  font-size: 19px;
  cursor: ${(props: INameStyleProps) => (props.isClicked ? 'pointer' : 'auto')};
`

export const Message = styled.span`
  font-family: Regular, Roboto;
  letter-spacing: 0px;
  font-weight: 400;
  font-size: 19px;
`
