import styled from 'styled-components'

import theme from '@/assets/styles/theme'

interface UserProps {
  status?: boolean
}

export const Container = styled.div`
  width: 100%;
  height: 71vh;
  padding: 0px 30px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`
export const PaginationContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
  padding: 30px 0px;
`
export const Header = styled.div`
  height: 100px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 30px 0px;
  @media only screen and (max-width: 768px) {
    height: 200px;
    flex-direction: column-reverse;
    padding: 30px 30px;
  }
`
export const Button = styled.button`
  width: 255px;
  height: 40px;
  background-color: ${theme.colors.primary};
  border: 0;
  border-radius: 5px;
  font-size: 16px;
  font-weight: 700;
  cursor: pointer;
`
export const TableContainer = styled.div`
  height: 570px;
  overflow-y: auto;
`
export const EditIcon = styled.img`
  width: 20px;
  height: 20px;
  cursor: pointer;
`
export const StatusContainer = styled.div<UserProps>`
  width: 133px;
  height: 26px;
  background-color: ${props =>
    props.status ? theme.colors.green[100] : theme.colors.red[100]};
  margin-bottom: -3px;
  border-radius: 5px;
  > p {
    color: ${props =>
      props.status ? theme.colors.green[300] : theme.colors.red[200]};
    padding: 5px;
    text-align: center;
  }
`
export const NameContainer = styled.div`
  color: ${theme.colors.blue};
  cursor: pointer;
`
export const SearchContainer = styled.div`
  width: 400px;
  @media only screen and (max-width: 768px) {
    width: 257px;
    margin-top: 20px;
  }
`
