import React from 'react'
import { useSelector } from 'react-redux'
import {
  Redirect,
  Route,
  RouteProps,
  RouteComponentProps,
} from 'react-router-dom'

import { IPermission } from '@/@types/Permission'
import AuthLayout from '@/modules/_layouts/auth'
import DefaultLayout from '@/modules/_layouts/default'
// import history from '@/services/history'
// import { RootState } from '@/store'

// import { getAllowedRoutesByRole } from './utils/getAllowedRoutesByRole'

interface Props extends RouteProps {
  isPrivate?: boolean
}

export interface AuthenticatedProps {
  Auth: {
    token: string
    role: IPermission
  }
}

const RouteWrapper: React.FC<Props> = ({
  component: Component,
  isPrivate = false,
  ...rest
}) => {
  const authenticated = useSelector(
    (state: AuthenticatedProps) => state.Auth.token,
  )
  // const role = useSelector((state: RootState) => state.Auth.role)

  if (!authenticated && isPrivate) {
    return <Redirect to="/" />
  }

  if (authenticated && !isPrivate) {
    return <Redirect to="/customer" />
  }

  // if (rest.path && rest.path !== '/' && role) {
  //   const allowRoutesByRole = getAllowedRoutesByRole(role)

  //   if (!allowRoutesByRole.includes(rest.path as string)) history.goBack()
  // }
  const Layout = authenticated ? DefaultLayout : AuthLayout

  if (!Component) {
    return null
  }

  return (
    <Route
      {...rest}
      render={(props: RouteComponentProps) => (
        <Layout>
          <Component {...props} />
        </Layout>
      )}
    />
  )
}

export default RouteWrapper
