import React from 'react'

import styled from 'styled-components'

import { Score } from '../ScoreInfo/ScoreInfo.types'

interface IScoreReasons {
  reasons: Score | undefined
}

const SComponents = {
  Container: styled.div`
    display: block;
    width: 100%;
  `,
  ReasonItem: styled.p`
    margin-bottom: 10px;
    color: red;
    &:before {
      content: '- ';
      color: #000;
    }

    /* &:after {
      content: '.';
      color: #000;
    } */
  `,
}

const ScoreReasons: React.FC<IScoreReasons> = ({ reasons }) => {
  return (
    <SComponents.Container>
      {!!reasons?.ReasonCode1 && (
        <SComponents.ReasonItem>
          {reasons?.ReasonCode1?.Message}.
        </SComponents.ReasonItem>
      )}
      {!!reasons?.ReasonCode2 && (
        <SComponents.ReasonItem>
          {reasons?.ReasonCode2?.Message}.
        </SComponents.ReasonItem>
      )}
      {!!reasons?.ReasonCode3 && (
        <SComponents.ReasonItem>
          {reasons?.ReasonCode3?.Message}.
        </SComponents.ReasonItem>
      )}
      {!!reasons?.ReasonCode4 && (
        <SComponents.ReasonItem>
          {reasons?.ReasonCode4?.Message}.
        </SComponents.ReasonItem>
      )}
    </SComponents.Container>
  )
}

export default ScoreReasons
