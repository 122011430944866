import React from 'react'

import { FormGroup, Grid, Switch, Typography } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'

const AntSwitch = withStyles(theme => ({
  root: {
    width: 28,
    height: 16,
    padding: 0,
    display: 'flex',
  },
  switchBase: {
    padding: 2,
    color: theme.palette.grey[500],
    '&$checked': {
      transform: 'translateX(12px)',
      color: theme.palette.common.white,
      '& + $track': {
        opacity: 1,
        backgroundColor: theme.palette.primary.main,
        borderColor: theme.palette.primary.main,
      },
    },
  },
  thumb: {
    width: 12,
    height: 12,
    boxShadow: 'none',
  },
  track: {
    border: `1px solid ${theme.palette.grey[500]}`,
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor: theme.palette.common.white,
  },
  checked: {},
}))(Switch)

interface iToggleSwitch {
  leftLabel: string
  rightLabel: string
  checked: boolean
  onChange: (checked: boolean) => void
}

const ToggleSwitch: React.FC<iToggleSwitch> = ({
  checked,
  leftLabel,
  rightLabel,
  onChange,
}) => {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) =>
    onChange(event.target.checked)

  return (
    <FormGroup>
      <Typography component="div">
        <Grid component="label" container alignItems="center" spacing={1}>
          <Grid item style={{ opacity: checked ? 0.3 : 1 }}>
            {leftLabel}
          </Grid>
          <Grid item>
            <AntSwitch
              checked={checked}
              onChange={handleChange}
              name="checkedC"
            />
          </Grid>
          <Grid item style={{ opacity: checked ? 1 : 0.3 }}>
            {rightLabel}
          </Grid>
        </Grid>
      </Typography>
    </FormGroup>
  )
}

export default ToggleSwitch
