import styled from 'styled-components'

import { TextField } from '@material-ui/core'
import MuiSelect from '@material-ui/core/Select'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { Form as FormComponent } from '@unform/web'

export interface InputProps {
  width?: string | undefined
  marginRight?: string | undefined
  marginLeft?: string | undefined
}

export const AutoCompleteStyled = styled(Autocomplete)`
  width: 100%;
  margin-top: 6px !important;
  margin-bottom: 20px !important;

  & .MuiInputBase-input {
    height: 15px;
  }
`

export const CssTextField = styled(TextField)`
  background-color: white;
  border: 1px solid #d8d8d8;
  color: #131313;

  & input::placeholder {
    font-size: 1rem;
    color: #131313;
  }

  & .MuiAutocomplete-input:first-child {
    color: #181818;
    font-weight: 300 !important;
  }

  & label.Mui-focused {
    border-width: 1px;
    border-color: #d8d8d8;
  }

  & .MuiInput-underline:after {
    border-bottom-color: #d8d8d8;
  }

  & .MuiFilledInput-underline:after {
    border-bottom-color: #d8d8d8;
  }

  & .MuiOutlinedInput-root {
    &.Mui-focused fieldset {
      border-width: 1px;
      border-color: #d8d8d8;
    }
    & fieldset {
      border-radius: 0;
    }
  }
`

export const MuiSelectCss = styled(MuiSelect)`
  white-space: pre-wrap;
`

export const Container = styled.div`
  padding: 0px 33px;
  height: 71vh;
  @media only screen and (max-width: 1100px) {
    padding: 0px 20px;
    overflow-y: auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    height: 565px;
  }
`
export const ContainerForm = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  padding: 35px 0 0;
  height: calc(100% - 19vh);
  @media only screen and (max-width: 1100px) {
    height: calc(100% - 5vh);
    padding: 0;
  }
`
export const FormLineInput = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;

  @media only screen and (max-width: 1100px) {
    flex-direction: column;
  }
`
export const FormLineInputCities = styled.div`
  display: grid;
  justify-content: space-between;
  align-items: center;
  grid-template-columns: auto 1fr;
  gap: 35px;

  @media only screen and (max-width: 1100px) {
    grid-template-columns: 1fr;
    gap: 0;
  }
`
export const ContainerButton = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  padding: 25px 0px;
  gap: 1rem;

  @media only screen and (max-width: 1100px) {
    flex-direction: column-reverse;
  }
`
export const SelectContainer = styled.div`
  width: 100%;
  margin-bottom: 23px;

  @media only screen and (max-width: 1100px) {
    width: 100%;
  }
`
export const ContainerInput = styled.div<InputProps>`
  width: ${props => props.width};
  margin-right: ${props => props.marginRight};
  margin-left: ${props => props.marginLeft || '0px'};

  @media only screen and (max-width: 1100px) {
    width: 100%;
    margin-right: 0px;
  }
`
export const Form = styled(FormComponent)`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-top: 2rem;

  @media only screen and (max-width: 1100px) {
    width: 100%;
  }
`
