import styled from 'styled-components'

import theme from '@/assets/styles/theme'

export const ContainerButton = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: baseline;
  font-family: Montserrat;

  @media only screen and (max-width: 375px) {
    margin: 0.25rem 0;
  }
`
export const Button = styled.button`
  font-size: 1rem;
  font-family: bold, Roboto;
  width: 120px;
  height: 39px;
  color: ${theme.colors.gray[500]};
  border: 0;
  border-radius: 6px;
  cursor: pointer;

  &:disabled {
    cursor: not-allowed;
  }
`
