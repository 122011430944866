/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { toast, ToastContent } from 'react-toastify'

import * as Yup from 'yup'

import { IPermission } from '@/@types/Permission'
import Button from '@/components/Ui/Button'
import { InputNumberMask } from '@/components/Ui/InputNumberMask'
import { Select } from '@/components/Ui/Select'
import { useState as HookUseState } from '@/hooks/useState'
import api from '@/services/api'
import { RootState } from '@/store'
import { Creators } from '@/store/ducks/Clients'
import { IClientBoard } from '@/store/ducks/Clients/types'
import { Form } from '@unform/web'

import {
  Container,
  ContainerForm,
  FormLineInput,
  ContainerInput,
  ContainerButton,
  SelectContainer,
  ValuesContainer,
} from './styles'

interface IClientEditProps {
  onClickToCancel: () => void
}

const EditClientBoard: React.FC<IClientEditProps> = ({ onClickToCancel }) => {
  const dispatch = useDispatch()

  const client = useSelector((state: RootState) => state.Client.client)

  interface IEquipment {
    id: number
    code: string
    potency: number
    type: 'Board' | 'Inverter'
    label: string
    value: number
  }

  interface IState {
    boards: IEquipment[]
    inverters: IEquipment[]
  }

  const { state, setState } = HookUseState<IState>({
    boards: [],
    inverters: [],
  })

  const clientProjectInfo = useSelector(
    (state: RootState) => state.Client.clientProjectInfo,
  )
  const permission = useSelector((state: RootState) => state.Auth.role)
  const isFranchisee = permission === IPermission.FRANCHISEE

  const [inverterPower, setInverterPower] = useState<number>(0)
  const [inverterName, setInverterName] = useState<string>('')
  const [modulePower, setModulePower] = useState<number>(0)
  const [moduleName, setModuleName] = useState<string>('')
  const [totalLosses, setTotalLosses] = useState<number>(0)
  const [specificProduction, setSpecificProduction] = useState<number>(0)
  const [optimizerQuantity, setOptimizerQuantity] = useState<number>(0)
  const [numberOfModules, setNumberOfModules] = useState<number>(0)

  const handleSubmit = useCallback(async () => {
    try {
      const clientBoard: IClientBoard = {
        inverter_power: inverterPower,
        module_power: modulePower,
        total_losses: totalLosses / 100,
        number_of_modules: numberOfModules,
        id: client.id,
        inverter_name: inverterName,
        module_name: moduleName,
        specific_production: specificProduction,
        optimizer_quantity: optimizerQuantity,
      }

      const userSchema = Yup.object().shape({
        inverter_power: Yup.number().required(
          'É obrigatório selecionar um inversor',
        ),
        module_power: Yup.number().required(
          'É obrigatório selecionar um módulo',
        ),
        total_losses: Yup.number().required('Perdas totais obrigatória'),
        number_of_modules: Yup.number().required(
          'Número de módulos do projeto obrigatório',
        ),
      })

      await userSchema.validate(clientBoard, {
        abortEarly: false,
      })

      dispatch(Creators.editClientBoardRequest(clientBoard))
    } catch (err) {
      // eslint-disable-next-line @typescript-eslint/no-extra-semi
      ;((err as unknown) as any).errors.forEach((error: ToastContent) => {
        toast.error(error)
      })
    }
  }, [
    client.id,
    dispatch,
    inverterPower,
    modulePower,
    numberOfModules,
    totalLosses,
    inverterName,
    moduleName,
    specificProduction,
    optimizerQuantity,
  ])

  const loadEquipmentData = () => {
    if (clientProjectInfo?.module_power && clientProjectInfo?.inverter_power) {
      setModulePower(clientProjectInfo.module_power)
      setInverterPower(clientProjectInfo.inverter_power)
    }
    if (clientProjectInfo?.inverter_name) {
      setInverterName(clientProjectInfo.inverter_name)
    }
    if (clientProjectInfo?.module_name) {
      setModuleName(clientProjectInfo.module_name)
    }
  }

  useEffect(() => {
    dispatch(Creators.getClientProjectInfoRequest({ id: client.id }))
  }, [client.id, dispatch])

  useEffect(() => {
    loadEquipmentData()
    // eslint-disable-next-line
  }, [clientProjectInfo])

  const getInvertersAndBoards = () => {
    api.get('/admin/boards-and-inverters').then(({ data }) => {
      const boards: IEquipment[] = []
      const inverters: IEquipment[] = []

      data
        .filter((i: IEquipment) => i.type === 'Board')
        .forEach((i: IEquipment) => {
          i.label = i.code
          i.value = i.potency
          boards.push(i)
        })
      data
        .filter((i: IEquipment) => i.type === 'Inverter')
        .forEach((i: IEquipment) => {
          i.label = i.code
          i.value = i.potency
          inverters.push(i)
        })
      if (data.length) setState({ boards, inverters })
    })
  }

  useEffect(() => {
    getInvertersAndBoards()
    // eslint-disable-next-line
  }, [])

  return (
    <Container>
      <Form onSubmit={handleSubmit}>
        {client.subscription_lending !== 'LENDING' ? (
          <ContainerForm>
            <FormLineInput>
              <SelectContainer marginRight="35px">
                <Select
                  label="Módulo fotovoltaico"
                  size="custom"
                  options={state.boards}
                  // eslint-disable-next-line @typescript-eslint/no-explicit-any
                  onChange={(solarModulesClass: any) => {
                    setModulePower(solarModulesClass.value)
                    setModuleName(solarModulesClass.label)
                  }}
                  isSearchable={false}
                  placeholder="Selecione um módulo fotovoltaico..."
                />
              </SelectContainer>
              <SelectContainer marginRight="35px">
                <Select
                  label="Inversor"
                  size="custom"
                  options={state.inverters}
                  // eslint-disable-next-line @typescript-eslint/no-explicit-any
                  onChange={(solarInverterClass: any) => {
                    setInverterPower(solarInverterClass.value)
                    setInverterName(solarInverterClass.label)
                  }}
                  isSearchable={false}
                  placeholder="Selecione um inversor..."
                />
              </SelectContainer>
            </FormLineInput>
            <FormLineInput>
              <ContainerInput width="707px" marginRight="35px">
                <InputNumberMask
                  isInteger
                  initialValue={clientProjectInfo?.number_of_modules || 0}
                  label="Número de módulos do projeto"
                  onChange={(value: number) => {
                    setNumberOfModules(value)
                  }}
                />
              </ContainerInput>
              <ContainerInput width="775px" marginRight="0px">
                <InputNumberMask
                  decimalLimit={2}
                  prefix=""
                  suffix=" %"
                  decimalSymbol=","
                  initialValue={(clientProjectInfo?.total_losses || 0) * 100}
                  label="Perdas totais (%)"
                  onChange={(value: number) => setTotalLosses(value)}
                />
              </ContainerInput>
            </FormLineInput>
          </ContainerForm>
        ) : (
          <ContainerForm>
            <FormLineInput>
              <ContainerInput width="775px" marginRight="0px">
                <InputNumberMask
                  decimalLimit={2}
                  decimalSymbol=","
                  suffix=" kWh/kWp.ano"
                  initialValue={clientProjectInfo?.specific_production || 1}
                  label="Produção específica (kWh/kWp.ano)"
                  onChange={(value: number) => setSpecificProduction(value)}
                />
                <InputNumberMask
                  isInteger
                  suffix=""
                  initialValue={clientProjectInfo?.optimizer_quantity || 0}
                  label="Número de Otimizadores"
                  onChange={(value: number) => setOptimizerQuantity(value)}
                />
              </ContainerInput>
            </FormLineInput>
          </ContainerForm>
        )}
        {client.subscription_lending !== 'LENDING' ? (
          <span>
            Verifique os valores de potência dos componentes do sistema
          </span>
        ) : (
          ''
        )}
        {client.subscription_lending !== 'LENDING' ? (
          <ValuesContainer>
            <ContainerInput>
              <span style={{ fontWeight: 'bold' }}>Potência por módulo:</span>{' '}
              {modulePower === 0 ? '' : `${modulePower.toString()} Wp`}
              {moduleName ? ` | ${moduleName}` : ''}
            </ContainerInput>
            <br />
            <ContainerInput>
              <span style={{ fontWeight: 'bold' }}>Potência do inversor:</span>{' '}
              {inverterPower === 0 ? '' : `${inverterPower.toString()} kW`}
              {inverterName ? ` | ${inverterName}` : ''}
            </ContainerInput>
            <br />
            <ContainerInput>
              <span style={{ fontWeight: 'bold' }}>Overload:</span>{' '}
              {inverterPower === 0 || modulePower === 0
                ? ''
                : `${((numberOfModules * modulePower) / (inverterPower * 1000))
                    .toFixed(2)
                    .toString()}`}
            </ContainerInput>
          </ValuesContainer>
        ) : (
          ''
        )}
        <ContainerButton>
          <Button type="button" size="default" onClick={onClickToCancel}>
            Voltar
          </Button>
          <Button
            size="default"
            color="primary"
            type="submit"
            disabled={isFranchisee}
          >
            Salvar alterações
          </Button>
        </ContainerButton>
      </Form>
    </Container>
  )
}

export { EditClientBoard }
