/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { MouseEvent } from 'react'
import Dropdown from 'react-bootstrap/Dropdown'

import {
  Button,
  SelectedOptionContainer,
  TextContainer,
  IconContainer,
} from './styles'

interface ICustomToggle {
  onClick: (e: MouseEvent) => void
  typeVariant?: StatusTypes
}

interface IItem {
  value: string
  label: string
}

export type StatusTypes = 'approved' | 'pending' | 'reproved'

interface IDropdownProps {
  items: IItem[]
  value?: StatusTypes
  width?: string
  height?: string
  handleSelect?: (eventKey: any, event: any) => any
  defaultLabel?: string
  isDisabled: boolean
}

// Developed with react bootstrap library
const DropdownBootstrap: React.FC<IDropdownProps> = ({
  items = [],
  value,
  handleSelect,
  width,
  height,
  defaultLabel = '',
  isDisabled,
}) => {
  const CustomToggle = React.forwardRef<HTMLButtonElement, ICustomToggle>(
    ({ children, onClick }, ref) => (
      <Button
        ref={ref}
        onClick={(e: MouseEvent) => {
          e.preventDefault()
          onClick(e)
        }}
        width={width}
        height={height}
        disabled={isDisabled}
        typeVariant={value}
      >
        {children}
      </Button>
    ),
  )

  return (
    <Dropdown>
      <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-component">
        <SelectedOptionContainer>
          <TextContainer>
            {items.find(item => item.value === value)?.label || defaultLabel}
          </TextContainer>
          {isDisabled ? ' ' : <IconContainer>&#x25bc;</IconContainer>}
        </SelectedOptionContainer>
      </Dropdown.Toggle>
      <Dropdown.Menu>
        {items.length > 0 &&
          items.map(item => (
            <Dropdown.Item
              key={item.value}
              eventKey={item.value}
              onSelect={handleSelect}
            >
              <span>{item.label}</span>
            </Dropdown.Item>
          ))}
      </Dropdown.Menu>
    </Dropdown>
  )
}

export { DropdownBootstrap }
