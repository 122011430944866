import styled from 'styled-components'

import theme from '@/assets/styles/theme'

export const Container = styled.div`
  background-color: ${theme.colors.white};
  border-radius: 5px;
  box-shadow: 0 0 20px #ededed;
  cursor: default;
  height: auto;
  float: right;
  max-height: 460px;
  position: absolute;
  max-width: 600px;
  z-index: 99;
  right: 90px;

  @media screen and (max-width: 1333px) {
    right: 95px;
  }

  @media screen and (max-height: ${theme.breakpoints.md}) {
    height: auto;
    max-height: 400px;
    width: 360px;
    right: 75px;
  }
`

export const Header = styled.div`
  border-bottom: 1px solid ${theme.colors.gray[300]};
  padding: 30px 20px 20px 20px;
  width: 100%;

  > h2 {
    font-size: 18px;
    font-weight: 500;
  }
`

export const Content = styled.div`
  padding: 30px 20px 20px 20px;
  width: 100%;

  display: flex;
  justify-content: center;
`

export const List = styled.div`
  width: 100%;
  height: 100%;
  max-height: 340px;
  overflow-y: scroll;

  @media screen and (max-height: ${theme.breakpoints.md}) {
    height: 100%;
    max-height: 280px;
  }

  ::-webkit-scrollbar {
    display: none;
  }
`

export const Footer = styled.li`
  background-color: ${theme.colors.white};
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
  border-top: 1px solid ${theme.colors.gray[300]};
  box-shadow: 0 10px 20px #ededed;
  display: flex;
  justify-content: center;
  padding: 20px;

  @media screen and (max-width: ${theme.breakpoints.md}) {
    padding: 15px;
  }

  > a {
    align-items: center;
    color: ${theme.colors.primary};
    display: flex;
    font-size: 18px;
    font-weight: 800;
    text-decoration: none;

    &:hover {
      opacity: 0.7;
    }

    > svg {
      margin-right: 10px;
    }
  }
`
