import React, { useCallback } from 'react'
import { FiX } from 'react-icons/fi'

import { Container, DivFix, ContentModal } from './styles'

interface PropsModal {
  openModal: boolean
  onClose: () => void
  children: React.ReactNode
}

const Modal: React.FC<PropsModal> = ({ onClose, openModal, children }) => {
  const handleCloseModal = useCallback(() => {
    onClose()
  }, [onClose])

  return (
    <>
      {openModal && (
        <Container>
          <ContentModal>
            <DivFix>
              <FiX size="30px" cursor="pointer" onClick={handleCloseModal} />
            </DivFix>
            {children}
          </ContentModal>
        </Container>
      )}
    </>
  )
}

export default Modal
