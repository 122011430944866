import { toast } from 'react-toastify'

import queryString from 'query-string'
import { call, put } from 'redux-saga/effects'

import { IPagination } from '@/@types/Pagination'
import api from '@/services/api'
import { CitiesCreators } from '@/store/ducks/Cities'
import { ICitiesQuery } from '@/store/ducks/Cities/types'

interface ICitiesItemsResponse {
  id: string
  name: string
  state: string
  is_commercial_available: boolean
  is_residential_available: boolean
}
interface IGetCitiesResponse {
  items: ICitiesItemsResponse[]
  meta: IPagination
}

export function* getCities({ query = {} }: { query?: ICitiesQuery }) {
  try {
    const queryCities = queryString.stringify(query, { arrayFormat: 'bracket' })
    const { data: cities }: { data: IGetCitiesResponse } = yield call(
      api.get,
      `/admin/cities?${queryCities}`,
    )

    yield put(
      CitiesCreators.getCitiesSuccess({
        items: cities.items.map(city => ({
          id: city.id,
          name: city.name,
          state: city.state,
          isCommercialAvailable: city.is_commercial_available,
          isResidentialAvailable: city.is_residential_available,
        })),
        pagination: cities.meta,
      }),
    )
  } catch (err) {
    yield put(CitiesCreators.getCitiesFailure())
    toast.error('Não foi possível carregar as Cidades.')
  }
}
