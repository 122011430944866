import React from 'react'

import Button from '@/components/Ui/Button'
import { useState } from '@/hooks/useState'
import { LinearProgress, TextField } from '@material-ui/core'

import { CreateDTO, Supply } from './types'

const initialState: CreateDTO = {
  code: '',
  potency: 0,
}

type IStoreForm = Supply & {
  onSubmit: (data: CreateDTO) => void
  loading: boolean
}

const StoreForm: React.FC<IStoreForm> = ({ type, onSubmit, loading }) => {
  const { state, setState } = useState<CreateDTO>(initialState)

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target
    setState({ [name]: value })
  }

  const submit = () => {
    if (!state.code || !state.potency) return
    onSubmit(state)
  }

  return (
    <>
      <TextField
        onChange={onChange}
        value={state.code}
        label={type === 'BOARD' ? 'Código da Placa' : 'Código do Inversor'}
        name="code"
        variant="outlined"
        fullWidth
        disabled={loading}
      />

      <div style={{ marginTop: 15 }} />

      <TextField
        onChange={onChange}
        value={state.potency}
        label="Potência"
        name="potency"
        variant="outlined"
        fullWidth
        disabled={loading}
      />

      <div style={{ marginTop: 15 }} />

      {loading && <LinearProgress />}

      <Button loading={loading} full onClick={submit}>
        Cadastrar
      </Button>
    </>
  )
}

export default StoreForm
