import styled from 'styled-components'

export const Container = styled.div`
  height: 71vh;
  padding: 0px 30px;

  @media only screen and (max-width: 750px) {
    padding: 0px 0.5rem;
  }
`
export const Header = styled.div`
  padding: 30px 0px;
`
export const Name = styled.h1`
  font-size: 26px;
  font-family: bold, Roboto;
  font-weight: 600;
  margin-right: 20px;
`

export const ClientInfoContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
`
