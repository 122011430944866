import React, { useEffect, useState } from 'react'
import { ButtonGroup, Button as ButtonRS } from 'react-bootstrap'
import Dropdown from 'react-bootstrap/Dropdown'
import { useDispatch, useSelector } from 'react-redux'

import { UserStatusEnum, UserRoleEnum } from '@/@types/user'
import penIcon from '@/assets/images/icons/ic-pen.svg'
import { FullScreenLoader } from '@/components/FullScreenLoader/FullScreenLoader'
import PageContent from '@/components/PageContent'
import DialogWithInput from '@/components/shared/DialogWithInput'
import { IInputProps } from '@/components/shared/DialogWithInput/types'
import { Table, ITableProps } from '@/components/Table'
import { Pagination } from '@/components/Ui/Pagination'
import { SearchInput } from '@/components/Ui/SearchInput'
import history from '@/services/history'
import { RootState } from '@/store'
import { Creators } from '@/store/ducks/Users'

import {
  Container,
  Header,
  TableContainer,
  EditIcon,
  StatusContainer,
  Button,
  PaginationContainer,
  NameContainer,
  SearchContainer,
} from './styles'

interface IUserData {
  name: string
  email: string
  role: UserRoleEnum
  status: UserStatusEnum
  document: string
  id: string
}

interface ItemSingleEdit {
  user?: IUserData
  type?: EditType
}

enum EditType {
  Senha = 'Senha',
  Email = 'Email',
  Documento = 'Documento',
  Nome = 'Nome',
}

const Users: React.FC = () => {
  const dispatch = useDispatch()
  const isLoading = useSelector((state: RootState) => state.User.loading.get)
  const userList = useSelector((state: RootState) => state.User.users.items)
  const pagination = useSelector((state: RootState) => state.User.users.meta)
  const [page, setPage] = useState<number>(1)
  const [search, setSearch] = useState<string>('')
  const [itemSingleEdit, setItemSingleEdit] = useState<ItemSingleEdit>({
    user: undefined,
    type: undefined,
  })

  const columns: ITableProps<IUserData>['columns'] = [
    {
      title: 'Nome',
      dataIndex: 'name',
      key: 'name',
      width: 200,
      render: (value, user) => (
        <NameContainer onClick={() => history.push(`/users/${user.id}/edit`)}>
          {user.name}
        </NameContainer>
      ),
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      width: 200,
    },
    {
      title: 'Perfil',
      dataIndex: 'role',
      key: 'role',
      width: 100,
      render: (value, user) => {
        const selectedRole: string = {
          [UserRoleEnum.TECHNICIAN]: 'Técnico',
          [UserRoleEnum.COMMERCIAL]: 'Comercial',
          [UserRoleEnum.ADMIN]: 'Administrador',
          [UserRoleEnum.FRANCHISEE]: 'Franqueado',
        }[user.role]
        return (
          <div>
            <p>{selectedRole}</p>
          </div>
        )
      },
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      width: 150,
      render: (value, user) => (
        <StatusContainer status={user.status === UserStatusEnum.ACTIVE}>
          <p>{user.status === UserStatusEnum.ACTIVE ? 'Ativo' : 'Bloqueado'}</p>
        </StatusContainer>
      ),
    },
    {
      title: '',
      dataIndex: 'edit',
      key: 'edit',
      width: 50,
      render: (value, user: IUserData) => {
        // console.log('value, user', value, user)
        return (
          <>
            <Dropdown as={ButtonGroup}>
              <ButtonRS
                onClick={() => history.push(`/users/${user.id}/edit`)}
                variant="secondary"
              >
                <EditIcon src={penIcon} />
              </ButtonRS>
              <Dropdown.Toggle split variant="secondary" />
              <Dropdown.Menu>
                {Object.values(EditType).map((item: EditType) => (
                  <Dropdown.Item
                    onClick={() =>
                      setItemSingleEdit({
                        type: item,
                        user,
                      })
                    }
                  >
                    {`Editar ${item}`}
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </Dropdown>
          </>
        )
      },
    },
  ]

  useEffect(() => {
    dispatch(Creators.getUsersRequest({ page, search }))
  }, [dispatch, page, search])

  const users = userList?.map((data, index) => {
    return {
      id: data.id,
      name: data.fullname,
      email: data.email,
      role: data.role,
      status: data.status,
      key: index,
      document: data.cpf,
    }
  })

  const handlePageChange = (value: number) => {
    setPage(value)
  }

  const handleChange = (value: string) => {
    setPage(1)
    setSearch(value)
  }

  const getInputsSingleEdit = (type?: EditType): IInputProps[] => {
    let inputs: IInputProps[]
    switch (type) {
      case EditType.Documento:
        inputs = [
          {
            label: 'Documento',
            name: 'document',
            disabled: false,
            defaultValue: itemSingleEdit.user?.document,
            placeholder: 'Documento',
          },
        ]
        break
      case EditType.Senha:
        inputs = [
          {
            label: 'Nova Senha',
            name: 'pwd',
            disabled: false,
            // defaultValue: '111',
            placeholder: 'Nova Senha',
          },
          {
            label: 'Confirmar nova senha',
            name: 'pwd-confirm',
            disabled: false,
            // defaultValue: '111',
            placeholder: 'Confirmar nova senha',
          },
        ]
        break
      case EditType.Email:
        inputs = [
          {
            label: 'Email',
            name: 'email',
            disabled: false,
            defaultValue: itemSingleEdit.user?.email,
            placeholder: 'Email',
          },
        ]
        break
      case EditType.Nome:
        inputs = [
          {
            label: 'Nome',
            name: 'name',
            disabled: false,
            defaultValue: itemSingleEdit.user?.name,
            placeholder: 'Nome',
          },
        ]
        break

      default:
        inputs = []
        break
    }
    return inputs

    /**
  Status = 'Status',
     */
  }

  return (
    <PageContent title="Usuários">
      <Container>
        <Header>
          <SearchContainer>
            <SearchInput onChange={handleChange} />
          </SearchContainer>
          <Button onClick={() => history.push('/users/create')}>
            + Adicionar Novo Usuário
          </Button>
        </Header>
        <TableContainer>
          {isLoading ? (
            <FullScreenLoader />
          ) : (
            <Table<IUserData> columns={columns} data={users} />
          )}
        </TableContainer>
        <PaginationContainer>
          <Pagination
            count={pagination.total_pages}
            size="large"
            shape="rounded"
            variant="outlined"
            page={page}
            onChange={handlePageChange}
          />
        </PaginationContainer>
      </Container>
      <DialogWithInput
        onCloseDialog={() =>
          setItemSingleEdit({
            type: undefined,
            user: undefined,
          })
        }
        opened={!!itemSingleEdit.type && !!itemSingleEdit.user}
        disabledAll={false}
        title={`Atualizar ${itemSingleEdit.type}`}
        loading={false}
        submitButton={{
          className: 'submit-btn',
          disabled: false,
          label: 'Atualizar',
          // eslint-disable-next-line no-console
          onClick: values => console.log('values', values),
        }}
        inputs={getInputsSingleEdit(itemSingleEdit.type)}
      />
    </PageContent>
  )
}

export { Users }
