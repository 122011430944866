import React, { MouseEventHandler } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { NavLink } from 'react-router-dom'

import { IPermission } from '@/@types/Permission'
import IconCustomer from '@/assets/images/icons/ic-customer.svg'
import IconInvoice from '@/assets/images/icons/ic-invoice.svg'
import IconLending from '@/assets/images/icons/ic-lending.svg'
import IconLogout from '@/assets/images/icons/ic-logout.svg'
import IconProfile from '@/assets/images/icons/ic-profile.svg'
import IconReport from '@/assets/images/icons/ic-report.svg'
import IconTechnicalVisit from '@/assets/images/icons/ic-technical-visit.svg'
import IconUsers from '@/assets/images/icons/ic-users.svg'
import Installation from '@/assets/images/icons/installation.svg'
import Truck from '@/assets/images/icons/truck.svg'
import LogoSolar21 from '@/assets/images/logo.svg'
import { RootState } from '@/store'
import { Creators } from '@/store/ducks/Auth'

import { Container, Logo, Nav } from './styles'

interface SidebarProps {
  isOpen: boolean
  onClick: MouseEventHandler<HTMLElement>
}

const Sidebar: React.FC<SidebarProps> = ({ isOpen, onClick }) => {
  const dispatch = useDispatch()

  const { role: permission, user_id: userId } = useSelector(
    (state: RootState) => state.Auth,
  )

  const handleLogout = () => {
    dispatch(Creators.logoutRequest())
  }

  const isAdmin = permission === IPermission.ADMIN
  const isCommercial = permission === IPermission.COMMERCIAL
  const isTechnician = permission === IPermission.TECHNICIAN
  const isBIVisitor = permission === IPermission.BIVISITOR
  const isFranchisee = permission === IPermission.FRANCHISEE

  return (
    <Container isOpen={isOpen} onClick={onClick}>
      <Logo>
        <img src={LogoSolar21} alt="Solar21" />
      </Logo>

      <Nav>
        {isAdmin && (
          <NavLink to="/users" activeClassName="active">
            <img src={IconUsers} alt="Usuários" />
            <span>Usuários</span>
          </NavLink>
        )}
        {(isAdmin || isCommercial || isFranchisee) && (
          <NavLink to="/customer" activeClassName="active">
            <img src={IconCustomer} alt="Clientes" />
            <span>Clientes</span>
          </NavLink>
        )}
        {(isAdmin || isCommercial || isTechnician || isFranchisee) && (
          <NavLink to="/visit" activeClassName="active">
            <img src={IconTechnicalVisit} alt="Visita Técnica" />
            <span>Visita Técnica</span>
          </NavLink>
        )}
        {(isAdmin || isCommercial || isFranchisee) && (
          <NavLink to="/financing" activeClassName="active">
            <img src={IconLending} alt="Financiamento" />
            <span>Financiamento</span>
          </NavLink>
        )}
        {(isAdmin || isFranchisee) && (
          <NavLink to="/projects_supplies" activeClassName="active">
            <img src={Truck} alt="Projetos e Supply" />
            <span>Projetos e Supply</span>
          </NavLink>
        )}
        {(isAdmin || isFranchisee) && (
          <NavLink to="/installation" activeClassName="active">
            <img src={Installation} alt="instalação" />
            <span>instalação</span>
          </NavLink>
        )}
        {isAdmin && (
          <NavLink to="/vert" activeClassName="active">
            <img src={IconInvoice} alt="Vert" />
            <span>Vert</span>
          </NavLink>
        )}
        {(isBIVisitor || isAdmin) && (
          <NavLink to="/businessintelligence" activeClassName="active">
            <img src={IconReport} alt="BI" />
            <span>Business Intelligence</span>
          </NavLink>
        )}
      </Nav>

      <Nav style={{ position: 'absolute', bottom: 25, width: '100%' }}>
        {isAdmin && (
          <NavLink to={`/users/${userId}/edit`} activeClassName="active">
            <img src={IconProfile} alt="Perfil" />
            <span>Perfil</span>
          </NavLink>
        )}

        <div onClick={handleLogout} aria-hidden="true">
          <img src={IconLogout} alt="Sair" />
          <span>Sair</span>
        </div>
      </Nav>
    </Container>
  )
}

export default Sidebar
