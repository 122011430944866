/* eslint-disable */
import React, { useState, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { toast, ToastContent } from 'react-toastify'

import * as Yup from 'yup'

import Button from '@/components/Ui/Button'
import { InputNumberMask } from '@/components/Ui/InputNumberMask'
import { RootState } from '@/store'
import { Creators } from '@/store/ducks/Clients'
import { IClientInstallationCost } from '@/store/ducks/Clients/types'
import { Form } from '@unform/web'

import {
  Container,
  ContainerForm,
  FormLineInput,
  ContainerInput,
  ContainerButton,
} from './styles'
import { error } from 'console'

interface IClientEditProps {
  onClickToCancel: () => void
}

const EditClientCommissionInstallationCost: React.FC<IClientEditProps> = ({
  onClickToCancel,
}) => {
  const dispatch = useDispatch()

  const client = useSelector((state: RootState) => state.Client.client)

  const clientProjectInfo = useSelector(
    (state: RootState) => state.Client.clientProjectInfo,
  )

  const [commission, setCommission] = useState<number>(0)
  const [installation_material_cost, setIMCost] = useState<number>(0)

  const handleSubmit = useCallback(async () => {
    try {
      const clientInstallationCost: IClientInstallationCost = {
        commission,
        installation_material_cost,
        id: client.id,
      }

      const userSchema = Yup.object().shape({
        installation_material_cost: Yup.number().required(
          'Tarifa concessionária obrigatória',
        ),
        commission: Yup.number().required('Tarifa não compensada obrigatória'),
      })

      await userSchema.validate(clientInstallationCost, {
        abortEarly: false,
      })

      dispatch(
        Creators.editClientCommissionInstallationCostRequest(
          clientInstallationCost,
        ),
      )
      await new Promise(r => setTimeout(r, 1500))
      onClickToCancel()
    } catch (err) {
      // @ts-ignore
      err.errors.forEach((error: ToastContent) => {
        toast.error(error)
      })
    }
  }, [
    client.id,
    commission,
    installation_material_cost,
    dispatch,
    onClickToCancel,
  ])

  return (
    <Container>
      <Form onSubmit={handleSubmit}>
        <ContainerForm>
          <FormLineInput>
            <ContainerInput width="775px" marginRight="35px">
              <InputNumberMask
                decimalLimit={2}
                initialValue={(clientProjectInfo?.commission || 0) * 100}
                label="Sua Comissão"
                prefix=""
                suffix=" %"
                decimalSymbol=","
                onChange={(value: number) => {
                  setCommission(value / 100)
                }}
              />
            </ContainerInput>
            <ContainerInput width="775px" marginRight="0px">
              <InputNumberMask
                decimalLimit={2}
                initialValue={
                  clientProjectInfo?.installation_material_cost || 0
                }
                label="Instalação + Material CA"
                prefix="R$/Wp "
                decimalSymbol=","
                onChange={(value: number) => {
                  setIMCost(value)
                }}
              />
            </ContainerInput>
          </FormLineInput>
        </ContainerForm>
        <ContainerButton>
          <Button type="button" size="default" onClick={onClickToCancel}>
            Voltar
          </Button>
          <Button
            size="default"
            color="primary"
            type="submit"
            onClick={event => {
              if (
                installation_material_cost < 0.3 ||
                installation_material_cost > 0.5
              ) {
                event.preventDefault()
                toast.error(
                  'O valor da instalação deve estar entre 300 R$/kWp e 500 R$/kWp',
                )
              } else if (commission > 0.20 || commission < 0.05) {
                event.preventDefault()
                toast.error('A sua comissão deve estar entre 5% e 20%')
              }
            }}
          >
            Salvar alterações
          </Button>
        </ContainerButton>
      </Form>
    </Container>
  )
}

export { EditClientCommissionInstallationCost }
