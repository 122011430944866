import React from 'react'

import { EditorState } from 'draft-js'

import SimpleModal from '../SimpleModal'
import TextEditor from '../TextEditor'
import { editorToHTML } from '../TextEditor/utils'
import { Faq as FaqInterface } from './faq.types'
import * as Styles from './styles'

interface INewFaqDialog {
  loading: boolean
  onCloseDialog: () => void
  onSubmit: (content: Partial<FaqInterface>) => Promise<FaqInterface>
}

const NewFaqDialog: React.FC<INewFaqDialog> = ({
  loading = false,
  onCloseDialog,
  onSubmit,
}): JSX.Element => {
  const [editorState, setEditorState] = React.useState(
    EditorState.createEmpty(),
  )

  const [title, setTitle] = React.useState<string>('')

  const handleSubmit = () => {
    const body: Partial<FaqInterface> = {
      title,
      description: editorToHTML(editorState.getCurrentContent()),
    }
    onSubmit(body)
  }

  const onChangeTitle = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTitle(e.target.value)
  }

  return (
    <SimpleModal title="Adicionar novo item" onClose={onCloseDialog}>
      <Styles.EditInputTitle
        value={title}
        onChange={onChangeTitle}
        disabled={loading}
        placeholder="Título"
      />

      <TextEditor
        editorState={editorState}
        onChangeEditorState={setEditorState}
      />

      <Styles.EditContainerActionGroup>
        <Styles.ActionButton
          onClick={onCloseDialog}
          loading={loading}
          color="error"
        >
          Cancelar
        </Styles.ActionButton>
        <Styles.ActionButton
          onClick={handleSubmit}
          loading={loading}
          color="success"
        >
          Salvar alterações
        </Styles.ActionButton>
      </Styles.EditContainerActionGroup>
    </SimpleModal>
  )
}

export default NewFaqDialog
