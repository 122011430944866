/* eslint-disable react/jsx-wrap-multilines */
import React, { FC, useEffect, useMemo, useState } from 'react'

import { insertPhoneMask } from '@/utils/insertNumberMask/insertPhoneMask'
import Box from '@material-ui/core/Box'
import Card from '@material-ui/core/Card'
import AlarmOutlinedIcon from '@material-ui/icons/AlarmOutlined'
import CheckIcon from '@material-ui/icons/Check'
import HourglassEmptyOutlinedIcon from '@material-ui/icons/HourglassEmptyOutlined'

import DoubleColumn from '../DoubleColumn/DoubleColumn'
import Priority from '../Priority/Priority'
import { useStyles } from '../styles'

type InfoCardPropsType = {
  id: string
  fullName: string
  phone: string
  power: string
  proposalValue: string
  priority: number
  time: string
  noProposal: boolean
  currentStep:
    | 'initial'
    | 'bill'
    | 'visit'
    | 'contract'
    | 'checkout'
    | 'supply'
    | 'instalation'
  // eslint-disable-next-line react/require-default-props
  status?: string
}

const InfoCard: FC<InfoCardPropsType> = ({
  id,
  fullName,
  phone,
  power,
  proposalValue,
  priority,
  time,
  noProposal,
  currentStep,
  status = 'Análise',
}) => {
  const classes = useStyles()

  const initials = useMemo(() => {
    const splitedName = fullName.split(' ')
    const surnameIndex = splitedName.length - 1
    const nameInitials: string[] = []
    splitedName.forEach((name, index) => {
      if (index === 0 || index === surnameIndex)
        nameInitials.push(name.charAt(0))
    })

    return nameInitials.join('').toUpperCase()
  }, [fullName])

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [styleStatus, setStyleStatus] = useState<any>()

  useEffect(() => {
    if (status === 'Pendente') setStyleStatus(classes.selectLikeCard)
    if (status === 'Análise') setStyleStatus(classes.selectLikeCard)
    if (status === 'Aprovada') setStyleStatus(classes.selectLikeCardApproved)
    if (status === 'Reprovada') setStyleStatus(classes.selectLikeCardDenied)
  }, [
    status,
    classes.selectLikeCard,
    classes.selectLikeCardApproved,
    classes.selectLikeCardDenied,
  ])

  return (
    <Card className={classes.cardInfo}>
      <Box
        className={classes.infoHeader}
        onClick={() => window.open(`/customer/${id}/details`, '_blank')}
      >
        <Box className={classes.avatar}>{initials}</Box>
        <DoubleColumn title="Nome" content={fullName} />
      </Box>
      <Box className={classes.infoRow}>
        <div className={classes.ContainerSection}>
          <div style={{ width: '55%' }}>
            <DoubleColumn
              title="Telefone"
              content={insertPhoneMask(
                phone.startsWith('55') ? phone.slice(2) : phone,
              )}
            />
          </div>
          <DoubleColumn title="Potência" content={power} />
        </div>
        <div className={classes.ContainerSection}>
          <div style={{ width: '55%' }}>
            <DoubleColumn
              title={noProposal ? 'Conta' : 'Proposta'}
              content={proposalValue}
            />
          </div>
          <DoubleColumn
            title="Prioridade"
            content={<Priority id={id} priority={priority} />}
          />
        </div>
        <div className={classes.ContainerSection}>
          <div style={{ width: '55%' }}>
            <DoubleColumn
              title="Conta de luz"
              content={
                <Box className={styleStatus}>
                  {currentStep !== 'bill' && currentStep !== 'initial' && (
                    <CheckIcon fontSize="small" color="action" />
                  )}
                  {(currentStep === 'bill' || currentStep === 'initial') &&
                    (status === 'Pendente' || status === 'Análise') && (
                      <HourglassEmptyOutlinedIcon fontSize="small" />
                    )}
                  {(currentStep === 'bill' || currentStep === 'initial') &&
                    status === 'Reprovada' && (
                      <CheckIcon fontSize="small" color="error" />
                    )}
                  {(currentStep === 'bill' || currentStep === 'initial') &&
                    status === 'Aprovada' && (
                      <CheckIcon fontSize="small" color="action" />
                    )}
                  {status}
                </Box>
              }
            />
          </div>
          <DoubleColumn
            title="Tempo"
            content={
              <Box className={classes.selectLikeCard}>
                <AlarmOutlinedIcon fontSize="small" /> {time}
              </Box>
            }
          />
        </div>
      </Box>
    </Card>
  )
}

export default InfoCard
