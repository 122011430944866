import styled from 'styled-components'

import theme from '@/assets/styles/theme'

export const Container = styled.div`
  background: rgba(0, 0, 0, 0.7);
  position: fixed;
  top: 0;
  height: 100vh;
  width: 100%;
  z-index: 10;
  display: flex;
  justify-content: center;
  overflow-y: scroll;
  align-items: center;
  &.is-closed {
    display: hidden;
  }
  @media only screen and (min-width: 769px) {
    left: 0;
  }
`

export const ContentModal = styled.div`
  border-radius: 5px;
  overflow: scroll;
  height: 90vh;
  background-color: ${theme.colors.white};
`
export const DivFix = styled.div`
  display: flex;
  padding: 20px;
  color: ${theme.colors.gray[300]};
  justify-content: flex-end;
}

`
export const Title = styled.h1`
  color: ${theme.colors.black};
  font-size: 35px;
  font-weight: 800;
  padding: 25px 0px 35px 0px;
`
