import React, { useState, useEffect } from 'react'
import { MaskedInputProps } from 'react-text-mask'

import { createNumberMask } from 'text-mask-addons'

import {
  Container,
  InputContainer,
  InputNumberContainer,
  Label,
} from './styles'

interface InputNumberProps {
  // eslint-disable-next-line react/require-default-props
  onChange?: (value: number) => void
  // eslint-disable-next-line react/require-default-props
  initialValue?: number
  // eslint-disable-next-line react/require-default-props
  label?: string
  // eslint-disable-next-line react/require-default-props, @typescript-eslint/no-explicit-any
  disabled?: any
  // eslint-disable-next-line react/require-default-props
  prefix?: string | ''
  // eslint-disable-next-line react/require-default-props
  suffix?: string
  // eslint-disable-next-line react/require-default-props
  maskOptions?: MaskedInputProps
  // eslint-disable-next-line react/require-default-props
  decimalLimit?: number
  // eslint-disable-next-line react/require-default-props
  decimalSymbol?: '' | ','
  // eslint-disable-next-line react/require-default-props
  isInteger?: boolean
}

const convertMoneyFormatToNumber = (money: string): number => {
  return parseFloat(money.replace('.', '').replace(',', '.'))
}

const convertNumberToMoneyFormat = (num: number): string => {
  return num.toFixed(2).replace('.', ',')
}

const convertNumberToDecimalFormat = (num: number): string => {
  return num.toString().replace('.', ',')
}

const InputNumberMask: React.FC<InputNumberProps> = ({
  label,
  disabled,
  initialValue = 0,
  onChange,
  prefix = '',
  suffix = '',
  decimalLimit = 2,
  decimalSymbol = ',',
  isInteger = false,
  ...maskOptions
}) => {
  const defaultMaskOptions = {
    prefix,
    suffix,
    includeThousandsSeparator: true,
    thousandsSeparatorSymbol: '.',
    allowDecimal: true,
    decimalLimit,
    decimalSymbol,
    integerLimit: false,
    allowNegative: false,
    allowLeadingZeroes: false,
  }

  const mask = createNumberMask({
    ...defaultMaskOptions,
    ...maskOptions,
  })

  const [value, setValue] = useState<string>(
    isInteger
      ? convertNumberToDecimalFormat(initialValue)
      : convertNumberToMoneyFormat(initialValue),
  )

  useEffect(() => {
    if (onChange) onChange(convertMoneyFormatToNumber(value))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value])

  useEffect(() => {
    isInteger
      ? setValue(convertNumberToDecimalFormat(initialValue))
      : setValue(convertNumberToMoneyFormat(initialValue))
  }, [initialValue, isInteger])

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    try {
      const _value = event.currentTarget.value
      const valueNumber = _value.substr(
        prefix.length,
        _value.length - suffix.length,
      )
      if (_value) setValue(valueNumber)
      if (_value && onChange) onChange(convertMoneyFormatToNumber(valueNumber))
      else setValue('0')
    } catch {
      setValue('0')
    }
  }

  return (
    <Container>
      <Label>{label}</Label>
      <InputContainer>
        <InputNumberContainer
          value={value}
          onChange={handleChange}
          mask={mask}
          disabled={disabled}
        />
      </InputContainer>
    </Container>
  )
}
export { InputNumberMask }
