import styled from 'styled-components'

import theme from '@/assets/styles/theme'

interface IWidth {
  width: string
}

export const PlanStructure = styled.div`
  display: flex;
  flex-direction: column;
  > div {
    &:first-child {
      margin: 0px 0px 8px 20px;
    }
  }
`
export const Header = styled.div`
  font-family: Light, Roboto;
  font-size: 16px;
  letter-spacing: 2.37px;
  color: ${theme.colors.gray[400]};
  text-transform: uppercase;
  opacity: 1;
`
export const PlanIcon = styled.img`
  width: 30px;
`

export const ContainerPlan = styled.div`
  display: flex;
  font-size: 24px;
  font-family: Bold, Roboto;
  letter-spacing: 0px;
  color: ${theme.colors.black};
  opacity: 1;
  font-weight: 700;
`
export const ContainerIcon = styled.div<IWidth>`
  display: flex;
  justify-content: space-between;
  width: ${props => props.width};

  > div {
    &:last-child {
      > span {
        font-weight: 100;
        > b {
          font-size: 26px;
          font-weight: 300;
        }
      }
    }
  }
`
export const PlanName = styled.div`
  display: flex;
  flex-direction: row;

  &:last-child {
    margin-top: 2px;
    margin-left: 5px;
  }
`
