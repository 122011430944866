import styled from 'styled-components'

export interface InputProps {
  width?: string
  marginRight?: string
}

export const Container = styled.div``

export const ContainerForm = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  padding: 0;
  height: calc(100% - 19vh);
`
export const FormLineInput = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 20px;
`
export const ContainerInput = styled.div<InputProps>`
  width: ${props => props.width};
  margin-right: ${props => props.marginRight};
`
export const ContainerButton = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: auto auto;
  column-gap: 1rem;
  justify-content: end;
  padding: 0 0 25px;

  @media only screen and (max-width: 750px) {
    display: flex;
    flex-direction: column-reverse;
    row-gap: 1rem;
  }
`
export const SelectContainer = styled.div`
  width: 775px;
  margin-bottom: 23px;
`
