import styled from 'styled-components'

import theme from '@/assets/styles/theme'

export const Container = styled.div`
  width: 100%;
  cursor: pointer;
`

export const Media = styled.div`
  align-items: flex-start;
  display: flex;
  margin-bottom: 20px;
`

export const Avatar = styled.div`
  align-self: flex-start;
  margin-right: 10px;
  width: 45px;
`

export const Info = styled.div`
  flex: 1;
  flex-direction: column;
`

export const Time = styled.div`
  letter-spacing: 0px;
  color: ${theme.colors.gray[400]};
  padding: 9px 0 0px 0px;
`
