import React from 'react'

import { Container } from './styles'

interface LabelProps {
  fontSize?: string
  fontWeight?: string
  padding?: string
}

export const Label: React.FC<LabelProps> = ({
  fontSize = '1rem',
  fontWeight = 'bold',
  padding = '0 0 1rem 0',
  children,
}) => {
  return (
    <Container fontSize={fontSize} fontWeight={fontWeight} padding={padding}>
      {children}
    </Container>
  )
}
