/* eslint-disable */
import React, { useState, useCallback, useEffect, ChangeEvent } from 'react'
import NumberFormat from 'react-number-format'
import { useDispatch, useSelector } from 'react-redux'
import { toast, ToastContent } from 'react-toastify'

import * as Yup from 'yup'

import Button from '@/components/Ui/Button'
import { RootState } from '@/store'
import { Creators } from '@/store/ducks/Clients'
import { IClientInstallationCost } from '@/store/ducks/Clients/types'
import { Form } from '@unform/web'

import {
  Container,
  ContainerForm,
  FormLineInput,
  ContainerInput,
  ContainerButton,
  CustomInputLabel,
  MuiInput,
} from './styles'

function NumberFormatCustom(props: {
  [x: string]: any
  name?: any
  inputRef?: any
  onChange?: any
}) {
  const { inputRef, onChange, ...other } = props

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={values => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        })
      }}
      thousandSeparator="."
      decimalSeparator=","
      isNumericString
      decimalScale={2}
    />
  )
}
interface IClientEditProps {
  onClickToCancel: () => void
}

const EditClientCommissionInstallationCost: React.FC<IClientEditProps> = ({
  onClickToCancel,
}) => {
  const dispatch = useDispatch()

  const client = useSelector((state: RootState) => state.Client.client)

  const clientProjectInfo = useSelector(
    (state: RootState) => state.Client.clientProjectInfo,
  )

  const [commission, setCommission] = useState<any>(0)
  const [installation_material_cost, setIMCost] = useState<any>(0)

  const handleSubmit = useCallback(async () => {
    try {
      const clientInstallationCost: IClientInstallationCost = {
        commission: commission / 100,
        installation_material_cost: installation_material_cost / 1000,
        id: client.id,
      }

      const userSchema = Yup.object().shape({
        installation_material_cost: Yup.number().required(
          'Tarifa concessionária obrigatória',
        ),
        commission: Yup.number().required('Tarifa não compensada obrigatória'),
      })

      await userSchema.validate(clientInstallationCost, {
        abortEarly: false,
      })

      dispatch(
        Creators.editClientCommissionInstallationCostRequest(
          clientInstallationCost,
        ),
      )
      await new Promise(r => setTimeout(r, 1500))
      onClickToCancel()
    } catch (err) {
      // @ts-ignore
      err.errors.forEach((error: ToastContent) => {
        toast.error(error)
      })
    }
  }, [
    client.id,
    commission,
    installation_material_cost,
    dispatch,
    onClickToCancel,
  ])

  useEffect(() => {
    setIMCost(clientProjectInfo?.installation_material_cost)
    setCommission(clientProjectInfo?.commission)
  }, [clientProjectInfo])

  const handleCommissionChange = (event: ChangeEvent<HTMLInputElement>) =>
    setCommission(event.target.value)

  const handleInstallationChange = (event: ChangeEvent<HTMLInputElement>) =>
    setIMCost(event.target.value)

  return (
    <Container>
      <Form onSubmit={handleSubmit}>
        <ContainerForm>
          <FormLineInput>
            <ContainerInput width="775px" marginRight="35px">
              <CustomInputLabel htmlFor="instalacao">
                Sua Comissão (%):
              </CustomInputLabel>
              <MuiInput
                variant="outlined"
                value={commission}
                onChange={handleCommissionChange}
                name="comissao"
                id="comissão"
                InputProps={{
                  inputComponent: NumberFormatCustom,
                }}
              />
            </ContainerInput>
            <ContainerInput width="775px" marginRight="0px">
              <CustomInputLabel htmlFor="instalacao">
                Instalação + Material CA (R$/kWp):
              </CustomInputLabel>
              <MuiInput
                variant="outlined"
                value={installation_material_cost}
                onChange={handleInstallationChange}
                name="instalacao"
                id="instalacao"
                InputProps={{
                  inputComponent: NumberFormatCustom,
                }}
              />
            </ContainerInput>
          </FormLineInput>
        </ContainerForm>
        <ContainerButton>
          <Button type="button" size="default" onClick={onClickToCancel}>
            Voltar
          </Button>
          <Button
            size="default"
            color="primary"
            type="submit"
            onClick={event => {
              if (
                installation_material_cost < 300 ||
                installation_material_cost > 500
              ) {
                event.preventDefault()
                toast.error(
                  'O valor da instalação deve estar entre 300 R$/kWp e 500 R$/kWp',
                )
              } else if (commission / 100 > 0.20 || commission / 100 < 0.05) {
                event.preventDefault()
                toast.error('A sua comissão deve estar entre 5% e 20%')
              }
            }}
          >
            Salvar alterações
          </Button>
        </ContainerButton>
      </Form>
    </Container>
  )
}

export { EditClientCommissionInstallationCost }
