import styled from 'styled-components'

import theme from '@/assets/styles/theme'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`

export const Logo = styled.div`
  align-items: center;
  display: flex;
  margin-bottom: 30px;

  img {
    height: 40px;
    margin-right: 20px;
    width: 40px;
  }

  h1 {
    font: 300 24px Roboto;
    text-transform: uppercase;
    letter-spacing: 8.9px;

    @media screen and (max-width: ${theme.breakpoints.sm}) {
      font-size: 20px;
    }
  }
`

export const Title = styled.h2`
  font: 800 48px Raleway;

  @media screen and (max-width: ${theme.breakpoints.sm}) {
    font-size: 42px;
  }
`

export const Subtitle = styled.h2`
  font: 300 18px Roboto;
  margin-top: 7px;

  @media screen and (max-width: ${theme.breakpoints.sm}) {
    font-size: 16px;
  }
`

export const Warp = styled.div`
  display: grid;
  margin-top: 35px;
  width: 450px;

  @media screen and (max-width: ${theme.breakpoints.sm}) {
    width: 100%;
  }
`

export const Forgot = styled.div`
  margin-top: 30px;
  text-align: center;

  a {
    color: ${theme.colors.gray[700]};
    font-weight: 300;
    font-size: 18px;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
`
