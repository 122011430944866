import styled from 'styled-components'

export const Container = styled.div`
  height: calc(100% - 19vh);
`
export const FormLineInput = styled.div`
  margin-top: 20px;
  display: flex;
  flex-direction: row;
`
export const ContainerInput = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`
export const ContainerHistory = styled.div`
  width: 234px;
  margin-right: 7px;

  @media only screen and (max-width: 750px) {
    height: 7rem;
    width: 100%;
  }
`
