import produce from 'immer'
import { createReducer, createActions } from 'reduxsauce'

import {
  AuthEnum,
  IAuthActionTypes,
  IAuthStateType,
  ISignInRequest,
  ISignInSuccess,
  ISignInFailure,
} from './types'

type AuthType = typeof AuthEnum

// Create Actions

export const { Types, Creators } = createActions<AuthType, IAuthActionTypes>({
  signInRequest: { email: '', password: '' },
  signInSuccess: { token: '', role: '', user_id: '' },
  signInFailure: { code: 0, message: '' },

  logoutRequest: [],
  logoutSuccess: [],
})

// Initial State

export const INITIAL_STATE: IAuthStateType = {
  email: null,
  password: null,
  token: null,
  role: null,
  user_id: null,
  isAuthorized: false,
  isLoading: false,
  failure: undefined,
}

// Reducers

export const SignInRequest = (
  state: IAuthStateType = INITIAL_STATE,
  { email, password }: ISignInRequest,
) =>
  produce(state, () => ({
    ...state,
    email,
    password,
    isLoading: true,
  }))

export const SignInSuccess = (
  state: IAuthStateType = INITIAL_STATE,
  { token, role, user_id }: ISignInSuccess,
) =>
  produce(state, () => ({
    ...state,
    token,
    role,
    user_id,
    failure: undefined,
    isAuthorized: true,
    isLoading: false,
  }))

export const SignInFailure = (
  state: IAuthStateType = INITIAL_STATE,
  { message }: ISignInFailure,
) =>
  produce(state, () => ({
    ...state,
    failure: {
      message,
    },
    isAuthorized: false,
    isLoading: false,
  }))

export const LogoutRequest = (state = INITIAL_STATE) => ({
  ...state,
})

export const LogoutSuccess = (state = INITIAL_STATE) => ({
  ...state,
  token: null,
  isAuthorized: false,
})

// Hookup Reducers To Types

export const reducer = createReducer<IAuthStateType, IAuthActionTypes>(
  INITIAL_STATE,
  {
    [Types.SIGN_IN_REQUEST]: SignInRequest,
    [Types.SIGN_IN_SUCCESS]: SignInSuccess,
    [Types.SIGN_IN_FAILURE]: SignInFailure,

    [Types.LOGOUT_REQUEST]: LogoutRequest,
    [Types.LOGOUT_SUCCESS]: LogoutSuccess,
  },
)
