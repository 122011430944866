import styled, { css } from 'styled-components'

import theme from '@/assets/styles/theme'

const selector = (active: boolean) => {
  if (active)
    return css`
      color: #000;
      border: 2px solid ${theme.colors.primary};
      border-bottom: 0px solid #fff;
      border-top-left-radius: 7px;
      border-top-right-radius: 7px;
      margin-left: 10px;
      font-weight: bold;
      background: #ffd500;
    `

  return css`
    color: ${theme.colors.gray[400]};
    border-bottom: 2px solid ${theme.colors.primary};
  `
}

interface StyleProps {
  isActive?: boolean
  floaded?: boolean
}

export const Container = styled.div<StyleProps>`
  max-width: auto;
  padding-bottom: 15px;
  overflow: ${({ floaded }) => (floaded ? 'auto' : 'visible')};
`

export const HeaderButtons = styled.div`
  display: flex;
  padding: 0 10px;
  border-bottom: 2px solid ${theme.colors.primary};
`
export const TabButton = styled.div<StyleProps>`
  display: flex;
  padding: 15px 15px;
  transform: translate(-10px, 2px);
  ${({ isActive }) => selector(isActive ?? false)}
`

export const TabText = styled.span`
  cursor: pointer;
`
