import styled, { css } from 'styled-components'

import theme from '@/assets/styles/theme'

interface ReadProps {
  hasBackground?: boolean
  hasBorderBottom?: boolean
}

export const Container = styled.div<ReadProps>`
  width: 100%;
  height: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  background-color: ${props =>
    props.hasBackground ? theme.colors.yellow[1000] : theme.colors.white};
  ${props =>
    props.hasBorderBottom
      ? css`
          border-bottom: 1px solid ${theme.colors.gray[400]};
        `
      : 'none'};
`
export const Avatar = styled.div`
  width: 80px;
  height: 80px;
  padding: 12px 15px 15px 15px;
  > img {
    width: 100%;
    height: 100%;
  }
`
export const TextContainer = styled.div`
  flex: 1;
  padding: 5px 0 0 0;
`

export const Time = styled.div`
  letter-spacing: 0px;
  color: ${theme.colors.gray[400]};
  padding: 9px 0 0px 0px;
`
export const Circle = styled.div<ReadProps>`
  margin: 0 30px;
  width: 15px;
  height: 15px;
  border: 3px solid ${theme.colors.gray[400]};
  border-radius: 15px;
  background-color: ${props =>
    props.hasBackground ? theme.colors.gray[400] : theme.colors.white};
  @media only screen and (max-width: 768px) {
    display: none;
  }
`
