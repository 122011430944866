import React, { useCallback, useState, useEffect } from 'react'
import InputMask from 'react-input-mask'
import { useDispatch, useSelector } from 'react-redux'
import { toast, ToastContent } from 'react-toastify'

import * as Yup from 'yup'

import Button from '@/components/Ui/Button'
import Input from '@/components/Ui/Form/Input'
import { Select } from '@/components/Ui/Select'
import { RootState } from '@/store'
import { BillCreators } from '@/store/ducks/Bill'
import { IBillOwnerEdit } from '@/store/ducks/Bill/types'
import { Form } from '@unform/web'

import {
  Container,
  ContainerForm,
  FormLineInput,
  ContainerInput,
  ContainerButton,
} from './styles'

interface IFormData {
  name: string
  email: string
  phone: string
  clientTypeValue: string
}

interface IClientEditProps {
  onClickToCancel: () => void
}

const EditBillOwner: React.FC<IClientEditProps> = ({ onClickToCancel }) => {
  const dispatch = useDispatch()

  const client = useSelector((state: RootState) => state.Client.client)

  const billOwner = useSelector((state: RootState) => state.Bill.billOwner)

  const [selectedMaritalStatus, setSelectedMaritalStatus] = useState<
    string | undefined
  >(billOwner?.marital_status)

  const [selectedOccupation, setSelectedOccupation] = useState<
    string | undefined
  >(billOwner?.occupation)

  const handleSubmit = useCallback(
    async (clientBillOwner: IFormData) => {
      try {
        const clientData: IBillOwnerEdit = {
          id: client.id,
          name: clientBillOwner.name,
          email: clientBillOwner.email,
          phone: clientBillOwner.phone.replace(/\D/g, ''),
          document_value: clientBillOwner.clientTypeValue.replace(/\D/g, ''),
          occupation: selectedOccupation,
          marital_status: selectedMaritalStatus,
        }

        const userSchema = Yup.object().shape({
          name: Yup.string().required('Nome obrigatório'),
          email: Yup.string()
            .email('Email invalido')
            .required('Email obrigatório'),
          phone: Yup.string().required('Telefone obrigatório'),
          document_value: Yup.string().required(
            'Número de Documento Obrigatório',
          ),
        })

        await userSchema.validate(clientData, {
          abortEarly: false,
        })

        dispatch(BillCreators.editBillOwnerRequest(clientData))
      } catch (err) {
        err.errors.forEach((error: ToastContent) => {
          toast.error(error)
        })
      }
    },
    [client.id, dispatch, selectedMaritalStatus, selectedOccupation],
  )

  useEffect(() => {
    dispatch(BillCreators.getBillOwnerRequest({ id: client.id }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleChangeMaritalStatus = (maritalStatus: any) => {
    setSelectedMaritalStatus(maritalStatus.value)
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleChangeOccupation = (occupation: any) => {
    setSelectedOccupation(occupation.value)
  }

  const maritalStatus = [
    {
      value: 'Solteiro',
      label: 'Solteiro',
    },
    { value: 'Casado', label: 'Casado' },
  ]

  const occupation = [
    {
      value: 'Médico',
      label: 'Médico',
    },
    { value: 'Dentista', label: 'Dentista' },
  ]

  return (
    <Container>
      <Form
        onSubmit={handleSubmit}
        initialData={{
          name: billOwner?.name,
          email: billOwner?.email,
        }}
      >
        <ContainerForm>
          <FormLineInput>
            <ContainerInput width="775px" marginRight="35px">
              <Input
                name="name"
                label="Nome completo"
                width="100%"
                placeholder="Digite o nome completo"
              />
            </ContainerInput>
            <ContainerInput width="775px" marginRight="0px">
              <InputMask
                mask="999.999.999-99"
                defaultValue={billOwner?.document_value}
              >
                {() => (
                  <Input
                    name="clientTypeValue"
                    placeholder="999.999.999-99"
                    label={billOwner?.document_type || 'CPF'}
                    width="100%"
                  />
                )}
              </InputMask>
            </ContainerInput>
          </FormLineInput>
          <FormLineInput>
            <ContainerInput width="775px" marginRight="35px">
              <Input
                name="email"
                label="Email"
                width="100%"
                placeholder="Digite o email"
              />
            </ContainerInput>
            <ContainerInput width="775px" marginRight="0px">
              <InputMask mask="(99)99999-9999" defaultValue={billOwner?.phone}>
                {() => (
                  <Input
                    placeholder="(99)99999-9999"
                    name="phone"
                    label="Telefone"
                    width="100%"
                  />
                )}
              </InputMask>
            </ContainerInput>
          </FormLineInput>

          <FormLineInput>
            <ContainerInput width="775px" marginRight="35px">
              <Select
                placeholder="Selecione..."
                label="Estado Civil"
                size="custom"
                options={maritalStatus}
                value={maritalStatus?.find(
                  maritalStatus =>
                    maritalStatus.value === selectedMaritalStatus,
                )}
                onChange={handleChangeMaritalStatus}
                isSearchable={false}
              />
            </ContainerInput>
            <ContainerInput width="775px" marginRight="0px">
              <Select
                placeholder="Selecione..."
                label="Profissão"
                size="custom"
                options={occupation}
                value={occupation.find(
                  occupation => occupation.value === selectedOccupation,
                )}
                onChange={handleChangeOccupation}
                isSearchable
              />
            </ContainerInput>
          </FormLineInput>
        </ContainerForm>
        <ContainerButton>
          <Button type="button" size="default" onClick={onClickToCancel}>
            Voltar
          </Button>
          <Button size="default" color="primary" type="submit">
            Salvar alterações
          </Button>
        </ContainerButton>
      </Form>
    </Container>
  )
}

export { EditBillOwner }
