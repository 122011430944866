import React, {
  FC,
  useRef,
  useState,
  DragEvent,
  useEffect,
  useCallback,
} from 'react'
import { toast } from 'react-toastify'

import CheckIcon from '@/assets/images/icons/check-icon-green.svg'

import * as S from './styles'

type FileInputProps = {
  // eslint-disable-next-line @typescript-eslint/ban-types
  onChange: Function
  // eslint-disable-next-line react/require-default-props
  hasFile?: boolean
  // eslint-disable-next-line react/require-default-props
  isFileSent?: boolean
  // eslint-disable-next-line react/require-default-props
  fileName?: string
}

const VALID_MIME_TYPES = [
  'image/jpeg',
  'image/jpg',
  'image/png',
  'application/pdf',
]

const FileInput: FC<FileInputProps> = ({
  onChange,
  hasFile = false,
  isFileSent = false,
  fileName = '',
}) => {
  const [selectedFile, setSelectedFile] = useState<File | null>(null)

  const getCurrentFileName = useCallback(() => {
    function elipseFileName(currentName: string) {
      const nameArray = currentName.split('.')
      return nameArray[0].substring(0, 15).concat('... .').concat(nameArray[1])
    }

    let fileNameToShow = 'Arquivo enviado'
    if (selectedFile?.name)
      fileNameToShow =
        selectedFile.name.length > 15
          ? elipseFileName(selectedFile.name)
          : selectedFile.name
    if (fileName)
      fileNameToShow =
        fileName.length > 15 ? elipseFileName(fileName) : fileName

    return fileNameToShow
  }, [selectedFile, fileName])

  useEffect(() => {
    onChange(selectedFile)
  }, [selectedFile, onChange])

  const fileInputRef = useRef<HTMLInputElement>(null)

  const dragPrevent = useCallback(
    (e: DragEvent<HTMLDivElement>) => e.preventDefault(),
    [],
  )

  const handleFiles = useCallback((files: FileList) => {
    if (files[0].size > 10000000) {
      toast.error(
        'Tamanho do arquivo excedido! Carregue um arquivo com até 10MB.',
      )
      return
    }

    const isFileValid = VALID_MIME_TYPES.indexOf(files[0].type) >= 0

    isFileValid
      ? setSelectedFile(files[0])
      : toast.error('Tipo de arquivo não suportado!')
  }, [])

  const fileDrop = useCallback(
    (e: DragEvent<HTMLDivElement>) => {
      e.stopPropagation()
      e.preventDefault()

      const { files } = e.dataTransfer
      if (files.length) handleFiles(files)
    },
    [handleFiles],
  )

  const filesSelected = useCallback(() => {
    if (fileInputRef?.current?.files?.length)
      handleFiles(fileInputRef.current.files)
  }, [handleFiles])

  const renderDropArea = useCallback(() => {
    const fileInputClicked = () => fileInputRef?.current?.click()

    if (hasFile || isFileSent)
      return (
        <S.FileArea>
          <img src={CheckIcon} alt="check icon" />
          <S.FileName>{getCurrentFileName()}</S.FileName>
        </S.FileArea>
      )

    return (
      <S.DropArea
        onDragOver={dragPrevent}
        onDragEnter={dragPrevent}
        onDragLeave={dragPrevent}
        onDrop={fileDrop}
        onClick={fileInputClicked}
      >
        <input ref={fileInputRef} type="file" onChange={filesSelected} />
        <S.InfoText>Click aqui para inserir o documento</S.InfoText>
      </S.DropArea>
    )
  }, [
    hasFile,
    filesSelected,
    dragPrevent,
    fileDrop,
    isFileSent,
    getCurrentFileName,
  ])

  return <S.Wrapper>{renderDropArea()}</S.Wrapper>
}

export default FileInput
