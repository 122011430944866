/* eslint-disable @typescript-eslint/no-explicit-any */
import { Action } from 'redux'

import { ClientStepStatusEnum, TypeDocumentEnum } from '@/@types/client/client'
import { ClientDocumentStatusEnum } from '@/@types/clientDocument/clientDocumentStatusEnum'
import { ClientDocumentTypeEnum } from '@/@types/clientDocument/clientDocumentTypeEnum'
import { TechnicalVisitData } from '@/store/ducks/Visit/types'

export enum ClientTypeEnum {
  GET_CLIENTS_REQUEST = 'GET_CLIENTS_REQUEST',
  GET_CLIENTS_SUCCESS = 'GET_CLIENTS_SUCCESS',
  GET_CLIENTS_FAILURE = 'GET_CLIENTS_FAILURE',

  GET_CLIENT_REQUEST = 'GET_CLIENT_REQUEST',
  GET_CLIENT_SUCCESS = 'GET_CLIENT_SUCCESS',
  GET_CLIENT_FAILURE = 'GET_CLIENT_FAILURE',

  EDIT_CLIENT_REQUEST = 'EDIT_CLIENT_REQUEST',
  EDIT_CLIENT_SUCCESS = 'EDIT_CLIENT_SUCCESS',
  EDIT_CLIENT_FAILURE = 'EDIT_CLIENT_FAILURE',

  GET_CLIENT_SIMULATION_REQUEST = 'GET_CLIENT_SIMULATION_REQUEST',
  GET_CLIENT_SIMULATION_SUCCESS = 'GET_CLIENT_SIMULATION_SUCCESS',
  GET_CLIENT_SIMULATION_FAILURE = 'GET_CLIENT_SIMULATION_FAILURE',

  GET_CLIENT_PLAN_REQUEST = 'GET_CLIENT_PLAN_REQUEST',
  GET_CLIENT_PLAN_SUCCESS = 'GET_CLIENT_PLAN_SUCCESS',
  GET_CLIENT_PLAN_FAILURE = 'GET_CLIENT_PLAN_FAILURE',

  GET_CLIENT_LEGAL_REQUEST = 'GET_CLIENT_LEGAL_REQUEST',
  GET_CLIENT_LEGAL_SUCCESS = 'GET_CLIENT_LEGAL_SUCCESS',
  GET_CLIENT_LEGAL_FAILURE = 'GET_CLIENT_LEGAL_FAILURE',

  EDIT_CLIENT_LEGAL_REQUEST = 'EDIT_CLIENT_LEGAL_REQUEST',
  EDIT_CLIENT_LEGAL_SUCCESS = 'EDIT_CLIENT_LEGAL_SUCCESS',
  EDIT_CLIENT_LEGAL_FAILURE = 'EDIT_CLIENT_LEGAL_FAILURE',

  SET_IS_EDIT_CLIENT_LEGAL = 'SET_IS_EDIT_CLIENT_LEGAL',

  GET_CLIENT_DOCUMENTS_REQUEST = 'GET_CLIENT_DOCUMENTS_REQUEST',
  GET_CLIENT_DOCUMENTS_SUCCESS = 'GET_CLIENT_DOCUMENTS_SUCCESS',
  GET_CLIENT_DOCUMENTS_FAILURE = 'GET_CLIENT_DOCUMENTS_FAILURE',

  EDIT_CLIENT_DOCUMENTS_STATUS_REQUEST = 'EDIT_CLIENT_DOCUMENTS_STATUS_REQUEST',
  EDIT_CLIENT_DOCUMENTS_STATUS_SUCCESS = 'EDIT_CLIENT_DOCUMENTS_STATUS_SUCCESS',
  EDIT_CLIENT_DOCUMENTS_STATUS_FAILURE = 'EDIT_CLIENT_DOCUMENTS_STATUS_FAILURE',

  SET_IS_EDIT_CLIENT_DETAILS = 'SET_IS_EDIT_CLIENT_DETAILS',

  GET_CLIENT_PROJECT_INFO_REQUEST = 'GET_CLIENT_PROJECT_INFO_REQUEST',
  GET_CLIENT_PROJECT_INFO_SUCCESS = 'GET_CLIENT_PROJECT_INFO_SUCCESS',
  GET_CLIENT_PROJECT_INFO_FAILURE = 'GET_CLIENT_PROJECT_INFO_FAILURE',

  SET_IS_EDIT_CLIENT_TARIFF = 'SET_IS_EDIT_CLIENT_TARIFF',

  EDIT_CLIENT_TARIFF_REQUEST = 'EDIT_CLIENT_TARIFF_REQUEST',
  EDIT_CLIENT_COMMISSION_INSTALLATION_COST_REQUEST = 'EDIT_CLIENT_COMMISSION_INSTALLATION_COST_REQUEST',
  EDIT_CLIENT_TARIFF_SUCCESS = 'EDIT_CLIENT_TARIFF_SUCCESS',
  EDIT_CLIENT_TARIFF_FAILURE = 'EDIT_CLIENT_TARIFF_FAILURE',

  SET_IS_EDIT_CLIENT_ENERGY_CONSUMPTION = 'SET_IS_EDIT_CLIENT_ENERGY_CONSUMPTION',

  EDIT_CLIENT_ENERGY_CONSUMPTION_REQUEST = 'EDIT_CLIENT_ENERGY_CONSUMPTION_REQUEST',
  EDIT_CLIENT_ENERGY_CONSUMPTION_SUCCESS = 'EDIT_CLIENT_ENERGY_CONSUMPTION_SUCCESS',
  EDIT_CLIENT_ENERGY_CONSUMPTION_FAILURE = 'EDIT_CLIENT_ENERGY_CONSUMPTION_FAILURE',

  SET_IS_EDIT_CLIENT_BOARD = 'SET_IS_EDIT_CLIENT_BOARD',

  EDIT_CLIENT_BOARD_REQUEST = 'EDIT_CLIENT_BOARD_REQUEST',
  EDIT_CLIENT_BOARD_SUCCESS = 'EDIT_CLIENT_BOARD_SUCCESS',
  EDIT_CLIENT_BOARD_FAILURE = 'EDIT_CLIENT_BOARD_FAILURE',

  GET_CLIENT_SIMULATION_BY_CLIENT_ID_REQUEST = 'GET_CLIENT_SIMULATION_BY_CLIENT_ID_REQUEST',
  GET_CLIENT_SIMULATION_BY_CLIENT_ID_SUCCESS = 'GET_CLIENT_SIMULATION_BY_CLIENT_ID_SUCCESS',
  GET_CLIENT_SIMULATION_BY_CLIENT_ID_FAILURE = 'GET_CLIENT_SIMULATION_BY_CLIENT_ID_FAILURE',

  SET_CLIENT_GENERATE_PLANS_REQUEST = 'SET_CLIENT_GENERATE_PLANS_REQUEST',
  SET_CLIENT_GENERATE_PLANS_SUCCESS = 'SET_CLIENT_GENERATE_PLANS_SUCCESS',
  SET_CLIENT_GENERATE_PLANS_FAILURE = 'SET_CLIENT_GENERATE_PLANS_FAILURE',

  CONFIRM_INSTALATION_REQUEST = 'CONFIRM_INSTALATION_REQUEST',
  CONFIRM_INSTALATION_SUCCESS = 'CONFIRM_INSTALATION_SUCCESS',
  CONFIRM_INSTALATION_FAILURE = 'CONFIRM_INSTALATION_FAILURE',

  CONFIRM_MESSAGES_REQUEST = 'CONFIRM_MESSAGES_REQUEST',
  CONFIRM_MESSAGES_SUCCESS = 'CONFIRM_MESSAGES_SUCCESS',
  CONFIRM_MESSAGES_FAILURE = 'CONFIRM_MESSAGES_FAILURE',

  CONFIRM_SAVE_MESSAGE_REQUEST = 'CONFIRM_SAVE_MESSAGE_REQUEST',
  CONFIRM_SAVE_MESSAGE_SUCCESS = 'CONFIRM_SAVE_MESSAGE_SUCCESS',
  CONFIRM_SAVE_MESSAGE_FAILURE = 'CONFIRM_SAVE_MESSAGE_FAILURE',

  CONFIRM_UPDATE_STATUS_REQUEST = 'CONFIRM_UPDATE_STATUS_REQUEST',
  CONFIRM_UPDATE_STATUS_SUCCESS = 'CONFIRM_UPDATE_STATUS_SUCCESS',
  CONFIRM_UPDATE_STATUS_FAILURE = 'CONFIRM_SAVE_MESSAGE_FAILURE',

  CONFIRM_FILES_REQUEST = 'CONFIRM_FILES_REQUEST',
  CONFIRM_FILES_SUCCESS = 'CONFIRM_FILES_SUCCESS',
  CONFIRM_FILES_FAILURE = 'CONFIRM_FILES_FAILURE',

  CONFIRM_UPLOAD_ENABLE_REQUEST = 'CONFIRM_UPLOAD_ENABLE_REQUEST',
  CONFIRM_UPLOAD_ENABLE_SUCCESS = 'CONFIRM_UPLOAD_ENABLE_SUCCESS',
  CONFIRM_UPLOAD_ENABLE_FAILURE = 'CONFIRM_UPLOAD_ENABLE_FAILURE',

  CONFIRM_CONTACT_NEEDED_REQUEST = 'CONFIRM_CONTACT_NEEDED_REQUEST',
  CONFIRM_CONTACT_NEEDED_SUCCESS = 'CONFIRM_CONTACT_NEEDED_SUCCESS',
  CONFIRM_CONTACT_NEEDED_FAILURE = 'CONFIRM_CONTACT_NEEDED_FAILURE',

  CONFIRM_CLIENT_SUCCESS_TABLES_REQUEST = 'CONFIRM_CLIENT_SUCCESS_TABLES_REQUEST',
  CONFIRM_CLIENT_SUCCESS_TABLES_SUCCESS = 'ONFIRM_CLIENT_SUCCESS_TABLES_SUCCESS',
  CONFIRM_CLIENT_SUCCESS_TABLES_FAILURE = 'CONFIRM_CLIENT_SUCCESS_TABLES_FAILURE',

  GET_CLIENT_FREE_MONTH_REQUEST = 'GET_CLIENT_FREE_MONTH_REQUEST',
  GET_CLIENT_FREE_MONTH_SUCCESS = 'GET_CLIENT_FREE_MONTH_SUCCESS',
  GET_CLIENT_FREE_MONTH_FAILURE = 'GET_CLIENT_FREE_MONTH_FAILURE',

  SET_CLIENT_FREE_MONTH_REQUEST = 'SET_CLIENT_FREE_MONTH_REQUEST',
  SET_CLIENT_FREE_MONTH_SUCCESS = 'SET_CLIENT_FREE_MONTH_SUCCESS',
  SET_CLIENT_FREE_MONTH_FAILURE = 'SET_CLIENT_FREE_MONTH_FAILURE',

  GET_CLIENT_PARTNERSHIP_REQUEST = 'GET_CLIENT_PARTNERSHIP_REQUEST',
  GET_CLIENT_PARTNERSHIP_SUCCESS = 'GET_CLIENT_PARTNERSHIP_SUCCESS',
  GET_CLIENT_PARTNERSHIP_FAILURE = 'GET_CLIENT_PARTNERSHIP_FAILURE',

  CONFIRM_PARTNERSHIP_REQUEST = 'CONFIRM_PARTNERSHIP_REQUEST',
  CONFIRM_PARTNERSHIP_SUCCESS = 'CONFIRM_PARTNERSHIP_SUCCESS',
  CONFIRM_PARTNERSHIP_FAILURE = 'CONFIRM_PARTNERSHIP_FAILURE',
}

export interface IClientStep {
  client_id: string
  type: ClientStepTypeEnum
  status: ClientStepStatusEnum
}
export interface IClientType {
  type?: TypeDocumentEnum
  value?: string
}
export interface ICity {
  id?: string
  name: string
  state: string
}

export interface ContractData {
  id: string
  created_at: string
  updated_at: string
  key: string
  type: string
  name: string
  client_id: string
  client_signed_ip: string
  is_signed: boolean
  is_signed_at: string
  contract_number?: string
  url?: string
  contract_private_url?: string
}

export enum SubscriptionLendingTypes {
  LENDING = 'LENDING',
  SUBSCRIPTION = 'SUBSCRIPTION',
}

export interface IClientItem {
  id: string
  name: string
  email: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  bill?: any
  contracts?: ContractData[]
  state?: ICity
  steps?: IClientStep[]
  type: IClientType
  city: ICity
  technical_visit?: TechnicalVisitData
  phone: string
  unit_type?: string
  address: string
  address_complement: string
  address_neighborhood: string
  address_number: string
  postal_code: string
  created_at?: string
  logs?: IActivityLog
  last_login?: string
  selected_client_plan?: IClientPlan
  bill_value?: number
  bill_id?: string
  technical_comments?: string
  client_project_info?: IClientProjectInfo
  customer_success?: ICostumerSuccess
  hasPartnership?: boolean
  subscription_lending?: SubscriptionLendingTypes
  franchisee_id?: string
  franchisee?: any
}

export interface IActivityLog {
  id: number
  description: string
  created_at: string
}

export type IUploadClientPayload = Omit<IClientItem, 'technical_visit'>

export interface IPagination {
  current_page: number
  item_count: number
  items_per_page: number
  total_items: number
  total_pages: number
}

export interface IClientsData {
  items: IClientItem[]
  meta: IPagination
}

export interface IFreeMonth {
  low?: number
  med?: number
  max?: number
}

export interface IPartnership {
  id?: string
  client_id?: string
  descriptio?: string
  url?: string
  is_validated?: boolean
}

export interface IClientStateType {
  clients: IClientsData
  isClientLoading: boolean
  client: IClientItem
  isEditClientDetails: boolean
  planSimulated?: IPlanSimulated
  clientPlan?: IClientPlan
  clientLegal?: IClientLegal
  isEditClientLegal: boolean
  clientDocuments: IClientDocument[]
  clientProjectInfo?: IClientProjectInfo
  isEditClientTariff: boolean
  isEditClientEnergyConsumption: boolean
  isEditClientBoard: boolean
  clientSimulatedPlanValues?: IClientPlanSimulated
  isLoading: boolean
  costumerSuccessMessages: IMessage[]
  postMessage: string
  status: ProjectStatusType
  files: IFile[]
  uploadAvailable: boolean
  contactAvailable: boolean
  freeMonths: IFreeMonth
  partnership: IPartnership
}

export enum ClientStepTypeEnum {
  SIMULATION = 'SIMULATION',
  BILL = 'BILL',
  BILL_PENDING = 'BILL_PENDING',
  BILL_DENIED = 'BILL_DENIED',
  BILL_NOT_DONE = 'BILL_NOT_DONE',
  TECHNICAL_VISIT = 'TECHNICAL_VISIT',
  TECHNICAL_VISIT_CONFIRMED_SCHEDULE = 'TECHNICAL_VISIT_CONFIRMED_SCHEDULE',
  TECHNICAL_VISIT_FULFILLED = 'TECHNICAL_VISIT_FULFILLED',
  TECHNICAL_VISIT_DONE_AND_NOT_APPROVED = 'TECHNICAL_VISIT_DONE_AND_NOT_APPROVED',
  SIGNATURE = 'SIGNATURE',
  SIGNATURE_DENIED = 'SIGNATURE_DENIED',
  SIGNATURE_APPROVED = 'SIGNATURE_APPROVED',
  CHECKOUT = 'CHECKOUT',
  CHECKOUT_DENIED = 'CHECKOUT_DENIED',
  CHECKOUT_APPROVED = 'CHECKOUT_APPROVED',
  INSTALATION = 'INSTALATION',
  ACTIVE = 'ACTIVE',
  CONTRACT = 'CONTRACT',
  CONTRACT_APPROVED = 'CONTRACT_APPROVED',
  CONTRACT_DENIED = 'CONTRACT_DENIED',
  PROPOSAL_INITIAL = 'PROPOSAL_INITIAL',
  PROPOSAL_FINAL = 'PROPOSAL_FINAL',
  SUPPLY = 'SUPPLY',
}

export interface IListClientQuery {
  page: number
  search?: string
  clientType?: string
  state?: string[]
  cityIds?: string[]
  situation?: string[]
  bill_status?: string
  proposal_status?: string
  visit_status?: string
  is_client_signed?: string
  limit?: number
  currentSteps?: ClientStepTypeEnum[]
  order?: string
  date?: string[]
  direction?: string
}

export interface IPlanSimulated {
  rent: string
  annual_savings: string
  old_bill: string
  new_bill: string
  reduction_percent: string
  economy_month: string
  nivel: string
  deadline_years: string
}

export interface IClientPlan {
  client_id: string
  plan_id: string
  plan: {
    name: string
    type: string
    joining_fee: string
    has_monitoring_by_app: boolean
    has_assistance: boolean
  }
  price: string
  deadline_type: string
  deadline: string
  annual_savings: string
  old_bill: string
  new_bill: string
  reduction_percent: string
  economy_per_month: string
  is_fit: boolean
  total_tree: string
  total_potential: string
}

export interface IClientLegal {
  id?: string
  name: string
  legal_document: string
  email: string
  phone: string
  state: string | undefined
  city: string | undefined
}

export interface IClientDocument {
  id: string
  image_url: string
  image_back_url: string
  type: ClientDocumentTypeEnum
  status: ClientDocumentStatusEnum
  created_at: string
}

export interface IClientDocumentStatus {
  id: string
  status: ClientDocumentStatusEnum
}

export interface IClientTariff {
  solar21_tax: number
  not_compensated_tax: number
  dealership_tax: number
  id: string
}

export interface IClientInstallationCost {
  installation_material_cost: number
  commission: number
  id: string
}

export interface IClientEnergyConsumption {
  kwh_availability: number
  concurrency_factor: number
  id: string
}

export interface IClientBoard {
  inverter_power?: number
  module_power: number
  total_losses: number
  number_of_modules: number
  id: string
  inverter_name?: string
  module_name?: string
  specific_production?: number
  optimizer_quantity?: number
}

export interface IClientProjectInfo {
  solar21_tax: number | undefined
  not_compensated_tax: number | undefined
  dealership_tax: number | undefined
  kwh_availability: number | undefined
  concurrency_factor: number | undefined
  inverter_power: number | undefined
  module_power: number | undefined
  total_losses: number | undefined
  number_of_modules: number | undefined
  commission: number
  installation_material_cost: number
  inverter_name: string | undefined
  module_name: string | undefined
  structure?: string
  technical_comment?: string
  new_standard_justify?: string
  specific_production?: number | undefined
  optimizer_quantity?: number | undefined
}

export interface ICostumerSuccess {
  id?: string
  created_at?: string
  updated_at?: string
  status?: string
  upload_available?: boolean
  contact_available?: boolean
}

export interface IMessage {
  message: string
  id: string
  created_at: string
  client_customer_success_id: string
}

export interface IFile {
  id: string
  created_at: string
  client_customer_success_id: string
  name: string
  url: string
}

export type ProjectStatusType =
  | 'PROJECT_IN_PREPARATION'
  | 'PROJECT_SUBMITTED_TO_DEALERSHIP'
  | 'PROJECT_APPROVED_BY_THE_CONCESSIONAIRE'
  | 'EQUIPMENT_DISPATCHED'
  | 'EQUIPMENT_DELIVERED'
  | 'INSPECTION_APPROVED'
  | 'SYSTEM_CONNECTED_AND_WORKING'
  | ''

export interface IClientSimulationByClientId {
  id: string
  bill_value: number
  solar21_tax?: number
  not_compensated_tax?: number
  dealership_tax?: number
  kwh_availability?: number
  concurrency_factor?: number
  module_power?: number
  total_losses?: number
  number_of_modules?: number
}

export interface IClientPlanSimulated {
  annual_values: IClientPlanSimulatedAnnualValues
  monthly_values: IClientPlanSimulatedMonthlyValues
  photovoltaic_system: IClientPlanSimulatedPhotovoltaic
  project_indicators: IClientPlanSimulatedIndicator
  plan: IClientPlanSimulatedType
  taxes: ISimulationTaxes
  energy_consumption: ISimulationEnergyConsumption
  overload: number
}

export interface ISimulationTaxes {
  dealership_tax: number
  solar21_tax: number
  not_compensated_tax: number
  minimum_tax: number
}

export interface ISimulationEnergyConsumption {
  average: number
  total: number
}

export interface IClientPlanSimulatedAnnualValues {
  annual_savings: number
  client_annual_rental_value: number
  client_final_annual_value: number
  new_bill_annual_value: number
  old_bill_annual_value: number
}

export interface IClientPlanSimulatedMonthlyValues {
  monthly_reduction: number
  monthly_rental_savings: number
  rental_value: number
}

export interface IClientPlanSimulatedPhotovoltaic {
  annual_production: number
  co2_emission: number
  excess_credit: number
  number_of_project_modules: number
  suggested_number_of_project_modules: number
  power_of_the_project: number
  total_planted_trees: number
}

export interface IClientPlanSimulatedIndicator {
  kwh_kwp: number
  kwp_price: number
}

export interface IClientPlanSimulatedType {
  low: IClientPlanSimulatedPlan
  med: IClientPlanSimulatedPlan
  max: IClientPlanSimulatedPlan
}

export interface IClientPlanSimulatedPlan {
  monthly_value: number
  estimated_annual_savings: IClientPlanSimulatedPlanAnnualSavings
  estimated_monthly_savings: IClientPlanSimulatedPlanMonthlySavings
}

export interface IClientPlanSimulatedPlanAnnualSavings {
  percent: number
  value: number
}

export interface IClientPlanSimulatedPlanMonthlySavings {
  value: number
}

export interface IActionTypes extends Action<ClientTypeEnum> {
  getClientsRequest: (query: IListClientQuery) => Action
  getClientsSuccess: (client: IClientsData) => Action
  getClientsFailure: () => Action

  getClientRequest: ({ id }: { id: string }) => Action
  getClientSuccess: (client: IClientItem) => Action
  getClientFailure: () => Action

  editClientRequest: (client: IUploadClientPayload) => Action
  editClientSuccess: () => Action
  editClientFailure: () => Action

  getClientSimulationRequest: ({ id }: { id: string }) => Action
  getClientSimulationSuccess: (planSimulated: IPlanSimulated) => Action
  getClientSimulationFailure: () => Action

  getClientPlanRequest: ({ id }: { id: string }) => Action
  getClientPlanSuccess: (clientPlan: IClientPlan) => Action
  getClientPlanFailure: () => Action

  getClientLegalRequest: ({ id }: { id: string }) => Action
  getClientLegalSuccess: (clientLegal: IClientLegal) => Action
  getClientLegalFailure: () => Action

  editClientLegalRequest: (clientLegal: IClientLegal) => Action
  editClientLegalSuccess: () => Action
  editClientLegalFailure: () => Action

  setIsEditClientLegal: (isEditClientLegal: boolean) => Action

  getClientDocumentsRequest: ({ id }: { id: string }) => Action
  getClientDocumentsSuccess: (clientDocuments: IClientDocument[]) => Action
  getClientDocumentsFailure: () => Action

  editClientDocumentsStatusRequest: (
    clientDocumentsStatus: IClientDocumentStatus[],
  ) => Action
  editClientDocumentsStatusSuccess: (
    clientDocuments: IClientDocument[],
  ) => Action
  editClientDocumentsStatusFailure: () => Action

  setIsEditClientDetails: (isEditClient: boolean) => Action

  getClientProjectInfoRequest: ({ id }: { id: string }) => Action
  getClientProjectInfoSuccess: (clientProjectInfo: IClientProjectInfo) => Action
  getClientProjectInfoFailure: () => Action

  setIsEditClientTariff: (isEditClientTariff: boolean) => Action

  editClientTariffRequest: (clientTariff: IClientTariff) => Action
  editClientCommissionInstallationCostRequest: (
    clientInstallationCost: IClientInstallationCost,
  ) => Action
  editClientTariffSuccess: () => Action
  editClientTariffFailure: () => Action

  setIsEditClientEnergyConsumption: (
    isEditClientEnergyConsumption: boolean,
  ) => Action

  editClientEnergyConsumptionRequest: (
    clientEnergyConsumption: IClientEnergyConsumption,
  ) => Action
  editClientEnergyConsumptionSuccess: () => Action
  editClientEnergyConsumptionFailure: () => Action

  setIsEditClientBoard: (isEditClientBoard: boolean) => Action

  editClientBoardRequest: (clientBoard: IClientBoard) => Action
  editClientBoardSuccess: () => Action
  editClientBoardFailure: () => Action

  getClientSimulationByClientIdRequest: (
    simulationPayload: IClientSimulationByClientId,
  ) => Action
  getClientSimulationByClientIdSuccess: (
    clientSimulatedPlanValues: IClientPlanSimulated,
  ) => Action
  getClientSimulationByClientIdFailure: () => Action

  setClientGeneratePlansRequest: ({ id }: { id: string }) => Action
  setClientGeneratePlansSuccess: () => Action
  setClientGeneratePlansFailure: () => Action

  confirmInstalationRequest: () => Action
  confirmInstalationSuccess: () => Action
  confirmInstalationFailure: () => Action

  confirmMessagesRequest: () => Action
  confirmMessagesSuccess: (messages: IMessage[]) => Action
  confirmMessagesFailure: () => Action

  confirmSaveMessageRequest: (newMessage: string) => Action
  confirmSaveMessageSuccess: () => Action
  confirmSaveMessageFailure: () => Action

  confirmUpdateStatusRequest: (status: ProjectStatusType) => Action
  confirmUpdateStatusSuccess: () => Action
  confirmUpdateStatusFailure: () => Action

  confirmFilesRequest: () => Action
  confirmFilesSuccess: (files: IFile[]) => Action
  confirmFilesFailure: () => Action

  confirmUploadEnableRequest: (uploadAvailable: boolean) => Action
  confirmUploadEnableSuccess: () => Action
  confirmUploadEnableFailure: () => Action

  confirmContactNeededRequest: (contactAvailable: boolean) => Action
  confirmContactNeededSuccess: () => Action
  confirmContactNeededFailure: () => Action

  confirmClientSuccessTablesRequest: () => Action
  confirmClientSuccessTablesSuccess: () => Action
  confirmClientSuccessTablesFailure: () => Action

  getClientFreeMonthRequest: () => Action
  getClientFreeMonthSuccess: (freeMonths: IFreeMonth) => Action
  getClientFreeMonthFailure: () => Action

  setClientFreeMonthRequest: (enable: boolean) => Action
  setClientFreeMonthSuccess: () => Action
  setClientFreeMonthFailure: () => Action

  getClientPartnershipRequest: () => Action
  getClientPartnershipSuccess: (partnership: IPartnership) => Action
  getClientPartnershipFailure: () => Action

  confirmPartnershipRequest: () => Action
  confirmPartnershipSuccess: () => Action
  confirmPartnershipFailure: () => Action
}
