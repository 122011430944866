import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { planType } from '@/@types/planType'
import { Plan } from '@/components/Plan/Plan'
import { RootState } from '@/store'
import { Creators } from '@/store/ducks/Clients/client'

import {
  Container,
  LabelContainer,
  ContainerBill,
  TitleBillValue,
  ContainerPlan,
} from './styles'

const Simulation: React.FC = () => {
  const dispatch = useDispatch()

  const client = useSelector((state: RootState) => state.Client.client)

  const planSimulated = useSelector(
    (state: RootState) => state.Client.planSimulated,
  )

  useEffect(() => {
    dispatch(Creators.getClientSimulationRequest({ id: client.id }))
  }, [client.id, dispatch])

  return (
    <Container>
      {planSimulated ? (
        <>
          <div>
            <LabelContainer>
              <label>Conta mensal de luz</label>
            </LabelContainer>
            <ContainerBill>
              <div>
                <TitleBillValue>
                  Valor da conta de luz:{' '}
                  <span>
                    R${' '}
                    {parseFloat(planSimulated.old_bill)
                      .toFixed(2)
                      .replace('.', ',')}
                  </span>
                </TitleBillValue>
              </div>
            </ContainerBill>
          </div>

          <div>
            <LabelContainer>
              <label>Plano escolhido</label>
            </LabelContainer>

            <ContainerPlan>
              <Plan
                planName={planType[planSimulated.nivel]}
                planPrice={parseFloat(planSimulated.rent)
                  .toFixed(2)
                  .replace('.', ',')}
              />
              <div>
                <TitleBillValue>
                  Prazo do plano:{' '}
                  <span>{planSimulated.deadline_years} anos</span>
                </TitleBillValue>
              </div>
              <div>
                <TitleBillValue>
                  Economia estimada anual:{' '}
                  <span>
                    R${' '}
                    {parseFloat(planSimulated.annual_savings)
                      .toFixed(2)
                      .replace('.', ',')}
                  </span>
                </TitleBillValue>
              </div>
            </ContainerPlan>
          </div>
        </>
      ) : (
        <h1>Não há um plano simulado.</h1>
      )}
    </Container>
  )
}

export { Simulation }
