/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { ChangeEvent, FC, useMemo, useState } from 'react'

import api from '@/services/api'
import { FormControl, MenuItem, Select } from '@material-ui/core'
import LocalOfferOutlinedIcon from '@material-ui/icons/LocalOfferOutlined'

import { useStyles } from '../styles'

type PriorityPropsType = {
  id: string
  priority: number
}

const Priority: FC<PriorityPropsType> = ({ id, priority }) => {
  const classes = useStyles()

  const [cardPriority, setCardPriority] = useState<number>(priority ?? 0)

  const handleChange = (event: ChangeEvent<any>) => {
    const { value } = event.target
    setCardPriority(value)
    api
      .patch(`/admin/customer_priority/${id}/${value}`)
      .catch(err => console.error('Error updating priority: ', err))
  }

  const priorityColor = useMemo(() => {
    switch (cardPriority) {
      case 1:
        return '#FFD500'
      case 2:
        return '#FF041A'
      default:
        return ''
    }
  }, [cardPriority])

  return (
    <FormControl variant="filled" className={classes.formControl}>
      <Select
        value={cardPriority}
        onChange={handleChange}
        className={cardPriority > 1 ? classes.selectRed : classes.select}
        style={{
          backgroundColor: priorityColor || undefined,
          height: 35,
          width: 'auto',
        }}
      >
        <MenuItem value={0}>
          <LocalOfferOutlinedIcon fontSize="small" /> Baixa
        </MenuItem>
        <MenuItem value={1}>
          <LocalOfferOutlinedIcon fontSize="small" /> Média
        </MenuItem>
        <MenuItem value={2}>
          <LocalOfferOutlinedIcon fontSize="small" /> Alta
        </MenuItem>
      </Select>
    </FormControl>
  )
}

export default Priority
