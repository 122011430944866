import styled from 'styled-components'

export const Container = styled.div``

export const ContainerTable = styled.div`
  margin-top: 30px;
  height: 465px;
  overflow-y: scroll;
`

export const ContainerPagination = styled.div`
  margin-top: 20px;
  display: flex;
  justify-content: flex-end;
`
